/*====================
	Version: 1.0
======================*/
/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ animations
	|	|___ shortcode
	|
	|
	|___Elements Styles
	|	|___ Button
	|	|___ section-title
	|	|___ service
	|	|___ counterup
	|	|___ team
	|	|___ wavify
	|	|___ wavify
	|	|___ blog-list
	|	|___ contact
	|	|___ about
	|	|___ call-to-action
	|	|___ slick-arrow
	|	|___ motivational-archive
	|	|___ social
	|	|___ shortnote
	|	|___ flexable-image-slide
	|	|___ carousel-slider
	|	|___ countdown
	|	|___ progress-charts
	|	|___ gradation
	|	|___ typed-text
	|	|___ Politic
	|	|___ pricing-plans
	|	|___ career
	|	|___ maintenance
	|	|___ contagion
	|	|___ player
	|	|___ 404
	|	
	|
	|
	|___Components Styles
	|	|___ video 
	|	|___ breadcaump 
	|	|___ instagram
	|	|___ popup
	|	|___ shopping-cart
	|	|___ mega-menu
	|	|___ toolbar
	|	|___ typography
	|	|___ list
	|	|___ pricing-box
	|	|___ icon-boxes
	|	|___ dividers
	|	|___ message-box
	|	|___ accordion
	|	|___ googlemap
	|	|___ rows-column
	|	|___ info-boxes
	|	|___ loader
	|	|___ demo-option
	|
	|
	|
	|___Portfolio Styles
	|	|___ portfolio-grid 
	|	|___ portfolio-grid-metro 
	|	|___ portfolio-caption
	|	|___ mesonry-button
	|	|___ paralax
	|	|___ has-center
	|	|___ type-hover
	|	|___ portfolio-details
	|
	|
	|
	|___Blog Grid Styles
	|	|___ blod-grid 
	|	|___ pagination 
	|	|___ blog-mesonry
	|	|___ blog-grid-no-flex
	|	|___ blog-grid-minimal
	|	|___ blog-details
	|	|___ blog-sidebar
	|
	|
	|
	|___Shop Sidebar Styles
	|	|___ shop-sidebar 
	|	|___ single-product 
	|	|___ cart
	|	|___ checkout
	|	|___ compare
	|	|___ my-account
	|	|___ vertical-slide
	|	|___ login
	|	|___ table-form
	|
	|
	|
	|
	|___Template Styles
	|	|___ header
	|	|___ sidebar-header
	|	|___ navigation
	|	|___ banner
	|	|___ feature
	|	|___ startup-service
	|	|___ freelancer
	|	|___ hamber-menu
	|	|___ left-vertical-header
	|	|___ fullscreen-slider
	|	|___ sidebar-mobilemenu
	|	|___ header-broadsheets
	|	|___ onepage
	|	|___ foodie
	|	|___ portfolio-slide
	|	|___ photo-slide
	|	|___ vertical-slide
	|	|___ musician
	|	|___ vertical-slider-portfolio
	|	|___ shop-slide
	|	|___ product
	|	|___ revulution
	|	|___ presentation
	|	|___ footer
	|	 
	|	
	|	|___ Color Variation
	|	|
	|	|___ color-2
	|	|___ font-variation
	|	|___ preview
	|	|___ responsive
	|
	|___ END STYLESHEET INDEXING

***************************************************************/
/*===================================
    Default Styles
====================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i,700,700i,800,900|Permanent+Marker|Playfair+Display:400,400i,700,700i,900,900i|Montserrat:300,300i,400,400i,500,500i,600,700,700i,800|Open+Sans:400|Roboto:400,400i,500,500i,700,700i,900|Reenie+Beanie");
@import url("https://fonts.googleapis.com/css?family=Spectral:600i&display=swap");
.color-dark {
  color: #222222 !important; }

/*================================================ 
 font-family: 'Poppins', sans-serif;
 font-family: 'Permanent Marker', cursive;
 font-family: 'Playfair Display', serif;
 font-family: 'Montserrat', sans-serif;
  font-family: 'Roboto', sans-serif;
 =================================================*/
@media only screen and (max-width: 575px) {
  .container {
    max-width: 450px; } }

@media only screen and (max-width: 479px) {
  .container {
    max-width: 320px; } }

/**
 * Reset Styels
 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html,
button,
input,
select,
textarea {
  font-family: "Source Sans Pro", Helvetica, sans-serif; }

a {
  color: #ca3c08;
  text-decoration: none; }

a:visited {
  color: #ac0404; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  color: #ea9629;
  outline: 0; }

a:hover {
  text-decoration: underline; }

address {
  font-style: italic;
  margin: 0 0 24px; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

p {
  margin: 0 0 24px; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 14px;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre {
  background: #f5f5f5;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

blockquote,
q {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

blockquote {
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  margin: 24px 40px; }

blockquote blockquote {
  margin-right: 0; }

blockquote cite,
blockquote small {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase; }

blockquote em,
blockquote i {
  font-style: normal;
  font-weight: 300; }

blockquote strong,
blockquote b {
  font-weight: 400; }

small {
  font-size: smaller; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dl {
  margin: 0 20px; }

dt {
  font-weight: bold; }

dd {
  margin: 0 0 20px; }

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px; }

ul {
  list-style-type: square; }

nav ul,
nav ol {
  list-style: none;
  list-style-image: none; }

li > ul,
li > ol {
  margin: 0; }

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline; }

button,
input {
  line-height: normal; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  /* Don't cut off the webkit search cancel button */
  width: 270px; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  line-height: 2;
  margin: 0 0 20px;
  width: 100%; }

caption,
th,
td {
  font-weight: normal;
  text-align: left; }

caption {
  font-size: 16px;
  margin: 20px 0; }

th {
  font-weight: bold;
  text-transform: uppercase; }

td {
  border-top: 1px solid #ededed;
  padding: 6px 10px 6px 0; }

del {
  color: #333; }

ins {
  background: #fff9c0;
  text-decoration: none; }

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px; }

/**
 * Typography
 */
* {
  box-sizing: border-box; }

img {
  max-width: 100%; }

html {
  overflow: hidden;
  overflow-y: auto; }

body {
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  color: #333333;
  font-weight: 400; }

a {
  transition: all 0.4s ease-in-out 0s; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
  margin-bottom: 15px; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "louis_george_caferegular";
  line-height: 1.3;
  color: #222222; }

h1,
.h1 {
  font-size: 42px; }

h2,
.h2 {
  font-size: 30px; }

h3,
.h3 {
  font-size: 26px; }

h4,
.h4 {
  font-size: 22px; }

h5,
.h5 {
  font-size: 18px; }

h6,
.h6 {
  font-size: 14px; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 38px; }
  h2,
  .h2 {
    font-size: 28px; }
  h3,
  .h3 {
    font-size: 24px; } }

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 36px; }
  h2,
  .h2 {
    font-size: 26px; }
  h3,
  .h3 {
    font-size: 24px; } }

@media only screen and (max-width: 575px) {
  h1,
  .h1 {
    font-size: 34px; } }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 700; }

h4,
.h4,
h5,
.h5 {
  font-weight: 600; }

h6,
.h6 {
  font-weight: 500; }

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none; }

a:visited {
  color: inherit; }

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid #eee;
  transition: all 0.4s ease-out 0s;
  color: #333333; }
  input:focus, input:active,
  button:focus,
  button:active,
  select:focus,
  select:active,
  textarea:focus,
  textarea:active {
    outline: none;
    border-color: #0038E3; }

input,
select,
textarea {
  width: 100%;
  font-size: 14px; }

input,
select {
  height: 40px;
  padding: 0 15px; }

/*==========================
Animation Css
============================*/
/* -----------------------------------
    Custom Animation For All Page
---------------------------------------*/
@-webkit-keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes moveVertical {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    opacity: 1; } }

@keyframes moveHorizontal {
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

.move-up {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px); }
  .move-up.animate {
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-animation: moveVertical .65s ease forwards;
    animation: moveVertical .65s ease forwards; }

.move-up-x {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px); }
  .move-up-x.animate {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-animation: moveHorizontal .65s ease forwards;
    animation: moveHorizontal .65s ease forwards; }

/* ------------------------
    Custom Animation 01 
----------------------------*/
@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -100px; }
  to {
    margin-top: 0; } }

@keyframes headerSlideDown {
  0% {
    margin-top: -100px; }
  to {
    margin-top: 0; } }

/* --------------------------------------
    Custom Hide Animation
---------------------------------------*/
@-webkit-keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(60px);
    opacity: 0; } }

@keyframes hide-animation {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0; } }

/* --------------------------------------
    Custom Slide Show Animation
---------------------------------------*/
@-webkit-keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    opacity: 1; } }

@keyframes show-animation {
  0% {
    -webkit-transform: translateY(60px);
    transform: translateY(60px);
    opacity: 0; }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

/* --------------------------------------
    Custom Slide Out To Right
---------------------------------------*/
@-webkit-keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(40px);
    opacity: 0; } }

@keyframes slide-out-to-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform: translateX(60px);
    transform: translateX(60px);
    opacity: 0; } }

/* --------------------------------------
    Custom Slide Out To Left
---------------------------------------*/
@-webkit-keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(60px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-to-left {
  0% {
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
    opacity: 0; }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

/* ------------------------
    Brook Hover Effect 
---------------------------*/
.brook-transition {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.bk-hover a {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  color: #999; }
  .bk-hover a::after {
    content: "";
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: currentColor; }
  .bk-hover a:hover::after {
    width: 100%;
    left: 0;
    right: auto; }

/* ------------------------
    Separator Bounce 
---------------------------*/
@-webkit-keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes separator-bounce {
  0%,
  80%,
  to {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/**
 * Utilities
 */
/* Font Family */
/*================================================
 font-family: 'Poppins', sans-serif;
 font-family: 'Permanent Marker', cursive;
 font-family: 'Playfair Display', serif;
 font-family: 'Montserrat', sans-serif;
 font-family: 'Open Sans', sans-serif;
 font-family: 'Roboto', sans-serif;
 font-family: 'Reenie Beanie', cursive;
 font-family: 'CerebriSans';

 =================================================*/
.hidden {
  display: none; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.fix {
  overflow: hidden; }

.poss_relative {
  position: relative; }

.poss_absolute {
  position: absolute; }

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

.wrapper {
  position: relative; }

/* Define Colors */
.theme-color {
  color: #0038E3; }

.secondary-color {
  color: #FF5EE1; }

.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

.color-light {
  color: #999999; }

.body-color {
  color: #999999; }

.color-dark {
  color: #333333; }

.color-black {
  color: #000000; }

.white {
  color: #fff; }

.secondary-color {
  color: #FF5EE1; }

.creative-color {
  color: #FE378C; }

.red-color {
  color: #F0263F; }

.red-color-2 {
  color: #F23E20; }

.yellow-color {
  color: #FCB72B; }

.yellow-color-2 {
  color: #F8A440; }

.text-color-1 {
  color: #7ed321 !important; }

.text-color-2 {
  color: #f55d4e !important; }

.text-color-3 {
  color: #4a90e2 !important; }

.text-color-4 {
  color: #f2b636 !important; }

.pink {
  color: #F12C6E; }

.pink {
  color: #F12C6E; }

.brown-color {
  color: #CE8F4F; }

.third-color {
  color: #99E5E8; }

.tarkis-color {
  color: #19D2A8; }

.theme-color-2 {
  color: #0069FF; }

.brown-2 {
  color: #F1C078; }

.brown-3 {
  color: #CAC0B3; }

.purple-color-2 {
  color: #AC61EE; }

.secondary-font {
  font-family: "Montserrat", sans-serif; }

.primary-font {
  font-family: "Poppins", sans-serif; }

.heading-font {
  font-family: "louis_george_caferegular"; }

.playfair-font {
  font-family: "Playfair Display", serif; }

.spectral-font {
  font-family: "Spectral", serif; }

.roboto-font {
  font-family: "Roboto", sans-serif; }

.CerebriSans-font {
  font-family: "CerebriSans";
  color: #777; }

/* Font Weight */
.fw-600 {
  font-weight: 600 !important; }

.fw-500 {
  font-weight: 500 !important; }

.fw-400 {
  font-weight: 400 !important; }

.fw-200 {
  font-weight: 200 !important; }

.w-150 {
  width: 150px; }

/* Background Color */
.bg_color--1 {
  background: #fff; }

.bg_color--2 {
  background: #0038E3; }

.bg_color--3 {
  background: #000000; }

.bg_color--4 {
  background: #111; }

.bg_color--5 {
  background: #f5f5f5; }

.bg_color--6 {
  background: #ecf3ff; }

.bg_color--7 {
  background: #222; }

.bg_color--8 {
  background: #eee; }

.bg_color--9 {
  background: #69afed; }

.bg_color--10 {
  background: #f9f9f9; }

.bg_color--11 {
  background: #001029; }

.bg_color--12 {
  background: #FCB72B; }

.bg_color--13 {
  background: #F8A440; }

.bg_color--14 {
  background: #f7f1ec; }

.bg_color--15 {
  background: #f7f7f7; }

.bg_color--16 {
  background: #f4efff; }

.bg_color--17 {
  background: #0069FF; }

.bg_color--18 {
  background: #000; }

.bg_color--19 {
  background: #AC61EE; }

.bg_color--20 {
  background: #F55D4E; }

.bg_color--21 {
  background: #f5f3ef; }

.bg_color--22 {
  background: #f10; }

.bg_color--23 {
  background: #FBFBFD; }

.bg_color--24 {
  background: #68AE4A; }

.bg_color--25 {
  background: #BC9464; }

.bg_color--26 {
  background: #7BA100; }

.bg_color--27 {
  background: #14359E; }

.bg_color--28 {
  background: #CC327B; }

.bg_color--29 {
  background: #FFE2D9; }

.bg_color--30 {
  background: #906032; }

.bg_color--31 {
  background: #EC5B4B; }

.bg_color--32 {
  background: #FFF3F2; }

.bg_color--33 {
  background: #C1B538; }

.bg_color--34 {
  background: #CAE0E5; }

.bg_color--35 {
  background: #126CDC; }

.bg_color--36 {
  background: #040C33; }

.bg_color--37 {
  background: #F50809; }

.bg_color--38 {
  background: #292930; }

.bg_color--39 {
  background: #4BE472; }

.bg_color--40 {
  background: #DA9D55; }

.bg_color--41 {
  background: #3264F5; }

.bg_color--42 {
  background: #121618; }

.bg_color--43 {
  background: #FFC851; }

.bg_color--44 {
  background: #C4DCFA; }

.bg-as-text {
  background-image: url(/img/icons/text.png);
  background-repeat: no-repeat;
  background-position: right center; }
  @media only screen and (max-width: 767px) {
    .bg-as-text {
      background-size: 554px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bg-as-text {
      background-size: 750px; } }

.bg-as-text2 {
  background-image: url(/img/icons/text2.png);
  background-repeat: no-repeat;
  background-position: center center; }

/* Youtube Video Background */
.background-video-holder {
  position: relative; }

.youtube-video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0 !important;
  left: 0;
  z-index: -1; }

.youtube-bg {
  display: block;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); }

/* Background Image */
.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.bg_image--1 {
  background-image: url(/img/bg/bg-image-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--2 {
  background-image: url(/img/bg/bg-image-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--3 {
  background-image: url(/img/bg/bg-image-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--4 {
  background-image: url(/img/bg/bg-image-4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--5 {
  background-image: url(/img/bg/bg-image-5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--6 {
  background-image: url(/img/bg/bg-image-6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--7 {
  background-image: url(/img/bg/bg-image-7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--8 {
  background-image: url(/img/bg/bg-image-8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--9 {
  background-image: url(/img/bg/bg-image-9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--10 {
  background-image: url(/img/bg/bg-image-10.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--11 {
  background-image: url(/img/bg/bg-image-11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--12 {
  background-image: url(/img/bg/bg-image-12.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--13 {
  background-image: url(/img/bg/bg-image-13.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--14 {
  background-image: url(/img/bg/bg-image-14.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--15 {
  background-image: url(/img/bg/bg-image-15.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--16 {
  background-image: url(/img/bg/bg-image-16.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--17 {
  background-image: url(/img/bg/bg-image-17.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--18 {
  background-image: url(/img/bg/bg-image-18.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--19 {
  background-image: url(/img/bg/bg-image-19.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--20 {
  background-image: url(/img/bg/bg-image-20.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--21 {
  background-image: url(/img/bg/bg-image-21.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--22 {
  background-image: url(/img/bg/bg-image-22.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--23 {
  background-image: url(/img/bg/bg-image-23.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--24 {
  background-image: url(/img/bg/bg-image-24.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--25 {
  background-image: url(/img/bg/bg-image-25.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--26 {
  background-image: url(/img/bg/bg-image-26.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--27 {
  background-image: url(/img/bg/bg-image-27.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--28 {
  background-image: url(/img/bg/bg-image-28.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--29 {
  background-image: url(/img/bg/bg-image-29.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--30 {
  background-image: url(/img/bg/bg-image-30.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--31 {
  background-image: url(/img/bg/bg-image-31.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--32 {
  background-image: url(/img/bg/bg-image-32.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--33 {
  background-image: url(/img/bg/bg-image-33.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--34 {
  background-image: url(/img/bg/bg-image-34.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--35 {
  background-image: url(/img/bg/bg-image-35.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--36 {
  background-image: url(/img/bg/bg-image-36.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--37 {
  background-image: url(/img/bg/bg-image-37.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--38 {
  background-image: url(/img/bg/bg-image-38.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--39 {
  background-image: url(/img/bg/bg-image-39.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--40 {
  background-image: url(/img/bg/bg-image-40.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--41 {
  background-image: url(/img/bg/bg-image-41.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--42 {
  background-image: url(/img/bg/bg-image-42.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--43 {
  background-image: url(/img/bg/bg-image-43.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--44 {
  background-image: url(/img/bg/bg-image-44.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--45 {
  background-image: url(/img/bg/bg-image-45.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--46 {
  background-image: url(/img/bg/bg-image-46.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--47 {
  background-image: url(/img/bg/bg-image-47.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--48 {
  background-image: url(/img/bg/bg-image-48.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--49 {
  background-image: url(/img/bg/bg-image-49.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--50 {
  background-image: url(/img/bg/bg-image-50.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--51 {
  background-image: url(/img/bg/bg-image-51.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--52 {
  background-image: url(/img/bg/bg-image-52.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--53 {
  background-image: url(/img/bg/bg-image-53.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--54 {
  background-image: url(/img/bg/bg-image-54.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--55 {
  background-image: url(/img/bg/bg-image-55.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--56 {
  background-image: url(/img/bg/bg-image-56.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--57 {
  background-image: url(/img/bg/bg-image-57.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--58 {
  background-image: url(/img/bg/bg-image-58.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--59 {
  background-image: url(/img/bg/bg-image-59.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--60 {
  background-image: url(/img/bg/bg-image-60.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--61 {
  background-image: url(/img/bg/bg-image-61.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--62 {
  background-image: url(/img/bg/bg-image-62.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--63 {
  background-image: url(/img/bg/bg-image-63.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--64 {
  background-image: url(/img/bg/bg-image-64.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--65 {
  background-image: url(/img/bg/bg-image-65.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--66 {
  background-image: url(/img/bg/bg-image-66.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--67 {
  background-image: url(/img/bg/bg-image-67.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--68 {
  background-image: url(/img/bg/bg-image-68.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--69 {
  background-image: url(/img/bg/bg-image-69.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--70 {
  background-image: url(/img/bg/bg-image-70.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--71 {
  background-image: url(/img/bg/bg-image-71.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--72 {
  background-image: url(/img/bg/bg-image-72.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--73 {
  background-image: url(/img/bg/bg-image-73.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--74 {
  background-image: url(/img/bg/bg-image-74.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--75 {
  background-image: url(/img/bg/bg-image-75.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--76 {
  background-image: url(/img/bg/bg-image-76.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--77 {
  background-image: url(/img/bg/bg-image-77.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--78 {
  background-image: url(/img/bg/bg-image-78.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--79 {
  background-image: url(/img/bg/bg-image-79.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--80 {
  background-image: url(/img/bg/bg-image-80.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--81 {
  background-image: url(/img/bg/bg-image-81.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--82 {
  background-image: url(/img/bg/bg-image-82.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--83 {
  background-image: url(/img/bg/bg-image-83.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--84 {
  background-image: url(/img/bg/bg-image-84.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--85 {
  background-image: url(/img/bg/bg-image-85.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--86 {
  background-image: url(/img/bg/bg-image-86.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--87 {
  background-image: url(/img/bg/bg-image-87.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--88 {
  background-image: url(/img/bg/bg-image-88.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--89 {
  background-image: url(/img/bg/bg-image-89.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--90 {
  background-image: url(/img/bg/bg-image-90.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--91 {
  background-image: url(/img/bg/bg-image-91.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--92 {
  background-image: url(/img/bg/bg-image-92.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--93 {
  background-image: url(/img/bg/bg-image-93.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--94 {
  background-image: url(/img/bg/bg-image-94.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--95 {
  background-image: url(/img/bg/bg-image-95.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--96 {
  background-image: url(/img/bg/bg-image-96.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--97 {
  background-image: url(/img/bg/bg-image-97.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--98 {
  background-image: url(/img/bg/bg-image-98.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--99 {
  background-image: url(/img/bg/bg-image-99.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--100 {
  background-image: url(/img/bg/bg-image-100.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--101 {
  background-image: url(/img/bg/bg-image-101.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--102 {
  background-image: url(/img/bg/bg-image-102.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--103 {
  background-image: url(/img/bg/bg-image-103.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--104 {
  background-image: url(/img/bg/bg-image-104.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--105 {
  background-image: url(/img/bg/bg-image-105.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--106 {
  background-image: url(/img/bg/bg-image-106.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--107 {
  background-image: url(/img/bg/bg-image-107.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--108 {
  background-image: url(/img/bg/bg-image-108.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--109 {
  background-image: url(/img/bg/bg-image-109.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--110 {
  background-image: url(/img/bg/bg-image-110.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--111 {
  background-image: url(/img/bg/bg-image-111.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--112 {
  background-image: url(/img/bg/bg-image-112.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--113 {
  background-image: url(/img/bg/bg-image-113.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--114 {
  background-image: url(/img/bg/bg-image-114.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--115 {
  background-image: url(/img/bg/bg-image-115.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--116 {
  background-image: url(/img/bg/bg-image-116.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--117 {
  background-image: url(/img/bg/bg-image-117.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--118 {
  background-image: url(/img/bg/bg-image-118.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--119 {
  background-image: url(/img/bg/bg-image-119.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--120 {
  background-image: url(/img/bg/bg-image-120.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--121 {
  background-image: url(/img/bg/bg-image-121.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--122 {
  background-image: url(/img/bg/bg-image-122.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--123 {
  background-image: url(/img/bg/bg-image-123.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--124 {
  background-image: url(/img/bg/bg-image-124.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--125 {
  background-image: url(/img/bg/bg-image-125.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--126 {
  background-image: url(/img/bg/bg-image-126.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--127 {
  background-image: url(/img/bg/bg-image-127.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--128 {
  background-image: url(/img/bg/bg-image-128.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--129 {
  background-image: url(/img/bg/bg-image-129.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--130 {
  background-image: url(/img/bg/bg-image-130.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--131 {
  background-image: url(/img/bg/bg-image-131.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--132 {
  background-image: url(/img/bg/bg-image-132.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--133 {
  background-image: url(/img/bg/bg-image-133.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--134 {
  background-image: url(/img/bg/bg-image-134.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--135 {
  background-image: url(/img/bg/bg-image-135.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--136 {
  background-image: url(/img/bg/bg-image-136.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--137 {
  background-image: url(/img/bg/bg-image-137.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--138 {
  background-image: url(/img/bg/bg-image-138.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--139 {
  background-image: url(/img/bg/bg-image-139.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--140 {
  background-image: url(/img/bg/bg-image-140.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.left-bg-image {
  background-image: url(/img/restaurant/home-restaurant-menus-lunch-set.jpg);
  background-repeat: no-repeat; }

.right-bg-image {
  background-image: url(/img/restaurant/home-restaurant-menus-starters-set.jpg);
  background-repeat: no-repeat;
  background-position: top right; }

.blog-bg-image {
  background-image: url(/img/bg/bg-image-63.jpg);
  background-repeat: repeat; }

.bg-image-business {
  background-image: url(/img/bg/bg-image-64.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg-image-slider {
  background-image: url(/img/business-2/home-business-02-hero-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-auto {
    padding: 100px 0;
    height: auto !important; } }

@media only screen and (max-width: 767px) {
  .height-auto {
    padding: 0px 0;
    height: auto !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .height-auto .fp-tableCell {
    height: auto !important; } }

@media only screen and (max-width: 767px) {
  .height-auto .fp-tableCell {
    height: auto !important;
    padding: 80px 0; } }

/* This color is default color. It will change depending your body template color class like .template-color-1 to 11  */
.text-color {
  color: #0038E3; }

body.template-color-1 .text-color {
  color: #0038E3; }

body.template-color-2 .text-color {
  color: #FE378C; }

body.template-color-3 .text-color {
  color: #F0263F; }

body.template-color-4 .text-color {
  color: #FCB72B; }

body.template-color-5 .text-color {
  color: #F8A440; }

body.template-color-6 .text-color {
  color: #F05874; }

body.template-color-7 .text-color {
  color: #99E5E8; }

body.template-color-8 .text-color {
  color: #82CECF; }

body.template-color-9 .text-color {
  color: #F0263F; }

body.template-color-10 .text-color {
  color: #19d2a8; }

body.template-color-11 .text-color {
  color: #0069FF; }

body.template-color-12 .text-color {
  color: #F1C078; }

body.template-color-13 .text-color {
  color: #CAC0B3; }

body.template-color-14 .text-color {
  color: #F55D4E; }

body.template-color-15 .text-color {
  color: #AC61EE; }

body.template-color-16 .text-color {
  color: #F5A623; }

body.template-color-17 .text-color {
  color: #B013FE; }

body.template-color-18 .text-color {
  color: #899664; }

body.template-color-19 .text-color {
  color: #F12C6E; }

body.template-color-20 .text-color {
  color: #CE8F4F; }

body.template-color-21 .text-color {
  color: #FB6031; }

body.template-color-22 .text-color {
  color: #A810E0; }

body.template-color-23 .text-color {
  color: #68AE4A; }

body.template-color-24 .text-color {
  color: #BC9464; }

body.template-color-25 .text-color {
  color: #9F3939; }

body.template-color-26 .text-color {
  color: #7BA100; }

body.template-color-27 .text-color {
  color: #14359E; }

body.template-color-28 .text-color {
  color: #CC327B; }

body.template-color-29 .text-color {
  color: #E23636; }

body.template-color-30 .text-color {
  color: #FB6031; }

body.template-color-31 .text-color {
  color: #906032; }

body.template-color-32 .text-color {
  color: #EC5B4B; }

body.template-color-33 .text-color {
  color: #C1B538; }

body.template-color-34 .text-color {
  color: #126CDC; }

body.template-color-35 .text-color {
  color: #F50809; }

body.template-color-36 .text-color {
  color: #4BE472; }

body.template-color-37 .text-color {
  color: #DA9D55; }

body.template-color-38 .text-color {
  color: #3264F5; }

body.template-color-39 .text-color {
  color: #FFC851; }

body.template-color-40 .text-color {
  color: #C4DCFA; }

body.template-color-41 .text-color {
  color: #7F48B0; }

/*-----------------------
    Slick Gutter
----------------------------*/
.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .slick-gutter-5 .slick-slide {
    padding-left: 5px;
    padding-right: 5px; }

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px; }
  .slick-gutter-10 .slick-slide {
    padding-left: 10px;
    padding-right: 10px; }

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px; }
  .slick-gutter-15 .slick-slide {
    padding-left: 15px;
    padding-right: 15px; }

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px; }
  .slick-gutter-20 .slick-slide {
    padding-left: 20px;
    padding-right: 20px; }

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px; }
  .slick-gutter-25 .slick-slide {
    padding-left: 25px;
    padding-right: 25px; }

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px; }
  .slick-gutter-30 .slick-slide {
    padding-left: 30px;
    padding-right: 30px; }

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px; }
  .slick-gutter-35 .slick-slide {
    padding-left: 35px;
    padding-right: 35px; }

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px; }
  .slick-gutter-40 .slick-slide {
    padding-left: 40px;
    padding-right: 40px; }

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px; }
  .slick-gutter-45 .slick-slide {
    padding-left: 45px;
    padding-right: 45px; }

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px; }
  .slick-gutter-50 .slick-slide {
    padding-left: 50px;
    padding-right: 50px; }

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px; }
  .slick-gutter-55 .slick-slide {
    padding-left: 55px;
    padding-right: 55px; }

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px; }
  .slick-gutter-60 .slick-slide {
    padding-left: 60px;
    padding-right: 60px; }

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px; }
  .slick-gutter-65 .slick-slide {
    padding-left: 65px;
    padding-right: 65px; }

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px; }
  .slick-gutter-70 .slick-slide {
    padding-left: 70px;
    padding-right: 70px; }

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px; }
  .slick-gutter-75 .slick-slide {
    padding-left: 75px;
    padding-right: 75px; }

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px; }
  .slick-gutter-80 .slick-slide {
    padding-left: 80px;
    padding-right: 80px; }

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px; }
  .slick-gutter-85 .slick-slide {
    padding-left: 85px;
    padding-right: 85px; }

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px; }
  .slick-gutter-90 .slick-slide {
    padding-left: 90px;
    padding-right: 90px; }

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px; }
  .slick-gutter-95 .slick-slide {
    padding-left: 95px;
    padding-right: 95px; }

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px; }
  .slick-gutter-100 .slick-slide {
    padding-left: 100px;
    padding-right: 100px; }

/*-- Padding Top --*/
.pt--5 {
  padding-top: 5px; }

.pt--10 {
  padding-top: 10px; }

.pt--15 {
  padding-top: 15px; }

.pt--20 {
  padding-top: 20px; }

.pt--25 {
  padding-top: 25px; }

.pt--30 {
  padding-top: 30px; }

.pt--35 {
  padding-top: 35px; }

.pt--40 {
  padding-top: 40px; }

.pt--45 {
  padding-top: 45px; }

.pt--50 {
  padding-top: 50px; }

.pt--55 {
  padding-top: 55px; }

.pt--60 {
  padding-top: 60px; }

.pt--65 {
  padding-top: 65px; }

.pt--70 {
  padding-top: 70px; }

.pt--75 {
  padding-top: 75px; }

.pt--80 {
  padding-top: 80px; }

.pt--85 {
  padding-top: 85px; }

.pt--90 {
  padding-top: 90px; }

.pt--95 {
  padding-top: 95px; }

.pt--100 {
  padding-top: 100px; }

.pt--105 {
  padding-top: 105px; }

.pt--110 {
  padding-top: 110px; }

.pt--115 {
  padding-top: 115px; }

.pt--120 {
  padding-top: 120px; }

.pt--125 {
  padding-top: 125px; }

.pt--130 {
  padding-top: 130px; }

.pt--135 {
  padding-top: 135px; }

.pt--140 {
  padding-top: 140px; }

.pt--145 {
  padding-top: 145px; }

.pt--150 {
  padding-top: 150px; }

.pt--155 {
  padding-top: 155px; }

.pt--160 {
  padding-top: 160px; }

.pt--165 {
  padding-top: 165px; }

.pt--170 {
  padding-top: 170px; }

.pt--175 {
  padding-top: 175px; }

.pt--180 {
  padding-top: 180px; }

.pt--185 {
  padding-top: 185px; }

.pt--190 {
  padding-top: 190px; }

.pt--195 {
  padding-top: 195px; }

.pt--200 {
  padding-top: 200px; }

.pt--205 {
  padding-top: 205px; }

.pt--210 {
  padding-top: 210px; }

.pt--215 {
  padding-top: 215px; }

.pt--220 {
  padding-top: 220px; }

.pt--225 {
  padding-top: 225px; }

.pt--230 {
  padding-top: 230px; }

.pt--235 {
  padding-top: 235px; }

.pt--240 {
  padding-top: 240px; }

.pt--245 {
  padding-top: 245px; }

.pt--250 {
  padding-top: 250px; }

.pt--255 {
  padding-top: 255px; }

.pt--260 {
  padding-top: 260px; }

.pt--265 {
  padding-top: 265px; }

.pt--270 {
  padding-top: 270px; }

.pt--275 {
  padding-top: 275px; }

.pt--280 {
  padding-top: 280px; }

.pt--285 {
  padding-top: 285px; }

.pt--290 {
  padding-top: 290px; }

.pt--295 {
  padding-top: 295px; }

.pt--300 {
  padding-top: 300px; }

.pt--305 {
  padding-top: 305px; }

.pt--310 {
  padding-top: 310px; }

.pt--315 {
  padding-top: 315px; }

.pt--320 {
  padding-top: 320px; }

.pt--325 {
  padding-top: 325px; }

.pt--330 {
  padding-top: 330px; }

.pt--335 {
  padding-top: 335px; }

.pt--340 {
  padding-top: 340px; }

.pt--345 {
  padding-top: 345px; }

.pt--350 {
  padding-top: 350px; }

.pt--355 {
  padding-top: 355px; }

.pt--360 {
  padding-top: 360px; }

.pt--365 {
  padding-top: 365px; }

.pt--370 {
  padding-top: 370px; }

.pt--375 {
  padding-top: 375px; }

.pt--380 {
  padding-top: 380px; }

.pt--385 {
  padding-top: 385px; }

.pt--390 {
  padding-top: 390px; }

.pt--395 {
  padding-top: 395px; }

.pt--400 {
  padding-top: 400px; }

@media only screen and (max-width: 575px) {
  .pt_sm--5 {
    padding-top: 5px; }
  .pt_sm--10 {
    padding-top: 10px; }
  .pt_sm--15 {
    padding-top: 15px; }
  .pt_sm--20 {
    padding-top: 20px; }
  .pt_sm--25 {
    padding-top: 25px; }
  .pt_sm--30 {
    padding-top: 30px; }
  .pt_sm--35 {
    padding-top: 35px; }
  .pt_sm--40 {
    padding-top: 40px; }
  .pt_sm--45 {
    padding-top: 45px; }
  .pt_sm--50 {
    padding-top: 50px; } }

/*-- Padding Bottom --*/
.pb--5 {
  padding-bottom: 5px; }

.pb--10 {
  padding-bottom: 10px; }

.pb--15 {
  padding-bottom: 15px; }

.pb--20 {
  padding-bottom: 20px; }

.pb--25 {
  padding-bottom: 25px; }

.pb--30 {
  padding-bottom: 30px; }

.pb--35 {
  padding-bottom: 35px; }

.pb--40 {
  padding-bottom: 40px; }

.pb--45 {
  padding-bottom: 45px; }

.pb--50 {
  padding-bottom: 50px; }

.pb--55 {
  padding-bottom: 55px; }

.pb--60 {
  padding-bottom: 60px; }

.pb--65 {
  padding-bottom: 65px; }

.pb--70 {
  padding-bottom: 70px; }

.pb--75 {
  padding-bottom: 75px; }

.pb--80 {
  padding-bottom: 80px; }

.pb--85 {
  padding-bottom: 85px; }

.pb--90 {
  padding-bottom: 90px; }

.pb--95 {
  padding-bottom: 95px; }

.pb--100 {
  padding-bottom: 100px; }

.pb--105 {
  padding-bottom: 105px; }

.pb--110 {
  padding-bottom: 110px; }

.pb--115 {
  padding-bottom: 115px; }

.pb--120 {
  padding-bottom: 120px; }

.pb--125 {
  padding-bottom: 125px; }

.pb--130 {
  padding-bottom: 130px; }

.pb--135 {
  padding-bottom: 135px; }

.pb--140 {
  padding-bottom: 140px; }

.pb--145 {
  padding-bottom: 145px; }

.pb--150 {
  padding-bottom: 150px; }

.pb--155 {
  padding-bottom: 155px; }

.pb--160 {
  padding-bottom: 160px; }

.pb--165 {
  padding-bottom: 165px; }

.pb--170 {
  padding-bottom: 170px; }

.pb--175 {
  padding-bottom: 175px; }

.pb--180 {
  padding-bottom: 180px; }

.pb--185 {
  padding-bottom: 185px; }

.pb--190 {
  padding-bottom: 190px; }

.pb--195 {
  padding-bottom: 195px; }

.pb--200 {
  padding-bottom: 200px; }

.pb--205 {
  padding-bottom: 205px; }

.pb--210 {
  padding-bottom: 210px; }

.pb--215 {
  padding-bottom: 215px; }

.pb--220 {
  padding-bottom: 220px; }

.pb--225 {
  padding-bottom: 225px; }

.pb--230 {
  padding-bottom: 230px; }

.pb--235 {
  padding-bottom: 235px; }

.pb--240 {
  padding-bottom: 240px; }

.pb--245 {
  padding-bottom: 245px; }

.pb--250 {
  padding-bottom: 250px; }

.pb--255 {
  padding-bottom: 255px; }

.pb--260 {
  padding-bottom: 260px; }

.pb--265 {
  padding-bottom: 265px; }

.pb--270 {
  padding-bottom: 270px; }

.pb--275 {
  padding-bottom: 275px; }

.pb--280 {
  padding-bottom: 280px; }

.pb--285 {
  padding-bottom: 285px; }

.pb--290 {
  padding-bottom: 290px; }

.pb--295 {
  padding-bottom: 295px; }

.pb--300 {
  padding-bottom: 300px; }

.pb--305 {
  padding-bottom: 305px; }

.pb--310 {
  padding-bottom: 310px; }

.pb--315 {
  padding-bottom: 315px; }

.pb--320 {
  padding-bottom: 320px; }

.pb--325 {
  padding-bottom: 325px; }

.pb--330 {
  padding-bottom: 330px; }

.pb--335 {
  padding-bottom: 335px; }

.pb--340 {
  padding-bottom: 340px; }

.pb--345 {
  padding-bottom: 345px; }

.pb--350 {
  padding-bottom: 350px; }

/*-- Padding Left --*/
.pl--5 {
  padding-left: 5px; }

.pl--10 {
  padding-left: 10px; }

.pl--15 {
  padding-left: 15px; }

.pl--20 {
  padding-left: 20px; }

.pl--25 {
  padding-left: 25px; }

.pl--30 {
  padding-left: 30px; }

.pl--35 {
  padding-left: 35px; }

.pl--40 {
  padding-left: 40px; }

.pl--45 {
  padding-left: 45px; }

.pl--50 {
  padding-left: 50px; }

.pl--55 {
  padding-left: 55px; }

.pl--60 {
  padding-left: 60px; }

.pl--65 {
  padding-left: 65px; }

.pl--70 {
  padding-left: 70px; }

.pl--75 {
  padding-left: 75px; }

.pl--80 {
  padding-left: 80px; }

.pl--85 {
  padding-left: 85px; }

.pl--90 {
  padding-left: 90px; }

.pl--95 {
  padding-left: 95px; }

.pl--100 {
  padding-left: 100px; }

.pl--105 {
  padding-left: 105px; }

.pl--110 {
  padding-left: 110px; }

.pl--115 {
  padding-left: 115px; }

.pl--120 {
  padding-left: 120px; }

.pl--125 {
  padding-left: 125px; }

.pl--130 {
  padding-left: 130px; }

.pl--135 {
  padding-left: 135px; }

.pl--140 {
  padding-left: 140px; }

.pl--145 {
  padding-left: 145px; }

.pl--150 {
  padding-left: 150px; }

.pl--155 {
  padding-left: 155px; }

.pl--160 {
  padding-left: 160px; }

.pl--165 {
  padding-left: 165px; }

.pl--170 {
  padding-left: 170px; }

.pl--175 {
  padding-left: 175px; }

.pl--180 {
  padding-left: 180px; }

.pl--185 {
  padding-left: 185px; }

.pl--190 {
  padding-left: 190px; }

.pl--195 {
  padding-left: 195px; }

.pl--200 {
  padding-left: 200px; }

.pl--205 {
  padding-left: 205px; }

.pl--210 {
  padding-left: 210px; }

.pl--215 {
  padding-left: 215px; }

.pl--220 {
  padding-left: 220px; }

.pl--225 {
  padding-left: 225px; }

.pl--230 {
  padding-left: 230px; }

.pl--235 {
  padding-left: 235px; }

.pl--240 {
  padding-left: 240px; }

.pl--245 {
  padding-left: 245px; }

.pl--250 {
  padding-left: 250px; }

.pl--255 {
  padding-left: 255px; }

.pl--260 {
  padding-left: 260px; }

.pl--265 {
  padding-left: 265px; }

.pl--270 {
  padding-left: 270px; }

.pl--275 {
  padding-left: 275px; }

.pl--280 {
  padding-left: 280px; }

.pl--285 {
  padding-left: 285px; }

.pl--290 {
  padding-left: 290px; }

.pl--295 {
  padding-left: 295px; }

.pl--300 {
  padding-left: 300px; }

.pl--305 {
  padding-left: 305px; }

.pl--310 {
  padding-left: 310px; }

.pl--315 {
  padding-left: 315px; }

.pl--320 {
  padding-left: 320px; }

.pl--325 {
  padding-left: 325px; }

.pl--330 {
  padding-left: 330px; }

.pl--335 {
  padding-left: 335px; }

.pl--340 {
  padding-left: 340px; }

.pl--345 {
  padding-left: 345px; }

.pl--350 {
  padding-left: 350px; }

.pl--355 {
  padding-left: 355px; }

.pl--360 {
  padding-left: 360px; }

.pl--365 {
  padding-left: 365px; }

.pl--370 {
  padding-left: 370px; }

.pl--375 {
  padding-left: 375px; }

.pl--380 {
  padding-left: 380px; }

.pl--385 {
  padding-left: 385px; }

.pl--390 {
  padding-left: 390px; }

.pl--395 {
  padding-left: 395px; }

.pl--400 {
  padding-left: 400px; }

.pl--405 {
  padding-left: 405px; }

.pl--410 {
  padding-left: 410px; }

.pl--415 {
  padding-left: 415px; }

.pl--420 {
  padding-left: 420px; }

.pl--425 {
  padding-left: 425px; }

.pl--430 {
  padding-left: 430px; }

.pl--435 {
  padding-left: 435px; }

.pl--440 {
  padding-left: 440px; }

.pl--445 {
  padding-left: 445px; }

.pl--450 {
  padding-left: 450px; }

.pl--5 {
  padding-left: 5px !important; }

/*-- Padding Right --*/
.pr--5 {
  padding-right: 5px; }

.pr--10 {
  padding-right: 10px; }

.pr--15 {
  padding-right: 15px; }

.pr--20 {
  padding-right: 20px; }

.pr--25 {
  padding-right: 25px; }

.pr--30 {
  padding-right: 30px; }

.pr--35 {
  padding-right: 35px; }

.pr--40 {
  padding-right: 40px; }

.pr--45 {
  padding-right: 45px; }

.pr--50 {
  padding-right: 50px; }

.pr--55 {
  padding-right: 55px; }

.pr--60 {
  padding-right: 60px; }

.pr--65 {
  padding-right: 65px; }

.pr--70 {
  padding-right: 70px; }

.pr--75 {
  padding-right: 75px; }

.pr--80 {
  padding-right: 80px; }

.pr--85 {
  padding-right: 85px; }

.pr--90 {
  padding-right: 90px; }

.pr--95 {
  padding-right: 95px; }

.pr--100 {
  padding-right: 100px; }

.pr--105 {
  padding-right: 105px; }

.pr--110 {
  padding-right: 110px; }

.pr--115 {
  padding-right: 115px; }

.pr--120 {
  padding-right: 120px; }

.pr--125 {
  padding-right: 125px; }

.pr--130 {
  padding-right: 130px; }

.pr--135 {
  padding-right: 135px; }

.pr--140 {
  padding-right: 140px; }

.pr--145 {
  padding-right: 145px; }

.pr--150 {
  padding-right: 150px; }

.pr--155 {
  padding-right: 155px; }

.pr--160 {
  padding-right: 160px; }

.pr--165 {
  padding-right: 165px; }

.pr--170 {
  padding-right: 170px; }

.pr--175 {
  padding-right: 175px; }

.pr--180 {
  padding-right: 180px; }

.pr--185 {
  padding-right: 185px; }

.pr--190 {
  padding-right: 190px; }

.pr--195 {
  padding-right: 195px; }

.pr--200 {
  padding-right: 200px; }

.pr--205 {
  padding-right: 205px; }

.pr--210 {
  padding-right: 210px; }

.pr--215 {
  padding-right: 215px; }

.pr--220 {
  padding-right: 220px; }

.pr--225 {
  padding-right: 225px; }

.pr--230 {
  padding-right: 230px; }

.pr--235 {
  padding-right: 235px; }

.pr--240 {
  padding-right: 240px; }

.pr--245 {
  padding-right: 245px; }

.pr--250 {
  padding-right: 250px; }

@media only screen and (max-width: 575px) {
  .pr_sm--5 {
    padding-right: 5px; }
  .pr_sm--10 {
    padding-right: 10px; }
  .pr_sm--15 {
    padding-right: 15px; }
  .pr_sm--20 {
    padding-right: 20px; }
  .pr_sm--25 {
    padding-right: 25px; }
  .pr_sm--30 {
    padding-right: 30px; }
  .pr_sm--35 {
    padding-right: 35px; }
  .pr_sm--40 {
    padding-right: 40px; }
  .pr_sm--45 {
    padding-right: 45px; }
  .pr_sm--50 {
    padding-right: 50px; } }

/*-- Padding Left Right --*/
.plr--5 {
  padding: 0 5px; }

.plr--10 {
  padding: 0 10px; }

.plr--15 {
  padding: 0 15px; }

.plr--20 {
  padding: 0 20px; }

.plr--25 {
  padding: 0 25px; }

.plr--30 {
  padding: 0 30px; }

.plr--35 {
  padding: 0 35px; }

.plr--40 {
  padding: 0 40px; }

.plr--45 {
  padding: 0 45px; }

.plr--50 {
  padding: 0 50px; }

.plr--55 {
  padding: 0 55px; }

.plr--60 {
  padding: 0 60px; }

.plr--65 {
  padding: 0 65px; }

.plr--70 {
  padding: 0 70px; }

.plr--75 {
  padding: 0 75px; }

.plr--80 {
  padding: 0 80px; }

.plr--85 {
  padding: 0 85px; }

.plr--90 {
  padding: 0 90px; }

.plr--95 {
  padding: 0 95px; }

.plr--100 {
  padding: 0 100px; }

.plr--105 {
  padding: 0 105px; }

.plr--110 {
  padding: 0 110px; }

.plr--115 {
  padding: 0 115px; }

.plr--120 {
  padding: 0 120px; }

.plr--125 {
  padding: 0 125px; }

.plr--130 {
  padding: 0 130px; }

.plr--135 {
  padding: 0 135px; }

.plr--140 {
  padding: 0 140px; }

.plr--145 {
  padding: 0 145px; }

.plr--150 {
  padding: 0 150px; }

.plr--155 {
  padding: 0 155px; }

.plr--160 {
  padding: 0 160px; }

.plr--165 {
  padding: 0 165px; }

.plr--170 {
  padding: 0 170px; }

.plr--175 {
  padding: 0 175px; }

.plr--180 {
  padding: 0 180px; }

.plr--185 {
  padding: 0 185px; }

.plr--190 {
  padding: 0 190px; }

.plr--195 {
  padding: 0 195px; }

.plr--200 {
  padding: 0 200px; }

.plr--205 {
  padding: 0 205px; }

.plr--210 {
  padding: 0 210px; }

.plr--215 {
  padding: 0 215px; }

.plr--220 {
  padding: 0 220px; }

.plr--225 {
  padding: 0 225px; }

.plr--230 {
  padding: 0 230px; }

.plr--235 {
  padding: 0 235px; }

.plr--240 {
  padding: 0 240px; }

.plr--245 {
  padding: 0 245px; }

.plr--250 {
  padding: 0 250px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .plr_lg--5 {
    padding: 0 5px; }
  .plr_lg--10 {
    padding: 0 10px; }
  .plr_lg--15 {
    padding: 0 15px; }
  .plr_lg--20 {
    padding: 0 20px; }
  .plr_lg--25 {
    padding: 0 25px; }
  .plr_lg--30 {
    padding: 0 30px; }
  .plr_lg--35 {
    padding: 0 35px; }
  .plr_lg--40 {
    padding: 0 40px; }
  .plr_lg--45 {
    padding: 0 45px; }
  .plr_lg--50 {
    padding: 0 50px; }
  .plr_lg--55 {
    padding: 0 55px; }
  .plr_lg--60 {
    padding: 0 60px; }
  .plr_lg--65 {
    padding: 0 65px; }
  .plr_lg--70 {
    padding: 0 70px; }
  .plr_lg--75 {
    padding: 0 75px; }
  .plr_lg--80 {
    padding: 0 80px; }
  .plr_lg--85 {
    padding: 0 85px; }
  .plr_lg--90 {
    padding: 0 90px; }
  .plr_lg--95 {
    padding: 0 95px; }
  .plr_lg--100 {
    padding: 0 100px; }
  .plr_lg--105 {
    padding: 0 105px; }
  .plr_lg--110 {
    padding: 0 110px; }
  .plr_lg--115 {
    padding: 0 115px; }
  .plr_lg--120 {
    padding: 0 120px; }
  .plr_lg--125 {
    padding: 0 125px; }
  .plr_lg--130 {
    padding: 0 130px; }
  .plr_lg--135 {
    padding: 0 135px; }
  .plr_lg--140 {
    padding: 0 140px; }
  .plr_lg--145 {
    padding: 0 145px; }
  .plr_lg--150 {
    padding: 0 150px; }
  .plr_lg--155 {
    padding: 0 155px; }
  .plr_lg--160 {
    padding: 0 160px; }
  .plr_lg--165 {
    padding: 0 165px; }
  .plr_lg--170 {
    padding: 0 170px; }
  .plr_lg--175 {
    padding: 0 175px; }
  .plr_lg--180 {
    padding: 0 180px; }
  .plr_lg--185 {
    padding: 0 185px; }
  .plr_lg--190 {
    padding: 0 190px; }
  .plr_lg--195 {
    padding: 0 195px; }
  .plr_lg--200 {
    padding: 0 200px; }
  .plr_lg--205 {
    padding: 0 205px; }
  .plr_lg--210 {
    padding: 0 210px; }
  .plr_lg--215 {
    padding: 0 215px; }
  .plr_lg--220 {
    padding: 0 220px; }
  .plr_lg--225 {
    padding: 0 225px; }
  .plr_lg--230 {
    padding: 0 230px; }
  .plr_lg--235 {
    padding: 0 235px; }
  .plr_lg--240 {
    padding: 0 240px; }
  .plr_lg--245 {
    padding: 0 245px; }
  .plr_lg--250 {
    padding: 0 250px; } }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .plr_lp--5 {
    padding: 0 5px; }
  .plr_lp--10 {
    padding: 0 10px; }
  .plr_lp--15 {
    padding: 0 15px; }
  .plr_lp--20 {
    padding: 0 20px; }
  .plr_lp--25 {
    padding: 0 25px; }
  .plr_lp--30 {
    padding: 0 30px; }
  .plr_lp--35 {
    padding: 0 35px; }
  .plr_lp--40 {
    padding: 0 40px; }
  .plr_lp--45 {
    padding: 0 45px; }
  .plr_lp--50 {
    padding: 0 50px; }
  .plr_lp--55 {
    padding: 0 55px; }
  .plr_lp--60 {
    padding: 0 60px; }
  .plr_lp--65 {
    padding: 0 65px; }
  .plr_lp--70 {
    padding: 0 70px; }
  .plr_lp--75 {
    padding: 0 75px; }
  .plr_lp--80 {
    padding: 0 80px; }
  .plr_lp--85 {
    padding: 0 85px; }
  .plr_lp--90 {
    padding: 0 90px; }
  .plr_lp--95 {
    padding: 0 95px; }
  .plr_lp--100 {
    padding: 0 100px; }
  .plr_lp--105 {
    padding: 0 105px; }
  .plr_lp--110 {
    padding: 0 110px; }
  .plr_lp--115 {
    padding: 0 115px; }
  .plr_lp--120 {
    padding: 0 120px; }
  .plr_lp--125 {
    padding: 0 125px; }
  .plr_lp--130 {
    padding: 0 130px; }
  .plr_lp--135 {
    padding: 0 135px; }
  .plr_lp--140 {
    padding: 0 140px; }
  .plr_lp--145 {
    padding: 0 145px; }
  .plr_lp--150 {
    padding: 0 150px; }
  .plr_lp--155 {
    padding: 0 155px; }
  .plr_lp--160 {
    padding: 0 160px; }
  .plr_lp--165 {
    padding: 0 165px; }
  .plr_lp--170 {
    padding: 0 170px; }
  .plr_lp--175 {
    padding: 0 175px; }
  .plr_lp--180 {
    padding: 0 180px; }
  .plr_lp--185 {
    padding: 0 185px; }
  .plr_lp--190 {
    padding: 0 190px; }
  .plr_lp--195 {
    padding: 0 195px; }
  .plr_lp--200 {
    padding: 0 200px; }
  .plr_lp--205 {
    padding: 0 205px; }
  .plr_lp--210 {
    padding: 0 210px; }
  .plr_lp--215 {
    padding: 0 215px; }
  .plr_lp--220 {
    padding: 0 220px; }
  .plr_lp--225 {
    padding: 0 225px; }
  .plr_lp--230 {
    padding: 0 230px; }
  .plr_lp--235 {
    padding: 0 235px; }
  .plr_lp--240 {
    padding: 0 240px; }
  .plr_lp--245 {
    padding: 0 245px; }
  .plr_lp--250 {
    padding: 0 250px; } }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .plr_lp--5 {
    padding: 0 5px; }
  .plr_lp--10 {
    padding: 0 10px; }
  .plr_lp--15 {
    padding: 0 15px; }
  .plr_lp--20 {
    padding: 0 20px; }
  .plr_lp--25 {
    padding: 0 25px; }
  .plr_lp--30 {
    padding: 0 30px; }
  .plr_lp--35 {
    padding: 0 35px; }
  .plr_lp--40 {
    padding: 0 40px; }
  .plr_lp--45 {
    padding: 0 45px; }
  .plr_lp--50 {
    padding: 0 50px; }
  .plr_lp--55 {
    padding: 0 55px; }
  .plr_lp--60 {
    padding: 0 60px; }
  .plr_lp--65 {
    padding: 0 65px; }
  .plr_lp--70 {
    padding: 0 70px; }
  .plr_lp--75 {
    padding: 0 75px; }
  .plr_lp--80 {
    padding: 0 80px; }
  .plr_lp--85 {
    padding: 0 85px; }
  .plr_lp--90 {
    padding: 0 90px; }
  .plr_lp--95 {
    padding: 0 95px; }
  .plr_lp--100 {
    padding: 0 100px; }
  .plr_lp--105 {
    padding: 0 105px; }
  .plr_lp--110 {
    padding: 0 110px; }
  .plr_lp--115 {
    padding: 0 115px; }
  .plr_lp--120 {
    padding: 0 120px; }
  .plr_lp--125 {
    padding: 0 125px; }
  .plr_lp--130 {
    padding: 0 130px; }
  .plr_lp--135 {
    padding: 0 135px; }
  .plr_lp--140 {
    padding: 0 140px; }
  .plr_lp--145 {
    padding: 0 145px; }
  .plr_lp--150 {
    padding: 0 150px; }
  .plr_lp--155 {
    padding: 0 155px; }
  .plr_lp--160 {
    padding: 0 160px; }
  .plr_lp--165 {
    padding: 0 165px; }
  .plr_lp--170 {
    padding: 0 170px; }
  .plr_lp--175 {
    padding: 0 175px; }
  .plr_lp--180 {
    padding: 0 180px; }
  .plr_lp--185 {
    padding: 0 185px; }
  .plr_lp--190 {
    padding: 0 190px; }
  .plr_lp--195 {
    padding: 0 195px; }
  .plr_lp--200 {
    padding: 0 200px; }
  .plr_lp--205 {
    padding: 0 205px; }
  .plr_lp--210 {
    padding: 0 210px; }
  .plr_lp--215 {
    padding: 0 215px; }
  .plr_lp--220 {
    padding: 0 220px; }
  .plr_lp--225 {
    padding: 0 225px; }
  .plr_lp--230 {
    padding: 0 230px; }
  .plr_lp--235 {
    padding: 0 235px; }
  .plr_lp--240 {
    padding: 0 240px; }
  .plr_lp--245 {
    padding: 0 245px; }
  .plr_lp--250 {
    padding: 0 250px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .plr_md--5 {
    padding: 0 5px; }
  .plr_md--10 {
    padding: 0 10px; }
  .plr_md--15 {
    padding: 0 15px; }
  .plr_md--20 {
    padding: 0 20px; }
  .plr_md--25 {
    padding: 0 25px; }
  .plr_md--30 {
    padding: 0 30px; }
  .plr_md--35 {
    padding: 0 35px; }
  .plr_md--40 {
    padding: 0 40px; }
  .plr_md--45 {
    padding: 0 45px; }
  .plr_md--50 {
    padding: 0 50px; }
  .plr_md--55 {
    padding: 0 55px; }
  .plr_md--60 {
    padding: 0 60px; }
  .plr_md--65 {
    padding: 0 65px; }
  .plr_md--70 {
    padding: 0 70px; }
  .plr_md--75 {
    padding: 0 75px; }
  .plr_md--80 {
    padding: 0 80px; }
  .plr_md--85 {
    padding: 0 85px; }
  .plr_md--90 {
    padding: 0 90px; }
  .plr_md--95 {
    padding: 0 95px; }
  .plr_md--100 {
    padding: 0 100px; }
  .plr_md--105 {
    padding: 0 105px; }
  .plr_md--110 {
    padding: 0 110px; }
  .plr_md--115 {
    padding: 0 115px; }
  .plr_md--120 {
    padding: 0 120px; }
  .plr_md--125 {
    padding: 0 125px; }
  .plr_md--130 {
    padding: 0 130px; }
  .plr_md--135 {
    padding: 0 135px; }
  .plr_md--140 {
    padding: 0 140px; }
  .plr_md--145 {
    padding: 0 145px; }
  .plr_md--150 {
    padding: 0 150px; }
  .plr_md--155 {
    padding: 0 155px; }
  .plr_md--160 {
    padding: 0 160px; }
  .plr_md--165 {
    padding: 0 165px; }
  .plr_md--170 {
    padding: 0 170px; }
  .plr_md--175 {
    padding: 0 175px; }
  .plr_md--180 {
    padding: 0 180px; }
  .plr_md--185 {
    padding: 0 185px; }
  .plr_md--190 {
    padding: 0 190px; }
  .plr_md--195 {
    padding: 0 195px; }
  .plr_md--200 {
    padding: 0 200px; }
  .plr_md--205 {
    padding: 0 205px; }
  .plr_md--210 {
    padding: 0 210px; }
  .plr_md--215 {
    padding: 0 215px; }
  .plr_md--220 {
    padding: 0 220px; }
  .plr_md--225 {
    padding: 0 225px; }
  .plr_md--230 {
    padding: 0 230px; }
  .plr_md--235 {
    padding: 0 235px; }
  .plr_md--240 {
    padding: 0 240px; }
  .plr_md--245 {
    padding: 0 245px; }
  .plr_md--250 {
    padding: 0 250px; }
  .ml_md--30 {
    margin-left: 30px !important; } }

@media only screen and (max-width: 767px) {
  .plr_sm--5 {
    padding: 0 5px; }
  .plr_sm--10 {
    padding: 0 10px; }
  .plr_sm--15 {
    padding: 0 15px; }
  .plr_sm--20 {
    padding: 0 20px; }
  .plr_sm--25 {
    padding: 0 25px; }
  .plr_sm--30 {
    padding: 0 30px; }
  .plr_sm--35 {
    padding: 0 35px; }
  .plr_sm--40 {
    padding: 0 40px; }
  .plr_sm--45 {
    padding: 0 45px; }
  .plr_sm--50 {
    padding: 0 50px; }
  .plr_sm--55 {
    padding: 0 55px; }
  .plr_sm--60 {
    padding: 0 60px; }
  .plr_sm--65 {
    padding: 0 65px; }
  .plr_sm--70 {
    padding: 0 70px; }
  .plr_sm--75 {
    padding: 0 75px; }
  .plr_sm--80 {
    padding: 0 80px; }
  .plr_sm--85 {
    padding: 0 85px; }
  .plr_sm--90 {
    padding: 0 90px; }
  .plr_sm--95 {
    padding: 0 95px; }
  .plr_sm--100 {
    padding: 0 100px; }
  .plr_sm--105 {
    padding: 0 105px; }
  .plr_sm--110 {
    padding: 0 110px; }
  .plr_sm--115 {
    padding: 0 115px; }
  .plr_sm--120 {
    padding: 0 120px; }
  .plr_sm--125 {
    padding: 0 125px; }
  .plr_sm--130 {
    padding: 0 130px; }
  .plr_sm--135 {
    padding: 0 135px; }
  .plr_sm--140 {
    padding: 0 140px; }
  .plr_sm--145 {
    padding: 0 145px; }
  .plr_sm--150 {
    padding: 0 150px; }
  .plr_sm--155 {
    padding: 0 155px; }
  .plr_sm--160 {
    padding: 0 160px; }
  .plr_sm--165 {
    padding: 0 165px; }
  .plr_sm--170 {
    padding: 0 170px; }
  .plr_sm--175 {
    padding: 0 175px; }
  .plr_sm--180 {
    padding: 0 180px; }
  .plr_sm--185 {
    padding: 0 185px; }
  .plr_sm--190 {
    padding: 0 190px; }
  .plr_sm--195 {
    padding: 0 195px; }
  .plr_sm--200 {
    padding: 0 200px; }
  .plr_sm--205 {
    padding: 0 205px; }
  .plr_sm--210 {
    padding: 0 210px; }
  .plr_sm--215 {
    padding: 0 215px; }
  .plr_sm--220 {
    padding: 0 220px; }
  .plr_sm--225 {
    padding: 0 225px; }
  .plr_sm--230 {
    padding: 0 230px; }
  .plr_sm--235 {
    padding: 0 235px; }
  .plr_sm--240 {
    padding: 0 240px; }
  .plr_sm--245 {
    padding: 0 245px; }
  .plr_sm--250 {
    padding: 0 250px; }
  .ml_sm--30 {
    margin-left: 30px !important; } }

.pr--350 {
  padding-right: 350px; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .footer-retaurant {
    padding-left: 15px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .footer-retaurant {
    padding-right: 15px !important; } }

/*---------------------------
Section Separation
----------------------------*/
.bkseparator--5 {
  height: 5px; }

.bkseparator--10 {
  height: 10px; }

.bkseparator--15 {
  height: 15px; }

.bkseparator--20 {
  height: 20px; }

.bkseparator--25 {
  height: 25px; }

.bkseparator--30 {
  height: 30px; }

.bkseparator--35 {
  height: 35px; }

.bkseparator--40 {
  height: 40px; }

.bkseparator--45 {
  height: 45px; }

.bkseparator--50 {
  height: 50px; }

.bkseparator--55 {
  height: 55px; }

.bkseparator--60 {
  height: 60px; }

.bkseparator--65 {
  height: 65px; }

.bkseparator--70 {
  height: 70px; }

.bkseparator--75 {
  height: 75px; }

.bkseparator--80 {
  height: 80px; }

.bkseparator--85 {
  height: 85px; }

.bkseparator--90 {
  height: 90px; }

.bkseparator--95 {
  height: 95px; }

.bkseparator--100 {
  height: 100px; }

.bkseparator--105 {
  height: 105px; }

.bkseparator--110 {
  height: 110px; }

.bkseparator--115 {
  height: 115px; }

.bkseparator--120 {
  height: 120px; }

.bkseparator--125 {
  height: 125px; }

.bkseparator--130 {
  height: 130px; }

.bkseparator--135 {
  height: 135px; }

.bkseparator--140 {
  height: 140px; }

.bkseparator--145 {
  height: 145px; }

.bkseparator--150 {
  height: 150px; }

.bkseparator--600 {
  height: 600px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bkseparator_lg--5 {
    height: 5px; }
  .bkseparator_lg--10 {
    height: 10px; }
  .bkseparator_lg--15 {
    height: 15px; }
  .bkseparator_lg--20 {
    height: 20px; }
  .bkseparator_lg--25 {
    height: 25px; }
  .bkseparator_lg--30 {
    height: 30px; }
  .bkseparator_lg--35 {
    height: 35px; }
  .bkseparator_lg--40 {
    height: 40px; }
  .bkseparator_lg--45 {
    height: 45px; }
  .bkseparator_lg--50 {
    height: 50px; }
  .bkseparator_lg--55 {
    height: 55px; }
  .bkseparator_lg--60 {
    height: 60px; }
  .bkseparator_lg--65 {
    height: 65px; }
  .bkseparator_lg--70 {
    height: 70px; }
  .bkseparator_lg--75 {
    height: 75px; }
  .bkseparator_lg--80 {
    height: 80px; }
  .bkseparator_lg--85 {
    height: 85px; }
  .bkseparator_lg--90 {
    height: 90px; }
  .bkseparator_lg--95 {
    height: 95px; }
  .bkseparator_lg--100 {
    height: 100px; }
  .bkseparator_lg--105 {
    height: 105px; }
  .bkseparator_lg--110 {
    height: 110px; }
  .bkseparator_lg--115 {
    height: 115px; }
  .bkseparator_lg--120 {
    height: 120px; }
  .bkseparator_lg--125 {
    height: 125px; }
  .bkseparator_lg--130 {
    height: 130px; }
  .bkseparator_lg--135 {
    height: 135px; }
  .bkseparator_lg--140 {
    height: 140px; }
  .bkseparator_lg--145 {
    height: 145px; }
  .bkseparator_lg--150 {
    height: 150px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bkseparator_md--5 {
    height: 5px; }
  .bkseparator_md--10 {
    height: 10px; }
  .bkseparator_md--15 {
    height: 15px; }
  .bkseparator_md--20 {
    height: 20px; }
  .bkseparator_md--25 {
    height: 25px; }
  .bkseparator_md--30 {
    height: 30px; }
  .bkseparator_md--35 {
    height: 35px; }
  .bkseparator_md--40 {
    height: 40px; }
  .bkseparator_md--45 {
    height: 45px; }
  .bkseparator_md--50 {
    height: 50px; }
  .bkseparator_md--55 {
    height: 55px; }
  .bkseparator_md--60 {
    height: 60px; }
  .bkseparator_md--65 {
    height: 65px; }
  .bkseparator_md--70 {
    height: 70px; }
  .bkseparator_md--75 {
    height: 75px; }
  .bkseparator_md--80 {
    height: 80px; }
  .bkseparator_md--85 {
    height: 85px; }
  .bkseparator_md--90 {
    height: 90px; }
  .bkseparator_md--95 {
    height: 95px; }
  .bkseparator_md--100 {
    height: 100px; }
  .bkseparator_md--105 {
    height: 105px; }
  .bkseparator_md--110 {
    height: 110px; }
  .bkseparator_md--115 {
    height: 115px; }
  .bkseparator_md--120 {
    height: 120px; }
  .bkseparator_md--125 {
    height: 125px; }
  .bkseparator_md--130 {
    height: 130px; }
  .bkseparator_md--135 {
    height: 135px; }
  .bkseparator_md--140 {
    height: 140px; }
  .bkseparator_md--145 {
    height: 145px; }
  .bkseparator_md--150 {
    height: 150px; } }

@media only screen and (max-width: 767px) {
  .bkseparator_sm--5 {
    height: 5px; }
  .bkseparator_sm--10 {
    height: 10px; }
  .bkseparator_sm--15 {
    height: 15px; }
  .bkseparator_sm--20 {
    height: 20px; }
  .bkseparator_sm--25 {
    height: 25px; }
  .bkseparator_sm--30 {
    height: 30px; }
  .bkseparator_sm--35 {
    height: 35px; }
  .bkseparator_sm--40 {
    height: 40px; }
  .bkseparator_sm--45 {
    height: 45px; }
  .bkseparator_sm--50 {
    height: 50px; }
  .bkseparator_sm--55 {
    height: 55px; }
  .bkseparator_sm--60 {
    height: 60px; }
  .bkseparator_sm--65 {
    height: 65px; }
  .bkseparator_sm--70 {
    height: 70px; }
  .bkseparator_sm--75 {
    height: 75px; }
  .bkseparator_sm--80 {
    height: 80px; }
  .bkseparator_sm--85 {
    height: 85px; }
  .bkseparator_sm--90 {
    height: 90px; }
  .bkseparator_sm--95 {
    height: 95px; }
  .bkseparator_sm--100 {
    height: 100px; }
  .bkseparator_sm--105 {
    height: 105px; }
  .bkseparator_sm--110 {
    height: 110px; }
  .bkseparator_sm--115 {
    height: 115px; }
  .bkseparator_sm--120 {
    height: 120px; }
  .bkseparator_sm--125 {
    height: 125px; }
  .bkseparator_sm--130 {
    height: 130px; }
  .bkseparator_sm--135 {
    height: 135px; }
  .bkseparator_sm--140 {
    height: 140px; }
  .bkseparator_sm--145 {
    height: 145px; }
  .bkseparator_sm--150 {
    height: 150px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .space_dec--none {
    margin-top: 0 !important; } }

@media only screen and (max-width: 767px) {
  .space_dec--none {
    margin-top: 0 !important; } }

.space_dec--110 {
  margin-top: -110px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .space_dec--110 {
      margin-top: -180px; } }
  @media only screen and (max-width: 767px) {
    .space_dec--110 {
      margin-top: -180px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .space_dec--110.space_dec--none {
      margin-top: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .space_dec--110.space_dec--none {
      margin-top: 0 !important; } }

.space_dec--100 {
  margin-top: -100px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .space_dec--100 {
      margin-top: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .space_dec--100 {
      margin-top: 0 !important; } }

.space_decLeft--170 {
  margin-left: -170px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .space_decLeft--170 {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .space_decLeft--170 {
      margin-left: 0; } }

.space_dec--70 {
  margin-top: -70px; }

.space_dec--40 {
  margin-top: -40px; }

.space_dec--250 {
  margin-top: -250px; }

@media only screen and (max-width: 767px) {
  .space_dec_sm--none {
    margin-top: 0; } }

.max-width-350 {
  max-width: 350px; }

.plr_dec--85 {
  margin: 0 -85px; }

/*-- Margin Top --*/
.mt--5 {
  margin-top: 5px; }

.mt--10 {
  margin-top: 10px; }

.mt--15 {
  margin-top: 15px; }

.mt--20 {
  margin-top: 20px; }

.mt--25 {
  margin-top: 25px; }

.mt--30 {
  margin-top: 30px; }

.mt--35 {
  margin-top: 35px; }

.mt--40 {
  margin-top: 40px; }

.mt--45 {
  margin-top: 45px; }

.mt--50 {
  margin-top: 50px; }

.mt--55 {
  margin-top: 55px; }

.mt--60 {
  margin-top: 60px; }

.mt--65 {
  margin-top: 65px; }

.mt--70 {
  margin-top: 70px; }

.mt--75 {
  margin-top: 75px; }

.mt--80 {
  margin-top: 80px; }

.mt--85 {
  margin-top: 85px; }

.mt--90 {
  margin-top: 90px; }

.mt--95 {
  margin-top: 95px; }

.mt--100 {
  margin-top: 100px; }

.mt--105 {
  margin-top: 105px; }

.mt--110 {
  margin-top: 110px; }

.mt--115 {
  margin-top: 115px; }

.mt--120 {
  margin-top: 120px; }

.mt--125 {
  margin-top: 125px; }

.mt--130 {
  margin-top: 130px; }

.mt--135 {
  margin-top: 135px; }

.mt--140 {
  margin-top: 140px; }

.mt--145 {
  margin-top: 145px; }

.mt--150 {
  margin-top: 150px; }

.mt--155 {
  margin-top: 155px; }

.mt--160 {
  margin-top: 160px; }

.mt--165 {
  margin-top: 165px; }

.mt--170 {
  margin-top: 170px; }

.mt--175 {
  margin-top: 175px; }

.mt--180 {
  margin-top: 180px; }

.mt--185 {
  margin-top: 185px; }

.mt--190 {
  margin-top: 190px; }

.mt--195 {
  margin-top: 195px; }

.mt--200 {
  margin-top: 200px; }

/*-- Margin Bottom --*/
.mb--5 {
  margin-bottom: 5px; }

.mb--10 {
  margin-bottom: 10px; }

.mb--15 {
  margin-bottom: 15px; }

.mb--20 {
  margin-bottom: 20px; }

.mb--25 {
  margin-bottom: 25px; }

.mb--30 {
  margin-bottom: 30px; }

.mb--35 {
  margin-bottom: 35px; }

.mb--40 {
  margin-bottom: 40px; }

.mb--45 {
  margin-bottom: 45px; }

.mb--50 {
  margin-bottom: 50px; }

.mb--55 {
  margin-bottom: 55px; }

.mb--60 {
  margin-bottom: 60px; }

.mb--65 {
  margin-bottom: 65px; }

.mb--70 {
  margin-bottom: 70px; }

.mb--75 {
  margin-bottom: 75px; }

.mb--80 {
  margin-bottom: 80px; }

.mb--85 {
  margin-bottom: 85px; }

.mb--90 {
  margin-bottom: 90px; }

.mb--95 {
  margin-bottom: 95px; }

.mb--100 {
  margin-bottom: 100px; }

.pl--0 {
  padding-left: 0; }

.pr--0 {
  padding-right: 0; }

/*-- Margin Right --*/
.mr--0 {
  margin-right: 0 !important; }

.ml--0 {
  margin-left: 0 !important; }

.mr--5 {
  margin-right: 5px; }

.mr--10 {
  margin-right: 10px; }

.mr--15 {
  margin-right: 15px; }

.mr--20 {
  margin-right: 20px; }

.mr--25 {
  margin-right: 25px; }

.mr--30 {
  margin-right: 30px; }

.mr--35 {
  margin-right: 35px; }

.mr--40 {
  margin-right: 40px; }

.mr--45 {
  margin-right: 45px; }

.mr--50 {
  margin-right: 50px; }

.mr--55 {
  margin-right: 55px; }

.mr--60 {
  margin-right: 60px; }

.mr--65 {
  margin-right: 65px; }

.mr--70 {
  margin-right: 70px; }

.mr--75 {
  margin-right: 75px; }

.mr--80 {
  margin-right: 80px; }

.mr--85 {
  margin-right: 85px; }

.mr--90 {
  margin-right: 90px; }

.mr--95 {
  margin-right: 95px; }

.mr--100 {
  margin-right: 100px; }

/*-- Margin Bottom --*/
.ml--5 {
  margin-left: 5px; }

.ml--10 {
  margin-left: 10px; }

.ml--15 {
  margin-left: 15px; }

.ml--20 {
  margin-left: 20px; }

.ml--25 {
  margin-left: 25px; }

.ml--30 {
  margin-left: 30px; }

.ml--35 {
  margin-left: 35px; }

.ml--40 {
  margin-left: 40px; }

.ml--45 {
  margin-left: 45px; }

.ml--50 {
  margin-left: 50px; }

.ml--55 {
  margin-left: 55px; }

.ml--60 {
  margin-left: 60px; }

.ml--65 {
  margin-left: 65px; }

.ml--70 {
  margin-left: 70px; }

.ml--75 {
  margin-left: 75px; }

.ml--80 {
  margin-left: 80px; }

.ml--85 {
  margin-left: 85px; }

.ml--90 {
  margin-left: 90px; }

.ml--95 {
  margin-left: 95px; }

.ml--100 {
  margin-left: 100px; }

.ml--105 {
  margin-left: 105px; }

.ml--110 {
  margin-left: 110px; }

.ml--115 {
  margin-left: 115px; }

.ml--120 {
  margin-left: 120px; }

.ml--125 {
  margin-left: 125px; }

.ml--130 {
  margin-left: 130px; }

.ml--135 {
  margin-left: 135px; }

.ml--140 {
  margin-left: 140px; }

.ml--145 {
  margin-left: 145px; }

.ml--150 {
  margin-left: 150px; }

.ml--155 {
  margin-left: 155px; }

.ml--160 {
  margin-left: 160px; }

.ml--165 {
  margin-left: 165px; }

.ml--170 {
  margin-left: 170px; }

.ml--175 {
  margin-left: 175px; }

/*-- Margin Bottom --*/
.ptb--5 {
  padding: 5px 0; }

.ptb--10 {
  padding: 10px 0; }

.ptb--15 {
  padding: 15px 0; }

.ptb--20 {
  padding: 20px 0; }

.ptb--25 {
  padding: 25px 0; }

.ptb--30 {
  padding: 30px 0; }

.ptb--35 {
  padding: 35px 0; }

.ptb--40 {
  padding: 40px 0; }

.ptb--45 {
  padding: 45px 0; }

.ptb--50 {
  padding: 50px 0; }

.ptb--55 {
  padding: 55px 0; }

.ptb--60 {
  padding: 60px 0; }

.ptb--65 {
  padding: 65px 0; }

.ptb--70 {
  padding: 70px 0; }

.ptb--75 {
  padding: 75px 0; }

.ptb--80 {
  padding: 80px 0; }

.ptb--85 {
  padding: 85px 0; }

.ptb--90 {
  padding: 90px 0; }

.ptb--95 {
  padding: 95px 0; }

.ptb--100 {
  padding: 100px 0; }

.ptb--105 {
  padding: 105px 0; }

.ptb--110 {
  padding: 110px 0; }

.ptb--115 {
  padding: 115px 0; }

.ptb--120 {
  padding: 120px 0; }

.ptb--125 {
  padding: 125px 0; }

.ptb--130 {
  padding: 130px 0; }

.ptb--135 {
  padding: 135px 0; }

.ptb--140 {
  padding: 140px 0; }

.ptb--145 {
  padding: 145px 0; }

.ptb--150 {
  padding: 150px 0; }

.ptb--155 {
  padding: 155px 0; }

.ptb--160 {
  padding: 160px 0; }

.ptb--165 {
  padding: 165px 0; }

.ptb--170 {
  padding: 170px 0; }

.ptb--175 {
  padding: 175px 0; }

.ptb--180 {
  padding: 180px 0; }

.ptb--185 {
  padding: 185px 0; }

.ptb--190 {
  padding: 190px 0; }

.ptb--195 {
  padding: 195px 0; }

.ptb--200 {
  padding: 200px 0; }

.ptb--205 {
  padding: 205px 0; }

.ptb--210 {
  padding: 210px 0; }

.ptb--215 {
  padding: 215px 0; }

.ptb--220 {
  padding: 220px 0; }

.ptb--225 {
  padding: 225px 0; }

.ptb--230 {
  padding: 230px 0; }

.ptb--235 {
  padding: 235px 0; }

.ptb--240 {
  padding: 240px 0; }

.ptb--245 {
  padding: 245px 0; }

.ptb--250 {
  padding: 250px 0; }

.ptb--255 {
  padding: 255px 0; }

.ptb--260 {
  padding: 260px 0; }

.ptb--265 {
  padding: 265px 0; }

.ptb--270 {
  padding: 270px 0; }

.ptb--275 {
  padding: 275px 0; }

.ptb--280 {
  padding: 280px 0; }

.ptb--285 {
  padding: 285px 0; }

.ptb--290 {
  padding: 290px 0; }

.ptb--295 {
  padding: 295px 0; }

.ptb--300 {
  padding: 300px 0; }

.ptb--305 {
  padding: 305px 0; }

.ptb--310 {
  padding: 310px 0; }

.ptb--315 {
  padding: 315px 0; }

.ptb--320 {
  padding: 320px 0; }

.ptb--325 {
  padding: 325px 0; }

.ptb--330 {
  padding: 330px 0; }

.ptb--335 {
  padding: 335px 0; }

.ptb--340 {
  padding: 340px 0; }

.ptb--345 {
  padding: 345px 0; }

.ptb--350 {
  padding: 350px 0; }

/* =================================
    Responsive Spacing Css
====================================*/
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .pb_lp--5 {
    padding-bottom: 5px; }
  .pr_lp--5 {
    padding-right: 5px; }
  .pl_lp--5 {
    padding-left: 5px; }
  .pt_lp--5 {
    padding-top: 5px; }
  .ptb_lp--5 {
    padding: 5px 0; }
  .pb_lp--10 {
    padding-bottom: 10px; }
  .pr_lp--10 {
    padding-right: 10px; }
  .pl_lp--10 {
    padding-left: 10px; }
  .pt_lp--10 {
    padding-top: 10px; }
  .ptb_lp--10 {
    padding: 10px 0; }
  .pb_lp--15 {
    padding-bottom: 15px; }
  .pr_lp--15 {
    padding-right: 15px; }
  .pl_lp--15 {
    padding-left: 15px; }
  .pt_lp--15 {
    padding-top: 15px; }
  .ptb_lp--15 {
    padding: 15px 0; }
  .pb_lp--20 {
    padding-bottom: 20px; }
  .pr_lp--20 {
    padding-right: 20px; }
  .pl_lp--20 {
    padding-left: 20px; }
  .pt_lp--20 {
    padding-top: 20px; }
  .ptb_lp--20 {
    padding: 20px 0; }
  .pb_lp--25 {
    padding-bottom: 25px; }
  .pr_lp--25 {
    padding-right: 25px; }
  .pl_lp--25 {
    padding-left: 25px; }
  .pt_lp--25 {
    padding-top: 25px; }
  .ptb_lp--25 {
    padding: 25px 0; }
  .pb_lp--30 {
    padding-bottom: 30px; }
  .pr_lp--30 {
    padding-right: 30px; }
  .pl_lp--30 {
    padding-left: 30px; }
  .pt_lp--30 {
    padding-top: 30px; }
  .ptb_lp--30 {
    padding: 30px 0; }
  .pb_lp--35 {
    padding-bottom: 35px; }
  .pr_lp--35 {
    padding-right: 35px; }
  .pl_lp--35 {
    padding-left: 35px; }
  .pt_lp--35 {
    padding-top: 35px; }
  .ptb_lp--35 {
    padding: 35px 0; }
  .pb_lp--40 {
    padding-bottom: 40px; }
  .pr_lp--40 {
    padding-right: 40px; }
  .pl_lp--40 {
    padding-left: 40px; }
  .pt_lp--40 {
    padding-top: 40px; }
  .ptb_lp--40 {
    padding: 40px 0; }
  .pb_lp--45 {
    padding-bottom: 45px; }
  .pr_lp--45 {
    padding-right: 45px; }
  .pl_lp--45 {
    padding-left: 45px; }
  .pt_lp--45 {
    padding-top: 45px; }
  .ptb_lp--45 {
    padding: 45px 0; }
  .pb_lp--50 {
    padding-bottom: 50px; }
  .pr_lp--50 {
    padding-right: 50px; }
  .pl_lp--50 {
    padding-left: 50px; }
  .pt_lp--50 {
    padding-top: 50px; }
  .ptb_lp--50 {
    padding: 50px 0; }
  .pb_lp--55 {
    padding-bottom: 55px; }
  .pr_lp--55 {
    padding-right: 55px; }
  .pl_lp--55 {
    padding-left: 55px; }
  .pt_lp--55 {
    padding-top: 55px; }
  .ptb_lp--55 {
    padding: 55px 0; }
  .pb_lp--60 {
    padding-bottom: 60px; }
  .pr_lp--60 {
    padding-right: 60px; }
  .pl_lp--60 {
    padding-left: 60px; }
  .pt_lp--60 {
    padding-top: 60px; }
  .ptb_lp--60 {
    padding: 60px 0; }
  .pb_lp--65 {
    padding-bottom: 65px; }
  .pr_lp--65 {
    padding-right: 65px; }
  .pl_lp--65 {
    padding-left: 65px; }
  .pt_lp--65 {
    padding-top: 65px; }
  .ptb_lp--65 {
    padding: 65px 0; }
  .pb_lp--70 {
    padding-bottom: 70px; }
  .pr_lp--70 {
    padding-right: 70px; }
  .pl_lp--70 {
    padding-left: 70px; }
  .pt_lp--70 {
    padding-top: 70px; }
  .ptb_lp--70 {
    padding: 70px 0; }
  .pb_lp--75 {
    padding-bottom: 75px; }
  .pr_lp--75 {
    padding-right: 75px; }
  .pl_lp--75 {
    padding-left: 75px; }
  .pt_lp--75 {
    padding-top: 75px; }
  .ptb_lp--75 {
    padding: 75px 0; }
  .pb_lp--80 {
    padding-bottom: 80px; }
  .pr_lp--80 {
    padding-right: 80px; }
  .pl_lp--80 {
    padding-left: 80px; }
  .pt_lp--80 {
    padding-top: 80px; }
  .ptb_lp--80 {
    padding: 80px 0; }
  .pb_lp--85 {
    padding-bottom: 85px; }
  .pr_lp--85 {
    padding-right: 85px; }
  .pl_lp--85 {
    padding-left: 85px; }
  .pt_lp--85 {
    padding-top: 85px; }
  .ptb_lp--85 {
    padding: 85px 0; }
  .pb_lp--90 {
    padding-bottom: 90px; }
  .pr_lp--90 {
    padding-right: 90px; }
  .pl_lp--90 {
    padding-left: 90px; }
  .pt_lp--90 {
    padding-top: 90px; }
  .ptb_lp--90 {
    padding: 90px 0; }
  .pb_lp--95 {
    padding-bottom: 95px; }
  .pr_lp--95 {
    padding-right: 95px; }
  .pl_lp--95 {
    padding-left: 95px; }
  .pt_lp--95 {
    padding-top: 95px; }
  .ptb_lp--95 {
    padding: 95px 0; }
  .pb_lp--100 {
    padding-bottom: 100px; }
  .pr_lp--100 {
    padding-right: 100px; }
  .pl_lp--100 {
    padding-left: 100px; }
  .pt_lp--100 {
    padding-top: 100px; }
  .ptb_lp--100 {
    padding: 100px 0; }
  .pb_lp--105 {
    padding-bottom: 105px; }
  .pr_lp--105 {
    padding-right: 105px; }
  .pl_lp--105 {
    padding-left: 105px; }
  .pt_lp--105 {
    padding-top: 105px; }
  .ptb_lp--105 {
    padding: 105px 0; }
  .pb_lp--110 {
    padding-bottom: 110px; }
  .pr_lp--110 {
    padding-right: 110px; }
  .pl_lp--110 {
    padding-left: 110px; }
  .pt_lp--110 {
    padding-top: 110px; }
  .ptb_lp--110 {
    padding: 110px 0; }
  .pb_lp--115 {
    padding-bottom: 115px; }
  .pr_lp--115 {
    padding-right: 115px; }
  .pl_lp--115 {
    padding-left: 115px; }
  .pt_lp--115 {
    padding-top: 115px; }
  .ptb_lp--115 {
    padding: 115px 0; }
  .pb_lp--120 {
    padding-bottom: 120px; }
  .pr_lp--120 {
    padding-right: 120px; }
  .pl_lp--120 {
    padding-left: 120px; }
  .pt_lp--120 {
    padding-top: 120px; }
  .ptb_lp--120 {
    padding: 120px 0; }
  .pb_lp--125 {
    padding-bottom: 125px; }
  .pr_lp--125 {
    padding-right: 125px; }
  .pl_lp--125 {
    padding-left: 125px; }
  .pt_lp--125 {
    padding-top: 125px; }
  .ptb_lp--125 {
    padding: 125px 0; }
  .pb_lp--130 {
    padding-bottom: 130px; }
  .pr_lp--130 {
    padding-right: 130px; }
  .pl_lp--130 {
    padding-left: 130px; }
  .pt_lp--130 {
    padding-top: 130px; }
  .ptb_lp--130 {
    padding: 130px 0; }
  .pb_lp--135 {
    padding-bottom: 135px; }
  .pr_lp--135 {
    padding-right: 135px; }
  .pl_lp--135 {
    padding-left: 135px; }
  .pt_lp--135 {
    padding-top: 135px; }
  .ptb_lp--135 {
    padding: 135px 0; }
  .pb_lp--140 {
    padding-bottom: 140px; }
  .pr_lp--140 {
    padding-right: 140px; }
  .pl_lp--140 {
    padding-left: 140px; }
  .pt_lp--140 {
    padding-top: 140px; }
  .ptb_lp--140 {
    padding: 140px 0; }
  .pb_lp--145 {
    padding-bottom: 145px; }
  .pr_lp--145 {
    padding-right: 145px; }
  .pl_lp--145 {
    padding-left: 145px; }
  .pt_lp--145 {
    padding-top: 145px; }
  .ptb_lp--145 {
    padding: 145px 0; }
  .pb_lp--150 {
    padding-bottom: 150px; }
  .pr_lp--150 {
    padding-right: 150px; }
  .pl_lp--150 {
    padding-left: 150px; }
  .pt_lp--150 {
    padding-top: 150px; }
  .ptb_lp--150 {
    padding: 150px 0; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pr_lg--0 {
    padding-right: 0; }
  .pl_lg--0 {
    padding-left: 0; }
  .pb_lg--5 {
    padding-bottom: 5px; }
  .pt_lg--5 {
    padding-top: 5px; }
  .pb_lg--10 {
    padding-bottom: 10px; }
  .pt_lg--10 {
    padding-top: 10px; }
  .pb_lg--15 {
    padding-bottom: 15px; }
  .pt_lg--15 {
    padding-top: 15px; }
  .pb_lg--20 {
    padding-bottom: 20px; }
  .pt_lg--20 {
    padding-top: 20px; }
  .pb_lg--25 {
    padding-bottom: 25px; }
  .pt_lg--25 {
    padding-top: 25px; }
  .pb_lg--30 {
    padding-bottom: 30px; }
  .pt_lg--30 {
    padding-top: 30px; }
  .pb_lg--35 {
    padding-bottom: 35px; }
  .pt_lg--35 {
    padding-top: 35px; }
  .pb_lg--40 {
    padding-bottom: 40px; }
  .pt_lg--40 {
    padding-top: 40px; }
  .pb_lg--45 {
    padding-bottom: 45px; }
  .pt_lg--45 {
    padding-top: 45px; }
  .pb_lg--50 {
    padding-bottom: 50px; }
  .pt_lg--50 {
    padding-top: 50px; }
  .pb_lg--55 {
    padding-bottom: 55px; }
  .pt_lg--55 {
    padding-top: 55px; }
  .pb_lg--60 {
    padding-bottom: 60px; }
  .pt_lg--60 {
    padding-top: 60px; }
  .pb_lg--65 {
    padding-bottom: 65px; }
  .pt_lg--65 {
    padding-top: 65px; }
  .pb_lg--70 {
    padding-bottom: 70px; }
  .pt_lg--70 {
    padding-top: 70px; }
  .pb_lg--75 {
    padding-bottom: 75px; }
  .pt_lg--75 {
    padding-top: 75px; }
  .pb_lg--80 {
    padding-bottom: 80px; }
  .pt_lg--80 {
    padding-top: 80px; }
  .pb_lg--85 {
    padding-bottom: 85px; }
  .pt_lg--85 {
    padding-top: 85px; }
  .pb_lg--90 {
    padding-bottom: 90px; }
  .pt_lg--90 {
    padding-top: 90px; }
  .pb_lg--95 {
    padding-bottom: 95px; }
  .pt_lg--95 {
    padding-top: 95px; }
  .pb_lg--100 {
    padding-bottom: 100px; }
  .pt_lg--100 {
    padding-top: 100px; }
  .pb_lg--105 {
    padding-bottom: 105px; }
  .pt_lg--105 {
    padding-top: 105px; }
  .pb_lg--110 {
    padding-bottom: 110px; }
  .pt_lg--110 {
    padding-top: 110px; }
  .pb_lg--115 {
    padding-bottom: 115px; }
  .pt_lg--115 {
    padding-top: 115px; }
  .pb_lg--120 {
    padding-bottom: 120px; }
  .pt_lg--120 {
    padding-top: 120px; }
  .pb_lg--125 {
    padding-bottom: 125px; }
  .pt_lg--125 {
    padding-top: 125px; }
  .pb_lg--130 {
    padding-bottom: 130px; }
  .pt_lg--130 {
    padding-top: 130px; }
  .pb_lg--135 {
    padding-bottom: 135px; }
  .pt_lg--135 {
    padding-top: 135px; }
  .pb_lg--140 {
    padding-bottom: 140px; }
  .pt_lg--140 {
    padding-top: 140px; }
  .pb_lg--145 {
    padding-bottom: 145px; }
  .pt_lg--145 {
    padding-top: 145px; }
  .pb_lg--150 {
    padding-bottom: 150px; }
  .pt_lg--150 {
    padding-top: 150px; }
  .pb_lg--155 {
    padding-bottom: 155px; }
  .pt_lg--155 {
    padding-top: 155px; }
  .pb_lg--160 {
    padding-bottom: 160px; }
  .pt_lg--160 {
    padding-top: 160px; }
  .pb_lg--165 {
    padding-bottom: 165px; }
  .pt_lg--165 {
    padding-top: 165px; }
  .pb_lg--170 {
    padding-bottom: 170px; }
  .pt_lg--170 {
    padding-top: 170px; }
  .pb_lg--175 {
    padding-bottom: 175px; }
  .pt_lg--175 {
    padding-top: 175px; }
  .pb_lg--180 {
    padding-bottom: 180px; }
  .pt_lg--180 {
    padding-top: 180px; }
  .pb_lg--185 {
    padding-bottom: 185px; }
  .pt_lg--185 {
    padding-top: 185px; }
  .pb_lg--190 {
    padding-bottom: 190px; }
  .pt_lg--190 {
    padding-top: 190px; }
  .pb_lg--195 {
    padding-bottom: 195px; }
  .pt_lg--195 {
    padding-top: 195px; }
  .pb_lg--200 {
    padding-bottom: 200px; }
  .pt_lg--200 {
    padding-top: 200px; }
  .pb_lg--205 {
    padding-bottom: 205px; }
  .pt_lg--205 {
    padding-top: 205px; }
  .pb_lg--210 {
    padding-bottom: 210px; }
  .pt_lg--210 {
    padding-top: 210px; }
  .pb_lg--215 {
    padding-bottom: 215px; }
  .pt_lg--215 {
    padding-top: 215px; }
  .pb_lg--220 {
    padding-bottom: 220px; }
  .pt_lg--220 {
    padding-top: 220px; }
  .pb_lg--225 {
    padding-bottom: 225px; }
  .pt_lg--225 {
    padding-top: 225px; }
  .pb_lg--230 {
    padding-bottom: 230px; }
  .pt_lg--230 {
    padding-top: 230px; }
  .pb_lg--235 {
    padding-bottom: 235px; }
  .pt_lg--235 {
    padding-top: 235px; }
  .pb_lg--240 {
    padding-bottom: 240px; }
  .pt_lg--240 {
    padding-top: 240px; }
  .pb_lg--245 {
    padding-bottom: 245px; }
  .pt_lg--245 {
    padding-top: 245px; }
  .pb_lg--250 {
    padding-bottom: 250px; }
  .pt_lg--250 {
    padding-top: 250px; }
  .pb_lg--255 {
    padding-bottom: 255px; }
  .pt_lg--255 {
    padding-top: 255px; }
  .pb_lg--260 {
    padding-bottom: 260px; }
  .pt_lg--260 {
    padding-top: 260px; }
  .pb_lg--265 {
    padding-bottom: 265px; }
  .pt_lg--265 {
    padding-top: 265px; }
  .pb_lg--270 {
    padding-bottom: 270px; }
  .pt_lg--270 {
    padding-top: 270px; }
  .pb_lg--275 {
    padding-bottom: 275px; }
  .pt_lg--275 {
    padding-top: 275px; }
  .pb_lg--280 {
    padding-bottom: 280px; }
  .pt_lg--280 {
    padding-top: 280px; }
  .pb_lg--285 {
    padding-bottom: 285px; }
  .pt_lg--285 {
    padding-top: 285px; }
  .pb_lg--290 {
    padding-bottom: 290px; }
  .pt_lg--290 {
    padding-top: 290px; }
  .pb_lg--295 {
    padding-bottom: 295px; }
  .pt_lg--295 {
    padding-top: 295px; }
  .pb_lg--300 {
    padding-bottom: 300px; }
  .pt_lg--300 {
    padding-top: 300px; }
  .pl_lg--5 {
    padding-left: 5px; }
  .pr_lg--5 {
    padding-right: 5px; }
  .ptb-lg--5 {
    padding: 5px 0; }
  .pl_lg--10 {
    padding-left: 10px; }
  .pr_lg--10 {
    padding-right: 10px; }
  .ptb-lg--10 {
    padding: 10px 0; }
  .pl_lg--15 {
    padding-left: 15px; }
  .pr_lg--15 {
    padding-right: 15px; }
  .ptb-lg--15 {
    padding: 15px 0; }
  .pl_lg--20 {
    padding-left: 20px; }
  .pr_lg--20 {
    padding-right: 20px; }
  .ptb-lg--20 {
    padding: 20px 0; }
  .pl_lg--25 {
    padding-left: 25px; }
  .pr_lg--25 {
    padding-right: 25px; }
  .ptb-lg--25 {
    padding: 25px 0; }
  .pl_lg--30 {
    padding-left: 30px; }
  .pr_lg--30 {
    padding-right: 30px; }
  .ptb-lg--30 {
    padding: 30px 0; }
  .pl_lg--35 {
    padding-left: 35px; }
  .pr_lg--35 {
    padding-right: 35px; }
  .ptb-lg--35 {
    padding: 35px 0; }
  .pl_lg--40 {
    padding-left: 40px; }
  .pr_lg--40 {
    padding-right: 40px; }
  .ptb-lg--40 {
    padding: 40px 0; }
  .pl_lg--45 {
    padding-left: 45px; }
  .pr_lg--45 {
    padding-right: 45px; }
  .ptb-lg--45 {
    padding: 45px 0; }
  .pl_lg--50 {
    padding-left: 50px; }
  .pr_lg--50 {
    padding-right: 50px; }
  .ptb-lg--50 {
    padding: 50px 0; }
  .pl_lg--55 {
    padding-left: 55px; }
  .pr_lg--55 {
    padding-right: 55px; }
  .ptb-lg--55 {
    padding: 55px 0; }
  .pl_lg--60 {
    padding-left: 60px; }
  .pr_lg--60 {
    padding-right: 60px; }
  .ptb-lg--60 {
    padding: 60px 0; }
  .pl_lg--65 {
    padding-left: 65px; }
  .pr_lg--65 {
    padding-right: 65px; }
  .ptb-lg--65 {
    padding: 65px 0; }
  .pl_lg--70 {
    padding-left: 70px; }
  .pr_lg--70 {
    padding-right: 70px; }
  .ptb-lg--70 {
    padding: 70px 0; }
  .pl_lg--75 {
    padding-left: 75px; }
  .pr_lg--75 {
    padding-right: 75px; }
  .ptb-lg--75 {
    padding: 75px 0; }
  .pl_lg--80 {
    padding-left: 80px; }
  .pr_lg--80 {
    padding-right: 80px; }
  .ptb-lg--80 {
    padding: 80px 0; }
  .pl_lg--85 {
    padding-left: 85px; }
  .pr_lg--85 {
    padding-right: 85px; }
  .ptb-lg--85 {
    padding: 85px 0; }
  .pl_lg--90 {
    padding-left: 90px; }
  .pr_lg--90 {
    padding-right: 90px; }
  .ptb-lg--90 {
    padding: 90px 0; }
  .pl_lg--95 {
    padding-left: 95px; }
  .pr_lg--95 {
    padding-right: 95px; }
  .ptb-lg--95 {
    padding: 95px 0; }
  .pl_lg--100 {
    padding-left: 100px; }
  .pr_lg--100 {
    padding-right: 100px; }
  .ptb-lg--100 {
    padding: 100px 0; }
  .pl_lg--105 {
    padding-left: 105px; }
  .pr_lg--105 {
    padding-right: 105px; }
  .ptb-lg--105 {
    padding: 105px 0; }
  .pl_lg--110 {
    padding-left: 110px; }
  .pr_lg--110 {
    padding-right: 110px; }
  .ptb-lg--110 {
    padding: 110px 0; }
  .pl_lg--115 {
    padding-left: 115px; }
  .pr_lg--115 {
    padding-right: 115px; }
  .ptb-lg--115 {
    padding: 115px 0; }
  .pl_lg--120 {
    padding-left: 120px; }
  .pr_lg--120 {
    padding-right: 120px; }
  .ptb-lg--120 {
    padding: 120px 0; }
  .pl_lg--125 {
    padding-left: 125px; }
  .pr_lg--125 {
    padding-right: 125px; }
  .ptb-lg--125 {
    padding: 125px 0; }
  .pl_lg--130 {
    padding-left: 130px; }
  .pr_lg--130 {
    padding-right: 130px; }
  .ptb-lg--130 {
    padding: 130px 0; }
  .pl_lg--135 {
    padding-left: 135px; }
  .pr_lg--135 {
    padding-right: 135px; }
  .ptb-lg--135 {
    padding: 135px 0; }
  .pl_lg--140 {
    padding-left: 140px; }
  .pr_lg--140 {
    padding-right: 140px; }
  .ptb-lg--140 {
    padding: 140px 0; }
  .pl_lg--145 {
    padding-left: 145px; }
  .pr_lg--145 {
    padding-right: 145px; }
  .ptb-lg--145 {
    padding: 145px 0; }
  .pl_lg--150 {
    padding-left: 150px; }
  .pr_lg--150 {
    padding-right: 150px; }
  .ptb-lg--150 {
    padding: 150px 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pr_md--0 {
    padding-right: 0; }
  .pl_md--0 {
    padding-left: 0; }
  .pb_md--5 {
    padding-bottom: 5px; }
  .pb_md--10 {
    padding-bottom: 10px; }
  .pb_md--15 {
    padding-bottom: 15px; }
  .pb_md--20 {
    padding-bottom: 20px; }
  .pb_md--25 {
    padding-bottom: 25px; }
  .pb_md--30 {
    padding-bottom: 30px; }
  .pb_md--35 {
    padding-bottom: 35px; }
  .pb_md--40 {
    padding-bottom: 40px; }
  .pb_md--45 {
    padding-bottom: 45px; }
  .pb_md--50 {
    padding-bottom: 50px; }
  .pb_md--55 {
    padding-bottom: 55px; }
  .pb_md--60 {
    padding-bottom: 60px; }
  .pb_md--65 {
    padding-bottom: 65px; }
  .pb_md--70 {
    padding-bottom: 70px; }
  .pb_md--75 {
    padding-bottom: 75px; }
  .pb_md--80 {
    padding-bottom: 80px; }
  .pb_md--85 {
    padding-bottom: 85px; }
  .pb_md--90 {
    padding-bottom: 90px; }
  .pb_md--95 {
    padding-bottom: 95px; }
  .pb_md--100 {
    padding-bottom: 100px; }
  .pb_md--105 {
    padding-bottom: 105px; }
  .pb_md--110 {
    padding-bottom: 110px; }
  .pb_md--115 {
    padding-bottom: 115px; }
  .pb_md--120 {
    padding-bottom: 120px; }
  .pb_md--125 {
    padding-bottom: 125px; }
  .pb_md--130 {
    padding-bottom: 130px; }
  .pb_md--135 {
    padding-bottom: 135px; }
  .pb_md--140 {
    padding-bottom: 140px; }
  .pb_md--145 {
    padding-bottom: 145px; }
  .pb_md--150 {
    padding-bottom: 150px; }
  .pb_md--155 {
    padding-bottom: 155px; }
  .pb_md--160 {
    padding-bottom: 160px; }
  .pb_md--165 {
    padding-bottom: 165px; }
  .pb_md--170 {
    padding-bottom: 170px; }
  .pb_md--175 {
    padding-bottom: 175px; }
  .pb_md--180 {
    padding-bottom: 180px; }
  .pb_md--185 {
    padding-bottom: 185px; }
  .pb_md--190 {
    padding-bottom: 190px; }
  .pb_md--195 {
    padding-bottom: 195px; }
  .pb_md--200 {
    padding-bottom: 200px; }
  .pl_md--5 {
    padding-left: 5px; }
  .pr_md--5 {
    padding-right: 5px; }
  .pl_md--10 {
    padding-left: 10px; }
  .pr_md--10 {
    padding-right: 10px; }
  .pl_md--15 {
    padding-left: 15px; }
  .pr_md--15 {
    padding-right: 15px; }
  .pl_md--20 {
    padding-left: 20px; }
  .pr_md--20 {
    padding-right: 20px; }
  .pl_md--25 {
    padding-left: 25px; }
  .pr_md--25 {
    padding-right: 25px; }
  .pl_md--30 {
    padding-left: 30px; }
  .pr_md--30 {
    padding-right: 30px; }
  .pl_md--35 {
    padding-left: 35px; }
  .pr_md--35 {
    padding-right: 35px; }
  .pl_md--40 {
    padding-left: 40px; }
  .pr_md--40 {
    padding-right: 40px; }
  .pl_md--45 {
    padding-left: 45px; }
  .pr_md--45 {
    padding-right: 45px; }
  .pl_md--50 {
    padding-left: 50px; }
  .pr_md--50 {
    padding-right: 50px; }
  .pl_md--55 {
    padding-left: 55px; }
  .pr_md--55 {
    padding-right: 55px; }
  .pl_md--60 {
    padding-left: 60px; }
  .pr_md--60 {
    padding-right: 60px; }
  .pl_md--65 {
    padding-left: 65px; }
  .pr_md--65 {
    padding-right: 65px; }
  .pl_md--70 {
    padding-left: 70px; }
  .pr_md--70 {
    padding-right: 70px; }
  .pl_md--75 {
    padding-left: 75px; }
  .pr_md--75 {
    padding-right: 75px; }
  .pl_md--80 {
    padding-left: 80px; }
  .pr_md--80 {
    padding-right: 80px; }
  .pl_md--85 {
    padding-left: 85px; }
  .pr_md--85 {
    padding-right: 85px; }
  .pl_md--90 {
    padding-left: 90px; }
  .pr_md--90 {
    padding-right: 90px; }
  .pl_md--95 {
    padding-left: 95px; }
  .pr_md--95 {
    padding-right: 95px; }
  .pl_md--100 {
    padding-left: 100px; }
  .pr_md--100 {
    padding-right: 100px; }
  .pt_md--5 {
    padding-top: 5px; }
  .pt_md--10 {
    padding-top: 10px; }
  .pt_md--15 {
    padding-top: 15px; }
  .pt_md--20 {
    padding-top: 20px; }
  .pt_md--25 {
    padding-top: 25px; }
  .pt_md--30 {
    padding-top: 30px; }
  .pt_md--35 {
    padding-top: 35px; }
  .pt_md--40 {
    padding-top: 40px; }
  .pt_md--45 {
    padding-top: 45px; }
  .pt_md--50 {
    padding-top: 50px; }
  .pt_md--55 {
    padding-top: 55px; }
  .pt_md--60 {
    padding-top: 60px; }
  .pt_md--65 {
    padding-top: 65px; }
  .pt_md--70 {
    padding-top: 70px; }
  .pt_md--75 {
    padding-top: 75px; }
  .pt_md--80 {
    padding-top: 80px; }
  .pt_md--85 {
    padding-top: 85px; }
  .pt_md--90 {
    padding-top: 90px; }
  .pt_md--95 {
    padding-top: 95px; }
  .pt_md--100 {
    padding-top: 100px; }
  .pt_md--105 {
    padding-top: 105px; }
  .pt_md--110 {
    padding-top: 110px; }
  .pt_md--115 {
    padding-top: 115px; }
  .pt_md--120 {
    padding-top: 120px; }
  .pt_md--125 {
    padding-top: 125px; }
  .pt_md--130 {
    padding-top: 130px; }
  .pt_md--135 {
    padding-top: 135px; }
  .pt_md--140 {
    padding-top: 140px; }
  .pt_md--145 {
    padding-top: 145px; }
  .pt_md--150 {
    padding-top: 150px; }
  .pt_md--155 {
    padding-top: 155px; }
  .pt_md--160 {
    padding-top: 160px; }
  .pt_md--165 {
    padding-top: 165px; }
  .pt_md--170 {
    padding-top: 170px; }
  .pt_md--175 {
    padding-top: 175px; }
  .pt_md--180 {
    padding-top: 180px; }
  .pt_md--185 {
    padding-top: 185px; }
  .pt_md--190 {
    padding-top: 190px; }
  .pt_md--195 {
    padding-top: 195px; }
  .pt_md--200 {
    padding-top: 200px; }
  .pt_md--205 {
    padding-top: 205px; }
  .pt_md--210 {
    padding-top: 210px; }
  .pt_md--215 {
    padding-top: 215px; }
  .pt_md--220 {
    padding-top: 220px; }
  .pt_md--225 {
    padding-top: 225px; }
  .pt_md--230 {
    padding-top: 230px; }
  .pt_md--235 {
    padding-top: 235px; }
  .pt_md--240 {
    padding-top: 240px; }
  .pt_md--245 {
    padding-top: 245px; }
  .pt_md--250 {
    padding-top: 250px; }
  .pt_md--255 {
    padding-top: 255px; }
  .pt_md--260 {
    padding-top: 260px; }
  .pt_md--265 {
    padding-top: 265px; }
  .pt_md--270 {
    padding-top: 270px; }
  .pt_md--275 {
    padding-top: 275px; }
  .pt_md--280 {
    padding-top: 280px; }
  .pt_md--285 {
    padding-top: 285px; }
  .pt_md--290 {
    padding-top: 290px; }
  .pt_md--295 {
    padding-top: 295px; }
  .pt_md--300 {
    padding-top: 300px; }
  .ptb-md--5 {
    padding: 5px 0; }
  .ptb-md--10 {
    padding: 10px 0; }
  .ptb-md--15 {
    padding: 15px 0; }
  .ptb-md--20 {
    padding: 20px 0; }
  .ptb-md--25 {
    padding: 25px 0; }
  .ptb-md--30 {
    padding: 30px 0; }
  .ptb-md--35 {
    padding: 35px 0; }
  .ptb-md--40 {
    padding: 40px 0; }
  .ptb-md--45 {
    padding: 45px 0; }
  .ptb-md--50 {
    padding: 50px 0; }
  .ptb-md--55 {
    padding: 55px 0; }
  .ptb-md--60 {
    padding: 60px 0; }
  .ptb-md--65 {
    padding: 65px 0; }
  .ptb-md--70 {
    padding: 70px 0; }
  .ptb-md--75 {
    padding: 75px 0; }
  .ptb-md--80 {
    padding: 80px 0; }
  .ptb-md--85 {
    padding: 85px 0; }
  .ptb-md--90 {
    padding: 90px 0; }
  .ptb-md--95 {
    padding: 95px 0; }
  .ptb-md--100 {
    padding: 100px 0; }
  .ptb-md--105 {
    padding: 105px 0; }
  .ptb-md--110 {
    padding: 110px 0; }
  .ptb-md--115 {
    padding: 115px 0; }
  .ptb-md--120 {
    padding: 120px 0; }
  .ptb-md--125 {
    padding: 125px 0; }
  .ptb-md--130 {
    padding: 130px 0; }
  .ptb-md--135 {
    padding: 135px 0; }
  .ptb-md--140 {
    padding: 140px 0; }
  .ptb-md--145 {
    padding: 145px 0; }
  .ptb-md--150 {
    padding: 150px 0; }
  .space_dec_md--180 {
    margin-top: -180px; } }

@media only screen and (max-width: 767px) {
  .pr_sm--0 {
    padding-right: 0; }
  .pl_sm--0 {
    padding-left: 0; }
  .pb_sm--5 {
    padding-bottom: 5px; }
  .pb_sm--10 {
    padding-bottom: 10px; }
  .pb_sm--15 {
    padding-bottom: 15px; }
  .pb_sm--20 {
    padding-bottom: 20px; }
  .pb_sm--25 {
    padding-bottom: 25px; }
  .pb_sm--30 {
    padding-bottom: 30px; }
  .pb_sm--35 {
    padding-bottom: 35px; }
  .pb_sm--40 {
    padding-bottom: 40px; }
  .pb_sm--45 {
    padding-bottom: 45px; }
  .pb_sm--50 {
    padding-bottom: 50px; }
  .pb_sm--55 {
    padding-bottom: 55px; }
  .pb_sm--60 {
    padding-bottom: 60px; }
  .pb_sm--65 {
    padding-bottom: 65px; }
  .pb_sm--70 {
    padding-bottom: 70px; }
  .pb_sm--75 {
    padding-bottom: 75px; }
  .pb_sm--80 {
    padding-bottom: 80px; }
  .pb_sm--85 {
    padding-bottom: 85px; }
  .pb_sm--90 {
    padding-bottom: 90px; }
  .pb_sm--95 {
    padding-bottom: 95px; }
  .pb_sm--100 {
    padding-bottom: 100px; }
  .pb_sm--105 {
    padding-bottom: 105px; }
  .pb_sm--110 {
    padding-bottom: 110px; }
  .pb_sm--115 {
    padding-bottom: 115px; }
  .pb_sm--120 {
    padding-bottom: 120px; }
  .pb_sm--125 {
    padding-bottom: 125px; }
  .pb_sm--130 {
    padding-bottom: 130px; }
  .pb_sm--135 {
    padding-bottom: 135px; }
  .pb_sm--140 {
    padding-bottom: 140px; }
  .pb_sm--145 {
    padding-bottom: 145px; }
  .pb_sm--150 {
    padding-bottom: 150px; }
  .pb_sm--155 {
    padding-bottom: 155px; }
  .pb_sm--160 {
    padding-bottom: 160px; }
  .pb_sm--165 {
    padding-bottom: 165px; }
  .pb_sm--170 {
    padding-bottom: 170px; }
  .pb_sm--175 {
    padding-bottom: 175px; }
  .pb_sm--180 {
    padding-bottom: 180px; }
  .pb_sm--185 {
    padding-bottom: 185px; }
  .pb_sm--190 {
    padding-bottom: 190px; }
  .pb_sm--195 {
    padding-bottom: 195px; }
  .pb_sm--200 {
    padding-bottom: 200px; }
  .pl_sm--5 {
    padding-left: 5px; }
  .pl_sm--10 {
    padding-left: 10px; }
  .pl_sm--15 {
    padding-left: 15px; }
  .pl_sm--20 {
    padding-left: 20px; }
  .pl_sm--25 {
    padding-left: 25px; }
  .pl_sm--30 {
    padding-left: 30px; }
  .pl_sm--35 {
    padding-left: 35px; }
  .pl_sm--40 {
    padding-left: 40px; }
  .pl_sm--45 {
    padding-left: 45px; }
  .pl_sm--50 {
    padding-left: 50px; }
  .pl_sm--55 {
    padding-left: 55px; }
  .pl_sm--60 {
    padding-left: 60px; }
  .pl_sm--65 {
    padding-left: 65px; }
  .pl_sm--70 {
    padding-left: 70px; }
  .pl_sm--75 {
    padding-left: 75px; }
  .pl_sm--80 {
    padding-left: 80px; }
  .pl_sm--85 {
    padding-left: 85px; }
  .pl_sm--90 {
    padding-left: 90px; }
  .pl_sm--95 {
    padding-left: 95px; }
  .pl_sm--100 {
    padding-left: 100px; }
  .pr_sm--5 {
    padding-right: 5px; }
  .pr_sm--10 {
    padding-right: 10px; }
  .pr_sm--15 {
    padding-right: 15px; }
  .pr_sm--20 {
    padding-right: 20px; }
  .pr_sm--25 {
    padding-right: 25px; }
  .pr_sm--30 {
    padding-right: 30px; }
  .pr_sm--35 {
    padding-right: 35px; }
  .pr_sm--40 {
    padding-right: 40px; }
  .pr_sm--45 {
    padding-right: 45px; }
  .pr_sm--50 {
    padding-right: 50px; }
  .pr_sm--55 {
    padding-right: 55px; }
  .pr_sm--60 {
    padding-right: 60px; }
  .pr_sm--65 {
    padding-right: 65px; }
  .pr_sm--70 {
    padding-right: 70px; }
  .pr_sm--75 {
    padding-right: 75px; }
  .pr_sm--80 {
    padding-right: 80px; }
  .pr_sm--85 {
    padding-right: 85px; }
  .pr_sm--90 {
    padding-right: 90px; }
  .pr_sm--95 {
    padding-right: 95px; }
  .pr_sm--100 {
    padding-right: 100px; }
  .pt_sm--5 {
    padding-top: 5px; }
  .pt_sm--10 {
    padding-top: 10px; }
  .pt_sm--15 {
    padding-top: 15px; }
  .pt_sm--20 {
    padding-top: 20px; }
  .pt_sm--25 {
    padding-top: 25px; }
  .pt_sm--30 {
    padding-top: 30px; }
  .pt_sm--35 {
    padding-top: 35px; }
  .pt_sm--40 {
    padding-top: 40px; }
  .pt_sm--45 {
    padding-top: 45px; }
  .pt_sm--50 {
    padding-top: 50px; }
  .pt_sm--55 {
    padding-top: 55px; }
  .pt_sm--60 {
    padding-top: 60px; }
  .pt_sm--65 {
    padding-top: 65px; }
  .pt_sm--70 {
    padding-top: 70px; }
  .pt_sm--75 {
    padding-top: 75px; }
  .pt_sm--80 {
    padding-top: 80px; }
  .pt_sm--85 {
    padding-top: 85px; }
  .pt_sm--90 {
    padding-top: 90px; }
  .pt_sm--95 {
    padding-top: 95px; }
  .pt_sm--100 {
    padding-top: 100px; }
  .pt_sm--105 {
    padding-top: 105px; }
  .pt_sm--110 {
    padding-top: 110px; }
  .pt_sm--115 {
    padding-top: 115px; }
  .pt_sm--120 {
    padding-top: 120px; }
  .pt_sm--125 {
    padding-top: 125px; }
  .pt_sm--130 {
    padding-top: 130px; }
  .pt_sm--135 {
    padding-top: 135px; }
  .pt_sm--140 {
    padding-top: 140px; }
  .pt_sm--145 {
    padding-top: 145px; }
  .pt_sm--150 {
    padding-top: 150px; }
  .pt_sm--155 {
    padding-top: 155px; }
  .pt_sm--160 {
    padding-top: 160px; }
  .pt_sm--165 {
    padding-top: 165px; }
  .pt_sm--170 {
    padding-top: 170px; }
  .pt_sm--175 {
    padding-top: 175px; }
  .pt_sm--180 {
    padding-top: 180px; }
  .pt_sm--185 {
    padding-top: 185px; }
  .pt_sm--190 {
    padding-top: 190px; }
  .pt_sm--195 {
    padding-top: 195px; }
  .pt_sm--200 {
    padding-top: 200px; }
  .ptb-sm--5 {
    padding: 5px 0; }
  .ptb-sm--10 {
    padding: 10px 0; }
  .ptb-sm--15 {
    padding: 15px 0; }
  .ptb-sm--20 {
    padding: 20px 0; }
  .ptb-sm--25 {
    padding: 25px 0; }
  .ptb-sm--30 {
    padding: 30px 0; }
  .ptb-sm--35 {
    padding: 35px 0; }
  .ptb-sm--40 {
    padding: 40px 0; }
  .ptb-sm--45 {
    padding: 45px 0; }
  .ptb-sm--50 {
    padding: 50px 0; }
  .ptb-sm--55 {
    padding: 55px 0; }
  .ptb-sm--60 {
    padding: 60px 0; }
  .ptb-sm--65 {
    padding: 65px 0; }
  .ptb-sm--70 {
    padding: 70px 0; }
  .ptb-sm--75 {
    padding: 75px 0; }
  .ptb-sm--80 {
    padding: 80px 0; }
  .ptb-sm--85 {
    padding: 85px 0; }
  .ptb-sm--90 {
    padding: 90px 0; }
  .ptb-sm--95 {
    padding: 95px 0; }
  .ptb-sm--100 {
    padding: 100px 0; }
  .ptb-sm--105 {
    padding: 105px 0; }
  .ptb-sm--110 {
    padding: 110px 0; }
  .ptb-sm--115 {
    padding: 115px 0; }
  .ptb-sm--120 {
    padding: 120px 0; }
  .ptb-sm--125 {
    padding: 125px 0; }
  .ptb-sm--130 {
    padding: 130px 0; }
  .ptb-sm--135 {
    padding: 135px 0; }
  .ptb-sm--140 {
    padding: 140px 0; }
  .ptb-sm--145 {
    padding: 145px 0; }
  .ptb-sm--150 {
    padding: 150px 0; } }

@media only screen and (max-width: 575px) {
  .pb_mobile--5 {
    padding-bottom: 5px; }
  .pl_mobile--5 {
    padding-left: 5px; }
  .pr_mobile--5 {
    padding-right: 5px; }
  .pt_mobile--5 {
    padding-top: 5px; }
  .pb_mobile--5 {
    padding-bottom: 5px; }
  .pb_mobile--10 {
    padding-bottom: 10px; }
  .pl_mobile--10 {
    padding-left: 10px; }
  .pr_mobile--10 {
    padding-right: 10px; }
  .pt_mobile--10 {
    padding-top: 10px; }
  .pb_mobile--10 {
    padding-bottom: 10px; }
  .pb_mobile--15 {
    padding-bottom: 15px; }
  .pl_mobile--15 {
    padding-left: 15px; }
  .pr_mobile--15 {
    padding-right: 15px; }
  .pt_mobile--15 {
    padding-top: 15px; }
  .pb_mobile--15 {
    padding-bottom: 15px; }
  .pb_mobile--20 {
    padding-bottom: 20px; }
  .pl_mobile--20 {
    padding-left: 20px; }
  .pr_mobile--20 {
    padding-right: 20px; }
  .pt_mobile--20 {
    padding-top: 20px; }
  .pb_mobile--20 {
    padding-bottom: 20px; }
  .pb_mobile--25 {
    padding-bottom: 25px; }
  .pl_mobile--25 {
    padding-left: 25px; }
  .pr_mobile--25 {
    padding-right: 25px; }
  .pt_mobile--25 {
    padding-top: 25px; }
  .pb_mobile--25 {
    padding-bottom: 25px; }
  .pb_mobile--30 {
    padding-bottom: 30px; }
  .pl_mobile--30 {
    padding-left: 30px; }
  .pr_mobile--30 {
    padding-right: 30px; }
  .pt_mobile--30 {
    padding-top: 30px; }
  .pb_mobile--30 {
    padding-bottom: 30px; }
  .pb_mobile--35 {
    padding-bottom: 35px; }
  .pl_mobile--35 {
    padding-left: 35px; }
  .pr_mobile--35 {
    padding-right: 35px; }
  .pt_mobile--35 {
    padding-top: 35px; }
  .pb_mobile--35 {
    padding-bottom: 35px; }
  .pb_mobile--40 {
    padding-bottom: 40px; }
  .pl_mobile--40 {
    padding-left: 40px; }
  .pr_mobile--40 {
    padding-right: 40px; }
  .pt_mobile--40 {
    padding-top: 40px; }
  .pb_mobile--40 {
    padding-bottom: 40px; }
  .pb_mobile--45 {
    padding-bottom: 45px; }
  .pl_mobile--45 {
    padding-left: 45px; }
  .pr_mobile--45 {
    padding-right: 45px; }
  .pt_mobile--45 {
    padding-top: 45px; }
  .pb_mobile--45 {
    padding-bottom: 45px; }
  .pb_mobile--50 {
    padding-bottom: 50px; }
  .pl_mobile--50 {
    padding-left: 50px; }
  .pr_mobile--50 {
    padding-right: 50px; }
  .pt_mobile--50 {
    padding-top: 50px; }
  .pb_mobile--50 {
    padding-bottom: 50px; }
  .pb_mobile--55 {
    padding-bottom: 55px; }
  .pl_mobile--55 {
    padding-left: 55px; }
  .pr_mobile--55 {
    padding-right: 55px; }
  .pt_mobile--55 {
    padding-top: 55px; }
  .pb_mobile--55 {
    padding-bottom: 55px; }
  .pb_mobile--60 {
    padding-bottom: 60px; }
  .pl_mobile--60 {
    padding-left: 60px; }
  .pr_mobile--60 {
    padding-right: 60px; }
  .pt_mobile--60 {
    padding-top: 60px; }
  .pb_mobile--60 {
    padding-bottom: 60px; }
  .pb_mobile--65 {
    padding-bottom: 65px; }
  .pl_mobile--65 {
    padding-left: 65px; }
  .pr_mobile--65 {
    padding-right: 65px; }
  .pt_mobile--65 {
    padding-top: 65px; }
  .pb_mobile--65 {
    padding-bottom: 65px; }
  .pb_mobile--70 {
    padding-bottom: 70px; }
  .pl_mobile--70 {
    padding-left: 70px; }
  .pr_mobile--70 {
    padding-right: 70px; }
  .pt_mobile--70 {
    padding-top: 70px; }
  .pb_mobile--70 {
    padding-bottom: 70px; }
  .pb_mobile--75 {
    padding-bottom: 75px; }
  .pl_mobile--75 {
    padding-left: 75px; }
  .pr_mobile--75 {
    padding-right: 75px; }
  .pt_mobile--75 {
    padding-top: 75px; }
  .pb_mobile--75 {
    padding-bottom: 75px; }
  .pb_mobile--80 {
    padding-bottom: 80px; }
  .pl_mobile--80 {
    padding-left: 80px; }
  .pr_mobile--80 {
    padding-right: 80px; }
  .pt_mobile--80 {
    padding-top: 80px; }
  .pb_mobile--80 {
    padding-bottom: 80px; }
  .pb_mobile--85 {
    padding-bottom: 85px; }
  .pl_mobile--85 {
    padding-left: 85px; }
  .pr_mobile--85 {
    padding-right: 85px; }
  .pt_mobile--85 {
    padding-top: 85px; }
  .pb_mobile--85 {
    padding-bottom: 85px; }
  .pb_mobile--90 {
    padding-bottom: 90px; }
  .pl_mobile--90 {
    padding-left: 90px; }
  .pr_mobile--90 {
    padding-right: 90px; }
  .pt_mobile--90 {
    padding-top: 90px; }
  .pb_mobile--90 {
    padding-bottom: 90px; }
  .pb_mobile--95 {
    padding-bottom: 95px; }
  .pl_mobile--95 {
    padding-left: 95px; }
  .pr_mobile--95 {
    padding-right: 95px; }
  .pt_mobile--95 {
    padding-top: 95px; }
  .pb_mobile--95 {
    padding-bottom: 95px; }
  .pb_mobile--100 {
    padding-bottom: 100px; }
  .pl_mobile--100 {
    padding-left: 100px; }
  .pr_mobile--100 {
    padding-right: 100px; }
  .pt_mobile--100 {
    padding-top: 100px; }
  .pb_mobile--100 {
    padding-bottom: 100px; } }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .pb_lp--5 {
    padding-bottom: 5px; }
  .pl_lp--5 {
    padding-left: 5px; }
  .pr_lp--5 {
    padding-right: 5px; }
  .pt_lp--5 {
    padding-top: 5px; }
  .pb_lp--5 {
    padding-bottom: 5px; }
  .pb_lp--10 {
    padding-bottom: 10px; }
  .pl_lp--10 {
    padding-left: 10px; }
  .pr_lp--10 {
    padding-right: 10px; }
  .pt_lp--10 {
    padding-top: 10px; }
  .pb_lp--10 {
    padding-bottom: 10px; }
  .pb_lp--15 {
    padding-bottom: 15px; }
  .pl_lp--15 {
    padding-left: 15px; }
  .pr_lp--15 {
    padding-right: 15px; }
  .pt_lp--15 {
    padding-top: 15px; }
  .pb_lp--15 {
    padding-bottom: 15px; }
  .pb_lp--20 {
    padding-bottom: 20px; }
  .pl_lp--20 {
    padding-left: 20px; }
  .pr_lp--20 {
    padding-right: 20px; }
  .pt_lp--20 {
    padding-top: 20px; }
  .pb_lp--20 {
    padding-bottom: 20px; }
  .pb_lp--25 {
    padding-bottom: 25px; }
  .pl_lp--25 {
    padding-left: 25px; }
  .pr_lp--25 {
    padding-right: 25px; }
  .pt_lp--25 {
    padding-top: 25px; }
  .pb_lp--25 {
    padding-bottom: 25px; }
  .pb_lp--30 {
    padding-bottom: 30px; }
  .pl_lp--30 {
    padding-left: 30px; }
  .pr_lp--30 {
    padding-right: 30px; }
  .pt_lp--30 {
    padding-top: 30px; }
  .pb_lp--30 {
    padding-bottom: 30px; }
  .pb_lp--35 {
    padding-bottom: 35px; }
  .pl_lp--35 {
    padding-left: 35px; }
  .pr_lp--35 {
    padding-right: 35px; }
  .pt_lp--35 {
    padding-top: 35px; }
  .pb_lp--35 {
    padding-bottom: 35px; }
  .pb_lp--40 {
    padding-bottom: 40px; }
  .pl_lp--40 {
    padding-left: 40px; }
  .pr_lp--40 {
    padding-right: 40px; }
  .pt_lp--40 {
    padding-top: 40px; }
  .pb_lp--40 {
    padding-bottom: 40px; }
  .pb_lp--45 {
    padding-bottom: 45px; }
  .pl_lp--45 {
    padding-left: 45px; }
  .pr_lp--45 {
    padding-right: 45px; }
  .pt_lp--45 {
    padding-top: 45px; }
  .pb_lp--45 {
    padding-bottom: 45px; }
  .pb_lp--50 {
    padding-bottom: 50px; }
  .pl_lp--50 {
    padding-left: 50px; }
  .pr_lp--50 {
    padding-right: 50px; }
  .pt_lp--50 {
    padding-top: 50px; }
  .pb_lp--50 {
    padding-bottom: 50px; }
  .pb_lp--55 {
    padding-bottom: 55px; }
  .pl_lp--55 {
    padding-left: 55px; }
  .pr_lp--55 {
    padding-right: 55px; }
  .pt_lp--55 {
    padding-top: 55px; }
  .pb_lp--55 {
    padding-bottom: 55px; }
  .pb_lp--60 {
    padding-bottom: 60px; }
  .pl_lp--60 {
    padding-left: 60px; }
  .pr_lp--60 {
    padding-right: 60px; }
  .pt_lp--60 {
    padding-top: 60px; }
  .pb_lp--60 {
    padding-bottom: 60px; }
  .pb_lp--65 {
    padding-bottom: 65px; }
  .pl_lp--65 {
    padding-left: 65px; }
  .pr_lp--65 {
    padding-right: 65px; }
  .pt_lp--65 {
    padding-top: 65px; }
  .pb_lp--65 {
    padding-bottom: 65px; }
  .pb_lp--70 {
    padding-bottom: 70px; }
  .pl_lp--70 {
    padding-left: 70px; }
  .pr_lp--70 {
    padding-right: 70px; }
  .pt_lp--70 {
    padding-top: 70px; }
  .pb_lp--70 {
    padding-bottom: 70px; }
  .pb_lp--75 {
    padding-bottom: 75px; }
  .pl_lp--75 {
    padding-left: 75px; }
  .pr_lp--75 {
    padding-right: 75px; }
  .pt_lp--75 {
    padding-top: 75px; }
  .pb_lp--75 {
    padding-bottom: 75px; }
  .pb_lp--80 {
    padding-bottom: 80px; }
  .pl_lp--80 {
    padding-left: 80px; }
  .pr_lp--80 {
    padding-right: 80px; }
  .pt_lp--80 {
    padding-top: 80px; }
  .pb_lp--80 {
    padding-bottom: 80px; }
  .pb_lp--85 {
    padding-bottom: 85px; }
  .pl_lp--85 {
    padding-left: 85px; }
  .pr_lp--85 {
    padding-right: 85px; }
  .pt_lp--85 {
    padding-top: 85px; }
  .pb_lp--85 {
    padding-bottom: 85px; }
  .pb_lp--90 {
    padding-bottom: 90px; }
  .pl_lp--90 {
    padding-left: 90px; }
  .pr_lp--90 {
    padding-right: 90px; }
  .pt_lp--90 {
    padding-top: 90px; }
  .pb_lp--90 {
    padding-bottom: 90px; }
  .pb_lp--95 {
    padding-bottom: 95px; }
  .pl_lp--95 {
    padding-left: 95px; }
  .pr_lp--95 {
    padding-right: 95px; }
  .pt_lp--95 {
    padding-top: 95px; }
  .pb_lp--95 {
    padding-bottom: 95px; }
  .pb_lp--100 {
    padding-bottom: 100px; }
  .pl_lp--100 {
    padding-left: 100px; }
  .pr_lp--100 {
    padding-right: 100px; }
  .pt_lp--100 {
    padding-top: 100px; }
  .pb_lp--100 {
    padding-bottom: 100px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mb_lg--5 {
    margin-bottom: 5px; }
  .ml_lg--5 {
    margin-left: 5px; }
  .mr_lg--5 {
    margin-right: 5px; }
  .mt_lg--5 {
    margin-top: 5px; }
  .mb_lg--10 {
    margin-bottom: 10px; }
  .ml_lg--10 {
    margin-left: 10px; }
  .mr_lg--10 {
    margin-right: 10px; }
  .mt_lg--10 {
    margin-top: 10px; }
  .mb_lg--15 {
    margin-bottom: 15px; }
  .ml_lg--15 {
    margin-left: 15px; }
  .mr_lg--15 {
    margin-right: 15px; }
  .mt_lg--15 {
    margin-top: 15px; }
  .mb_lg--20 {
    margin-bottom: 20px; }
  .ml_lg--20 {
    margin-left: 20px; }
  .mr_lg--20 {
    margin-right: 20px; }
  .mt_lg--20 {
    margin-top: 20px; }
  .mb_lg--25 {
    margin-bottom: 25px; }
  .ml_lg--25 {
    margin-left: 25px; }
  .mr_lg--25 {
    margin-right: 25px; }
  .mt_lg--25 {
    margin-top: 25px; }
  .mb_lg--30 {
    margin-bottom: 30px; }
  .ml_lg--30 {
    margin-left: 30px; }
  .mr_lg--30 {
    margin-right: 30px; }
  .mt_lg--30 {
    margin-top: 30px; }
  .mb_lg--35 {
    margin-bottom: 35px; }
  .ml_lg--35 {
    margin-left: 35px; }
  .mr_lg--35 {
    margin-right: 35px; }
  .mt_lg--35 {
    margin-top: 35px; }
  .mb_lg--40 {
    margin-bottom: 40px; }
  .ml_lg--40 {
    margin-left: 40px; }
  .mr_lg--40 {
    margin-right: 40px; }
  .mt_lg--40 {
    margin-top: 40px; }
  .mb_lg--45 {
    margin-bottom: 45px; }
  .ml_lg--45 {
    margin-left: 45px; }
  .mr_lg--45 {
    margin-right: 45px; }
  .mt_lg--45 {
    margin-top: 45px; }
  .mb_lg--50 {
    margin-bottom: 50px; }
  .ml_lg--50 {
    margin-left: 50px; }
  .mr_lg--50 {
    margin-right: 50px; }
  .mt_lg--50 {
    margin-top: 50px; }
  .mb_lg--55 {
    margin-bottom: 55px; }
  .ml_lg--55 {
    margin-left: 55px; }
  .mr_lg--55 {
    margin-right: 55px; }
  .mt_lg--55 {
    margin-top: 55px; }
  .mb_lg--60 {
    margin-bottom: 60px; }
  .ml_lg--60 {
    margin-left: 60px; }
  .mr_lg--60 {
    margin-right: 60px; }
  .mt_lg--60 {
    margin-top: 60px; }
  .mb_lg--65 {
    margin-bottom: 65px; }
  .ml_lg--65 {
    margin-left: 65px; }
  .mr_lg--65 {
    margin-right: 65px; }
  .mt_lg--65 {
    margin-top: 65px; }
  .mb_lg--70 {
    margin-bottom: 70px; }
  .ml_lg--70 {
    margin-left: 70px; }
  .mr_lg--70 {
    margin-right: 70px; }
  .mt_lg--70 {
    margin-top: 70px; }
  .mb_lg--75 {
    margin-bottom: 75px; }
  .ml_lg--75 {
    margin-left: 75px; }
  .mr_lg--75 {
    margin-right: 75px; }
  .mt_lg--75 {
    margin-top: 75px; }
  .mb_lg--80 {
    margin-bottom: 80px; }
  .ml_lg--80 {
    margin-left: 80px; }
  .mr_lg--80 {
    margin-right: 80px; }
  .mt_lg--80 {
    margin-top: 80px; }
  .mb_lg--85 {
    margin-bottom: 85px; }
  .ml_lg--85 {
    margin-left: 85px; }
  .mr_lg--85 {
    margin-right: 85px; }
  .mt_lg--85 {
    margin-top: 85px; }
  .mb_lg--90 {
    margin-bottom: 90px; }
  .ml_lg--90 {
    margin-left: 90px; }
  .mr_lg--90 {
    margin-right: 90px; }
  .mt_lg--90 {
    margin-top: 90px; }
  .mb_lg--95 {
    margin-bottom: 95px; }
  .ml_lg--95 {
    margin-left: 95px; }
  .mr_lg--95 {
    margin-right: 95px; }
  .mt_lg--95 {
    margin-top: 95px; }
  .mb_lg--100 {
    margin-bottom: 100px; }
  .ml_lg--100 {
    margin-left: 100px; }
  .mr_lg--100 {
    margin-right: 100px; }
  .mt_lg--100 {
    margin-top: 100px; }
  .ml_lg--0 {
    margin-left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb_md--5 {
    margin-bottom: 5px; }
  .ml_md--5 {
    margin-left: 5px; }
  .mr_md--5 {
    margin-right: 5px; }
  .mt_md--5 {
    margin-top: 5px; }
  .mb_md--10 {
    margin-bottom: 10px; }
  .ml_md--10 {
    margin-left: 10px; }
  .mr_md--10 {
    margin-right: 10px; }
  .mt_md--10 {
    margin-top: 10px; }
  .mb_md--15 {
    margin-bottom: 15px; }
  .ml_md--15 {
    margin-left: 15px; }
  .mr_md--15 {
    margin-right: 15px; }
  .mt_md--15 {
    margin-top: 15px; }
  .mb_md--20 {
    margin-bottom: 20px; }
  .ml_md--20 {
    margin-left: 20px; }
  .mr_md--20 {
    margin-right: 20px; }
  .mt_md--20 {
    margin-top: 20px; }
  .mb_md--25 {
    margin-bottom: 25px; }
  .ml_md--25 {
    margin-left: 25px; }
  .mr_md--25 {
    margin-right: 25px; }
  .mt_md--25 {
    margin-top: 25px; }
  .mb_md--30 {
    margin-bottom: 30px; }
  .ml_md--30 {
    margin-left: 30px; }
  .mr_md--30 {
    margin-right: 30px; }
  .mt_md--30 {
    margin-top: 30px; }
  .mb_md--35 {
    margin-bottom: 35px; }
  .ml_md--35 {
    margin-left: 35px; }
  .mr_md--35 {
    margin-right: 35px; }
  .mt_md--35 {
    margin-top: 35px; }
  .mb_md--40 {
    margin-bottom: 40px; }
  .ml_md--40 {
    margin-left: 40px; }
  .mr_md--40 {
    margin-right: 40px; }
  .mt_md--40 {
    margin-top: 40px; }
  .mb_md--45 {
    margin-bottom: 45px; }
  .ml_md--45 {
    margin-left: 45px; }
  .mr_md--45 {
    margin-right: 45px; }
  .mt_md--45 {
    margin-top: 45px; }
  .mb_md--50 {
    margin-bottom: 50px; }
  .ml_md--50 {
    margin-left: 50px; }
  .mr_md--50 {
    margin-right: 50px; }
  .mt_md--50 {
    margin-top: 50px; }
  .mb_md--55 {
    margin-bottom: 55px; }
  .ml_md--55 {
    margin-left: 55px; }
  .mr_md--55 {
    margin-right: 55px; }
  .mt_md--55 {
    margin-top: 55px; }
  .mb_md--60 {
    margin-bottom: 60px; }
  .ml_md--60 {
    margin-left: 60px; }
  .mr_md--60 {
    margin-right: 60px; }
  .mt_md--60 {
    margin-top: 60px; }
  .mb_md--65 {
    margin-bottom: 65px; }
  .ml_md--65 {
    margin-left: 65px; }
  .mr_md--65 {
    margin-right: 65px; }
  .mt_md--65 {
    margin-top: 65px; }
  .mb_md--70 {
    margin-bottom: 70px; }
  .ml_md--70 {
    margin-left: 70px; }
  .mr_md--70 {
    margin-right: 70px; }
  .mt_md--70 {
    margin-top: 70px; }
  .mb_md--75 {
    margin-bottom: 75px; }
  .ml_md--75 {
    margin-left: 75px; }
  .mr_md--75 {
    margin-right: 75px; }
  .mt_md--75 {
    margin-top: 75px; }
  .mb_md--80 {
    margin-bottom: 80px; }
  .ml_md--80 {
    margin-left: 80px; }
  .mr_md--80 {
    margin-right: 80px; }
  .mt_md--80 {
    margin-top: 80px; }
  .mb_md--85 {
    margin-bottom: 85px; }
  .ml_md--85 {
    margin-left: 85px; }
  .mr_md--85 {
    margin-right: 85px; }
  .mt_md--85 {
    margin-top: 85px; }
  .mb_md--90 {
    margin-bottom: 90px; }
  .ml_md--90 {
    margin-left: 90px; }
  .mr_md--90 {
    margin-right: 90px; }
  .mt_md--90 {
    margin-top: 90px; }
  .mb_md--95 {
    margin-bottom: 95px; }
  .ml_md--95 {
    margin-left: 95px; }
  .mr_md--95 {
    margin-right: 95px; }
  .mt_md--95 {
    margin-top: 95px; }
  .mb_md--100 {
    margin-bottom: 100px; }
  .ml_md--100 {
    margin-left: 100px; }
  .mr_md--100 {
    margin-right: 100px; }
  .mt_md--100 {
    margin-top: 100px; }
  .ml_md--0 {
    margin-left: 0; } }

@media only screen and (max-width: 767px) {
  .mb_sm--5 {
    margin-bottom: 5px; }
  .ml_sm--5 {
    margin-left: 5px; }
  .mr_sm--5 {
    margin-right: 5px; }
  .mt_sm--5 {
    margin-top: 5px; }
  .mb_sm--10 {
    margin-bottom: 10px; }
  .ml_sm--10 {
    margin-left: 10px; }
  .mr_sm--10 {
    margin-right: 10px; }
  .mt_sm--10 {
    margin-top: 10px; }
  .mb_sm--15 {
    margin-bottom: 15px; }
  .ml_sm--15 {
    margin-left: 15px; }
  .mr_sm--15 {
    margin-right: 15px; }
  .mt_sm--15 {
    margin-top: 15px; }
  .mb_sm--20 {
    margin-bottom: 20px; }
  .ml_sm--20 {
    margin-left: 20px; }
  .mr_sm--20 {
    margin-right: 20px; }
  .mt_sm--20 {
    margin-top: 20px; }
  .mb_sm--25 {
    margin-bottom: 25px; }
  .ml_sm--25 {
    margin-left: 25px; }
  .mr_sm--25 {
    margin-right: 25px; }
  .mt_sm--25 {
    margin-top: 25px; }
  .mb_sm--30 {
    margin-bottom: 30px; }
  .ml_sm--30 {
    margin-left: 30px; }
  .mr_sm--30 {
    margin-right: 30px; }
  .mt_sm--30 {
    margin-top: 30px; }
  .mb_sm--35 {
    margin-bottom: 35px; }
  .ml_sm--35 {
    margin-left: 35px; }
  .mr_sm--35 {
    margin-right: 35px; }
  .mt_sm--35 {
    margin-top: 35px; }
  .mb_sm--40 {
    margin-bottom: 40px; }
  .ml_sm--40 {
    margin-left: 40px; }
  .mr_sm--40 {
    margin-right: 40px; }
  .mt_sm--40 {
    margin-top: 40px; }
  .mb_sm--45 {
    margin-bottom: 45px; }
  .ml_sm--45 {
    margin-left: 45px; }
  .mr_sm--45 {
    margin-right: 45px; }
  .mt_sm--45 {
    margin-top: 45px; }
  .mb_sm--50 {
    margin-bottom: 50px; }
  .ml_sm--50 {
    margin-left: 50px; }
  .mr_sm--50 {
    margin-right: 50px; }
  .mt_sm--50 {
    margin-top: 50px; }
  .mb_sm--55 {
    margin-bottom: 55px; }
  .ml_sm--55 {
    margin-left: 55px; }
  .mr_sm--55 {
    margin-right: 55px; }
  .mt_sm--55 {
    margin-top: 55px; }
  .mb_sm--60 {
    margin-bottom: 60px; }
  .ml_sm--60 {
    margin-left: 60px; }
  .mr_sm--60 {
    margin-right: 60px; }
  .mt_sm--60 {
    margin-top: 60px; }
  .mb_sm--65 {
    margin-bottom: 65px; }
  .ml_sm--65 {
    margin-left: 65px; }
  .mr_sm--65 {
    margin-right: 65px; }
  .mt_sm--65 {
    margin-top: 65px; }
  .mb_sm--70 {
    margin-bottom: 70px; }
  .ml_sm--70 {
    margin-left: 70px; }
  .mr_sm--70 {
    margin-right: 70px; }
  .mt_sm--70 {
    margin-top: 70px; }
  .mb_sm--75 {
    margin-bottom: 75px; }
  .ml_sm--75 {
    margin-left: 75px; }
  .mr_sm--75 {
    margin-right: 75px; }
  .mt_sm--75 {
    margin-top: 75px; }
  .mb_sm--80 {
    margin-bottom: 80px; }
  .ml_sm--80 {
    margin-left: 80px; }
  .mr_sm--80 {
    margin-right: 80px; }
  .mt_sm--80 {
    margin-top: 80px; }
  .mb_sm--85 {
    margin-bottom: 85px; }
  .ml_sm--85 {
    margin-left: 85px; }
  .mr_sm--85 {
    margin-right: 85px; }
  .mt_sm--85 {
    margin-top: 85px; }
  .mb_sm--90 {
    margin-bottom: 90px; }
  .ml_sm--90 {
    margin-left: 90px; }
  .mr_sm--90 {
    margin-right: 90px; }
  .mt_sm--90 {
    margin-top: 90px; }
  .mb_sm--95 {
    margin-bottom: 95px; }
  .ml_sm--95 {
    margin-left: 95px; }
  .mr_sm--95 {
    margin-right: 95px; }
  .mt_sm--95 {
    margin-top: 95px; }
  .mb_sm--100 {
    margin-bottom: 100px; }
  .ml_sm--100 {
    margin-left: 100px; }
  .mr_sm--100 {
    margin-right: 100px; }
  .mt_sm--100 {
    margin-top: 100px; }
  .ml_sm--0 {
    margin-left: 0; } }

@media only screen and (max-width: 575px) {
  .mb_mobile--5 {
    margin-bottom: 5px; }
  .ml_mobile--5 {
    margin-left: 5px; }
  .mr_mobile--5 {
    margin-right: 5px; }
  .mt_mobile--5 {
    margin-top: 5px; }
  .mb_mobile--10 {
    margin-bottom: 10px; }
  .ml_mobile--10 {
    margin-left: 10px; }
  .mr_mobile--10 {
    margin-right: 10px; }
  .mt_mobile--10 {
    margin-top: 10px; }
  .mb_mobile--15 {
    margin-bottom: 15px; }
  .ml_mobile--15 {
    margin-left: 15px; }
  .mr_mobile--15 {
    margin-right: 15px; }
  .mt_mobile--15 {
    margin-top: 15px; }
  .mb_mobile--20 {
    margin-bottom: 20px; }
  .ml_mobile--20 {
    margin-left: 20px; }
  .mr_mobile--20 {
    margin-right: 20px; }
  .mt_mobile--20 {
    margin-top: 20px; }
  .mb_mobile--25 {
    margin-bottom: 25px; }
  .ml_mobile--25 {
    margin-left: 25px; }
  .mr_mobile--25 {
    margin-right: 25px; }
  .mt_mobile--25 {
    margin-top: 25px; }
  .mb_mobile--30 {
    margin-bottom: 30px; }
  .ml_mobile--30 {
    margin-left: 30px; }
  .mr_mobile--30 {
    margin-right: 30px; }
  .mt_mobile--30 {
    margin-top: 30px; }
  .mb_mobile--35 {
    margin-bottom: 35px; }
  .ml_mobile--35 {
    margin-left: 35px; }
  .mr_mobile--35 {
    margin-right: 35px; }
  .mt_mobile--35 {
    margin-top: 35px; }
  .mb_mobile--40 {
    margin-bottom: 40px; }
  .ml_mobile--40 {
    margin-left: 40px; }
  .mr_mobile--40 {
    margin-right: 40px; }
  .mt_mobile--40 {
    margin-top: 40px; }
  .mb_mobile--45 {
    margin-bottom: 45px; }
  .ml_mobile--45 {
    margin-left: 45px; }
  .mr_mobile--45 {
    margin-right: 45px; }
  .mt_mobile--45 {
    margin-top: 45px; }
  .mb_mobile--50 {
    margin-bottom: 50px; }
  .ml_mobile--50 {
    margin-left: 50px; }
  .mr_mobile--50 {
    margin-right: 50px; }
  .mt_mobile--50 {
    margin-top: 50px; }
  .mb_mobile--55 {
    margin-bottom: 55px; }
  .ml_mobile--55 {
    margin-left: 55px; }
  .mr_mobile--55 {
    margin-right: 55px; }
  .mt_mobile--55 {
    margin-top: 55px; }
  .mb_mobile--60 {
    margin-bottom: 60px; }
  .ml_mobile--60 {
    margin-left: 60px; }
  .mr_mobile--60 {
    margin-right: 60px; }
  .mt_mobile--60 {
    margin-top: 60px; }
  .mb_mobile--65 {
    margin-bottom: 65px; }
  .ml_mobile--65 {
    margin-left: 65px; }
  .mr_mobile--65 {
    margin-right: 65px; }
  .mt_mobile--65 {
    margin-top: 65px; }
  .mb_mobile--70 {
    margin-bottom: 70px; }
  .ml_mobile--70 {
    margin-left: 70px; }
  .mr_mobile--70 {
    margin-right: 70px; }
  .mt_mobile--70 {
    margin-top: 70px; }
  .mb_mobile--75 {
    margin-bottom: 75px; }
  .ml_mobile--75 {
    margin-left: 75px; }
  .mr_mobile--75 {
    margin-right: 75px; }
  .mt_mobile--75 {
    margin-top: 75px; }
  .mb_mobile--80 {
    margin-bottom: 80px; }
  .ml_mobile--80 {
    margin-left: 80px; }
  .mr_mobile--80 {
    margin-right: 80px; }
  .mt_mobile--80 {
    margin-top: 80px; }
  .mb_mobile--85 {
    margin-bottom: 85px; }
  .ml_mobile--85 {
    margin-left: 85px; }
  .mr_mobile--85 {
    margin-right: 85px; }
  .mt_mobile--85 {
    margin-top: 85px; }
  .mb_mobile--90 {
    margin-bottom: 90px; }
  .ml_mobile--90 {
    margin-left: 90px; }
  .mr_mobile--90 {
    margin-right: 90px; }
  .mt_mobile--90 {
    margin-top: 90px; }
  .mb_mobile--95 {
    margin-bottom: 95px; }
  .ml_mobile--95 {
    margin-left: 95px; }
  .mr_mobile--95 {
    margin-right: 95px; }
  .mt_mobile--95 {
    margin-top: 95px; }
  .mb_mobile--100 {
    margin-bottom: 100px; }
  .ml_mobile--100 {
    margin-left: 100px; }
  .mr_mobile--100 {
    margin-right: 100px; }
  .mt_mobile--100 {
    margin-top: 100px; } }

/*-----------------------
Padding Top Bottom
----------------------------*/
/* Section Padding Top Bottom */
.section-ptb-xl {
  padding: 150px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-ptb-xl {
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .section-ptb-xl {
      padding: 70px 0; } }
  @media only screen and (max-width: 575px) {
    .section-ptb-xl {
      padding: 60px 0; } }

.section-ptb-120 {
  padding: 120px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-ptb-120 {
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .section-ptb-120 {
      padding: 70px 0; } }
  @media only screen and (max-width: 575px) {
    .section-ptb-120 {
      padding: 60px 0; } }

.section-ptb-110 {
  padding: 110px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-ptb-110 {
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .section-ptb-110 {
      padding: 70px 0; } }
  @media only screen and (max-width: 575px) {
    .section-ptb-110 {
      padding: 60px 0; } }

.section-ptb-100 {
  padding: 100px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-ptb-100 {
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .section-ptb-100 {
      padding: 70px 0; } }
  @media only screen and (max-width: 575px) {
    .section-ptb-100 {
      padding: 60px 0; } }

.section-ptb-150 {
  padding: 150px 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-ptb-150 {
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .section-ptb-150 {
      padding: 70px 0; } }
  @media only screen and (max-width: 575px) {
    .section-ptb-150 {
      padding: 60px 0; } }

/* Section Padding Top */
.section-pt-xl {
  padding-top: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pt-xl {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pt-xl {
      padding-top: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pt-xl {
      padding-top: 60px; } }

.section-pt-110 {
  padding-top: 110px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pt-110 {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pt-110 {
      padding-top: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pt-110 {
      padding-top: 60px; } }

.section-pt-120 {
  padding-top: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pt-120 {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pt-120 {
      padding-top: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pt-120 {
      padding-top: 60px; } }

.section-pt-100 {
  padding-top: 100px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pt-100 {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pt-100 {
      padding-top: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pt-100 {
      padding-top: 60px; } }

.section-pt-150 {
  padding-top: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pt-150 {
      padding-top: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pt-150 {
      padding-top: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pt-150 {
      padding-top: 60px; } }

/* Section Padding Bottom */
.section-pb-xl {
  padding-bottom: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pb-xl {
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pb-xl {
      padding-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pb-xl {
      padding-bottom: 60px; } }

.section-pb-110 {
  padding-bottom: 110px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pb-110 {
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pb-110 {
      padding-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pb-110 {
      padding-bottom: 60px; } }

.section-pb-120 {
  padding-bottom: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pb-120 {
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pb-120 {
      padding-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pb-120 {
      padding-bottom: 60px; } }

.section-pb-100 {
  padding-bottom: 100px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pb-100 {
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pb-100 {
      padding-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pb-100 {
      padding-bottom: 60px; } }

.section-pb-150 {
  padding-bottom: 150px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-pb-150 {
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .section-pb-150 {
      padding-bottom: 70px; } }
  @media only screen and (max-width: 575px) {
    .section-pb-150 {
      padding-bottom: 60px; } }

/* Text specialized */
.text-italic {
  font-style: italic; }

.text-normal {
  font-style: normal; }

.text-underline {
  text-decoration: underline; }

/* Font specialized */
.body-font {
  font-family: "Poppins", sans-serif; }

.heading-font {
  font-family: "louis_george_caferegular"; }

/* Height and width */
.fullscreen {
  min-height: 100vh;
  width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .fullscreen.fullscreen-md--fixed {
      min-height: auto;
      width: 100%;
      padding: 150px 0; } }
  @media only screen and (max-width: 767px) {
    .fullscreen.fullscreen-md--fixed {
      min-height: auto;
      width: 100%;
      padding: 150px 0; } }

.w-100 {
  width: 100%; }

.w-75 {
  width: 75%; }

.w-50 {
  width: 50%; }

.w-33 {
  width: 33.33%; }

.w-25 {
  width: 25%; }

.w-20 {
  width: 20%; }

.w-10 {
  width: 10%; }

.max-width--600 {
  max-width: 600px;
  width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .max-width--600 {
      max-width: 100%; } }
  @media only screen and (max-width: 767px) {
    .max-width--600 {
      max-width: 100%; } }

.h-100 {
  height: 100%; }

.h-75 {
  height: 75%; }

.h-50 {
  height: 50%; }

.h-25 {
  height: 25%; }

.h-33 {
  height: 33.33%; }

.h-10 {
  height: 10%; }

/*-- Custom Row ---*/
.row--0 {
  margin-left: -0px;
  margin-right: -0px; }
  .row--0 > [class*="col"] {
    padding-left: 0px;
    padding-right: 0px; }

.row--2 {
  margin-left: -2px;
  margin-right: -2px; }
  .row--2 > [class*="col"] {
    padding-left: 2px;
    padding-right: 2px; }

.row--5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row--5 > [class*="col"] {
    padding-left: 5px;
    padding-right: 5px; }

.row--10 {
  margin-left: -10px;
  margin-right: -10px; }
  .row--10 > [class*="col"] {
    padding-left: 10px;
    padding-right: 10px; }

.row--20 {
  margin-left: -20px;
  margin-right: -20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--20 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--20 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--20 > [class*="col"],
      .row--20 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--25 {
  margin-left: -25px;
  margin-right: -25px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--25 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--25 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--25 > [class*="col"],
      .row--25 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--30 {
  margin-left: -30px;
  margin-right: -30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--30 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--30 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--30 > [class*="col"],
      .row--30 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--40 {
  margin-left: -40px;
  margin-right: -40px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--40 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--40 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 40px;
    padding-right: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--40 > [class*="col"],
      .row--40 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

.row--45 {
  margin-left: -45px;
  margin-right: -45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .row--45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .row--45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .row--45 {
      margin-left: -15px;
      margin-right: -15px; } }
  @media only screen and (max-width: 767px) {
    .row--45 {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .row--45 > [class*="col"],
  .row--45 > [class*="col-"] {
    padding-left: 45px;
    padding-right: 45px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }
    @media only screen and (max-width: 767px) {
      .row--45 > [class*="col"],
      .row--45 > [class*="col-"] {
        padding-left: 15px !important;
        padding-right: 15px !important; } }

/*------------------------------------
    Input Placeholder
---------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; }

/*--------------------------
	Overlay styles
---------------------------*/
[data-overlay],
[data-black-overlay],
[data-heading-overlay],
[data-light-overlay],
[data-white-overlay] {
  position: relative; }

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-light-overlay] > *,
[data-white-overlay] > div,
[data-heading-overlay] > div,
[data-light-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2; }

[data-overlay]:before,
[data-black-overlay]:before,
[data-heading-overlay]:before,
[data-light-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2; }

[data-overlay]:before {
  background-color: #0038E3; }

[data-black-overlay]:before {
  background-color: #000000; }

[data-light-overlay]:before {
  background-color: #5e0891; }

[data-white-overlay]:before {
  background-color: #ffffff; }

[data-heading-overlay]:before {
  background-color: #222222; }

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before,
[data-light-overlay="1"]:before,
[data-heading-overlay="1"]:before {
  opacity: 0.1; }

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before,
[data-light-overlay="2"]:before,
[data-heading-overlay="2"]:before {
  opacity: 0.2; }

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before,
[data-light-overlay="3"]:before,
[data-heading-overlay="3"]:before {
  opacity: 0.3; }

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before,
[data-light-overlay="4"]:before,
[data-heading-overlay="4"]:before {
  opacity: 0.4; }

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before,
[data-light-overlay="5"]:before,
[data-heading-overlay="5"]:before {
  opacity: 0.5; }

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before,
[data-light-overlay="6"]:before,
[data-heading-overlay="6"]:before {
  opacity: 0.6; }

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before,
[data-light-overlay="7"]:before,
[data-heading-overlay="7"]:before {
  opacity: 0.7; }

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before,
[data-light-overlay="8"]:before,
[data-heading-overlay="8"]:before {
  opacity: 0.8; }

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before,
[data-light-overlay="9"]:before,
[data-heading-overlay="9"]:before {
  opacity: 0.9; }

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before,
[data-light-overlay="10"]:before,
[data-heading-overlay="10"]:before {
  opacity: 1; }

.bg-theme,
.bg-dark,
.bg-dark-light,
.bg-secondary {
  color: #ffffff; }
  .bg-theme h1,
  .bg-theme h2,
  .bg-theme h3,
  .bg-theme h4,
  .bg-theme h5,
  .bg-theme h6,
  .bg-theme p,
  .bg-dark h1,
  .bg-dark h2,
  .bg-dark h3,
  .bg-dark h4,
  .bg-dark h5,
  .bg-dark h6,
  .bg-dark p,
  .bg-dark-light h1,
  .bg-dark-light h2,
  .bg-dark-light h3,
  .bg-dark-light h4,
  .bg-dark-light h5,
  .bg-dark-light h6,
  .bg-dark-light p,
  .bg-secondary h1,
  .bg-secondary h2,
  .bg-secondary h3,
  .bg-secondary h4,
  .bg-secondary h5,
  .bg-secondary h6,
  .bg-secondary p {
    color: #ffffff; }

/*------------------------------
    ScrollTo Up
--------------------------------*/
#scrollUp {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 25px;
  line-height: 60px;
  background-color: #ffffff;
  color: #222222;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  z-index: 9811 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
  @media only screen and (max-width: 479px) {
    #scrollUp {
      display: none !important; } }
  #scrollUp i {
    display: block;
    line-height: 55px;
    font-size: 25px; }
  #scrollUp:hover i {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite; }

.form-message {
  margin-bottom: 0;
  text-align: center; }
  .form-message.error {
    margin-top: 20px;
    color: #f80707; }
  .form-message.success {
    margin-top: 20px;
    color: #0d8d2d; }

.bk-parallax {
  position: relative;
  z-index: 0; }

.bk-parallax > .bk-parallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.lr-padding {
  padding: 0 120px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .lr-padding {
      padding: 50px 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .lr-padding {
      padding: 50px 15px; } }
  @media only screen and (max-width: 767px) {
    .lr-padding {
      padding: 50px 15px; } }

.mr-30 {
  margin-right: 30px !important; }

.slick-slide .service.service--3 .thumb img {
  width: 100%; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pl--50 {
    padding-left: 0 !important; } }

@media only screen and (max-width: 767px) {
  .pl--50 {
    padding-left: 0 !important; } }

.z-index {
  z-index: 9; }

/*----------------------
    Social Color
------------------------*/
/*====================================
	Elements Styles
=====================================*/
/*============================
 * Button
================================*/
a.bk-btn,
button.bk-btn {
  padding: 0 36px;
  height: 55px;
  line-height: 55px;
  border-width: 0;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "Poppins", sans-serif; }
  a.bk-btn.theme-btn,
  button.bk-btn.theme-btn {
    background-color: #0038E3; }
  a.bk-btn:hover,
  button.bk-btn:hover {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px); }

.marketing-color {
  border-color: #CC327B !important;
  background-color: #CC327B !important;
  color: #fff !important; }

.button-group .spacing {
  height: 46px; }

a.brook-btn,
button.brook-btn {
  color: #fff;
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  font-weight: 600;
  letter-spacing: 0em;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  border-style: solid;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  a.brook-btn.text-dark,
  button.brook-btn.text-dark {
    color: #001029; }
  a.brook-btn.text-theme,
  button.brook-btn.text-theme {
    color: #0038E3; }
  a.brook-btn.text-white,
  button.brook-btn.text-white {
    color: #ffffff; }
  a.brook-btn.space-between,
  button.brook-btn.space-between {
    margin: 5px; }
  a.brook-btn.with-no-shadow,
  button.brook-btn.with-no-shadow {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none; }
  a.brook-btn.bk-btn-theme,
  button.brook-btn.bk-btn-theme {
    background: #0038E3; }
  a.brook-btn.bk-btn-theme-2,
  button.brook-btn.bk-btn-theme-2 {
    background: #0069FF; }
  a.brook-btn.bk-btn-yellow,
  button.brook-btn.bk-btn-yellow {
    background: #FCB72B; }
  a.brook-btn.red-color-2,
  button.brook-btn.red-color-2 {
    background: #F23E20; }
  a.brook-btn.bk-btn-secondary,
  button.brook-btn.bk-btn-secondary {
    background: #899664; }
  a.brook-btn.bk-btn-white,
  button.brook-btn.bk-btn-white {
    background: #ffffff;
    color: #222222; }
  a.brook-btn.bk-btn-green,
  button.brook-btn.bk-btn-green {
    background: #68AE4A;
    color: #fff; }
  a.brook-btn.bk-btn-gray,
  button.brook-btn.bk-btn-gray {
    background-color: #ededed;
    color: #222222;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "CerebriSans";
    box-shadow: none; }
    a.brook-btn.bk-btn-gray:hover,
    button.brook-btn.bk-btn-gray:hover {
      background-color: #9F3939;
      color: #fff; }
  a.brook-btn.bk-btn-pink,
  button.brook-btn.bk-btn-pink {
    background: #f55d4e; }
  a.brook-btn.bk-btn-red,
  button.brook-btn.bk-btn-red {
    background: #F0263F; }
  a.brook-btn.red-color-5,
  button.brook-btn.red-color-5 {
    background: #d0021b; }
  a.brook-btn.color-green,
  button.brook-btn.color-green {
    background: #899664; }
  a.brook-btn.color-green-2,
  button.brook-btn.color-green-2 {
    background: #88db5e; }
  a.brook-btn.brown-3,
  button.brook-btn.brown-3 {
    background: #CAC0B3; }
  a.brook-btn.red-color-3,
  button.brook-btn.red-color-3 {
    background: #F55D4E; }
    a.brook-btn.red-color-3:hover,
    button.brook-btn.red-color-3:hover {
      background: #F55D4E;
      color: #fff; }
  a.brook-btn.bk-btn-dark,
  button.brook-btn.bk-btn-dark {
    background: #222222; }
    a.brook-btn.bk-btn-dark:hover,
    button.brook-btn.bk-btn-dark:hover {
      background: #0038E3;
      color: #ffffff; }
    a.brook-btn.bk-btn-dark.red-color-3:hover,
    button.brook-btn.bk-btn-dark.red-color-3:hover {
      background: #F55D4E !important;
      color: #fff; }
  a.brook-btn.bk-btn-gradient-pink,
  button.brook-btn.bk-btn-gradient-pink {
    color: #fff;
    border-color: transparent;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, #FF3F85), color-stop(85%, #FAA720));
    background: -moz-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
    background: -webkit-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
    background: -o-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
    background: -ms-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
    background: linear-gradient(150deg, #FF3F85 40%, #FAA720 85%); }
  a.brook-btn.bk-btn-theme-border,
  button.brook-btn.bk-btn-theme-border {
    border-width: 1px;
    background: transparent;
    border-color: #0038E3;
    color: #0038E3; }
    a.brook-btn.bk-btn-theme-border:hover,
    button.brook-btn.bk-btn-theme-border:hover {
      background: #0038E3;
      border-color: #0038E3;
      color: #ffffff; }
  a.brook-btn.bk-btn-secondary-border,
  button.brook-btn.bk-btn-secondary-border {
    border-width: 1px;
    background: #ffffff;
    border-color: #899664;
    color: #899664; }
    a.brook-btn.bk-btn-secondary-border:hover,
    button.brook-btn.bk-btn-secondary-border:hover {
      background: #899664;
      border-color: #899664;
      color: #ffffff; }
  a.brook-btn.bk-btn-white-border,
  button.brook-btn.bk-btn-white-border {
    border-width: 1px;
    background: transparent;
    border-color: #ddd;
    color: #222222; }
    a.brook-btn.bk-btn-white-border:hover,
    button.brook-btn.bk-btn-white-border:hover {
      background: #ffffff;
      border-color: #ffffff;
      color: #222222; }
  a.brook-btn.bk-btn-pink-border,
  button.brook-btn.bk-btn-pink-border {
    border-width: 1px;
    background: #ffffff;
    border-color: #f55d4e;
    color: #f55d4e; }
    a.brook-btn.bk-btn-pink-border:hover,
    button.brook-btn.bk-btn-pink-border:hover {
      background: #f55d4e;
      border-color: #f55d4e;
      color: #ffffff; }
  a.brook-btn.btn-rounded,
  button.brook-btn.btn-rounded {
    border-radius: 6px; }
  a.brook-btn.btn-rectangle,
  button.brook-btn.btn-rectangle {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
  a.brook-btn.btn-bullet,
  button.brook-btn.btn-bullet {
    -moz-border-radius: 500px;
    -webkit-border-radius: 500px;
    border-radius: 500px; }
  a.brook-btn.btn-xs-size,
  button.brook-btn.btn-xs-size {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 13px; }
  a.brook-btn.btn-sm-size,
  button.brook-btn.btn-sm-size {
    padding: 0 30px;
    height: 48px;
    line-height: 48px;
    font-size: 14px; }
  a.brook-btn.btn-sd-size,
  button.brook-btn.btn-sd-size {
    padding: 0 36px;
    height: 55px;
    line-height: 55px;
    font-size: 14px; }
  a.brook-btn.btn-lg-size,
  button.brook-btn.btn-lg-size {
    padding: 0 40px;
    height: 62px;
    line-height: 62px;
    font-size: 16px; }
  a.brook-btn:hover,
  button.brook-btn:hover {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px); }

a.moredetails-btn {
  font-weight: 600;
  font-size: 14px;
  color: #222;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  a.moredetails-btn.font-16 {
    font-size: 16px; }
  a.moredetails-btn:hover {
    color: #0038E3; }
  a.moredetails-btn i {
    padding-left: 20px !important;
    color: #0038E3;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  a.moredetails-btn.red-color i {
    color: #F0263F; }
  a.moredetails-btn.red-color:hover {
    color: #F0263F; }
  a.moredetails-btn.yellow-color-2 i {
    color: #F8A440; }
  a.moredetails-btn.yellow-color-2:hover {
    color: #F8A440; }

.view-more-btn.heding-color a span {
  color: #222222; }

.view-more-btn.headingfont a span {
  font-family: "louis_george_caferegular"; }

.view-more-btn.font-18 a {
  font-size: 18px; }

.view-more-btn.font-700 a {
  font-weight: 700; }

.view-more-btn.font-600 a {
  font-weight: 600; }

.view-more-btn a {
  font-size: 14px; }
  .view-more-btn a span {
    color: #ffffff;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .view-more-btn a span.btn-arrow {
      transition: all .5s !important;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 73px;
      height: 2px;
      background: currentColor;
      margin-left: 14px;
      color: #FCB72B;
      font-size: 14px;
      font-family: "Poppins", sans-serif; }
      .view-more-btn a span.btn-arrow::before {
        content: "";
        position: absolute;
        right: 1px;
        width: 15px;
        height: 15px;
        border: 2px solid currentColor;
        border-left: 0;
        border-bottom: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -6px; }
  .view-more-btn a:hover span.btn-arrow {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%); }

.view-more-btn.brown-color a span.btn-arrow {
  background: #CE8F4F;
  color: #CE8F4F; }

.btn-essential {
  border: 2px solid #eee !important;
  color: #111 !important;
  background: transparent !important;
  width: 100% !important;
  display: block !important;
  text-transform: uppercase;
  box-shadow: none !important;
  margin: 0 !important; }
  .btn-essential:hover {
    background: #68AE4A !important;
    border-color: #68AE4A !important;
    color: #fff !important;
    transform: translateY(0px) !important; }

.btn-restaurant {
  border-radius: 0; }

.video-btn-text {
  display: inline-block;
  font-size: 18px;
  line-height: 32px;
  color: #fff !important;
  font-family: "Roboto", sans-serif; }
  .video-btn-text i {
    margin-right: 5px; }

.corona-btn {
  font-size: 18px !important;
  font-family: "Roboto", sans-serif !important; }

@-webkit-keyframes border-animate-top {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); } }

@keyframes border-animate-top {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); } }

@-webkit-keyframes border-animate-right {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); } }

@keyframes border-animate-right {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); } }

@-webkit-keyframes border-animate-bottom {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@keyframes border-animate-bottom {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@-webkit-keyframes border-animate-left {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); } }

@keyframes border-animate-left {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%); }
  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); } }

.padding-bottom-50 {
  padding-bottom: 50px !important; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-50 {
      padding-bottom: 0px !important; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-50 {
      padding-bottom: 0px !important; } }
  @media only screen and (max-width: 767px) {
    .padding-bottom-50 {
      padding-bottom: 0px !important; } }

.book-table-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center; }
  .book-table-btn .border-animate {
    overflow: hidden;
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px; }
    .book-table-btn .border-animate > div {
      position: absolute; }
      .book-table-btn .border-animate > div.bottom, .book-table-btn .border-animate > div.top {
        width: 100%;
        height: 1px;
        left: 0; }
      .book-table-btn .border-animate > div.top {
        top: 0;
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.3), #fff);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), #fff);
        -webkit-animation: border-animate-top 2s linear infinite;
        animation: border-animate-top 2s linear infinite; }
      .book-table-btn .border-animate > div.bottom {
        background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0.3), #fff);
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.3), #fff);
        bottom: 0;
        -webkit-animation: border-animate-bottom 2s linear infinite;
        animation: border-animate-bottom 2s linear infinite; }
      .book-table-btn .border-animate > div.left, .book-table-btn .border-animate > div.right {
        height: 100%;
        width: 1px;
        top: 0; }
      .book-table-btn .border-animate > div.right {
        right: 0;
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), #fff);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3), #fff);
        -webkit-animation: border-animate-right 2s linear infinite;
        animation: border-animate-right 2s linear infinite;
        -webkit-animation-delay: 1s;
        animation-delay: 1s; }
      .book-table-btn .border-animate > div.left {
        background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 0.3), #fff);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), #fff);
        left: 0;
        -webkit-animation: border-animate-left 2s linear infinite;
        animation: border-animate-left 2s linear infinite;
        -webkit-animation-delay: 1s;
        animation-delay: 1s; }
  .book-table-btn a {
    height: 85px;
    line-height: 85px;
    width: 100%;
    border-radius: 0;
    color: #fff;
    border-color: #BC9464;
    background-color: #BC9464;
    display: block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase; }
    .book-table-btn a:hover {
      color: #fff; }

/* ========================
    Slider Video Play 
=========================*/
.yu2fvl {
  z-index: 9999;
  top: 0; }

.yu2fvl-iframe {
  display: block;
  height: 100%;
  width: 100%;
  border: 0; }

.yu2fvl-overlay {
  z-index: 9998;
  background: #000;
  opacity: 0.8; }

.yu2fvl-close {
  position: absolute;
  top: 1px;
  left: 100%;
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background: #f10 !important;
  background-color: #f10 !important;
  color: #ffffff;
  border: 0;
  background: none;
  cursor: pointer; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%; } }

@media only screen and (max-width: 767px) {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%; }
  .video h2 {
    color: #ffffff;
    font-size: 32px; }
  .video h4 {
    font-size: 22px;
    line-height: 32px; } }

/*==========================
 * Section Title
============================*/
.section-title h3 {
  color: #0038E3;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.43;
  font-family: "Poppins", sans-serif;
  margin-bottom: 39px; }
  @media only screen and (max-width: 767px) {
    .section-title h3 {
      margin-bottom: 16px; } }

.section-title h2 {
  font-size: 48px;
  line-height: 1.25;
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 100%;
  color: #222222; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-title h2 {
      font-size: 38px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title h2 {
      font-size: 38px; }
      .section-title h2 br {
        display: none; } }
  @media only screen and (max-width: 767px) {
    .section-title h2 {
      font-size: 30px; }
      .section-title h2 br {
        display: none; } }

.section-title.white-text h3 {
  color: #ffffff; }

.section-title.white-text h2 {
  color: #ffffff; }

.section-title--2 h2 {
  font-size: 48px;
  line-height: 1.32;
  font-weight: 700;
  color: #222222;
  font-family: "louis_george_caferegular"; }

.section-title.normal-text h2 {
  font-weight: 400; }

.brook-section-title-restaurant h3 {
  font-size: 40px;
  line-height: 1.4;
  font-weight: 400;
  font-family: "Comic Sans MS" !important;
  margin-bottom: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-section-title-restaurant h3 {
      font-size: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-section-title-restaurant h3 {
      font-size: 28px; } }
  @media only screen and (max-width: 767px) {
    .brook-section-title-restaurant h3 {
      font-size: 28px; } }

.brook-section-title-restaurant .title {
  font-size: 46px;
  line-height: 1.22;
  letter-spacing: 3px;
  font-weight: 700;
  font-family: "CerebriSans" !important;
  text-transform: uppercase;
  margin-bottom: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-section-title-restaurant .title {
      font-size: 34px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-section-title-restaurant .title {
      font-size: 34px; } }
  @media only screen and (max-width: 767px) {
    .brook-section-title-restaurant .title {
      font-size: 34px; } }

.brook-section-title-business h5 {
  font-size: 18px;
  font-family: "Spectral", serif !important;
  line-height: 1.29;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-style: italic;
  color: rgba(17, 17, 17, 0.4); }

.brook-section-title-business h3 {
  font-size: 46px;
  margin-bottom: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-section-title-business h3 {
      font-size: 32px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-section-title-business h3 {
      font-size: 28px; } }
  @media only screen and (max-width: 767px) {
    .brook-section-title-business h3 {
      font-size: 26px; } }

.brook-section-title-business .separator::after {
  content: "";
  display: inline-block;
  margin-top: 39px;
  width: 80px;
  height: 2px;
  background-image: linear-gradient(90deg, rgba(159, 57, 57, 0), rgba(159, 57, 57, 0.8), rgba(159, 57, 57, 0)); }

.brook-section-title-corona h2 {
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  color: #0038E3; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-section-title-corona h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-section-title-corona h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (max-width: 767px) {
    .brook-section-title-corona h2 {
      font-size: 30px;
      line-height: 40px; } }

.brook-section-title-corona p {
  font-size: 18px;
  line-height: 34px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .brook-section-title-corona p br {
      display: none; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-section-title-corona p {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-section-title-corona p {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (max-width: 767px) {
    .brook-section-title-corona p {
      font-size: 14px;
      line-height: 24px; } }

.group-link a {
  position: relative;
  font-size: 18px;
  font-family: "CerebriSans";
  color: #222222;
  position: relative;
  padding: 2px 0;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .group-link a::before {
    content: "";
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    -webkit-transition: width 1s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: width 1s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    background: currentColor; }
  .group-link a::after {
    width: 100% !important;
    right: 0 !important;
    left: auto !important;
    z-index: -2;
    background: #ddd;
    content: "";
    height: 1px;
    bottom: 0;
    position: absolute;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s; }
  .group-link a:hover {
    color: #222222; }
    .group-link a:hover::before {
      width: 100%;
      left: 0;
      right: auto; }

.view-btn a {
  position: relative;
  padding: 5px 0;
  display: inline-block;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  color: #111;
  text-transform: uppercase;
  font-family: "CerebriSans"; }
  .view-btn a::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #eee;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .view-btn a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    opacity: 0;
    background: currentColor;
    transform: translateY(10px);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .view-btn a:hover {
    color: #9F3939; }
    .view-btn a:hover::after {
      opacity: 1;
      transform: translateY(0); }

.color-white {
  color: #fff; }

.colo-gray {
  color: rgba(255, 255, 255, 0.4) !important; }

/* Title SEparator */
.title-separator {
  display: block;
  line-height: 0px; }
  .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  .title-separator.w--80::after {
    width: 80px; }
  .title-separator.w--90::after {
    width: 90px; }
  .title-separator.w--100::after {
    width: 100px; }
  .title-separator.w--200::after {
    width: 200px; }
  .title-separator.color-red::after {
    background: #F0263F; }
  .title-separator.color-blue::after {
    background: #0038E3; }

/*=====================================
Creative Agency Section Title 
=======================================*/
.theme-creative {
  background-color: #FE378C;
  font-style: italic;
  font-weight: 700;
  background-color: #FE378C;
  background-image: linear-gradient(-133deg, #FE5B34 0, #FE378C 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text; }

.section-title--between {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }
  .section-title--between .title h3 {
    font-size: 48px;
    line-height: 1.21;
    color: #fff;
    margin-bottom: 0;
    word-wrap: break-word;
    max-width: 100%;
    font-family: 'Montserrat', sans-serif; }
    .section-title--between .title h3 span {
      font-family: 'Playfair Display', serif;
      font-style: italic;
      font-weight: 700; }
  .section-title--between .section-btn a.button-text {
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    white-space: nowrap;
    line-height: 1.2; }
    .section-title--between .section-btn a.button-text i {
      padding-left: 20px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 12px; }
    .section-title--between .section-btn a.button-text:hover i {
      -webkit-transform: translate(5px, 0);
      -ms-transform: translate(5px, 0);
      transform: translate(5px, 0); }

.title--creative h3.heading {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.43;
  margin-bottom: 0;
  word-wrap: break-word;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 34px;
  color: #ffffff; }

.title--creative h2 {
  font-size: 48px;
  line-height: 1.21;
  color: #fff;
  max-width: 470px;
  font-family: 'Montserrat', sans-serif; }
  .title--creative h2 span {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700; }
  @media only screen and (max-width: 767px) {
    .title--creative h2 br {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .title--creative h2 br {
      display: none; } }

.title--creative p {
  font-size: 18px;
  line-height: 1.67;
  color: #ffffff;
  margin-bottom: 45px;
  word-wrap: break-word;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  margin-top: 30px; }

/* ======================
    Service Css
=========================*/
.service.service--1 {
  position: relative;
  padding: 60px 35px 64px;
  background: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1; }
  .service.service--1.theme-color {
    background: #0038E3; }
    .service.service--1.theme-color::before {
      border: 1px solid #000; }
    .service.service--1.theme-color:hover::before {
      border: 2px solid #000 !important; }
    .service.service--1.theme-color .content a.service-btn i {
      color: #222 !important; }
    .service.service--1.theme-color .content a.service-btn:hover {
      color: #222 !important; }
      .service.service--1.theme-color .content a.service-btn:hover i {
        color: #222 !important; }
  .service.service--1::before {
    position: absolute;
    content: "";
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 1px solid #eee;
    opacity: 1;
    visibility: visible;
    z-index: -1; }
  .service.service--1:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1); }
    .service.service--1:hover::before {
      border-color: #0038E3; }
  .service.service--1 .icons {
    margin-bottom: 22px; }
    .service.service--1 .icons i {
      color: #0038E3;
      font-size: 80px; }
  .service.service--1 .content h4 {
    font-size: 18px;
    line-height: 1.38;
    margin-bottom: 18px;
    color: #222222;
    font-family: "Poppins", sans-serif;
    font-weight: 600; }
  .service.service--1 .content p {
    color: #333;
    font-family: 'louis_george_caferegular';
    letter-spacing: 0em;
    line-height: 1.32;
    font-size: 16px; }
  .service.service--1 .content a.service-btn {
    border: 0;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    color: #222;
    display: inline-block;
    margin-top: 46px;
    padding-right: 25px;
    transition: 0.3s; }
    .service.service--1 .content a.service-btn i {
      font-size: 12px;
      left: auto;
      right: auto;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      margin-left: 15px;
      color: #0038E3;
      transition: 0.3s; }
    .service.service--1 .content a.service-btn:hover {
      color: #0038E3; }
      .service.service--1 .content a.service-btn:hover i {
        color: #0038E3; }

.service.bg-transparent {
  background: transparent; }
  .service.bg-transparent .content h4 {
    color: #fff; }
  .service.bg-transparent .content p {
    color: #fff; }
  .service.bg-transparent .content a.service-btn {
    color: #fff; }

.service.service--2 .thumb {
  margin-bottom: 35px; }
  .service.service--2 .thumb img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.service.service--2 .content h4 {
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 21px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif; }

.service.service--2 .content p {
  color: #ffffff;
  max-width: 320px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.6;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0; }

.service.service--2:hover .thumb img {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px); }

.service.service--3 .thumb {
  overflow: hidden; }
  .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.service.service--3 .content {
  padding: 0 20px;
  text-align: center; }
  .service.service--3 .content h3 {
    font-size: 18px;
    margin-bottom: 14px;
    margin-top: 28px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.23;
    font-family: "Poppins", sans-serif; }
    .service.service--3 .content h3 a {
      color: #222222;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .service.service--3 .content h3 a:hover {
        color: #0038E3; }
  .service.service--3 .content p {
    letter-spacing: 0em;
    line-height: 1.32;
    font-size: 16px;
    font-family: "louis_george_caferegular";
    color: #333333; }

.service.service--3:hover .thumb a img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.modern-service-content p {
  font-size: 18px !important;
  line-height: 32px !important;
  max-width: 470px !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .modern-service-content p {
      max-width: 100% !important; } }
  @media only screen and (max-width: 767px) {
    .modern-service-content p {
      max-width: 100% !important; } }

.slick-btn.slick-arrow i {
  font-size: 30px;
  color: #f10; }

.bk-service-list .thumb {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 40%; }
  @media only screen and (max-width: 767px) {
    .bk-service-list .thumb {
      max-width: 100%;
      margin-bottom: 30px; } }

.bk-service-list .content {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 890px; }

/* Architecture Service */
.architecture-service-container {
  position: relative;
  background: #fff;
  overflow: hidden; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .authenthic-service h1.heading-h1 {
    font-size: 37px; } }

.architecture-bg-thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .architecture-bg-thumbnail .inner {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%); }
    .architecture-bg-thumbnail .inner .architecture-hover-image {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: all 1.5s;
      transition: all 1.5s;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .architecture-bg-thumbnail .inner .architecture-hover-image.active {
        z-index: 1;
        opacity: 1;
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05); }
      .architecture-bg-thumbnail .inner .architecture-hover-image .bg-thumb {
        -webkit-transition: all 1s;
        transition: all 1s;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; }
        .architecture-bg-thumbnail .inner .architecture-hover-image .bg-thumb.bg-image--1 {
          background-image: url(/img/slider/type/bg-image-19.jpg); }
        .architecture-bg-thumbnail .inner .architecture-hover-image .bg-thumb.bg-image--2 {
          background-image: url(/img/slider/type/bg-image-20.jpg); }
        .architecture-bg-thumbnail .inner .architecture-hover-image .bg-thumb.bg-image--3 {
          background-image: url(/img/slider/type/bg-image-21.jpg); }
        .architecture-bg-thumbnail .inner .architecture-hover-image .bg-thumb.bg-image--4 {
          background-image: url(/img/slider/type/bg-image-22.jpg); }
        .architecture-bg-thumbnail .inner .architecture-hover-image .bg-thumb.bg-image--5 {
          background-image: url(/img/bg/bg-image-68.jpg); }

.architecture-service {
  min-height: 650px;
  border-right: 1px solid rgba(255, 255, 255, 0.21);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .architecture-service {
      min-height: auto;
      height: auto;
      padding: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .architecture-service {
      min-height: auto;
      height: auto;
      padding: 0 30px; } }
  .architecture-service .architecture-inner {
    padding: 97px 50px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .architecture-service .architecture-inner {
        padding: 60px 0px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .architecture-service .architecture-inner {
        padding: 97px 19px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .architecture-service .architecture-inner {
        padding: 60px 0px; } }
    @media only screen and (max-width: 767px) {
      .architecture-service .architecture-inner {
        padding: 60px 0px; } }
    .architecture-service .architecture-inner .view-more-btn {
      height: 0;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px);
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .architecture-service:hover .architecture-inner .view-more-btn {
    height: auto;
    opacity: 1;
    visibility: visible;
    margin-top: 24px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }

.architecture-service .architecture-inner p {
  color: #fff; }

.service-content h3 {
  font-size: 40px;
  line-height: 1.28;
  font-weight: 200;
  margin-bottom: 30px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .service-content h3 {
      font-size: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .service-content h3 {
      font-size: 28px; } }
  @media only screen and (max-width: 767px) {
    .service-content h3 {
      font-size: 28px; } }

.service-content p {
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 60px; }

/* -------------------
Counterup Area
----------------------*/
/* ---------------------------
    Default Counter Area
-----------------------------*/
.counter-fullwidth-grid {
  width: 100%;
  max-width: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .counter-fullwidth-grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 767px) {
    .counter-fullwidth-grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 575px) {
    .counter-fullwidth-grid {
      grid-template-columns: repeat(1, 1fr); } }

.brook-counter .icon {
  line-height: 1;
  margin-bottom: 32px; }
  .brook-counter .icon i {
    font-size: 80px;
    color: #0038E3; }

.brook-counter .content span {
  margin-bottom: 29px;
  font-size: 48px;
  font-weight: 700;
  color: #222222;
  display: block; }

.brook-counter .content h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333; }

.brook-counter.counter-with-border {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  padding: 40px;
  justify-content: center;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-counter.counter-with-border {
      padding: 63px 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-counter.counter-with-border {
      padding: 63px 30px; } }
  .brook-counter.counter-with-border::before {
    content: "";
    padding-top: 100%; }
  .brook-counter.counter-with-border:hover {
    border: 30px solid #0038E3; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .brook-counter.counter-with-border:hover {
        border: 5px solid #0038E3; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .brook-counter.counter-with-border:hover {
        border: 5px solid #0038E3; } }
    @media only screen and (max-width: 767px) {
      .brook-counter.counter-with-border:hover {
        border: 5px solid #0038E3; } }

.brook-counter.onepage-counter .content span {
  margin-bottom: 21px;
  font-size: 80px;
  font-weight: 600;
  line-height: .9; }
  @media only screen and (max-width: 767px) {
    .brook-counter.onepage-counter .content span {
      font-size: 60px; } }

.brook-counter.onepage-counter .content h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333; }

.brook-counter.product-count .icon i {
  font-size: 80px; }

.brook-counter.product-count .content span {
  margin-bottom: 30px; }

.counterup-bg-shape {
  background-image: url(/img/icons/one-page-shape.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom 200px; }

/* -------------------
Counterup Area
----------------------*/
.number-speaks .content h3 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.43;
  margin-bottom: 0;
  word-wrap: break-word;
  font-family: "Poppins", sans-serif; }

.number-speaks .content h2 {
  font-size: 48px;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 100%;
  color: #222222;
  margin-top: 38px; }

.number-speaks .content a.bk-btn {
  margin-top: 40px; }

.number-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .number-wrap span {
    font-size: 70px;
    font-weight: 700;
    line-height: .9;
    font-family: "louis_george_caferegular";
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .number-wrap .content {
    padding-left: 20px;
    text-align: left;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
    .number-wrap .content h6 {
      font-size: 18px;
      line-height: 1.39;
      margin-bottom: 0;
      color: #222222;
      font-family: "Poppins", sans-serif;
      font-weight: 600; }
    .number-wrap .content p {
      margin-top: 20px;
      font-family: "louis_george_caferegular";
      line-height: 1.32;
      font-size: 16px;
      margin-bottom: 0;
      color: #333; }

/* ==========================
    Conter Style 2
===========================*/
.counter-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  width: 100%;
  max-width: 100%;
  display: -ms-grid;
  display: grid; }
  @media only screen and (max-width: 767px) {
    .counter-grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 479px) {
    .counter-grid {
      grid-template-columns: repeat(1, 1fr); } }

.counter-style--2 {
  text-align: center; }
  .counter-style--2 .icon {
    font-size: 80px;
    line-height: 1;
    display: block;
    margin-bottom: 10px; }
    .counter-style--2 .icon i {
      font-size: 80px;
      color: #FE378C; }
  .counter-style--2 .count {
    font-size: 48px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 3px;
    line-height: 1.6;
    color: #fff; }
  .counter-style--2 .content h6 {
    font-size: 15px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.6;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    margin: 0; }

/* =====================
    Team Area
========================*/
.team-heading .heading-left h3 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.43;
  font-family: "Poppins", sans-serif; }

.team-heading .heading-right button {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #222;
  font-family: "Poppins", sans-serif; }
  .team-heading .heading-right button i {
    padding-left: 10px;
    color: #0038E3; }
  .team-heading .heading-right button:hover {
    color: #0038E3; }

.team.team_style--1 {
  margin-top: 40px; }
  .team.team_style--1 .image-wrap {
    position: relative;
    margin-bottom: 27px; }
    .team.team_style--1 .image-wrap .thumb {
      position: relative;
      overflow: hidden; }
      .team.team_style--1 .image-wrap .thumb img {
        width: 100%; }
      .team.team_style--1 .image-wrap .thumb .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        padding: 30px;
        opacity: .3;
        background-blend-mode: multiply;
        background-size: cover;
        background-color: #0038E3;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .team.team_style--1 .image-wrap .thumb .overlay.red-color {
          background-color: #F0263F; }
      .team.team_style--1 .image-wrap .thumb .shape img {
        position: absolute;
        width: auto;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .team.team_style--1 .image-wrap .thumb .shape img.shape-01 {
          top: 0;
          left: 0;
          -webkit-transform: translateY(-10px);
          -ms-transform: translateY(-10px);
          transform: translateY(-10px); }
        .team.team_style--1 .image-wrap .thumb .shape img.shape-02 {
          right: 0;
          bottom: 0;
          -webkit-transform: translate(10px);
          -ms-transform: translate(10px);
          transform: translate(10px); }
        .team.team_style--1 .image-wrap .thumb .shape img.shape-03 {
          right: 0;
          bottom: 50px;
          -webkit-transform: translateY(10px);
          -ms-transform: translateY(10px);
          transform: translateY(10px); }
    .team.team_style--1 .image-wrap .social-networks {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      padding: 0 20px;
      font-size: 0;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px); }
      .team.team_style--1 .image-wrap .social-networks .inner a {
        display: inline-block;
        font-size: 18px;
        padding: 5px 10px;
        color: #fff;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        position: relative; }
        .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--] .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--] {
          position: relative;
          display: inline-block; }
        .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after, .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:before {
          position: absolute;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          visibility: hidden;
          opacity: 0;
          z-index: 1000000;
          pointer-events: none;
          -webkit-transition: .3s ease;
          transition: .3s ease;
          -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }
        .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:before {
          content: "";
          position: absolute;
          background: transparent;
          border: 7px solid transparent;
          z-index: 1000001; }
        .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
          color: #fff;
          padding: 8px 10px;
          font-size: 14px;
          line-height: 14px;
          white-space: nowrap;
          border-radius: 5px;
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
          content: attr(aria-label);
          background-color: #0038E3; }
        .team.team_style--1 .image-wrap .social-networks .inner a.hint--bounce::before, .team.team_style--1 .image-wrap .social-networks .inner a.hint--bounce::after {
          -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }
        .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before, .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::after {
          bottom: 100%;
          left: 50%; }
        .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
          margin-bottom: -13px;
          border-top-color: #0038E3;
          left: calc(50% - 7px); }
        .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::after {
          bottom: 100%;
          left: 50%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%); }
        .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:hover:after, .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:hover:before {
          visibility: visible;
          opacity: 1;
          -webkit-transition-delay: .1s;
          transition-delay: .1s; }
        .team.team_style--1 .image-wrap .social-networks .inner a:hover[class*=hint--]:after {
          visibility: visible;
          opacity: 1;
          -webkit-transition-delay: .1s;
          transition-delay: .1s; }
        .team.team_style--1 .image-wrap .social-networks .inner a:hover.hint--top::before, .team.team_style--1 .image-wrap .social-networks .inner a:hover.hint--top::after {
          visibility: visible;
          opacity: 1;
          -webkit-transition-delay: .1s;
          transition-delay: .1s; }
        .team.team_style--1 .image-wrap .social-networks .inner a:hover.hint--top::before {
          -webkit-transform: translateY(-8px);
          -ms-transform: translateY(-8px);
          transform: translateY(-8px); }
        .team.team_style--1 .image-wrap .social-networks .inner a:hover.hint--top:after {
          -webkit-transform: translateX(-50%) translateY(-8px);
          -ms-transform: translateX(-50%) translateY(-8px);
          transform: translateX(-50%) translateY(-8px); }
  .team.team_style--1 .info h6 {
    font-size: 18px;
    line-height: 1.388;
    margin-bottom: 4px;
    color: #222222;
    font-weight: 600;
    font-family: "Poppins", sans-serif; }
  .team.team_style--1 .info span {
    letter-spacing: 0em;
    line-height: 1.32;
    font-size: 16px;
    font-family: "louis_george_caferegular";
    color: #333; }
  .team.team_style--1:hover .image-wrap .thumb .overlay {
    opacity: 1;
    background: -webkit-linear-gradient(bottom, #000, transparent 50%) !important;
    background: linear-gradient(0deg, #000 0, transparent 50%) !important;
    background-blend-mode: normal; }
  .team.team_style--1:hover .image-wrap .thumb .shape img {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    visibility: visible;
    opacity: 1; }
  .team.team_style--1:hover .image-wrap .social-networks {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }

.team.team__style--2 .thumb {
  position: relative;
  border-radius: 50%;
  border: 20px solid transparent;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .team.team__style--2 .thumb img {
    border-radius: inherit;
    width: 100%; }
  .team.team__style--2 .thumb .overlay {
    background-color: #0038E3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .team.team__style--2 .thumb .overlay.red-color {
      background-color: #F0263F; }
  .team.team__style--2 .thumb .social-icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 30px;
    font-size: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
    .team.team__style--2 .thumb .social-icon li {
      margin: 0; }
      .team.team__style--2 .thumb .social-icon li a {
        display: inline-block;
        font-size: 18px;
        color: #fff;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        margin: 7px;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .team.team__style--2 .thumb .social-icon li a:hover {
          background: #fff;
          color: #222; }
    .team.team__style--2 .thumb .social-icon.text-center li a:hover {
      background: #fff;
      color: #0038E3 !important; }
  .team.team__style--2 .thumb .team-info {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 58px;
    left: 0;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    @media only screen and (max-width: 575px) {
      .team.team__style--2 .thumb .team-info {
        bottom: 20px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .team.team__style--2 .thumb .team-info {
        bottom: 34px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .team.team__style--2 .thumb .team-info {
        bottom: 34px; } }
    .team.team__style--2 .thumb .team-info .info h5 {
      font-size: 18px;
      line-height: 1.388;
      margin-bottom: 6px;
      color: #fff;
      font-family: "Poppins", sans-serif; }
    .team.team__style--2 .thumb .team-info .info span {
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
      font-weight: 500;
      line-height: 1.58;
      font-size: 14px; }

.team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }
  .team.team__style--2:hover .thumb .overlay {
    opacity: .85;
    visibility: visible; }
  .team.team__style--2:hover .thumb .social-icon {
    opacity: 1;
    visibility: visible; }
  .team.team__style--2:hover .thumb .team-info {
    opacity: 1;
    visibility: visible; }

.team.team__style--3 .thumb {
  position: relative;
  margin-bottom: 38px; }
  .team.team__style--3 .thumb img {
    width: 100%; }
  .team.team__style--3 .thumb .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 30px;
    opacity: 0;
    visibility: hidden;
    background-blend-mode: multiply;
    background-size: cover;
    background-color: #0038E3;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .team.team__style--3 .thumb .overlay.red-color {
      background-color: #F0263F; }
  .team.team__style--3 .thumb .social-icon {
    position: absolute;
    bottom: 33px;
    left: 0;
    padding: 0 30px;
    font-size: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    right: 0; }
    .team.team__style--3 .thumb .social-icon li {
      margin: 0; }
      .team.team__style--3 .thumb .social-icon li a {
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        display: inline-block;
        font-size: 18px;
        color: #222;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        background: #fff;
        margin: 7px; }

.team.team__style--3 .team-info .info h5 {
  font-size: 18px;
  line-height: 1.388;
  margin-bottom: 6px;
  font-family: "Poppins", sans-serif; }

.team.team__style--3 .team-info .info span {
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.58;
  font-size: 14px;
  color: #333333; }

.team.team__style--3:hover .thumb .overlay {
  opacity: 1;
  visibility: visible; }

.team.team__style--3:hover .thumb .social-icon {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

.team.team_style--4 {
  text-align: center; }
  .team.team_style--4 .inner .content-header {
    margin-bottom: 23px; }
    .team.team_style--4 .inner .content-header .social-network {
      margin-top: -43px;
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
      visibility: hidden;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .team.team_style--4 .inner .content-header .social-network .social-list {
        padding: 11px 10px;
        min-width: 160px;
        border-radius: 5px;
        display: inline-block;
        background: #fff;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.06); }
        .team.team_style--4 .inner .content-header .social-network .social-list a {
          display: inline-block;
          padding: 5px 10px;
          line-height: 1.15;
          font-size: 16px;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          color: #999999; }
          .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
            color: #19D2A8; }
  .team.team_style--4 .inner .content-body h4.heading {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 2px;
    color: #222222;
    font-family: "Poppins", sans-serif; }
  .team.team_style--4 .inner .content-body .position {
    font-size: 14px;
    color: #333; }
  .team.team_style--4:hover .inner .content-header .social-network {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible; }

.team-bottom-text-image {
  background-image: url(/img/icons/team.png);
  background-repeat: no-repeat;
  background-position: left bottom; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .team-bottom-text-image {
      background-size: 90%; } }
  @media only screen and (max-width: 767px) {
    .team-bottom-text-image {
      background-size: 90%; } }

/* =======================
Wavify Area 
==============================*/
.wavify-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }

.wavify-wrapper .wavify-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

/* =======================
Wavify Area 
==============================*/
.wavify-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0; }

.wavify-wrapper .wavify-item {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0; }

/* ======================
    Brand Area
==========================*/
.brook-brand-area.bg-shape {
  background-image: url(/img/slider/revolution/slider-4.png);
  background-repeat: no-repeat;
  background-position: top 80px right; }

.brand-default {
  padding: 0;
  margin: 0;
  list-style: none; }
  .brand-default.brand-style--3 {
    grid-template-columns: repeat(3, 1fr);
    display: -ms-grid;
    display: grid; }
  .brand-default .brand {
    text-align: center; }
    .brand-default .brand a img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: .3;
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      display: inline-block; }
    .brand-default .brand a:hover img {
      -webkit-filter: none;
      filter: none;
      opacity: 1; }
  .brand-default.brand-style--2 {
    grid-template-columns: repeat(3, 1fr);
    display: -ms-grid;
    display: grid; }
    @media only screen and (max-width: 575px) {
      .brand-default.brand-style--2 {
        grid-template-columns: repeat(1, 1fr); } }
    .brand-default.brand-style--2 .brand {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 15px;
      min-height: 200px; }
      .brand-default.brand-style--2 .brand::after {
        display: block;
        position: absolute;
        content: "";
        left: -1px;
        top: -1px;
        right: 0;
        bottom: 0;
        border: 1px solid #eee;
        pointer-events: none; }
    .brand-default.brand-style--2.no-border .brand::after {
      display: none; }
  .brand-default.brand-business {
    grid-template-columns: repeat(4, 1fr) !important;
    display: -ms-grid;
    display: grid;
    margin: 0 -25px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .brand-default.brand-business {
        grid-template-columns: repeat(2, 1fr) !important; } }
    @media only screen and (max-width: 767px) {
      .brand-default.brand-business {
        grid-template-columns: repeat(1, 1fr) !important;
        margin: 0px; } }
    .brand-default.brand-business .brand {
      margin: 0 25px;
      margin-bottom: 50px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .brand-default.brand-business .brand {
          margin: 0 10px;
          margin-bottom: 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .brand-default.brand-business .brand {
          margin: 0 10px;
          margin-bottom: 20px; } }
      @media only screen and (max-width: 767px) {
        .brand-default.brand-business .brand {
          margin: 0 0px;
          margin-bottom: 20px; } }

.social-clint {
  grid-template-columns: repeat(4, 1fr);
  display: -ms-grid;
  display: grid; }
  @media only screen and (max-width: 767px) {
    .social-clint {
      grid-template-columns: repeat(2, 1fr); } }
  .social-clint .clint-grid .grid-inner {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 15px;
    min-height: 115px;
    height: 100%; }
    .social-clint .clint-grid .grid-inner .inner a {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .social-clint .clint-grid .grid-inner .inner a img {
        -webkit-transition: all .3s linear;
        transition: all .3s linear;
        height: auto;
        max-width: 100%; }
    .social-clint .clint-grid .grid-inner .inner:hover a img {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px); }

.brand-arrow {
  position: relative; }
  .brand-arrow .slick-btn {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
    left: -70px;
    z-index: 9; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .brand-arrow .slick-btn {
        left: 0px; } }
    @media only screen and (max-width: 767px) {
      .brand-arrow .slick-btn {
        left: 0px; } }
    .brand-arrow .slick-btn i {
      color: #222222;
      font-size: 20px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s; }
    .brand-arrow .slick-btn:hover i {
      color: #0038E3; }
    .brand-arrow .slick-btn.slick-next {
      right: -70px;
      left: auto; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .brand-arrow .slick-btn.slick-next {
          right: 0px;
          left: auto; } }
      @media only screen and (max-width: 767px) {
        .brand-arrow .slick-btn.slick-next {
          right: 0px;
          left: auto; } }
  .brand-arrow:hover .slick-btn {
    opacity: 1; }

.brand-dots {
  position: relative; }
  .brand-dots .slick-dots {
    position: absolute;
    bottom: -65px; }
    .brand-dots .slick-dots li {
      width: auto;
      height: auto;
      margin: 0 9px;
      padding: 0; }
      .brand-dots .slick-dots li button {
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: #d8d8d8;
        border-radius: 100%; }
        .brand-dots .slick-dots li button::before {
          display: none; }
      .brand-dots .slick-dots li.slick-active button {
        background: #222222; }

.slick-prev:before,
.slick-next:before {
  display: none; }

/*=======================
Blog List Area
===========================*/
/* ---------------------------
Blog Default Style
-----------------------------*/
.bg_image--4 {
  background-image: url(/img/bg/bg-image-4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; }

.blog {
  position: relative;
  /* Blog Style 2 */ }
  .blog .thumb {
    position: relative; }
    .blog .thumb a img {
      width: 100%; }
    .blog .thumb .video-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .blog .thumb .video-btn a.video-icon {
        display: block;
        position: relative;
        margin: 0 auto;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background: #0038E3;
        -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2); }
        .blog .thumb .video-btn a.video-icon::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          -webkit-transform: translate(-6px, -9px);
          -ms-transform: translate(-6px, -9px);
          transform: translate(-6px, -9px);
          border-top: 9px solid transparent;
          border-bottom: 9px solid transparent;
          border-left: 15px solid #fff; }
        .blog .thumb .video-btn a.video-icon:hover {
          -webkit-transform: scale3d(1.15, 1.15, 1.15);
          transform: scale3d(1.15, 1.15, 1.15); }
  .blog .blog-content {
    padding: 10px; }
    .blog .blog-content .content ul.meta {
      margin-bottom: 21px;
      padding: 0; }
      .blog .blog-content .content ul.meta li {
        position: relative;
        display: inline-block;
        color: #222222; }
        .blog .blog-content .content ul.meta li a {
          color: #222222; }
        .blog .blog-content .content ul.meta li + li {
          padding-left: 15px;
          margin-left: 7px; }
          .blog .blog-content .content ul.meta li + li::before {
            content: "|";
            position: absolute;
            top: 0;
            left: 2px; }
    .blog .blog-content .content h3.the-title {
      font-size: 20px;
      line-height: 1.56;
      margin-bottom: 0;
      font-weight: 600;
      font-family: "Poppins", sans-serif;
      margin: 0 0 18px; }
      .blog .blog-content .content h3.the-title a {
        color: #fff; }
  .blog.blog-style--2 {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .blog.blog-style--2 .blog-content {
      position: relative;
      height: 100%;
      min-height: 172px;
      padding: 21px 16px 32px;
      background: #222;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
      .blog.blog-style--2 .blog-content::before {
        -webkit-transition: all .5s;
        transition: all .5s;
        content: "";
        width: 0;
        height: 3px;
        position: absolute;
        left: 50%;
        bottom: 0;
        opacity: 0; }
      .blog.blog-style--2 .blog-content .content h3.the-title {
        font-size: 18px;
        line-height: 1.38;
        margin-bottom: 27px;
        font-family: "Montserrat", sans-serif; }
        .blog.blog-style--2 .blog-content .content h3.the-title a {
          color: #fff;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .blog.blog-style--2 .blog-content .content ul.meta {
        margin-bottom: 0;
        padding: 0; }
        .blog.blog-style--2 .blog-content .content ul.meta li {
          letter-spacing: 0em;
          line-height: 1.6;
          font-size: 15px;
          color: #ffffff; }
          .blog.blog-style--2 .blog-content .content ul.meta li a {
            color: #ffffff; }
          .blog.blog-style--2 .blog-content .content ul.meta li + li {
            padding-left: 14px;
            margin-left: 4px; }
    .blog.blog-style--2.creative--blog .thumb .video-btn a.video-icon {
      background-color: #FE378C;
      background-image: linear-gradient(-133deg, #FE5B34 0, #FE378C 100%); }
    .blog.blog-style--2.creative--blog .blog-content::before {
      background-color: #FE378C;
      background-image: linear-gradient(-133deg, #FE5B34 0, #FE378C 100%); }
    .blog.blog-style--2.creative--blog .blog-content .content ul.meta li a:hover {
      color: #FE378C; }
    .blog.blog-style--2.creative--blog .blog-content .content h3.the-title a:hover {
      color: #FE378C; }
    .blog.blog-style--2:hover {
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px); }
      .blog.blog-style--2:hover.creative--blog .blog-content::before {
        width: 100%;
        opacity: 1;
        left: 0; }

.blog-style--1 {
  position: relative;
  overflow: hidden; }
  .blog-style--1 .thumb a {
    overflow: hidden; }
    .blog-style--1 .thumb a img {
      -webkit-transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
      transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
      width: 100%; }
  .blog-style--1 .blog-content.blog-position--bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 30px 50px;
    color: #fff;
    width: 100%;
    text-align: center; }
  .blog-style--1 .blog-content .content .video-btn {
    margin-bottom: 30px; }
    .blog-style--1 .blog-content .content .video-btn a.video-icon {
      display: block;
      position: relative;
      margin: 0 auto;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background: #FF5EE1; }
      .blog-style--1 .blog-content .content .video-btn a.video-icon::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        -webkit-transform: translate(-6px, -9px);
        -ms-transform: translate(-6px, -9px);
        transform: translate(-6px, -9px);
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 15px solid #fff; }
  .blog-style--1 .blog-content .content ul.meta {
    margin-bottom: 21px; }
    .blog-style--1 .blog-content .content ul.meta li {
      position: relative;
      display: inline-block;
      color: #fff; }
      .blog-style--1 .blog-content .content ul.meta li a {
        color: #fff; }
      .blog-style--1 .blog-content .content ul.meta li + li {
        padding-left: 15px;
        margin-left: 7px; }
        .blog-style--1 .blog-content .content ul.meta li + li::before {
          content: "|";
          position: absolute;
          top: 0;
          left: 2px; }
  .blog-style--1 .blog-content .content h3.the-title {
    font-size: 24px;
    line-height: 1.375;
    margin-bottom: 0;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin: 0 0 18px; }
    .blog-style--1 .blog-content .content h3.the-title a {
      color: #fff; }
  .blog-style--1 .blog-content .content h6.post-quote-by {
    font-size: 18px;
    line-height: 1.12;
    margin-bottom: 0;
    font-weight: 600;
    font-family: "Poppins", sans-serif; }
    .blog-style--1 .blog-content .content h6.post-quote-by a {
      color: #fff; }
  .blog-style--1:hover .thumb a img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05); }

/* ========================
    Blog Style 2
===========================*/
/* ============================
    Contact Area
    http://prntscr.com/m3ajf8
=================================*/
.bg_image--23 {
  background-attachment: fixed; }

.subscribe-text-bg {
  background-image: url(/img/icons/subscribe.png);
  background-repeat: no-repeat;
  background-position: center center; }

.address-inner .address h3.heading {
  line-height: 1.59;
  font-weight: 700;
  font-size: 24px;
  color: #222222;
  font-family: "louis_george_caferegular"; }

.address-inner .address p {
  line-height: 1.94;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 16px;
  color: #333333;
  font-family: "louis_george_caferegular"; }
  .address-inner .address p a {
    color: #333333;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    padding: 2px 0;
    overflow: hidden;
    z-index: 1;
    font-family: "louis_george_caferegular";
    display: inline-block;
    line-height: 16px; }
    .address-inner .address p a::after {
      content: "";
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      z-index: -1;
      -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor; }
    .address-inner .address p a:hover::after {
      width: 100%;
      left: 0;
      right: auto; }

.contact-wrap {
  border: 2px solid #eee;
  padding-top: 48px !important;
  padding-right: 35px !important;
  padding-bottom: 57px !important;
  padding-left: 35px !important; }
  @media only screen and (max-width: 767px) {
    .contact-wrap {
      padding: 20px !important; } }

.contact-business {
  background: #fff; }

.bg-input-one input {
  border: 1px solid #f5f6fa !important;
  background: #f5f6fa !important;
  height: 50px !important; }
  .bg-input-one input:focus {
    border: 1px solid #68AE4A !important; }

.bg-input-one textarea {
  border: 1px solid #f5f6fa !important;
  background: #f5f6fa !important; }
  .bg-input-one textarea:focus {
    border: 1px solid #68AE4A !important; }

.bg-input-one input[type="submit"] {
  border-color: #68AE4A !important;
  background-color: #68AE4A !important;
  color: #fff !important;
  border-radius: 0 !important; }

.business-btn {
  margin: 15px auto 0 !important;
  border-radius: 0 !important;
  color: #ffffff !important;
  border-color: #9F3939 !important;
  background-color: #9F3939 !important;
  font-family: "CerebriSans" !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  width: 200px !important; }

.single-contact-address .title {
  font-size: 16px;
  font-weight: 500; }

.single-contact-address .address p {
  font-size: 15px;
  line-height: 1.74;
  color: #777; }

.single-contact-address .mail-phone p, .single-contact-address .social-share p {
  margin-bottom: 0; }
  .single-contact-address .mail-phone p a, .single-contact-address .social-share p a {
    font-size: 15px;
    line-height: 1.74;
    position: relative;
    padding: 2px 0;
    overflow: hidden;
    z-index: 1;
    color: #777;
    display: inline-block; }
    .single-contact-address .mail-phone p a::after, .single-contact-address .social-share p a::after {
      content: "";
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      z-index: -1;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor; }
    .single-contact-address .mail-phone p a:hover::after, .single-contact-address .social-share p a:hover::after {
      width: 100%;
      left: 0;
      right: auto; }

/* ========================
Contact Form Style 01
============================*/
.form-style--1 .input-box {
  position: relative;
  margin-bottom: 30px;
  z-index: 1; }
  .form-style--1 .input-box span.form-icon {
    left: 0;
    right: auto;
    top: 14px;
    z-index: 2;
    font-size: 20px;
    color: #888;
    position: absolute; }
  .form-style--1 .input-box input {
    padding-left: 35px;
    padding-right: 35px;
    border: 0;
    border-bottom: 2px solid #ddd;
    background: none;
    height: 50px;
    color: #777;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(1, 0.03, 0, 0.99);
    font-family: "louis_george_caferegular";
    font-size: 16px;
    font-weight: 700; }
  .form-style--1 .input-box textarea {
    border: 0;
    border-bottom: 2px solid #ddd;
    background: none;
    height: 120px;
    color: #777;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(1, 0.03, 0, 0.99);
    font-family: "louis_george_caferegular";
    font-size: 16px;
    font-weight: 700;
    padding-left: 0; }
  .form-style--1 .input-box::after {
    content: "";
    width: 0;
    height: 2px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    background-color: #0038E3;
    -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s; }
  .form-style--1 .input-box:hover::after {
    width: 100%;
    left: 0;
    right: auto; }

.form-style--1 .form-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 19px; }
  @media only screen and (max-width: 767px) {
    .form-style--1 .form-action {
      display: block; } }
  .form-style--1 .form-action .form-description {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    line-height: 1.63;
    margin-right: 110px;
    color: #333333;
    font-weight: 700;
    font-size: 16px;
    font-family: "louis_george_caferegular"; }
    @media only screen and (max-width: 767px) {
      .form-style--1 .form-action .form-description {
        margin-right: 40px;
        margin-bottom: 27px; } }
  .form-style--1 .form-action .form-submit {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .form-style--1 .form-action .form-submit input {
      padding: 0 41px;
      height: 55px;
      line-height: 53px;
      outline: none;
      border-width: 1px;
      border-style: solid;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0;
      cursor: pointer;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
      font-family: "Poppins", sans-serif;
      color: #ffffff;
      border-color: #0038E3;
      background-color: #0038E3;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(1, 0.03, 0, 0.99); }
      .form-style--1 .form-action .form-submit input:hover {
        -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        transform: translateY(-3px); }

/* ========================
    Contact
============================*/
.contact-form input {
  color: #777;
  border-color: #eee;
  background-color: #fff;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 0;
  padding: 3px 20px;
  height: 62px; }
  .contact-form input[type="submit"] {
    padding: 0 41px;
    height: 55px;
    line-height: 53px;
    color: #ffffff;
    border-color: #0038E3;
    background-color: #0038E3;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    border-radius: 6px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .contact-form input[type="submit"]:hover {
      -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      transform: translateY(-3px); }
  .contact-form input:focus {
    border-color: #0038E3;
    background-color: #fff; }

.contact-form textarea {
  color: #777;
  border-color: #eee;
  background-color: #fff;
  height: 150px;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 0;
  padding: 12px 20px 3px;
  max-width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .contact-form textarea:focus {
    color: #0038E3;
    border-color: #0038E3;
    background-color: #fff; }

.contact-form input[type="checkbox"] {
  width: auto;
  height: auto;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 0;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #151515; }

.contact-form label {
  position: relative;
  padding-left: 10px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #252525;
  margin: 0; }

.contact-form.contact-form--2 {
  padding: 83px 70px 85px;
  text-align: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-form.contact-form--2 {
      padding: 60px 30px 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-form.contact-form--2 {
      padding: 60px 30px 60px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-form.contact-form--2 {
      padding: 33px 30px 29px; } }
  @media only screen and (max-width: 767px) {
    .contact-form.contact-form--2 {
      padding: 60px 20px 60px; } }
  .contact-form.contact-form--2 h4.heading {
    font-size: 24px;
    line-height: 1.59;
    margin-bottom: 37px; }
  .contact-form.contact-form--2 .form-desc {
    display: block;
    margin-top: 20px;
    font-size: 12px;
    line-height: 1.84;
    font-style: italic;
    color: #333333; }
  .contact-form.contact-form--2 input {
    text-align: center; }

.contact-form.contact-form--3 {
  padding: 53px 30px;
  width: 380px;
  max-width: 100%; }
  .contact-form.contact-form--3 .input-box {
    position: relative;
    margin-right: 57px; }
    .contact-form.contact-form--3 .input-box input {
      padding-right: 57px; }
    .contact-form.contact-form--3 .input-box button {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      color: #0038E3;
      border: 0 none; }

.contact-form.contact-form--4 h4.heading {
  margin-bottom: 30px; }

.contact-form.contact-form--4 .input-box {
  position: relative; }
  .contact-form.contact-form--4 .input-box input {
    padding-right: 108px; }
  .contact-form.contact-form--4 .input-box button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #0038E3;
    border: 0 none;
    background: transparent;
    width: 62px;
    height: 100%;
    transition: 0.4s; }
    .contact-form.contact-form--4 .input-box button:hover {
      background: #0038E3;
      color: #ffffff; }

.contact-form.contact-form--4.heading-hover .input-box button {
  width: auto;
  padding: 0 25px;
  font-weight: 600; }
  .contact-form.contact-form--4.heading-hover .input-box button:hover {
    background: #222222;
    color: #ffffff; }

.contact-form.contact-form--4.yellow-color-2 .input-box input {
  padding-right: 129px; }
  .contact-form.contact-form--4.yellow-color-2 .input-box input:focus {
    color: #F8A440;
    border-color: #F8A440; }

.contact-form.contact-form--4.yellow-color-2 .input-box button {
  color: #F8A440;
  width: auto;
  height: 100%;
  padding: 0 22px; }
  .contact-form.contact-form--4.yellow-color-2 .input-box button:hover {
    background: #F8A440;
    color: #ffffff; }

.contact-form.contact-form--5 {
  background: #fff;
  padding: 60px 50px; }
  @media only screen and (max-width: 767px) {
    .contact-form.contact-form--5 {
      padding: 60px 13px; } }
  .contact-form.contact-form--5 input {
    border: 0 none;
    color: #001029;
    border-color: #eee;
    background-color: #fff;
    width: 100%;
    outline: none;
    border-bottom: 1px solid #eee;
    border-radius: 0;
    padding: 0;
    height: 62px; }
  .contact-form.contact-form--5 textarea {
    border: 0 none;
    color: #001029;
    border-color: #eee;
    height: 87px;
    outline: none;
    border-bottom: 1px solid #eee;
    border-radius: 0;
    padding: 0; }

.contact-form.contact-bt-border input {
  border: 0;
  border-bottom: 2px solid #eee;
  background: none;
  padding-left: 0;
  padding-right: 0;
  height: 50px;
  padding: 3px 20px;
  color: #777;
  line-height: 1.75;
  font-size: 16px; }

.contact-form.contact-bt-border textarea {
  border: 0;
  border-bottom: 2px solid #eee;
  background: none;
  padding-left: 0;
  padding-right: 0;
  padding: 3px 20px;
  color: #777;
  line-height: 1.75;
  font-size: 16px; }

.contact-form.contact-form--5 input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #001029; }

.contact-form.contact-form--5 input::-moz-placeholder {
  /* Firefox 19+ */
  color: #001029; }

.contact-form.contact-form--5 input:-ms-input-placeholder {
  /* IE 10+ */
  color: #001029; }

.contact-form.contact-form--5 input:-moz-placeholder {
  /* Firefox 18- */
  color: #001029; }

.contact-form.contact-form--5 textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #001029; }

.contact-form.contact-form--5 textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #001029; }

.contact-form.contact-form--5 textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #001029; }

.contact-form.contact-form--5 textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #001029; }

/*==========================
    About Area
===========================*/
.brook-about-area.bg-shape {
  background-image: url(/img/slider/revolution/slider-5.png);
  background-repeat: no-repeat;
  background-position: bottom 20px left 128px; }

.about--creative .content .title--creative h3.heading {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.43;
  margin-bottom: 0;
  word-wrap: break-word;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 34px; }

.about--creative .content .title--creative h2 {
  font-size: 48px;
  line-height: 1.21;
  color: #fff;
  margin-bottom: 47px;
  max-width: 470px;
  font-family: 'Montserrat', sans-serif; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about--creative .content .title--creative h2 {
      font-size: 35px;
      margin-bottom: 17px; } }
  @media only screen and (max-width: 767px) {
    .about--creative .content .title--creative h2 {
      font-size: 30px;
      margin-bottom: 17px; } }
  .about--creative .content .title--creative h2 span {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700; }

.about--creative .content p {
  font-size: 18px;
  line-height: 1.67;
  color: #ffffff;
  margin-bottom: 45px;
  word-wrap: break-word;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif; }

.about--creative .content a.about-btn {
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  white-space: nowrap;
  line-height: 1.2; }
  .about--creative .content a.about-btn i {
    padding-left: 20px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 12px; }
  .about--creative .content a.about-btn:hover i {
    -webkit-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    transform: translate(5px, 0); }

.about-accordion .thumb img {
  width: 100%; }

.brook-icon-boxes-area .poss_relative {
  z-index: 2; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-coronavirus-content {
    margin-bottom: 30px; } }

@media only screen and (max-width: 767px) {
  .about-coronavirus-content {
    margin-bottom: 30px; } }

.about-coronavirus-content h2 {
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .about-coronavirus-content h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-coronavirus-content h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-coronavirus-content h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (max-width: 767px) {
    .about-coronavirus-content h2 {
      font-size: 26px;
      line-height: 34px; } }

.about-coronavirus-content .sub-title {
  font-size: 24px;
  line-height: 38px;
  font-weight: 300;
  color: #fff;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .about-coronavirus-content .sub-title {
      font-size: 20px;
      line-height: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-coronavirus-content .sub-title {
      font-size: 20px;
      line-height: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-coronavirus-content .sub-title {
      font-size: 20px;
      line-height: 30px; } }
  @media only screen and (max-width: 767px) {
    .about-coronavirus-content .sub-title {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 15px; } }

.about-coronavirus-content .pragraph {
  font-size: 18px;
  line-height: 34px;
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .about-coronavirus-content .pragraph {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-coronavirus-content .pragraph {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-coronavirus-content .pragraph {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (max-width: 767px) {
    .about-coronavirus-content .pragraph {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 15px; } }

/* ========================
    Call To Action Area 
============================*/
.bg_image--9,
.bg_image--10 {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; }

.vertical-call-toaction .spacing {
  height: 44px; }

.creative-gradient--1 {
  overflow: hidden;
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, #4EA132), color-stop(47%, #FE5448), color-stop(100%, #FE378D));
  background: -moz-linear-gradient(230deg, #4EA132 5%, #FE5448 47%, #FE378D 100%);
  background: -webkit-linear-gradient(230deg, #4EA132 5%, #FE5448 47%, #FE378D 100%);
  background: -o-linear-gradient(230deg, #4EA132 5%, #FE5448 47%, #FE378D 100%);
  background: -ms-linear-gradient(230deg, #4EA132 5%, #FE5448 47%, #FE378D 100%);
  background: linear-gradient(230deg, #4EA132 5%, #FE5448 47%, #FE378D 100%); }

.fireflies-wrapper {
  margin: 0 auto;
  position: relative; }

/* Call to Action 1 */
.call-to-action button {
  color: #222;
  background-color: #fff;
  padding: 0 36px;
  height: 55px;
  line-height: 55px;
  border-width: 0;
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px; }
  .call-to-action button i {
    font-size: 12px;
    margin-left: 10px;
    color: #222; }
  .call-to-action button:hover {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px); }

.call-to-action.callto-action--1 {
  text-align: center; }
  .call-to-action.callto-action--1 .content h6 {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-bottom: 24px;
    font-family: "Montserrat", sans-serif; }
  .call-to-action.callto-action--1 .content h3 {
    font-size: 48px;
    line-height: 1.21;
    color: #fff;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 60px; }
    .call-to-action.callto-action--1 .content h3 span {
      font-family: "Playfair Display", serif;
      font-style: italic;
      font-weight: 700; }

.call-to-action.callto-action--2 .content h3 {
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
  font-size: 36px; }

.essential-cta h3 {
  font-size: 40px;
  line-height: 1.28;
  font-weight: 200;
  color: #fff;
  margin-bottom: 20px; }

.essential-cta p {
  font-size: 18px;
  max-width: 580px;
  line-height: 1.89;
  color: #fff;
  margin: 0 auto; }

.essential-cta-content h3 {
  font-size: 28px;
  line-height: 1.25;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .essential-cta-content h3 {
      text-align: center;
      margin-bottom: 20px; } }

.essential-btn {
  text-transform: uppercase;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
  border: 2px solid #fff !important;
  box-shadow: none !important; }
  .essential-btn:hover {
    background-color: transparent !important;
    color: #fff !important; }
  @media only screen and (max-width: 767px) {
    .essential-btn {
      text-align: center; } }

/*==========================
 Slick Arrow Style 
=============================*/
.slick-dotted.slick-slider {
  margin-bottom: 0; }

.slick-slide img {
  display: inline-block; }

.slick-arrow-center {
  position: relative; }
  .slick-arrow-center .slick-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    left: -70px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 2; }
    .slick-arrow-center .slick-btn::before {
      display: none; }
    .slick-arrow-center .slick-btn i {
      background: transparent;
      color: #333333; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-arrow-center .slick-btn {
        left: 0px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slick-arrow-center .slick-btn {
        left: 0px; } }
    @media only screen and (max-width: 767px) {
      .slick-arrow-center .slick-btn {
        left: 0px; } }
    .slick-arrow-center .slick-btn.slick-next {
      left: auto;
      right: -70px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slick-arrow-center .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slick-arrow-center .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
      @media only screen and (max-width: 767px) {
        .slick-arrow-center .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
  .slick-arrow-center:hover .slick-btn {
    opacity: 1;
    visibility: visible; }
  .slick-arrow-center.slick-arrow-rounded.lr-0 .slick-btn {
    left: 25px; }
    .slick-arrow-center.slick-arrow-rounded.lr-0 .slick-btn.slick-next {
      left: auto;
      right: 25px; }
  .slick-arrow-center.slick-arrow-rounded .slick-btn {
    background-color: #fff;
    width: 68px;
    height: 68px;
    font-size: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(34, 34, 34, 0.3);
    left: -14px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
    border-radius: 50%;
    z-index: 2;
    text-align: center;
    line-height: 75px;
    padding: 0; }
    .slick-arrow-center.slick-arrow-rounded .slick-btn i {
      background: transparent;
      color: #333333; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-arrow-center.slick-arrow-rounded .slick-btn {
        left: 0px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slick-arrow-center.slick-arrow-rounded .slick-btn {
        left: 0px; } }
    @media only screen and (max-width: 767px) {
      .slick-arrow-center.slick-arrow-rounded .slick-btn {
        left: 0px; } }
    .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
      background-color: #0038E3;
      color: #fff; }
      .slick-arrow-center.slick-arrow-rounded .slick-btn:hover i {
        color: #fff; }
    .slick-arrow-center.slick-arrow-rounded .slick-btn.slick-next {
      left: auto;
      right: -14px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slick-arrow-center.slick-arrow-rounded .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slick-arrow-center.slick-arrow-rounded .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
      @media only screen and (max-width: 767px) {
        .slick-arrow-center.slick-arrow-rounded .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
  .slick-arrow-center.slick-arrow-triggle .slick-btn {
    color: #fff;
    width: 85px;
    height: 108px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
    z-index: 2;
    outline: none;
    text-align: center;
    line-height: 123px; }
    .slick-arrow-center.slick-arrow-triggle .slick-btn i {
      position: relative;
      color: #fff;
      font-size: 30px; }
    .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-prev {
      border-bottom-right-radius: 108px;
      border-top-right-radius: 108px;
      left: 0; }
      .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-prev i {
        left: 11px; }
    .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-next {
      border-bottom-left-radius: 108px;
      border-top-left-radius: 108px;
      right: 0; }
      .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-next i {
        right: 11px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-arrow-center.slick-arrow-triggle .slick-btn {
        left: 0px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slick-arrow-center.slick-arrow-triggle .slick-btn {
        left: 0px; } }
    @media only screen and (max-width: 767px) {
      .slick-arrow-center.slick-arrow-triggle .slick-btn {
        left: 0px; } }
    .slick-arrow-center.slick-arrow-triggle .slick-btn:hover {
      background-color: #ffffff;
      color: #222222; }
      .slick-arrow-center.slick-arrow-triggle .slick-btn:hover i {
        color: #222222;
        transition: 0.4s ease-in-out; }
    .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-next {
      left: auto;
      right: 0; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
      @media only screen and (max-width: 767px) {
        .slick-arrow-center.slick-arrow-triggle .slick-btn.slick-next {
          left: auto;
          right: 0px; } }
  .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn {
    color: #fff;
    width: 55px;
    height: 100px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0; }
    .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn.slick-prev {
      border-bottom-right-radius: 108px;
      border-top-right-radius: 108px;
      left: 0; }
      .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn.slick-prev i {
        left: -2px; }
    .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn.slick-next {
      border-bottom-left-radius: 108px;
      border-top-left-radius: 108px;
      right: 0; }
      .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn.slick-next i {
        right: -2px; }
    .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn:hover {
      background-color: #ffffff;
      color: #222222; }
      .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn:hover i {
        color: #222222;
        transition: 0.4s ease-in-out; }

.slick-arrow-hover:hover .slick-arrow-center .slick-btn,
.slick-arrow-hover:hover .slick-arrow-triggle .slick-btn,
.slick-arrow-hover:hover .slick-arrow-rounded .slick-btn {
  opacity: 1;
  visibility: visible; }

.slick-dots-bottom {
  position: relative; }
  .slick-dots-bottom.bottom-30 .slick-dots {
    bottom: 30px; }
  .slick-dots-bottom .slick-dots {
    position: absolute;
    z-index: 2;
    bottom: -60px; }
    .slick-dots-bottom .slick-dots li {
      width: auto;
      height: auto;
      margin: 0 9px;
      padding: 0; }
      .slick-dots-bottom .slick-dots li button {
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: #d8d8d8;
        border-radius: 100%; }
        .slick-dots-bottom .slick-dots li button::before {
          display: none; }
      .slick-dots-bottom .slick-dots li.slick-active button {
        background: #222222; }

.squre-dots {
  position: absolute;
  z-index: 2;
  bottom: -60px; }
  .squre-dots li {
    width: auto;
    height: auto;
    margin: 0 9px;
    padding: 0; }
    .squre-dots li button {
      width: 22px;
      height: 4px;
      padding: 0;
      cursor: pointer;
      border: 0;
      outline: none;
      background: #d8d8d8; }
      .squre-dots li button::before {
        display: none; }
    .squre-dots li.slick-active button {
      background: #222222; }

.number-dot .slick-dots {
  position: absolute;
  z-index: 2;
  bottom: 30px; }
  @media only screen and (max-width: 767px) {
    .number-dot .slick-dots {
      bottom: 15px; } }
  .number-dot .slick-dots li {
    margin: 0 10px !important; }
    .number-dot .slick-dots li button {
      font-size: 20px !important;
      line-height: 20px !important;
      display: flex !important;
      flex-direction: row-reverse !important;
      color: #fff !important; }
      .number-dot .slick-dots li button::before {
        display: none; }
      .number-dot .slick-dots li button::after {
        content: "0"; }
    .number-dot .slick-dots li::before {
      content: "";
      position: absolute;
      bottom: -10px;
      left: -5px;
      width: 0;
      height: 2px;
      background-color: #fff;
      z-index: 9;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out; }
    .number-dot .slick-dots li.slick-active::before, .number-dot .slick-dots li:hover::before {
      opacity: 1;
      visibility: visible;
      width: 100%; }

.slick-dot-vertical-center {
  position: relative; }
  .slick-dot-vertical-center .slick-dots {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 95px;
    width: auto;
    bottom: auto !important; }
    @media only screen and (max-width: 767px) {
      .slick-dot-vertical-center .slick-dots {
        right: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-dot-vertical-center .slick-dots {
        right: 30px; } }
    .slick-dot-vertical-center .slick-dots li {
      display: block;
      margin: 12px 0; }
      .slick-dot-vertical-center .slick-dots li button {
        font-size: 0;
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%; }
        .slick-dot-vertical-center .slick-dots li button::before {
          display: none; }
      .slick-dot-vertical-center .slick-dots li.slick-active button {
        background: #ffffff; }
  .slick-dot-vertical-center.button-gray .slick-dots li button {
    background: #d8d8d8; }
  .slick-dot-vertical-center.button-gray .slick-dots li.slick-active button {
    background: #222222; }

.bk-about-area:hover .slick-btn {
  opacity: 1;
  visibility: visible; }

/* =================================
    Motivational Archive 
=================================*/
.bg_image--7 {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center; }

.motivational-wrap {
  width: 600px;
  max-width: 100%;
  float: right; }
  .motivational-wrap .motivational-inner h3.title {
    line-height: 1.32;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 0;
    word-wrap: break-word;
    max-width: 100%;
    color: #222222; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .motivational-wrap .motivational-inner h3.title {
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      .motivational-wrap .motivational-inner h3.title {
        font-size: 35px; } }

.digital-motivational {
  padding-top: 17px;
  padding-bottom: 25px; }

.motivational-thumbnail {
  text-align: center; }
  .motivational-thumbnail img {
    height: auto;
    max-width: 100%; }

/* ------------------------
    Social Area 
----------------------------*/
.social-share.padding-10 a:first-child {
  padding-left: 0; }

.social-share.padding-10 a:last-child {
  padding-right: 0; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .social-share.padding-10 a {
    padding: 5px 7px; } }

.social-share a {
  display: inline-block;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #222222;
  padding: 5px 13px;
  font-size: 20px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .social-share a {
      padding: 5px 10px; } }
  @media only screen and (max-width: 767px) {
    .social-share a {
      padding: 5px 9px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .social-share a {
      padding: 5px 8px; } }
  .social-share a:hover {
    color: #0038E3; }

.social-share.social--transparent {
  background: transparent; }

.social-share.body-color a {
  color: #999; }
  .social-share.body-color a:hover {
    color: #222222; }

.social-share.text-white a {
  color: #ffffff; }
  .social-share.text-white a:hover {
    opacity: 0.8; }

.social-icon {
  padding: 0;
  margin: 0 -15px; }
  .social-icon li {
    display: inline-block; }
    .social-icon li a {
      padding: 5px 15px;
      color: #fff;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .social-icon.social-vertical {
    margin: 0; }
    .social-icon.social-vertical li {
      display: block;
      margin: 10px; }
      .social-icon.social-vertical li a {
        display: block; }
  .social-icon.text-dark li a {
    color: #222222; }
  .social-icon.text-dark.tooltip-layout li a {
    color: #222222; }
    .social-icon.text-dark.tooltip-layout li a:hover {
      color: #0038E3; }
  .social-icon.icon-size-large li a {
    font-size: 30px; }
  .social-icon.icon-size-medium li a {
    font-size: 20px; }
  .social-icon.social-text li a span {
    font-size: 15px;
    position: relative;
    padding: 2px 0;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #ffffff; }
    .social-icon.social-text li a span::after {
      content: "";
      width: 0;
      height: 1px;
      bottom: 0;
      position: absolute;
      left: auto;
      right: 0;
      z-index: -1;
      -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      background: currentColor; }
    .social-icon.social-text li a span:hover::after {
      width: 100%;
      left: 0;
      right: auto; }
  .social-icon.social-text.text-dark li a span {
    color: #999999; }
    .social-icon.social-text.text-dark li a span:hover::after {
      width: 100%;
      left: 0;
      right: auto; }
  .social-icon.social-text.second-font li a {
    font-family: "louis_george_caferegular"; }
  .social-icon.tooltip-layout.text-dark li a {
    color: #333333;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .social-icon.tooltip-layout.text-dark li a:hover {
      color: #222222; }
  .social-icon.tooltip-layout.text-dark-2 li a {
    color: #222222;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .social-icon.tooltip-layout.text-dark-2 li a:hover {
      color: #222222; }
  .social-icon.tooltip-layout li a {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #fff; }
    .social-icon.tooltip-layout li a[class*=hint--] {
      position: relative; }
    .social-icon.tooltip-layout li a.hint--bounce:after, .social-icon.tooltip-layout li a.hint--bounce:before {
      -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }
    .social-icon.tooltip-layout li a[class*=hint--]:after, .social-icon.tooltip-layout li a[class*=hint--]:before {
      position: absolute;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      visibility: hidden;
      opacity: 0;
      z-index: 1000000;
      pointer-events: none;
      -webkit-transition: .3s ease;
      transition: .3s ease;
      -webkit-transition-delay: 0ms;
      transition-delay: 0ms; }
    .social-icon.tooltip-layout li a.hint--top:after, .social-icon.tooltip-layout li a.hint--top:before {
      bottom: 100%;
      left: 50%; }
    .social-icon.tooltip-layout li a.hint--top:after {
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
    .social-icon.tooltip-layout li a.hint--top:before {
      left: calc(50% - 7px);
      margin-bottom: -13px; }
    .social-icon.tooltip-layout li a.hint--bottom:after, .social-icon.tooltip-layout li a.hint--bottom:before {
      top: 100%;
      left: 50%; }
    .social-icon.tooltip-layout li a.hint--bottom:after {
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
    .social-icon.tooltip-layout li a[class*=hint--]:before {
      content: "";
      position: absolute;
      background: transparent;
      border: 7px solid transparent;
      z-index: 1000001; }
    .social-icon.tooltip-layout li a[class*=hint--][aria-label]:after {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      line-height: 1.23;
      content: attr(aria-label); }
    .social-icon.tooltip-layout li a[class*=hint--]:after {
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
      padding: 8px 10px;
      font-size: 14px;
      white-space: nowrap;
      border-radius: 5px; }
    .social-icon.tooltip-layout li a.hint--white[aria-label]::before {
      border-top-color: #fff; }
    .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
      background: #0038E3;
      color: #ffffff;
      text-shadow: none; }
    .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
      border-top-color: #0038E3; }
    .social-icon.tooltip-layout li a.hint--dark[aria-label]::after {
      background: #222222;
      color: #ffffff;
      text-shadow: none; }
    .social-icon.tooltip-layout li a.hint--dark[aria-label]::before {
      border-top-color: #222222; }
    .social-icon.tooltip-layout li a.hint--white[aria-label]::after {
      background: #fff;
      color: #111;
      text-shadow: none; }
    .social-icon.tooltip-layout li a.hint--bottom::before {
      left: calc(50% - 7px);
      margin-top: -13px;
      border-bottom-color: #000;
      border-top-color: transparent !important; }
    .social-icon.tooltip-layout li a.hint--bottom.hint--white[aria-label]::before {
      border-bottom-color: #ffffff; }
    .social-icon.tooltip-layout li a:hover {
      color: rgba(255, 255, 255, 0.5); }
      .social-icon.tooltip-layout li a:hover.hint--top[class*=hint--]:after, .social-icon.tooltip-layout li a:hover.hint--top[class*=hint--]:before {
        visibility: visible;
        opacity: 1;
        -webkit-transition-delay: .1s;
        transition-delay: .1s; }
      .social-icon.tooltip-layout li a:hover.hint--top::before {
        -webkit-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
        transform: translateY(-8px); }
      .social-icon.tooltip-layout li a:hover.hint--top::after {
        -webkit-transform: translateX(-50%) translateY(-8px);
        -ms-transform: translateX(-50%) translateY(-8px);
        transform: translateX(-50%) translateY(-8px); }
      .social-icon.tooltip-layout li a:hover.hint--bottom[class*=hint--]:after, .social-icon.tooltip-layout li a:hover.hint--bottom[class*=hint--]:before {
        visibility: visible;
        opacity: 1;
        -webkit-transition-delay: .1s;
        transition-delay: .1s; }
      .social-icon.tooltip-layout li a:hover.hint--bottom::before {
        -webkit-transform: translateY(8px);
        -ms-transform: translateY(8px);
        transform: translateY(8px); }
      .social-icon.tooltip-layout li a:hover.hint--bottom::after {
        -webkit-transform: translateX(-50%) translateY(8px);
        -ms-transform: translateX(-50%) translateY(8px);
        transform: translateX(-50%) translateY(8px); }
  .social-icon.icon-solid-rounded li {
    margin: 5px; }
    .social-icon.icon-solid-rounded li a {
      font-size: 20px;
      display: block;
      text-align: center;
      height: 50px;
      width: 50px;
      background: #eee;
      color: #222;
      line-height: 51px;
      border-radius: 50%;
      position: relative;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      padding: 0; }
      .social-icon.icon-solid-rounded li a:hover {
        background-color: #0038E3;
        color: #ffffff !important; }
  .social-icon.icon-border-rounded.social-vertical li {
    margin: 20px; }
  .social-icon.icon-border-rounded li {
    margin: 5px; }
    .social-icon.icon-border-rounded li a {
      display: block;
      text-align: center;
      height: 55px;
      width: 55px;
      background: transparent;
      color: #ffffff;
      border: 2px solid rgba(0, 0, 0, 0.21);
      border-radius: 50%;
      line-height: 52px;
      position: relative;
      border-color: rgba(255, 255, 255, 0.21);
      padding: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .social-icon.icon-border-rounded li a:hover {
        border-color: #fff;
        background-color: #fff;
        color: #222; }
  .social-icon.icon-border-rounded.text-dark-2 li a {
    color: #222222;
    border: 1px solid #eee; }
    .social-icon.icon-border-rounded.text-dark-2 li a:hover {
      border-color: #222222;
      background-color: #222222;
      color: #ffffff; }
  .social-icon.icon-border-rounded.style-solid-thin-rounded-icon li a {
    color: #222;
    border: 1px solid #eee; }
    .social-icon.icon-border-rounded.style-solid-thin-rounded-icon li a:hover {
      color: #fff;
      border-color: #222;
      background: #222; }
  .social-icon.style-solid-rounded-icon {
    margin: -10px; }
    .social-icon.style-solid-rounded-icon li {
      margin: 10px; }
      @media only screen and (max-width: 767px) {
        .social-icon.style-solid-rounded-icon li {
          margin: 6px; } }
      .social-icon.style-solid-rounded-icon li a {
        font-size: 20px;
        display: block;
        text-align: center;
        height: 55px;
        line-height: 42px;
        width: 55px;
        color: #fff;
        border: 2px solid rgba(0, 0, 0, 0.21);
        border-radius: 50%;
        position: relative;
        border-color: transparent;
        background-color: rgba(255, 255, 255, 0.18);
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .social-icon.style-solid-rounded-icon li a:hover {
          border-color: transparent;
          background-color: #fff;
          color: #222222; }

a {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #fff; }
  a[class*=hint--] {
    position: relative; }
  a.hint--bounce:after, a.hint--bounce:before {
    -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }
  a[class*=hint--]:after, a[class*=hint--]:before {
    position: absolute;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    -webkit-transition: .3s ease;
    transition: .3s ease;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms; }
  a.hint--top:after, a.hint--top:before {
    bottom: 100%;
    left: 50%; }
  a.hint--top:after {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  a.hint--top:before {
    left: calc(50% - 7px);
    margin-bottom: -13px; }
  a.hint--bottom:after, a.hint--bottom:before {
    top: 100%;
    left: 50%; }
  a.hint--bottom:after {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  a[class*=hint--]:before {
    content: "";
    position: absolute;
    background: transparent;
    border: 7px solid transparent;
    z-index: 1000001; }
  a[class*=hint--][aria-label]:after {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    line-height: 1.23;
    content: attr(aria-label); }
  a[class*=hint--]:after {
    -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    padding: 8px 10px;
    font-size: 14px;
    white-space: normal;
    border-radius: 5px;
    max-width: 160px;
    min-width: 120px;
    text-align: center; }
  a.hint--white[aria-label]::before {
    border-top-color: #fff; }
  a.hint--theme[aria-label]::after {
    background: #0038E3;
    color: #ffffff;
    text-shadow: none; }
  a.hint--theme[aria-label]::before {
    border-top-color: #0038E3; }
  a.hint--dark[aria-label]::after {
    background: #222222;
    color: #ffffff;
    text-shadow: none; }
  a.hint--dark[aria-label]::before {
    border-top-color: #222222; }
  a.hint--white[aria-label]::after {
    background: #fff;
    color: #111;
    text-shadow: none; }
  a.hint--bottom::before {
    left: calc(50% - 7px);
    margin-top: -13px;
    border-bottom-color: #000;
    border-top-color: transparent !important; }
  a.hint--bottom.hint--white[aria-label]::before {
    border-bottom-color: #ffffff; }
  a:hover {
    color: rgba(255, 255, 255, 0.5); }
    a:hover.hint--top[class*=hint--]:after, a:hover.hint--top[class*=hint--]:before {
      visibility: visible;
      opacity: 1;
      -webkit-transition-delay: .1s;
      transition-delay: .1s; }
    a:hover.hint--top::before {
      -webkit-transform: translateY(-8px);
      -ms-transform: translateY(-8px);
      transform: translateY(-8px); }
    a:hover.hint--top::after {
      -webkit-transform: translateX(-50%) translateY(-8px);
      -ms-transform: translateX(-50%) translateY(-8px);
      transform: translateX(-50%) translateY(-8px); }
    a:hover.hint--bottom[class*=hint--]:after, a:hover.hint--bottom[class*=hint--]:before {
      visibility: visible;
      opacity: 1;
      -webkit-transition-delay: .1s;
      transition-delay: .1s; }
    a:hover.hint--bottom::before {
      -webkit-transform: translateY(8px);
      -ms-transform: translateY(8px);
      transform: translateY(8px); }
    a:hover.hint--bottom::after {
      -webkit-transform: translateX(-50%) translateY(8px);
      -ms-transform: translateX(-50%) translateY(8px);
      transform: translateX(-50%) translateY(8px); }

/* ===========================
    Shot Note Css 
==============================*/
.section-separator-with--border {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }

.swipper-custom-arrow {
  position: relative; }
  .swipper-custom-arrow .swipper-btn {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    color: #fff;
    width: 55px;
    height: 108px;
    font-size: 30px;
    border: 0 none;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
    transform: translateY(-50%); }
    .swipper-custom-arrow .swipper-btn.swiper-btn-prev {
      border-bottom-right-radius: 108px;
      border-top-right-radius: 108px;
      left: 0; }
    .swipper-custom-arrow .swipper-btn.swiper-btn-next {
      left: auto;
      border-bottom-left-radius: 108px;
      border-top-left-radius: 108px;
      right: 0; }
    .swipper-custom-arrow .swipper-btn:hover {
      color: #222;
      background: #fff;
      opacity: 1;
      visibility: visible; }

.swipper-custom-dots {
  position: relative; }
  .swipper-custom-dots .swiper-container-horizontal {
    overflow: visible;
    position: relative; }
  .swipper-custom-dots .swiper-pagination {
    position: absolute;
    z-index: 2;
    bottom: -60px; }
    @media only screen and (max-width: 767px) {
      .swipper-custom-dots .swiper-pagination {
        bottom: 19px; } }
    .swipper-custom-dots .swiper-pagination .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      padding: 0;
      cursor: pointer;
      border: 0;
      outline: none;
      background: #d8d8d8;
      border-radius: 100%;
      margin: 0 6px;
      opacity: 1; }
      .swipper-custom-dots .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #222222; }

/* =========================
    Flexable Image Area 
===========================*/
.flexale-image .thumb img {
  width: 100%; }

.flexable-carousle-activation--2 .swiper-wrapper .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  .flexable-carousle-activation--2 .swiper-wrapper .swiper-wrapper .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    box-sizing: border-box; }

.flexable-carousle-activation--2 .swiper-slide,
.flexable-carousle-activation--2 .swiper-slide img,
.flexable-carousle-activation--2 .swiper-slide .thumb,
.flexable-carousle-activation--2 .swiper-slide {
  width: auto !important; }

.flexable-carousle-activation--2 .swiper-slide img {
  height: 678px;
  max-width: 100%;
  vertical-align: top; }
  @media only screen and (max-width: 767px) {
    .flexable-carousle-activation--2 .swiper-slide img {
      height: 238px; } }

.flexable-arrow {
  position: relative; }
  .flexable-arrow .slick-btn {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    left: 50px;
    z-index: 9; }
    .flexable-arrow .slick-btn::before {
      display: none; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .flexable-arrow .slick-btn {
        left: 16px; } }
    @media only screen and (max-width: 767px) {
      .flexable-arrow .slick-btn {
        left: 16px; } }
    .flexable-arrow .slick-btn i {
      color: #ffffff;
      font-size: 20px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0.3s linear 2s, opacity 0.3s linear 2s; }
    .flexable-arrow .slick-btn:hover i {
      color: #0038E3; }
    .flexable-arrow .slick-btn.slick-next {
      right: 50px;
      left: auto; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .flexable-arrow .slick-btn.slick-next {
          left: auto;
          right: 16px; } }
      @media only screen and (max-width: 767px) {
        .flexable-arrow .slick-btn.slick-next {
          left: auto;
          right: 16px; } }
  .flexable-arrow:hover .slick-btn {
    opacity: 1; }

.flexable-dots {
  position: relative; }
  .flexable-dots .slick-dots {
    position: absolute;
    bottom: 50px;
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .flexable-dots .slick-dots {
        bottom: 15px; } }
    .flexable-dots .slick-dots li {
      width: auto;
      height: auto;
      margin: 0 9px;
      padding: 0; }
      .flexable-dots .slick-dots li button {
        width: 10px;
        height: 10px;
        padding: 0;
        cursor: pointer;
        border: 0;
        outline: none;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%; }
        .flexable-dots .slick-dots li button::before {
          display: none; }
      .flexable-dots .slick-dots li.slick-active button {
        background: #ffffff; }

/* =======================
    Custom Carousel Area 
===========================*/
/*-- Hero Item --*/
.hero-item {
  width: 100%;
  height: 750px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-item {
      height: 650px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-item {
      height: 500px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-item {
      height: 450px; } }
  @media only screen and (max-width: 767px) {
    .hero-item {
      height: 350px; } }
  .hero-item.hero-item-corona {
    height: 100vh; }

.hero-item-restaurant {
  width: 100%;
  height: 100vh;
  align-items: flex-end;
  display: flex !important;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0 90px;
  z-index: 1; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-item-restaurant {
      padding: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .hero-item-restaurant {
      padding: 0 15px; } }

.business-slider-content {
  text-align: center; }
  .business-slider-content h5 {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-family: "Spectral", serif !important;
    font-style: italic;
    margin-bottom: 70px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .business-slider-content h5 {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .business-slider-content h5 {
        margin-bottom: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .business-slider-content h5 {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .business-slider-content h5 {
        margin-bottom: 30px; } }
  .business-slider-content h2 {
    font-size: 80px;
    line-height: 102px;
    letter-spacing: 12px;
    color: #fff;
    margin-bottom: 40px;
    font-weight: 400;
    text-transform: uppercase; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .business-slider-content h2 {
        font-size: 50px;
        line-height: 70px;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .business-slider-content h2 {
        font-size: 50px;
        line-height: 70px;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .business-slider-content h2 {
        font-size: 50px;
        line-height: 70px;
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .business-slider-content h2 {
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 20px; } }
  .business-slider-content p {
    font-size: 18px;
    line-height: 34px;
    color: #fff;
    font-family: "CerebriSans";
    max-width: 610px;
    margin: 0 auto;
    margin-bottom: 40px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .business-slider-content p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .business-slider-content p {
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .business-slider-content p {
        margin-bottom: 20px; } }
    @media only screen and (max-width: 767px) {
      .business-slider-content p {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 20px; } }

/*-- Hero Content Two For Hero Slider --*/
.slider-font-image {
  position: relative;
  bottom: -30px; }

.hero-content-2.left {
  text-align: left; }

.hero-content-2.center {
  text-align: center; }

.hero-content-2.right {
  text-align: right; }

.hero-content-2 > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp; }

.hero-content-2 h6 {
  font-size: 18px;
  line-height: 33px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-2 h6 {
      font-size: 15px;
      line-height: 28px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-2 h6 {
      font-size: 17px;
      line-height: 32px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-2 h6 {
      font-size: 16px;
      line-height: 33px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-2 h6 {
      font-size: 12px;
      line-height: 24px; } }

.hero-content-2 h3 {
  font-size: 80px;
  line-height: 90px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-2 h3 {
      font-size: 68px;
      line-height: 76px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-2 h3 {
      font-size: 58px;
      line-height: 65px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-2 h3 {
      font-size: 44px;
      line-height: 49px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-2 h3 {
      font-size: 33px;
      line-height: 37px; } }

.hero-content-3.left {
  text-align: left; }

.hero-content-3.center {
  text-align: center; }

.hero-content-3.right {
  text-align: right; }

.hero-content-3 > * {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeOutUp; }

.hero-content-3 h6 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 200;
  text-transform: capitalize;
  color: #777;
  margin-bottom: 20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-3 h6 {
      font-size: 20px;
      line-height: 22px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-3 h6 {
      font-size: 18px;
      line-height: 22px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-3 h6 {
      font-size: 18px;
      line-height: 22px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-3 h6 {
      font-size: 16px;
      line-height: 18px; } }

.hero-content-3 h3 {
  font-size: 56px;
  line-height: 71px;
  font-weight: 200;
  color: #222222;
  margin-bottom: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-3 h3 {
      font-size: 48px;
      line-height: 61px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-3 h3 {
      font-size: 38px;
      line-height: 51px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-3 h3 {
      font-size: 36px;
      line-height: 44px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-3 h3 {
      font-size: 33px;
      line-height: 40px; } }

.hero-content-3 .btn {
  display: inline-block !important;
  min-width: 160px;
  max-width: 160px;
  font-size: 13px !important;
  line-height: 52px !important;
  border-radius: 0;
  margin-top: 40px !important;
  font-weight: 500 !important; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-3 .btn {
      margin-top: 20px !important; } }
  @media only screen and (max-width: 767px) {
    .hero-content-3 .btn {
      margin-top: 15px !important; } }

.hero-content-4.left {
  text-align: left; }

.hero-content-4.center {
  text-align: center; }

.hero-content-4.right {
  text-align: right; }

.hero-content-4 h3 {
  font-size: 100px;
  line-height: 1.1;
  letter-spacing: 3px;
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 12px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-4 h3 {
      font-size: 100px;
      line-height: 1.1; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-4 h3 {
      font-size: 100px;
      line-height: 1.1; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-4 h3 {
      font-size: 60px;
      line-height: 1.1; } }
  @media only screen and (max-width: 767px) {
    .hero-content-4 h3 {
      font-size: 40px;
      line-height: 1.1; } }

.hero-content-4 h4 {
  font-size: 40px;
  line-height: 1.4;
  font-weight: 400;
  font-family: "Comic Sans MS" !important;
  display: block;
  margin-bottom: 45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-4 h4 {
      font-size: 40px;
      line-height: 1.4; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-4 h4 {
      font-size: 38px;
      line-height: 51px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-4 h4 {
      font-size: 36px;
      line-height: 44px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-4 h4 {
      font-size: 33px;
      line-height: 40px; } }

.hero-content-4 .check-btn {
  position: relative;
  padding: 5px 0;
  padding-left: 62px;
  background-color: transparent;
  font-weight: 500;
  font-size: 13px;
  display: block;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 105px; }
  .hero-content-4 .check-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 0;
    width: 50px;
    height: 1px;
    background: currentColor;
    opacity: .4; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-4 .check-btn {
      margin-top: 20px !important; } }
  @media only screen and (max-width: 767px) {
    .hero-content-4 .check-btn {
      margin-top: 15px !important; } }

.hero-content-5.left {
  text-align: left; }

.hero-content-5.center {
  text-align: center; }

.hero-content-5.right {
  text-align: right; }

.hero-content-5 h3 {
  font-size: 73px;
  line-height: 90px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 40px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-5 h3 {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-5 h3 {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-5 h3 {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-5 h3 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px; } }

.hero-content-5 p {
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 40px;
  font-family: "Roboto", sans-serif; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .hero-content-5 p {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .hero-content-5 p {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-content-5 p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 767px) {
    .hero-content-5 p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px; } }

/*-- Slide Content In Animation --*/
.slick-active {
  /*-- Hero Content Two For Hero Slider --*/ }
  .slick-active .hero-content-2 > *, .slick-active .hero-content-3 > * {
    animation-name: fadeInUp; }
    .slick-active .hero-content-2 > *:nth-child(1), .slick-active .hero-content-3 > *:nth-child(1) {
      animation-delay: 0.5s; }
    .slick-active .hero-content-2 > *:nth-child(2), .slick-active .hero-content-3 > *:nth-child(2) {
      animation-delay: 1s; }
    .slick-active .hero-content-2 > *:nth-child(3), .slick-active .hero-content-3 > *:nth-child(3) {
      animation-delay: 1.5s; }
    .slick-active .hero-content-2 > *:nth-child(4), .slick-active .hero-content-3 > *:nth-child(4) {
      animation-delay: 2s; }
    .slick-active .hero-content-2 > *:nth-child(5), .slick-active .hero-content-3 > *:nth-child(5) {
      animation-delay: 2.5s; }
    .slick-active .hero-content-2 > *:nth-child(6), .slick-active .hero-content-3 > *:nth-child(6) {
      animation-delay: 3s; }

.carousle-slider .thumb img {
  width: 100%; }

.carousle-slider .content {
  padding: 0 30px 0 0; }
  .carousle-slider .content .spacing {
    height: 28px; }
  .carousle-slider .content h5 {
    font-size: 18px;
    margin-bottom: 14px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    line-height: 1.23; }
  .carousle-slider .content p {
    line-height: 1.58;
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #333333;
    margin: 0; }

.case-slider-content .post-count {
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  color: rgba(18, 18, 18, 0.06);
  margin-bottom: 14px; }

.case-slider-content h6 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 14px; }

.case-slider-content h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 19px;
  padding-bottom: 23px;
  border-bottom: 1px solid #eee; }
  @media only screen and (max-width: 767px) {
    .case-slider-content h3 {
      font-size: 22px; } }

.case-slider-content .text {
  font-size: 18px;
  line-height: 1.67; }

/*==============================
Countdown Area 
====================================*/
.countdown-style-1 .timer-grid > div {
  display: flex;
  margin: 0 -15px; }
  @media only screen and (max-width: 575px) {
    .countdown-style-1 .timer-grid > div {
      flex-wrap: wrap; } }
  .countdown-style-1 .timer-grid > div span.ht-count {
    margin: 0 15px;
    text-align: center; }
    @media only screen and (max-width: 575px) {
      .countdown-style-1 .timer-grid > div span.ht-count {
        margin-bottom: 30px; } }
  .countdown-style-1 .timer-grid > div span.time-count {
    display: inline-block;
    padding: 31px 10px;
    font-size: 36px;
    width: 70px;
    text-align: center;
    margin-bottom: 18px;
    border-radius: 6px;
    font-weight: 600; }
  .countdown-style-1 .timer-grid > div span.count-inner p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff; }

.countdown-style-1.bg-solid-grey .timer-grid > div span.time-count {
  background: #f3f3f3;
  color: #222; }

.countdown-style-1.bg-solid-theme .timer-grid > div span.time-count {
  background: #0038E3;
  color: #ffffff; }

.countdown-style-1.font-lg-size .timer-grid > div .second span.time-count {
  color: #0038E3; }

.countdown-style-1.font-lg-size .timer-grid > div span.time-count {
  color: #222222;
  font-size: 67px;
  padding: 0px 4px;
  width: auto;
  display: block;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px; }
  @media only screen and (max-width: 767px) {
    .countdown-style-1.font-lg-size .timer-grid > div span.time-count {
      font-size: 40px;
      margin-bottom: 4px; } }

.time-circles .countdown {
  display: inline-block;
  width: 770px !important;
  max-width: 100%; }

.time_circles {
  position: relative;
  width: 100%;
  height: 100%; }

.time_circles > div {
  position: absolute;
  text-align: center; }

.time_circles > div > h4 {
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-transform: capitalize;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -31px;
  font-size: 14px !important;
  color: #999;
  font-family: "Poppins", sans-serif; }

.time_circles > div > span {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 30px !important;
  margin-top: 14px;
  font-weight: 500; }

.time_circles .textDiv_Days,
.textDiv_Hours,
.textDiv_Minutes,
.textDiv_Seconds {
  color: #222222;
  font-size: 30px; }

@media only screen and (max-width: 767px) {
  .time_circles > div > h4 {
    bottom: -27px;
    font-size: 14px !important; }
  .time_circles > div > span {
    font-size: 18px !important;
    position: absolute;
    bottom: -9px; }
  .time-circles .countdown {
    display: block;
    width: 799px !important;
    max-width: 100%; } }

@media only screen and (max-width: 575px) {
  .time_circles > div > h4 {
    font-size: 10px !important; }
  .time_circles > div > span {
    font-size: 15px !important; } }

/* ==========================
Progress Charts 
==============================*/
.progress-wrapper h3.heading {
  font-size: 24px;
  margin-bottom: 60px; }

.progress-charts {
  overflow: hidden; }
  .progress-charts h6.heading {
    margin-bottom: 13px;
    text-transform: uppercase; }
    .progress-charts h6.heading span.percent-label {
      color: #222;
      font-weight: 600; }
  .progress-charts .progress {
    height: 4px;
    overflow: visible;
    font-size: 14px;
    background-color: #f5f5f5;
    border-radius: 0; }
    .progress-charts .progress .progress-bar {
      overflow: visible;
      position: relative;
      background: #0038E3; }
      .progress-charts .progress .progress-bar.custom-color--2 {
        background-color: #7ed321; }
      .progress-charts .progress .progress-bar.custom-color--3 {
        background-color: #f2b636; }
      .progress-charts .progress .progress-bar.custom-color--4 {
        background-color: #f55d4e; }
      .progress-charts .progress .progress-bar span.percent-label {
        position: absolute;
        color: #222;
        right: -7px;
        top: -35px;
        font-size: 14px;
        font-weight: 600; }

.radial-progress,
.radial-progress--2 {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .radial-progress,
    .radial-progress--2 {
      display: inline-block; } }
  .radial-progress .circle-text,
  .radial-progress--2 .circle-text {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%); }
    .radial-progress .circle-text span.count,
    .radial-progress--2 .circle-text span.count {
      font-size: 18px;
      line-height: 1;
      font-weight: 600;
      color: #222222;
      position: relative; }
      .radial-progress .circle-text span.count.text-white::after,
      .radial-progress--2 .circle-text span.count.text-white::after {
        color: #fff; }
      .radial-progress .circle-text span.count::after,
      .radial-progress--2 .circle-text span.count::after {
        position: absolute;
        content: "%";
        right: -14px;
        color: #222; }

.radial-progress-single.progress-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 575px) {
    .radial-progress-single.progress-flex {
      display: block; } }

/* ====================
    Gallery Area 
========================*/
.gallery-grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px; }
  @media only screen and (max-width: 767px) {
    .gallery-grid {
      grid-template-columns: repeat(1, 1fr); } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .gallery-grid {
      grid-template-columns: repeat(1, 1fr); } }
  .gallery-grid .grid-width-2 {
    -ms-grid-column: span 2;
    grid-column: span 2; }
    @media only screen and (max-width: 767px) {
      .gallery-grid .grid-width-2 {
        -ms-grid-column: span 1;
        grid-column: span 1; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .gallery-grid .grid-width-2 {
        -ms-grid-column: span 1;
        grid-column: span 1; } }
  .gallery-grid .grid-height-2 {
    -ms-grid-row: span 2;
    grid-row: span 2; }
    @media only screen and (max-width: 767px) {
      .gallery-grid .grid-height-2 {
        -ms-grid-row: span 1;
        grid-row: span 1; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .gallery-grid .grid-height-2 {
        -ms-grid-row: span 1;
        grid-row: span 1; } }

.musician-gallery-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 90px;
  grid-row-gap: 80px;
  display: -ms-grid;
  display: grid; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .musician-gallery-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .musician-gallery-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }
  @media only screen and (max-width: 767px) {
    .musician-gallery-grid {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px; } }
  .musician-gallery-grid .grid-width-2 {
    -ms-grid-column: span 2;
    grid-column: span 2; }
  .musician-gallery-grid .grid-height-2 {
    -ms-grid-row: span 2;
    grid-row: span 2; }

.gallery {
  display: block;
  position: relative !important;
  width: 100%;
  height: 100%; }
  .gallery .thumb img {
    width: 100%; }
  .gallery .hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: #0038E3;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .gallery .hover-overlay .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .gallery .hover-overlay .inner span {
        font-size: 36px;
        color: #fff; }
  .gallery:hover .hover-overlay {
    visibility: visible;
    opacity: .9; }

.masonary-item {
  margin-top: 30px; }

/*=========================
    Minimal Gallery 
==========================*/
.minimal-gallery.masonry-wrap {
  margin: 0 -45px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .minimal-gallery.masonry-wrap {
      margin: 0 -15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .minimal-gallery.masonry-wrap {
      margin: 0 -15px; } }
  @media only screen and (max-width: 767px) {
    .minimal-gallery.masonry-wrap {
      margin: 0 -15px; } }
  .minimal-gallery.masonry-wrap .masonary-item {
    padding: 0 45px;
    margin-top: 90px;
    display: block; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .minimal-gallery.masonry-wrap .masonary-item {
        padding: 0 15px;
        margin-top: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .minimal-gallery.masonry-wrap .masonary-item {
        padding: 0 15px;
        margin-top: 30px; } }
    @media only screen and (max-width: 767px) {
      .minimal-gallery.masonry-wrap .masonary-item {
        padding: 0 15px;
        margin-top: 30px; } }

/* ==========================
    Gradation Area 
============================*/
.bk-gradation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .bk-gradation {
      flex-wrap: wrap; } }
  .bk-gradation .item-grid {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    padding: 0 10px; }
    .bk-gradation .item-grid .line {
      position: absolute;
      top: 40px;
      right: 100%;
      -webkit-transform: translateX(50%);
      -ms-transform: translateX(50%);
      transform: translateX(50%);
      background: #ddd;
      width: 60%;
      height: 1px; }
      @media only screen and (max-width: 767px) {
        .bk-gradation .item-grid .line {
          display: none; } }
    .bk-gradation .item-grid .dot-wrap {
      max-width: 270px;
      position: relative;
      height: 80px;
      width: 80px;
      margin: 0 auto 24px; }
      .bk-gradation .item-grid .dot-wrap .dot {
        background: #0038E3; }
        .bk-gradation .item-grid .dot-wrap .dot::before, .bk-gradation .item-grid .dot-wrap .dot::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: inherit; }
        .bk-gradation .item-grid .dot-wrap .dot::before {
          width: 80px;
          height: 80px;
          opacity: .3; }
        .bk-gradation .item-grid .dot-wrap .dot::after {
          width: 60px;
          height: 60px;
          opacity: .7; }
        .bk-gradation .item-grid .dot-wrap .dot .count {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          height: 40px;
          width: 40px;
          line-height: 40px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          background: inherit;
          border-radius: 50%;
          text-align: center;
          z-index: 3;
          font-family: "Poppins", sans-serif; }
    .bk-gradation .item-grid .content {
      position: relative;
      text-align: center;
      max-width: 270px;
      margin: 0 auto;
      background: #fff;
      padding: 38px 30px 44px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
      -webkit-filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.06));
      filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.06));
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .bk-gradation .item-grid .content::before {
        position: absolute;
        bottom: 100%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        content: "";
        border-bottom: 13px solid #fff;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .bk-gradation .item-grid .content.bg-black {
        background: #000;
        color: #fff; }
        .bk-gradation .item-grid .content.bg-black::before {
          border-bottom-color: #000; }
        .bk-gradation .item-grid .content.bg-black h5 {
          color: #fff; }
      .bk-gradation .item-grid .content h5.heading {
        font-size: 16px;
        margin-bottom: 12px; }
    .bk-gradation .item-grid:first-child .line {
      display: none; }
    .bk-gradation .item-grid:hover .content {
      background: #222;
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.06);
      -webkit-filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.06));
      filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.06));
      color: #fff; }
      .bk-gradation .item-grid:hover .content::before {
        border-bottom-color: #222; }
      .bk-gradation .item-grid:hover .content h5.heading {
        color: #fff; }
      .bk-gradation .item-grid:hover .content p.bk_pra {
        color: #fff; }
    .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
      background: #f55d4e; }
    .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
      background: #9013fe; }
    .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
      background: #7ed321; }
    .bk-gradation .item-grid.custom-color--5 .dot-wrap .dot {
      background: #D0021B !important; }
    .bk-gradation .item-grid.custom-color--6 .dot-wrap .dot {
      background: #F5A623 !important; }
    .bk-gradation .item-grid.custom-color--7 .dot-wrap .dot {
      background: #7ed321 !important; }

/* =================
    Typed Text 
======================*/
.cd-headline.clip span {
  padding: 0; }

.cd-headline.clip .cd-words-wrapper.brown-color::after {
  background-color: #CE8F4F;
  width: 4px; }

.typed-text .content {
  text-align: left; }
  .typed-text .content h3.heading {
    max-width: 900px;
    line-height: 1.2;
    font-size: 56px;
    margin-bottom: 0;
    word-wrap: break-word;
    margin-top: -20px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .typed-text .content h3.heading {
        margin-top: 19px;
        font-size: 43px; } }
    @media only screen and (max-width: 767px) {
      .typed-text .content h3.heading {
        margin-top: 19px;
        font-size: 30px; } }
    .typed-text .content h3.heading span.text-variation-play {
      font-family: "Playfair Display", Georgia, serif;
      font-style: italic; }
  .typed-text .content p {
    font-size: 24px;
    word-wrap: break-word;
    max-width: 100%;
    margin-top: 30px; }
    @media only screen and (max-width: 767px) {
      .typed-text .content p {
        font-size: 16px;
        line-height: 28px; } }

/* ============================
    Political Area 
    http://prntscr.com/m3ajf8
=================================*/
.margin-50 {
  position: relative; }
  .margin-50::before {
    position: absolute;
    content: "";
    max-width: 1320px;
    height: 110px;
    z-index: 9;
    background: #fff;
    top: -50px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .margin-50::before {
    max-width: 992px;
    height: 110px;
    top: -70px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .margin-50::before {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .margin-50::before {
    display: none; } }

@media only screen and (max-width: 767px) {
  .margin-50::before {
    display: none; } }

.box-shadow-title {
  margin-top: -50px;
  padding: 80px 0;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
  background-color: #fff; }

.max-width-720 {
  max-width: 720px;
  margin: auto;
  font-size: 16px;
  line-height: 30px; }

/* ========================
Pricing PLans 
==========================*/
.ht-pricing-list-view {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.06); }
  .ht-pricing-list-view table {
    border: 0; }
  .ht-pricing-list-view thead tr th.pricing-header {
    padding: 0;
    width: 266px;
    border: 0;
    border-left: 1px solid rgba(51, 51, 51, 0.1);
    border-right: 1px solid rgba(51, 51, 51, 0.1);
    text-align: center; }
    .ht-pricing-list-view thead tr th.pricing-header .heading-h4 {
      font-size: 24px;
      color: #222222;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      letter-spacing: 0em;
      line-height: 1.23;
      margin: 0; }
    .ht-pricing-list-view thead tr th.pricing-header .heading-h6 {
      font-size: 14px;
      color: #222222;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      letter-spacing: 0em;
      line-height: 1.23;
      margin: 0; }
    .ht-pricing-list-view thead tr th.pricing-header:first-child {
      text-align: left;
      width: 370px;
      padding: 52px 30px 32px 46px;
      border-left: 0; }
  .ht-pricing-list-view thead tr th.pricing-header {
    padding: 52px 30px 32px;
    position: relative; }
    .ht-pricing-list-view thead tr th.pricing-header .feature-park {
      background: blue;
      padding: 6px 21px;
      position: absolute;
      top: 15px;
      left: 50%;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
      color: #fff;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0em;
      line-height: 1.23; }
    .ht-pricing-list-view thead tr th.pricing-header .heading-h6 {
      text-transform: uppercase;
      margin-bottom: 34px;
      display: block; }
    .ht-pricing-list-view thead tr th.pricing-header .price-wrap .price-wrap-inner {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .ht-pricing-list-view thead tr th.pricing-header .price-wrap .price-wrap-inner span {
        color: #222222; }
        .ht-pricing-list-view thead tr th.pricing-header .price-wrap .price-wrap-inner span.currency {
          font-size: 18px;
          margin-bottom: 0; }
        .ht-pricing-list-view thead tr th.pricing-header .price-wrap .price-wrap-inner span.price {
          font-size: 36px !important;
          line-height: .9;
          margin: 0 4px;
          font-weight: 600 !important; }
        .ht-pricing-list-view thead tr th.pricing-header .price-wrap .price-wrap-inner span.period {
          font-size: 14px;
          margin-bottom: 0;
          color: #999;
          -webkit-align-self: flex-end;
          -ms-flex-item-align: end;
          align-self: flex-end;
          line-height: 2; }
  .ht-pricing-list-view tbody tr td {
    border: 0;
    border-left: 1px solid rgba(51, 51, 51, 0.1);
    border-right: 1px solid rgba(51, 51, 51, 0.1);
    padding: 0 30px; }
    .ht-pricing-list-view tbody tr td:first-child {
      border-left: 0; }
    .ht-pricing-list-view tbody tr td:last-child {
      border-right: 0; }
    .ht-pricing-list-view tbody tr td.price-list ul {
      padding: 0;
      margin: 0;
      list-style: none; }
      .ht-pricing-list-view tbody tr td.price-list ul li {
        border-top: 1px solid rgba(51, 51, 51, 0.1);
        padding: 9px 0; }
        .ht-pricing-list-view tbody tr td.price-list ul li span.item-checked {
          color: #7ed321;
          font-size: 16px; }
        .ht-pricing-list-view tbody tr td.price-list ul li.ft--foot {
          padding: 30px 10px 40px; }

/*======================
    Career Area 
==========================*/
.brook-career-area {
  position: relative;
  z-index: 2; }

.career {
  padding-top: 69px;
  padding-right: 50px;
  padding-bottom: 80px;
  padding-left: 50px;
  -moz-box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.07);
  background-color: #fff; }
  .career .title h3.heading {
    border-bottom-width: 1px !important;
    padding-bottom: 20px !important;
    border-bottom-color: #eeeeee !important;
    border-bottom-style: solid !important; }

/* ===========================
    Maintanence Area 
==============================*/
.maintanence-wrapper .inner h2 {
  font-size: 90px;
  line-height: 1;
  color: #7bb7fc;
  margin-bottom: 27px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .maintanence-wrapper .inner h2 {
      font-size: 63px; } }
  @media only screen and (max-width: 767px) {
    .maintanence-wrapper .inner h2 {
      font-size: 41px; } }

.maintanence-wrapper .inner .maintanence-text {
  font-size: 18px;
  line-height: 1.95;
  margin-bottom: 35px;
  max-width: 670px;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .maintanence-wrapper .inner .maintanence-text {
      max-width: 564px; } }
  @media only screen and (max-width: 767px) {
    .maintanence-wrapper .inner .maintanence-text {
      max-width: 564px;
      font-size: 16px; } }

.maintanence-wrapper .inner .maintanence-form {
  max-width: 700px;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .maintanence-wrapper .inner .maintanence-form {
      max-width: 564px; } }
  @media only screen and (max-width: 767px) {
    .maintanence-wrapper .inner .maintanence-form {
      max-width: 400px; } }
  .maintanence-wrapper .inner .maintanence-form input {
    padding-right: 110px;
    color: #777;
    border-color: #eee;
    background-color: #fff;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    outline: none;
    border: 1px solid #eee;
    border-radius: 0;
    padding: 3px 20px;
    height: 62px; }
  .maintanence-wrapper .inner .maintanence-form button {
    border: 0 !important;
    border-radius: 0;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 25px;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    height: 62px;
    line-height: 62px;
    color: #0038E3;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .maintanence-wrapper .inner .maintanence-form button:hover {
      background-color: #0038E3;
      color: #fff; }

.maintanence-wrapper .inner .form-desc {
  margin-top: 22px;
  color: #fff;
  font-size: 14px; }

.maintenance-social-networks {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 55px;
  height: 1.5em;
  margin: auto;
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform: rotate(90deg) translate(50%, 50%);
  -ms-transform: rotate(90deg) translate(50%, 50%);
  transform: rotate(90deg) translate(50%, 50%); }
  @media only screen and (max-width: 575px) {
    .maintenance-social-networks {
      display: none; } }
  .maintenance-social-networks .inner {
    margin: -5px -15px; }
    .maintenance-social-networks .inner a {
      display: inline-block;
      margin: 5px 15px;
      padding: 5px 0;
      color: #fff !important; }
      .maintenance-social-networks .inner a span {
        position: relative;
        overflow: hidden;
        z-index: 1;
        line-height: 1.58;
        font-size: 14px;
        font-weight: 500; }
        .maintenance-social-networks .inner a span::before {
          content: "";
          width: 0;
          height: 1px;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background: currentColor; }
      .maintenance-social-networks .inner a:hover span::before {
        width: 100%;
        left: 0;
        right: auto; }

.page-template-comming-soon {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%; }
  .page-template-comming-soon .bg_image--58 {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .page-template-comming-soon .site {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }

/* Coming Soon Wrapper */
.comming-soon-wrapper {
  max-width: 660px; }
  @media only screen and (max-width: 767px) {
    .comming-soon-wrapper {
      max-width: 100%;
      padding: 30px; } }
  .comming-soon-wrapper .content h3.heading {
    position: relative; }
    .comming-soon-wrapper .content h3.heading::before {
      content: "";
      position: absolute;
      top: 26px;
      left: -200px;
      background: #fff;
      height: 1px;
      width: 170px; }

/*==============================
Contagion Area
====================================*/
.single-contagion {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .single-contagion .contagion-image {
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 100%; }
    @media only screen and (max-width: 767px) {
      .single-contagion .contagion-image {
        margin: 0 auto;
        margin-bottom: 30px; } }
  .single-contagion .contagion-content {
    width: calc(100% - 115px);
    padding-left: 15px; }
    @media only screen and (max-width: 767px) {
      .single-contagion .contagion-content {
        width: 100%;
        padding-left: 0;
        text-align: center; } }
    .single-contagion .contagion-content h3 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      color: #0038E3; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-contagion .contagion-content h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-contagion .contagion-content h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px; } }
      @media only screen and (max-width: 767px) {
        .single-contagion .contagion-content h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px; } }
    .single-contagion .contagion-content p {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      margin-bottom: 0; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-contagion .contagion-content p {
          font-size: 14px;
          line-height: 22px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-contagion .contagion-content p {
          font-size: 14px;
          line-height: 22px; } }
      @media only screen and (max-width: 767px) {
        .single-contagion .contagion-content p {
          font-size: 14px;
          line-height: 22px; } }
  .single-contagion:hover .contagion-image {
    animation: heartbeat 1.5s ease-in-out infinite both; }

.single-sympotom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #F9F2FF;
  padding: 40px 0;
  background-image: url(/img/corona/design.png);
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.07);
  position: relative;
  margin-right: -45px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-sympotom {
      margin-right: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-sympotom {
      margin-right: 0; } }
  @media only screen and (max-width: 767px) {
    .single-sympotom {
      margin-right: 0;
      padding: 30px 0; } }
  .single-sympotom .sympotom-thumb {
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 100%;
    margin-left: -45px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-sympotom .sympotom-thumb {
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-sympotom .sympotom-thumb {
        margin-left: 0; } }
    @media only screen and (max-width: 767px) {
      .single-sympotom .sympotom-thumb {
        margin-left: 0; } }
  .single-sympotom .sympotom-content {
    width: calc(100% - 55px);
    padding-left: 30px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-sympotom .sympotom-content {
        width: calc(100% - 100px);
        padding-left: 10px;
        padding-right: 10px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-sympotom .sympotom-content {
        width: calc(100% - 100px);
        padding-left: 10px;
        padding-right: 10px; } }
    @media only screen and (max-width: 767px) {
      .single-sympotom .sympotom-content {
        width: calc(100% - 100px);
        padding-left: 10px;
        padding-right: 10px; } }
    .single-sympotom .sympotom-content h3 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      color: #0038E3; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-sympotom .sympotom-content h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-sympotom .sympotom-content h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px; } }
      @media only screen and (max-width: 767px) {
        .single-sympotom .sympotom-content h3 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 10px; } }
    .single-sympotom .sympotom-content p {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      max-width: 255px;
      margin-bottom: 0; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-sympotom .sympotom-content p {
          font-size: 14px;
          line-height: 22px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-sympotom .sympotom-content p {
          font-size: 14px;
          line-height: 22px; } }
      @media only screen and (max-width: 767px) {
        .single-sympotom .sympotom-content p {
          font-size: 14px;
          line-height: 22px; } }
  .single-sympotom:hover .sympotom-thumb {
    animation: heartbeat 1.5s ease-in-out infinite both; }

.country-area {
  top: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .6s ease-in-out; }
  .country-area .single-country {
    position: absolute;
    transition: all .6s ease-in-out; }
    .country-area .single-country.one {
      top: 28%;
      right: 43%; }
    .country-area .single-country.two {
      top: 36%;
      left: 25%; }
    .country-area .single-country.three {
      top: 53%;
      right: 32%; }
    .country-area .single-country.four {
      top: 33%;
      right: 27%; }
    .country-area .single-country.five {
      bottom: 43%;
      right: 45%; }
    .country-area .single-country.six {
      top: 70%;
      left: 27%; }
    .country-area .single-country .country-number-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px 10px;
      width: 200px;
      background: #fff;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: translateY(-120px);
      background-image: url(/img/corona/country/design.png);
      background-position: right;
      background-repeat: no-repeat;
      border-radius: 10px;
      box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.06);
      z-index: 5;
      transition: all .6s ease-in-out; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .country-area .single-country .country-number-box {
          margin-left: -18px; } }
      @media only screen and (max-width: 767px) {
        .country-area .single-country .country-number-box {
          display: none; } }
      .country-area .single-country .country-number-box::before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 15px 0 0;
        border-color: #ffffff transparent transparent transparent;
        content: '';
        position: absolute;
        bottom: -23px;
        left: 15px; }
        @media only screen and (max-width: 767px) {
          .country-area .single-country .country-number-box::before {
            display: none; } }
      .country-area .single-country .country-number-box .country-text p {
        font-size: 13px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 0; }
    .country-area .single-country .country-box {
      cursor: pointer; }
      .country-area .single-country .country-box:hover .country-number-box {
        opacity: 1;
        transform: translateY(-90px); }

.single-prevination {
  text-align: center; }
  .single-prevination .prevination-iamge {
    width: 130px;
    height: 130px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 100%; }
  .single-prevination .prevination-content h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #0038E3; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-prevination .prevination-content h3 {
        font-size: 18px;
        line-height: 22px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-prevination .prevination-content h3 {
        font-size: 18px;
        line-height: 22px; } }
    @media only screen and (max-width: 767px) {
      .single-prevination .prevination-content h3 {
        font-size: 18px;
        line-height: 22px; } }
  .single-prevination:hover .prevination-iamge {
    animation: heartbeat 1.5s ease-in-out infinite both; }

.single-hand-wash {
  text-align: center; }
  .single-hand-wash .hand-wash-image {
    width: 160px;
    height: 160px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 100%; }
    @media only screen and (max-width: 767px) {
      .single-hand-wash .hand-wash-image {
        width: 120px;
        height: 120px; } }
  .single-hand-wash .hand-wash-content h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .single-hand-wash .hand-wash-content h5 {
        font-size: 15px;
        line-height: 22px; } }
  .single-hand-wash:hover .hand-wash-image {
    animation: heartbeat 1.5s ease-in-out infinite both; }

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

/* ----------------------
Testimonial Area
-------------------------*/
.bk-testimonial-area {
  position: relative;
  z-index: 2; }

.testimonial_style--1 {
  background: #fff;
  padding: 44px 40px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial_style--1 {
      padding: 44px 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial_style--1 {
      padding: 36px 18px; } }
  @media only screen and (max-width: 767px) {
    .testimonial_style--1 {
      padding: 33px 28px; } }
  .testimonial_style--1 .content .testimonial-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 26px; }
    .testimonial_style--1 .content .testimonial-info .post-thumbnail {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
      .testimonial_style--1 .content .testimonial-info .post-thumbnail img {
        border-radius: 50%;
        height: auto;
        max-width: 100%; }
    .testimonial_style--1 .content .testimonial-info .clint-info {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-left: 20px; }
      .testimonial_style--1 .content .testimonial-info .clint-info h6 {
        font-size: 18px;
        line-height: 1.388;
        margin-bottom: 6px;
        color: #222222;
        font-weight: 600;
        font-family: "Poppins", sans-serif; }
      .testimonial_style--1 .content .testimonial-info .clint-info span {
        color: #333333;
        font-weight: 500;
        line-height: 1.58;
        font-size: 14px;
        font-family: "Poppins", sans-serif; }
  .testimonial_style--1 .content .testimonial-quote {
    padding: 2px 5px 0 0;
    font-size: 28px;
    text-align: right;
    line-height: 0; }
    .testimonial_style--1 .content .testimonial-quote span {
      color: #0038E3;
      font-weight: 900; }
  .testimonial_style--1:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
    color: #fff;
    background: #0038E3; }
    .testimonial_style--1:hover .content p {
      color: #ffffff; }
      .testimonial_style--1:hover .content p span.theme-color {
        color: #ffffff; }
    .testimonial_style--1:hover .content .testimonial-info .clint-info h6 {
      color: #ffffff; }
    .testimonial_style--1:hover .content .testimonial-info .clint-info span {
      color: #ffffff; }
    .testimonial_style--1:hover .content .testimonial-quote span {
      color: #ffffff; }
  .testimonial_style--1.hover-transparent:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  .testimonial_style--1.space-large--topbottom {
    padding: 58px 60px 122px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .testimonial_style--1.space-large--topbottom {
        padding: 45px 30px 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .testimonial_style--1.space-large--topbottom {
        padding: 45px 30px 50px; } }
    @media only screen and (max-width: 767px) {
      .testimonial_style--1.space-large--topbottom {
        padding: 45px 30px 50px; } }
  .testimonial_style--1.bg-dark {
    background: #000;
    color: #fff; }
    .testimonial_style--1.bg-dark .content p {
      font-size: 20px;
      line-height: 1.75;
      color: #fff; }
    .testimonial_style--1.bg-dark .content .testimonial-info .clint-info h6 {
      color: #fff; }
    .testimonial_style--1.bg-dark .content .testimonial-info .clint-info span {
      color: #fff; }
    .testimonial_style--1.bg-dark .content .testimonial-quote {
      padding: 52px 0 0;
      font-size: 28px;
      text-align: right;
      line-height: 0; }
      .testimonial_style--1.bg-dark .content .testimonial-quote span {
        font-size: 28px;
        color: #fff; }
  .testimonial_style--1.theme-color-2 .content p {
    color: #333333; }
  .testimonial_style--1.theme-color-2:hover {
    color: #fff; }
    .testimonial_style--1.theme-color-2:hover .content p {
      color: #ffffff; }
    .testimonial_style--1.theme-color-2:hover .content .testimonial-quote span {
      color: #ffffff; }
  .testimonial_style--1.purple-color-2 .content p {
    color: #333333; }
  .testimonial_style--1.purple-color-2 .content .testimonial-quote span {
    color: #AC61EE; }
  .testimonial_style--1.purple-color-2:hover {
    background: #AC61EE;
    color: #fff; }
    .testimonial_style--1.purple-color-2:hover .content p {
      color: #ffffff; }
      .testimonial_style--1.purple-color-2:hover .content p span {
        color: #ffffff; }
    .testimonial_style--1.purple-color-2:hover .content .testimonial-quote span {
      color: #ffffff; }

.testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  color: #fff;
  background: #0038E3 !important; }
  .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark .content p {
    color: #ffffff; }
    .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark .content p span.theme-color {
      color: #ffffff; }
  .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark .content .testimonial-info .clint-info h6 {
    color: #ffffff; }
  .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark .content .testimonial-info .clint-info span {
    color: #ffffff; }
  .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark .content .testimonial-quote span {
    color: #ffffff; }

.testimonial-color-variation.testimonial-bg-red .slick-center .testimonial_style--1.bg-dark {
  background: #F0263F !important; }

.testimonial_style--2 {
  background: #fff;
  padding: 36px 30px 32px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .testimonial_style--2 .content h4.heading {
    margin-right: 30px;
    margin-bottom: 25px; }
  .testimonial_style--2 .content ul.rating {
    padding: 0;
    margin: 0;
    margin-bottom: 27px;
    list-style: none; }
    .testimonial_style--2 .content ul.rating li i {
      padding: 0 2px;
      color: #ffb944;
      font-size: 12px; }
  .testimonial_style--2 .content p {
    font-size: 16px;
    line-height: 1.88; }
  .testimonial_style--2 .content .testimonial-info {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 30px;
    padding-right: 35px;
    margin-top: 26px;
    border-top: 1px solid #eee; }
    .testimonial_style--2 .content .testimonial-info .post-thumbnail {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
      .testimonial_style--2 .content .testimonial-info .post-thumbnail img {
        border-radius: 50%;
        height: auto;
        max-width: 100%; }
    .testimonial_style--2 .content .testimonial-info .clint-info {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-left: 20px; }
      .testimonial_style--2 .content .testimonial-info .clint-info h6 {
        font-size: 18px;
        line-height: 1.388;
        margin-bottom: 4px;
        font-family: "Poppins", sans-serif; }
      .testimonial_style--2 .content .testimonial-info .clint-info span {
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 1.58;
        font-size: 14px;
        font-family: "Poppins", sans-serif; }
    .testimonial_style--2 .content .testimonial-info .testimonial-quote {
      position: absolute;
      right: 0;
      top: 33px;
      padding: 2px 5px 0 0;
      font-size: 28px;
      text-align: right;
      line-height: 0;
      color: #0038E3; }
  .testimonial_style--2:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.07); }

/* Testimonial Deffernt Css */
.testimonial-space-right .slick-list {
  padding-right: 140px !important; }

.testimonial-fixed-width {
  width: 456px;
  max-width: 100%;
  margin: 0 auto 70px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .testimonial-fixed-width {
      margin: 0 auto 25px; } }

.brook-testimonial-area .title-max-width {
  width: 600px;
  max-width: 100%;
  float: right; }

.testimonial-nav-style .clint-thumb {
  -webkit-transform: scale(0.6667);
  -ms-transform: scale(0.6667);
  transform: scale(0.6667);
  background: none;
  -webkit-transition: all .6s ease 0s;
  transition: all .6s ease 0s; }
  .testimonial-nav-style .clint-thumb img {
    border-radius: 50%;
    height: auto;
    max-width: 100%;
    width: 100%;
    vertical-align: top;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .testimonial-nav-style .clint-thumb img {
        width: 114px !important; } }
  .testimonial-nav-style .clint-thumb:hover {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8); }

.testimonial-nav-style .slick-center .clint-thumb {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  padding: 6px;
  width: 132px !important;
  height: 132px;
  border-radius: 50%;
  background-image: -webkit-linear-gradient(right, #fe5b34, #fe378c);
  background-image: linear-gradient(270deg, #fe5b34 0, #fe378c); }

.testimonial-nav-style.testimonial-nav--2 .slick-center .clint-thumb {
  background-image: inherit; }

.testimonial-nav-content .testimonial-nav .content {
  width: 780px;
  margin: 0 auto;
  max-width: 100%;
  text-align: center; }
  .testimonial-nav-content .testimonial-nav .content p {
    font-size: 30px;
    line-height: 1.6;
    font-style: italic;
    font-family: "Playfair Display", serif;
    margin-bottom: 40px;
    color: #fff; }
    @media only screen and (max-width: 767px) {
      .testimonial-nav-content .testimonial-nav .content p {
        font-size: 26px;
        line-height: 1.5; } }
  .testimonial-nav-content .testimonial-nav .content .rating {
    padding: 0;
    margin: 0;
    margin-bottom: 27px;
    list-style: none; }
    .testimonial-nav-content .testimonial-nav .content .rating li i {
      padding: 0 2px;
      color: #ffb944;
      font-size: 18px; }
  .testimonial-nav-content .testimonial-nav .content .testimonial-info .clint-info h6 {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif; }
  .testimonial-nav-content .testimonial-nav .content .testimonial-info .clint-info span {
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    display: inline-block;
    color: #fff; }

.testimonial-nav-content.testimonial-content--2 .testimonial-nav .content p {
  font-size: 30px;
  line-height: 1.5;
  font-style: normal;
  font-family: "louis_george_caferegular";
  color: #222222; }
  @media only screen and (max-width: 767px) {
    .testimonial-nav-content.testimonial-content--2 .testimonial-nav .content p {
      font-size: 22px; } }

.testimonial-nav-content.testimonial-content--2 .testimonial-nav .content .testimonial-info .clint-info h6 {
  color: #222222;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif; }

.testimonial-nav-content.testimonial-content--2 .testimonial-nav .content .testimonial-info .clint-info span {
  font-size: 15px;
  font-style: italic;
  display: inline-block;
  color: #333333; }

/* Creative Testimonial */
.bg_image--5 {
  background-attachment: fixed; }

/* Testimonial Home Variation */
.bg-architecture--1 {
  background-image: url(/img/testimonial/testimonial-bg/home-architect-image-03.png);
  background-repeat: no-repeat;
  background-position: left bottom; }

.bg-architecture--2 {
  background-image: url(/img/testimonial/testimonial-bg/home-architect-image-02.jpg);
  background-size: cover;
  background-repeat: no-repeat; }

.bg-yoga--2 {
  background-image: url(/img/yoga/testimonials.png);
  background-size: cover;
  background-repeat: no-repeat; }

.testimonanial-style--3 .quote {
  margin: 0 auto 49px;
  font-size: 28px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 2px solid #FCB72B;
  border-radius: 50%;
  text-align: center; }
  .testimonanial-style--3 .quote i {
    color: #FCB72B; }

.testimonanial-style--3 .testimonial-info {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center; }
  .testimonanial-style--3 .testimonial-info .post-thumbnail img {
    border-radius: 50%;
    height: auto;
    max-width: 100%; }
  .testimonanial-style--3 .testimonial-info .clint-info {
    margin-left: 20px; }
    .testimonanial-style--3 .testimonial-info .clint-info h6 {
      font-size: 18px;
      line-height: 1.388;
      margin-bottom: 4px;
      font-family: "Poppins", sans-serif;
      color: #ffffff; }
    .testimonanial-style--3 .testimonial-info .clint-info span {
      font-weight: 500;
      letter-spacing: 0em;
      line-height: 1.58;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      color: #ffffff; }

.testimonial_style--4 .content {
  width: 780px;
  margin: 0 auto;
  max-width: 100%;
  text-align: center; }
  .testimonial_style--4 .content p {
    font-size: 30px;
    line-height: 1.54;
    text-align: center;
    color: #fff;
    font-weight: 400; }
    @media only screen and (max-width: 767px) {
      .testimonial_style--4 .content p {
        font-size: 22px; } }
  .testimonial_style--4 .content .testimonial-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px; }
    .testimonial_style--4 .content .testimonial-info .post-thumbnail {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
      .testimonial_style--4 .content .testimonial-info .post-thumbnail img {
        border-radius: 50%;
        height: auto;
        max-width: 100%; }
    .testimonial_style--4 .content .testimonial-info .clint-info {
      text-align: left;
      margin-left: 20px; }
      .testimonial_style--4 .content .testimonial-info .clint-info h6 {
        font-size: 18px;
        line-height: 1.388;
        margin-bottom: 6px;
        color: #fff;
        font-weight: 600;
        font-family: "Poppins", sans-serif; }
      .testimonial_style--4 .content .testimonial-info .clint-info span {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
        line-height: 1.58;
        font-size: 14px;
        font-family: "Poppins", sans-serif; }

.testimonial_style--5 .content {
  margin: 0 auto;
  max-width: 100%;
  text-align: center; }
  .testimonial_style--5 .content p {
    font-size: 36px;
    line-height: 1.54;
    text-align: center;
    color: #fff;
    font-weight: 400; }
  .testimonial_style--5 .content .testimonial-info {
    display: block;
    padding-top: 35px; }
    .testimonial_style--5 .content .testimonial-info .post-thumbnail img {
      border-radius: 50%;
      height: auto;
      max-width: 100%; }
    .testimonial_style--5 .content .testimonial-info .clint-info {
      text-align: center;
      margin-top: 20px; }
      .testimonial_style--5 .content .testimonial-info .clint-info h6 {
        font-size: 26px;
        line-height: 1.388;
        margin-bottom: 6px;
        color: #fff;
        font-weight: 600;
        font-family: "Poppins", sans-serif; }
      .testimonial_style--5 .content .testimonial-info .clint-info span {
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
        line-height: 1.58;
        font-size: 18px;
        font-family: "Poppins", sans-serif; }

@media only screen and (max-width: 767px) {
  .testimonial_style--5 .content p {
    font-size: 22px; } }

/* ================================
 Testimonial Horizontal Area
===================================*/
.testimonial--horizontal--active {
  position: relative;
  padding-bottom: 100px;
  padding-right: 210px;
  margin-left: 32px; }
  @media only screen and (max-width: 767px) {
    .testimonial--horizontal--active {
      padding-right: 90px;
      padding-bottom: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial--horizontal--active {
      padding-right: 30px;
      padding-bottom: 54px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial--horizontal--active {
      padding-right: 30px;
      padding-bottom: 54px; } }

.testimonial--horizontal--active .swiper-pagination.swiper-pagination-progressbar {
  position: absolute;
  bottom: -97px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  top: auto; }

.testimonial--horizontal--active .swiper-pagination.swiper-pagination-progressbar {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  height: 3px;
  width: 400px;
  max-width: 100%;
  background: #d8d8d8; }

.testimonial--horizontal--active .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #0038E3;
  height: 100%;
  position: absolute; }

.testimonial--horizontal--active .swiper-pagination-progressbar .swiper-pagination-progressbar-fill:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -7px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: inherit; }

.testimonial--horizontal--active .swiper-pagination.swiper-pagination-progressbar {
  position: absolute;
  bottom: -97px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  top: auto; }

.testimonial--horizontal--active .swiper-pagination.swiper-pagination-custom {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  height: 3px;
  width: 400px;
  max-width: 100%;
  background: #d8d8d8; }

.testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #0038E3;
  height: 100%;
  position: absolute; }

.testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled:before {
  content: "";
  position: absolute;
  top: -6px;
  right: -7px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: inherit; }

@media only screen and (max-width: 575px) {
  .testimonial-space-right .slick-list {
    padding-right: 0px !important; } }

/*====================
 404 Area 
 =====================*/
.error-not-found {
  background: #000;
  min-height: 100vh;
  display: flex;
  align-items: center; }
  .error-not-found .error-inner {
    padding-top: 100px;
    padding-bottom: 100px; }
    .error-not-found .error-inner .error-text p {
      font-size: 18px;
      line-height: 1.95;
      max-width: 600px;
      margin: 0 auto; }

/*================
Tabs Area 
==================*/
.tab,
.scrollspy {
  border: 2px solid #fafafa;
  padding: 30px; }

.nav-pills .nav-link {
  color: #222222; }

.tab-content > .tab-pane {
  font-size: 14px; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0038E3; }

.tab.vertical .tab-nav {
  flex-basis: 200px; }
  @media only screen and (max-width: 767px) {
    .tab.vertical .tab-nav {
      flex-basis: 100%; } }

.tab.vertical .tab-content {
  flex-basis: calc(100% - 200px);
  padding-left: 30px;
  padding-right: 30px; }
  @media only screen and (max-width: 767px) {
    .tab.vertical .tab-content {
      flex-basis: 100%;
      padding-left: 0;
      padding-right: 0;
      padding-top: 20px;
      padding-bottom: 10px; } }

@media (min-width: 768px) {
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; } }

/*==========================
    Player Area CSS
===========================*/
.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  background: #0069FF;
  color: #fff; }

.plyr--full-ui input[type="range"] {
  color: #0069FF; }

.single-audio-player {
  padding: 15px; }
  .single-audio-player .audio-title a {
    color: #0069FF; }
  .single-audio-player .plyr {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); }

.single-video-player video {
  width: 100%; }

.audio-player-title .title {
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  max-width: 250px;
  margin-bottom: 50px; }
  @media only screen and (max-width: 767px) {
    .audio-player-title .title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 30px; } }

.audio-list-tab ul {
  display: block; }
  .audio-list-tab ul li a {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    margin-left: 10px;
    margin-bottom: 15px;
    font-weight: 500;
    display: inline-block;
    transition: all 0.3s ease-in-out; }
    @media only screen and (max-width: 767px) {
      .audio-list-tab ul li a {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px; } }
    .audio-list-tab ul li a::before {
      content: "\f10b";
      font-family: "Ionicons";
      margin-right: 10px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out; }
    .audio-list-tab ul li a.active::before, .audio-list-tab ul li a:hover::before {
      opacity: 1;
      visibility: visible; }

.audio-track {
  position: relative;
  margin-top: 145px;
  left: -30%;
  bottom: -100px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .audio-track {
      left: 0;
      bottom: -50px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .audio-track {
      left: 0;
      bottom: -30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .audio-track {
      left: 0;
      bottom: 0;
      margin-top: 100px; } }
  @media only screen and (max-width: 767px) {
    .audio-track {
      left: 0;
      bottom: 0;
      margin-top: 80px; } }
  .audio-track .info h3 {
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 0;
    margin-left: 110px; }
    @media only screen and (max-width: 767px) {
      .audio-track .info h3 {
        font-size: 16px;
        line-height: 22px;
        margin-left: 80px; } }
  .audio-track svg {
    position: absolute;
    top: 50%;
    left: 0;
    stroke: #fff;
    width: 90px;
    height: 90px;
    background-color: #0038E3;
    border-radius: 100%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 0; }
    @media only screen and (max-width: 767px) {
      .audio-track svg {
        width: 60px;
        height: 60px; } }

audio {
  display: block;
  height: 100%; }

.play {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;
  width: 90px;
  background: transparent !important;
  height: 90px;
  cursor: pointer;
  transform: translateY(-50%);
  border: none; }
  @media only screen and (max-width: 767px) {
    .play {
      width: 60px;
      height: 60px; } }

.play::before {
  content: '';
  display: block;
  width: 90px;
  height: 90px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  opacity: 0;
  position: relative;
  left: -8px;
  top: 0px; }
  @media only screen and (max-width: 767px) {
    .play::before {
      width: 60px;
      height: 60px; } }

.play::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/img/icons/audio-play.png");
  background-size: 80%;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

#circle {
  visibility: hidden;
  stroke-width: 5; }

.playing #circle {
  visibility: visible; }

.playing .play::after {
  background-image: url("/img/icons/audio-push.png"); }

.playing .play::before {
  opacity: 1; }

/*================================
	Components Style
=================================*/
/* ---------------------------
    Video Area 
-----------------------------*/
.comp-video.line--between,
.comp-video .line--between {
  position: relative; }
  .comp-video.line--between::before, .comp-video.line--between::after,
  .comp-video .line--between::before,
  .comp-video .line--between::after {
    content: "";
    position: absolute;
    top: 40px;
    height: 1px;
    width: calc(50% - 85px);
    background: #eee; }
  .comp-video.line--between::before,
  .comp-video .line--between::before {
    left: 0; }
  .comp-video.line--between::after,
  .comp-video .line--between::after {
    right: 0; }

.line--between {
  position: relative; }
  .line--between::before, .line--between::after {
    content: "";
    position: absolute;
    top: 40px;
    height: 1px;
    width: calc(50% - 85px);
    background: #eee; }
  .line--between::before {
    left: 0; }
  .line--between::after {
    right: 0; }

.video-btn {
  text-align: center; }
  .video-btn.breadcaump-video-position {
    position: absolute;
    left: 58%;
    bottom: 30px; }
  .video-btn a.video-icon,
  .video-btn a .video-icon {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #0038E3;
    -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2); }
    .video-btn a.video-icon::before,
    .video-btn a .video-icon::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      -webkit-transform: translate(-6px, -9px);
      -ms-transform: translate(-6px, -9px);
      transform: translate(-6px, -9px);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 15px solid #fff; }
    .video-btn a.video-icon.second-icon,
    .video-btn a .video-icon.second-icon {
      width: 90px;
      height: 90px; }
      .video-btn a.video-icon.second-icon::before,
      .video-btn a .video-icon.second-icon::before {
        border-top-width: 11px;
        border-bottom-width: 11px;
        border-left-width: 19px;
        transform: translate(-6px, -12px); }
    .video-btn a.video-icon.third-icon,
    .video-btn a .video-icon.third-icon {
      width: 110px;
      height: 110px; }
      .video-btn a.video-icon.third-icon::before,
      .video-btn a .video-icon.third-icon::before {
        border-top-width: 14px;
        border-bottom-width: 14px;
        border-left-width: 24px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .video-btn a.video-icon.four-icon,
    .video-btn a .video-icon.four-icon {
      width: 130px;
      height: 130px; }
      .video-btn a.video-icon.four-icon::before,
      .video-btn a .video-icon.four-icon::before {
        border-top-width: 16px;
        border-bottom-width: 16px;
        border-left-width: 28px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .video-btn a.video-icon.secondary-color,
    .video-btn a .video-icon.secondary-color {
      background: #FF5EE1; }
    .video-btn a.video-icon.third-icon.brown-color,
    .video-btn a .video-icon.third-icon.brown-color {
      background: #fff; }
      .video-btn a.video-icon.third-icon.brown-color::before,
      .video-btn a .video-icon.third-icon.brown-color::before {
        border-left: 24px solid currentColor; }
    .video-btn a.video-icon.bg-brown-color,
    .video-btn a .video-icon.bg-brown-color {
      background: #CE8F4F; }
      .video-btn a.video-icon.bg-brown-color::before,
      .video-btn a .video-icon.bg-brown-color::before {
        border-left: 15px solid #fff; }
    .video-btn a.video-icon:hover,
    .video-btn a .video-icon:hover {
      -webkit-transform: scale3d(1.15, 1.15, 1.15);
      transform: scale3d(1.15, 1.15, 1.15); }
  .video-btn a.watch-text,
  .video-btn a .watch-text {
    color: #333333;
    margin: 32px 0 0;
    font-size: 14px;
    border: 0 none;
    padding: 0;
    display: block;
    font-weight: 600;
    -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
    transition: all 1s cubic-bezier(0, 0, 0.2, 1); }
    .video-btn a.watch-text:hover,
    .video-btn a .watch-text:hover {
      color: #0038E3; }

.video-btn-2 .icon-gradient {
  width: 130px;
  height: 130px;
  box-shadow: none;
  background: none;
  display: block;
  margin: 0 auto;
  -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1) !important;
  border-radius: 50%;
  position: relative;
  z-index: 2; }
  .video-btn-2 .icon-gradient::before {
    -webkit-transition: all .5s;
    transition: all .5s;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .15;
    position: absolute;
    content: "";
    border-radius: 50%;
    background-color: #0038E3;
    background-image: linear-gradient(-133deg, #899664 0, #0038E3 100%); }
  .video-btn-2 .icon-gradient::after {
    background-color: #0038E3;
    background-image: linear-gradient(-133deg, #899664 0, #0038E3 100%);
    width: 90px;
    height: 90px;
    top: 20px;
    left: 20px;
    z-index: -1;
    position: absolute;
    content: "";
    border-radius: 50%; }
  .video-btn-2 .icon-gradient.color-pink::before {
    background-color: #FE378C;
    background-image: linear-gradient(-133deg, #FE5B34 0, #FE378C 100%); }
  .video-btn-2 .icon-gradient.color-pink::after {
    background-color: #FE378C;
    background-image: linear-gradient(-133deg, #FE5B34 0, #FE378C 100%); }
  .video-btn-2 .icon-gradient .video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 1;
    margin-left: 1px;
    z-index: 3;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .video-btn-2 .icon-gradient .video-icon::before {
      content: "";
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 15px solid #fff;
      border-top-width: 11px;
      border-bottom-width: 11px;
      border-left-width: 19px; }
  .video-btn-2 .icon-gradient:hover::before {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    opacity: 0; }
  .video-btn-2 .icon-gradient:hover.color-pink::before {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    opacity: 0; }

.video-with-thumb {
  position: relative; }
  .video-with-thumb .position--center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.slider-business .video-btn a .video-icon.secondary-color {
  background: #FF5EE1 !important; }

/* =========================
Breadcaump Area 
=============================*/
.breadcaump-area.breadcaump-title-bar .breadcaump-inner .heading {
  font-weight: 600;
  line-height: 1.41;
  font-size: 32px;
  font-family: "Poppins", sans-serif; }
  .breadcaump-area.breadcaump-title-bar .breadcaump-inner .heading.heading-h1 {
    font-weight: 600;
    line-height: 1.41;
    font-size: 56px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .breadcaump-area.breadcaump-title-bar .breadcaump-inner .heading.heading-h1 {
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      .breadcaump-area.breadcaump-title-bar .breadcaump-inner .heading.heading-h1 {
        font-size: 40px; } }
    @media only screen and (max-width: 575px) {
      .breadcaump-area.breadcaump-title-bar .breadcaump-inner .heading.heading-h1 {
        font-size: 30px; } }

.breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump {
  padding: 0;
  margin: 0;
  margin-top: 29px; }
  .breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li {
    display: inline-block;
    position: relative; }
    .breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li a {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.58;
      position: relative;
      overflow: hidden;
      z-index: 1;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-family: "Poppins", sans-serif; }
      .breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li a::after {
        content: "";
        width: 0;
        height: 1px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        z-index: -1;
        -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        background: currentColor; }
      .breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li a:hover::after {
        width: 100%;
        left: 0;
        right: auto; }
    .breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li + li {
      position: relative;
      padding-left: 22px; }
      .breadcaump-area.breadcaump-title-bar .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li + li::before {
        content: "\f105";
        font-size: 12px;
        position: absolute;
        left: 6px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-family: "Font Awesome 5 Free";
        font-weight: 900; }

.breadcaump-area.breadcaump-title-white .breadcaump-inner .heading {
  color: #ffffff; }

.breadcaump-area.breadcaump-title-white .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li {
  color: #ffffff; }
  .breadcaump-area.breadcaump-title-white .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li::before {
    color: #ffffff; }
  .breadcaump-area.breadcaump-title-white .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li a {
    color: #ffffff; }
    .breadcaump-area.breadcaump-title-white .breadcaump-inner .breadcrumb-insite ul.core-breadcaump li a::after {
      background-color: #ffffff; }

/* =======================
    Instagram Area 
==========================*/
/* Instagram Style 01 */
.instagram-grid-wrap {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px; }
  .instagram-grid-wrap.instagram-grid-5 {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .instagram-grid-wrap.instagram-grid-5 {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px; } }
    @media only screen and (max-width: 767px) {
      .instagram-grid-wrap.instagram-grid-5 {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px; } }
  .instagram-grid-wrap .item-grid.grid-style--1 {
    position: relative;
    overflow: hidden; }
    .instagram-grid-wrap .item-grid.grid-style--1 .thumb a img {
      border-radius: inherit;
      width: 100%;
      -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
      transition: all 1.5s cubic-bezier(0, 0, 0.2, 1); }
    .instagram-grid-wrap .item-grid.grid-style--1 .item-info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      visibility: hidden;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      cursor: pointer;
      border-radius: inherit;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .instagram-grid-wrap .item-grid.grid-style--1 .item-info .inner {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        color: #fff;
        text-align: center;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: 0;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .instagram-grid-wrap .item-grid.grid-style--1 .item-info .inner a {
          position: relative;
          color: #fff;
          font-size: 14px;
          display: inline-block;
          margin: 0 5px; }
          .instagram-grid-wrap .item-grid.grid-style--1 .item-info .inner a i {
            padding-right: 4px; }
    .instagram-grid-wrap .item-grid.grid-style--1:hover .thumb a img {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1); }
    .instagram-grid-wrap .item-grid.grid-style--1:hover .item-info {
      visibility: visible;
      opacity: 1; }

/* ==========================
    Popup Style
=============================*/
.brook-search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  cursor: url(/img/icons/light-close.png) 16 16, pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 99999; }
  .brook-search-popup .inner {
    background: #000000;
    position: relative;
    width: 400px;
    max-width: 80%;
    height: 100%;
    text-align: left;
    -webkit-transition: all .3s;
    transition: all .3s;
    overflow-y: auto;
    padding-top: 85px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    cursor: default; }
    .brook-search-popup .inner .search-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 0 15px 0 30px;
      height: 85px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background: #fff; }
      .brook-search-popup .inner .search-header .logo {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1; }
        .brook-search-popup .inner .search-header .logo a img {
          width: 115px;
          height: auto;
          max-width: 100%; }
      .brook-search-popup .inner .search-header a.search-close {
        position: relative;
        cursor: pointer;
        height: 40px;
        width: 40px;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        .brook-search-popup .inner .search-header a.search-close::before, .brook-search-popup .inner .search-header a.search-close::after {
          position: absolute;
          top: 19px;
          left: 8px;
          content: "";
          width: 24px;
          height: 3px;
          background: #222;
          -webkit-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .brook-search-popup .inner .search-header a.search-close::before {
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        .brook-search-popup .inner .search-header a.search-close::after {
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg); }
        .brook-search-popup .inner .search-header a.search-close:hover::before, .brook-search-popup .inner .search-header a.search-close:hover::after {
          -webkit-transform: none;
          -ms-transform: none;
          transform: none; }
    .brook-search-popup .inner .search-content {
      padding: 30px 40px; }
      .brook-search-popup .inner .search-content form {
        position: relative; }
        .brook-search-popup .inner .search-content form label {
          color: #fff;
          width: 100%; }
          .brook-search-popup .inner .search-content form label input {
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            padding: 15px 30px 15px 0;
            color: inherit;
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: #fff;
            width: 100%;
            height: 62px; }
            .brook-search-popup .inner .search-content form label input[placeholder]:focus::-webkit-input-placeholder {
              transition: opacity 0.3s 0.3s ease;
              opacity: 0; }
        .brook-search-popup .inner .search-content form button.search-submit {
          position: absolute;
          top: 0;
          right: 0;
          background: transparent;
          border: 0;
          padding: 0;
          height: 62px;
          line-height: 62px;
          color: #fff;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
          box-shadow: none;
          font-size: 15px; }

.page-search-popup-opened .brook-search-popup {
  visibility: visible;
  opacity: 1; }
  .page-search-popup-opened .brook-search-popup .inner {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

/* ===========================
    Shopping Cart Area 
==============================*/
.mini-cart {
  position: relative; }
  @media only screen and (max-width: 575px) {
    .mini-cart {
      order: 2; } }
  .mini-cart .shopping-cart {
    position: absolute;
    top: 100%;
    z-index: 99;
    right: 0;
    display: none;
    overflow: auto;
    padding: 30px 20px 27px;
    max-height: 700px;
    width: 360px;
    border-bottom: 2px solid #0038E3;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12); }
    @media only screen and (max-width: 479px) {
      .mini-cart .shopping-cart {
        width: 207px;
        padding: 30px 17px 27px; } }
    @media only screen and (max-width: 575px) {
      .mini-cart .shopping-cart {
        width: 250px; } }
    .mini-cart .shopping-cart .shop-inner ul.product-list {
      list-style: none outside;
      padding: 0;
      margin: 0;
      max-height: 340px;
      overflow-y: auto; }
      .mini-cart .shopping-cart .shop-inner ul.product-list li {
        display: flex; }
        .mini-cart .shopping-cart .shop-inner ul.product-list li .thumb {
          margin-right: 15px;
          flex-grow: 0; }
          @media only screen and (max-width: 575px) {
            .mini-cart .shopping-cart .shop-inner ul.product-list li .thumb {
              margin-right: 8px; } }
          .mini-cart .shopping-cart .shop-inner ul.product-list li .thumb a img {
            width: 80px; }
        .mini-cart .shopping-cart .shop-inner ul.product-list li .content {
          position: relative;
          flex-grow: 1;
          padding-right: 20px; }
          .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
            margin-bottom: 0px;
            line-height: 20px; }
            .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a {
              font-size: 16px;
              font-weight: 600;
              color: #333333;
              -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
              .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
                color: #0038E3; }
              @media only screen and (max-width: 575px) {
                .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a {
                  font-size: 14px; } }
          .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner .quatity span {
            display: inline-block;
            font-size: 15px;
            font-weight: 600;
            color: #333333; }
          .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn {
            position: absolute;
            right: 0;
            top: 4px;
            font-size: 18px;
            border: 0 none; }
            .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
              color: #0038E3; }
        .mini-cart .shopping-cart .shop-inner ul.product-list li + li {
          margin-top: 20px; }
    .mini-cart .shopping-cart .footer {
      margin-top: 30px;
      padding-top: 22px;
      border-top: 1px solid #eee; }
      .mini-cart .shopping-cart .footer .total {
        width: 100%;
        font-size: 15px;
        margin-bottom: 10px;
        font-weight: 700;
        color: #333333; }
        .mini-cart .shopping-cart .footer .total span {
          font-weight: 600;
          color: #333333;
          padding-left: 3px; }
      .mini-cart .shopping-cart .footer a.cart-btn {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        border-color: #0038E3;
        background-color: #0038E3;
        font-family: "Poppins", sans-serif;
        height: 55px;
        line-height: 53px;
        outline: none;
        border-width: 1px;
        border-style: solid;
        border-radius: 6px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
        min-width: 176px;
        text-align: center;
        display: inline-block; }
        .mini-cart .shopping-cart .footer a.cart-btn:hover {
          -webkit-transform: translateY(-3px);
          -ms-transform: translateY(-3px);
          transform: translateY(-3px); }

/* ========================
    Mega Menu 
==========================*/
.header-mega-menu .row,
.header-mega-menu [class*="col-"] {
  position: static; }

.mainmenu li.with--drop {
  position: relative; }
  .mainmenu li.with--drop.slide--megamenu {
    position: static; }
    .mainmenu li.with--drop.slide--megamenu:hover .mega__width--fullscreen {
      display: block;
      -webkit-animation: show-animation .5s;
      animation: show-animation .5s; }
  .mainmenu li.with--drop.slide-dropdown {
    position: relative; }
    .mainmenu li.with--drop.slide-dropdown:hover .dropdown__menu {
      display: block;
      -webkit-animation: show-animation .5s;
      animation: show-animation .5s;
      top: 100%; }

/* -------------------------
Start Dropdown Menu 
--------------------------*/
.dropdown__menu {
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
  position: absolute;
  list-style: none;
  background-color: #222222;
  -webkit-animation: hide-animation .5s;
  animation: hide-animation .5s;
  left: 0px;
  display: none;
  width: 300px;
  margin: 0;
  padding: 35px 0 50px;
  min-width: 300px; }
  .dropdown__menu li {
    padding: 0 40px; }
    .dropdown__menu li a {
      display: block; }
      .dropdown__menu li a span {
        position: relative;
        padding: 2px 0;
        overflow: hidden;
        z-index: 1;
        font-weight: 500;
        line-height: 1.58;
        color: #ffffff;
        font-size: 14px;
        -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        display: inline-block;
        margin: 6px 0; }
        .dropdown__menu li a span::after {
          content: "";
          width: 0;
          height: 1px;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background: currentColor; }
        .dropdown__menu li a span:hover::after {
          width: 100%;
          left: 0;
          right: auto; }
        .dropdown__menu li a span:hover {
          color: #ffffff; }
    .dropdown__menu li.drop-lavel--2 {
      position: relative; }
      .dropdown__menu li.drop-lavel--2 > a {
        position: relative; }
        .dropdown__menu li.drop-lavel--2 > a::before {
          content: "\f105";
          font-size: 14px;
          position: absolute;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-family: Font Awesome\ 5 Free;
          font-weight: 900;
          background: transparent !important; }
      .dropdown__menu li.drop-lavel--2 .lavel--2 {
        position: absolute;
        list-style: none;
        display: none;
        padding: 30px 0 50px;
        margin: 0;
        background: #222222;
        width: 300px;
        -webkit-animation: slide-out-to-right .3s;
        animation: slide-out-to-right .3s;
        left: 100%;
        top: 0; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .dropdown__menu li.drop-lavel--2 .lavel--2 {
            width: 230px; } }
      .dropdown__menu li.drop-lavel--2:hover > .lavel--2 {
        display: block;
        -webkit-animation: slide-in-to-left .3s;
        animation: slide-in-to-left .3s; }

/* For Menu Style */
.mega__width--fullscreen {
  width: auto;
  display: none;
  top: 100%;
  left: 0px;
  margin-left: -643.563px;
  margin-top: 0px;
  min-width: 10em;
  max-width: 20em;
  right: 0;
  margin-left: 0;
  max-width: none;
  padding: 70px 0;
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
  position: absolute;
  list-style: none;
  background-color: #222222;
  -webkit-animation: hide-animation .5s;
  animation: hide-animation .5s; }
  .mega__width--fullscreen .mega__list {
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none; }
    .mega__width--fullscreen .mega__list li.mega--title {
      display: block;
      margin: 0 30px 18px 0;
      padding-bottom: 12px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      border: 0 solid transparent;
      border-bottom: 1px solid #444;
      color: #ffffff;
      font-family: "Poppins", sans-serif; }
    .mega__width--fullscreen .mega__list li a span {
      position: relative;
      padding: 2px 0;
      overflow: hidden;
      z-index: 1;
      font-weight: 500;
      line-height: 1.58;
      color: #fff;
      font-size: 14px;
      -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      display: inline-block;
      margin: 6px 0; }
      .mega__width--fullscreen .mega__list li a span::after {
        content: "";
        width: 0;
        height: 1px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        z-index: -1;
        -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        background: currentColor; }
      .mega__width--fullscreen .mega__list li a span:hover::after {
        width: 100%;
        left: 0;
        right: auto; }
      .mega__width--fullscreen .mega__list li a span:hover {
        color: #ffffff; }

/* ====================
    Toolbar Area 
========================*/
.brook__toolbar .inner {
  position: absolute;
  top: 200px;
  right: 100%;
  display: block;
  width: 40px;
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #fff;
  text-align: center;
  box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1); }
  .brook__toolbar .inner a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #222;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .brook__toolbar .inner a i {
      font-size: 16px; }
    .brook__toolbar .inner a[class*=hint--] {
      position: relative;
      display: inline-block;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .brook__toolbar .inner a[class*=hint--]::before, .brook__toolbar .inner a[class*=hint--]::after {
        position: absolute;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        visibility: hidden;
        opacity: 0;
        z-index: 1000000;
        pointer-events: none;
        -webkit-transition: .3s ease;
        transition: .3s ease;
        -webkit-transition-delay: 0ms;
        transition-delay: 0ms; }
      .brook__toolbar .inner a[class*=hint--]::before {
        content: "";
        position: absolute;
        background: transparent;
        border: 7px solid transparent;
        z-index: 1000001;
        right: 100%;
        bottom: 50%;
        margin-right: -13px;
        margin-bottom: -7px;
        border-left-color: #000; }
      .brook__toolbar .inner a[class*=hint--]::after {
        content: attr(aria-label);
        background: #000;
        color: #fff;
        padding: 8px 10px;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
        border-radius: 5px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); }
    .brook__toolbar .inner a.hint--bounce {
      -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
      transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }
    .brook__toolbar .inner a.hint--left::after {
      margin-bottom: -15px;
      right: 100%;
      bottom: 50%; }
    .brook__toolbar .inner a:hover {
      color: #0038E3; }
      .brook__toolbar .inner a:hover[class*=hint--]::before, .brook__toolbar .inner a:hover[class*=hint--]::after {
        visibility: visible;
        opacity: 1;
        -webkit-transition-delay: .1s;
        transition-delay: .1s; }
      .brook__toolbar .inner a:hover[class*=hint--]::before {
        -webkit-transform: translateX(-8px);
        -ms-transform: translateX(-8px);
        transform: translateX(-8px); }
      .brook__toolbar .inner a:hover[class*=hint--]::after {
        -webkit-transform: translateX(-8px);
        -ms-transform: translateX(-8px);
        transform: translateX(-8px); }

/* ==========================
Typography Area 
===============================*/
.brook-heading .spacing {
  height: 40px; }

.heading {
  color: #222222;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.23;
  margin: 0; }
  .heading.letter-spacing-3 {
    letter-spacing: 3px; }
  .heading.heading-font {
    font-family: "louis_george_caferegular"; }
  .heading.bk-hover a {
    color: #222222; }
  .heading.playfair-font {
    font-family: "Playfair Display", serif; }
  .heading.secondary-font {
    font-family: "Montserrat", sans-serif; }
  .heading.roboto-font {
    font-family: "Roboto", sans-serif; }
  .heading.font-reenie {
    font-family: 'Reenie Beanie', cursive; }
  .heading.font-blod {
    font-weight: 700; }
  .heading.font-medium {
    font-weight: 500; }
  .heading.font-400 {
    font-weight: 400; }
  .heading.text-white {
    color: #ffffff; }
  .heading.theme-color {
    color: #0038E3; }
  .heading.body-color {
    color: #333333; }
  .heading.yellow-color-2 {
    color: #F8A440; }
  .heading.pink {
    color: #F12C6E; }
  .heading.brown-color {
    color: #CE8F4F; }
  .heading.third-color {
    color: #99E5E8; }
  .heading.red-color-2 {
    color: #F23E20; }
  .heading.lineheight-1-58 {
    line-height: 1.58; }
  .heading.lineheight-1-44 {
    line-height: 1.44; }
  .heading.line-height-1-42 {
    line-height: 1.42; }
  .heading.line-height-1-62 {
    line-height: 1.62; }
  .heading.line-height-1-95 {
    line-height: 1.95; }
  .heading.line-height-1 {
    line-height: 1; }
  .heading.line-height-1-5 {
    line-height: 1.5; }
  .heading.line-height-1-2 {
    line-height: 1.2; }
  .heading.line-height-1-25 {
    line-height: 1.25; }
  .heading.line-height-1-88 {
    line-height: 1.88; }
  .heading.line-height-1-39 {
    line-height: 1.39; }

.heading-13 {
  font-size: 13px;
  margin-bottom: 0; }

.heading-40 {
  font-size: 40px;
  line-height: 1.28;
  margin-bottom: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .heading-40 {
      font-size: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .heading-40 {
      font-size: 28px; } }
  @media only screen and (max-width: 767px) {
    .heading-40 {
      font-size: 28px; } }

h1.heading-h1 {
  font-size: 56px; }
  @media only screen and (max-width: 767px) {
    h1.heading-h1 {
      font-size: 48px; }
      h1.heading-h1 br {
        display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h1.heading-h1 {
      font-size: 40px; } }
  @media only screen and (max-width: 767px) {
    h1.heading-h1 {
      font-size: 32px; } }
  @media only screen and (max-width: 479px) {
    h1.heading-h1 {
      font-size: 36px; } }
  h1.heading-h1.large-font-1 {
    font-size: 79px;
    line-height: 159px;
    font-weight: 600; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      h1.heading-h1.large-font-1 {
        font-size: 57px;
        line-height: 105px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      h1.heading-h1.large-font-1 {
        font-size: 49px;
        line-height: 109px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      h1.heading-h1.large-font-1 {
        font-size: 46px;
        line-height: 100px; } }
    @media only screen and (max-width: 767px) {
      h1.heading-h1.large-font-1 {
        font-size: 37px;
        line-height: 71px; } }
    @media only screen and (max-width: 575px) {
      h1.heading-h1.large-font-1 {
        font-size: 30px;
        line-height: 48px; } }
  h1.heading-h1.font-80 {
    font-size: 80px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      h1.heading-h1.font-80 {
        font-size: 60px !important; }
        h1.heading-h1.font-80 br {
          display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      h1.heading-h1.font-80 {
        font-size: 40px; }
        h1.heading-h1.font-80 br {
          display: none; } }
    @media only screen and (max-width: 767px) {
      h1.heading-h1.font-80 {
        font-size: 45px; }
        h1.heading-h1.font-80 br {
          display: none; } }
    @media only screen and (max-width: 479px) {
      h1.heading-h1.font-80 {
        font-size: 36px; } }
  h1.heading-h1.font-90 {
    font-size: 90px; }
    @media only screen and (max-width: 767px) {
      h1.heading-h1.font-90 {
        font-size: 48px; } }
    @media only screen and (max-width: 479px) {
      h1.heading-h1.font-90 {
        font-size: 36px; } }
  h1.heading-h1.font-60 {
    font-size: 60px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      h1.heading-h1.font-60 {
        font-size: 45px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      h1.heading-h1.font-60 {
        font-size: 40px; } }
    @media only screen and (max-width: 767px) {
      h1.heading-h1.font-60 {
        font-size: 36px; } }
    @media only screen and (max-width: 479px) {
      h1.heading-h1.font-60 {
        font-size: 30px; } }
  h1.heading-h1.large-font-2 {
    line-height: 1.4;
    font-size: 100px;
    font-weight: 600; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      h1.heading-h1.large-font-2 {
        font-size: 80px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      h1.heading-h1.large-font-2 {
        font-size: 70px; } }
    @media only screen and (max-width: 767px) {
      h1.heading-h1.large-font-2 {
        font-size: 50px; } }

h2.heading-h2 {
  font-size: 48px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    h2.heading-h2 br {
      display: none; } }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    h2.heading-h2 {
      font-size: 36px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h2.heading-h2 {
      font-size: 36px; } }
  @media only screen and (max-width: 767px) {
    h2.heading-h2 {
      font-size: 32px; }
      h2.heading-h2 br {
        display: none; } }
  @media only screen and (max-width: 479px) {
    h2.heading-h2 {
      font-size: 32px; } }
  h2.heading-h2.font-50 {
    font-size: 50px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      h2.heading-h2.font-50 {
        font-size: 37px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      h2.heading-h2.font-50 {
        font-size: 37px; } }
    @media only screen and (max-width: 767px) {
      h2.heading-h2.font-50 {
        font-size: 32px; }
        h2.heading-h2.font-50 br {
          display: none; } }
    @media only screen and (max-width: 479px) {
      h2.heading-h2.font-50 {
        font-size: 32px; } }

h3.heading-h3 {
  font-size: 36px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    h3.heading-h3 {
      font-size: 32px; } }
  @media only screen and (max-width: 767px) {
    h3.heading-h3 {
      font-size: 22px; } }
  h3.heading-h3.font-large {
    font-size: 40px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      h3.heading-h3.font-large {
        font-size: 32px; } }
    @media only screen and (max-width: 767px) {
      h3.heading-h3.font-large {
        font-size: 30px; } }
  h3.heading-h3.font-32 {
    font-size: 32px; }
    @media only screen and (max-width: 767px) {
      h3.heading-h3.font-32 {
        font-size: 30px; } }
  h3.heading-h3.font-40 {
    font-size: 40px; }
    @media only screen and (max-width: 767px) {
      h3.heading-h3.font-40 {
        font-size: 30px; } }

h4.heading-h4 {
  font-size: 24px; }
  @media only screen and (max-width: 767px) {
    h4.heading-h4 {
      font-size: 18px; } }

h5.heading-h5 {
  font-size: 18px; }
  @media only screen and (max-width: 767px) {
    h5.heading-h5 {
      font-size: 16px; }
      h5.heading-h5 br {
        display: none; } }
  h5.heading-h5.font-19 {
    font-size: 19px; }
  h5.heading-h5.font-20 {
    font-size: 20px; }

h6.heading-h6 {
  font-size: 14px; }
  h6.heading-h6.font-17 {
    font-size: 17px; }
  h6.heading-h6.font-16 {
    font-size: 16px; }
  h6.heading-h6.font-15 {
    font-size: 15px; }

.brook-paragraph .spacing {
  height: 40px; }

p.bk_pra {
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 100%;
  font-weight: 500;
  line-height: 1.58;
  font-size: 14px;
  font-family: "Poppins", sans-serif; }
  @media only screen and (max-width: 767px) {
    p.bk_pra br {
      display: none; } }
  p.bk_pra.heading-font {
    font-family: "louis_george_caferegular";
    line-height: 1.32;
    font-size: 16px;
    color: #333333; }
    @media only screen and (max-width: 767px) {
      p.bk_pra.heading-font {
        font-size: 14px; } }
  p.bk_pra.text-white {
    color: #ffffff; }
  p.bk_pra.font-16 {
    font-size: 16px; }
    @media only screen and (max-width: 767px) {
      p.bk_pra.font-16 {
        font-size: 14px; } }
  p.bk_pra.font-20 {
    font-size: 20px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      p.bk_pra.font-20 {
        font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      p.bk_pra.font-20 {
        font-size: 14px; } }
  p.bk_pra.font-18 {
    font-size: 18px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      p.bk_pra.font-18 {
        font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      p.bk_pra.font-18 {
        font-size: 14px; } }
  p.bk_pra.secondary-font {
    font-family: "Montserrat", sans-serif; }
  p.bk_pra.heading-font2 {
    font-family: "louis_george_caferegular"; }
  p.bk_pra.font-blod {
    font-weight: 700; }
  p.bk_pra.line-height-1-63 {
    line-height: 1.63; }
  p.bk_pra.line-height-1-6 {
    line-height: 1.6; }
  p.bk_pra.line-height-1-87 {
    line-height: 1.875; }
  p.bk_pra.line-height-2-22 {
    line-height: 2.22; }

.dropcaps .spacing {
  height: 102px; }
  @media only screen and (max-width: 767px) {
    .dropcaps .spacing {
      height: 66px; } }

.dropcaps p {
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.58;
  font-size: 14px;
  color: #333333;
  margin: 0; }
  .dropcaps p span {
    float: left;
    font-weight: 600;
    font-size: 48px;
    line-height: 33px;
    padding-right: 10px;
    color: #222222; }

.bk-text-box .spacing {
  height: 27px; }

.bk-text-box.box-bg-solid {
  background: #eee; }

.bk-text-box.box-solid-border {
  border: 1px solid #ddd; }

.bk-text-box.text-box--2 {
  padding: 50px 40px 61px; }

.bk-text-box.bg-theme-color {
  background: #0038E3; }
  .bk-text-box.bg-theme-color .heading {
    color: #ffffff; }
  .bk-text-box.bg-theme-color p.bk_pra {
    color: #ffffff; }

.bk-text-box.bg-dark-color {
  background: #222; }
  .bk-text-box.bg-dark-color .heading {
    color: #ffffff; }
  .bk-text-box.bg-dark-color p.bk_pra {
    color: #ffffff; }

.bk-quote-content .spacing {
  height: 30px; }

.brook-quote {
  position: relative;
  margin: 37px 0 44px !important;
  padding: 0 0 0 53px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.625; }
  .brook-quote::before {
    content: "\f10e";
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    color: #0038E3; }
  .brook-quote .quote-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.625;
    color: #222222;
    font-family: "Poppins", sans-serif;
    font-style: normal; }

/*==========================
List Area 
===========================*/
.bk-list .list-header {
  padding-left: 36px; }
  .bk-list .list-header .marker {
    display: inline-block;
    float: left;
    position: relative; }
    .bk-list .list-header .marker::before {
      content: "\f058";
      position: absolute;
      top: 0;
      left: -36px;
      display: block;
      font-size: 18px;
      color: #7ed321;
      font-family: Font Awesome\ 5 Free;
      font-weight: 900; }
  .bk-list .list-header p.bk_pra {
    margin-top: 13px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.58;
    font-size: 14px; }

.bk-list--2 .list-header {
  padding-left: 14px;
  margin-bottom: 20px; }
  .bk-list--2 .list-header .marker {
    display: inline-block;
    float: left;
    position: relative; }
    .bk-list--2 .list-header .marker::before {
      content: "";
      position: absolute;
      top: 6px;
      left: -14px; }
    .bk-list--2 .list-header .marker.with-dot::before {
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #999; }
  .bk-list--2 .list-header .title-wrap h6.heading {
    color: inherit;
    font-weight: 500; }
  .bk-list--2 .list-header.with-ckeck {
    padding-left: 26px; }
    .bk-list--2 .list-header.with-ckeck .marker::before {
      content: "\f00c";
      display: block;
      font-size: 13px;
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      color: #0038E3;
      top: -4px;
      left: -26px; }
    .bk-list--2 .list-header.with-ckeck .marker.dark-color::before {
      color: #222222 !important; }
    .bk-list--2 .list-header.with-ckeck.item-available .marker::before {
      color: #7ed321; }
    .bk-list--2 .list-header.with-ckeck.item-not-available .marker::before {
      color: #333333; }
    .bk-list--2 .list-header.with-ckeck.check-yellow-color-2 .marker::before {
      color: #F8A440; }
  .bk-list--2 .list-header.with-number {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px; }
    .bk-list--2 .list-header.with-number .marker {
      position: absolute;
      left: 0;
      top: -3px; }
    .bk-list--2 .list-header.with-number--theme {
      padding-left: 30px;
      margin-bottom: 20px; }
      .bk-list--2 .list-header.with-number--theme .marker {
        color: #0038E3;
        font-size: 15px;
        font-weight: 700; }
      .bk-list--2 .list-header.with-number--theme h6.heading {
        font-size: 15px;
        line-height: 1.2;
        color: #222222; }
    .bk-list--2 .list-header.with-number--body {
      padding-left: 30px;
      margin-bottom: 3px; }
      .bk-list--2 .list-header.with-number--body .marker {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 1.38;
        font-family: "louis_george_caferegular"; }
      .bk-list--2 .list-header.with-number--body h6.heading {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 1.38;
        color: #333333;
        font-family: "louis_george_caferegular"; }

/* =====================
Pricing Box 
========================*/
.procing-wrap {
  margin-left: auto;
  margin-right: auto; }

.procing-box {
  padding: 62px 40px 60px;
  position: relative;
  background-color: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 100%;
  margin-bottom: 0; }
  .procing-box.active {
    padding: 106px 40px 80px; }
  .procing-box .header {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 38px; }
    .procing-box .header .feature-mark {
      background-color: #0038E3;
      display: block;
      padding: 5px 18px 5px 10px;
      position: absolute;
      top: 44px;
      left: -4px;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 1.4;
      font-weight: 600;
      color: #fff; }
      .procing-box .header .feature-mark::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-right: 9px solid #fff;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent; }
    .procing-box .header .price {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; }
      .procing-box .header .price h3.currenct {
        font-size: 18px;
        margin-bottom: 0; }
      .procing-box .header .price h3.heading {
        font-size: 48px;
        line-height: .9;
        margin: 0 4px;
        font-weight: 600; }
  .procing-box .content h5.heading {
    margin-bottom: 21px; }

.price-style-3 .procing-box {
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
  padding: 25px 35px 37px;
  transition: all 0.3s ease-in-out; }
  .price-style-3 .procing-box .header {
    padding-bottom: 33px;
    border-bottom: 1px solid #eee;
    margin-bottom: 23px; }
    .price-style-3 .procing-box .header .price-area .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .price-style-3 .procing-box .header .price-area .header-title .pricing-feature-mark {
        order: 2;
        display: block;
        padding: 3px 10px;
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        background: #efefef;
        line-height: 1.75;
        margin-bottom: 15px; }
      .price-style-3 .procing-box .header .price-area .header-title .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.34;
        margin-bottom: 15px;
        order: 1; }
    .price-style-3 .procing-box .header .price-area .price-wrap h3 {
      font-size: 40px;
      line-height: 1.275;
      margin: 0;
      font-weight: 600; }
    .price-style-3 .procing-box .header .price-area .period {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.67;
      color: inherit;
      width: 100%;
      margin: 10px 0 0; }

.price-style-3:hover .procing-box {
  transform: translateY(-5px); }

.more-pricing {
  text-align: center; }
  .more-pricing p {
    font-size: 16px;
    line-height: 1.75;
    color: #777; }
    .more-pricing p a {
      color: #68AE4A;
      position: relative;
      padding: 2px 0;
      overflow: hidden;
      z-index: 1; }
      .more-pricing p a::after {
        content: "";
        position: absolute;
        height: 1px;
        bottom: 0;
        width: 100%;
        right: 0;
        left: auto;
        z-index: -2;
        background: #ddd;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s; }
      .more-pricing p a::before {
        content: "";
        width: 0;
        height: 1px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        z-index: -1;
        -webkit-transition: width 1s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        transition: width 1s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        background: currentColor; }
      .more-pricing p a:hover::before {
        width: 100%;
        left: 0;
        right: auto; }

/* =====================
    Icon Boxes 
========================*/
.black-bg {
  background-color: #000000 !important; }

.black-bg-two {
  background-color: #121618 !important; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brook-icon-boxes-area .space_dec--110 {
    margin-top: 0;
    padding-top: 80px; } }

@media only screen and (max-width: 767px) {
  .brook-icon-boxes-area .space_dec--110 {
    margin-top: 0;
    padding-top: 80px; } }

.icon-box.bg-transparant .inner {
  background: transparent; }

.icon-box .inner {
  position: relative;
  padding: 60px 35px 64px;
  background: #fff;
  z-index: 2;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .icon-box .inner::before {
    position: absolute;
    content: "";
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 1px solid #eee;
    opacity: 1;
    visibility: visible;
    z-index: -1; }
  .icon-box .inner .icon {
    font-size: 80px;
    margin-bottom: 22px;
    color: #0038E3; }
    .icon-box .inner .icon.brown-color {
      color: #CE8F4F; }
  .icon-box .inner .svg-icon .tm-svg {
    padding: 10px 0 12px;
    width: 60px;
    margin: 0 auto; }
  .icon-box .inner .content h5.heading {
    margin-bottom: 18px; }
  .icon-box .inner .content a.icon-btn {
    margin-top: 46px;
    display: inline-block;
    color: #222222;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .icon-box .inner .content a.icon-btn span {
      padding: 5px 0;
      font-weight: 600;
      font-size: 14px; }
    .icon-box .inner .content a.icon-btn i {
      font-size: 12px;
      color: #0038E3;
      padding-left: 14px; }
    .icon-box .inner .content a.icon-btn:hover {
      color: #0038E3; }
    .icon-box .inner .content a.icon-btn.color-red i {
      color: #F0263F; }
    .icon-box .inner .content a.icon-btn.color-red:hover {
      color: #F0263F; }
  .icon-box .inner:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1); }

.icon-box.no-border .inner {
  padding: 0; }
  .icon-box.no-border .inner::before {
    display: none; }
  .icon-box.no-border .inner:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: inherit; }

.icon-box.with-padding .inner {
  padding: 60px 35px 64px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .icon-box.with-padding .inner {
      padding: 14px 21px 21px; } }
  @media only screen and (max-width: 767px) {
    .icon-box.with-padding .inner {
      padding: 10px 8px 32px; } }

.icon-box.icon-box-style-2 .icon {
  flex: 0 0 74px;
  width: 74px;
  height: 74px;
  margin-bottom: 35px;
  margin-right: 35px; }

.icon-box.icon-box-style-2 .content h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 15px; }

/* =====================
    Icon Box Style 
========================*/
.icon-grid-box {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  width: 760px;
  display: -ms-grid;
  display: grid;
  margin: auto;
  margin-top: 60px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .icon-grid-box {
      width: auto; } }
  @media only screen and (max-width: 767px) {
    .icon-grid-box {
      width: auto;
      grid-row-gap: 30px; } }
  @media only screen and (max-width: 575px) {
    .icon-grid-box {
      grid-template-columns: repeat(1, 1fr); } }
  .icon-grid-box .bk-icon {
    max-width: 160px; }
    @media only screen and (max-width: 575px) {
      .icon-grid-box .bk-icon {
        max-width: inherit; } }
    .icon-grid-box .bk-icon .icon {
      font-size: 80px;
      width: 160px;
      height: 160px;
      display: inline-block;
      border-radius: 50%;
      color: #fff;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .icon-grid-box .bk-icon .icon {
          width: 110px;
          height: 110px;
          font-size: 50px; } }
      .icon-grid-box .bk-icon .icon.bg-color-1 {
        background: #e71361; }
      .icon-grid-box .bk-icon .icon.bg-color-2 {
        background: #ecb505; }
      .icon-grid-box .bk-icon .icon.bg-color-3 {
        background: #116cd7; }
      .icon-grid-box .bk-icon .icon i {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }

.vibrate-style .icon i {
  font-size: 30px; }

.vibrate-style .content h5 {
  font-size: 20px;
  font-weight: 500; }

.vibrate-style .content p {
  font-size: 16px;
  line-height: 1.75;
  color: #777;
  font-weight: 400; }

.vibrate-style:hover .inner {
  transform: translateY(0px); }

.single-svg-icon-box {
  position: relative;
  height: 100%;
  padding: 37px 30px 34px;
  background: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
  z-index: 9; }
  .single-svg-icon-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out; }
  .single-svg-icon-box .grid-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #222;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #000000;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .single-svg-icon-box .inner {
    position: relative;
    z-index: 5; }
  .single-svg-icon-box .svg-icon {
    width: 60px;
    margin-bottom: 22px; }
    .single-svg-icon-box .svg-icon svg * {
      stroke: #68AE4A; }
  .single-svg-icon-box .content h5 {
    font-size: 20px;
    margin-bottom: 18px;
    font-weight: 500; }
  .single-svg-icon-box:hover {
    background: none; }
    .single-svg-icon-box:hover::before {
      opacity: 0.5; }
    .single-svg-icon-box:hover .grid-overlay {
      opacity: 1;
      visibility: visible; }
    .single-svg-icon-box:hover .svg-icon svg * {
      stroke: #fff; }
    .single-svg-icon-box:hover .content h5 {
      color: #fff; }
    .single-svg-icon-box:hover .content p {
      color: #fff; }
  .single-svg-icon-box.business-icon-box {
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .single-svg-icon-box.business-icon-box .grid-overlay {
      opacity: 1;
      visibility: visible; }
    .single-svg-icon-box.business-icon-box .svg-icon {
      text-align: center;
      margin: 0 auto;
      margin-bottom: 22px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .single-svg-icon-box.business-icon-box .svg-icon svg * {
        stroke: #fff !important; }
    .single-svg-icon-box.business-icon-box .content {
      position: relative;
      z-index: 1; }
      .single-svg-icon-box.business-icon-box .content h5 {
        color: #fff;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .single-svg-icon-box.business-icon-box .content p {
        color: #fff;
        font-family: "CerebriSans";
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .single-svg-icon-box.business-icon-box .br-icon-box-btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      color: #fff;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1px;
      margin: 0;
      padding-right: 0;
      display: block;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .single-svg-icon-box.business-icon-box .br-icon-box-btn a {
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 5px 0;
        display: block; }
    .single-svg-icon-box.business-icon-box:hover {
      background-image: none;
      background: none;
      box-shadow: 0 0 30px -15px rgba(51, 51, 51, 0.25);
      background: #c75353; }
      .single-svg-icon-box.business-icon-box:hover::before {
        display: none; }
      .single-svg-icon-box.business-icon-box:hover .grid-overlay {
        opacity: 0;
        visibility: hidden; }
      .single-svg-icon-box.business-icon-box:hover .svg-icon {
        transform: translateY(-50px);
        opacity: 0;
        visibility: hidden; }
      .single-svg-icon-box.business-icon-box:hover .content h5 {
        transform: translateY(-50px);
        opacity: 0;
        visibility: hidden; }
      .single-svg-icon-box.business-icon-box:hover .content p {
        transform: translateY(-75px); }
      .single-svg-icon-box.business-icon-box:hover .br-icon-box-btn {
        transform: translate(-50%, 15px);
        opacity: 1;
        visibility: visible; }

/* ========================
Dividers Area 
==========================*/
.dividers-inner .space {
  height: 90px; }

.basic-separator {
  display: inline-block;
  height: 2px;
  width: 120px;
  background: #222; }
  .basic-separator.line-3 {
    height: 3px; }

.basic-thine-line {
  border-bottom: 1px solid #ddd; }

.basic-dark-line-1px {
  border-bottom: 1px solid #333; }

.basic-dark2-line-1px {
  border-bottom: 1px solid #eeeeee; }

.basic-thick-line {
  border-bottom: 2px solid #ddd; }

.basic-dark-line {
  border-bottom: 2px solid #222; }

.basic-thick-line-theme-4 {
  border-top: 4px solid #0038E3; }

.basic-dash-line {
  border-bottom: 1px dashed #ddd; }

.basic-short-line {
  width: 120px;
  background: #222;
  height: 3px;
  display: inline-block; }

.basic-modern-dots {
  display: inline-block;
  padding: 3px 10px;
  cursor: pointer; }
  .basic-modern-dots .dot {
    background-color: #0038E3;
    border-radius: 50%;
    box-shadow: 0 0 12px #def0ff;
    -webkit-animation: separator-bounce 1.4s ease-in-out 0s infinite both;
    animation: separator-bounce 1.4s ease-in-out 0s infinite both;
    width: 14px;
    height: 14px; }
    .basic-modern-dots .dot.second-circle {
      margin-top: 18px;
      -webkit-animation-delay: -.16s;
      animation-delay: -.16s; }
    .basic-modern-dots .dot.third-circle {
      margin-top: 14px;
      -webkit-animation-delay: -.32s;
      animation-delay: -.32s; }
  .basic-modern-dots.white-dots .dot {
    background-color: #ffffff;
    box-shadow: 0 0 12px #ffffff; }

/* =========================
    Message Box Area 
============================*/
.message-box {
  position: relative;
  padding: 37px 30px 37px 70px; }
  .message-box .icon {
    position: absolute;
    top: 40px;
    left: 41px;
    font-size: 16px;
    line-height: 1; }
  .message-box .content {
    font-weight: 500;
    line-height: 1.58;
    font-size: 14px;
    font-family: "Poppins", sans-serif; }
  .message-box.bg_cat--1 {
    background: #eee; }
    .message-box.bg_cat--1 .icon {
      color: #222; }
  .message-box.bg_cat--2 {
    background: #f9e2e5; }
    .message-box.bg_cat--2 .icon {
      color: #d0021b; }
    .message-box.bg_cat--2 .content {
      color: #d0021b; }
  .message-box.bg_cat--3 {
    background: #edf9e1; }
    .message-box.bg_cat--3 .icon {
      color: #6ca928; }
    .message-box.bg_cat--3 .content {
      color: #6ca928; }
  .message-box.bg_cat--4 {
    background: #0038E3; }
    .message-box.bg_cat--4 .icon {
      color: #ffffff; }
    .message-box.bg_cat--4 .content {
      color: #ffffff; }

/*============================
    Accordion Area 
==============================*/
.bk-accordion .card {
  border: 0 none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.bk-accordion .card-header {
  position: relative;
  background: transparent;
  padding: 0;
  border: 0 none; }
  .bk-accordion .card-header h5 a.acc-btn {
    color: #111;
    font-size: 18px;
    line-height: 1.2;
    padding: 0;
    font-weight: 600;
    border: 0 none;
    font-family: "Poppins", sans-serif;
    -webkit-appearance: none;
    display: block;
    background: #f7f7f7;
    padding: 20px 26px;
    margin: -1px -1px 0;
    border: 0 none;
    position: relative;
    border-radius: 5px 5px 0 0; }
    .bk-accordion .card-header h5 a.acc-btn::before {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      content: "\f107";
      right: 16px;
      font-size: 16px; }
    .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
      background: #0038E3;
      color: #ffffff; }
      .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"]::before {
        content: "\f104"; }

.bk-accordion .card-body {
  padding: 27px 25px 39px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.58;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border: 0 none;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0 0 5px 5px; }

/* Accordion Style 02 */
.bk-accordion-style--2 .card {
  border: 0 none;
  margin-bottom: 7px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.bk-accordion-style--2 .card-header {
  position: relative;
  background: transparent;
  padding: 0;
  border: 0 none; }
  .bk-accordion-style--2 .card-header h5 a.acc-btn {
    color: #111;
    padding: 0;
    font-weight: 600;
    border: 0 none;
    font-family: "Poppins", sans-serif;
    -webkit-appearance: none;
    display: block;
    position: relative;
    font-size: 18px;
    line-height: 1.2;
    padding: 14px 0 14px 26px; }
    .bk-accordion-style--2 .card-header h5 a.acc-btn.black-bg::before {
      color: #fff; }
    .bk-accordion-style--2 .card-header h5 a.acc-btn::before {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      content: "+";
      left: 0;
      font-size: 18px;
      color: #222222;
      font-weight: 700; }
    .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
      background: transparent;
      color: #0038E3; }
      .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
        content: "-";
        color: #0038E3; }

.bk-accordion-style--2 .card-body {
  padding: 5px 0 21px 26px;
  font-weight: 500;
  line-height: 1.58;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border: 0 none;
  box-shadow: inherit;
  border-radius: inherit; }

.bk-accordion-style--2.color-white .card-header h5 {
  background: #121618; }
  .bk-accordion-style--2.color-white .card-header h5 a.acc-btn {
    color: #fff; }
    .bk-accordion-style--2.color-white .card-header h5 a.acc-btn::before {
      color: #fff; }

.bk-accordion-style--2.color-white .card-body {
  background: #121618;
  color: #fff; }

.bk-accordion-style--2.border-style .card {
  border-top: 1px solid #9C9C9C;
  border-bottom: 1px solid #9C9C9C;
  border-radius: 0;
  margin-bottom: 45px; }

.bk-accordion-style--2.border-style .card-header h5 a.acc-btn {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding: 14px 0 14px 26px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bk-accordion-style--2.border-style .card-header h5 a.acc-btn {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bk-accordion-style--2.border-style .card-header h5 a.acc-btn {
      font-size: 18px; } }
  @media only screen and (max-width: 767px) {
    .bk-accordion-style--2.border-style .card-header h5 a.acc-btn {
      font-size: 18px; } }
  .bk-accordion-style--2.border-style .card-header h5 a.acc-btn::before {
    font-size: 24px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .bk-accordion-style--2.border-style .card-header h5 a.acc-btn::before {
        font-size: 18px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .bk-accordion-style--2.border-style .card-header h5 a.acc-btn::before {
        font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .bk-accordion-style--2.border-style .card-header h5 a.acc-btn::before {
        font-size: 18px; } }

.bk-accordion-style--2.border-style .card-body {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  font-family: "Roboto", sans-serif; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bk-accordion-style--2.border-style .card-body {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bk-accordion-style--2.border-style .card-body {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (max-width: 767px) {
    .bk-accordion-style--2.border-style .card-body {
      font-size: 14px;
      line-height: 22px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-content {
    margin-bottom: 50px; } }

@media only screen and (max-width: 767px) {
  .faq-content {
    margin-bottom: 50px; } }

.faq-content h2 {
  font-size: 50px;
  line-height: 65px;
  font-weight: 600;
  color: #0038E3;
  margin-bottom: 30px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .faq-content h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .faq-content h2 {
      font-size: 40px;
      line-height: 50px; } }
  @media only screen and (max-width: 767px) {
    .faq-content h2 {
      font-size: 30px;
      line-height: 40px; } }

.faq-content p {
  font-size: 18px;
  line-height: 34px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  max-width: 460px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .faq-content p {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .faq-content p {
      font-size: 16px;
      line-height: 28px; } }
  @media only screen and (max-width: 767px) {
    .faq-content p {
      font-size: 14px;
      line-height: 22px; } }

/* =====================
    Google Map Area 
========================*/
/*Google Map Area css*/
#htmap {
  width: 100%;
  height: 400px; }

.gmap-marker-wrap {
  padding: 20px; }

.gmap-marker-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px; }

.gmap-marker-content {
  font-size: 14px; }
  .gmap-marker-content i {
    font-size: 15px;
    margin-right: 5px;
    color: #0038E3; }

.gm-style .gm-style-iw {
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.12); }

.animated-dot {
  width: 100%;
  height: 100%;
  left: -9px;
  top: -5px;
  position: relative;
  background-color: #0038E3; }
  .animated-dot .middle-dot {
    width: 12px;
    height: 12px;
    border-radius: 30px;
    position: absolute;
    left: 2px;
    top: 2px;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    background-color: #0038E3; }
    .animated-dot .middle-dot::after {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      content: ' ';
      background-color: #fff;
      border-radius: 30px;
      z-index: 100;
      opacity: 0;
      animation: animationSignal2 cubic-bezier(0, 0.55, 0.55, 1) 2s;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;
      animation-delay: 0.78s;
      animation-iteration-count: infinite; }
  .animated-dot .signal {
    width: 180px;
    pointer-events: none;
    height: 180px;
    border-radius: 200px;
    position: absolute;
    left: -80px;
    top: -80px;
    opacity: 0;
    -webkit-animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
    animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    animation-delay: 0.78s;
    animation-iteration-count: infinite;
    background-color: #0038E3; }
  .animated-dot .signal2 {
    width: 180px;
    height: 180px;
    pointer-events: none;
    border-radius: 200px;
    position: absolute;
    left: -80px;
    top: -80px;
    opacity: 0;
    -webkit-animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
    animation: animationSignal cubic-bezier(0, 0.55, 0.55, 1) 2s;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    background-color: #0038E3; }
  .animated-dot.red-color {
    background-color: #F0263F; }
    .animated-dot.red-color .middle-dot {
      background-color: #F0263F; }
    .animated-dot.red-color .signal {
      background-color: #F0263F; }
    .animated-dot.red-color .signal2 {
      background-color: #F0263F; }
  .animated-dot.light-color {
    background-color: #fff !important; }
    .animated-dot.light-color .middle-dot {
      width: 15px;
      height: 15px;
      border: 2px solid #fff; }
      .animated-dot.light-color .middle-dot::after {
        animation: animationSignal2 cubic-bezier(0, 0.1, 0.1, 0.25) 2s;
        transform-origin: 50% 50%;
        animation-fill-mode: forwards;
        animation-delay: 0.78s;
        animation-iteration-count: infinite; }
    .animated-dot.light-color .signal {
      background-color: #fff !important;
      animation: animationSignalTwo cubic-bezier(0, 0.1, 0.1, 0.25) 2s;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;
      animation-delay: 0.78s;
      animation-iteration-count: infinite; }
    .animated-dot.light-color .signal2 {
      background-color: #fff !important;
      animation: animationSignalTwo cubic-bezier(0, 0.2, 0.2, 0.25) 2s;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;
      animation-delay: 1s;
      animation-iteration-count: infinite; }

@-webkit-keyframes animationSignal {
  0% {
    opacity: 0;
    transform: scale(0); }
  1% {
    opacity: 0.25; }
  20% {
    opacity: 0.25; }
  60% {
    transform: scale(1);
    opacity: 0; } }

@-webkit-keyframes animationSignal2 {
  0% {
    opacity: 0; }
  1% {
    opacity: 0.2; }
  20% {
    opacity: 0.17; }
  60% {
    opacity: 0; } }

@keyframes animationSignal {
  0% {
    opacity: 0;
    transform: scale(0); }
  1% {
    opacity: 0.25; }
  20% {
    opacity: 0.25; }
  60% {
    transform: scale(1);
    opacity: 0; } }

@keyframes animationSignalTwo {
  0% {
    opacity: 0;
    transform: scale(0); }
  1% {
    opacity: 0.25; }
  20% {
    opacity: 0.25; }
  60% {
    transform: scale(0.5);
    opacity: 0; } }

@keyframes animationSignal2 {
  0% {
    opacity: 0; }
  1% {
    opacity: 0.2; }
  20% {
    opacity: 0.17; }
  60% {
    opacity: 0; } }

/*============================
Row And Column
================================*/
.vc_row-separator {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  line-height: 0; }
  .vc_row-separator.tilt_right--bottom {
    bottom: 0;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1); }
  .vc_row-separator.tilt_right--top {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1); }
  .vc_row-separator.tilt_style--bottom {
    bottom: 0;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit; }
  .vc_row-separator.tilt_style--top {
    -webkit-transform: scale(-1);
    -ms-transform: scale(-1);
    transform: scale(-1);
    top: 0; }
  .vc_row-separator.triangle {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1); }
    .vc_row-separator.triangle svg {
      height: 40px;
      fill: #fff;
      width: 100%; }
    .vc_row-separator.triangle.triangle--top {
      -webkit-transform: inherit;
      -ms-transform: inherit;
      transform: inherit; }
      .vc_row-separator.triangle.triangle--top svg {
        height: 27px;
        fill: #fff;
        width: 100%; }
    .vc_row-separator.triangle.bottom {
      bottom: 0; }
    .vc_row-separator.triangle.triangle-bg-gray {
      -webkit-transform: inherit;
      -ms-transform: inherit;
      transform: inherit; }
      .vc_row-separator.triangle.triangle-bg-gray svg {
        fill: #f7f7f7;
        height: 27px; }
    .vc_row-separator.triangle.top {
      top: 0; }
  .vc_row-separator.curve svg {
    height: 50px;
    fill: #69afed;
    width: 100%; }
  .vc_row-separator.curve.bottom {
    bottom: 0; }
  .vc_row-separator.clouds {
    -webkit-transform: scale(-1);
    -ms-transform: scale(-1);
    transform: scale(-1); }
    .vc_row-separator.clouds svg {
      height: 60px;
      fill: #69afed;
      width: 100%; }
    .vc_row-separator.clouds.top {
      top: 0; }
  .vc_row-separator.waves svg {
    height: 30px;
    fill: #69afed;
    width: 100%; }
  .vc_row-separator.waves.bottom {
    bottom: 0; }
  .vc_row-separator.circle svg {
    fill: #69afed;
    width: 100%;
    height: 30px; }
  .vc_row-separator.circle.top {
    top: 0; }
  .vc_row-separator.square {
    -webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    width: auto;
    left: 50%; }
    .vc_row-separator.square.bottom {
      bottom: 0; }
    .vc_row-separator.square svg {
      height: 90px;
      fill: #0038E3;
      width: auto; }

.wavify-activation {
  z-index: 2; }

.row-column-content .separator {
  height: 200px; }

.vc_row-separator svg {
  fill: #69afed;
  height: 200px; }

#firefly {
  width: 100%;
  height: 100%; }

.constellation-effect {
  position: relative; }
  .constellation-effect canvas {
    height: 580px !important;
    width: 100% !important; }
  .constellation-effect .row-column-content {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    z-index: 2; }

.bg-svg--1 {
  background-image: url(/img/icons/home-digital-agency-image-03.jpg);
  background-repeat: no-repeat;
  background-position: top 122px center;
  position: relative; }

/* =====================
Info Boxes 
=======================*/
.bk-info-boxes {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 1fr); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bk-info-boxes {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bk-info-boxes {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 767px) {
    .bk-info-boxes {
      grid-template-columns: repeat(1, 1fr); } }
  .bk-info-boxes .info-grid-box.has-image {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat; }
  .bk-info-boxes .info-grid-box .box-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
    .bk-info-boxes .info-grid-box .box-content .box-content-inner {
      padding: 50px 55px; }
      @media only screen and (max-width: 767px) {
        .bk-info-boxes .info-grid-box .box-content .box-content-inner {
          padding: 30px 18px; } }
  .bk-info-boxes .info-grid-box.space-variation .box-content .box-content-inner {
    padding-top: 100px;
    padding-right: 60px;
    padding-bottom: 100px;
    padding-left: 60px; }

.info-box-wrap .has-image .box-image img {
  width: 100%; }

.info-box-wrap .space-variation .box-content .box-content-inner {
  padding-top: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  padding-left: 60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .info-box-wrap .space-variation .box-content .box-content-inner {
      padding: 20px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .info-box-wrap .space-variation .box-content .box-content-inner {
      padding: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .info-box-wrap .space-variation .box-content .box-content-inner {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media only screen and (max-width: 767px) {
    .info-box-wrap .space-variation .box-content .box-content-inner {
      padding-top: 30px;
      padding-bottom: 30px; } }

/* ===================
Pre Loader 
======================*/
.page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background: #ffffff; }

.page-load-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.preloader-wrap {
  display: table;
  width: 100%;
  height: 100%; }

.wrap-2 {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

body.loaded .page-loading {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

.page-loading {
  -webkit-transition: all 1s;
  transition: all 1s; }

/*========================
    Demo Option 
=========================*/
.demo-option-container {
  position: fixed;
  top: 0;
  left: 100%;
  background: #fff;
  height: 100%;
  width: 350px;
  max-width: calc(100% - 45px);
  z-index: 100000;
  -webkit-transition: all .5s;
  transition: all .5s; }
  .demo-option-container.open {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14); }

.demo-option-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 55px; }
  .demo-option-wrapper .demo-panel-header {
    padding: 40px 30px 30px;
    text-align: center; }
    .demo-option-wrapper .demo-panel-header .panel-btn {
      margin-bottom: 30px; }
      .demo-option-wrapper .demo-panel-header .panel-btn a i {
        line-height: 0;
        font-size: 20px;
        margin-right: 10px;
        color: #fff; }
    .demo-option-wrapper .demo-panel-header .title .heading-h5 {
      margin: 0 0 18px; }
  .demo-option-wrapper .demo-quick-option-list {
    padding: 27px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px; }
    .demo-option-wrapper .demo-quick-option-list a {
      position: relative;
      display: inline-block; }

/*==================================
	Portfolio Styles
===================================*/
/* =============================
    Portfolio Grid Area 
==============================*/
.portfolio {
  position: relative;
  /* Portfolio Style 1 */
  /* Portfolio Style 2 */ }
  .portfolio.portfolio_style--1 .thumb img {
    width: 100%; }
  .portfolio.portfolio_style--1 .port-overlay-info {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: #0038E3; }
    .portfolio.portfolio_style--1 .port-overlay-info .hover-action {
      text-align: center;
      padding: 0 15px;
      top: 50%;
      position: absolute;
      width: 100%;
      transform: translateY(-50%); }
      .portfolio.portfolio_style--1 .port-overlay-info .hover-action h3.post-overlay-title {
        opacity: 0;
        -webkit-transition: .3s ease-out 0s;
        transition: .3s ease-out 0s;
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
        font-size: 18px;
        line-height: 1.4;
        color: #fff;
        margin: 0;
        font-weight: 600;
        letter-spacing: 0em;
        font-family: "Poppins", sans-serif; }
        @media only screen and (max-width: 767px) {
          .portfolio.portfolio_style--1 .port-overlay-info .hover-action h3.post-overlay-title {
            font-size: 20px; } }
        .portfolio.portfolio_style--1 .port-overlay-info .hover-action h3.post-overlay-title a {
          color: #fff; }
        .portfolio.portfolio_style--1 .port-overlay-info .hover-action h3.post-overlay-title.font-24 {
          font-size: 24px; }
          @media only screen and (max-width: 767px) {
            .portfolio.portfolio_style--1 .port-overlay-info .hover-action h3.post-overlay-title.font-24 {
              font-size: 17px; } }
      .portfolio.portfolio_style--1 .port-overlay-info .hover-action .category {
        font-weight: 500;
        font-size: 14px;
        opacity: 0;
        -webkit-transition: .3s ease-out 0s;
        transition: .3s ease-out 0s;
        -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
        transform: translateY(10px);
        margin-top: 10px;
        color: #fff; }
    .portfolio.portfolio_style--1 .port-overlay-info.font-playfair .hover-action h3.post-overlay-title {
      font-size: 24px;
      font-family: "Playfair Display", Georgia, serif; }
      @media only screen and (max-width: 767px) {
        .portfolio.portfolio_style--1 .port-overlay-info.font-playfair .hover-action h3.post-overlay-title {
          font-size: 20px; } }
    .portfolio.portfolio_style--1 .port-overlay-info.lg-font .hover-action h3.post-overlay-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 1.24; }
    .portfolio.portfolio_style--1 .port-overlay-info.lg-font .hover-action .category {
      font-style: italic;
      font-size: 15px;
      position: relative;
      padding-left: 50px; }
      .portfolio.portfolio_style--1 .port-overlay-info.lg-font .hover-action .category::before {
        position: absolute;
        top: 12px;
        left: 0;
        content: "";
        width: 40px;
        height: 1px;
        background: #fff; }
    .portfolio.portfolio_style--1 .port-overlay-info.position-left .hover-action {
      padding: 40px;
      top: 0;
      position: absolute;
      transform: translateY(0);
      text-align: left; }
      @media only screen and (max-width: 767px) {
        .portfolio.portfolio_style--1 .port-overlay-info.position-left .hover-action {
          padding: 10px; } }
    .portfolio.portfolio_style--1 .port-overlay-info.position-bottom .hover-action {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;
      padding: 37px 28px;
      transform: translateY(0);
      text-align: left; }
    .portfolio.portfolio_style--1 .port-overlay-info.with-caption .hover-action h3.post-overlay-title {
      -webkit-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      transform: translateY(-10px);
      -webkit-transition: .3s ease-out 0s;
      transition: .3s ease-out 0s; }
  .portfolio.portfolio_style--1.bg-black-shape .port-overlay-info {
    background-color: transparent; }
  .portfolio.portfolio_style--1.bg-black-shape .port-black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: -webkit-linear-gradient(bottom, #000, transparent 50%);
    background: linear-gradient(0deg, #000 0, transparent 50%);
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .portfolio.portfolio_style--1.rgb-color:hover .port-overlay-info {
    background: linear-gradient(0deg, #7202bb 25%, #3264f5 100%) !important; }
  .portfolio.portfolio_style--1:hover .port-overlay-info {
    visibility: visible;
    opacity: .9; }
    .portfolio.portfolio_style--1:hover .port-overlay-info h3.post-overlay-title {
      opacity: 1;
      -webkit-transition: .5s ease-out .2s;
      transition: .5s ease-out .2s;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); }
    .portfolio.portfolio_style--1:hover .port-overlay-info .category {
      opacity: 1;
      -webkit-transition: .5s ease-out .2s;
      transition: .5s ease-out .2s;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); }
  .portfolio.portfolio_style--1:hover .port-overlay-info.with-caption .hover-action h3.post-overlay-title {
    opacity: 1;
    -webkit-transition: .5s ease-out .2s;
    transition: .5s ease-out .2s;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  .portfolio.portfolio_style--1:hover.bg-black-shape .port-black-overlay {
    visibility: visible;
    opacity: .9; }
  .portfolio.portfolio_style--2 {
    width: auto !important;
    box-sizing: border-box; }
    .portfolio.portfolio_style--2 .thumb {
      display: block; }
      .portfolio.portfolio_style--2 .thumb img {
        width: auto;
        height: 550px; }
    .portfolio.portfolio_style--2 .portfolio-overlay {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: -webkit-linear-gradient(bottom, #000, transparent 50%);
      background: linear-gradient(0deg, #000 0, transparent 50%);
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .portfolio.portfolio_style--2 .port-overlay-info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      visibility: hidden;
      opacity: 0; }
      .portfolio.portfolio_style--2 .port-overlay-info .content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 37px 28px; }
        .portfolio.portfolio_style--2 .port-overlay-info .content h3.port-title {
          opacity: 0;
          -webkit-transition: .3s ease-out 0s;
          transition: .3s ease-out 0s;
          -webkit-transform: translateY(-10px);
          -ms-transform: translateY(-10px);
          transform: translateY(-10px);
          font-size: 30px;
          font-weight: 700;
          line-height: 1.24;
          color: #fff;
          margin: 0 0 7px;
          font-family: 'Montserrat', sans-serif; }
        .portfolio.portfolio_style--2 .port-overlay-info .content .category {
          position: relative;
          padding-left: 50px;
          opacity: 0;
          -webkit-transition: .3s ease-out 0s;
          transition: .3s ease-out 0s;
          -webkit-transform: translateY(10px);
          -ms-transform: translateY(10px);
          transform: translateY(10px);
          color: #fff;
          font-style: italic;
          font-size: 15px; }
          .portfolio.portfolio_style--2 .port-overlay-info .content .category::before {
            position: absolute;
            top: 12px;
            left: 0;
            content: "";
            width: 40px;
            height: 1px;
            background: #fff; }
    .portfolio.portfolio_style--2:hover .portfolio-overlay {
      visibility: visible;
      opacity: .9; }
    .portfolio.portfolio_style--2:hover .port-overlay-info {
      visibility: visible;
      opacity: 1; }
      .portfolio.portfolio_style--2:hover .port-overlay-info .content h3.port-title {
        opacity: 1;
        -webkit-transition: .5s ease-out .2s;
        transition: .5s ease-out .2s;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0); }
      .portfolio.portfolio_style--2:hover .port-overlay-info .content .category {
        opacity: 1;
        -webkit-transition: .5s ease-out .2s;
        transition: .5s ease-out .2s;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0); }

/*=========================
    Portfolio Grid Metro 
 ==========================*/
/* Metro Grid Style 1 */
.portfolio-grid-metro .portfolio-25 {
  width: 25%; }

.portfolio-grid-metro .grid-width-2 {
  width: 50%; }

.portfolio-grid-metro .resizer {
  width: 25%; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-grid-metro .portfolio-25 {
    width: 50%; }
  .portfolio-grid-metro .grid-width-2 {
    width: 100%; }
  .portfolio-grid-metro .resizer {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .portfolio-grid-metro .portfolio-25 {
    width: 100%; }
  .portfolio-grid-metro .grid-width-2 {
    width: 100%; }
  .portfolio-grid-metro .resizer {
    width: 100%; } }

/* Metro Grid Style 1 */
.portfolio-grid-metro2 .portfolio-20 {
  width: 20%; }

.portfolio-grid-metro2 .grid-width-2 {
  width: 40%; }

.portfolio-grid-metro2 .resizer {
  width: 20%; }

.portfolio-grid-metro2.news-bulletin .portfolio-20 {
  padding: 0 40px; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-grid-metro2 .portfolio-20 {
    width: 50%; }
  .portfolio-grid-metro2 .grid-width-2 {
    width: 50%; }
  .portfolio-grid-metro2 .resizer {
    width: 50%; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-grid-metro2 .portfolio-20 {
    width: 50%; }
  .portfolio-grid-metro2 .grid-width-2 {
    width: 50%; }
  .portfolio-grid-metro2 .resizer {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .portfolio-grid-metro2 .portfolio-20 {
    width: 50%; }
  .portfolio-grid-metro2 .grid-width-2 {
    width: 50%; }
  .portfolio-grid-metro2 .resizer {
    width: 50%; } }

@media only screen and (max-width: 575px) {
  .portfolio-grid-metro2 .portfolio-20 {
    width: 100%; }
  .portfolio-grid-metro2 .grid-width-2 {
    width: 100%; }
  .portfolio-grid-metro2 .resizer {
    width: 100%; } }

/* Metro Grid Style 3 */
.portfolio-grid-metro3 {
  margin: 0 70px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-grid-metro3 {
      margin: 0 30px; } }
  @media only screen and (max-width: 767px) {
    .portfolio-grid-metro3 {
      margin: 0 20px; } }
  .portfolio-grid-metro3 .portfolio-25 {
    width: 25%;
    padding: 0 35px;
    margin-bottom: 70px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .portfolio-grid-metro3 .portfolio-25 {
        width: 50%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .portfolio-grid-metro3 .portfolio-25 {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 30px; } }
    @media only screen and (max-width: 767px) {
      .portfolio-grid-metro3 .portfolio-25 {
        width: 100%;
        padding: 0 15px;
        margin-bottom: 30px; } }
  .portfolio-grid-metro3 .grid-width-2 {
    width: 50%; }
  .portfolio-grid-metro3 .resizer {
    width: 25%; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .portfolio-grid-metro3 .portfolio-25 {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 30px; }
    .portfolio-grid-metro3 .grid-width-2 {
      width: 50%; }
    .portfolio-grid-metro3 .resizer {
      width: 50%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio-grid-metro3 .portfolio-25 {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 30px; }
    .portfolio-grid-metro3 .grid-width-2 {
      width: 50%; }
    .portfolio-grid-metro3 .resizer {
      width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-grid-metro3 .portfolio-25 {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 30px; }
    .portfolio-grid-metro3 .grid-width-2 {
      width: 100%; }
    .portfolio-grid-metro3 .resizer {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .portfolio-grid-metro3 .portfolio-25 {
      width: 50%;
      padding: 0 15px;
      margin-bottom: 30px; }
    .portfolio-grid-metro3 .grid-width-2 {
      width: 100%; }
    .portfolio-grid-metro3 .resizer {
      width: 50%; } }
  @media only screen and (max-width: 575px) {
    .portfolio-grid-metro3 .portfolio-25 {
      width: 100%; }
    .portfolio-grid-metro3 .grid-width-2 {
      width: 100%; }
    .portfolio-grid-metro3 .resizer {
      width: 100%; } }

/* Metro Grid Style 3 */
.portfolio-grid-metro4 {
  margin: 0 -15px; }
  .portfolio-grid-metro4 .portfolio-33-33 {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px; }
  .portfolio-grid-metro4 .resizer {
    width: 33.33%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-grid-metro4 .portfolio-33-33 {
      width: 50%; }
    .portfolio-grid-metro4 .resizer {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .portfolio-grid-metro4 .portfolio-33-33 {
      width: 100%; }
    .portfolio-grid-metro4 .resizer {
      width: 100%; } }

/* Metro Grid Style 3 */
.portfolio-grid-metro5 .portfolio-33-33 {
  width: 33.33%; }

.portfolio-grid-metro5 .resizer {
  width: 33.33%; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-grid-metro5 .portfolio-33-33 {
    width: 50%; }
  .portfolio-grid-metro5 .resizer {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .portfolio-grid-metro5 .portfolio-33-33 {
    width: 100%; }
  .portfolio-grid-metro5 .resizer {
    width: 100%; } }

/* Metro Grid Style 3 */
.portfolio-grid-metro6 {
  margin: 0 -15px; }
  .portfolio-grid-metro6 .portfolio-33-33 {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px; }
  .portfolio-grid-metro6 .resizer {
    width: 33.33%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-grid-metro6 .portfolio-33-33 {
      width: 50%; }
    .portfolio-grid-metro6 .resizer {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .portfolio-grid-metro6 .portfolio-33-33 {
      width: 50%; }
    .portfolio-grid-metro6 .resizer {
      width: 50%; } }
  @media only screen and (max-width: 575px) {
    .portfolio-grid-metro6 .portfolio-33-33 {
      width: 100%; }
    .portfolio-grid-metro6 .resizer {
      width: 100%; } }

/* Metro Grid Style 7 */
.portfolio-grid-metro7 {
  margin: 0 -25px; }
  .portfolio-grid-metro7 .portfolio-25 {
    width: 25%;
    padding: 0 25px;
    margin-top: 50px; }
  .portfolio-grid-metro7 .resizer {
    width: 25%; }
  .portfolio-grid-metro7 .grid-width-2 {
    width: 50%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-grid-metro7 .portfolio-25 {
      width: 50%; }
    .portfolio-grid-metro7 .resizer {
      width: 50%; }
    .portfolio-grid-metro7 .grid-width-2 {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .portfolio-grid-metro7 .portfolio-25 {
      width: 50%; }
    .portfolio-grid-metro7 .resizer {
      width: 50%; }
    .portfolio-grid-metro7 .grid-width-2 {
      width: 50%; } }
  @media only screen and (max-width: 575px) {
    .portfolio-grid-metro7 .portfolio-25 {
      width: 100%; }
    .portfolio-grid-metro7 .resizer {
      width: 100%; }
    .portfolio-grid-metro7 .grid-width-2 {
      width: 100%; } }

.ajax-filter-single {
  display: none; }

.ajax-filter-single2 {
  display: none; }

/* ------------------------------
    Home Creative Potfolio 
--------------------------------*/
.porfolio-swip-horizontal {
  width: auto;
  margin-right: -1000px;
  position: relative;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .porfolio-swip-horizontal {
      margin-right: 0px; } }
  .porfolio-swip-horizontal .swiper-slide {
    width: auto !important; }
    .porfolio-swip-horizontal .swiper-slide .thumb {
      width: auto !important; }
      .porfolio-swip-horizontal .swiper-slide .thumb img {
        height: 550px;
        max-width: 100%;
        vertical-align: top; }
  .porfolio-swip-horizontal .swiper-pagination.swiper-pagination-custom {
    position: absolute;
    top: auto;
    bottom: 20px;
    left: 50%;
    height: 3px;
    max-width: 100%;
    background: #3e3e3e;
    width: 594px;
    transform: translateX(-124%); }
    @media only screen and (max-width: 767px) {
      .porfolio-swip-horizontal .swiper-pagination.swiper-pagination-custom {
        width: 245px;
        transform: translateX(-50%);
        left: 50%; } }
    @media only screen and (max-width: 479px) {
      .porfolio-swip-horizontal .swiper-pagination.swiper-pagination-custom {
        width: 200px;
        transform: translateX(-20%); } }
  .porfolio-swip-horizontal .progressbar .filled {
    background: #fff;
    color: #fff;
    position: absolute;
    bottom: 0;
    height: 100%; }
    .porfolio-swip-horizontal .progressbar .filled::before {
      content: "";
      position: absolute;
      top: -6px;
      right: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: inherit; }
  .porfolio-swip-horizontal .fraction {
    position: absolute;
    left: -67px;
    top: -10px; }
    .porfolio-swip-horizontal .fraction span {
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-weight: 500;
      color: #535353;
      font-size: 14px; }
      .porfolio-swip-horizontal .fraction span.current {
        color: #fff; }
  .porfolio-swip-horizontal.black-number .progressbar .filled {
    background: #535353;
    color: #535353; }
    .porfolio-swip-horizontal.black-number .progressbar .filled::before {
      content: "";
      position: absolute;
      top: -6px;
      right: -7px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: inherit; }
  .porfolio-swip-horizontal.black-number .fraction {
    position: absolute;
    left: -67px;
    top: -10px; }
    .porfolio-swip-horizontal.black-number .fraction span {
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-weight: 500;
      color: #000;
      font-size: 14px; }
      .porfolio-swip-horizontal.black-number .fraction span.current {
        color: #000; }

/* ======================
    Metro Grid Style 8 
==========================*/
.portfolio-grid-metro8 {
  margin: 0 -50px; }
  .portfolio-grid-metro8 .portfolio-50 {
    width: 50%;
    padding: 0 50px;
    margin-top: 100px; }
  .portfolio-grid-metro8 .resizer {
    width: 50%; }
  .portfolio-grid-metro8 .grid-width-2 {
    width: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio-grid-metro8 {
      margin: 0 -25px; }
      .portfolio-grid-metro8 .portfolio-50 {
        width: 50%;
        padding: 0 25px;
        margin-top: 50px; }
      .portfolio-grid-metro8 .resizer {
        width: 50%; }
      .portfolio-grid-metro8 .grid-width-2 {
        width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-grid-metro8 {
      margin: 0 -25px; }
      .portfolio-grid-metro8 .portfolio-50 {
        width: 50%;
        padding: 0 25px;
        margin-top: 50px; }
      .portfolio-grid-metro8 .resizer {
        width: 50%; }
      .portfolio-grid-metro8 .grid-width-2 {
        width: 50%; } }
  @media only screen and (max-width: 767px) {
    .portfolio-grid-metro8 {
      margin: 0 -15px; }
      .portfolio-grid-metro8 .portfolio-50 {
        width: 50%;
        padding: 0 15px;
        margin-top: 30px; }
      .portfolio-grid-metro8 .resizer {
        width: 50%; }
      .portfolio-grid-metro8 .grid-width-2 {
        width: 50%; } }
  @media only screen and (max-width: 575px) {
    .portfolio-grid-metro8 .portfolio-50 {
      width: 100%; }
    .portfolio-grid-metro8 .resizer {
      width: 100%; }
    .portfolio-grid-metro8 .grid-width-2 {
      width: 100%; } }

/* =========================
    Portfolio Caption 
============================*/
.portfolio.with-caption {
  background: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .portfolio.with-caption .thumb {
    position: relative; }
    .portfolio.with-caption .thumb a img {
      width: 100%; }
    .portfolio.with-caption .thumb .port-plus-view {
      width: 68px;
      height: 68px;
      line-height: 68px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0.9);
      -ms-transform: translate(-50%, -50%) scale(0.9);
      transform: translate(-50%, -50%) scale(0.9);
      font-size: 18px;
      text-align: center;
      border-radius: 50%;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .portfolio.with-caption .thumb .port-plus-view::before {
        content: "\f067";
        font-family: Font Awesome\ 5 Free;
        font-weight: 600;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: #222; }
  .portfolio.with-caption:hover .thumb .port-plus-view {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .portfolio.with-caption .caption-bottom {
    padding: 27px 30px 41px; }
    @media only screen and (max-width: 767px) {
      .portfolio.with-caption .caption-bottom {
        padding: 24px 17px 25px; } }
    .portfolio.with-caption .caption-bottom .info h5.heading a {
      color: #222222; }
    .portfolio.with-caption .caption-bottom .info p {
      margin-top: 10px; }
  .portfolio.with-caption.no--padding .caption-bottom {
    padding: 0; }
  .portfolio.with-caption:hover {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  .portfolio.with-caption.no-translate:hover {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  .portfolio.with-caption.hover-image-zoom .thumb {
    overflow: hidden; }
    .portfolio.with-caption.hover-image-zoom .thumb a img {
      width: 100%;
      -webkit-transition: all 1s;
      transition: all 1s;
      background-position: 50%;
      background-size: cover; }
  .portfolio.with-caption.hover-image-zoom:hover {
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit; }
    .portfolio.with-caption.hover-image-zoom:hover .thumb a img {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05); }
  .portfolio.with-caption.news-bulletin .inner {
    height: 100%; }
  .portfolio.with-caption.news-bulletin .paralax-portfolio {
    height: auto; }

/* ========================
    Portfolio Caption 
===========================*/
.portfolio-cation {
  position: relative; }
  .portfolio-cation .thumb img {
    width: 100%; }
  .portfolio-cation .port-overlay-info {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    overflow: hidden; }
    .portfolio-cation .port-overlay-info .hover-action {
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      padding: 25px;
      background: #fff;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .portfolio-cation .port-overlay-info .hover-action .post-overlay-title {
        font-size: 20px;
        line-height: 1.4;
        margin: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        font-weight: 600;
        font-family: "Poppins", sans-serif; }
        .portfolio-cation .port-overlay-info .hover-action .post-overlay-title a {
          color: #222222; }
      .portfolio-cation .port-overlay-info .hover-action .category {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-left: 10px;
        font-weight: 500;
        font-size: 14px;
        color: #333333; }
  .portfolio-cation:hover .port-overlay-info .hover-action {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }

/* ==========================
    Mesonry Button 
=============================*/
.messonry-button button {
  padding: 0;
  border: 0 none;
  position: relative; }
  .messonry-button button span {
    position: relative;
    display: inline-block;
    padding: 5px 15px; }
    .messonry-button button span.filter-text {
      font-weight: 600;
      line-height: 1.58;
      font-size: 14px;
      color: #333333;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .messonry-button button span.filter-counter {
      position: absolute;
      top: 0;
      left: 50%;
      visibility: hidden;
      margin: 0 auto;
      min-width: 34px;
      height: 24px;
      line-height: 13px;
      border-radius: 3px;
      font-size: 12px;
      background-color: #0038E3;
      color: #fff;
      text-align: center;
      opacity: 0;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-weight: 600;
      font-family: "Poppins", sans-serif; }
      .messonry-button button span.filter-counter::before {
        position: absolute;
        right: 0;
        bottom: -4px;
        left: 0;
        display: block;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: 4px solid #0038E3;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
        border-top-color: #0038E3; }
  .messonry-button button:hover span.filter-counter {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%); }
  .messonry-button button:hover span.filter-text {
    color: #0038E3; }
  .messonry-button button.is-checked span.filter-text {
    color: #0038E3; }

.toggle-btn .loadMore {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  border: 0 none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .toggle-btn .loadMore:hover {
    color: #0038E3; }
  .toggle-btn .loadMore i {
    margin-left: 15px; }

/* ==============================
    Portfolio Button Style 2 
==================================*/
.messonry-button--2 button {
  position: relative;
  display: inline-block;
  padding: 5px 30px 5px 10px;
  color: #888;
  border: 0 none; }
  @media only screen and (max-width: 767px) {
    .messonry-button--2 button {
      margin-bottom: 18px; } }
  .messonry-button--2 button span {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .messonry-button--2 button span.filter-text {
      letter-spacing: .2px;
      font-weight: 600;
      color: #333;
      line-height: 1.58;
      font-size: 14px; }
    .messonry-button--2 button span.filter-counter {
      position: absolute;
      top: -7px;
      right: 10px;
      font-size: 12px;
      color: #333;
      text-align: right; }
  .messonry-button--2 button.is-checked span.filter-text {
    color: #001029; }
  .messonry-button--2 button.is-checked span.filter-counter {
    color: #001029; }

/* =========================
    Paralax Area
============================*/
.paralax-grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr); }

.paralax-portfolio {
  position: relative;
  height: 390px;
  width: 390px;
  transform-style: preserve-3d;
  z-index: 2; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .paralax-portfolio {
      width: auto; } }
  @media only screen and (max-width: 767px) {
    .paralax-portfolio {
      width: auto; } }
  .paralax-portfolio .portfolio-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center; }
    .paralax-portfolio .portfolio-bg.bg--1 {
      background: url("/img/portfolio/grid/paralax-1.jpg"); }
    .paralax-portfolio .portfolio-bg.bg--2 {
      background: url("/img/portfolio/grid/paralax-2.jpg"); }
    .paralax-portfolio .portfolio-bg.bg--3 {
      background: url("/img/portfolio/grid/paralax-3.jpg"); }
    .paralax-portfolio .portfolio-bg.bg--4 {
      background: url("/img/portfolio/grid/paralax-4.jpg"); }
    .paralax-portfolio .portfolio-bg.bg--5 {
      background: url("/img/portfolio/grid/paralax-5.jpg"); }
    .paralax-portfolio .portfolio-bg.bg--6 {
      background: url("/img/portfolio/grid/paralax-6.jpg"); }

/* =========================
Has Center 
===============================*/
.has-center-content .has-centerinner .thumb img {
  width: auto;
  height: 600px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .has-center-content .has-centerinner .thumb img {
      height: 516px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .has-center-content .has-centerinner .thumb img {
      height: 406px; } }
  @media only screen and (max-width: 767px) {
    .has-center-content .has-centerinner .thumb img {
      height: 406px; } }
  @media only screen and (max-width: 575px) {
    .has-center-content .has-centerinner .thumb img {
      height: 283px; } }

.has-center-content .has-centerinner .content {
  text-align: left;
  padding: 102px 50px 50px;
  -webkit-transition: opacity .3s,width .3s,visibility .3s;
  transition: opacity .3s,width .3s,visibility .3s;
  visibility: hidden;
  opacity: 0;
  width: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .has-center-content .has-centerinner .content {
      padding: 37px 28px 22px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .has-center-content .has-centerinner .content {
      padding: 37px 33px 33px; } }
  @media only screen and (max-width: 767px) {
    .has-center-content .has-centerinner .content {
      padding: 37px 33px 33px; } }
  .has-center-content .has-centerinner .content h1.heading {
    position: relative; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .has-center-content .has-centerinner .content h1.heading {
        font-size: 36px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .has-center-content .has-centerinner .content h1.heading {
        font-size: 27px; } }
    @media only screen and (max-width: 767px) {
      .has-center-content .has-centerinner .content h1.heading {
        font-size: 25px; } }
    .has-center-content .has-centerinner .content h1.heading::before {
      content: "";
      position: absolute;
      top: 40px;
      left: -172px;
      width: 150px;
      height: 3px;
      background-color: #99E5E8; }
      @media only screen and (max-width: 767px) {
        .has-center-content .has-centerinner .content h1.heading::before {
          top: 18px;
          left: -66px;
          width: 53px; } }
  @media only screen and (max-width: 767px) {
    .has-center-content .has-centerinner .content .bkseparator--55 {
      height: 24px; } }
  .has-center-content .has-centerinner .content .read-more-btn a {
    font-size: 16px;
    font-weight: 500;
    transition: 0.4s ease-in-out;
    color: #fff; }
    .has-center-content .has-centerinner .content .read-more-btn a i {
      font-size: 12px;
      margin-left: 8px;
      color: #99E5E8; }
    .has-center-content .has-centerinner .content .read-more-btn a:hover {
      color: #99E5E8; }

.slick-slide .has-center-content .has-centerinner .thumb img {
  width: 100%;
  opacity: 0.3;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.slick-slide .has-center-content .has-centerinner .content {
  visibility: hidden;
  opacity: 0;
  transform: scale(0, 0);
  width: 0;
  display: none; }

.slick-center .has-center-content .has-centerinner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .slick-center .has-center-content .has-centerinner {
      display: block; } }
  .slick-center .has-center-content .has-centerinner .thumb img {
    opacity: 1; }
  .slick-center .has-center-content .has-centerinner .content {
    visibility: visible;
    opacity: 1;
    width: 380px;
    transform: scale(1, 1);
    display: block; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slick-center .has-center-content .has-centerinner .content {
        width: 437px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slick-center .has-center-content .has-centerinner .content {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .slick-center .has-center-content .has-centerinner .content {
        width: 100%; } }

.has-portfolio-wrapper .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn.slick-prev {
  left: 35px; }

@media only screen and (max-width: 767px) {
  .slick-gutter-35 .slick-slide {
    padding-left: 15px;
    padding-right: 15px; } }

.has-portfolio-wrapper .slick-arrow-center.slick-arrow-triggle.slick-arrow-trigglestyle-2 .slick-btn.slick-next {
  right: 35px; }

/*=================================
 Type Hover Portfolio Animation
===================================*/
.portfolio-type-hover {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100vh; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-type-hover {
      padding-bottom: 0 !important;
      height: auto;
      padding: 150px 0;
      padding-top: 115px !important; } }
  @media only screen and (max-width: 767px) {
    .portfolio-type-hover {
      padding-bottom: 0 !important;
      height: auto;
      padding: 150px 0;
      padding-top: 115px !important; } }
  .portfolio-type-hover .row-xs-center {
    height: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

.type-portfolio-wrapper {
  padding: 30px 100px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .type-portfolio-wrapper {
      padding: 30px 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .type-portfolio-wrapper {
      padding: 30px 50px; } }
  @media only screen and (max-width: 767px) {
    .type-portfolio-wrapper {
      padding: 30px 50px; } }
  .type-portfolio-wrapper .type-portfolio {
    margin-top: 55px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .type-portfolio-wrapper .type-portfolio {
        margin-top: 22px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .type-portfolio-wrapper .type-portfolio {
        margin-top: 22px; } }
    @media only screen and (max-width: 767px) {
      .type-portfolio-wrapper .type-portfolio {
        margin-top: 22px; } }
    .type-portfolio-wrapper .type-portfolio h3 a {
      font-size: 36px;
      line-height: 1.23;
      vertical-align: top;
      color: #666;
      margin-bottom: 0;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-family: Montserrat, Helvetica, Arial, sans-serif;
      font-weight: 600; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .type-portfolio-wrapper .type-portfolio h3 a {
          font-size: 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .type-portfolio-wrapper .type-portfolio h3 a {
          font-size: 30px; } }
      @media only screen and (max-width: 767px) {
        .type-portfolio-wrapper .type-portfolio h3 a {
          font-size: 24px; } }
    .type-portfolio-wrapper .type-portfolio h3 a.active {
      color: #222;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

/* Type Hover Thumbnail */
.type-portfolio-thumbnail {
  position: relative;
  padding: 75px 75px 75px 35px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .type-portfolio-thumbnail {
      padding-top: 0; } }
  @media only screen and (max-width: 767px) {
    .type-portfolio-thumbnail {
      padding-top: 0; } }
  .type-portfolio-thumbnail .inner {
    position: relative;
    width: 850px;
    max-width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .type-portfolio-thumbnail .inner {
        width: 500px;
        margin: auto; } }
    @media only screen and (max-width: 767px) {
      .type-portfolio-thumbnail .inner {
        width: 100%;
        margin: auto; } }
    .type-portfolio-thumbnail .inner::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 100%; }
    .type-portfolio-thumbnail .inner .type-thumbnail-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transition: all 1s;
      transition: all 1s;
      opacity: 0;
      z-index: -1;
      overflow: hidden; }
      .type-portfolio-thumbnail .inner .type-thumbnail-bg .bg-thumb {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
        border-radius: 50%;
        -webkit-transition: all 1.5s;
        transition: all 1.5s; }
        .type-portfolio-thumbnail .inner .type-thumbnail-bg .bg-thumb.bg-image--1 {
          background-image: url(/img/portfolio/type-hover/type-hover-1.jpg); }
        .type-portfolio-thumbnail .inner .type-thumbnail-bg .bg-thumb.bg-image--2 {
          background-image: url(/img/portfolio/type-hover/type-hover-2.jpg); }
        .type-portfolio-thumbnail .inner .type-thumbnail-bg .bg-thumb.bg-image--3 {
          background-image: url(/img/portfolio/type-hover/type-hover-3.jpg); }
        .type-portfolio-thumbnail .inner .type-thumbnail-bg .bg-thumb.bg-image--4 {
          background-image: url(/img/portfolio/type-hover/type-hover-4.jpg); }
        .type-portfolio-thumbnail .inner .type-thumbnail-bg .bg-thumb.bg-image--5 {
          background-image: url(/img/portfolio/type-hover/type-hover-5.jpg); }
      .type-portfolio-thumbnail .inner .type-thumbnail-bg.active {
        z-index: 1;
        opacity: 1; }
        .type-portfolio-thumbnail .inner .type-thumbnail-bg.active .bg-thumb {
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1); }

/* ===============================
    Portfolio Type Two
==================================*/
.portfolio-type2-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 100vh; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .portfolio-type2-container {
      height: auto; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio-type2-container {
      height: auto; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-type2-container {
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .portfolio-type2-container {
      height: auto; } }
  .portfolio-type2-container .row-xs-center {
    height: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

/* Type Hover Wrapper */
.portfolio-type2-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-top: 70px;
  /* Type Hover Thumbnail */ }
  @media only screen and (max-width: 767px) {
    .portfolio-type2-content {
      margin-top: 101px; } }
  .portfolio-type2-content .type-portfolio-wrapper-2 {
    padding: 80px 30px;
    text-align: center;
    position: relative;
    z-index: 3; }
    @media only screen and (max-width: 575px) {
      .portfolio-type2-content .type-portfolio-wrapper-2 {
        padding: 19px 30px; } }
    .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 {
      display: block;
      padding: 20px; }
      .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 {
        margin: 0; }
        .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a {
          font-size: 70px;
          line-height: 1.4;
          color: rgba(255, 255, 255, 0.3);
          margin-bottom: 0;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          font-family: Poppins, Helvetica, Arial, sans-serif;
          font-weight: 600; }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a {
              font-size: 50px; } }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a {
              font-size: 40px; } }
          @media only screen and (max-width: 767px) {
            .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a {
              font-size: 30px; } }
          @media only screen and (max-width: 575px) {
            .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a {
              font-size: 22px; } }
          .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a span {
            font-size: 18px;
            vertical-align: middle;
            margin-right: 20px; }
      .portfolio-type2-content .type-portfolio-wrapper-2 .type-portfolio2 h3 a.active {
        color: #fff; }
  .portfolio-type2-content .type-portfolio-thumbnail-2 {
    position: absolute;
    height: 800px;
    width: 1060px;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .portfolio-type2-content .type-portfolio-thumbnail-2 {
        width: 100%;
        height: 675px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .portfolio-type2-content .type-portfolio-thumbnail-2 {
        width: 100%;
        height: 600px; } }
    @media only screen and (max-width: 767px) {
      .portfolio-type2-content .type-portfolio-thumbnail-2 {
        width: 100%;
        height: 600px; } }
    @media only screen and (max-width: 575px) {
      .portfolio-type2-content .type-portfolio-thumbnail-2 {
        height: 430px; } }
    .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px;
      -webkit-transition: all .5s;
      transition: all .5s;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 .bg-thumb {
        -webkit-transition: all 1s;
        transition: all 1s;
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; }
        .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 .bg-thumb.bg-image--1 {
          background-image: url(/img/portfolio/type-hover/type2-hover-1.jpg); }
        .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 .bg-thumb.bg-image--2 {
          background-image: url(/img/portfolio/type-hover/type2-hover-2.jpg); }
        .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 .bg-thumb.bg-image--3 {
          background-image: url(/img/portfolio/type-hover/type2-hover-3.jpg); }
        .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 .bg-thumb.bg-image--4 {
          background-image: url(/img/portfolio/type-hover/type2-hover-4.jpg); }
        .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2 .bg-thumb.bg-image--5 {
          background-image: url(/img/portfolio/type-hover/type2-hover-5.jpg); }
      .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2.active {
        z-index: 1;
        opacity: 1; }
        .portfolio-type2-content .type-portfolio-thumbnail-2 .inner .type-thumbnail-bg2.active .bg-thumb {
          -webkit-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1); }

/* ===============================
    Portfolio Type 3
==================================*/
.portfolio-type3-container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 100px;
  padding-right: 100px; }
  @media only screen and (max-width: 767px) {
    .portfolio-type3-container {
      padding-left: 50px;
      padding-right: 50px; } }

/* Type Hover Wrapper */
.portfolio-type3-content {
  /* Type Hover Thumbnail */ }
  .portfolio-type3-content .type-portfolio-wrapper-3 {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .portfolio-type3-content .type-portfolio-wrapper-3 {
        flex-wrap: wrap;
        margin-top: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .portfolio-type3-content .type-portfolio-wrapper-3 {
        flex-wrap: wrap;
        margin-top: 40px; } }
    @media only screen and (max-width: 767px) {
      .portfolio-type3-content .type-portfolio-wrapper-3 {
        flex-wrap: wrap;
        margin-top: 40px; } }
    .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 {
      text-align: left;
      padding: 56px 0;
      margin-right: 105px;
      flex-basis: 25%; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 {
          padding-right: 20px;
          flex-basis: 50%;
          margin-right: 0;
          padding: 32px 0; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 {
          padding-right: 20px;
          flex-basis: 50%;
          margin-right: 0;
          padding-top: 20px;
          padding-bottom: 20px; } }
      @media only screen and (max-width: 767px) {
        .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 {
          padding-right: 20px;
          flex-basis: 100%;
          margin-right: 0;
          padding-top: 20px;
          padding-bottom: 20px; } }
      .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 {
        margin: 0; }
        .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a {
          position: relative;
          font-size: 40px;
          line-height: 1.1;
          margin-bottom: 0;
          padding-left: 40px;
          color: rgba(0, 0, 0, 0.3);
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          font-family: "louis_george_caferegular";
          font-weight: 700;
          display: inline-block; }
          @media only screen and (min-width: 1200px) and (max-width: 1599px) {
            .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a {
              font-size: 29px;
              padding-left: 27px; } }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a {
              font-size: 29px;
              padding-left: 27px; } }
          @media only screen and (max-width: 767px) {
            .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a {
              font-size: 29px;
              padding-left: 27px; } }
          .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a::before {
            content: "";
            width: 80px;
            height: 1px;
            background: #ddd;
            position: absolute;
            top: 22px;
            left: -95px; }
            @media only screen and (min-width: 992px) and (max-width: 1199px) {
              .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a::before {
                display: none; } }
            @media only screen and (min-width: 768px) and (max-width: 991px) {
              .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a::before {
                display: none; } }
            @media only screen and (max-width: 767px) {
              .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a::before {
                display: none; } }
          .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a span {
            position: absolute;
            top: 13px;
            left: 0;
            font-size: 18px;
            vertical-align: middle;
            margin-right: 20px;
            color: rgba(0, 0, 0, 0.3);
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3:first-child h3 a::before {
        display: none; }
      .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a.active {
        color: #222222; }
        .portfolio-type3-content .type-portfolio-wrapper-3 .type-portfolio3 h3 a.active span {
          color: #222222; }
  .portfolio-type3-content .type-portfolio-thumbnail-3 {
    position: relative;
    width: 100%;
    height: 560px; }
    .portfolio-type3-content .type-portfolio-thumbnail-3 .inner .type-thumbnail-bg3 .bg-thumb {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: all .5s;
      transition: all .5s;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%; }
      .portfolio-type3-content .type-portfolio-thumbnail-3 .inner .type-thumbnail-bg3 .bg-thumb.bg-image--1 {
        background-image: url(/img/portfolio/type-hover/type3-hover-1.jpg); }
      .portfolio-type3-content .type-portfolio-thumbnail-3 .inner .type-thumbnail-bg3 .bg-thumb.bg-image--2 {
        background-image: url(/img/portfolio/type-hover/type3-hover-2.jpg); }
      .portfolio-type3-content .type-portfolio-thumbnail-3 .inner .type-thumbnail-bg3 .bg-thumb.bg-image--3 {
        background-image: url(/img/portfolio/type-hover/type3-hover-3.jpg); }
      .portfolio-type3-content .type-portfolio-thumbnail-3 .inner .type-thumbnail-bg3 .bg-thumb.bg-image--4 {
        background-image: url(/img/portfolio/type-hover/type3-hover-4.jpg); }
    .portfolio-type3-content .type-portfolio-thumbnail-3 .inner .type-thumbnail-bg3.active .bg-thumb {
      z-index: 1;
      opacity: 1; }

/* ========================
Portfolio Details
===========================*/
.bg_image--38 {
  background-image: url(/img/bg/bg-image-38.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed; }

.portfolio-details-list {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 62px 30px; }
  .portfolio-details-list .details-list label {
    color: #222222;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 12px; }
  .portfolio-details-list .details-list span {
    color: #999;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 1.58;
    font-size: 14px;
    display: block; }
    .portfolio-details-list .details-list span a {
      color: #999;
      transition: 0.4s; }
      .portfolio-details-list .details-list span a:hover {
        color: #0038E3; }

/*==========================
Portfolio Nav Page
============================*/
.portfolio-nav-list {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .portfolio-nav-list {
      display: block; } }
  .portfolio-nav-list .portfolio-page .inner a {
    display: block;
    color: #999;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .portfolio-nav-list .portfolio-page .inner a h3 {
      line-height: 1.42;
      margin: 22px 0 0;
      position: relative; }
      .portfolio-nav-list .portfolio-page .inner a h3::before {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 24px;
        font-family: Font Awesome\ 5 Free;
        font-weight: 900; }
    .portfolio-nav-list .portfolio-page .inner a:hover {
      color: #0038E3; }
  .portfolio-nav-list .portfolio-page.prev {
    padding-left: 100px;
    text-align: left; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .portfolio-nav-list .portfolio-page.prev {
        padding-left: 60px; } }
    @media only screen and (max-width: 767px) {
      .portfolio-nav-list .portfolio-page.prev {
        padding-left: 60px; } }
    .portfolio-nav-list .portfolio-page.prev .inner a h3::before {
      left: -100px;
      content: "\f060"; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .portfolio-nav-list .portfolio-page.prev .inner a h3::before {
          left: -60px; } }
      @media only screen and (max-width: 767px) {
        .portfolio-nav-list .portfolio-page.prev .inner a h3::before {
          left: -60px; } }
  .portfolio-nav-list .portfolio-page.next {
    padding-right: 100px;
    text-align: right; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .portfolio-nav-list .portfolio-page.next {
        padding-right: 60px; } }
    @media only screen and (max-width: 767px) {
      .portfolio-nav-list .portfolio-page.next {
        padding-right: 60px; } }
    .portfolio-nav-list .portfolio-page.next .inner a h3::before {
      right: -100px;
      content: "\f061"; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .portfolio-nav-list .portfolio-page.next .inner a h3::before {
          right: -60px; } }
      @media only screen and (max-width: 767px) {
        .portfolio-nav-list .portfolio-page.next .inner a h3::before {
          right: -60px; } }

/*=====================================
	Blog Grid Styles
======================================*/
/* ===========================
    Blog Grid Area 
=============================*/
.blog-grid {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .blog-grid .post-thumb > a {
    overflow: hidden;
    display: block; }
    .blog-grid .post-thumb > a img {
      width: 100%; }
  .blog-grid .post-content {
    position: relative;
    height: 100%;
    min-height: 172px;
    padding: 21px 20px 30px;
    background: #fff;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    .blog-grid .post-content::before {
      -webkit-transition: all .5s;
      transition: all .5s;
      content: "";
      width: 0;
      height: 3px;
      position: absolute;
      left: 50%;
      bottom: 0;
      opacity: 0;
      background-color: #0038E3; }
    .blog-grid .post-content .post-inner .post-categories a {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.38px;
      text-transform: uppercase;
      margin-bottom: 9px;
      display: block; }
    .blog-grid .post-content .post-inner h5.heading {
      line-height: 1.38;
      margin-bottom: 26px;
      color: inherit; }
      .blog-grid .post-content .post-inner h5.heading a {
        color: #222222;
        -webkit-transition: all .5s;
        transition: all .5s; }
        .blog-grid .post-content .post-inner h5.heading a:hover {
          color: #0038E3; }
    .blog-grid .post-content .post-inner h4.heading {
      color: inherit;
      line-height: 1.38;
      margin-bottom: 23px; }
      .blog-grid .post-content .post-inner h4.heading a {
        color: #222222;
        -webkit-transition: all .5s;
        transition: all .5s; }
        .blog-grid .post-content .post-inner h4.heading a:hover {
          color: #0038E3; }
      .blog-grid .post-content .post-inner h4.heading.hover-yellow-color a:hover {
        color: #FCB72B; }
    .blog-grid .post-content .post-inner h3.heading {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.46;
      margin-bottom: 37px; }
      .blog-grid .post-content .post-inner h3.heading a {
        color: #222222;
        -webkit-transition: all .5s;
        transition: all .5s; }
        .blog-grid .post-content .post-inner h3.heading a:hover {
          color: #0038E3; }
    .blog-grid .post-content .post-inner .post-meta.meta-restaurant {
      font-size: 14px;
      font-style: italic;
      color: #999; }
      .blog-grid .post-content .post-inner .post-meta.meta-restaurant .post-date {
        font-size: 14px;
        color: #999; }
      .blog-grid .post-content .post-inner .post-meta.meta-restaurant .post-author a {
        color: #222222; }
    .blog-grid .post-content .post-inner .post-meta.meta-corona {
      font-size: 14px;
      color: #999; }
      .blog-grid .post-content .post-inner .post-meta.meta-corona .post-date {
        font-size: 14px;
        color: #999; }
      .blog-grid .post-content .post-inner .post-meta.meta-corona .post-author a {
        color: #999; }
    .blog-grid .post-content .post-inner .post-meta > div {
      position: relative;
      display: inline; }
    .blog-grid .post-content .post-inner .post-meta .post-date {
      line-height: 1.58;
      font-size: 14px;
      font-weight: 500;
      color: #333333; }
    .blog-grid .post-content .post-inner .post-meta .post-category {
      padding-left: 15px;
      margin-left: 7px;
      line-height: 1.58;
      font-size: 14px;
      font-weight: 500; }
      .blog-grid .post-content .post-inner .post-meta .post-category::before {
        content: "|";
        position: absolute;
        top: 0;
        left: 2px;
        color: #333333; }
      .blog-grid .post-content .post-inner .post-meta .post-category a {
        color: #333333;
        -webkit-transition: all .5s;
        transition: all .5s; }
        .blog-grid .post-content .post-inner .post-meta .post-category a:hover {
          color: #0038E3; }
      .blog-grid .post-content .post-inner .post-meta .post-category.hover-yellow-color a:hover {
        color: #FCB72B; }
  .blog-grid.blog-business-two .post-thumb a img {
    transition: all 0.3s ease-in-out;
    width: 100%; }
  .blog-grid.blog-business-two .post-content {
    padding-left: 0;
    padding-right: 0;
    text-align: left; }
    .blog-grid.blog-business-two .post-content::before {
      display: none; }
    .blog-grid.blog-business-two .post-content .post-inner h3.heading {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.56;
      margin: 9px 0 20px; }
  .blog-grid.blog-business-two:hover {
    transform: translateY(0px); }
    .blog-grid.blog-business-two:hover .post-thumb a img {
      width: 100%;
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1); }
  .blog-grid.bg-transparent .post-content {
    background: transparent; }
  .blog-grid .post-quote-wrapper {
    display: block;
    height: 100%; }
  .blog-grid .post-quote {
    padding: 30px;
    height: 100%;
    color: #fff;
    position: relative;
    z-index: 2; }
    .blog-grid .post-quote .post-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      z-index: -1; }
      .blog-grid .post-quote .post-overlay::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); }
    .blog-grid .post-quote h5.heading a {
      font-size: 18px;
      line-height: 1.38;
      color: #fff; }
    .blog-grid .post-quote h4.heading a {
      font-size: 18px;
      line-height: 1.38;
      color: #fff; }
    .blog-grid .post-quote h6.quote-name {
      font-size: 18px;
      line-height: 1.38;
      color: #fff;
      margin: 0;
      margin-top: 20px; }
    .blog-grid .post-quote .quote-icon {
      margin-top: 95px;
      font-size: 26px;
      text-align: right; }
      .blog-grid .post-quote .quote-icon i {
        color: #0038E3; }
  .blog-grid:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); }
    .blog-grid:hover .post-content::before {
      width: 100%;
      opacity: 1;
      left: 0; }
  .blog-grid.hover-tarkis-color .post-inner h5.heading:hover {
    color: #19D2A8; }
    .blog-grid.hover-tarkis-color .post-inner h5.heading:hover a {
      color: #19D2A8; }
  .blog-grid.hover-tarkis-color .post-inner .post-meta .post-category:hover a {
    color: #19D2A8; }
  .blog-grid.blog-standard {
    overflow: hidden; }
    .blog-grid.blog-standard .post-thumb a img {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%; }
    .blog-grid.blog-standard .post-content {
      padding: 29px 20px 30px;
      padding-bottom: 0;
      min-height: inherit; }
      .blog-grid.blog-standard .post-content::before {
        display: none; }
    .blog-grid.blog-standard.padding-left-none .post-content {
      padding-left: 0; }
    .blog-grid.blog-standard.grid-simple .post-content .post-inner h5.heading {
      margin: 20px 0 0; }
    .blog-grid.blog-standard:hover {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0); }
      .blog-grid.blog-standard:hover .post-thumb a img {
        width: 100%;
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1); }
  .blog-grid .position-bottom {
    padding: 30px 50px 50px;
    width: 100%;
    position: absolute;
    bottom: 0; }
  .blog-grid--modern.blog-standard {
    display: inherit;
    overflow: visible;
    height: auto;
    /* -------------------
            Yellow Color  
            ---------------------*/ }
    .blog-grid--modern.blog-standard .post-thumb {
      overflow: hidden;
      position: relative; }
      .blog-grid--modern.blog-standard .post-thumb::after {
        content: "";
        width: 0;
        height: 4px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        background: #0038E3; }
    .blog-grid--modern.blog-standard .post-content {
      background: #fff;
      text-align: center;
      padding: 31px 30px 67px; }
      .blog-grid--modern.blog-standard .post-content .post-inner h5.heading {
        margin-bottom: 0; }
    .blog-grid--modern.blog-standard .post-read-more {
      position: absolute;
      bottom: -30px;
      left: 50%;
      -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
      transform: translate(-50%);
      font-size: 15px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      border-radius: 50%;
      color: #001029;
      text-align: center;
      background-color: #0038E3;
      color: #899664;
      display: inline-block; }
      .blog-grid--modern.blog-standard .post-read-more::before {
        font-family: Font Awesome\ 5 free;
        font-weight: 900;
        position: absolute;
        content: "\f061";
        left: 50%;
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%); }
    .blog-grid--modern.blog-standard:hover .post-thumb::after {
      width: 100%;
      left: 0;
      right: auto; }
    .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
      background: #FCB72B; }
    .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover {
      color: #FCB72B; }
    .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
      color: #FCB72B; }
    .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
      background-color: #FCB72B;
      color: #001029; }
  .blog-grid.blog-creative .post-content {
    padding: 29px 0 34px;
    background: transparent; }
    .blog-grid.blog-creative .post-content .post-inner h4.heading {
      margin-bottom: 12px; }
  .blog-grid.blog-standard-list .post-content {
    padding: 50px 40px 63px;
    background: #fff; }
    .blog-grid.blog-standard-list .post-content::before {
      display: none; }
    .blog-grid.blog-standard-list .post-content .post-inner .post-meta {
      margin-bottom: 22px;
      padding-bottom: 24px;
      border-bottom: 1px solid #eee; }

/*======================
 Blog Slide Button 
========================*/
.blog-slick-initial .slick-arrow {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  border: 0 none;
  color: #fff;
  font-size: 22px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  visibility: hidden; }
  .blog-slick-initial .slick-arrow:hover {
    color: #222; }

.blog-slick-initial .blog-arrow-next.slick-arrow {
  left: auto;
  right: 50px; }

.blog-slick-initial:hover .slick-arrow {
  opacity: 1;
  visibility: visible; }

.blog-slick-initial .slick-slide img {
  display: inline-block;
  width: 100%; }

/* ==========================
    Brook Pagination 
=============================*/
.brook-pagination-wrapper .brook-pagination {
  padding: 0;
  margin: -5px;
  font-size: 14px;
  font-weight: 500;
  list-style: none; }
  .brook-pagination-wrapper .brook-pagination li {
    display: inline-block;
    list-style-type: none;
    padding: 5px; }
    .brook-pagination-wrapper .brook-pagination li a {
      padding: 0 5px;
      display: block;
      text-align: center;
      line-height: 39px;
      min-width: 41px;
      height: 41px;
      border: 1px solid #eee;
      background: #fff;
      color: #333333;
      transition: 0.4s ease-in; }
      .brook-pagination-wrapper .brook-pagination li a:hover {
        background: #0038E3;
        color: #fff; }
    .brook-pagination-wrapper .brook-pagination li.active a {
      background: #0038E3;
      color: #fff; }

/*============================
    Blog Metro Grid Style 3 
=============================*/
.bk-blog-masonry {
  margin: 0 -15px; }
  .bk-blog-masonry .blog-33-33 {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .bk-blog-masonry .blog-33-33 {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .bk-blog-masonry .blog-33-33 {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .bk-blog-masonry .blog-33-33 {
        width: 100%; } }
  .bk-blog-masonry .resizer {
    width: 33.33%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .bk-blog-masonry .resizer {
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .bk-blog-masonry .resizer {
        width: 50%; } }
    @media only screen and (max-width: 575px) {
      .bk-blog-masonry .resizer {
        width: 100%; } }

.bk-blog-masonry2 .blog-25 {
  width: 25%; }

.bk-blog-masonry2 .grid-width-2 {
  width: 50%; }

.bk-blog-masonry2 .resizer {
  width: 25%; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bk-blog-masonry2 .blog-25 {
    width: 50%; }
  .bk-blog-masonry2 .grid-width-2 {
    width: 50%; }
  .bk-blog-masonry2 .resizer {
    width: 50%; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bk-blog-masonry2 .blog-25 {
    width: 50%; }
  .bk-blog-masonry2 .grid-width-2 {
    width: 50%; }
  .bk-blog-masonry2 .resizer {
    width: 50%; } }

@media only screen and (max-width: 767px) {
  .bk-blog-masonry2 .blog-25 {
    width: 100%; }
  .bk-blog-masonry2 .grid-width-2 {
    width: 100%; }
  .bk-blog-masonry2 .resizer {
    width: 100%; } }

.bk-blog-masonry3 .blog-33-33 {
  width: 33.33%; }

.bk-blog-masonry3 .grid-width-2 {
  width: 66.66%; }

.bk-blog-masonry3 .resizer {
  width: 33.33%; }

/* ==========================
    Blog Grid No Flex 
=============================*/
.blog-grid-no-flex {
  position: relative;
  cursor: pointer; }
  .blog-grid-no-flex .post-thumb {
    overflow: hidden; }
    .blog-grid-no-flex .post-thumb a img {
      -webkit-transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
      transition: all 0.5s cubic-bezier(0.57, 0.21, 0.69, 1);
      width: 100%; }
  .blog-grid-no-flex:hover .post-thumb {
    overflow: hidden; }
    .blog-grid-no-flex:hover .post-thumb a img {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05); }
  .blog-grid-no-flex .post-content .post-inner h5.heading {
    font-size: 24px;
    line-height: 1.375;
    margin-bottom: 0; }
  .blog-grid-no-flex .post-content .post-inner h5.text-white a {
    color: #ffffff; }
  .blog-grid-no-flex .post-content.position-bottom {
    position: absolute;
    bottom: 0;
    padding: 30px 50px 50px;
    width: 100%;
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .blog-grid-no-flex .post-content.position-bottom {
        padding: 18px 20px 28px; } }
  .blog-grid-no-flex.post-overlay {
    z-index: 2; }
    .blog-grid-no-flex.post-overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
      width: 100%;
      height: 100%; }
  .blog-grid-no-flex .post-quote {
    position: absolute;
    top: 50px;
    right: 50px; }
    .blog-grid-no-flex .post-quote::before {
      content: "\f10e";
      position: absolute;
      top: 50px;
      right: 50px;
      font-size: 26px;
      line-height: 22px;
      color: #0038E3;
      font-family: Font Awesome\ 5 Free;
      font-weight: 900; }

/* Post Meta */
.post-meta > div {
  position: relative;
  display: inline-block; }

.post-meta .post-date {
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.58;
  font-size: 14px;
  color: #333333; }

.post-meta .post-category {
  padding-left: 15px;
  margin-left: 7px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.58;
  font-size: 14px; }
  .post-meta .post-category a {
    color: #333333; }
  .post-meta .post-category::before {
    content: "|";
    position: absolute;
    top: 0;
    left: 2px; }

.post-meta.text-white .post-date {
  color: #ffffff; }

.post-meta.text-white .post-category a {
  color: #ffffff !important; }

/* =======================
    Blog Grid Minimal
==========================*/
.bk-blog-grid-area.bg-shape {
  background-image: url(/img/slider/revolution/slider-3.png);
  background-repeat: no-repeat;
  background-position: left top 170px; }

.blog-grid-minimal {
  position: relative;
  height: 100%;
  background-color: #fff; }
  .blog-grid-minimal .grid-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #222;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .blog-grid-minimal .grid-overlay::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2); }
  .blog-grid-minimal .post-content {
    position: relative;
    z-index: 1;
    padding: 44px 40px 56px;
    min-height: 242px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .blog-grid-minimal .post-content {
        padding: 40px 23px 40px; } }
    @media only screen and (max-width: 767px) {
      .blog-grid-minimal .post-content {
        padding: 40px 23px 40px; } }
    .blog-grid-minimal .post-content h5.heading {
      margin-bottom: 21px;
      font-size: 24px;
      line-height: 1.392;
      font-weight: 600;
      color: inherit; }
      .blog-grid-minimal .post-content h5.heading a {
        color: #222222; }
    .blog-grid-minimal .post-content .post-meta .post-category a {
      color: #333333; }
  .blog-grid-minimal.outline {
    position: relative;
    height: 100%;
    background-color: #fff;
    border: 1px solid #ddd; }
    .blog-grid-minimal.outline.bg-transparant {
      background-color: transparent;
      border-color: #0038E3; }
      .blog-grid-minimal.outline.bg-transparant .post-content h5 a {
        color: #fff; }
      .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
        color: #0038E3; }
    .blog-grid-minimal.outline:hover {
      border-color: transparent; }
  .blog-grid-minimal:hover .grid-overlay {
    opacity: 1;
    visibility: visible; }
  .blog-grid-minimal:hover .post-content h5.heading a {
    color: #fff; }
  .blog-grid-minimal:hover .post-content .post-meta .post-date {
    color: #fff; }
  .blog-grid-minimal:hover .post-content .post-meta .post-category a {
    color: #fff !important; }
  .blog-grid-minimal:hover .post-content .post-meta .post-category::before {
    color: #fff; }
  .blog-grid-minimal.minimal-style-2 {
    background-color: transparent; }
    .blog-grid-minimal.minimal-style-2 .post-content {
      position: relative;
      height: 100%;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      min-height: 350px;
      padding: 39px 40px 100px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .blog-grid-minimal.minimal-style-2 .post-content .post-meta {
        color: #222222;
        margin-bottom: 25px; }
        .blog-grid-minimal.minimal-style-2 .post-content .post-meta .post-category a {
          color: #333333; }
      .blog-grid-minimal.minimal-style-2 .post-content .post-author {
        position: absolute;
        bottom: 0;
        left: 40px;
        right: 40px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding: 26px 0; }
    .blog-grid-minimal.minimal-style-2:hover .post-content {
      background: #fff;
      border-color: #fff;
      box-shadow: 0 30px 40px rgba(0, 0, 0, 0.11);
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px); }
      .blog-grid-minimal.minimal-style-2:hover .post-content h5.heading a {
        color: #222222; }
      .blog-grid-minimal.minimal-style-2:hover .post-content .post-meta .post-date {
        color: #222222; }
      .blog-grid-minimal.minimal-style-2:hover .post-content .post-meta .post-category a {
        color: #222222 !important; }
      .blog-grid-minimal.minimal-style-2:hover .post-content .post-meta .post-category::before {
        color: #222222; }
    .blog-grid-minimal.minimal-style-2.text-white .post-content .heading {
      color: #fff; }
      .blog-grid-minimal.minimal-style-2.text-white .post-content .heading a {
        color: #fff; }
    .blog-grid-minimal.minimal-style-2.text-white .post-content .post-meta {
      color: #fff; }
      .blog-grid-minimal.minimal-style-2.text-white .post-content .post-meta .post-category a {
        color: #fff !important; }
      .blog-grid-minimal.minimal-style-2.text-white .post-content .post-meta .post-date {
        color: #fff !important; }
    .blog-grid-minimal.minimal-style-2.text-white .post-content .post-author h6.heading {
      color: #fff; }
    .blog-grid-minimal.minimal-style-2.text-white:hover .post-content {
      background: #fff;
      border-color: #fff; }
      .blog-grid-minimal.minimal-style-2.text-white:hover .post-content .heading a {
        color: #222222; }
      .blog-grid-minimal.minimal-style-2.text-white:hover .post-content .post-meta .post-date {
        color: #222222 !important; }
      .blog-grid-minimal.minimal-style-2.text-white:hover .post-content .post-meta .post-category a {
        color: #222222 !important; }
      .blog-grid-minimal.minimal-style-2.text-white:hover .post-content .post-meta .post-category::before {
        color: #222222; }
      .blog-grid-minimal.minimal-style-2.text-white:hover .post-content .post-author {
        border-color: #eee; }
        .blog-grid-minimal.minimal-style-2.text-white:hover .post-content .post-author h6.heading {
          color: #222222; }
  .blog-grid-minimal.overlay-image-list {
    position: relative;
    z-index: 1;
    padding: 55px 90px 55px 40px;
    border-top: 1px solid #eee; }
    @media only screen and (max-width: 767px) {
      .blog-grid-minimal.overlay-image-list {
        padding: 46px 51px 34px 10px; } }
    .blog-grid-minimal.overlay-image-list .post-content {
      padding: 0;
      min-height: auto; }
      .blog-grid-minimal.overlay-image-list .post-content .post-arrow {
        position: absolute;
        right: -26px;
        top: 4px; }
        .blog-grid-minimal.overlay-image-list .post-content .post-arrow span {
          font-size: 30px;
          color: #222222; }
    .blog-grid-minimal.overlay-image-list:hover .post-content .post-arrow span {
      color: #ffffff; }

.brook-pagination-wrapper .load-btn a i {
  color: #222222;
  font-size: 50px; }

.brook-pagination-wrapper .load-more-button a {
  font-size: 14px;
  font-weight: 500;
  color: #191970; }
  .brook-pagination-wrapper .load-more-button a i {
    margin-left: 15px;
    color: #0038E3; }

/*=========================
Blog Details 
============================*/
.blog-tag-list a {
  color: #999999;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 500;
  line-height: 1.58;
  font-size: 14px;
  position: relative;
  padding-left: 5px; }
  .blog-tag-list a::before {
    position: absolute;
    content: ",";
    left: 0; }
  .blog-tag-list a:first-child::before {
    display: none; }
  .blog-tag-list a:hover {
    color: #0038E3; }

.post-nav-lisnt {
  margin-top: 47px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .post-nav-lisnt {
      display: block; } }
  .post-nav-lisnt .nav-item {
    max-width: 48%;
    width: 360px;
    text-align: left; }
    @media only screen and (max-width: 767px) {
      .post-nav-lisnt .nav-item {
        max-width: 100%; } }
    .post-nav-lisnt .nav-item a {
      display: block;
      padding: 47px 30px 57px;
      height: 100%;
      border: 1px solid #eee;
      background: #fff;
      font-size: 24px;
      line-height: 1.375;
      font-weight: 600;
      color: #222;
      word-wrap: break-word;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      @media only screen and (max-width: 767px) {
        .post-nav-lisnt .nav-item a {
          font-size: 17px;
          line-height: 1.5; } }
      .post-nav-lisnt .nav-item a .link-text {
        position: relative;
        font-size: 14px;
        font-weight: 500;
        color: #999;
        margin-bottom: 28px; }
        .post-nav-lisnt .nav-item a .link-text span {
          left: -61px;
          position: absolute;
          top: -3px;
          font-size: 24px;
          color: #222; }
      .post-nav-lisnt .nav-item a:hover {
        background: #0038E3;
        color: #fff; }
        .post-nav-lisnt .nav-item a:hover .link-text {
          color: #ffffff; }
          .post-nav-lisnt .nav-item a:hover .link-text span {
            color: #ffffff; }
    .post-nav-lisnt .nav-item.next {
      text-align: right; }
      .post-nav-lisnt .nav-item.next a {
        padding-right: 90px; }
        .post-nav-lisnt .nav-item.next a .link-text span {
          right: -61px;
          left: auto; }
    .post-nav-lisnt .nav-item.previous a {
      padding-left: 90px; }

/* ========================
Start Comment Area 
==========================*/
.blog-details-wrapper .audio-post iframe {
  min-height: 200px;
  width: 100%;
  border: medium none;
  vertical-align: middle; }

.comments-wrapper {
  border: 1px solid #eee;
  background: #fff;
  padding: 40px 65px 40px 35px;
  margin-bottom: 70px; }

.commnent-list-wrap .comment {
  list-style-type: none;
  margin-top: 48px;
  padding-top: 37px;
  padding-left: 6px;
  border-top: 1px solid #eee; }
  .commnent-list-wrap .comment .thumb {
    float: left; }
    @media only screen and (max-width: 767px) {
      .commnent-list-wrap .comment .thumb {
        float: none; } }
    .commnent-list-wrap .comment .thumb img {
      border-radius: 50%; }
  .commnent-list-wrap .comment .content {
    position: relative;
    overflow: hidden;
    margin-left: 152px; }
    @media only screen and (max-width: 767px) {
      .commnent-list-wrap .comment .content {
        margin-left: 0;
        margin-top: 30px; } }
    .commnent-list-wrap .comment .content .comment-footer span {
      font-size: 12px;
      font-weight: 500;
      display: inline-block; }
      .commnent-list-wrap .comment .content .comment-footer span.reply-btn {
        padding-left: 5px; }
        .commnent-list-wrap .comment .content .comment-footer span.reply-btn a {
          color: #333333;
          transition: 0.4s; }
          .commnent-list-wrap .comment .content .comment-footer span.reply-btn a:hover {
            color: #0038E3; }
  .commnent-list-wrap .comment.comment-reply {
    padding-left: 61px; }
    @media only screen and (max-width: 767px) {
      .commnent-list-wrap .comment.comment-reply {
        padding-left: 30px; } }
    @media only screen and (max-width: 575px) {
      .commnent-list-wrap .comment.comment-reply {
        padding-left: 0; } }

/* ====================
    Contact Form 
======================*/
.comment-form-wrapper .contact-form input {
  height: 50px; }

.comment-form-wrapper .contact-form .textarea {
  height: 180px; }

/* ====================
Blog Main Quote 
======================*/
.blog-main-quote {
  position: relative;
  padding: 48px 40px;
  text-align: center; }

/*===================================
 Post Return Button 
 ==================================*/
.blog-post-return-button a {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #999999;
  line-height: 1.58;
  font-size: 14px;
  font-weight: 500; }
  .blog-post-return-button a i {
    font-size: 12px;
    color: #222;
    margin-right: 6px; }
  .blog-post-return-button a:hover {
    color: #0038E3; }

/*===================================
    Modern Pagination
 ==================================*/
.blog-modern-pagination .modern-pagination a {
  color: #ffffff;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .blog-modern-pagination .modern-pagination a:hover {
    color: #0038E3; }
  .blog-modern-pagination .modern-pagination a .nav-icon {
    font-size: 24px; }

.blog-modern-layout iframe {
  width: 100%; }

/* ========================
    Blog Sidebar 
===========================*/
.blog-sidebar-wrapper {
  border: 1px solid #eee;
  padding: 50px 29px 66px;
  background: #fff; }

.blog-sidebar-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .blog-sidebar-container {
      padding-left: 0;
      padding-right: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog-sidebar-container {
      padding-left: 0;
      padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .blog-sidebar-container {
      padding-left: 0;
      padding-right: 0; } }

.bl-sidebar .widget-title {
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 11px;
  border-bottom: 1px solid #eee;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.23;
  color: #222222; }

.bl-sidebar.search .search-box {
  position: relative; }
  .bl-sidebar.search .search-box input {
    width: 100%;
    padding-right: 72px;
    color: #777;
    border-color: #eee;
    background-color: #fff;
    outline: none;
    border: 1px solid #eee;
    border-radius: 0;
    padding: 3px 20px;
    height: 62px;
    padding-right: 60px; }
  .bl-sidebar.search .search-box button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    width: 62px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    background: none;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
    .bl-sidebar.search .search-box button:hover {
      background-color: #0038E3; }
    .bl-sidebar.search .search-box button span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      line-height: 1;
      font-size: 18px;
      color: #0038E3; }
    .bl-sidebar.search .search-box button:hover span {
      color: #fff; }

.bl-sidebar.instagram .instagram-grid-wrap.instagram-grid-5 {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px; }

.bl-sidebar.category .category-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .bl-sidebar.category .category-list li a {
    display: block;
    padding: 12px 0;
    color: #333333;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-weight: 500;
    line-height: 1.58;
    font-size: 14px; }
    .bl-sidebar.category .category-list li a:hover {
      color: #0038E3; }

.bl-sidebar.banner .inner {
  position: relative; }
  .bl-sidebar.banner .inner .content {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }

.bl-sidebar.archive .inner select {
  letter-spacing: 0em;
  color: #777;
  border-color: #eee;
  background-color: #fff;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 0;
  padding: 3px 20px;
  height: 62px; }

.bl-sidebar.tag .inner .tagcloud {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .bl-sidebar.tag .inner .tagcloud li {
    display: inline-block; }
    .bl-sidebar.tag .inner .tagcloud li a {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      display: block;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
      color: #333333; }
      .bl-sidebar.tag .inner .tagcloud li a:hover {
        color: #0038E3; }

/*====================================
	Shop Sidebar Styles
=====================================*/
/*=====================
Shop Sidebar
=======================*/
.archive-shop-inner select {
  height: 50px;
  color: #777;
  border-color: #eee;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
  border: 1px solid #eee;
  border-radius: 0;
  padding: 3px 20px;
  max-width: 100%;
  background: #ffffff url(/img/payment/download.png) no-repeat center right 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 215px; }

.shop-sidebar-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-sidebar-container {
      padding-left: 0;
      padding-right: 0; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shop-sidebar-container {
      padding-left: 0;
      padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .shop-sidebar-container {
      padding-left: 0;
      padding-right: 0; } }
  .shop-sidebar-container .shop-sidebar-wrapper {
    border: 1px solid #eee;
    padding: 50px 29px 66px;
    background: #fff; }

.shop-sidebar .widget-title {
  font-size: 18px;
  margin-bottom: 20px;
  padding-bottom: 11px;
  border-bottom: 1px solid #eee;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.23;
  color: #222222; }

.shop-sidebar.search .search-box {
  position: relative; }
  .shop-sidebar.search .search-box input {
    width: 100%;
    padding-right: 72px;
    color: #777;
    border-color: #eee;
    background-color: #fff;
    outline: none;
    border: 1px solid #eee;
    border-radius: 0;
    padding: 3px 20px;
    height: 62px; }
  .shop-sidebar.search .search-box button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    width: 62px;
    height: 62px;
    line-height: 62px;
    text-align: center;
    background: none;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
    .shop-sidebar.search .search-box button:hover {
      background-color: #0038E3; }
    .shop-sidebar.search .search-box button span {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      line-height: 1;
      font-size: 18px;
      color: #0038E3; }
    .shop-sidebar.search .search-box button:hover span {
      color: #fff; }

.shop-sidebar.search.color-green .search-box button:hover {
  background-color: #899664; }

.shop-sidebar.search.color-green .search-box button span {
  color: #899664; }

.shop-sidebar.search.color-green .search-box button:hover span {
  color: #fff; }

.shop-sidebar.related-product-inner .related-product {
  padding: 0;
  margin: 0;
  list-style: none; }
  .shop-sidebar.related-product-inner .related-product li {
    margin-top: 20px; }
    .shop-sidebar.related-product-inner .related-product li .product-item {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
      .shop-sidebar.related-product-inner .related-product li .product-item .thumbnail {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 15px;
        width: 100px; }
        .shop-sidebar.related-product-inner .related-product li .product-item .thumbnail a img {
          width: 100%;
          border-radius: 3px; }
      .shop-sidebar.related-product-inner .related-product li .product-item .info h5.heading {
        margin: 0 0 12px;
        line-height: 1.4; }
        .shop-sidebar.related-product-inner .related-product li .product-item .info h5.heading a {
          color: #222222; }
      .shop-sidebar.related-product-inner .related-product li .product-item .info ul.rating {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        margin-bottom: 10px;
        font-size: 12px; }
        .shop-sidebar.related-product-inner .related-product li .product-item .info ul.rating li i {
          color: #ffb805; }
      .shop-sidebar.related-product-inner .related-product li .product-item .info .price span {
        font-weight: 500;
        font-size: 15px;
        display: inline-block;
        color: #333; }
        .shop-sidebar.related-product-inner .related-product li .product-item .info .price span.new-price {
          color: #d5382c; }

.shop-sidebar.instagram .instagram-grid-wrap.instagram-grid-5 {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px; }

.shop-sidebar.banner .inner {
  position: relative; }
  .shop-sidebar.banner .inner .content {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%); }

.shop-sidebar.tag .inner .tagcloud {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .shop-sidebar.tag .inner .tagcloud li {
    display: inline-block; }
    .shop-sidebar.tag .inner .tagcloud li a {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      display: block;
      font-size: 14px;
      font-weight: 500;
      padding: 10px;
      color: #333333; }
      .shop-sidebar.tag .inner .tagcloud li a:hover {
        color: #0038E3; }

.shop-sidebar.tag.color-green .inner .tagcloud li a:hover {
  color: #899664; }

.content-shopby {
  margin-top: 32px; }

.price--output input {
  border: 0 none; }

.price--output span {
  color: #333;
  font-size: 14px; }

.price--filter {
  display: inline-block; }

.price--output {
  display: inline-block; }

.price--filter a {
  background: #0038E3 none repeat scroll 0 0;
  border-radius: 3px;
  color: #fff;
  display: block;
  font-size: 1.15em;
  height: 45px;
  line-height: 44px;
  padding: 0 24px; }

.ui-widget-content {
  background: #ededed none repeat scroll 0 0;
  border: 0 none;
  color: #0038E3; }

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: #0038E3 none repeat scroll 0 0; }

.ui-slider-horizontal {
  height: 7px; }

.ui-slider .ui-slider-handle {
  border-radius: 100%;
  cursor: default;
  height: 16px;
  position: absolute;
  touch-action: none;
  width: 16px;
  z-index: 2;
  outline: none;
  cursor: pointer; }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #0038E3 none repeat scroll 0 0;
  border: 0 none;
  color: #0038E3; }

.ui-slider-range.ui-widget-header.ui-corner-all {
  background: #0038E3 none repeat scroll 0 0; }

.ui-slider-horizontal {
  margin-bottom: 25px;
  margin-top: 7px; }

.price__output--wrap {
  display: flex;
  align-items: center; }
  .price__output--wrap .price--output {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    .price__output--wrap .price--output span {
      color: #333;
      font-size: 19px;
      display: inline-block; }
    .price__output--wrap .price--output input {
      border: 0 none;
      width: 94px;
      flex-grow: 1;
      font-size: 16px;
      font-weight: 500; }
  .price__output--wrap .price--filter {
    display: inline-block; }

/*------------------------------
    Single Product Styles
-------------------------------*/
/* Next Prev Button */
.nextprev-btn {
  margin-bottom: 29px; }
  .nextprev-btn .navlist {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .nextprev-btn .navlist .navitem {
      width: 50%; }
      .nextprev-btn .navlist .navitem a {
        display: inline-block;
        position: relative;
        color: #999999;
        font-size: 14px; }
        .nextprev-btn .navlist .navitem a.text-white::before {
          color: #fff; }
        .nextprev-btn .navlist .navitem a::before {
          position: absolute;
          top: 1px;
          font-size: 12px;
          color: #222;
          font-family: Font Awesome\ 5 Free;
          font-weight: 900; }
      .nextprev-btn .navlist .navitem.prev a {
        padding-left: 22px; }
        .nextprev-btn .navlist .navitem.prev a::before {
          content: "\f060";
          left: 0; }
      .nextprev-btn .navlist .navitem.next {
        text-align: right; }
        .nextprev-btn .navlist .navitem.next a {
          padding-right: 22px; }
          .nextprev-btn .navlist .navitem.next a::before {
            content: "\f061";
            right: 0; }

.single-product-details-side {
  margin-bottom: 40px;
  width: 100% !important;
  max-width: 440px;
  margin-left: 100px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-product-details-side {
      margin-left: 60px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-product-details-side {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .single-product-details-side {
      margin-left: 0; } }
  .single-product-details-side .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 24px; }
    .single-product-details-side .header .product-badges {
      position: static;
      display: inline-block;
      margin: 0 15px 0 0;
      z-index: 0; }
      .single-product-details-side .header .product-badges span {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin: 0 2px;
        min-height: 0;
        min-width: 0;
        border-radius: 10px 10px 10px 0;
        color: #fff;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
        line-height: 1.44;
        text-transform: uppercase;
        background: #d31129;
        padding: 4px 11px;
        color: #fff;
        background-color: #d31129; }
  .single-product-details-side .price {
    margin-bottom: 42px; }
    .single-product-details-side .price p {
      font-size: 18px;
      font-weight: 600; }
  .single-product-details-side .description {
    margin-bottom: 44px; }
  .single-product-details-side .quenty-container {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    padding: 46px 0 49px;
    margin: 41px 0 0;
    margin-bottom: 40px; }
  .single-product-details-side .quenty-button {
    overflow: hidden;
    margin-bottom: 40px; }
    .single-product-details-side .quenty-button h6.heading {
      float: left;
      margin: 9px 15px 0 0;
      text-transform: uppercase;
      min-width: 100px; }
    .single-product-details-side .quenty-button input {
      display: inline-block;
      float: left;
      margin: 0 30px 0 0;
      overflow: hidden;
      position: relative;
      width: 66px; }
  .single-product-details-side .product-action {
    display: flex;
    margin: 0 -10px; }
    .single-product-details-side .product-action a {
      color: #ffffff;
      border-color: #0038E3;
      background-color: #0038E3;
      position: relative;
      display: inline-block;
      margin: 0 10px;
      border-radius: 6px; }
      .single-product-details-side .product-action a.addtocart {
        padding: 0 30px 0;
        height: 53px;
        font-weight: 600;
        line-height: 52px;
        font-size: 16px; }
        .single-product-details-side .product-action a.addtocart i {
          margin-right: 19px; }
      .single-product-details-side .product-action a.wislist {
        position: relative;
        display: block;
        width: 55px;
        height: 55px;
        line-height: 53px;
        border-radius: 6px;
        color: #fff;
        font-size: 0;
        padding: 0;
        border: 1px solid #fff;
        text-align: center; }
        .single-product-details-side .product-action a.wislist::before {
          content: "\f004";
          font-size: 20px;
          margin: 0;
          font-family: Font Awesome\ 5 Free;
          font-weight: 400; }
  .single-product-details-side .product-meta .meta-item {
    display: flex;
    align-items: center; }
    .single-product-details-side .product-meta .meta-item h6 {
      font-size: 14px;
      text-transform: uppercase;
      min-width: 100px;
      margin: 0 15px 0 0; }
    .single-product-details-side .product-meta .meta-item .item-list a {
      color: #999999;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 14px;
      font-weight: 500; }
    .single-product-details-side .product-meta .meta-item + .meta-item {
      margin-top: 24px; }

.active_thumb img {
  width: 100%; }

.single_product_inner h4 {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 6px; }

.single_product_inner .price span {
  font-size: 16px; }
  .single_product_inner .price span.new {
    border-bottom: 1px solid #222222;
    color: #0038E3;
    display: inline-block;
    font-size: 24px;
    margin-right: 3px;
    font-weight: 600; }

.single_product_inner .rating {
  display: flex;
  list-style: outside none none;
  margin-bottom: 24px;
  margin-top: 8px;
  padding: 0; }
  .single_product_inner .rating li {
    margin-right: 2px; }
    .single_product_inner .rating li i {
      color: #222222; }

.single_product_inner .pro_dtl p {
  margin-bottom: 25px; }

.single_product_inner .product_cart_action {
  display: flex;
  margin-top: 36px; }
  .single_product_inner .product_cart_action .cart_qun_inner {
    align-items: center;
    border: 1px solid #dddddd;
    display: flex;
    height: 38px;
    justify-content: center;
    line-height: 35px;
    padding: 0 20px;
    position: relative;
    margin-left: 30px; }
    .single_product_inner .product_cart_action .cart_qun_inner .pro-quantity .pro-qty span.qtybtn {
      float: left;
      padding: 0; }
    .single_product_inner .product_cart_action .cart_qun_inner .pro-quantity input {
      background-color: transparent;
      border: medium none;
      float: left;
      height: 33px;
      line-height: 33px;
      padding: 0;
      text-align: center;
      width: 28px; }
  .single_product_inner .product_cart_action .cart_list {
    display: flex;
    justify-content: center;
    list-style: outside none none;
    margin: 0 -2px;
    padding: 0; }
    .single_product_inner .product_cart_action .cart_list li {
      margin: 0 2px; }
      .single_product_inner .product_cart_action .cart_list li a {
        background: #222222 none repeat scroll 0 0;
        color: #fff;
        display: block;
        font-size: 14px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        transition: all 0.5s ease 0s;
        width: 36px; }
        .single_product_inner .product_cart_action .cart_list li a:hover {
          opacity: 0.7; }
      .single_product_inner .product_cart_action .cart_list li.shopping_basket a {
        padding: 0 16px;
        text-transform: uppercase;
        width: auto; }

.big_img_container .tab-pane:not(.active) {
  display: none; }

.sm_roduct_nav {
  border: 0 none;
  margin: 0 -8px; }
  .sm_roduct_nav a {
    display: block;
    flex-basis: 20%;
    padding: 16px 8px 0; }
    .sm_roduct_nav a img {
      width: 100%; }

.product_share {
  align-items: center;
  display: flex;
  margin-top: 60px; }
  .product_share ul.social_share {
    display: flex;
    list-style: outside none none;
    margin: 0;
    padding: 0; }
    .product_share ul.social_share li {
      margin-left: 15px; }
      .product_share ul.social_share li a {
        color: #fff;
        display: block;
        font-size: 15px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        transition: all 0.5s ease 0s;
        width: 30px; }

.cart_qun_inner span {
  color: #050505;
  font-size: 16px;
  padding-right: 12px; }

.cart_qun_inner .cart-plus-minus {
  position: relative; }
  .cart_qun_inner .cart-plus-minus input {
    border: 0 none;
    text-align: center;
    width: 70px;
    outline: none; }

.single_review_content.tab-pane:not(.active) {
  display: none; }

.product_review {
  padding-top: 100px; }
  .product_review .description_nav {
    border: 0 none; }
    .product_review .description_nav a {
      border-bottom: 2px solid transparent;
      padding: 13px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: #222222; }
      .product_review .description_nav a.active, .product_review .description_nav a:hover {
        border-bottom: 3px solid #0038E3;
        color: #000000; }
      .product_review .description_nav a + a {
        margin-left: 20px; }
  .product_review .single_review_content {
    border-top: 1px solid #f5f5f5;
    margin-top: 14px;
    padding: 40px;
    padding-left: 0; }
    .product_review .single_review_content p {
      font-size: 15px;
      line-height: 30px;
      margin: 0 auto;
      text-align: left;
      padding-left: 0; }
    .product_review .single_review_content .rating {
      display: flex;
      list-style: outside none none;
      margin-bottom: 24px;
      margin-top: 8px;
      padding: 0; }
      .product_review .single_review_content .rating li {
        margin-right: 3px; }
        .product_review .single_review_content .rating li i {
          font-size: 16px;
          color: #efc71f; }
    .product_review .single_review_content .classs__review__inner h4 {
      color: #000;
      font-weight: 500; }
    .product_review .single_review_content .classs__review__inner p {
      margin-bottom: 10px;
      margin-top: 5px;
      padding: 0;
      text-align: left; }
    .product_review .single_review_content .classs__review__inner span {
      font-weight: 400; }
    .product_review .single_review_content .classs__review__inner .input__box span {
      display: block;
      font-weight: 400;
      margin-bottom: 10px;
      text-transform: capitalize;
      font-size: 14px; }
    .product_review .single_review_content .classs__review__inner .input__box input {
      border: 1px solid #eeeeee;
      border-radius: 5px;
      height: 50px;
      outline: medium none;
      width: 100%; }
    .product_review .single_review_content .classs__review__inner .input__box textarea {
      background: #fff none repeat scroll 0 0;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      min-height: 200px;
      padding: 10px;
      width: 100%; }
    .product_review .single_review_content .classs__review__inner .input__box + .input__box {
      margin-top: 15px; }
    .product_review .single_review_content .classs__review__inner .submit__btn a {
      background-color: #252525;
      color: #ffffff;
      display: inline-block;
      font-weight: 600;
      height: 45px;
      line-height: 45px;
      margin-top: 30px;
      padding: 0 20px;
      transition: all 0.3s ease-in-out 0s; }
      .product_review .single_review_content .classs__review__inner .submit__btn a:hover {
        opacity: 0.7; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .big_img_container .big_img img {
    width: 100%; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .big_img_container .big_img img {
    width: 100%; }
  .sm_roduct_nav a {
    flex-basis: 20%; }
  .single_product_inner .pro_ration {
    margin-bottom: 11px; }
  .single_product_inner .pro_dtl p {
    margin-bottom: 10px; }
  .product_share {
    margin-top: 29px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .big_img_container .big_img img {
    width: 100%; }
  .single_product_inner {
    margin-top: 30px; }
  .product_review .description_nav a {
    font-size: 20px; } }

@media only screen and (max-width: 767px) {
  .big_img_container .big_img img {
    width: 100%; }
  .single_product_inner {
    margin-top: 30px; }
  .product_review .description_nav a {
    font-size: 16px; } }

@media only screen and (max-width: 575px) {
  .product_review .description_nav a {
    padding: 0 9px; }
  .single_product_inner .product_cart_action {
    display: block; }
  .single_product_inner .product_cart_action .cart_qun_inner {
    margin-top: 30px; } }

@media only screen and (max-width: 479px) {
  .product_review .description_nav a {
    border-left: 1px solid transparent;
    margin-bottom: 15px;
    display: inline-block; }
  .product_review .description_nav {
    border-color: #ecefea #ecefea #efefef;
    border-style: solid;
    border-width: 1px;
    padding: 10px 0; }
  .product_review .description_nav a:last-child {
    border-right: 1px solid transparent; }
  .sm_roduct_nav a {
    flex-basis: 33.33%; }
  .product_review .single_review_content {
    padding: 26px 9px; }
  .product_review .single_review_content p {
    font-size: 14px;
    line-height: 25px;
    padding: 0 10px; } }

/*---------------------------------------
    11. Cart
-----------------------------------------*/
/*-- Cart Table --*/
.cart-table .table {
  border-bottom: 8px solid #f6f6f6;
  margin: 0; }
  @media only screen and (max-width: 575px) {
    .cart-table .table {
      border-top: 8px solid #f6f6f6; } }
  .cart-table .table thead {
    background-color: #f6f6f6; }
    @media only screen and (max-width: 575px) {
      .cart-table .table thead {
        display: none; } }
    .cart-table .table thead tr th {
      text-align: center;
      border: none;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
      color: #222222;
      padding: 12px 20px; }
  .cart-table .table tbody tr td {
    text-align: center;
    border: none;
    padding: 25px 20px;
    vertical-align: middle;
    border-bottom: 1px solid #dddddd; }
    @media only screen and (max-width: 575px) {
      .cart-table .table tbody tr td {
        display: block;
        width: 100%;
        max-width: none;
        padding: 15px;
        text-align: left; } }

.cart-table th.pro-thumbnail,
.cart-table td.pro-thumbnail {
  max-width: 160px;
  min-width: 120px;
  width: 160px; }
  @media only screen and (max-width: 575px) {
    .cart-table th.pro-thumbnail a,
    .cart-table td.pro-thumbnail a {
      width: 160px; } }

.cart-table th.pro-title,
.cart-table td.pro-title {
  min-width: 200px; }

.cart-table td.pro-thumbnail a {
  display: block; }
  .cart-table td.pro-thumbnail a img {
    width: 100%;
    background-color: #f6f7f8; }

.cart-table td.pro-title a {
  font-size: 16px;
  font-weight: 600;
  color: #222222; }

.cart-table td.pro-price span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #333333; }

.cart-table td.pro-quantity .pro-qty {
  width: 90px;
  height: 35px;
  border: 1px solid #999999;
  border-radius: 50px;
  padding: 0 15px;
  display: block;
  margin: auto; }
  .cart-table td.pro-quantity .pro-qty .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 31px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #333333; }
  .cart-table td.pro-quantity .pro-qty input {
    width: 28px;
    float: left;
    border: none;
    height: 33px;
    line-height: 33px;
    padding: 0;
    text-align: center;
    background-color: transparent; }
  @media only screen and (max-width: 575px) {
    .cart-table td.pro-quantity .pro-qty {
      margin: 0; } }

.cart-table td.pro-subtotal span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #0038E3; }

.cart-table td.pro-addtocart button {
  width: 140px;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #0038E3;
  line-height: 24px;
  padding: 5px 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff;
  background-color: #0038E3; }

.cart-table td.pro-remove a {
  display: block;
  font-weight: 600;
  color: #222222; }
  .cart-table td.pro-remove a i {
    font-size: 24px; }
  .cart-table td.pro-remove a:hover {
    color: #ff0000; }
  @media only screen and (max-width: 575px) {
    .cart-table td.pro-remove a {
      width: 60px;
      text-align: center; } }

/*-- Calculate Shipping --*/
.calculate-shipping {
  margin-bottom: 23px; }
  .calculate-shipping h4 {
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 30px; }
  .calculate-shipping form .nice-select {
    width: 100%;
    border-radius: 50px;
    height: 36px;
    border: 1px solid #999999;
    line-height: 24px;
    padding: 5px 20px;
    background-color: transparent; }
    .calculate-shipping form .nice-select::after {
      border-color: #333333; }
    .calculate-shipping form .nice-select .current {
      display: block;
      line-height: 24px;
      font-size: 14px;
      color: #333333; }
  .calculate-shipping form input {
    width: 100%;
    border-radius: 50px;
    height: 36px;
    border: 1px solid #999999;
    line-height: 24px;
    padding: 5px 20px;
    color: #333333;
    background-color: transparent; }
    .calculate-shipping form input[type="submit"] {
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      background-color: #0038E3;
      border-color: #0038E3;
      width: 140px; }

/*-- Discount Coupon --*/
.discount-coupon h4 {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px; }

.discount-coupon form input {
  width: 100%;
  border-radius: 50px;
  height: 36px;
  border: 1px solid #999999;
  line-height: 24px;
  padding: 5px 20px;
  color: #333333;
  background-color: transparent; }
  .discount-coupon form input[type="submit"] {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    background-color: #0038E3;
    border-color: #0038E3;
    width: 140px; }

/*-- Cart Summary --*/
.cart-summary {
  float: right;
  max-width: 410px;
  width: 100%;
  margin-left: auto; }
  @media only screen and (max-width: 767px) {
    .cart-summary {
      margin-left: 0; } }
  @media only screen and (max-width: 575px) {
    .cart-summary {
      margin-left: 0; } }
  .cart-summary .cart-summary-wrap {
    background-color: #f6f6f6;
    padding: 45px 50px;
    margin-bottom: 20px; }
    @media only screen and (max-width: 479px) {
      .cart-summary .cart-summary-wrap {
        padding: 25px 30px; } }
    .cart-summary .cart-summary-wrap h4 {
      font-size: 20px;
      line-height: 23px;
      text-decoration: underline;
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 30px; }
    .cart-summary .cart-summary-wrap p {
      font-size: 14px;
      font-weight: 600;
      line-height: 23px;
      color: #222222; }
      .cart-summary .cart-summary-wrap p span {
        float: right; }
    .cart-summary .cart-summary-wrap h2 {
      border-top: 1px solid #999999;
      padding-top: 9px;
      font-size: 18px;
      line-height: 23px;
      font-weight: 700;
      color: #000;
      margin: 0; }
      .cart-summary .cart-summary-wrap h2 span {
        float: right; }
  .cart-summary .cart-summary-button {
    overflow: hidden;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .cart-summary .cart-summary-button {
        display: flex;
        justify-content: flex-start; } }
    @media only screen and (max-width: 575px) {
      .cart-summary .cart-summary-button {
        display: flex;
        justify-content: flex-start; } }
    .cart-summary .cart-summary-button button {
      margin-top: 10px;
      width: 140px;
      border-radius: 50px;
      height: 36px;
      border: 1px solid #999999;
      line-height: 24px;
      padding: 5px 20px;
      color: #333333;
      background-color: transparent;
      margin-left: 20px;
      float: right; }
      .cart-summary .cart-summary-button button:last-child {
        margin-left: 0; }
      .cart-summary .cart-summary-button button.checkout-btn {
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        background-color: #0038E3;
        border-color: #0038E3; }
      @media only screen and (max-width: 767px) {
        .cart-summary .cart-summary-button button {
          margin-left: 0;
          margin-right: 20px; }
          .cart-summary .cart-summary-button button:last-child {
            margin-right: 0; } }
      @media only screen and (max-width: 575px) {
        .cart-summary .cart-summary-button button {
          margin-left: 0;
          margin-right: 10px; }
          .cart-summary .cart-summary-button button:last-child {
            margin-right: 0; } }
      @media only screen and (max-width: 479px) {
        .cart-summary .cart-summary-button button {
          width: 130px; } }

/*---------------------------------------
    13. Checkout
-----------------------------------------*/
/*-- Checkout Title --*/
.checkout-title {
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px; }

/*-- Checkout Form --*/
.checkout-form label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: #222222; }

.checkout-form .nice-select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 50px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  height: 45px;
  color: #333333;
  margin-bottom: 15px; }
  .checkout-form .nice-select::after {
    width: 6px;
    height: 6px;
    border-width: 1px;
    right: 20px;
    border-color: #333333; }
  .checkout-form .nice-select .current {
    color: #333333;
    display: block;
    line-height: 23px; }
  .checkout-form .nice-select .list {
    width: 100%; }

.checkout-form input {
  width: 100%;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 50px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px; }
  .checkout-form input[type="checkbox"] {
    width: auto; }

.checkout-form .check-box {
  float: left;
  margin-right: 70px; }
  .checkout-form .check-box:last-child {
    margin-right: 0; }
  .checkout-form .check-box input[type="checkbox"] {
    display: none; }
    .checkout-form .check-box input[type="checkbox"] + label {
      position: relative;
      padding-left: 30px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      margin: 0; }
      .checkout-form .check-box input[type="checkbox"] + label::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        display: block;
        border: 2px solid #999999;
        content: "";
        transition: all 0.3s ease 0s; }
      .checkout-form .check-box input[type="checkbox"] + label::after {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 12px;
        line-height: 20px;
        opacity: 0;
        color: #222222;
        width: 20px;
        text-align: center;
        transition: all 0.3s ease 0s; }
      @media only screen and (max-width: 575px) {
        .checkout-form .check-box input[type="checkbox"] + label {
          margin-top: 20px; } }
    .checkout-form .check-box input[type="checkbox"]:checked + label::before {
      border: 2px solid #222222; }
    .checkout-form .check-box input[type="checkbox"]:checked + label::after {
      opacity: 1; }

/*-- Shipping Form --*/
#shipping-form {
  display: none; }

/*-- Checkout Cart Total --*/
.checkout-cart-total {
  background-color: #f6f6f6;
  padding: 45px; }
  @media only screen and (max-width: 479px) {
    .checkout-cart-total {
      padding: 30px 19px; } }
  .checkout-cart-total h4 {
    flex-basis: 18px;
    line-height: 23px;
    font-weight: 700; }
    .checkout-cart-total h4:first-child {
      margin-top: 0;
      margin-bottom: 25px; }
    .checkout-cart-total h4:last-child {
      margin-top: 15px;
      margin-bottom: 0; }
    .checkout-cart-total h4 span {
      float: right;
      display: block; }
  .checkout-cart-total ul {
    border-bottom: 1px solid #999999;
    padding: 0;
    margin: 0; }
    .checkout-cart-total ul li {
      color: #333333;
      font-size: 14px;
      line-height: 23px;
      font-weight: 600;
      display: block;
      margin-bottom: 16px; }
      .checkout-cart-total ul li span {
        color: #222222;
        float: right; }
  .checkout-cart-total p {
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
    color: #505050;
    padding: 10px 0;
    border-bottom: 1px solid #999999;
    margin: 0; }
    .checkout-cart-total p span {
      float: right; }

/*-- Checkout Payment Method --*/
.checkout-payment-method {
  background-color: #f6f6f6;
  padding: 45px; }
  @media only screen and (max-width: 479px) {
    .checkout-payment-method {
      padding: 30px; } }

/*-- Single Payment Method --*/
.single-method {
  margin-bottom: 20px; }
  .single-method:last-child {
    margin-bottom: 0; }
  .single-method input[type="radio"] {
    display: none; }
    .single-method input[type="radio"] + label {
      position: relative;
      padding-left: 30px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      margin: 0; }
      .single-method input[type="radio"] + label::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        display: block;
        border: 2px solid #999999;
        content: "";
        transition: all 0.3s ease 0s; }
      .single-method input[type="radio"] + label::after {
        position: absolute;
        left: 5px;
        top: 5px;
        display: block;
        content: "";
        opacity: 0;
        background-color: #222222;
        width: 10px;
        text-align: center;
        height: 10px;
        transition: all 0.3s ease 0s; }
    .single-method input[type="radio"]:checked + label::before {
      border: 2px solid #222222; }
    .single-method input[type="radio"]:checked + label::after {
      opacity: 1; }
  .single-method input[type="checkbox"] {
    display: none; }
    .single-method input[type="checkbox"] + label {
      position: relative;
      padding-left: 30px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 0; }
      .single-method input[type="checkbox"] + label::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        display: block;
        border: 2px solid #999999;
        content: "";
        transition: all 0.3s ease 0s; }
      .single-method input[type="checkbox"] + label::after {
        position: absolute;
        left: 4px;
        top: 4px;
        display: block;
        content: "";
        opacity: 0;
        background-color: #222222;
        width: 8px;
        text-align: center;
        height: 8px;
        transition: all 0.3s ease 0s; }
    .single-method input[type="checkbox"]:checked + label::before {
      border: 2px solid #222222; }
    .single-method input[type="checkbox"]:checked + label::after {
      opacity: 1; }
  .single-method p {
    display: none;
    margin-top: 8px;
    font-size: 14px;
    color: #222222;
    line-height: 23px; }

/*-- Place Order --*/
.place-order {
  background-color: #222222;
  border: medium none;
  border-radius: 50px;
  color: #fff;
  float: left;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  line-height: 24px;
  margin-top: 40px;
  padding: 6px 20px;
  text-transform: uppercase;
  width: 158px; }

/*---------------------------------------
    12. Compare
-----------------------------------------*/
/*-- Compare Table --*/
.compare-table .table tbody tr:first-child {
  border-top: 1px solid #eeeeee; }

.compare-table .table tbody tr td {
  text-align: center;
  border: none;
  padding: 25px 30px;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee; }
  .compare-table .table tbody tr td:last-child {
    border-right: 1px solid #eeeeee; }
  .compare-table .table tbody tr td.first-column {
    min-width: 180px;
    font-size: 18px;
    font-weight: 600;
    color: #222222;
    margin: 0;
    line-height: 1; }
  .compare-table .table tbody tr td.product-image-title {
    min-width: 310px;
    vertical-align: bottom;
    padding-top: 70px; }
    .compare-table .table tbody tr td.product-image-title .image {
      clear: both;
      width: 100%;
      margin-bottom: 40px; }
      .compare-table .table tbody tr td.product-image-title .image img {
        max-width: 100%;
        width: 100%; }
    .compare-table .table tbody tr td.product-image-title .category {
      clear: both;
      color: #454545;
      float: left;
      font-size: 13px;
      letter-spacing: 0.5px;
      line-height: 15px;
      margin-bottom: 7px;
      margin-top: 15px;
      text-transform: capitalize; }
    .compare-table .table tbody tr td.product-image-title .title {
      float: left;
      clear: both;
      font-size: 16px;
      font-weight: 600;
      color: #222222;
      line-height: 20px;
      margin-bottom: 10px; }
  .compare-table .table tbody tr td.pro-desc p {
    text-align: left;
    margin: 0;
    color: #222222; }
  .compare-table .table tbody tr td.pro-price {
    font-size: 16px;
    font-weight: 600;
    color: #333333; }
  .compare-table .table tbody tr td.pro-color {
    font-size: 16px;
    font-weight: 600;
    color: #333333; }
  .compare-table .table tbody tr td.pro-stock {
    font-size: 16px;
    font-weight: 600;
    color: #333333; }
  .compare-table .table tbody tr td.pro-addtocart .add-to-cart {
    position: relative;
    background-color: #f6f6f6;
    color: #222222;
    border-radius: 50px;
    display: inline-block;
    width: 170px;
    padding: 10px 25px 10px 56px;
    transition: all 0.7s cubic-bezier(0.77, -1.5, 0.12, 3) 0s; }
    .compare-table .table tbody tr td.pro-addtocart .add-to-cart i {
      width: 41px;
      height: 41px;
      background-color: #fff;
      border-radius: 50px;
      display: block;
      float: left;
      font-size: 20px;
      text-align: center;
      line-height: 41px;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease 0s;
      position: absolute;
      left: 0;
      right: auto;
      top: 0; }
    .compare-table .table tbody tr td.pro-addtocart .add-to-cart span {
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 21px;
      overflow: hidden;
      display: block;
      text-align: center; }
    .compare-table .table tbody tr td.pro-addtocart .add-to-cart.added {
      padding-left: 25px;
      padding-right: 56px; }
      .compare-table .table tbody tr td.pro-addtocart .add-to-cart.added i {
        left: calc(100% - 41px); }
  .compare-table .table tbody tr td.pro-remove button {
    border: none;
    background-color: transparent;
    padding: 0; }
    .compare-table .table tbody tr td.pro-remove button i {
      font-size: 20px; }
  .compare-table .table tbody tr td.pro-ratting i {
    color: #222222;
    font-size: 14px; }

/*------------------------
     My account
--------------------------*/
.panel .panel-heading h5.panel-title {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  color: #434343;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 30px;
  position: relative;
  text-transform: uppercase; }
  .panel .panel-heading h5.panel-title.border-none {
    border: none !important; }
  .panel .panel-heading h5.panel-title a {
    color: #333;
    display: block;
    padding: 12px 55px 11px;
    position: relative; }
    .panel .panel-heading h5.panel-title a::before {
      color: #666666;
      content: "\f107";
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 12px;
      position: absolute;
      right: 10px;
      top: 14px; }
    .panel .panel-heading h5.panel-title a.collapsed::after {
      color: #666666;
      content: "\f104";
      display: inline-block;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 12px;
      position: absolute;
      right: 10px;
      top: 14px; }
    .panel .panel-heading h5.panel-title a.collapsed::before {
      display: none; }
    .panel .panel-heading h5.panel-title a:hover {
      color: #222222; }
  .panel .panel-heading h5.panel-title span {
    background-color: #222222;
    color: #fff;
    font-size: 15px;
    height: 42px;
    left: 0;
    line-height: 42px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 42px; }

.panel-body {
  margin-bottom: 50px; }
  .panel-body .billing-information-wrapper {
    background-color: #fff;
    padding: 30px 20px;
    border: 1px solid #eaeaea; }
  .panel-body .account-info-wrapper {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 28px;
    padding-bottom: 30px; }
    .panel-body .account-info-wrapper h4 {
      font-size: 18px;
      letter-spacing: 0.2px;
      margin: 0;
      text-transform: uppercase; }
    .panel-body .account-info-wrapper h5 {
      font-size: 16px;
      letter-spacing: 0.2px;
      margin-top: 7px; }
  .panel-body .billing-info {
    margin-bottom: 20px; }
    .panel-body .billing-info label {
      color: #434343;
      font-size: 14px;
      text-transform: capitalize; }
    .panel-body .billing-info input {
      background: transparent none repeat scroll 0 0;
      border: 1px solid #eaeaea;
      color: #555;
      height: 40px;
      border-radius: 5px; }
  .panel-body .billing-back-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 26px; }
    .panel-body .billing-back-btn .billing-back a {
      display: inline-block;
      font-size: 15px;
      margin-top: 7px;
      text-transform: uppercase; }
      .panel-body .billing-back-btn .billing-back a:hover {
        color: #222222; }
      .panel-body .billing-back-btn .billing-back a i {
        font-size: 18px;
        color: #222222; }
    .panel-body .billing-back-btn .billing-btn button {
      background-color: #222222;
      border: medium none;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-weight: 600;
      line-height: 1;
      padding: 13px 23px;
      text-transform: uppercase;
      transition: all 0.3s ease 0s; }
      .panel-body .billing-back-btn .billing-btn button:hover {
        background-color: #222222;
        color: #fff; }
  .panel-body .entries-wrapper {
    border: 1px solid #eaeaea;
    position: relative; }
    .panel-body .entries-wrapper::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 1px;
      left: 50%;
      top: 0;
      background-color: #eaeaea; }
      @media only screen and (max-width: 767px) {
        .panel-body .entries-wrapper::before {
          display: none; } }
    .panel-body .entries-wrapper .entries-info {
      padding: 30px 20px; }
      .panel-body .entries-wrapper .entries-info p {
        color: #454545;
        font-size: 15px;
        margin: 0;
        text-transform: capitalize; }
    .panel-body .entries-wrapper .entries-edit-delete a {
      background-color: #454545;
      border-radius: 5px;
      color: #fff;
      display: inline-block;
      letter-spacing: 0.2px;
      line-height: 1;
      margin: 0 2px;
      padding: 12px 15px;
      text-transform: uppercase; }
      .panel-body .entries-wrapper .entries-edit-delete a.edit {
        background-color: #222222; }
        .panel-body .entries-wrapper .entries-edit-delete a.edit:hover {
          background-color: #222222; }
      .panel-body .entries-wrapper .entries-edit-delete a:hover {
        background-color: #222222; }
    @media only screen and (max-width: 767px) {
      .panel-body .entries-wrapper .entries-edit-delete {
        margin-bottom: 24px; } }

/* -------------------------------- 
Main Components 
-------------------------------- */
.gradient-wrapper {
  margin: 150px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .gradient-wrapper {
      margin: 40px; } }
  @media only screen and (max-width: 767px) {
    .gradient-wrapper {
      margin: 40px;
      margin-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .gradient-wrapper {
      margin: 40px; } }
  .gradient-wrapper .post-info .inner .main-info {
    position: relative;
    z-index: 1;
    max-width: 400px;
    min-height: 550px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .gradient-wrapper .post-info .inner .main-info {
        min-height: 408px; } }
    @media only screen and (max-width: 575px) {
      .gradient-wrapper .post-info .inner .main-info {
        min-height: 331px; } }
    @media only screen and (max-width: 767px) {
      .gradient-wrapper .post-info .inner .main-info .main-info-inner {
        padding: 20px; } }
    .gradient-wrapper .post-info .inner .main-info .main-info-inner h6 {
      font-size: 100px;
      line-height: 1.06;
      margin-bottom: 77px;
      font-family: "louis_george_caferegular"; }
      @media only screen and (max-width: 767px) {
        .gradient-wrapper .post-info .inner .main-info .main-info-inner h6 {
          font-size: 76px;
          margin-bottom: 26px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .gradient-wrapper .post-info .inner .main-info .main-info-inner h6 {
          font-size: 41px;
          margin-bottom: 122px; } }
    .gradient-wrapper .post-info .inner .main-info .main-info-inner h2 {
      font-size: 56px;
      line-height: 1.13;
      margin-bottom: 140px;
      text-align: left;
      font-family: "louis_george_caferegular"; }
      @media only screen and (max-width: 767px) {
        .gradient-wrapper .post-info .inner .main-info .main-info-inner h2 {
          font-size: 30px;
          margin-bottom: 50px; } }
      .gradient-wrapper .post-info .inner .main-info .main-info-inner h2 a {
        position: relative;
        color: #222222; }
    .gradient-wrapper .post-info .inner .main-info .main-info-inner a.post-read-more {
      font-size: 18px;
      font-weight: 700;
      color: #222;
      font-family: "louis_george_caferegular"; }
      .gradient-wrapper .post-info .inner .main-info .main-info-inner a.post-read-more .btn-icon {
        position: relative;
        content: "";
        width: 73px;
        top: -5px;
        height: 2px;
        display: inline-block;
        color: #222;
        background: currentColor;
        margin-left: 15px; }
        .gradient-wrapper .post-info .inner .main-info .main-info-inner a.post-read-more .btn-icon::before {
          content: "";
          position: absolute;
          top: -7px;
          right: 1px;
          width: 16px;
          height: 16px;
          border: 2px solid currentColor;
          border-left: 0;
          border-bottom: 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          -webkit-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }
  .gradient-wrapper .post-info .inner .post-feature {
    position: absolute;
    top: 0;
    left: 40px;
    display: inline-block;
    padding-right: 245px; }
    @media only screen and (max-width: 767px) {
      .gradient-wrapper .post-info .inner .post-feature {
        left: 0;
        padding-right: 0; } }
    .gradient-wrapper .post-info .inner .post-feature .post-gradient {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFE643), color-stop(100%, #409844));
      background: -moz-linear-gradient(135deg, #FFE643 0, #409844 100%);
      background: -webkit-linear-gradient(135deg, #FFE643 0, #409844 100%);
      background: -o-linear-gradient(135deg, #FFE643 0, #409844 100%);
      background: -ms-linear-gradient(135deg, #FFE643 0, #409844 100%);
      background: linear-gradient(135deg, #FFE643 0, #409844 100%);
      position: relative;
      width: 550px;
      max-width: 100%;
      box-shadow: 0 20px 30px 0 rgba(131, 232, 239, 0.1); }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .gradient-wrapper .post-info .inner .post-feature .post-gradient {
          width: 454px; } }
      @media only screen and (max-width: 767px) {
        .gradient-wrapper .post-info .inner .post-feature .post-gradient {
          width: 366px; } }
      .gradient-wrapper .post-info .inner .post-feature .post-gradient::before {
        content: "";
        padding-top: 100%;
        width: 100%;
        display: block; }
      .gradient-wrapper .post-info .inner .post-feature .post-gradient .post-thumbnail {
        position: absolute;
        top: 30px;
        bottom: 30px;
        width: calc(100% - 60px);
        right: 0;
        -webkit-transform: translate(50%);
        -ms-transform: translate(50%);
        transform: translate(50%); }
        .gradient-wrapper .post-info .inner .post-feature .post-gradient .post-thumbnail img {
          height: auto;
          max-width: 100%; }
    .gradient-wrapper .post-info .inner .post-feature.post-feature--2 .post-gradient {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #38D4FF), color-stop(100%, #12A149));
      background: -moz-linear-gradient(135deg, #38D4FF 0, #12A149 100%);
      background: -webkit-linear-gradient(135deg, #38D4FF 0, #12A149 100%);
      background: -o-linear-gradient(135deg, #38D4FF 0, #12A149 100%);
      background: -ms-linear-gradient(135deg, #38D4FF 0, #12A149 100%);
      background: linear-gradient(135deg, #38D4FF 0, #12A149 100%); }
    .gradient-wrapper .post-info .inner .post-feature.post-feature--3 .post-gradient {
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #D32F20), color-stop(100%, #F6802B));
      background: -moz-linear-gradient(135deg, #D32F20 0, #F6802B 100%);
      background: -webkit-linear-gradient(135deg, #D32F20 0, #F6802B 100%);
      background: -o-linear-gradient(135deg, #D32F20 0, #F6802B 100%);
      background: -ms-linear-gradient(135deg, #D32F20 0, #F6802B 100%);
      background: linear-gradient(135deg, #D32F20 0, #F6802B 100%); }
  .gradient-wrapper .post-info .inner .post-categories {
    position: absolute;
    bottom: -130px;
    left: 40px;
    font-size: 18px;
    color: #222;
    font-family: "louis_george_caferegular"; }
    @media only screen and (max-width: 767px) {
      .gradient-wrapper .post-info .inner .post-categories {
        bottom: -26px; } }
    .gradient-wrapper .post-info .inner .post-categories::before {
      content: "";
      width: 110px;
      height: 1px;
      background: currentColor;
      display: inline-block;
      position: relative;
      top: -5px;
      margin-right: 20px; }
    .gradient-wrapper .post-info .inner .post-categories a {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: #222222; }

.text-black {
  color: #222222 !important; }

.vertical-geadient-home {
  font-size: 1.6rem;
  font-family: "Open Sans", sans-serif;
  color: #ffffff;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.vertical-geadient-home::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none; }

@media only screen and (min-width: 1050px) {
  .vertical-geadient-home::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop'; } }

a {
  color: #267481;
  text-decoration: none; }

/* -------------------------------- 

Main Components 

-------------------------------- */
@media only screen and (min-width: 1050px) {
  .vertical-geadient-home[data-hijacking="on"] {
    overflow: hidden; } }

.cd-section {
  height: 100vh; }

.cd-section h2 {
  line-height: 100vh;
  text-align: center;
  font-size: 2.4rem; }

.cd-section:first-of-type > div {
  background-color: #ffffff; }

.cd-section:first-of-type > div::before {
  /* alert -> all scrolling effects are not visible on small devices */
  content: 'Effects not visible on mobile!';
  position: absolute;
  width: 100%;
  text-align: center;
  top: 20px;
  z-index: 2;
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #6a7083; }

@media only screen and (min-width: 1050px) {
  .cd-section h2 {
    font-size: 4rem;
    font-weight: 300; }
  [data-hijacking="on"] .cd-section {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  [data-hijacking="on"] .cd-section > div {
    visibility: visible; }
  [data-hijacking="off"] .cd-section > div {
    opacity: 0; }
  [data-animation="rotate"] .cd-section {
    /* enable a 3D-space for children elements */
    -webkit-perspective: 1800px;
    -moz-perspective: 1800px;
    perspective: 1800px; }
  [data-hijacking="on"][data-animation="rotate"] .cd-section:not(:first-of-type) {
    -webkit-perspective-origin: center 0;
    -moz-perspective-origin: center 0;
    perspective-origin: center 0; }
  [data-animation="scaleDown"] .cd-section > div,
  [data-animation="gallery"] .cd-section > div,
  [data-animation="catch"] .cd-section > div {
    box-shadow: 0 0 0 rgba(25, 30, 46, 0.4); }
  [data-animation="opacity"] .cd-section.visible > div {
    z-index: 1; } }

@media only screen and (min-width: 1050px) {
  .cd-section:first-of-type > div::before {
    display: none; } }

@media only screen and (min-width: 1050px) {
  .cd-section > div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* Force Hardware Acceleration */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  [data-hijacking="on"] .cd-section > div {
    position: absolute; }
  [data-animation="rotate"] .cd-section > div {
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .vertical-slide-gradient-portfolio {
    padding-top: 80px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vertical-slide-gradient-portfolio {
    padding-top: 60px; } }

@media only screen and (max-width: 767px) {
  .vertical-slide-gradient-portfolio {
    padding-top: 50px; } }

/*-----------------------
 * Comments & Forms
-------------------------*/
.login-form-wrapper {
  padding: 30px;
  border: 1px solid #d5d5d5; }

.sn-form-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -20px; }
  .sn-form-inner .single-input {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px; }
    .sn-form-inner .single-input.single-input-half {
      flex: 0 0 calc(50% - 15px);
      max-width: calc(50% - 15px); }
    .sn-form-inner .single-input label {
      font-weight: 400;
      margin-bottom: 8px;
      display: block;
      color: #222222; }
    .sn-form-inner .single-input input,
    .sn-form-inner .single-input select,
    .sn-form-inner .single-input textarea,
    .sn-form-inner .single-input .nice-select {
      border-radius: 3px;
      border: 1px solid #e5e5e5; }
    .sn-form-inner .single-input input + input {
      margin-top: 20px; }
    .sn-form-inner .single-input .checkbox-input {
      display: inline-block; }
      .sn-form-inner .single-input .checkbox-input input {
        display: inline-block;
        height: 14px;
        width: 14px;
        vertical-align: middle; }
      .sn-form-inner .single-input .checkbox-input label {
        display: inline-block; }
    .sn-form-inner .single-input button {
      display: inline-block;
      border: 1px solid #222222;
      font-weight: 700;
      font-size: 14px;
      padding: 10px 35px 9px 35px;
      text-transform: uppercase;
      text-align: center;
      transition: all 0.4s ease-in-out 0s;
      background: #222222;
      color: #ffffff; }
      @media only screen and (max-width: 575px) {
        .sn-form-inner .single-input button {
          margin-bottom: 20px; } }
      .sn-form-inner .single-input button:hover {
        opacity: 0.7; }
  .sn-form-inner a {
    color: #333333;
    font-size: 13px; }
    .sn-form-inner a:hover {
      color: #0038E3; }

@media only screen and (max-width: 767px) {
  .sn-form .single-input.single-input-half {
    flex: 0 0 100%;
    max-width: 100%; } }

/*==========================
    Table Form CSS
============================*/
.book-table-form {
  margin-bottom: -170px;
  padding-top: 55px;
  padding-right: 85px;
  padding-bottom: 66px;
  padding-left: 85px;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.06);
  background-color: #fff; }
  @media only screen and (max-width: 767px) {
    .book-table-form {
      padding: 15px; } }

.single-input input {
  border: 0;
  border-bottom: 2px solid #eee;
  background: none;
  padding-left: 0;
  padding-right: 0;
  height: 50px;
  padding: 3px 20px;
  color: #777;
  line-height: 1.75;
  font-size: 16px; }

.single-input select {
  border: 0;
  border-bottom: 2px solid #eee;
  background: none;
  padding-left: 0;
  padding-right: 0;
  height: 50px;
  padding: 3px 20px;
  color: #777;
  line-height: 1.75;
  font-size: 16px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none; }

.table-notice p {
  line-height: 1.75;
  font-size: 16px;
  color: #777;
  margin-bottom: 0; }

/*=================================
	Template Styles
==================================*/
/*========================
 * Header
=========================================*/
.page_nav ul.mainmenu {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center; }

/* ============================
    Header Default Area 
================================*/
/* Maninmenu Area */
.mainmenu-wrapper .page_nav ul.mainmenu {
  justify-content: center;
  padding: 0;
  margin: 0; }
  .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 {
    display: inline-block;
    padding-top: 41px;
    padding-bottom: 41px;
    padding-left: 0;
    padding-right: 9px; }
    .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
      position: relative;
      overflow: hidden;
      z-index: 1;
      font-weight: 600;
      line-height: 1.18;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      color: #0038E3;
      padding-top: 41px;
      padding-bottom: 41px;
      padding-left: 15px;
      padding-right: 15px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
        position: relative;
        overflow: hidden; }
        .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span::after {
          content: "";
          width: 0;
          height: 1px;
          bottom: 0;
          position: absolute;
          left: auto;
          right: 0;
          z-index: -1;
          -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
          background: currentColor; }
    .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1:hover > a span::after {
      width: 100%;
      left: 0;
      right: auto; }
    .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1.with--drop > a {
      position: relative;
      margin-left: 13px;
      padding-right: 21px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1.with--drop > a {
          margin-left: 3px; } }
      .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1.with--drop > a::before {
        content: "\f107";
        font-size: 14px;
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-family: Font Awesome\ 5 Free;
        font-weight: 900; }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .lg-not-transparent {
    position: fixed !important;
    background-color: #fff !important;
    padding-top: 0px !important; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .lg-not-transparent {
    position: fixed !important;
    background-color: #fff !important;
    padding-top: 0px !important; } }

@media only screen and (max-width: 767px) {
  .lg-not-transparent {
    position: fixed !important;
    background-color: #fff !important;
    padding-top: 10px !important; } }

header.br_header.header-default.vertical-slide-portfolio {
  background: #fff; }

.header-default {
  position: relative;
  z-index: 9; }
  .header-default.haeder-fixed-width.haeder-fixed-150 .container-fluid {
    padding-left: 150px;
    padding-right: 150px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .header-default.haeder-fixed-width.haeder-fixed-150 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .header-default.haeder-fixed-width.haeder-fixed-150 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-default.haeder-fixed-width.haeder-fixed-150 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 767px) {
      .header-default.haeder-fixed-width.haeder-fixed-150 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 479px) {
      .header-default.haeder-fixed-width.haeder-fixed-150 .container-fluid {
        padding-left: 20px;
        padding-right: 20px; } }
  .header-default.haeder-fixed-width.haeder-fixed-130 .container-fluid {
    padding-left: 130px;
    padding-right: 130px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .header-default.haeder-fixed-width.haeder-fixed-130 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-default.haeder-fixed-width.haeder-fixed-130 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 767px) {
      .header-default.haeder-fixed-width.haeder-fixed-130 .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 479px) {
      .header-default.haeder-fixed-width.haeder-fixed-130 .container-fluid {
        padding-left: 20px;
        padding-right: 20px; } }
  .header-default.haeder-fixed-width .container-fluid {
    padding-left: 100px;
    padding-right: 100px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .header-default.haeder-fixed-width .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-default.haeder-fixed-width .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 767px) {
      .header-default.haeder-fixed-width .container-fluid {
        padding-left: 50px;
        padding-right: 50px; } }
    @media only screen and (max-width: 479px) {
      .header-default.haeder-fixed-width .container-fluid {
        padding-left: 20px;
        padding-right: 20px; } }
  .header-default.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999; }
    .header-default.header-transparent.position-from--top {
      padding-top: 24px; }
      .header-default.header-transparent.position-from--top.headroom--not-top {
        padding-top: 0; }
  .header-default .header__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    justify-content: space-between; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-default .header__wrapper {
        margin-right: 29px;
        position: relative;
        z-index: 9; } }
    @media only screen and (max-width: 767px) {
      .header-default .header__wrapper {
        margin-right: 29px; } }
    .header-default .header__wrapper .header-left {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 20%;
      -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-default .header__wrapper .header-left {
          -webkit-flex-basis: 30%;
          -ms-flex-preferred-size: 30%;
          flex-basis: 30%; } }
      @media only screen and (max-width: 767px) {
        .header-default .header__wrapper .header-left {
          -webkit-flex-basis: 30%;
          -ms-flex-preferred-size: 30%;
          flex-basis: 30%; } }
      @media only screen and (max-width: 575px) {
        .header-default .header__wrapper .header-left {
          -webkit-flex-basis: 50%;
          -ms-flex-preferred-size: 50%;
          flex-basis: 50%; } }
      .header-default .header__wrapper .header-left .logo a img {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 170px;
        height: auto;
        max-width: 100%; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .header-default .header__wrapper .header-left .logo a img {
            width: 140px !important; } }
        @media only screen and (max-width: 767px) {
          .header-default .header__wrapper .header-left .logo a img {
            width: 110px !important; } }
    .header-default .header__wrapper .header-flex-right {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end; }
      .header-default .header__wrapper .header-flex-right.flex-80 {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1; }
    .header-default .header__wrapper .mainmenu-wrapper {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
      .header-default .header__wrapper .mainmenu-wrapper.have-not-flex {
        flex-grow: inherit; }
        .header-default .header__wrapper .mainmenu-wrapper.have-not-flex .page_nav ul.mainmenu {
          justify-content: flex-end; }
    .header-default .header__wrapper .header-right {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 20%;
      -ms-flex-preferred-size: 20%;
      flex-basis: 20%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin: 0 -12px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
      .header-default .header__wrapper .header-right.have-not-flex {
        flex-basis: inherit; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .header-default .header__wrapper .header-right {
          -webkit-flex-basis: 12%;
          -ms-flex-preferred-size: 12%;
          flex-basis: 12%; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-default .header__wrapper .header-right {
          -webkit-flex-basis: 70%;
          -ms-flex-preferred-size: 70%;
          flex-basis: 70%; } }
      @media only screen and (max-width: 767px) {
        .header-default .header__wrapper .header-right {
          -webkit-flex-basis: 70%;
          -ms-flex-preferred-size: 70%;
          flex-basis: 70%; } }
      @media only screen and (max-width: 575px) {
        .header-default .header__wrapper .header-right {
          -webkit-flex-basis: 50%;
          -ms-flex-preferred-size: 50%;
          flex-basis: 50%; } }
  .header-default .header-right {
    margin: 0 -12px; }
    .header-default .header-right > div {
      margin: 0 12px; }
    .header-default .header-right .mini-cart-button {
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      cursor: pointer; }
      .header-default .header-right .mini-cart-button::after {
        position: absolute;
        top: -4px;
        right: -13px;
        padding: 0 4px;
        min-width: 15px;
        height: 15px;
        border-radius: 100%;
        content: attr(data-count);
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        background-color: rgba(0, 0, 0, 0);
        color: #fff; }
      .header-default .header-right .mini-cart-button button {
        border: 0 none; }
  .header-default.light-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li a {
    color: #ffffff; }
    .header-default.light-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li a::after {
      background: #ffffff; }
  .header-default.light-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li.with--drop::before {
    color: #ffffff; }
  .header-default.light-logo--version .header-right .mini-cart-button {
    color: #fff; }
    .header-default.light-logo--version .header-right .mini-cart-button::after {
      color: #fff; }
    .header-default.light-logo--version .header-right .mini-cart-button button {
      color: #fff; }
  .header-default.light-logo--version .header-right .popup-search-wrap a.btn-search-click {
    color: #fff; }
  .header-default.black-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li a {
    color: #222222; }
    .header-default.black-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li a::after {
      background: #222222; }
  .header-default.black-logo--version .mainmenu-wrapper .page_nav ul.mainmenu li.with--drop::before {
    color: #222222; }
  .header-default.black-logo--version .header-right .mini-cart-button {
    color: #222222; }
    .header-default.black-logo--version .header-right .mini-cart-button::after {
      color: #222222; }
    .header-default.black-logo--version .header-right .mini-cart-button button {
      color: #222222; }
  .header-default.black-logo--version .header-right .popup-search-wrap a.btn-search-click {
    color: #222222; }

.black-logo--version .mean-container a.meanmenu-reveal {
  color: #222; }

.black-logo--version .mean-container a.meanmenu-reveal span {
  background: #222; }

/* ==========================
    Header left Align 
===========================*/
.header-left--align.header-default .header__wrapper .header-left .logo a img {
  width: auto !important; }

.header-left--align.header-default .header__wrapper .header-left {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.header-left--align.header-default .header__wrapper .header-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.header-left--align.header-default .header__wrapper .mainmenu-wrapper.have-not-flex .page_nav ul.mainmenu {
  justify-content: flex-start; }

.header-left--align .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1.with--drop > a::before {
  display: none; }

.header-left--align .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1.with--drop > a {
  margin-left: 8px;
  padding-right: 8px; }

/* ============================
    Sticky Header Css 
================================*/
.black-logo--version.headroom--sticky.headroom--not-top {
  background: rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0.9); }

.headroom--sticky.headroom--not-top {
  background: rgba(0, 0, 0, 0.9);
  background-color: rgba(0, 0, 0, 0.9);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 0; }

.headroom--sticky.headroom--not-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  -webkit-animation: headerSlideDown .95s ease forwards;
  animation: headerSlideDown .95s ease forwards;
  padding-top: 0 !important; }

.headroom--sticky.headroom--not-top .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 {
  padding-top: 31px;
  padding-bottom: 31px; }

.headroom--sticky.headroom--not-top .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
  padding-top: 31px;
  padding-bottom: 31px; }

.header-default.headroom--not-top .header__wrapper .header-left .logo a img {
  width: 150px; }

.header-default.header-architecture.headroom--not-top .header__wrapper .header-left .logo a img {
  width: auto; }

.header-default.header-portfolio-slide.headroom--not-top .header__wrapper {
  padding: 16px 0; }

/* =================================
    Header One Page 
==================================*/
.one-page-header .header-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  height: 100%; }
  .one-page-header .header-wrapper .header-left-wrapper {
    background: #222;
    background-color: #222;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-left: 76px;
    padding-right: 43px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50%; }
    @media only screen and (max-width: 575px) {
      .one-page-header .header-wrapper .header-left-wrapper {
        width: 100%; } }
    .one-page-header .header-wrapper .header-left-wrapper .branding {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1; }
      .one-page-header .header-wrapper .header-left-wrapper .branding a img {
        padding-top: 14px;
        padding-bottom: 14px;
        width: 52px; }
    .one-page-header .header-wrapper .header-left-wrapper .header-left-inner {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      height: 30px;
      cursor: pointer; }
  .one-page-header .header-wrapper .header-right-wrapper {
    background: #19d2a8;
    background-color: #19d2a8;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-left: 30px;
    padding-right: 49px;
    width: 50%; }
    .one-page-header .header-wrapper .header-right-wrapper .header-text {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin: 0 -12px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      height: 100%; }

/*==========================
Hambergar Style Two 
============================*/
.manu-hamber-2 {
  padding: 0 12px;
  color: #fff; }
  .manu-hamber-2 div {
    position: relative;
    width: 24px;
    height: 17px;
    cursor: pointer; }
    .manu-hamber-2 div i {
      height: 3px;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      background: currentColor; }
      .manu-hamber-2 div i::before, .manu-hamber-2 div i::after {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
        background: inherit;
        content: "";
        -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
        left: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none; }
      .manu-hamber-2 div i::before {
        width: 16px;
        top: -7px; }
      .manu-hamber-2 div i::after {
        width: 18px;
        top: 7px; }
    .manu-hamber-2 div:hover i {
      width: 16px; }
      .manu-hamber-2 div:hover i::before, .manu-hamber-2 div:hover i::after {
        width: 70%; }
      .manu-hamber-2 div:hover i::before {
        width: 24px; }
      .manu-hamber-2 div:hover i::after {
        width: 22px; }
  .manu-hamber-2.dak-version {
    color: #222222; }

.header-default.header-bar.header-new-bar .header__wrapper {
  justify-content: space-between; }

.header-default.header-bar.header-new-bar .header__wrapper .header-right {
  -webkit-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%; }

/*=================================
    Header Fixed 
=====================================*/
header.br_header.header-default.header-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0; }

/* =====================
    Mobile Menu 
========================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mean-container .mean-bar {
    min-height: inherit; }
  .mean-container .mean-nav {
    margin-top: 0px; }
  .mean-container a.meanmenu-reveal.meanclose,
  .mean-container a.meanmenu-reveal {
    top: -75px; }
  .header-default.header-bar .header__wrapper {
    margin-right: 84px; }
  .header-default.header-bar.header-new-bar .header__wrapper {
    margin-right: 0; } }

@media only screen and (max-width: 767px) {
  .mean-container .mean-bar {
    min-height: inherit; }
  .mean-container .mean-nav {
    margin-top: 0px; }
  .mean-container a.meanmenu-reveal.meanclose,
  .mean-container a.meanmenu-reveal {
    top: -68px; }
  .header-default.header-bar .header__wrapper {
    margin-right: 60px; }
  .header-default.header-bar.header-new-bar .header__wrapper {
    margin-right: 0; } }

@media only screen and (max-width: 575px) {
  .header-default.header-bar .header__wrapper {
    margin-right: 110px; } }

@media only screen and (max-width: 479px) {
  .header-default.header-bar .header__wrapper {
    margin-right: 68px; } }

/* ==========================
    Sidebar Header 
=============================*/
.br_header-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100px;
  height: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .br_header-sidebar {
      position: static;
      width: 100%;
      height: auto; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .br_header-sidebar {
      position: static;
      width: 100%;
      height: auto; } }
  @media only screen and (max-width: 767px) {
    .br_header-sidebar {
      position: static;
      width: 100%;
      height: auto; } }
  .br_header-sidebar .side-header-inner {
    height: 100%;
    border: 0;
    border-right-width: 0px;
    border-color: rgba(0, 0, 0, 0);
    background: #fff;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-box-shadow: 0 -9px 25px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 -9px 25px rgba(0, 0, 0, 0.03);
    box-shadow: 0 -9px 25px rgba(0, 0, 0, 0.03);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .br_header-sidebar .side-header-inner {
        height: auto; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .br_header-sidebar .side-header-inner {
        height: auto; } }
    @media only screen and (max-width: 767px) {
      .br_header-sidebar .side-header-inner {
        height: auto; } }
    .br_header-sidebar .side-header-inner .header-wrap {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-flow: column;
      -ms-flex-flow: column;
      flex-flow: column;
      padding: 15px 10px 50px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .br_header-sidebar .side-header-inner .header-wrap {
          height: auto;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-flow: row;
          -ms-flex-flow: row;
          flex-flow: row;
          padding: 24px 23px 26px;
          width: 100%;
          align-items: center; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .br_header-sidebar .side-header-inner .header-wrap {
          height: auto;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-flow: row;
          -ms-flex-flow: row;
          flex-flow: row;
          padding: 24px 23px 26px;
          width: 100%;
          align-items: center; } }
      @media only screen and (max-width: 767px) {
        .br_header-sidebar .side-header-inner .header-wrap {
          height: auto;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-flow: row;
          -ms-flex-flow: row;
          flex-flow: row;
          padding: 24px 23px 26px;
          width: 100%;
          align-items: center; } }
      .br_header-sidebar .side-header-inner .header-wrap .header-top {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; }
        .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu {
          margin-top: 36px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          height: 30px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: pointer;
          color: #999; }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu {
              display: none; } }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu {
              display: none; } }
          @media only screen and (max-width: 767px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu {
              display: none; } }
          .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon {
            position: relative;
            width: 24px;
            height: 18px; }
            .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon i {
              color: #999;
              position: absolute;
              top: 50%;
              left: 0;
              margin-top: -1px;
              width: 100%;
              height: 2px;
              background: currentColor;
              -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
              content: ""; }
              .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon i::before, .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon i::after {
                position: absolute;
                left: 50%;
                width: 100%;
                height: 100%;
                background: inherit;
                content: "";
                -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                -webkit-transform: translate(-50%);
                -ms-transform: translate(-50%);
                transform: translate(-50%); }
              .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon i::before {
                top: -8px; }
              .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon i::after {
                top: 8px; }
            .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon:hover i {
              color: #000000; }
              .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon:hover i::before, .br_header-sidebar .side-header-inner .header-wrap .header-top .popop-open-menu .menu-icon:hover i::after {
                width: 70%; }
      .br_header-sidebar .side-header-inner .header-wrap .header-center {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        position: relative;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks {
          -webkit-writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl; }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks {
              writing-mode: horizontal-tb; } }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks {
              writing-mode: horizontal-tb; } }
          @media only screen and (max-width: 767px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks {
              writing-mode: horizontal-tb; } }
          @media only screen and (max-width: 575px) {
            .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks {
              display: none; } }
          .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks .inner {
            margin: -5px -15px; }
            .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks .inner a {
              display: inline-block;
              margin: 5px 15px;
              padding: 5px 0;
              font-size: 14px;
              font-family: "Montserrat", sans-serif; }
              .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks .inner a span {
                color: #999;
                -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
              .br_header-sidebar .side-header-inner .header-wrap .header-center .header-social-neworks .inner a:hover span {
                color: #000000; }
      .br_header-sidebar .side-header-inner .header-wrap .header-bottom {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .br_header-sidebar .side-header-inner .header-wrap .header-bottom {
            display: flex; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .br_header-sidebar .side-header-inner .header-wrap .header-bottom {
            display: flex; } }
        @media only screen and (max-width: 767px) {
          .br_header-sidebar .side-header-inner .header-wrap .header-bottom {
            display: flex; } }
        .br_header-sidebar .side-header-inner .header-wrap .header-bottom .btn-search-click {
          display: block;
          line-height: 0;
          font-size: 18px;
          color: #999999;
          text-align: center; }
          .br_header-sidebar .side-header-inner .header-wrap .header-bottom .btn-search-click:hover {
            color: #000000; }

/**
 * Navigation
 */
/*=========================
 * Banner
===========================*/
.single-banner {
  position: relative;
  overflow: hidden; }
  .single-banner .banner-image a img {
    border-radius: inherit;
    width: 100%;
    -webkit-transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 1.5s cubic-bezier(0, 0, 0.2, 1); }
  .single-banner:hover .banner-image a img {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }

/* =====================
    Feature Area 
========================*/
.bk-feature .icon {
  margin-right: 20px; }
  .bk-feature .icon i {
    font-size: 40px; }

/* =======================
    Start Up Service 
==========================*/
.startupservice-wrapper {
  padding: 150px 100px 150px 316px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .startupservice-wrapper {
      padding: 50px 46px 50px 66px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .startupservice-wrapper {
      padding: 90px 34px 90px 66px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .startupservice-wrapper {
      padding: 90px 34px 90px 66px; } }
  @media only screen and (max-width: 767px) {
    .startupservice-wrapper {
      padding: 90px 15px 90px 35px; } }

/* Start Single Motivationa Speace */
.single-motive-speach {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .single-motive-speach .icon {
    font-size: 40px;
    margin-right: 20px;
    display: inline-block;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    .single-motive-speach .icon i {
      font-size: 40px; }
  .single-motive-speach .content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    .single-motive-speach .content h4 {
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 21px;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      margin: 0 0 18px; }
    .single-motive-speach .content p {
      max-width: 450px;
      margin-bottom: 7px; }
  .single-motive-speach.text-white .icon i {
    color: #ffffff; }
  .single-motive-speach.text-white .content h4 {
    color: #ffffff; }
  .single-motive-speach.text-white .content p {
    color: #ffffff; }
  .single-motive-speach.theme-color-2 .icon i {
    color: #0069FF; }

/* ======================
Freelancer Area 
==========================*/
.freelancer-wrapper {
  padding-top: 59px;
  padding-right: 135px;
  padding-left: 135px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .freelancer-wrapper {
      padding-right: 70px;
      padding-left: 70px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .freelancer-wrapper {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .freelancer-wrapper {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .freelancer-wrapper {
      padding-right: 30px;
      padding-left: 30px; } }
  .freelancer-wrapper.bg_image--27 {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top 150px center;
    background-size: auto; }
  .freelancer-wrapper .freelancer-left {
    width: 480px;
    max-width: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .freelancer-wrapper .freelancer-left {
        width: 100%; } }
  .freelancer-wrapper .freelancer-right {
    width: 595px;
    max-width: 100%;
    float: right; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .freelancer-wrapper .freelancer-right {
        width: 100%;
        float: left; } }

/* ======================
    Hamber Menu
=========================*/
.manu-hamber {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  pointer-events: auto;
  height: 19px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer; }
  .manu-hamber.light-version {
    color: #fff; }
  .manu-hamber.black-version {
    color: #222222; }
  .manu-hamber.gray-version {
    color: #999; }
  .manu-hamber div {
    position: relative;
    width: 24px;
    height: 18px;
    pointer-events: auto; }
    .manu-hamber div i {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      height: 2px;
      background: currentColor; }
      .manu-hamber div i::before, .manu-hamber div i::after {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
        background: inherit;
        content: "";
        -webkit-transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%); }
      .manu-hamber div i::before {
        top: -8px; }
      .manu-hamber div i::after {
        top: 8px; }
  .manu-hamber:hover div i::before, .manu-hamber:hover div i::after {
    width: 70%; }

/* Page Close Button */
.open-hamberger-wrapper .page-close {
  position: fixed;
  top: 80px;
  right: 90px;
  z-index: 100000;
  font-size: 100px;
  line-height: 0;
  cursor: pointer;
  height: 40px;
  width: 40px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .open-hamberger-wrapper .page-close::before, .open-hamberger-wrapper .page-close::after {
    position: absolute;
    top: 19px;
    left: 4px;
    content: "";
    width: 32px;
    height: 3px;
    background: #222;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .open-hamberger-wrapper .page-close::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .open-hamberger-wrapper .page-close::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .open-hamberger-wrapper .page-close:hover::before, .open-hamberger-wrapper .page-close:hover::after {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
  .open-hamberger-wrapper .page-close.light-version::before, .open-hamberger-wrapper .page-close.light-version::after {
    background: #fff; }

.open-hamberger-wrapper {
  background-image: url(/img/bg/bg-image-28.jpg);
  background-color: #222;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  visibility: hidden;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  padding: 100px 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  padding-top: 60px; }
  .open-hamberger-wrapper.is-visiable {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }

/* Hamberger Wrapper */
.header-default.header-bar .header__wrapper .header-right {
  -webkit-flex-basis: 80%;
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%; }

.open-hamberger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu {
  justify-content: flex-start; }

.open-hamberger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
  line-height: 1.4;
  font-size: 48px; }

/* Hamberger Search */
.hamberger-search {
  margin-top: 230px;
  max-width: 420px;
  margin-bottom: 148px; }
  .hamberger-search .input-box {
    position: relative; }
    .hamberger-search .input-box input {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #eee;
      padding: 28px 50px 28px 0;
      width: 420px; }
    .hamberger-search .input-box button {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      border: 0;
      font-size: 0;
      padding: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      box-shadow: none;
      height: 62px; }
      .hamberger-search .input-box button i {
        font-size: 15px; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .open-hamberger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
    font-size: 30px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .open-hamberger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
    font-size: 20px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .open-hamberger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
    font-size: 20px; } }

/*======================================
    Left Vertical Header 
======================================*/
.vertical-menu-wrapper .mainmenu-wrapper .page_nav ul.mainmenu {
  padding: 0;
  margin: 0;
  text-align: left;
  padding-left: 55px;
  display: block; }

.vertical-menu-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 54px; }

.vertical-menu-wrapper .mainmenu li.with--drop.slide--megamenu {
  position: relative; }

.vertical-menu-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1.with--drop > a {
  margin-left: 0;
  padding-right: 30px;
  display: block;
  line-height: 16px;
  padding-top: 22px;
  padding-bottom: 22px; }

.vertical-menu-wrapper .mega__width--fullscreen {
  max-width: calc(100vw - 394px) !important;
  width: 1170px !important;
  box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
  margin-left: 244px;
  top: 0;
  padding: 70px 0; }

.vertical-menu-wrapper .mainmenu li.with--drop.slide-dropdown:hover .dropdown__menu {
  top: 0;
  left: 100%; }

/* =======================
Header Font Family 
==========================*/
.secondary-font .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a {
  font-family: "Montserrat", sans-serif; }

.secondary-font .mega__width--fullscreen .mega__list li.mega--title {
  font-family: "Montserrat", sans-serif; }

/* ======================
    Footer Area 
========================*/
.bk_left-vertical-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 300px;
  height: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bk_left-vertical-header {
      width: 100%;
      height: 80px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bk_left-vertical-header {
      width: 100%;
      height: 80px; } }
  @media only screen and (max-width: 767px) {
    .bk_left-vertical-header {
      width: 100%;
      height: 80px; } }
  .bk_left-vertical-header .side-header-inner {
    border-right-width: 0px;
    border-color: rgba(0, 0, 0, 0);
    background: #fff;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-box-shadow: 3px 1px 29px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 3px 1px 29px rgba(0, 0, 0, 0.12);
    box-shadow: 3px 1px 29px rgba(0, 0, 0, 0.12);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    border: 0; }
    .bk_left-vertical-header .side-header-inner .header-wrap {
      padding-top: 85px;
      padding-bottom: 100px;
      padding-left: 60px;
      padding-right: 60px;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-flow: column;
      -ms-flex-flow: column;
      flex-flow: column; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .bk_left-vertical-header .side-header-inner .header-wrap {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 50px;
          padding-right: 50px;
          flex-flow: row;
          justify-content: space-between;
          align-items: center; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .bk_left-vertical-header .side-header-inner .header-wrap {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 50px;
          padding-right: 50px;
          flex-flow: row;
          justify-content: space-between;
          align-items: center; } }
      @media only screen and (max-width: 767px) {
        .bk_left-vertical-header .side-header-inner .header-wrap {
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 30px;
          padding-right: 30px;
          flex-flow: row;
          justify-content: space-between;
          align-items: center; } }
      .bk_left-vertical-header .side-header-inner .header-wrap .header-top {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        .bk_left-vertical-header .side-header-inner .header-wrap .header-top .branding a img {
          max-width: 142px;
          height: auto; }
      .bk_left-vertical-header .side-header-inner .header-wrap .header-center {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-top: 80px;
        margin-bottom: 0px;
        margin-left: -60px;
        margin-right: -60px; }
      .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom .inner {
            display: flex; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom .inner {
            display: flex; } }
        @media only screen and (max-width: 767px) {
          .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom .inner {
            display: flex; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom .inner .manu-hamber {
            margin-left: 30px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom .inner .manu-hamber {
            margin-left: 30px; } }
        @media only screen and (max-width: 767px) {
          .bk_left-vertical-header .side-header-inner .header-wrap .header-bottom .inner .manu-hamber {
            margin-left: 30px; } }

/* ========================
    Fullscreen Slider 
===========================*/
.left-vertical-header {
  margin-left: 310px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .left-vertical-header {
      margin-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .left-vertical-header {
      margin-left: 0; } }
  @media only screen and (max-width: 767px) {
    .left-vertical-header {
      margin-left: 0; } }

.fullscreen-slider-activation .slide-portfolio-single {
  padding: 30px 100px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .fullscreen-slider-activation .slide-portfolio-single {
      padding: 150px 30px;
      min-height: auto; } }
  @media only screen and (max-width: 767px) {
    .fullscreen-slider-activation .slide-portfolio-single {
      padding: 150px 30px;
      min-height: auto; } }
  .fullscreen-slider-activation .slide-portfolio-single .content {
    max-width: 600px;
    text-align: left; }
    .fullscreen-slider-activation .slide-portfolio-single .content h5 {
      font-weight: 600;
      margin-bottom: 30px;
      font-family: "Montserrat", sans-serif; }
    .fullscreen-slider-activation .slide-portfolio-single .content h2 {
      margin-bottom: 52px; }
      .fullscreen-slider-activation .slide-portfolio-single .content h2 a {
        color: #fff;
        font-size: 48px;
        line-height: 1.21;
        font-family: "Montserrat", sans-serif; }
        @media only screen and (max-width: 767px) {
          .fullscreen-slider-activation .slide-portfolio-single .content h2 a {
            font-size: 32px; } }
    .fullscreen-slider-activation .slide-portfolio-single .content .slide-btn a {
      color: #fff;
      background: transparent;
      border-color: #fff;
      font-family: "Montserrat", sans-serif; }
      .fullscreen-slider-activation .slide-portfolio-single .content .slide-btn a:hover {
        background: #fff;
        color: #222; }
  .fullscreen-slider-activation .slide-portfolio-single .resume-slider-content {
    margin: auto; }
    .fullscreen-slider-activation .slide-portfolio-single .resume-slider-content h1 {
      font-weight: 400;
      font-size: 110px;
      line-height: 1.2; }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .fullscreen-slider-activation .slide-portfolio-single .resume-slider-content h1 {
          font-size: 90px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .fullscreen-slider-activation .slide-portfolio-single .resume-slider-content h1 {
          font-size: 80px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .fullscreen-slider-activation .slide-portfolio-single .resume-slider-content h1 {
          font-size: 70px; } }
      @media only screen and (max-width: 767px) {
        .fullscreen-slider-activation .slide-portfolio-single .resume-slider-content h1 {
          font-size: 40px; } }

@media only screen and (max-width: 767px) {
  .revolution-slider-area .inner h1.heading {
    font-size: 40px; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-info {
    padding: 50px 39px 42px 54px; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character {
    font-size: 220px; }
  .fullscreen-slider-wrapper .feature-wrapper {
    position: relative;
    z-index: 2; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-info {
    width: 100%;
    position: relative;
    z-index: 2; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character {
    display: none; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-thumbnail::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #ffffff;
    opacity: 0.4;
    content: ""; } }

/* ==========================
    Sidebar Mobile Menu
==============================*/
.popup-mobile-menu-wrapper .popup-mobile-manu {
  visibility: visible;
  opacity: 1; }
  .popup-mobile-menu-wrapper .popup-mobile-manu .inner {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

.popup-mobile-manu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  cursor: url(/img/icons/light-close.png) 16 16, pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 99999; }
  .popup-mobile-manu .inner {
    background: #000000;
    position: relative;
    width: 400px;
    max-width: 80%;
    height: 100%;
    text-align: left;
    -webkit-transition: all .3s;
    transition: all .3s;
    overflow-y: auto;
    padding-top: 85px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    cursor: default; }
    .popup-mobile-manu .inner .mobileheader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 0 15px 0 30px;
      height: 85px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background: #fff; }
      .popup-mobile-manu .inner .mobileheader .logo {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1; }
        .popup-mobile-manu .inner .mobileheader .logo a img {
          width: 115px;
          height: auto;
          max-width: 100%; }
      .popup-mobile-manu .inner .mobileheader a.mobile-close {
        position: relative;
        cursor: pointer;
        height: 40px;
        width: 40px;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        .popup-mobile-manu .inner .mobileheader a.mobile-close::before, .popup-mobile-manu .inner .mobileheader a.mobile-close::after {
          position: absolute;
          top: 19px;
          left: 8px;
          content: "";
          width: 24px;
          height: 3px;
          background: #222;
          -webkit-transform-origin: 50% 50%;
          -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
        .popup-mobile-manu .inner .mobileheader a.mobile-close::before {
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg); }
        .popup-mobile-manu .inner .mobileheader a.mobile-close::after {
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg); }
        .popup-mobile-manu .inner .mobileheader a.mobile-close:hover::before, .popup-mobile-manu .inner .mobileheader a.mobile-close:hover::after {
          -webkit-transform: none;
          -ms-transform: none;
          transform: none; }

/* =======================
    Header menu
==========================*/
.object-custom-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 20px 40px 30px; }
  .object-custom-menu > li {
    position: relative;
    border-top: 1px solid transparent;
    border-color: rgba(255, 255, 255, 0.15); }
    .object-custom-menu > li:first-child {
      border: 0 none; }
    .object-custom-menu > li a {
      padding-top: 19px;
      padding-bottom: 19px;
      display: block;
      position: relative; }
      .object-custom-menu > li a span {
        font-size: 14px;
        color: #999999; }
    .object-custom-menu > li.has-mega-menu > a::after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      text-align: center;
      line-height: 40px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "\f107";
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .object-custom-menu > li.has-mega-menu > a.active::after {
      content: "\f106"; }
    .object-custom-menu > li.has-mega-menu .object-submenu {
      display: none;
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: 30px;
      margin-bottom: 30px; }
      .object-custom-menu > li.has-mega-menu .object-submenu li.title {
        font-weight: 700;
        line-height: 1.5;
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
        margin: 0 0 18px; }
      .object-custom-menu > li.has-mega-menu .object-submenu li a {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block; }
        .object-custom-menu > li.has-mega-menu .object-submenu li a span {
          font-size: 14px;
          color: #999999; }

/* ========================
    Header Broadsheets 
==========================*/
.header__digital--broadcast {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .header__digital--broadcast .header-left {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%; }
    .header__digital--broadcast .header-left .bk-menu {
      list-style: none;
      padding: 0;
      margin: 0; }
      .header__digital--broadcast .header-left .bk-menu li {
        padding-top: 58px;
        padding-bottom: 58px;
        padding-left: 15px;
        padding-right: 15px; }
        @media only screen and (max-width: 767px) {
          .header__digital--broadcast .header-left .bk-menu li {
            padding-left: 8px;
            padding-right: 8px; } }
        .header__digital--broadcast .header-left .bk-menu li a {
          font-weight: 700;
          line-height: 1.18;
          font-size: 18px;
          color: #222222;
          font-family: "louis_george_caferegular";
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .header__digital--broadcast .header-center {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    @media only screen and (max-width: 575px) {
      .header__digital--broadcast .header-center {
        justify-content: flex-end; } }
  .header__digital--broadcast .header-right-arrow {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 40%;
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
    display: flex;
    margin: 0 -12px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

.headroom--not-top .header__digital--broadcast .header-left .bk-menu li {
  padding-top: 30px;
  padding-bottom: 25px; }

/*======================
Button Hire  
========================*/
.hire-on-top-button {
  position: relative; }
  .hire-on-top-button .the-arrow {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: inline-block;
    width: 74px;
    position: relative;
    color: #999; }
    .hire-on-top-button .the-arrow .shaft {
      background-color: currentColor;
      display: block;
      height: 2px;
      position: relative;
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
      will-change: transform;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .hire-on-top-button .the-arrow .shaft::before, .hire-on-top-button .the-arrow .shaft::after {
        -webkit-transform-origin: top right;
        -ms-transform-origin: top right;
        transform-origin: top right;
        background-color: currentColor;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transition: all .2s;
        transition: all .2s;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
        width: 14px; }
      .hire-on-top-button .the-arrow .shaft::before {
        -webkit-transform-origin: top right;
        -ms-transform-origin: top right;
        transform-origin: top right; }
      .hire-on-top-button .the-arrow .shaft::after {
        -webkit-transform-origin: bottom right;
        -ms-transform-origin: bottom right;
        transform-origin: bottom right; }
    .hire-on-top-button .the-arrow-left {
      position: absolute;
      top: 50%;
      margin-top: -1px;
      left: 0; }
      .hire-on-top-button .the-arrow-left .shaft {
        width: 0; }
        .hire-on-top-button .the-arrow-left .shaft::before, .hire-on-top-button .the-arrow-left .shaft::after {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
          width: 0; }
    .hire-on-top-button .the-arrow-right {
      vertical-align: middle;
      display: inline-block;
      margin-left: 14px;
      margin-top: -2px; }
      .hire-on-top-button .the-arrow-right .shaft {
        width: 74px;
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
        opacity: .15; }
        .hire-on-top-button .the-arrow-right .shaft::before {
          -webkit-transform: rotate(40deg);
          -ms-transform: rotate(40deg);
          transform: rotate(40deg); }
        .hire-on-top-button .the-arrow-right .shaft::after {
          -webkit-transform: rotate(-40deg);
          -ms-transform: rotate(-40deg);
          transform: rotate(-40deg); }
  .hire-on-top-button span.button-text {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: inline-block;
    color: #222;
    font-size: 18px;
    font-weight: 700;
    font-family: "louis_george_caferegular"; }
  .hire-on-top-button:hover .the-arrow {
    color: #222; }
    .hire-on-top-button:hover .the-arrow-left .shaft {
      width: 74px;
      -webkit-transition-delay: .1s;
      transition-delay: .1s;
      background-color: currentColor; }
      .hire-on-top-button:hover .the-arrow-left .shaft::before, .hire-on-top-button:hover .the-arrow-left .shaft::after {
        width: 14px;
        -webkit-transition-delay: .1s;
        transition-delay: .1s;
        background-color: currentColor; }
      .hire-on-top-button:hover .the-arrow-left .shaft::before {
        -webkit-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        transform: rotate(40deg); }
      .hire-on-top-button:hover .the-arrow-left .shaft::after {
        -webkit-transform: rotate(-40deg);
        -ms-transform: rotate(-40deg);
        transform: rotate(-40deg); }
    .hire-on-top-button:hover .the-arrow-right {
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
      opacity: 0; }
  .hire-on-top-button:hover span.button-text {
    -webkit-transform: translateX(88px);
    -ms-transform: translateX(88px);
    transform: translateX(88px); }

/* ========================
    One pge Contact 
==========================*/
.onepage-contact-inner {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .onepage-contact-inner {
      display: block; } }
  .onepage-contact-inner .page-address-inner {
    padding-top: 80px !important;
    padding-right: 70px !important;
    padding-bottom: 67px !important;
    padding-left: 70px !important;
    width: 50%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .onepage-contact-inner .page-address-inner {
        padding-top: 81px !important;
        padding-right: 34px !important;
        padding-bottom: 53px !important;
        padding-left: 55px !important; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .onepage-contact-inner .page-address-inner {
        padding-top: 81px !important;
        padding-right: 34px !important;
        padding-bottom: 53px !important;
        padding-left: 55px !important; } }
    @media only screen and (max-width: 767px) {
      .onepage-contact-inner .page-address-inner {
        padding-top: 81px !important;
        padding-right: 34px !important;
        padding-bottom: 53px !important;
        padding-left: 55px !important;
        width: 100%; } }
  .onepage-contact-inner .googlemap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%; }
    @media only screen and (max-width: 767px) {
      .onepage-contact-inner .googlemap {
        width: 100%; } }

.brook-professonal-design .thumbnail {
  margin-left: -81px; }

.brook-contagion-area .thumbnail {
  margin-left: -130px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .brook-contagion-area .thumbnail {
      margin-left: 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .brook-contagion-area .thumbnail {
      margin-left: 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .brook-contagion-area .thumbnail {
      margin-left: 0px;
      text-align: center;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 767px) {
    .brook-contagion-area .thumbnail {
      margin-left: 0px;
      text-align: center;
      margin-bottom: 30px; } }

/* =========================
    Foodie Css
============================*/
.foodie-slider {
  min-height: 690px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative; }
  .foodie-slider .foodie-slider-inner {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/img/others/foodie.jpg);
    background-repeat: repeat-x;
    background-size: cover; }

/*=========================
    Portfolio Slide 
============================*/
.single-portfolio-slide {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .single-portfolio-slide .fullscreen {
      min-height: auto;
      padding: 150px 0; } }
  .single-portfolio-slide .inner {
    text-align: left;
    max-width: 600px;
    background: #000;
    padding: 64px 68px 62px;
    position: relative;
    left: -100px; }
    @media only screen and (max-width: 767px) {
      .single-portfolio-slide .inner {
        left: 0;
        padding: 44px 33px 32px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-portfolio-slide .inner {
        left: -50px; } }
    .single-portfolio-slide .inner .info h3.heading {
      margin-bottom: 18px;
      font-family: "louis_george_caferegular"; }
      .single-portfolio-slide .inner .info h3.heading a {
        color: #ffffff; }
    .single-portfolio-slide .inner .info .category {
      margin-bottom: 36px; }
      .single-portfolio-slide .inner .info .category a {
        font-size: 16px;
        color: #999;
        font-family: "louis_george_caferegular"; }
    .single-portfolio-slide .inner .info .slide-btn a {
      box-shadow: none;
      border-radius: 40px;
      border: 2px solid rgba(255, 255, 255, 0.3);
      height: 57px;
      line-height: 53px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      background: transparent;
      padding: 0 36px;
      position: relative;
      display: inline-block; }
      .single-portfolio-slide .inner .info .slide-btn a:hover {
        background: #fff;
        color: #222;
        -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        transform: translateY(-3px); }

.single-photo-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-photo-slider {
      display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-photo-slider {
      display: block; } }
  @media only screen and (max-width: 767px) {
    .single-photo-slider {
      display: block; } }
  .single-photo-slider .image-wrapper {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 50%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-photo-slider .image-wrapper {
        width: 100%;
        padding: 0px 52px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-photo-slider .image-wrapper {
        width: 100%;
        padding: 0px 52px; } }
    @media only screen and (max-width: 767px) {
      .single-photo-slider .image-wrapper {
        width: 100%;
        padding: 0px 52px; } }
  .single-photo-slider .text-wrapper {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 50%;
    padding: 50px 100px; }
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      .single-photo-slider .text-wrapper {
        padding: 35px 52px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single-photo-slider .text-wrapper {
        padding: 35px 52px;
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-photo-slider .text-wrapper {
        padding: 35px 52px;
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .single-photo-slider .text-wrapper {
        padding: 35px 52px;
        width: 100%; } }
    .single-photo-slider .text-wrapper .text h1.heading {
      font-size: 70px;
      letter-spacing: -3px;
      line-height: 1.22;
      font-weight: 400;
      max-width: 400px;
      margin-bottom: 0; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-photo-slider .text-wrapper .text h1.heading {
          max-width: 100%; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-photo-slider .text-wrapper .text h1.heading {
          max-width: 100%;
          font-size: 58px; } }
      @media only screen and (max-width: 767px) {
        .single-photo-slider .text-wrapper .text h1.heading {
          max-width: 100%;
          font-size: 40px; } }

/*==============================
Vertical Slider Area
=================================*/
.vertical-bg-1 {
  background-image: url(/img/slider/vertical-slide/vertical-slide-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.vertical-bg-2 {
  background-image: url(/img/slider/vertical-slide/vertical-slide-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.vertical-bg-3 {
  background-image: url(/img/slider/vertical-slide/vertical-slide-3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.vertical-bg-4 {
  background-image: url(/img/slider/vertical-slide/vertical-slide-4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.vertical-bg-5 {
  background-image: url(/img/slider/vertical-slide/vertical-slide-5.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg_image--49 {
  background-repeat: no-repeat;
  background-position: top 73px center;
  background-size: initial; }

.vertical-what-do-area {
  padding-top: 167px !important;
  padding-bottom: 138px !important;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  box-sizing: border-box;
  width: 1803px;
  padding-left: 301.5px;
  padding-right: 301.5px; }
  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .vertical-what-do-area {
      width: auto;
      padding-left: 100px;
      padding-right: 100px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .vertical-what-do-area {
      width: auto;
      padding-left: 100px;
      padding-right: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .vertical-what-do-area {
      width: auto;
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 80px !important;
      padding-bottom: 80px !important; } }
  @media only screen and (max-width: 767px) {
    .vertical-what-do-area {
      width: auto;
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 80px !important;
      padding-bottom: 80px !important; } }
  @media only screen and (max-width: 575px) {
    .vertical-what-do-area {
      width: auto;
      padding-left: 50px;
      padding-right: 50px; } }

/*===============================
    Vertivcal Slider Inner
=====================================*/
@media only screen and (max-width: 767px) {
  .single-vertical-slide.fullscreen {
    height: auto;
    min-height: auto;
    padding: 150px 0; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-vertical-slide.fullscreen {
    height: auto;
    min-height: auto;
    padding: 150px 0; } }

.single-vertical-slide .slide-inner .info {
  padding: 30px 50px 66px; }
  .single-vertical-slide .slide-inner .info h2.heading {
    font-size: 48px;
    line-height: 1.4;
    margin-bottom: 57px;
    font-weight: 600; }
    .single-vertical-slide .slide-inner .info h2.heading a {
      color: #fff; }
  .single-vertical-slide .slide-inner .info .category {
    font-size: 14px;
    color: #fff; }

/*===============================
Creative People Area
=====================================*/
.creative-people-inner h2.heading {
  display: inline-block;
  padding: 2px 20px;
  background: #fff;
  font-size: 50px;
  line-height: 1.42; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .creative-people-inner h2.heading {
      font-size: 45px; } }
  @media only screen and (max-width: 767px) {
    .creative-people-inner h2.heading {
      font-size: 32px; } }

/* ========================
Indie Musician Area 
============================*/
.bg_image--52 {
  background-attachment: fixed; }

.musician-address .marker i {
  min-width: 40px;
  line-height: 44px;
  font-size: 37px;
  margin-right: 10px;
  color: #aaa; }

.cloud-mixtabs-inner i {
  color: #f57323;
  font-size: 90px; }

.cloud-mixtabs-inner button {
  padding: 0 36px;
  height: 55px;
  line-height: 53px;
  border-width: 1px;
  border-radius: 6px;
  background: transparent;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .cloud-mixtabs-inner button:hover {
    color: #F55D4E;
    border-color: #fff;
    background-color: #fff;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px); }

.cloud-mixtabs-audio .audio-post-inner {
  display: flex;
  padding: 10px;
  border-radius: 3px;
  overflow: hidden; }
  .cloud-mixtabs-audio .audio-post-inner .thumbnail {
    flex-grow: 0;
    width: 162px;
    height: 153px;
    margin: 1px;
    overflow: hidden;
    border-radius: 2px 0 0 2px;
    opacity: 1; }
    .cloud-mixtabs-audio .audio-post-inner .thumbnail img {
      background-size: cover;
      background-position: 50% 50%; }
  .cloud-mixtabs-audio .audio-post-inner .audio-post {
    flex-grow: 1; }

/* ==============================
Vertical Slider Portfolio
=================================*/
#fp-nav ul {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%) !important; }

.fullscreen-bg-1 {
  width: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-image: url(/img/slider/fullscreen/fullscreen-1.jpg); }

.fullscreen-bg-2 {
  width: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-image: url(/img/slider/fullscreen/fullscreen-2.jpg); }

.fullscreen-bg-3 {
  width: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-image: url(/img/slider/fullscreen/fullscreen-3.jpg); }

.fullpage {
  position: relative;
  z-index: 2;
  -webkit-transition: -webkit-transform .6s ease;
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease,-webkit-transform .6s ease; }

.vertical-fullscreen-header.headroom--sticky.headroom--not-top {
  box-shadow: none;
  animation: none; }

.vertical-fullscreen-header.headroom--sticky.headroom--not-top {
  background: transparent;
  background-color: transparent; }

.vertical-fullscreen-header.header-default .header__wrapper .header-left .logo a img.black {
  display: none; }

.vertical-fullscreen-header.headroom--sticky.headroom--not-top .header__wrapper .header-left .logo a img.black {
  display: block; }

.vertical-fullscreen-header.headroom--sticky.headroom--not-top .header__wrapper .header-left .logo a img.light {
  display: none; }

.vertical-fullscreen-header.header-default.header-transparent.position-from--top.headroom--not-top {
  padding-top: 24px; }

/*================================
 Fullscreen Slider Wrapper
 =================================*/
.fullscreen-slider-wrapper {
  height: 100%; }
  .fullscreen-slider-wrapper .feature-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%; }
    .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner {
      position: relative;
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-info {
        padding: 50px 50px 50px 150px; }
      .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character {
        font-size: 300px;
        position: absolute;
        top: 50%;
        right: 0;
        -webkit-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
        transform: translate(50%, -50%);
        z-index: 2;
        font-weight: 700;
        line-height: 1.38;
        font-family: "louis_george_caferegular"; }
        .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character.style-1 {
          color: #b40720; }
        .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character.style-2 {
          color: #c17d4b; }
        .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character.style-3 {
          color: #188a63; }
      .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner {
        max-width: 400px; }
        .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner .bk_pra {
          margin-bottom: 34px;
          font-weight: 700; }
        .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner h2.heading {
          position: relative;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
          .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner h2.heading a {
            color: inherit; }
          .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner h2.heading:hover {
            color: #FB6031; }
          .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner h2.heading span.post-number {
            position: absolute;
            top: 20px;
            left: -40px;
            font-size: 18px;
            color: #222222; }
        .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner .fullpage-btn a {
          font-size: 18px;
          font-weight: 700;
          color: #222;
          font-family: "louis_george_caferegular";
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
          .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner .fullpage-btn a .btn-icon {
            position: relative;
            content: "";
            width: 73px;
            top: -5px;
            height: 2px;
            display: inline-block;
            color: rgba(0, 0, 0, 0.1);
            background: currentColor;
            margin-left: 15px;
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
            .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner .fullpage-btn a .btn-icon::before {
              content: "";
              position: absolute;
              top: -7px;
              right: 1px;
              width: 16px;
              height: 16px;
              border: 2px solid currentColor;
              border-left: 0;
              border-bottom: 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transform-origin: 50% 50%;
              -ms-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }
          .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .inner .fullpage-btn a:hover .btn-icon {
            color: #222;
            -webkit-transform: translateX(5px);
            -ms-transform: translateX(5px);
            transform: translateX(5px); }

@media only screen and (max-width: 767px) {
  .vertical-slide-gradient-portfolio .cd-section {
    height: auto;
    padding: 10px 0; } }

/* =========================
Shop Slide Area
=============================*/
.shop-slide-bg-1 {
  width: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  background-image: url(/img/bg/bg-image-53.jpg); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-slide-bg-1 {
      background-position: 100%;
      width: 100%;
      padding: 150px 0; } }
  @media only screen and (max-width: 767px) {
    .shop-slide-bg-1 {
      background-position: 100%;
      width: 100%;
      padding: 150px 0; } }

.shop-slide-bg-2 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(/img/Coffeeshop/slider1.jpg); }

.shop-slide-bg-3 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(/img/architecture/slider1.jpg); }

.shop-slider-area {
  height: 100vh; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shop-slider-area {
      padding: 100px 0;
      padding-top: 150px; } }
  @media only screen and (max-width: 767px) {
    .shop-slider-area {
      padding: 100px 0;
      padding-top: 150px; } }
  .shop-slider-area .shop-slide-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: 100%; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .shop-slider-area .shop-slide-wrapper {
        flex-wrap: wrap; } }
    @media only screen and (max-width: 767px) {
      .shop-slider-area .shop-slide-wrapper {
        flex-wrap: wrap; } }
    .shop-slider-area .shop-slide-wrapper .shop-slide-inner {
      position: relative;
      width: 50%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .shop-slider-area .shop-slide-wrapper .shop-slide-inner {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .shop-slider-area .shop-slide-wrapper .shop-slide-inner {
          width: 100%; } }
      .shop-slider-area .shop-slide-wrapper .shop-slide-inner.shop-slide-info {
        padding: 50px 50px 50px 150px; }
        @media only screen and (max-width: 767px) {
          .shop-slider-area .shop-slide-wrapper .shop-slide-inner.shop-slide-info {
            padding: 9px 51px 73px 92px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .shop-slider-area .shop-slide-wrapper .shop-slide-inner.shop-slide-info {
            padding: 9px 51px 73px 92px; } }
        @media only screen and (max-width: 767px) {
          .shop-slider-area .shop-slide-wrapper .shop-slide-inner.shop-slide-info {
            padding: 9px 51px 73px 40px; } }
        .shop-slider-area .shop-slide-wrapper .shop-slide-inner.shop-slide-info .inner {
          max-width: 470px; }

/*==========================
    Product Area 
============================*/
.product-tab {
  margin-top: 30px; }
  .product-tab li {
    padding: 5px 25px; }
    @media only screen and (max-width: 767px) {
      .product-tab li {
        padding: 5px 15px; } }
    .product-tab li a {
      font-weight: 600;
      line-height: 1.58;
      font-size: 14px;
      position: relative;
      display: block;
      text-transform: uppercase;
      color: #222222; }
      .product-tab li a::after {
        content: "";
        width: 0;
        height: 1px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        z-index: 1;
        transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        background: currentColor; }
      .product-tab li a:hover::after, .product-tab li a.active::after {
        width: 100%;
        left: 0;
        right: auto; }

.product .product-thumbnail {
  margin-bottom: 28px; }
  .product .product-thumbnail .thumbnail {
    position: relative; }
    .product .product-thumbnail .thumbnail .product-main-image {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .product .product-thumbnail .thumbnail .product-main-image img {
        width: 100%;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .product .product-thumbnail .thumbnail .product-hover-image {
      -webkit-transition: all .6s;
      transition: all .6s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
      transform: translateY(10px); }
      .product .product-thumbnail .thumbnail .product-hover-image img {
        width: 100%;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .product .product-thumbnail .thumbnail .product-badges {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 2;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
      .product .product-thumbnail .thumbnail .product-badges .onsale {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin: 0 2px;
        min-height: 0;
        min-width: 0;
        border-radius: 10px 10px 10px 0;
        color: #fff;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
        line-height: 1.44;
        text-transform: uppercase;
        background: #d5382c;
        padding: 4px 8px;
        color: #fff; }

.product .product-info {
  text-align: center; }
  .product .product-info h5.heading {
    margin-bottom: 10px; }
    .product .product-info h5.heading a {
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: #222222; }
  .product .product-info ul.rating {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0 auto 13px; }
    .product .product-info ul.rating li i {
      color: #ffb805;
      font-size: 16px; }
  .product .product-info .price {
    font-size: 18px;
    font-weight: 600; }
    .product .product-info .price span {
      display: inline-block;
      color: #333333;
      font-family: "Poppins", sans-serif; }
      .product .product-info .price span.new-price {
        color: #d5382c; }

.product:hover .product-thumbnail .thumbnail .product-main-image img {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07); }

.product:hover .product-thumbnail .thumbnail .product-hover-image {
  z-index: 2;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }
  .product:hover .product-thumbnail .thumbnail .product-hover-image img {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.07); }

.product-tab-two {
  width: 100%;
  padding: 0 0 0 1px;
  text-align: center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }
  .product-tab-two li a {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.38px;
    width: 100%;
    padding: 14px 20px;
    display: block;
    color: #a6a6a6; }
    @media only screen and (max-width: 767px) {
      .product-tab-two li a {
        padding: 10px 10px; } }
    .product-tab-two li a:hover, .product-tab-two li a.active {
      color: #222222; }

.product-style-two {
  text-align: center; }
  .product-style-two .product-thumbnail .thumbnail {
    margin-bottom: 30px; }
  .product-style-two .product-info {
    text-align: center; }
    .product-style-two .product-info h5 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 9px; }
    .product-style-two .product-info .tag {
      font-size: 14px;
      font-style: italic;
      margin-bottom: 19px;
      display: block; }
    .product-style-two .product-info .price {
      font-size: 15px; }

.product .product-thumbnail {
  position: relative; }
  .product .product-thumbnail .product-action {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
    z-index: 9;
    text-align: center; }
    .product .product-thumbnail .product-action .action-list {
      padding: 0;
      margin: 0;
      list-style: none; }
      .product .product-thumbnail .product-action .action-list.tooltip-layout li.single-action {
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        display: inline-block;
        padding: 10px;
        margin: 0 !important; }
      .product .product-thumbnail .product-action .action-list.tooltip-layout li a {
        position: relative;
        display: block;
        width: 70px;
        height: 70px;
        line-height: 71px;
        border-radius: 0;
        color: #222;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
        border: 0;
        text-align: center; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a i {
          font-size: 18px; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a[class*=hint--] {
          position: relative; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--bounce:after, .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--bounce:before {
          -webkit-transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          transition: opacity 0.3s ease, visibility 0.3s ease, -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
          transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24), -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24); }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a[class*=hint--]:after, .product .product-thumbnail .product-action .action-list.tooltip-layout li a[class*=hint--]:before {
          position: absolute;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          visibility: hidden;
          opacity: 0;
          z-index: 1000000;
          pointer-events: none;
          -webkit-transition: .3s ease;
          transition: .3s ease;
          -webkit-transition-delay: 0ms;
          transition-delay: 0ms; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--top:after, .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--top:before {
          bottom: 100%;
          left: 50%; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a[class*=hint--]:before {
          content: "";
          position: absolute;
          background: transparent;
          border: 7px solid transparent;
          z-index: 1000001; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--top:before {
          left: calc(50% - 7px);
          margin-bottom: -7px; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--top:after {
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%); }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a[class*=hint--][aria-label]:after {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          line-height: 1.23;
          content: attr(aria-label); }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a[class*=hint--]:after {
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
          padding: 8px 10px;
          font-size: 14px;
          white-space: nowrap;
          border-radius: 5px; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--white[aria-label]::after {
          background: #fff;
          color: #111;
          text-shadow: none; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--white[aria-label]::before {
          border-top-color: #fff; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--theme[aria-label]::after {
          background: #0038E3;
          color: #ffffff;
          text-shadow: none; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--theme[aria-label]::before {
          border-top-color: #0038E3; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--dark[aria-label]::after {
          background: #222222;
          color: #ffffff;
          text-shadow: none; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a.hint--dark[aria-label]::before {
          border-top-color: #222222; }
        .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
          background-color: #0038E3;
          color: #fff;
          border-color: #0038E3;
          -webkit-transform: translateY(-3px);
          -ms-transform: translateY(-3px);
          transform: translateY(-3px); }
          .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover.hint--top[class*=hint--]:after, .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover.hint--top[class*=hint--]:before {
            visibility: visible;
            opacity: 1;
            -webkit-transition-delay: .1s;
            transition-delay: .1s; }
          .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover.hint--top::before {
            -webkit-transform: translateY(-8px);
            -ms-transform: translateY(-8px);
            transform: translateY(-8px); }
          .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover.hint--top::after {
            -webkit-transform: translateX(-50%) translateY(-15px);
            -ms-transform: translateX(-50%) translateY(-15px);
            transform: translateX(-50%) translateY(-15px); }

.product:hover .product-thumbnail .product-action .action-list.tooltip-layout li.single-action {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

.food-menu-wrap {
  margin-top: 63px;
  padding-top: 52px;
  padding-right: 27px;
  padding-bottom: 66px;
  padding-left: 27px;
  background-color: #faf7f4; }

.content-top {
  margin-top: -177px !important;
  margin-bottom: 70px !important;
  padding-top: 52px !important;
  padding-right: 27px !important;
  padding-bottom: 66px !important;
  padding-left: 27px !important; }

.menu-list {
  padding-left: 0px !important; }
  .menu-list .menu-item {
    list-style: none; }
    .menu-list .menu-item .menu-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
      .menu-list .menu-item .menu-header .menu-title {
        position: relative;
        z-index: 1;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0; }
      .menu-list .menu-item .menu-header .menu-separator {
        height: 1px;
        background: rgba(18, 18, 18, 0.08);
        flex-grow: 1;
        margin: 0 20px; }
      .menu-list .menu-item .menu-header .menu-price {
        font-size: 14px; }
    .menu-list .menu-item .menu-text {
      font-style: italic;
      font-size: 14px; }
  .menu-list li + li {
    margin-top: 35px; }

/* =====================
Revulion Area
========================*/
.revolution-slider-area.slider-bg-1 {
  background-image: url(/img/slider/revolution/slider-1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center; }

.revolution-slider-area .slider-bg-2 {
  background-image: url(/img/slider/revolution/slider-2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% -305%; }

.revolution-slider-area .inner h1.heading {
  line-height: 1.25;
  font-weight: 700;
  font-size: 120px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .revolution-slider-area .inner h1.heading {
      font-size: 70px; } }

.revolution-slider-area .inner .slider-btn {
  margin-top: 45px; }
  .revolution-slider-area .inner .slider-btn a {
    display: inline-block;
    min-height: 55px;
    line-height: 51px;
    padding: 0 36px;
    height: 55px;
    line-height: 51px;
    border-radius: 6px;
    background: transparent;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border: 2px solid #fff;
    color: #fff; }
    .revolution-slider-area .inner .slider-btn a:hover {
      -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      transform: translateY(-3px);
      background: #fff;
      border-color: #fff;
      color: #222; }

.max-width--990 {
  max-width: 990px;
  margin: auto; }

.revulutionlink h3.heading {
  position: relative;
  display: inline-block;
  margin-top: 25px; }
  @media only screen and (max-width: 767px) {
    .revulutionlink h3.heading {
      font-size: 20px; } }
  .revulutionlink h3.heading::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 15px;
    opacity: .4;
    background: currentColor; }

.rvbody {
  transition: background 1s ease;
  background: #3d088e; }

.scroll-bt-100 {
  bottom: 100px; }
  @media only screen and (min-width: 1300px) and (max-width: 1599px) {
    .scroll-bt-100 {
      bottom: 230px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .scroll-bt-100 {
      bottom: 80px; } }
  @media only screen and (min-width: 1021px) and (max-width: 1090px) {
    .scroll-bt-100 {
      bottom: 0 !important; } }
  @media only screen and (max-width: 767px) {
    .scroll-bt-100 {
      bottom: 0px; } }

/* -----------------------------
Prresentation Area
-------------------------------*/
@media only screen and (max-width: 767px) {
  .presentation-home {
    height: auto !important;
    padding: 200px 0; } }

.presentation-home .bg-image-1 {
  background-image: url(/img/slider/presentation/home-presentation-image-01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.presentation-home .bg-image-2 {
  background-image: url(/img/slider/presentation/home-presentation-image-02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative; }
  .presentation-home .bg-image-2::before {
    position: absolute;
    background: #000;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8; }

.presentation-home .bg-image-3 {
  background-image: url(/img/slider/presentation/home-presentation-image-05.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.header-default.light-logo--version .header__wrapper .header-left .logo a img {
  display: block; }

.header-default.light-logo--version .header__wrapper .header-left .logo a img.black {
  display: none; }

.header-default.light-logo--version .header__wrapper .header-left .logo a img.light {
  display: block; }

.header-default.black-logo--version .header__wrapper .header-left .logo a img.light {
  display: none; }

.header-default.black-logo--version .header__wrapper .header-left .logo a img.black {
  display: block; }

.header-default.black-logo--version .manu-hamber.light-version {
  color: #222222; }

.header-default.light-logo--version .manu-hamber.light-version {
  color: #ffffff; }

.presentation-slider-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center; }
  .presentation-slider-wrapper .presentation-inner {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1;
    height: 100%;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .presentation-slider-wrapper .presentation-inner {
        display: block;
        height: auto; } }
    .presentation-slider-wrapper .presentation-inner.contact-address-wrapper .inner {
      text-align: left;
      width: 100%; }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .presentation-slider-wrapper .presentation-inner.contact-address-wrapper .inner {
          padding-left: 81px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .presentation-slider-wrapper .presentation-inner.contact-address-wrapper .inner {
          padding-left: 81px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .presentation-slider-wrapper .presentation-inner.contact-address-wrapper .inner {
          padding-left: 81px; } }
      @media only screen and (max-width: 767px) {
        .presentation-slider-wrapper .presentation-inner.contact-address-wrapper .inner {
          padding: 0 30px; } }
    .presentation-slider-wrapper .presentation-inner .inner {
      text-align: center; }
      .presentation-slider-wrapper .presentation-inner .inner.slider h2 {
        font-size: 110px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .presentation-slider-wrapper .presentation-inner .inner.slider h2 {
            font-size: 70px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .presentation-slider-wrapper .presentation-inner .inner.slider h2 {
            font-size: 60px; } }
        @media only screen and (max-width: 767px) {
          .presentation-slider-wrapper .presentation-inner .inner.slider h2 {
            font-size: 50px; } }
      @media only screen and (min-width: 1200px) and (max-width: 1599px) {
        .presentation-slider-wrapper .presentation-inner .inner.about-inner {
          padding-left: 81px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .presentation-slider-wrapper .presentation-inner .inner.about-inner {
          padding-left: 81px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .presentation-slider-wrapper .presentation-inner .inner.about-inner {
          padding-left: 81px; } }
      @media only screen and (max-width: 767px) {
        .presentation-slider-wrapper .presentation-inner .inner.about-inner {
          padding: 0 30px; } }
      .presentation-slider-wrapper .presentation-inner .inner.about-inner .row {
        background-image: url(/img/slider/presentation/bgshape.jpg);
        background-repeat: no-repeat;
        background-position: left -22px center;
        background-size: contain; }
      .presentation-slider-wrapper .presentation-inner .inner.about-inner .content {
        text-align: left;
        width: 472px;
        max-width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center; }
        @media only screen and (max-width: 767px) {
          .presentation-slider-wrapper .presentation-inner .inner.about-inner .content {
            width: auto; } }
        .presentation-slider-wrapper .presentation-inner .inner.about-inner .content h6 {
          text-transform: uppercase; }
        .presentation-slider-wrapper .presentation-inner .inner.about-inner .content h2 {
          font-family: "louis_george_caferegular";
          font-size: 48px;
          line-height: 1.11;
          word-wrap: break-word;
          max-width: 100%; }
          @media only screen and (max-width: 767px) {
            .presentation-slider-wrapper .presentation-inner .inner.about-inner .content h2 {
              font-size: 32px; } }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .presentation-slider-wrapper .presentation-inner .inner.about-inner .content h2 {
              font-size: 35px; } }
        .presentation-slider-wrapper .presentation-inner .inner.about-inner .content p {
          font-family: "louis_george_caferegular";
          font-weight: 700; }
      .presentation-slider-wrapper .presentation-inner .inner.about-service .row {
        align-items: center; }
      .presentation-slider-wrapper .presentation-inner .inner.skill-area {
        text-align: left;
        padding-left: 360px;
        padding-right: 360px;
        min-height: 563px;
        background-image: url(/img/slider/presentation/home-presentation-image-04.jpg);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: top right;
        height: 100%;
        display: flex;
        align-items: center; }
        @media only screen and (min-width: 1200px) and (max-width: 1599px) {
          .presentation-slider-wrapper .presentation-inner .inner.skill-area {
            padding-left: 24px;
            padding-right: 100px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .presentation-slider-wrapper .presentation-inner .inner.skill-area {
            padding-left: 50px;
            padding-right: 50px;
            background-image: none; } }
        @media only screen and (max-width: 767px) {
          .presentation-slider-wrapper .presentation-inner .inner.skill-area {
            padding-left: 30px;
            padding-right: 30px;
            background-image: none; } }

#fp-nav ul li a {
  width: 10px;
  height: 10px;
  background-color: #eee;
  display: inline-block;
  border-radius: 100%; }

#fp-nav ul li a.active {
  background-color: #0038E3; }

#fp-nav ul {
  position: absolute;
  top: 55%;
  z-index: 3;
  right: 67px;
  padding: 0;
  margin: 0;
  list-style: none; }

@media only screen and (max-width: 767px) {
  #fp-nav ul {
    display: none; }
  .fp-tableCell {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner.feature-info {
    padding: 50px 50px 50px 50px;
    padding-top: 150px;
    padding-bottom: 100px; }
  .fullpage-btn.mt--130 {
    margin-top: 50px; } }

/*-----------------------------
 * Footer Area
--------------------------------*/
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .essential-footer {
    padding: 0 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .essential-footer {
    padding: 0 15px; } }

/* Footer Widget */
.footer-widget h2.widgettitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0);
  margin-bottom: 19px;
  padding-bottom: 13px;
  font-family: "Poppins", sans-serif; }

.footer-widget .footer-menu ul.ft-menu-list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .footer-widget .footer-menu ul.ft-menu-list li {
    display: block; }
    .footer-widget .footer-menu ul.ft-menu-list li a {
      display: inline-block;
      color: #ffffff;
      display: inline-block;
      padding: 3px 0;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0em;
      line-height: 2;
      font-family: "louis_george_caferegular";
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .footer-widget .footer-menu ul.ft-menu-list li a:hover {
        color: #fff; }

.footer-widget.dark-text .footer-menu ul.ft-menu-list li a:hover {
  color: #222222; }

.footer-widget .footer-address p {
  margin-bottom: 0;
  word-wrap: break-word;
  max-width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  font-family: "louis_george_caferegular";
  color: #ffffff; }
  .footer-widget .footer-address p a {
    padding: 4px 0;
    line-height: 10px;
    color: #ffffff;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .footer-widget .footer-address p a:hover {
      color: #fff; }

.footer-widget .footer-address.dark-text p a:hover {
  color: #222222; }

.footer-widget .footer-address .social-share {
  margin-top: 28px; }

.footer-widget .instagram-grid-wrap {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px; }

.footer-widget .footer-inner {
  margin-top: 28px; }
  .footer-widget .footer-inner p {
    max-width: 300px;
    line-height: 1.875;
    font-size: 16px;
    margin-bottom: 0;
    word-wrap: break-word;
    color: #fff; }

.footer-widget.text-var--2 .footer-menu ul.ft-menu-list li a {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 2; }

.footer-widget.text-var--2 .footer-address p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif; }
  .footer-widget.text-var--2 .footer-address p a {
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif; }

.footer-widget.news-bulletin .heading {
  color: #999; }

.footer-widget.news-bulletin .link .heading a {
  position: relative;
  padding: 2px 0;
  overflow: hidden;
  z-index: 1;
  color: #222222;
  padding-bottom: 6px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .footer-widget.news-bulletin .link .heading a::before {
    width: 100% !important;
    right: 0 !important;
    left: auto !important;
    z-index: -2;
    background: #ddd;
    content: "";
    width: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: auto;
    right: 0;
    z-index: -1;
    -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s; }
  .footer-widget.news-bulletin .link .heading a:hover {
    color: #0038E3; }

.minimal-portfolio-footer .bk-hover a:hover {
  color: #222222; }

/* =====================
    Fixed Footer 
======================*/
.all-wrapper {
  position: relative;
  z-index: 3; }

.page-footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; }

/* -------------------------
    Copyright Variation 
----------------------------*/
.copyright .copyright-left ul.bk-copyright-menu,
.copyright .copyright-right ul.bk-copyright-menu {
  list-style: none;
  padding: 0;
  margin: 0 -12px; }
  .copyright .copyright-left ul.bk-copyright-menu li,
  .copyright .copyright-right ul.bk-copyright-menu li {
    padding: 5px 12px; }
    @media only screen and (max-width: 575px) {
      .copyright .copyright-left ul.bk-copyright-menu li,
      .copyright .copyright-right ul.bk-copyright-menu li {
        padding: 5px 6px; } }
    .copyright .copyright-left ul.bk-copyright-menu li a,
    .copyright .copyright-right ul.bk-copyright-menu li a {
      line-height: 1.6;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 14px;
      color: #ffffff; }
      .copyright .copyright-left ul.bk-copyright-menu li a:hover,
      .copyright .copyright-right ul.bk-copyright-menu li a:hover {
        color: #fff; }
  .copyright .copyright-left ul.bk-copyright-menu.hover-text-dark li a:hover,
  .copyright .copyright-right ul.bk-copyright-menu.hover-text-dark li a:hover {
    color: #222222; }
  .copyright .copyright-left ul.bk-copyright-menu.minimal-copyright li a,
  .copyright .copyright-right ul.bk-copyright-menu.minimal-copyright li a {
    font-weight: 700; }

.copyright .copyright-right p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  font-family: "louis_george_caferegular";
  color: #ffffff; }
  .copyright .copyright-right p a {
    color: #fff; }

.copyright.text-var-2 .copyright-left ul.bk-copyright-menu li a {
  font-family: "Poppins", sans-serif;
  font-size: 14px; }

.copyright.text-var-2 .copyright-right p {
  font-family: "Poppins", sans-serif; }

.copyright.copyright--2 .copyright-left p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.86;
  font-family: "Montserrat", sans-serif;
  color: #999; }

.copyright.copyright--2 .copyright-right {
  width: 430px;
  max-width: 100%;
  float: right; }
  @media only screen and (max-width: 767px) {
    .copyright.copyright--2 .copyright-right {
      float: inherit; } }
  .copyright.copyright--2 .copyright-right .input-box {
    position: relative; }
    .copyright.copyright--2 .copyright-right .input-box input {
      padding-left: 0;
      padding-right: 65px;
      height: 46px;
      border: 0;
      border-bottom: 2px solid rgba(255, 255, 255, 0.23);
      background: transparent; }
    .copyright.copyright--2 .copyright-right .input-box button {
      border: 0 !important;
      border-radius: 0;
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      width: 46px;
      height: 100%;
      padding: 0;
      box-shadow: none;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      color: #fff; }

.minimal-home-address {
  width: 700px;
  max-width: 100%; }

.bk-copyright-payment div {
  padding: 4px; }

/* ==========================
Extra Footer 
==============================*/
.page-extra-footer .page-left-text {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 1.5em;
  left: 40px;
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  transform: rotate(-90deg) translate(-50%, 50%); }
  @media only screen and (max-width: 767px) {
    .page-extra-footer .page-left-text {
      z-index: 99;
      background: #fff;
      padding: 0 15px; } }

.page-extra-footer .page-right-text {
  position: fixed;
  bottom: 200px;
  right: 0; }
  @media only screen and (max-width: 767px) {
    .page-extra-footer .page-right-text {
      position: static;
      background: transparent;
      padding-bottom: 15px;
      text-align: center; } }
  .page-extra-footer .page-right-text p.bk_pra.heading-font {
    transform: rotate(90deg); }
    @media only screen and (max-width: 767px) {
      .page-extra-footer .page-right-text p.bk_pra.heading-font {
        transform: rotate(0deg); } }

/*================================
	Color Variation
=================================*/
/* ===========================
    Color Two Variation 
=============================*/
body.template-color-1 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #0038E3; }

body.template-color-1 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #0038E3; }

body.template-color-1 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #0038E3; }

body.template-color-1 .faq-content h2 {
  color: #0038E3; }

body.template-color-1 .brook-section-title-corona h2 {
  color: #0038E3; }

body.template-color-1 .single-contagion .contagion-content h3 {
  color: #0038E3; }

body.template-color-1 .single-sympotom .sympotom-content h3 {
  color: #0038E3; }

body.template-color-1 .single-prevination .prevination-content h3 {
  color: #0038E3; }

body.template-color-1 .service.service--1.theme-color {
  background: #0038E3; }

body.template-color-1 .blog-grid-minimal.outline.bg-transparant {
  border-color: #0038E3; }
  body.template-color-1 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #0038E3; }

body.template-color-1 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-1 .single-svg-icon-box.business-icon-box:hover {
  background: #0038E3; }

body.template-color-1 .product_review .description_nav a.active, body.template-color-1 .product_review .description_nav a:hover {
  border-bottom: 3px solid #0038E3; }

body.template-color-1 .single-product-details-side .product-action a {
  border-color: #0038E3;
  background-color: #0038E3; }

body.template-color-1 .cart-table td.pro-subtotal span {
  color: #0038E3; }

body.template-color-1 .cart-table td.pro-addtocart button {
  border: 1px solid #0038E3;
  background-color: #0038E3; }

body.template-color-1 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #0038E3; }

body.template-color-1 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #0038E3; }

body.template-color-1 .slider-business .video-btn a .video-icon.secondary-color {
  background: #0038E3 !important; }

body.template-color-1 a.brook-btn.bk-btn-yellow, body.template-color-1 button.brook-btn.bk-btn-yellow {
  background: #0038E3;
  color: #fff !important; }

body.template-color-1 a.bk-btn.theme-btn, body.template-color-1 button.bk-btn.theme-btn {
  background-color: #0038E3; }

body.template-color-1 a.brook-btn.bk-btn-red, body.template-color-1 button.brook-btn.bk-btn-red {
  background: #0038E3; }

body.template-color-1 .section-title h3 {
  color: #0038E3; }

body.template-color-1 .service.service--1:hover::before {
  border-color: #0038E3; }

body.template-color-1 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-1 .service.service--1 .icons i {
    color: #0038E3;
    font-size: 80px; }

body.template-color-1 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #0038E3;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-1 .service.service--1 .content a.service-btn:hover {
  color: #0038E3; }
  body.template-color-1 .service.service--1 .content a.service-btn:hover i {
    color: #0038E3; }

body.template-color-1 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-1 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-1 .service.service--3 .content h3 a:hover {
  color: #0038E3; }

body.template-color-1 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-1 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-1 .title-separator.color-red::after, body.template-color-1 .title-separator.color-blue::after {
    background: #0038E3; }

body.template-color-1 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-1 .bk-gradation .item-grid .dot-wrap .dot {
    background: #0038E3; }
  body.template-color-1 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #0038E3; }
  body.template-color-1 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #0038E3; }
  body.template-color-1 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #0038E3; }

body.template-color-1 .post-nav-lisnt .nav-item a:hover {
  background: #0038E3; }

body.template-color-1 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #0038E3; }

body.template-color-1 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #0038E3; }

body.template-color-1 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #0038E3; }

body.template-color-1 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #0038E3; }

body.template-color-1 .team.team__style--2 .thumb .overlay {
  background-color: #0038E3; }
  body.template-color-1 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #0038E3; }

body.template-color-1 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-1 .team.team__style--3 .thumb .overlay {
  background-color: #0038E3; }
  body.template-color-1 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #0038E3; }

body.template-color-1 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-1 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #0038E3; }
  body.template-color-1 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #0038E3; }
  body.template-color-1 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #0038E3; }
  body.template-color-1 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-1 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #0038E3; }
  body.template-color-1 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #0038E3;
    color: #fff; }

body.template-color-1 [data-overlay]:before {
  background-color: #0038E3; }

body.template-color-1 .team-heading .heading-right button i {
  color: #0038E3; }

body.template-color-1 .team-heading .heading-right button:hover {
  color: #0038E3 !important; }

body.template-color-1 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #0038E3; }

body.template-color-1 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-1 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #0038E3; }

body.template-color-1 .mini-cart .shopping-cart {
  border-bottom: 2px solid #0038E3; }
  body.template-color-1 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #0038E3; }
  body.template-color-1 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #0038E3; }
  body.template-color-1 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #0038E3;
    background-color: #0038E3; }

body.template-color-1 .brook__toolbar .inner a:hover {
  color: #0038E3; }

body.template-color-1 .blog-grid .post-inner h5.heading:hover {
  color: #0038E3; }
  body.template-color-1 .blog-grid .post-inner h5.heading:hover a {
    color: #0038E3; }

body.template-color-1 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #0038E3; }

body.template-color-1 .theme-color {
  color: #0038E3; }

body.template-color-1 .bg_color--2 {
  background: #0038E3; }

body.template-color-1 a.brook-btn.bk-btn-theme,
body.template-color-1 button.brook-btn.bk-btn-theme {
  background: #0038E3;
  color: #fff; }

body.template-color-1 .btn-essential:hover {
  background: #0038E3 !important;
  border-color: #0038E3 !important;
  color: #fff; }

body.template-color-1 .red-border {
  border-color: #0038E3 !important; }

body.template-color-1 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #0038E3;
  color: #fff; }

body.template-color-1 .single-svg-icon-box .svg-icon svg * {
  stroke: #0038E3 !important; }

body.template-color-1 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-1 .menu-price {
  color: #0038E3; }

body.template-color-1 a.moredetails-btn:hover {
  color: #0038E3; }

body.template-color-1 a.moredetails-btn i {
  color: #0038E3; }

body.template-color-1 .messonry-button button.is-checked span.filter-text {
  color: #0038E3; }

body.template-color-1 .messonry-button button:hover span.filter-text {
  color: #0038E3; }

body.template-color-1 .messonry-button button span.filter-counter {
  background-color: #0038E3; }
  body.template-color-1 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #0038E3;
    border-top-color: #0038E3; }

body.template-color-1 .messonry-button button:hover span.filter-text {
  color: #0038E3; }

body.template-color-1 .messonry-button button.is-checked span.filter-text {
  color: #0038E3; }

body.template-color-1 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #0038E3; }

body.template-color-1 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #0038E3; }

body.template-color-1 .testimonial_style--1 .content .testimonial-quote span {
  color: #0038E3; }

body.template-color-1 .testimonial_style--1:hover {
  background: #0038E3; }
  body.template-color-1 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-1 .contact-form.contact-form--4 .input-box button {
  color: #0038E3; }
  body.template-color-1 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #0038E3; }

body.template-color-1 .contact-form.contact-form--3 .input-box button {
  color: #0038E3; }

body.template-color-1 .animated-dot {
  background-color: #0038E3; }
  body.template-color-1 .animated-dot .middle-dot {
    background-color: #0038E3; }
  body.template-color-1 .animated-dot .signal {
    background-color: #0038E3; }
  body.template-color-1 .animated-dot .signal2 {
    background-color: #0038E3; }

body.template-color-1 .progress-charts .progress .progress-bar {
  background-color: #0038E3; }

body.template-color-1 .brook-counter .icon i {
  color: #0038E3; }

body.template-color-1 .social-share a:hover {
  color: #0038E3; }

body.template-color-1 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #0038E3; }

body.template-color-1 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #0038E3; }

body.template-color-1 .video-btn a.video-icon,
body.template-color-1 .video-btn a .video-icon {
  background: #0038E3; }

body.template-color-1 .video-btn-2 .icon-gradient::before {
  background-color: #0038E3;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #0038E3 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #0038E3 100%);
  background-image: linear-gradient(-133deg, #899664 0, #0038E3 100%); }

body.template-color-1 .video-btn-2 .icon-gradient::after {
  background-color: #0038E3;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #0038E3 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #0038E3 100%);
  background-image: linear-gradient(-133deg, #899664 0, #0038E3 100%); }

body.template-color-1 .single-motive-speach .icon i {
  color: #0038E3; }

body.template-color-1 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #0038E3; }
  body.template-color-1 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #0038E3; }

body.template-color-1 .contact-form input:focus,
body.template-color-1 .contact-form textarea:focus {
  border-color: #0038E3; }

body.template-color-1 .contact-form input[type="submit"],
body.template-color-1 .contact-form textarea[type="submit"] {
  border-color: #0038E3;
  background-color: #0038E3; }

body.template-color-1 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #0038E3;
  color: #fff;
  border-color: #0038E3; }

body.template-color-1 .gallery .hover-overlay {
  background-color: #0038E3; }

body.template-color-1 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #0038E3; }

body.template-color-1 .blog-grid .post-content::before {
  background-color: #0038E3; }

body.template-color-1 .blog-grid-no-flex .post-quote::before {
  color: #0038E3; }

body.template-color-1 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #0038E3; }
  body.template-color-1 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #0038E3; }

body.template-color-1 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #0038E3; }

body.template-color-1 .bl-sidebar.search button:hover {
  background-color: #0038E3; }

body.template-color-1 .bl-sidebar.search button span {
  color: #0038E3; }

body.template-color-1 .bl-sidebar.category .category-list li a:hover {
  color: #0038E3; }

body.template-color-1 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #0038E3; }

body.template-color-1 .social-icon.icon-solid-rounded li a:hover {
  background-color: #0038E3; }

body.template-color-1 .icon-box .inner .icon {
  color: #0038E3; }

body.template-color-1 .basic-thick-line-theme-4 {
  border-top: 4px solid #0038E3; }

body.template-color-1 .procing-box .header .feature-mark {
  background-color: #0038E3; }

body.template-color-1 a.brook-btn.bk-btn-dark:hover {
  background: #0038E3; }

body.template-color-1 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #0038E3; }

body.template-color-1 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #0038E3; }

body.template-color-1 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #0038E3 !important; }

body.template-color-1 .basic-modern-dots .dot {
  background-color: #0038E3; }

body.template-color-1 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #0038E3; }

body.template-color-1 a.brook-btn.bk-btn-theme-border,
body.template-color-1 button.brook-btn.bk-btn-theme-border {
  border-color: #0038E3;
  color: #0038E3; }
  body.template-color-1 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-1 button.brook-btn.bk-btn-theme-border:hover {
    background: #0038E3;
    color: #ffffff; }

body.template-color-1 .brook-quote::before {
  color: #0038E3; }

body.template-color-1 .bk-text-box.bg-theme-color {
  background: #0038E3; }

body.template-color-1 .bk-list--2 .list-header.with-number--theme .marker {
  color: #0038E3; }

body.template-color-1 .view-more-btn a span.btn-arrow {
  color: #0038E3; }

body.template-color-1 #fp-nav ul li a.active {
  background-color: #0038E3; }

body.template-color-2 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #FE378C; }

body.template-color-2 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #FE378C; }

body.template-color-2 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #FE378C; }

body.template-color-2 .faq-content h2 {
  color: #FE378C; }

body.template-color-2 .brook-section-title-corona h2 {
  color: #FE378C; }

body.template-color-2 .single-contagion .contagion-content h3 {
  color: #FE378C; }

body.template-color-2 .single-sympotom .sympotom-content h3 {
  color: #FE378C; }

body.template-color-2 .single-prevination .prevination-content h3 {
  color: #FE378C; }

body.template-color-2 .service.service--1.theme-color {
  background: #FE378C; }

body.template-color-2 .blog-grid-minimal.outline.bg-transparant {
  border-color: #FE378C; }
  body.template-color-2 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #FE378C; }

body.template-color-2 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-2 .single-svg-icon-box.business-icon-box:hover {
  background: #FE378C; }

body.template-color-2 .product_review .description_nav a.active, body.template-color-2 .product_review .description_nav a:hover {
  border-bottom: 3px solid #FE378C; }

body.template-color-2 .single-product-details-side .product-action a {
  border-color: #FE378C;
  background-color: #FE378C; }

body.template-color-2 .cart-table td.pro-subtotal span {
  color: #FE378C; }

body.template-color-2 .cart-table td.pro-addtocart button {
  border: 1px solid #FE378C;
  background-color: #FE378C; }

body.template-color-2 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #FE378C; }

body.template-color-2 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #FE378C; }

body.template-color-2 .slider-business .video-btn a .video-icon.secondary-color {
  background: #FE378C !important; }

body.template-color-2 a.brook-btn.bk-btn-yellow, body.template-color-2 button.brook-btn.bk-btn-yellow {
  background: #FE378C;
  color: #fff !important; }

body.template-color-2 a.bk-btn.theme-btn, body.template-color-2 button.bk-btn.theme-btn {
  background-color: #FE378C; }

body.template-color-2 a.brook-btn.bk-btn-red, body.template-color-2 button.brook-btn.bk-btn-red {
  background: #FE378C; }

body.template-color-2 .section-title h3 {
  color: #FE378C; }

body.template-color-2 .service.service--1:hover::before {
  border-color: #FE378C; }

body.template-color-2 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-2 .service.service--1 .icons i {
    color: #FE378C;
    font-size: 80px; }

body.template-color-2 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #FE378C;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-2 .service.service--1 .content a.service-btn:hover {
  color: #FE378C; }
  body.template-color-2 .service.service--1 .content a.service-btn:hover i {
    color: #FE378C; }

body.template-color-2 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-2 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-2 .service.service--3 .content h3 a:hover {
  color: #FE378C; }

body.template-color-2 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-2 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-2 .title-separator.color-red::after, body.template-color-2 .title-separator.color-blue::after {
    background: #FE378C; }

body.template-color-2 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-2 .bk-gradation .item-grid .dot-wrap .dot {
    background: #FE378C; }
  body.template-color-2 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #FE378C; }
  body.template-color-2 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #FE378C; }
  body.template-color-2 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #FE378C; }

body.template-color-2 .post-nav-lisnt .nav-item a:hover {
  background: #FE378C; }

body.template-color-2 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #FE378C; }

body.template-color-2 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #FE378C; }

body.template-color-2 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #FE378C; }

body.template-color-2 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #FE378C; }

body.template-color-2 .team.team__style--2 .thumb .overlay {
  background-color: #FE378C; }
  body.template-color-2 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #FE378C; }

body.template-color-2 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-2 .team.team__style--3 .thumb .overlay {
  background-color: #FE378C; }
  body.template-color-2 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #FE378C; }

body.template-color-2 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-2 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #FE378C; }
  body.template-color-2 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #FE378C; }
  body.template-color-2 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #FE378C; }
  body.template-color-2 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-2 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #FE378C; }
  body.template-color-2 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #FE378C;
    color: #fff; }

body.template-color-2 [data-overlay]:before {
  background-color: #FE378C; }

body.template-color-2 .team-heading .heading-right button i {
  color: #FE378C; }

body.template-color-2 .team-heading .heading-right button:hover {
  color: #FE378C !important; }

body.template-color-2 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #FE378C; }

body.template-color-2 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-2 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #FE378C; }

body.template-color-2 .mini-cart .shopping-cart {
  border-bottom: 2px solid #FE378C; }
  body.template-color-2 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #FE378C; }
  body.template-color-2 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #FE378C; }
  body.template-color-2 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #FE378C;
    background-color: #FE378C; }

body.template-color-2 .brook__toolbar .inner a:hover {
  color: #FE378C; }

body.template-color-2 .blog-grid .post-inner h5.heading:hover {
  color: #FE378C; }
  body.template-color-2 .blog-grid .post-inner h5.heading:hover a {
    color: #FE378C; }

body.template-color-2 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #FE378C; }

body.template-color-2 .theme-color {
  color: #FE378C; }

body.template-color-2 .bg_color--2 {
  background: #FE378C; }

body.template-color-2 a.brook-btn.bk-btn-theme,
body.template-color-2 button.brook-btn.bk-btn-theme {
  background: #FE378C;
  color: #fff; }

body.template-color-2 .btn-essential:hover {
  background: #FE378C !important;
  border-color: #FE378C !important;
  color: #fff; }

body.template-color-2 .red-border {
  border-color: #FE378C !important; }

body.template-color-2 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #FE378C;
  color: #fff; }

body.template-color-2 .single-svg-icon-box .svg-icon svg * {
  stroke: #FE378C !important; }

body.template-color-2 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-2 .menu-price {
  color: #FE378C; }

body.template-color-2 a.moredetails-btn:hover {
  color: #FE378C; }

body.template-color-2 a.moredetails-btn i {
  color: #FE378C; }

body.template-color-2 .messonry-button button.is-checked span.filter-text {
  color: #FE378C; }

body.template-color-2 .messonry-button button:hover span.filter-text {
  color: #FE378C; }

body.template-color-2 .messonry-button button span.filter-counter {
  background-color: #FE378C; }
  body.template-color-2 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #FE378C;
    border-top-color: #FE378C; }

body.template-color-2 .messonry-button button:hover span.filter-text {
  color: #FE378C; }

body.template-color-2 .messonry-button button.is-checked span.filter-text {
  color: #FE378C; }

body.template-color-2 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #FE378C; }

body.template-color-2 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #FE378C; }

body.template-color-2 .testimonial_style--1 .content .testimonial-quote span {
  color: #FE378C; }

body.template-color-2 .testimonial_style--1:hover {
  background: #FE378C; }
  body.template-color-2 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-2 .contact-form.contact-form--4 .input-box button {
  color: #FE378C; }
  body.template-color-2 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #FE378C; }

body.template-color-2 .contact-form.contact-form--3 .input-box button {
  color: #FE378C; }

body.template-color-2 .animated-dot {
  background-color: #FE378C; }
  body.template-color-2 .animated-dot .middle-dot {
    background-color: #FE378C; }
  body.template-color-2 .animated-dot .signal {
    background-color: #FE378C; }
  body.template-color-2 .animated-dot .signal2 {
    background-color: #FE378C; }

body.template-color-2 .progress-charts .progress .progress-bar {
  background-color: #FE378C; }

body.template-color-2 .brook-counter .icon i {
  color: #FE378C; }

body.template-color-2 .social-share a:hover {
  color: #FE378C; }

body.template-color-2 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #FE378C; }

body.template-color-2 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #FE378C; }

body.template-color-2 .video-btn a.video-icon,
body.template-color-2 .video-btn a .video-icon {
  background: #FE378C; }

body.template-color-2 .video-btn-2 .icon-gradient::before {
  background-color: #FE378C;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FE378C 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FE378C 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FE378C 100%); }

body.template-color-2 .video-btn-2 .icon-gradient::after {
  background-color: #FE378C;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FE378C 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FE378C 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FE378C 100%); }

body.template-color-2 .single-motive-speach .icon i {
  color: #FE378C; }

body.template-color-2 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #FE378C; }
  body.template-color-2 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #FE378C; }

body.template-color-2 .contact-form input:focus,
body.template-color-2 .contact-form textarea:focus {
  border-color: #FE378C; }

body.template-color-2 .contact-form input[type="submit"],
body.template-color-2 .contact-form textarea[type="submit"] {
  border-color: #FE378C;
  background-color: #FE378C; }

body.template-color-2 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #FE378C;
  color: #fff;
  border-color: #FE378C; }

body.template-color-2 .gallery .hover-overlay {
  background-color: #FE378C; }

body.template-color-2 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #FE378C; }

body.template-color-2 .blog-grid .post-content::before {
  background-color: #FE378C; }

body.template-color-2 .blog-grid-no-flex .post-quote::before {
  color: #FE378C; }

body.template-color-2 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #FE378C; }
  body.template-color-2 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #FE378C; }

body.template-color-2 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #FE378C; }

body.template-color-2 .bl-sidebar.search button:hover {
  background-color: #FE378C; }

body.template-color-2 .bl-sidebar.search button span {
  color: #FE378C; }

body.template-color-2 .bl-sidebar.category .category-list li a:hover {
  color: #FE378C; }

body.template-color-2 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #FE378C; }

body.template-color-2 .social-icon.icon-solid-rounded li a:hover {
  background-color: #FE378C; }

body.template-color-2 .icon-box .inner .icon {
  color: #FE378C; }

body.template-color-2 .basic-thick-line-theme-4 {
  border-top: 4px solid #FE378C; }

body.template-color-2 .procing-box .header .feature-mark {
  background-color: #FE378C; }

body.template-color-2 a.brook-btn.bk-btn-dark:hover {
  background: #FE378C; }

body.template-color-2 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #FE378C; }

body.template-color-2 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #FE378C; }

body.template-color-2 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #FE378C !important; }

body.template-color-2 .basic-modern-dots .dot {
  background-color: #FE378C; }

body.template-color-2 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #FE378C; }

body.template-color-2 a.brook-btn.bk-btn-theme-border,
body.template-color-2 button.brook-btn.bk-btn-theme-border {
  border-color: #FE378C;
  color: #FE378C; }
  body.template-color-2 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-2 button.brook-btn.bk-btn-theme-border:hover {
    background: #FE378C;
    color: #ffffff; }

body.template-color-2 .brook-quote::before {
  color: #FE378C; }

body.template-color-2 .bk-text-box.bg-theme-color {
  background: #FE378C; }

body.template-color-2 .bk-list--2 .list-header.with-number--theme .marker {
  color: #FE378C; }

body.template-color-2 .view-more-btn a span.btn-arrow {
  color: #FE378C; }

body.template-color-2 #fp-nav ul li a.active {
  background-color: #FE378C; }

body.template-color-3 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F0263F; }

body.template-color-3 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F0263F; }

body.template-color-3 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F0263F; }

body.template-color-3 .faq-content h2 {
  color: #F0263F; }

body.template-color-3 .brook-section-title-corona h2 {
  color: #F0263F; }

body.template-color-3 .single-contagion .contagion-content h3 {
  color: #F0263F; }

body.template-color-3 .single-sympotom .sympotom-content h3 {
  color: #F0263F; }

body.template-color-3 .single-prevination .prevination-content h3 {
  color: #F0263F; }

body.template-color-3 .service.service--1.theme-color {
  background: #F0263F; }

body.template-color-3 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F0263F; }
  body.template-color-3 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F0263F; }

body.template-color-3 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-3 .single-svg-icon-box.business-icon-box:hover {
  background: #F0263F; }

body.template-color-3 .product_review .description_nav a.active, body.template-color-3 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F0263F; }

body.template-color-3 .single-product-details-side .product-action a {
  border-color: #F0263F;
  background-color: #F0263F; }

body.template-color-3 .cart-table td.pro-subtotal span {
  color: #F0263F; }

body.template-color-3 .cart-table td.pro-addtocart button {
  border: 1px solid #F0263F;
  background-color: #F0263F; }

body.template-color-3 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F0263F; }

body.template-color-3 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F0263F; }

body.template-color-3 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F0263F !important; }

body.template-color-3 a.brook-btn.bk-btn-yellow, body.template-color-3 button.brook-btn.bk-btn-yellow {
  background: #F0263F;
  color: #fff !important; }

body.template-color-3 a.bk-btn.theme-btn, body.template-color-3 button.bk-btn.theme-btn {
  background-color: #F0263F; }

body.template-color-3 a.brook-btn.bk-btn-red, body.template-color-3 button.brook-btn.bk-btn-red {
  background: #F0263F; }

body.template-color-3 .section-title h3 {
  color: #F0263F; }

body.template-color-3 .service.service--1:hover::before {
  border-color: #F0263F; }

body.template-color-3 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-3 .service.service--1 .icons i {
    color: #F0263F;
    font-size: 80px; }

body.template-color-3 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F0263F;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-3 .service.service--1 .content a.service-btn:hover {
  color: #F0263F; }
  body.template-color-3 .service.service--1 .content a.service-btn:hover i {
    color: #F0263F; }

body.template-color-3 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-3 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-3 .service.service--3 .content h3 a:hover {
  color: #F0263F; }

body.template-color-3 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-3 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-3 .title-separator.color-red::after, body.template-color-3 .title-separator.color-blue::after {
    background: #F0263F; }

body.template-color-3 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-3 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F0263F; }
  body.template-color-3 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F0263F; }
  body.template-color-3 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F0263F; }
  body.template-color-3 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F0263F; }

body.template-color-3 .post-nav-lisnt .nav-item a:hover {
  background: #F0263F; }

body.template-color-3 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F0263F; }

body.template-color-3 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F0263F; }

body.template-color-3 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F0263F; }

body.template-color-3 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F0263F; }

body.template-color-3 .team.team__style--2 .thumb .overlay {
  background-color: #F0263F; }
  body.template-color-3 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F0263F; }

body.template-color-3 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-3 .team.team__style--3 .thumb .overlay {
  background-color: #F0263F; }
  body.template-color-3 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F0263F; }

body.template-color-3 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-3 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F0263F; }
  body.template-color-3 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F0263F; }
  body.template-color-3 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F0263F; }
  body.template-color-3 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-3 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F0263F; }
  body.template-color-3 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F0263F;
    color: #fff; }

body.template-color-3 [data-overlay]:before {
  background-color: #F0263F; }

body.template-color-3 .team-heading .heading-right button i {
  color: #F0263F; }

body.template-color-3 .team-heading .heading-right button:hover {
  color: #F0263F !important; }

body.template-color-3 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F0263F; }

body.template-color-3 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-3 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F0263F; }

body.template-color-3 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F0263F; }
  body.template-color-3 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F0263F; }
  body.template-color-3 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F0263F; }
  body.template-color-3 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F0263F;
    background-color: #F0263F; }

body.template-color-3 .brook__toolbar .inner a:hover {
  color: #F0263F; }

body.template-color-3 .blog-grid .post-inner h5.heading:hover {
  color: #F0263F; }
  body.template-color-3 .blog-grid .post-inner h5.heading:hover a {
    color: #F0263F; }

body.template-color-3 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F0263F; }

body.template-color-3 .theme-color {
  color: #F0263F; }

body.template-color-3 .bg_color--2 {
  background: #F0263F; }

body.template-color-3 a.brook-btn.bk-btn-theme,
body.template-color-3 button.brook-btn.bk-btn-theme {
  background: #F0263F;
  color: #fff; }

body.template-color-3 .btn-essential:hover {
  background: #F0263F !important;
  border-color: #F0263F !important;
  color: #fff; }

body.template-color-3 .red-border {
  border-color: #F0263F !important; }

body.template-color-3 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F0263F;
  color: #fff; }

body.template-color-3 .single-svg-icon-box .svg-icon svg * {
  stroke: #F0263F !important; }

body.template-color-3 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-3 .menu-price {
  color: #F0263F; }

body.template-color-3 a.moredetails-btn:hover {
  color: #F0263F; }

body.template-color-3 a.moredetails-btn i {
  color: #F0263F; }

body.template-color-3 .messonry-button button.is-checked span.filter-text {
  color: #F0263F; }

body.template-color-3 .messonry-button button:hover span.filter-text {
  color: #F0263F; }

body.template-color-3 .messonry-button button span.filter-counter {
  background-color: #F0263F; }
  body.template-color-3 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F0263F;
    border-top-color: #F0263F; }

body.template-color-3 .messonry-button button:hover span.filter-text {
  color: #F0263F; }

body.template-color-3 .messonry-button button.is-checked span.filter-text {
  color: #F0263F; }

body.template-color-3 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F0263F; }

body.template-color-3 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F0263F; }

body.template-color-3 .testimonial_style--1 .content .testimonial-quote span {
  color: #F0263F; }

body.template-color-3 .testimonial_style--1:hover {
  background: #F0263F; }
  body.template-color-3 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-3 .contact-form.contact-form--4 .input-box button {
  color: #F0263F; }
  body.template-color-3 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F0263F; }

body.template-color-3 .contact-form.contact-form--3 .input-box button {
  color: #F0263F; }

body.template-color-3 .animated-dot {
  background-color: #F0263F; }
  body.template-color-3 .animated-dot .middle-dot {
    background-color: #F0263F; }
  body.template-color-3 .animated-dot .signal {
    background-color: #F0263F; }
  body.template-color-3 .animated-dot .signal2 {
    background-color: #F0263F; }

body.template-color-3 .progress-charts .progress .progress-bar {
  background-color: #F0263F; }

body.template-color-3 .brook-counter .icon i {
  color: #F0263F; }

body.template-color-3 .social-share a:hover {
  color: #F0263F; }

body.template-color-3 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F0263F; }

body.template-color-3 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F0263F; }

body.template-color-3 .video-btn a.video-icon,
body.template-color-3 .video-btn a .video-icon {
  background: #F0263F; }

body.template-color-3 .video-btn-2 .icon-gradient::before {
  background-color: #F0263F;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F0263F 100%); }

body.template-color-3 .video-btn-2 .icon-gradient::after {
  background-color: #F0263F;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F0263F 100%); }

body.template-color-3 .single-motive-speach .icon i {
  color: #F0263F; }

body.template-color-3 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F0263F; }
  body.template-color-3 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F0263F; }

body.template-color-3 .contact-form input:focus,
body.template-color-3 .contact-form textarea:focus {
  border-color: #F0263F; }

body.template-color-3 .contact-form input[type="submit"],
body.template-color-3 .contact-form textarea[type="submit"] {
  border-color: #F0263F;
  background-color: #F0263F; }

body.template-color-3 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F0263F;
  color: #fff;
  border-color: #F0263F; }

body.template-color-3 .gallery .hover-overlay {
  background-color: #F0263F; }

body.template-color-3 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F0263F; }

body.template-color-3 .blog-grid .post-content::before {
  background-color: #F0263F; }

body.template-color-3 .blog-grid-no-flex .post-quote::before {
  color: #F0263F; }

body.template-color-3 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F0263F; }
  body.template-color-3 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F0263F; }

body.template-color-3 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F0263F; }

body.template-color-3 .bl-sidebar.search button:hover {
  background-color: #F0263F; }

body.template-color-3 .bl-sidebar.search button span {
  color: #F0263F; }

body.template-color-3 .bl-sidebar.category .category-list li a:hover {
  color: #F0263F; }

body.template-color-3 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F0263F; }

body.template-color-3 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F0263F; }

body.template-color-3 .icon-box .inner .icon {
  color: #F0263F; }

body.template-color-3 .basic-thick-line-theme-4 {
  border-top: 4px solid #F0263F; }

body.template-color-3 .procing-box .header .feature-mark {
  background-color: #F0263F; }

body.template-color-3 a.brook-btn.bk-btn-dark:hover {
  background: #F0263F; }

body.template-color-3 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F0263F; }

body.template-color-3 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F0263F; }

body.template-color-3 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F0263F !important; }

body.template-color-3 .basic-modern-dots .dot {
  background-color: #F0263F; }

body.template-color-3 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F0263F; }

body.template-color-3 a.brook-btn.bk-btn-theme-border,
body.template-color-3 button.brook-btn.bk-btn-theme-border {
  border-color: #F0263F;
  color: #F0263F; }
  body.template-color-3 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-3 button.brook-btn.bk-btn-theme-border:hover {
    background: #F0263F;
    color: #ffffff; }

body.template-color-3 .brook-quote::before {
  color: #F0263F; }

body.template-color-3 .bk-text-box.bg-theme-color {
  background: #F0263F; }

body.template-color-3 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F0263F; }

body.template-color-3 .view-more-btn a span.btn-arrow {
  color: #F0263F; }

body.template-color-3 #fp-nav ul li a.active {
  background-color: #F0263F; }

body.template-color-4 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #FCB72B; }

body.template-color-4 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #FCB72B; }

body.template-color-4 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #FCB72B; }

body.template-color-4 .faq-content h2 {
  color: #FCB72B; }

body.template-color-4 .brook-section-title-corona h2 {
  color: #FCB72B; }

body.template-color-4 .single-contagion .contagion-content h3 {
  color: #FCB72B; }

body.template-color-4 .single-sympotom .sympotom-content h3 {
  color: #FCB72B; }

body.template-color-4 .single-prevination .prevination-content h3 {
  color: #FCB72B; }

body.template-color-4 .service.service--1.theme-color {
  background: #FCB72B; }

body.template-color-4 .blog-grid-minimal.outline.bg-transparant {
  border-color: #FCB72B; }
  body.template-color-4 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #FCB72B; }

body.template-color-4 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-4 .single-svg-icon-box.business-icon-box:hover {
  background: #FCB72B; }

body.template-color-4 .product_review .description_nav a.active, body.template-color-4 .product_review .description_nav a:hover {
  border-bottom: 3px solid #FCB72B; }

body.template-color-4 .single-product-details-side .product-action a {
  border-color: #FCB72B;
  background-color: #FCB72B; }

body.template-color-4 .cart-table td.pro-subtotal span {
  color: #FCB72B; }

body.template-color-4 .cart-table td.pro-addtocart button {
  border: 1px solid #FCB72B;
  background-color: #FCB72B; }

body.template-color-4 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #FCB72B; }

body.template-color-4 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #FCB72B; }

body.template-color-4 .slider-business .video-btn a .video-icon.secondary-color {
  background: #FCB72B !important; }

body.template-color-4 a.brook-btn.bk-btn-yellow, body.template-color-4 button.brook-btn.bk-btn-yellow {
  background: #FCB72B;
  color: #fff !important; }

body.template-color-4 a.bk-btn.theme-btn, body.template-color-4 button.bk-btn.theme-btn {
  background-color: #FCB72B; }

body.template-color-4 a.brook-btn.bk-btn-red, body.template-color-4 button.brook-btn.bk-btn-red {
  background: #FCB72B; }

body.template-color-4 .section-title h3 {
  color: #FCB72B; }

body.template-color-4 .service.service--1:hover::before {
  border-color: #FCB72B; }

body.template-color-4 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-4 .service.service--1 .icons i {
    color: #FCB72B;
    font-size: 80px; }

body.template-color-4 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #FCB72B;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-4 .service.service--1 .content a.service-btn:hover {
  color: #FCB72B; }
  body.template-color-4 .service.service--1 .content a.service-btn:hover i {
    color: #FCB72B; }

body.template-color-4 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-4 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-4 .service.service--3 .content h3 a:hover {
  color: #FCB72B; }

body.template-color-4 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-4 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-4 .title-separator.color-red::after, body.template-color-4 .title-separator.color-blue::after {
    background: #FCB72B; }

body.template-color-4 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-4 .bk-gradation .item-grid .dot-wrap .dot {
    background: #FCB72B; }
  body.template-color-4 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #FCB72B; }
  body.template-color-4 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #FCB72B; }
  body.template-color-4 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #FCB72B; }

body.template-color-4 .post-nav-lisnt .nav-item a:hover {
  background: #FCB72B; }

body.template-color-4 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #FCB72B; }

body.template-color-4 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #FCB72B; }

body.template-color-4 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #FCB72B; }

body.template-color-4 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #FCB72B; }

body.template-color-4 .team.team__style--2 .thumb .overlay {
  background-color: #FCB72B; }
  body.template-color-4 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #FCB72B; }

body.template-color-4 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-4 .team.team__style--3 .thumb .overlay {
  background-color: #FCB72B; }
  body.template-color-4 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #FCB72B; }

body.template-color-4 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-4 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #FCB72B; }
  body.template-color-4 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #FCB72B; }
  body.template-color-4 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #FCB72B; }
  body.template-color-4 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-4 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #FCB72B; }
  body.template-color-4 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #FCB72B;
    color: #fff; }

body.template-color-4 [data-overlay]:before {
  background-color: #FCB72B; }

body.template-color-4 .team-heading .heading-right button i {
  color: #FCB72B; }

body.template-color-4 .team-heading .heading-right button:hover {
  color: #FCB72B !important; }

body.template-color-4 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #FCB72B; }

body.template-color-4 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-4 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #FCB72B; }

body.template-color-4 .mini-cart .shopping-cart {
  border-bottom: 2px solid #FCB72B; }
  body.template-color-4 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #FCB72B; }
  body.template-color-4 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #FCB72B; }
  body.template-color-4 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #FCB72B;
    background-color: #FCB72B; }

body.template-color-4 .brook__toolbar .inner a:hover {
  color: #FCB72B; }

body.template-color-4 .blog-grid .post-inner h5.heading:hover {
  color: #FCB72B; }
  body.template-color-4 .blog-grid .post-inner h5.heading:hover a {
    color: #FCB72B; }

body.template-color-4 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #FCB72B; }

body.template-color-4 .theme-color {
  color: #FCB72B; }

body.template-color-4 .bg_color--2 {
  background: #FCB72B; }

body.template-color-4 a.brook-btn.bk-btn-theme,
body.template-color-4 button.brook-btn.bk-btn-theme {
  background: #FCB72B;
  color: #fff; }

body.template-color-4 .btn-essential:hover {
  background: #FCB72B !important;
  border-color: #FCB72B !important;
  color: #fff; }

body.template-color-4 .red-border {
  border-color: #FCB72B !important; }

body.template-color-4 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #FCB72B;
  color: #fff; }

body.template-color-4 .single-svg-icon-box .svg-icon svg * {
  stroke: #FCB72B !important; }

body.template-color-4 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-4 .menu-price {
  color: #FCB72B; }

body.template-color-4 a.moredetails-btn:hover {
  color: #FCB72B; }

body.template-color-4 a.moredetails-btn i {
  color: #FCB72B; }

body.template-color-4 .messonry-button button.is-checked span.filter-text {
  color: #FCB72B; }

body.template-color-4 .messonry-button button:hover span.filter-text {
  color: #FCB72B; }

body.template-color-4 .messonry-button button span.filter-counter {
  background-color: #FCB72B; }
  body.template-color-4 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #FCB72B;
    border-top-color: #FCB72B; }

body.template-color-4 .messonry-button button:hover span.filter-text {
  color: #FCB72B; }

body.template-color-4 .messonry-button button.is-checked span.filter-text {
  color: #FCB72B; }

body.template-color-4 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #FCB72B; }

body.template-color-4 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #FCB72B; }

body.template-color-4 .testimonial_style--1 .content .testimonial-quote span {
  color: #FCB72B; }

body.template-color-4 .testimonial_style--1:hover {
  background: #FCB72B; }
  body.template-color-4 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-4 .contact-form.contact-form--4 .input-box button {
  color: #FCB72B; }
  body.template-color-4 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #FCB72B; }

body.template-color-4 .contact-form.contact-form--3 .input-box button {
  color: #FCB72B; }

body.template-color-4 .animated-dot {
  background-color: #FCB72B; }
  body.template-color-4 .animated-dot .middle-dot {
    background-color: #FCB72B; }
  body.template-color-4 .animated-dot .signal {
    background-color: #FCB72B; }
  body.template-color-4 .animated-dot .signal2 {
    background-color: #FCB72B; }

body.template-color-4 .progress-charts .progress .progress-bar {
  background-color: #FCB72B; }

body.template-color-4 .brook-counter .icon i {
  color: #FCB72B; }

body.template-color-4 .social-share a:hover {
  color: #FCB72B; }

body.template-color-4 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #FCB72B; }

body.template-color-4 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #FCB72B; }

body.template-color-4 .video-btn a.video-icon,
body.template-color-4 .video-btn a .video-icon {
  background: #FCB72B; }

body.template-color-4 .video-btn-2 .icon-gradient::before {
  background-color: #FCB72B;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FCB72B 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FCB72B 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FCB72B 100%); }

body.template-color-4 .video-btn-2 .icon-gradient::after {
  background-color: #FCB72B;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FCB72B 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FCB72B 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FCB72B 100%); }

body.template-color-4 .single-motive-speach .icon i {
  color: #FCB72B; }

body.template-color-4 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #FCB72B; }
  body.template-color-4 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #FCB72B; }

body.template-color-4 .contact-form input:focus,
body.template-color-4 .contact-form textarea:focus {
  border-color: #FCB72B; }

body.template-color-4 .contact-form input[type="submit"],
body.template-color-4 .contact-form textarea[type="submit"] {
  border-color: #FCB72B;
  background-color: #FCB72B; }

body.template-color-4 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #FCB72B;
  color: #fff;
  border-color: #FCB72B; }

body.template-color-4 .gallery .hover-overlay {
  background-color: #FCB72B; }

body.template-color-4 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #FCB72B; }

body.template-color-4 .blog-grid .post-content::before {
  background-color: #FCB72B; }

body.template-color-4 .blog-grid-no-flex .post-quote::before {
  color: #FCB72B; }

body.template-color-4 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #FCB72B; }
  body.template-color-4 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #FCB72B; }

body.template-color-4 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #FCB72B; }

body.template-color-4 .bl-sidebar.search button:hover {
  background-color: #FCB72B; }

body.template-color-4 .bl-sidebar.search button span {
  color: #FCB72B; }

body.template-color-4 .bl-sidebar.category .category-list li a:hover {
  color: #FCB72B; }

body.template-color-4 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #FCB72B; }

body.template-color-4 .social-icon.icon-solid-rounded li a:hover {
  background-color: #FCB72B; }

body.template-color-4 .icon-box .inner .icon {
  color: #FCB72B; }

body.template-color-4 .basic-thick-line-theme-4 {
  border-top: 4px solid #FCB72B; }

body.template-color-4 .procing-box .header .feature-mark {
  background-color: #FCB72B; }

body.template-color-4 a.brook-btn.bk-btn-dark:hover {
  background: #FCB72B; }

body.template-color-4 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #FCB72B; }

body.template-color-4 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #FCB72B; }

body.template-color-4 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #FCB72B !important; }

body.template-color-4 .basic-modern-dots .dot {
  background-color: #FCB72B; }

body.template-color-4 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #FCB72B; }

body.template-color-4 a.brook-btn.bk-btn-theme-border,
body.template-color-4 button.brook-btn.bk-btn-theme-border {
  border-color: #FCB72B;
  color: #FCB72B; }
  body.template-color-4 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-4 button.brook-btn.bk-btn-theme-border:hover {
    background: #FCB72B;
    color: #ffffff; }

body.template-color-4 .brook-quote::before {
  color: #FCB72B; }

body.template-color-4 .bk-text-box.bg-theme-color {
  background: #FCB72B; }

body.template-color-4 .bk-list--2 .list-header.with-number--theme .marker {
  color: #FCB72B; }

body.template-color-4 .view-more-btn a span.btn-arrow {
  color: #FCB72B; }

body.template-color-4 #fp-nav ul li a.active {
  background-color: #FCB72B; }

body.template-color-5 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F8A440; }

body.template-color-5 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F8A440; }

body.template-color-5 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F8A440; }

body.template-color-5 .faq-content h2 {
  color: #F8A440; }

body.template-color-5 .brook-section-title-corona h2 {
  color: #F8A440; }

body.template-color-5 .single-contagion .contagion-content h3 {
  color: #F8A440; }

body.template-color-5 .single-sympotom .sympotom-content h3 {
  color: #F8A440; }

body.template-color-5 .single-prevination .prevination-content h3 {
  color: #F8A440; }

body.template-color-5 .service.service--1.theme-color {
  background: #F8A440; }

body.template-color-5 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F8A440; }
  body.template-color-5 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F8A440; }

body.template-color-5 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-5 .single-svg-icon-box.business-icon-box:hover {
  background: #F8A440; }

body.template-color-5 .product_review .description_nav a.active, body.template-color-5 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F8A440; }

body.template-color-5 .single-product-details-side .product-action a {
  border-color: #F8A440;
  background-color: #F8A440; }

body.template-color-5 .cart-table td.pro-subtotal span {
  color: #F8A440; }

body.template-color-5 .cart-table td.pro-addtocart button {
  border: 1px solid #F8A440;
  background-color: #F8A440; }

body.template-color-5 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F8A440; }

body.template-color-5 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F8A440; }

body.template-color-5 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F8A440 !important; }

body.template-color-5 a.brook-btn.bk-btn-yellow, body.template-color-5 button.brook-btn.bk-btn-yellow {
  background: #F8A440;
  color: #fff !important; }

body.template-color-5 a.bk-btn.theme-btn, body.template-color-5 button.bk-btn.theme-btn {
  background-color: #F8A440; }

body.template-color-5 a.brook-btn.bk-btn-red, body.template-color-5 button.brook-btn.bk-btn-red {
  background: #F8A440; }

body.template-color-5 .section-title h3 {
  color: #F8A440; }

body.template-color-5 .service.service--1:hover::before {
  border-color: #F8A440; }

body.template-color-5 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-5 .service.service--1 .icons i {
    color: #F8A440;
    font-size: 80px; }

body.template-color-5 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F8A440;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-5 .service.service--1 .content a.service-btn:hover {
  color: #F8A440; }
  body.template-color-5 .service.service--1 .content a.service-btn:hover i {
    color: #F8A440; }

body.template-color-5 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-5 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-5 .service.service--3 .content h3 a:hover {
  color: #F8A440; }

body.template-color-5 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-5 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-5 .title-separator.color-red::after, body.template-color-5 .title-separator.color-blue::after {
    background: #F8A440; }

body.template-color-5 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-5 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F8A440; }
  body.template-color-5 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F8A440; }
  body.template-color-5 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F8A440; }
  body.template-color-5 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F8A440; }

body.template-color-5 .post-nav-lisnt .nav-item a:hover {
  background: #F8A440; }

body.template-color-5 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F8A440; }

body.template-color-5 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F8A440; }

body.template-color-5 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F8A440; }

body.template-color-5 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F8A440; }

body.template-color-5 .team.team__style--2 .thumb .overlay {
  background-color: #F8A440; }
  body.template-color-5 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F8A440; }

body.template-color-5 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-5 .team.team__style--3 .thumb .overlay {
  background-color: #F8A440; }
  body.template-color-5 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F8A440; }

body.template-color-5 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-5 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F8A440; }
  body.template-color-5 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F8A440; }
  body.template-color-5 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F8A440; }
  body.template-color-5 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-5 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F8A440; }
  body.template-color-5 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F8A440;
    color: #fff; }

body.template-color-5 [data-overlay]:before {
  background-color: #F8A440; }

body.template-color-5 .team-heading .heading-right button i {
  color: #F8A440; }

body.template-color-5 .team-heading .heading-right button:hover {
  color: #F8A440 !important; }

body.template-color-5 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F8A440; }

body.template-color-5 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-5 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F8A440; }

body.template-color-5 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F8A440; }
  body.template-color-5 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F8A440; }
  body.template-color-5 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F8A440; }
  body.template-color-5 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F8A440;
    background-color: #F8A440; }

body.template-color-5 .brook__toolbar .inner a:hover {
  color: #F8A440; }

body.template-color-5 .blog-grid .post-inner h5.heading:hover {
  color: #F8A440; }
  body.template-color-5 .blog-grid .post-inner h5.heading:hover a {
    color: #F8A440; }

body.template-color-5 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F8A440; }

body.template-color-5 .theme-color {
  color: #F8A440; }

body.template-color-5 .bg_color--2 {
  background: #F8A440; }

body.template-color-5 a.brook-btn.bk-btn-theme,
body.template-color-5 button.brook-btn.bk-btn-theme {
  background: #F8A440;
  color: #fff; }

body.template-color-5 .btn-essential:hover {
  background: #F8A440 !important;
  border-color: #F8A440 !important;
  color: #fff; }

body.template-color-5 .red-border {
  border-color: #F8A440 !important; }

body.template-color-5 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F8A440;
  color: #fff; }

body.template-color-5 .single-svg-icon-box .svg-icon svg * {
  stroke: #F8A440 !important; }

body.template-color-5 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-5 .menu-price {
  color: #F8A440; }

body.template-color-5 a.moredetails-btn:hover {
  color: #F8A440; }

body.template-color-5 a.moredetails-btn i {
  color: #F8A440; }

body.template-color-5 .messonry-button button.is-checked span.filter-text {
  color: #F8A440; }

body.template-color-5 .messonry-button button:hover span.filter-text {
  color: #F8A440; }

body.template-color-5 .messonry-button button span.filter-counter {
  background-color: #F8A440; }
  body.template-color-5 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F8A440;
    border-top-color: #F8A440; }

body.template-color-5 .messonry-button button:hover span.filter-text {
  color: #F8A440; }

body.template-color-5 .messonry-button button.is-checked span.filter-text {
  color: #F8A440; }

body.template-color-5 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F8A440; }

body.template-color-5 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F8A440; }

body.template-color-5 .testimonial_style--1 .content .testimonial-quote span {
  color: #F8A440; }

body.template-color-5 .testimonial_style--1:hover {
  background: #F8A440; }
  body.template-color-5 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-5 .contact-form.contact-form--4 .input-box button {
  color: #F8A440; }
  body.template-color-5 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F8A440; }

body.template-color-5 .contact-form.contact-form--3 .input-box button {
  color: #F8A440; }

body.template-color-5 .animated-dot {
  background-color: #F8A440; }
  body.template-color-5 .animated-dot .middle-dot {
    background-color: #F8A440; }
  body.template-color-5 .animated-dot .signal {
    background-color: #F8A440; }
  body.template-color-5 .animated-dot .signal2 {
    background-color: #F8A440; }

body.template-color-5 .progress-charts .progress .progress-bar {
  background-color: #F8A440; }

body.template-color-5 .brook-counter .icon i {
  color: #F8A440; }

body.template-color-5 .social-share a:hover {
  color: #F8A440; }

body.template-color-5 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F8A440; }

body.template-color-5 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F8A440; }

body.template-color-5 .video-btn a.video-icon,
body.template-color-5 .video-btn a .video-icon {
  background: #F8A440; }

body.template-color-5 .video-btn-2 .icon-gradient::before {
  background-color: #F8A440;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F8A440 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F8A440 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F8A440 100%); }

body.template-color-5 .video-btn-2 .icon-gradient::after {
  background-color: #F8A440;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F8A440 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F8A440 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F8A440 100%); }

body.template-color-5 .single-motive-speach .icon i {
  color: #F8A440; }

body.template-color-5 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F8A440; }
  body.template-color-5 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F8A440; }

body.template-color-5 .contact-form input:focus,
body.template-color-5 .contact-form textarea:focus {
  border-color: #F8A440; }

body.template-color-5 .contact-form input[type="submit"],
body.template-color-5 .contact-form textarea[type="submit"] {
  border-color: #F8A440;
  background-color: #F8A440; }

body.template-color-5 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F8A440;
  color: #fff;
  border-color: #F8A440; }

body.template-color-5 .gallery .hover-overlay {
  background-color: #F8A440; }

body.template-color-5 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F8A440; }

body.template-color-5 .blog-grid .post-content::before {
  background-color: #F8A440; }

body.template-color-5 .blog-grid-no-flex .post-quote::before {
  color: #F8A440; }

body.template-color-5 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F8A440; }
  body.template-color-5 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F8A440; }

body.template-color-5 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F8A440; }

body.template-color-5 .bl-sidebar.search button:hover {
  background-color: #F8A440; }

body.template-color-5 .bl-sidebar.search button span {
  color: #F8A440; }

body.template-color-5 .bl-sidebar.category .category-list li a:hover {
  color: #F8A440; }

body.template-color-5 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F8A440; }

body.template-color-5 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F8A440; }

body.template-color-5 .icon-box .inner .icon {
  color: #F8A440; }

body.template-color-5 .basic-thick-line-theme-4 {
  border-top: 4px solid #F8A440; }

body.template-color-5 .procing-box .header .feature-mark {
  background-color: #F8A440; }

body.template-color-5 a.brook-btn.bk-btn-dark:hover {
  background: #F8A440; }

body.template-color-5 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F8A440; }

body.template-color-5 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F8A440; }

body.template-color-5 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F8A440 !important; }

body.template-color-5 .basic-modern-dots .dot {
  background-color: #F8A440; }

body.template-color-5 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F8A440; }

body.template-color-5 a.brook-btn.bk-btn-theme-border,
body.template-color-5 button.brook-btn.bk-btn-theme-border {
  border-color: #F8A440;
  color: #F8A440; }
  body.template-color-5 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-5 button.brook-btn.bk-btn-theme-border:hover {
    background: #F8A440;
    color: #ffffff; }

body.template-color-5 .brook-quote::before {
  color: #F8A440; }

body.template-color-5 .bk-text-box.bg-theme-color {
  background: #F8A440; }

body.template-color-5 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F8A440; }

body.template-color-5 .view-more-btn a span.btn-arrow {
  color: #F8A440; }

body.template-color-5 #fp-nav ul li a.active {
  background-color: #F8A440; }

body.template-color-6 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F05874; }

body.template-color-6 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F05874; }

body.template-color-6 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F05874; }

body.template-color-6 .faq-content h2 {
  color: #F05874; }

body.template-color-6 .brook-section-title-corona h2 {
  color: #F05874; }

body.template-color-6 .single-contagion .contagion-content h3 {
  color: #F05874; }

body.template-color-6 .single-sympotom .sympotom-content h3 {
  color: #F05874; }

body.template-color-6 .single-prevination .prevination-content h3 {
  color: #F05874; }

body.template-color-6 .service.service--1.theme-color {
  background: #F05874; }

body.template-color-6 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F05874; }
  body.template-color-6 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F05874; }

body.template-color-6 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-6 .single-svg-icon-box.business-icon-box:hover {
  background: #F05874; }

body.template-color-6 .product_review .description_nav a.active, body.template-color-6 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F05874; }

body.template-color-6 .single-product-details-side .product-action a {
  border-color: #F05874;
  background-color: #F05874; }

body.template-color-6 .cart-table td.pro-subtotal span {
  color: #F05874; }

body.template-color-6 .cart-table td.pro-addtocart button {
  border: 1px solid #F05874;
  background-color: #F05874; }

body.template-color-6 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F05874; }

body.template-color-6 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F05874; }

body.template-color-6 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F05874 !important; }

body.template-color-6 a.brook-btn.bk-btn-yellow, body.template-color-6 button.brook-btn.bk-btn-yellow {
  background: #F05874;
  color: #fff !important; }

body.template-color-6 a.bk-btn.theme-btn, body.template-color-6 button.bk-btn.theme-btn {
  background-color: #F05874; }

body.template-color-6 a.brook-btn.bk-btn-red, body.template-color-6 button.brook-btn.bk-btn-red {
  background: #F05874; }

body.template-color-6 .section-title h3 {
  color: #F05874; }

body.template-color-6 .service.service--1:hover::before {
  border-color: #F05874; }

body.template-color-6 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-6 .service.service--1 .icons i {
    color: #F05874;
    font-size: 80px; }

body.template-color-6 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F05874;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-6 .service.service--1 .content a.service-btn:hover {
  color: #F05874; }
  body.template-color-6 .service.service--1 .content a.service-btn:hover i {
    color: #F05874; }

body.template-color-6 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-6 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-6 .service.service--3 .content h3 a:hover {
  color: #F05874; }

body.template-color-6 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-6 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-6 .title-separator.color-red::after, body.template-color-6 .title-separator.color-blue::after {
    background: #F05874; }

body.template-color-6 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-6 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F05874; }
  body.template-color-6 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F05874; }
  body.template-color-6 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F05874; }
  body.template-color-6 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F05874; }

body.template-color-6 .post-nav-lisnt .nav-item a:hover {
  background: #F05874; }

body.template-color-6 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F05874; }

body.template-color-6 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F05874; }

body.template-color-6 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F05874; }

body.template-color-6 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F05874; }

body.template-color-6 .team.team__style--2 .thumb .overlay {
  background-color: #F05874; }
  body.template-color-6 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F05874; }

body.template-color-6 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-6 .team.team__style--3 .thumb .overlay {
  background-color: #F05874; }
  body.template-color-6 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F05874; }

body.template-color-6 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-6 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F05874; }
  body.template-color-6 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F05874; }
  body.template-color-6 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F05874; }
  body.template-color-6 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-6 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F05874; }
  body.template-color-6 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F05874;
    color: #fff; }

body.template-color-6 [data-overlay]:before {
  background-color: #F05874; }

body.template-color-6 .team-heading .heading-right button i {
  color: #F05874; }

body.template-color-6 .team-heading .heading-right button:hover {
  color: #F05874 !important; }

body.template-color-6 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F05874; }

body.template-color-6 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-6 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F05874; }

body.template-color-6 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F05874; }
  body.template-color-6 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F05874; }
  body.template-color-6 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F05874; }
  body.template-color-6 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F05874;
    background-color: #F05874; }

body.template-color-6 .brook__toolbar .inner a:hover {
  color: #F05874; }

body.template-color-6 .blog-grid .post-inner h5.heading:hover {
  color: #F05874; }
  body.template-color-6 .blog-grid .post-inner h5.heading:hover a {
    color: #F05874; }

body.template-color-6 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F05874; }

body.template-color-6 .theme-color {
  color: #F05874; }

body.template-color-6 .bg_color--2 {
  background: #F05874; }

body.template-color-6 a.brook-btn.bk-btn-theme,
body.template-color-6 button.brook-btn.bk-btn-theme {
  background: #F05874;
  color: #fff; }

body.template-color-6 .btn-essential:hover {
  background: #F05874 !important;
  border-color: #F05874 !important;
  color: #fff; }

body.template-color-6 .red-border {
  border-color: #F05874 !important; }

body.template-color-6 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F05874;
  color: #fff; }

body.template-color-6 .single-svg-icon-box .svg-icon svg * {
  stroke: #F05874 !important; }

body.template-color-6 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-6 .menu-price {
  color: #F05874; }

body.template-color-6 a.moredetails-btn:hover {
  color: #F05874; }

body.template-color-6 a.moredetails-btn i {
  color: #F05874; }

body.template-color-6 .messonry-button button.is-checked span.filter-text {
  color: #F05874; }

body.template-color-6 .messonry-button button:hover span.filter-text {
  color: #F05874; }

body.template-color-6 .messonry-button button span.filter-counter {
  background-color: #F05874; }
  body.template-color-6 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F05874;
    border-top-color: #F05874; }

body.template-color-6 .messonry-button button:hover span.filter-text {
  color: #F05874; }

body.template-color-6 .messonry-button button.is-checked span.filter-text {
  color: #F05874; }

body.template-color-6 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F05874; }

body.template-color-6 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F05874; }

body.template-color-6 .testimonial_style--1 .content .testimonial-quote span {
  color: #F05874; }

body.template-color-6 .testimonial_style--1:hover {
  background: #F05874; }
  body.template-color-6 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-6 .contact-form.contact-form--4 .input-box button {
  color: #F05874; }
  body.template-color-6 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F05874; }

body.template-color-6 .contact-form.contact-form--3 .input-box button {
  color: #F05874; }

body.template-color-6 .animated-dot {
  background-color: #F05874; }
  body.template-color-6 .animated-dot .middle-dot {
    background-color: #F05874; }
  body.template-color-6 .animated-dot .signal {
    background-color: #F05874; }
  body.template-color-6 .animated-dot .signal2 {
    background-color: #F05874; }

body.template-color-6 .progress-charts .progress .progress-bar {
  background-color: #F05874; }

body.template-color-6 .brook-counter .icon i {
  color: #F05874; }

body.template-color-6 .social-share a:hover {
  color: #F05874; }

body.template-color-6 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F05874; }

body.template-color-6 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F05874; }

body.template-color-6 .video-btn a.video-icon,
body.template-color-6 .video-btn a .video-icon {
  background: #F05874; }

body.template-color-6 .video-btn-2 .icon-gradient::before {
  background-color: #F05874;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F05874 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F05874 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F05874 100%); }

body.template-color-6 .video-btn-2 .icon-gradient::after {
  background-color: #F05874;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F05874 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F05874 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F05874 100%); }

body.template-color-6 .single-motive-speach .icon i {
  color: #F05874; }

body.template-color-6 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F05874; }
  body.template-color-6 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F05874; }

body.template-color-6 .contact-form input:focus,
body.template-color-6 .contact-form textarea:focus {
  border-color: #F05874; }

body.template-color-6 .contact-form input[type="submit"],
body.template-color-6 .contact-form textarea[type="submit"] {
  border-color: #F05874;
  background-color: #F05874; }

body.template-color-6 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F05874;
  color: #fff;
  border-color: #F05874; }

body.template-color-6 .gallery .hover-overlay {
  background-color: #F05874; }

body.template-color-6 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F05874; }

body.template-color-6 .blog-grid .post-content::before {
  background-color: #F05874; }

body.template-color-6 .blog-grid-no-flex .post-quote::before {
  color: #F05874; }

body.template-color-6 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F05874; }
  body.template-color-6 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F05874; }

body.template-color-6 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F05874; }

body.template-color-6 .bl-sidebar.search button:hover {
  background-color: #F05874; }

body.template-color-6 .bl-sidebar.search button span {
  color: #F05874; }

body.template-color-6 .bl-sidebar.category .category-list li a:hover {
  color: #F05874; }

body.template-color-6 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F05874; }

body.template-color-6 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F05874; }

body.template-color-6 .icon-box .inner .icon {
  color: #F05874; }

body.template-color-6 .basic-thick-line-theme-4 {
  border-top: 4px solid #F05874; }

body.template-color-6 .procing-box .header .feature-mark {
  background-color: #F05874; }

body.template-color-6 a.brook-btn.bk-btn-dark:hover {
  background: #F05874; }

body.template-color-6 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F05874; }

body.template-color-6 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F05874; }

body.template-color-6 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F05874 !important; }

body.template-color-6 .basic-modern-dots .dot {
  background-color: #F05874; }

body.template-color-6 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F05874; }

body.template-color-6 a.brook-btn.bk-btn-theme-border,
body.template-color-6 button.brook-btn.bk-btn-theme-border {
  border-color: #F05874;
  color: #F05874; }
  body.template-color-6 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-6 button.brook-btn.bk-btn-theme-border:hover {
    background: #F05874;
    color: #ffffff; }

body.template-color-6 .brook-quote::before {
  color: #F05874; }

body.template-color-6 .bk-text-box.bg-theme-color {
  background: #F05874; }

body.template-color-6 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F05874; }

body.template-color-6 .view-more-btn a span.btn-arrow {
  color: #F05874; }

body.template-color-6 #fp-nav ul li a.active {
  background-color: #F05874; }

body.template-color-7 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #99E5E8; }

body.template-color-7 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #99E5E8; }

body.template-color-7 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #99E5E8; }

body.template-color-7 .faq-content h2 {
  color: #99E5E8; }

body.template-color-7 .brook-section-title-corona h2 {
  color: #99E5E8; }

body.template-color-7 .single-contagion .contagion-content h3 {
  color: #99E5E8; }

body.template-color-7 .single-sympotom .sympotom-content h3 {
  color: #99E5E8; }

body.template-color-7 .single-prevination .prevination-content h3 {
  color: #99E5E8; }

body.template-color-7 .service.service--1.theme-color {
  background: #99E5E8; }

body.template-color-7 .blog-grid-minimal.outline.bg-transparant {
  border-color: #99E5E8; }
  body.template-color-7 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #99E5E8; }

body.template-color-7 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-7 .single-svg-icon-box.business-icon-box:hover {
  background: #99E5E8; }

body.template-color-7 .product_review .description_nav a.active, body.template-color-7 .product_review .description_nav a:hover {
  border-bottom: 3px solid #99E5E8; }

body.template-color-7 .single-product-details-side .product-action a {
  border-color: #99E5E8;
  background-color: #99E5E8; }

body.template-color-7 .cart-table td.pro-subtotal span {
  color: #99E5E8; }

body.template-color-7 .cart-table td.pro-addtocart button {
  border: 1px solid #99E5E8;
  background-color: #99E5E8; }

body.template-color-7 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #99E5E8; }

body.template-color-7 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #99E5E8; }

body.template-color-7 .slider-business .video-btn a .video-icon.secondary-color {
  background: #99E5E8 !important; }

body.template-color-7 a.brook-btn.bk-btn-yellow, body.template-color-7 button.brook-btn.bk-btn-yellow {
  background: #99E5E8;
  color: #fff !important; }

body.template-color-7 a.bk-btn.theme-btn, body.template-color-7 button.bk-btn.theme-btn {
  background-color: #99E5E8; }

body.template-color-7 a.brook-btn.bk-btn-red, body.template-color-7 button.brook-btn.bk-btn-red {
  background: #99E5E8; }

body.template-color-7 .section-title h3 {
  color: #99E5E8; }

body.template-color-7 .service.service--1:hover::before {
  border-color: #99E5E8; }

body.template-color-7 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-7 .service.service--1 .icons i {
    color: #99E5E8;
    font-size: 80px; }

body.template-color-7 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #99E5E8;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-7 .service.service--1 .content a.service-btn:hover {
  color: #99E5E8; }
  body.template-color-7 .service.service--1 .content a.service-btn:hover i {
    color: #99E5E8; }

body.template-color-7 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-7 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-7 .service.service--3 .content h3 a:hover {
  color: #99E5E8; }

body.template-color-7 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-7 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-7 .title-separator.color-red::after, body.template-color-7 .title-separator.color-blue::after {
    background: #99E5E8; }

body.template-color-7 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-7 .bk-gradation .item-grid .dot-wrap .dot {
    background: #99E5E8; }
  body.template-color-7 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #99E5E8; }
  body.template-color-7 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #99E5E8; }
  body.template-color-7 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #99E5E8; }

body.template-color-7 .post-nav-lisnt .nav-item a:hover {
  background: #99E5E8; }

body.template-color-7 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #99E5E8; }

body.template-color-7 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #99E5E8; }

body.template-color-7 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #99E5E8; }

body.template-color-7 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #99E5E8; }

body.template-color-7 .team.team__style--2 .thumb .overlay {
  background-color: #99E5E8; }
  body.template-color-7 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #99E5E8; }

body.template-color-7 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-7 .team.team__style--3 .thumb .overlay {
  background-color: #99E5E8; }
  body.template-color-7 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #99E5E8; }

body.template-color-7 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-7 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #99E5E8; }
  body.template-color-7 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #99E5E8; }
  body.template-color-7 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #99E5E8; }
  body.template-color-7 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-7 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #99E5E8; }
  body.template-color-7 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #99E5E8;
    color: #fff; }

body.template-color-7 [data-overlay]:before {
  background-color: #99E5E8; }

body.template-color-7 .team-heading .heading-right button i {
  color: #99E5E8; }

body.template-color-7 .team-heading .heading-right button:hover {
  color: #99E5E8 !important; }

body.template-color-7 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #99E5E8; }

body.template-color-7 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-7 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #99E5E8; }

body.template-color-7 .mini-cart .shopping-cart {
  border-bottom: 2px solid #99E5E8; }
  body.template-color-7 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #99E5E8; }
  body.template-color-7 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #99E5E8; }
  body.template-color-7 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #99E5E8;
    background-color: #99E5E8; }

body.template-color-7 .brook__toolbar .inner a:hover {
  color: #99E5E8; }

body.template-color-7 .blog-grid .post-inner h5.heading:hover {
  color: #99E5E8; }
  body.template-color-7 .blog-grid .post-inner h5.heading:hover a {
    color: #99E5E8; }

body.template-color-7 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #99E5E8; }

body.template-color-7 .theme-color {
  color: #99E5E8; }

body.template-color-7 .bg_color--2 {
  background: #99E5E8; }

body.template-color-7 a.brook-btn.bk-btn-theme,
body.template-color-7 button.brook-btn.bk-btn-theme {
  background: #99E5E8;
  color: #fff; }

body.template-color-7 .btn-essential:hover {
  background: #99E5E8 !important;
  border-color: #99E5E8 !important;
  color: #fff; }

body.template-color-7 .red-border {
  border-color: #99E5E8 !important; }

body.template-color-7 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #99E5E8;
  color: #fff; }

body.template-color-7 .single-svg-icon-box .svg-icon svg * {
  stroke: #99E5E8 !important; }

body.template-color-7 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-7 .menu-price {
  color: #99E5E8; }

body.template-color-7 a.moredetails-btn:hover {
  color: #99E5E8; }

body.template-color-7 a.moredetails-btn i {
  color: #99E5E8; }

body.template-color-7 .messonry-button button.is-checked span.filter-text {
  color: #99E5E8; }

body.template-color-7 .messonry-button button:hover span.filter-text {
  color: #99E5E8; }

body.template-color-7 .messonry-button button span.filter-counter {
  background-color: #99E5E8; }
  body.template-color-7 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #99E5E8;
    border-top-color: #99E5E8; }

body.template-color-7 .messonry-button button:hover span.filter-text {
  color: #99E5E8; }

body.template-color-7 .messonry-button button.is-checked span.filter-text {
  color: #99E5E8; }

body.template-color-7 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #99E5E8; }

body.template-color-7 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #99E5E8; }

body.template-color-7 .testimonial_style--1 .content .testimonial-quote span {
  color: #99E5E8; }

body.template-color-7 .testimonial_style--1:hover {
  background: #99E5E8; }
  body.template-color-7 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-7 .contact-form.contact-form--4 .input-box button {
  color: #99E5E8; }
  body.template-color-7 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #99E5E8; }

body.template-color-7 .contact-form.contact-form--3 .input-box button {
  color: #99E5E8; }

body.template-color-7 .animated-dot {
  background-color: #99E5E8; }
  body.template-color-7 .animated-dot .middle-dot {
    background-color: #99E5E8; }
  body.template-color-7 .animated-dot .signal {
    background-color: #99E5E8; }
  body.template-color-7 .animated-dot .signal2 {
    background-color: #99E5E8; }

body.template-color-7 .progress-charts .progress .progress-bar {
  background-color: #99E5E8; }

body.template-color-7 .brook-counter .icon i {
  color: #99E5E8; }

body.template-color-7 .social-share a:hover {
  color: #99E5E8; }

body.template-color-7 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #99E5E8; }

body.template-color-7 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #99E5E8; }

body.template-color-7 .video-btn a.video-icon,
body.template-color-7 .video-btn a .video-icon {
  background: #99E5E8; }

body.template-color-7 .video-btn-2 .icon-gradient::before {
  background-color: #99E5E8;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #99E5E8 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #99E5E8 100%);
  background-image: linear-gradient(-133deg, #899664 0, #99E5E8 100%); }

body.template-color-7 .video-btn-2 .icon-gradient::after {
  background-color: #99E5E8;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #99E5E8 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #99E5E8 100%);
  background-image: linear-gradient(-133deg, #899664 0, #99E5E8 100%); }

body.template-color-7 .single-motive-speach .icon i {
  color: #99E5E8; }

body.template-color-7 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #99E5E8; }
  body.template-color-7 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #99E5E8; }

body.template-color-7 .contact-form input:focus,
body.template-color-7 .contact-form textarea:focus {
  border-color: #99E5E8; }

body.template-color-7 .contact-form input[type="submit"],
body.template-color-7 .contact-form textarea[type="submit"] {
  border-color: #99E5E8;
  background-color: #99E5E8; }

body.template-color-7 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #99E5E8;
  color: #fff;
  border-color: #99E5E8; }

body.template-color-7 .gallery .hover-overlay {
  background-color: #99E5E8; }

body.template-color-7 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #99E5E8; }

body.template-color-7 .blog-grid .post-content::before {
  background-color: #99E5E8; }

body.template-color-7 .blog-grid-no-flex .post-quote::before {
  color: #99E5E8; }

body.template-color-7 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #99E5E8; }
  body.template-color-7 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #99E5E8; }

body.template-color-7 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #99E5E8; }

body.template-color-7 .bl-sidebar.search button:hover {
  background-color: #99E5E8; }

body.template-color-7 .bl-sidebar.search button span {
  color: #99E5E8; }

body.template-color-7 .bl-sidebar.category .category-list li a:hover {
  color: #99E5E8; }

body.template-color-7 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #99E5E8; }

body.template-color-7 .social-icon.icon-solid-rounded li a:hover {
  background-color: #99E5E8; }

body.template-color-7 .icon-box .inner .icon {
  color: #99E5E8; }

body.template-color-7 .basic-thick-line-theme-4 {
  border-top: 4px solid #99E5E8; }

body.template-color-7 .procing-box .header .feature-mark {
  background-color: #99E5E8; }

body.template-color-7 a.brook-btn.bk-btn-dark:hover {
  background: #99E5E8; }

body.template-color-7 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #99E5E8; }

body.template-color-7 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #99E5E8; }

body.template-color-7 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #99E5E8 !important; }

body.template-color-7 .basic-modern-dots .dot {
  background-color: #99E5E8; }

body.template-color-7 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #99E5E8; }

body.template-color-7 a.brook-btn.bk-btn-theme-border,
body.template-color-7 button.brook-btn.bk-btn-theme-border {
  border-color: #99E5E8;
  color: #99E5E8; }
  body.template-color-7 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-7 button.brook-btn.bk-btn-theme-border:hover {
    background: #99E5E8;
    color: #ffffff; }

body.template-color-7 .brook-quote::before {
  color: #99E5E8; }

body.template-color-7 .bk-text-box.bg-theme-color {
  background: #99E5E8; }

body.template-color-7 .bk-list--2 .list-header.with-number--theme .marker {
  color: #99E5E8; }

body.template-color-7 .view-more-btn a span.btn-arrow {
  color: #99E5E8; }

body.template-color-7 #fp-nav ul li a.active {
  background-color: #99E5E8; }

body.template-color-8 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #82CECF; }

body.template-color-8 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #82CECF; }

body.template-color-8 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #82CECF; }

body.template-color-8 .faq-content h2 {
  color: #82CECF; }

body.template-color-8 .brook-section-title-corona h2 {
  color: #82CECF; }

body.template-color-8 .single-contagion .contagion-content h3 {
  color: #82CECF; }

body.template-color-8 .single-sympotom .sympotom-content h3 {
  color: #82CECF; }

body.template-color-8 .single-prevination .prevination-content h3 {
  color: #82CECF; }

body.template-color-8 .service.service--1.theme-color {
  background: #82CECF; }

body.template-color-8 .blog-grid-minimal.outline.bg-transparant {
  border-color: #82CECF; }
  body.template-color-8 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #82CECF; }

body.template-color-8 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-8 .single-svg-icon-box.business-icon-box:hover {
  background: #82CECF; }

body.template-color-8 .product_review .description_nav a.active, body.template-color-8 .product_review .description_nav a:hover {
  border-bottom: 3px solid #82CECF; }

body.template-color-8 .single-product-details-side .product-action a {
  border-color: #82CECF;
  background-color: #82CECF; }

body.template-color-8 .cart-table td.pro-subtotal span {
  color: #82CECF; }

body.template-color-8 .cart-table td.pro-addtocart button {
  border: 1px solid #82CECF;
  background-color: #82CECF; }

body.template-color-8 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #82CECF; }

body.template-color-8 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #82CECF; }

body.template-color-8 .slider-business .video-btn a .video-icon.secondary-color {
  background: #82CECF !important; }

body.template-color-8 a.brook-btn.bk-btn-yellow, body.template-color-8 button.brook-btn.bk-btn-yellow {
  background: #82CECF;
  color: #fff !important; }

body.template-color-8 a.bk-btn.theme-btn, body.template-color-8 button.bk-btn.theme-btn {
  background-color: #82CECF; }

body.template-color-8 a.brook-btn.bk-btn-red, body.template-color-8 button.brook-btn.bk-btn-red {
  background: #82CECF; }

body.template-color-8 .section-title h3 {
  color: #82CECF; }

body.template-color-8 .service.service--1:hover::before {
  border-color: #82CECF; }

body.template-color-8 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-8 .service.service--1 .icons i {
    color: #82CECF;
    font-size: 80px; }

body.template-color-8 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #82CECF;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-8 .service.service--1 .content a.service-btn:hover {
  color: #82CECF; }
  body.template-color-8 .service.service--1 .content a.service-btn:hover i {
    color: #82CECF; }

body.template-color-8 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-8 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-8 .service.service--3 .content h3 a:hover {
  color: #82CECF; }

body.template-color-8 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-8 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-8 .title-separator.color-red::after, body.template-color-8 .title-separator.color-blue::after {
    background: #82CECF; }

body.template-color-8 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-8 .bk-gradation .item-grid .dot-wrap .dot {
    background: #82CECF; }
  body.template-color-8 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #82CECF; }
  body.template-color-8 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #82CECF; }
  body.template-color-8 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #82CECF; }

body.template-color-8 .post-nav-lisnt .nav-item a:hover {
  background: #82CECF; }

body.template-color-8 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #82CECF; }

body.template-color-8 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #82CECF; }

body.template-color-8 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #82CECF; }

body.template-color-8 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #82CECF; }

body.template-color-8 .team.team__style--2 .thumb .overlay {
  background-color: #82CECF; }
  body.template-color-8 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #82CECF; }

body.template-color-8 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-8 .team.team__style--3 .thumb .overlay {
  background-color: #82CECF; }
  body.template-color-8 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #82CECF; }

body.template-color-8 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-8 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #82CECF; }
  body.template-color-8 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #82CECF; }
  body.template-color-8 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #82CECF; }
  body.template-color-8 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-8 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #82CECF; }
  body.template-color-8 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #82CECF;
    color: #fff; }

body.template-color-8 [data-overlay]:before {
  background-color: #82CECF; }

body.template-color-8 .team-heading .heading-right button i {
  color: #82CECF; }

body.template-color-8 .team-heading .heading-right button:hover {
  color: #82CECF !important; }

body.template-color-8 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #82CECF; }

body.template-color-8 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-8 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #82CECF; }

body.template-color-8 .mini-cart .shopping-cart {
  border-bottom: 2px solid #82CECF; }
  body.template-color-8 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #82CECF; }
  body.template-color-8 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #82CECF; }
  body.template-color-8 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #82CECF;
    background-color: #82CECF; }

body.template-color-8 .brook__toolbar .inner a:hover {
  color: #82CECF; }

body.template-color-8 .blog-grid .post-inner h5.heading:hover {
  color: #82CECF; }
  body.template-color-8 .blog-grid .post-inner h5.heading:hover a {
    color: #82CECF; }

body.template-color-8 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #82CECF; }

body.template-color-8 .theme-color {
  color: #82CECF; }

body.template-color-8 .bg_color--2 {
  background: #82CECF; }

body.template-color-8 a.brook-btn.bk-btn-theme,
body.template-color-8 button.brook-btn.bk-btn-theme {
  background: #82CECF;
  color: #fff; }

body.template-color-8 .btn-essential:hover {
  background: #82CECF !important;
  border-color: #82CECF !important;
  color: #fff; }

body.template-color-8 .red-border {
  border-color: #82CECF !important; }

body.template-color-8 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #82CECF;
  color: #fff; }

body.template-color-8 .single-svg-icon-box .svg-icon svg * {
  stroke: #82CECF !important; }

body.template-color-8 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-8 .menu-price {
  color: #82CECF; }

body.template-color-8 a.moredetails-btn:hover {
  color: #82CECF; }

body.template-color-8 a.moredetails-btn i {
  color: #82CECF; }

body.template-color-8 .messonry-button button.is-checked span.filter-text {
  color: #82CECF; }

body.template-color-8 .messonry-button button:hover span.filter-text {
  color: #82CECF; }

body.template-color-8 .messonry-button button span.filter-counter {
  background-color: #82CECF; }
  body.template-color-8 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #82CECF;
    border-top-color: #82CECF; }

body.template-color-8 .messonry-button button:hover span.filter-text {
  color: #82CECF; }

body.template-color-8 .messonry-button button.is-checked span.filter-text {
  color: #82CECF; }

body.template-color-8 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #82CECF; }

body.template-color-8 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #82CECF; }

body.template-color-8 .testimonial_style--1 .content .testimonial-quote span {
  color: #82CECF; }

body.template-color-8 .testimonial_style--1:hover {
  background: #82CECF; }
  body.template-color-8 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-8 .contact-form.contact-form--4 .input-box button {
  color: #82CECF; }
  body.template-color-8 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #82CECF; }

body.template-color-8 .contact-form.contact-form--3 .input-box button {
  color: #82CECF; }

body.template-color-8 .animated-dot {
  background-color: #82CECF; }
  body.template-color-8 .animated-dot .middle-dot {
    background-color: #82CECF; }
  body.template-color-8 .animated-dot .signal {
    background-color: #82CECF; }
  body.template-color-8 .animated-dot .signal2 {
    background-color: #82CECF; }

body.template-color-8 .progress-charts .progress .progress-bar {
  background-color: #82CECF; }

body.template-color-8 .brook-counter .icon i {
  color: #82CECF; }

body.template-color-8 .social-share a:hover {
  color: #82CECF; }

body.template-color-8 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #82CECF; }

body.template-color-8 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #82CECF; }

body.template-color-8 .video-btn a.video-icon,
body.template-color-8 .video-btn a .video-icon {
  background: #82CECF; }

body.template-color-8 .video-btn-2 .icon-gradient::before {
  background-color: #82CECF;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #82CECF 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #82CECF 100%);
  background-image: linear-gradient(-133deg, #899664 0, #82CECF 100%); }

body.template-color-8 .video-btn-2 .icon-gradient::after {
  background-color: #82CECF;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #82CECF 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #82CECF 100%);
  background-image: linear-gradient(-133deg, #899664 0, #82CECF 100%); }

body.template-color-8 .single-motive-speach .icon i {
  color: #82CECF; }

body.template-color-8 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #82CECF; }
  body.template-color-8 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #82CECF; }

body.template-color-8 .contact-form input:focus,
body.template-color-8 .contact-form textarea:focus {
  border-color: #82CECF; }

body.template-color-8 .contact-form input[type="submit"],
body.template-color-8 .contact-form textarea[type="submit"] {
  border-color: #82CECF;
  background-color: #82CECF; }

body.template-color-8 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #82CECF;
  color: #fff;
  border-color: #82CECF; }

body.template-color-8 .gallery .hover-overlay {
  background-color: #82CECF; }

body.template-color-8 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #82CECF; }

body.template-color-8 .blog-grid .post-content::before {
  background-color: #82CECF; }

body.template-color-8 .blog-grid-no-flex .post-quote::before {
  color: #82CECF; }

body.template-color-8 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #82CECF; }
  body.template-color-8 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #82CECF; }

body.template-color-8 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #82CECF; }

body.template-color-8 .bl-sidebar.search button:hover {
  background-color: #82CECF; }

body.template-color-8 .bl-sidebar.search button span {
  color: #82CECF; }

body.template-color-8 .bl-sidebar.category .category-list li a:hover {
  color: #82CECF; }

body.template-color-8 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #82CECF; }

body.template-color-8 .social-icon.icon-solid-rounded li a:hover {
  background-color: #82CECF; }

body.template-color-8 .icon-box .inner .icon {
  color: #82CECF; }

body.template-color-8 .basic-thick-line-theme-4 {
  border-top: 4px solid #82CECF; }

body.template-color-8 .procing-box .header .feature-mark {
  background-color: #82CECF; }

body.template-color-8 a.brook-btn.bk-btn-dark:hover {
  background: #82CECF; }

body.template-color-8 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #82CECF; }

body.template-color-8 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #82CECF; }

body.template-color-8 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #82CECF !important; }

body.template-color-8 .basic-modern-dots .dot {
  background-color: #82CECF; }

body.template-color-8 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #82CECF; }

body.template-color-8 a.brook-btn.bk-btn-theme-border,
body.template-color-8 button.brook-btn.bk-btn-theme-border {
  border-color: #82CECF;
  color: #82CECF; }
  body.template-color-8 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-8 button.brook-btn.bk-btn-theme-border:hover {
    background: #82CECF;
    color: #ffffff; }

body.template-color-8 .brook-quote::before {
  color: #82CECF; }

body.template-color-8 .bk-text-box.bg-theme-color {
  background: #82CECF; }

body.template-color-8 .bk-list--2 .list-header.with-number--theme .marker {
  color: #82CECF; }

body.template-color-8 .view-more-btn a span.btn-arrow {
  color: #82CECF; }

body.template-color-8 #fp-nav ul li a.active {
  background-color: #82CECF; }

body.template-color-9 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F0263F; }

body.template-color-9 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F0263F; }

body.template-color-9 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F0263F; }

body.template-color-9 .faq-content h2 {
  color: #F0263F; }

body.template-color-9 .brook-section-title-corona h2 {
  color: #F0263F; }

body.template-color-9 .single-contagion .contagion-content h3 {
  color: #F0263F; }

body.template-color-9 .single-sympotom .sympotom-content h3 {
  color: #F0263F; }

body.template-color-9 .single-prevination .prevination-content h3 {
  color: #F0263F; }

body.template-color-9 .service.service--1.theme-color {
  background: #F0263F; }

body.template-color-9 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F0263F; }
  body.template-color-9 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F0263F; }

body.template-color-9 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-9 .single-svg-icon-box.business-icon-box:hover {
  background: #F0263F; }

body.template-color-9 .product_review .description_nav a.active, body.template-color-9 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F0263F; }

body.template-color-9 .single-product-details-side .product-action a {
  border-color: #F0263F;
  background-color: #F0263F; }

body.template-color-9 .cart-table td.pro-subtotal span {
  color: #F0263F; }

body.template-color-9 .cart-table td.pro-addtocart button {
  border: 1px solid #F0263F;
  background-color: #F0263F; }

body.template-color-9 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F0263F; }

body.template-color-9 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F0263F; }

body.template-color-9 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F0263F !important; }

body.template-color-9 a.brook-btn.bk-btn-yellow, body.template-color-9 button.brook-btn.bk-btn-yellow {
  background: #F0263F;
  color: #fff !important; }

body.template-color-9 a.bk-btn.theme-btn, body.template-color-9 button.bk-btn.theme-btn {
  background-color: #F0263F; }

body.template-color-9 a.brook-btn.bk-btn-red, body.template-color-9 button.brook-btn.bk-btn-red {
  background: #F0263F; }

body.template-color-9 .section-title h3 {
  color: #F0263F; }

body.template-color-9 .service.service--1:hover::before {
  border-color: #F0263F; }

body.template-color-9 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-9 .service.service--1 .icons i {
    color: #F0263F;
    font-size: 80px; }

body.template-color-9 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F0263F;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-9 .service.service--1 .content a.service-btn:hover {
  color: #F0263F; }
  body.template-color-9 .service.service--1 .content a.service-btn:hover i {
    color: #F0263F; }

body.template-color-9 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-9 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-9 .service.service--3 .content h3 a:hover {
  color: #F0263F; }

body.template-color-9 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-9 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-9 .title-separator.color-red::after, body.template-color-9 .title-separator.color-blue::after {
    background: #F0263F; }

body.template-color-9 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-9 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F0263F; }
  body.template-color-9 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F0263F; }
  body.template-color-9 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F0263F; }
  body.template-color-9 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F0263F; }

body.template-color-9 .post-nav-lisnt .nav-item a:hover {
  background: #F0263F; }

body.template-color-9 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F0263F; }

body.template-color-9 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F0263F; }

body.template-color-9 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F0263F; }

body.template-color-9 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F0263F; }

body.template-color-9 .team.team__style--2 .thumb .overlay {
  background-color: #F0263F; }
  body.template-color-9 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F0263F; }

body.template-color-9 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-9 .team.team__style--3 .thumb .overlay {
  background-color: #F0263F; }
  body.template-color-9 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F0263F; }

body.template-color-9 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-9 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F0263F; }
  body.template-color-9 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F0263F; }
  body.template-color-9 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F0263F; }
  body.template-color-9 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-9 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F0263F; }
  body.template-color-9 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F0263F;
    color: #fff; }

body.template-color-9 [data-overlay]:before {
  background-color: #F0263F; }

body.template-color-9 .team-heading .heading-right button i {
  color: #F0263F; }

body.template-color-9 .team-heading .heading-right button:hover {
  color: #F0263F !important; }

body.template-color-9 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F0263F; }

body.template-color-9 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-9 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F0263F; }

body.template-color-9 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F0263F; }
  body.template-color-9 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F0263F; }
  body.template-color-9 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F0263F; }
  body.template-color-9 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F0263F;
    background-color: #F0263F; }

body.template-color-9 .brook__toolbar .inner a:hover {
  color: #F0263F; }

body.template-color-9 .blog-grid .post-inner h5.heading:hover {
  color: #F0263F; }
  body.template-color-9 .blog-grid .post-inner h5.heading:hover a {
    color: #F0263F; }

body.template-color-9 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F0263F; }

body.template-color-9 .theme-color {
  color: #F0263F; }

body.template-color-9 .bg_color--2 {
  background: #F0263F; }

body.template-color-9 a.brook-btn.bk-btn-theme,
body.template-color-9 button.brook-btn.bk-btn-theme {
  background: #F0263F;
  color: #fff; }

body.template-color-9 .btn-essential:hover {
  background: #F0263F !important;
  border-color: #F0263F !important;
  color: #fff; }

body.template-color-9 .red-border {
  border-color: #F0263F !important; }

body.template-color-9 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F0263F;
  color: #fff; }

body.template-color-9 .single-svg-icon-box .svg-icon svg * {
  stroke: #F0263F !important; }

body.template-color-9 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-9 .menu-price {
  color: #F0263F; }

body.template-color-9 a.moredetails-btn:hover {
  color: #F0263F; }

body.template-color-9 a.moredetails-btn i {
  color: #F0263F; }

body.template-color-9 .messonry-button button.is-checked span.filter-text {
  color: #F0263F; }

body.template-color-9 .messonry-button button:hover span.filter-text {
  color: #F0263F; }

body.template-color-9 .messonry-button button span.filter-counter {
  background-color: #F0263F; }
  body.template-color-9 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F0263F;
    border-top-color: #F0263F; }

body.template-color-9 .messonry-button button:hover span.filter-text {
  color: #F0263F; }

body.template-color-9 .messonry-button button.is-checked span.filter-text {
  color: #F0263F; }

body.template-color-9 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F0263F; }

body.template-color-9 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F0263F; }

body.template-color-9 .testimonial_style--1 .content .testimonial-quote span {
  color: #F0263F; }

body.template-color-9 .testimonial_style--1:hover {
  background: #F0263F; }
  body.template-color-9 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-9 .contact-form.contact-form--4 .input-box button {
  color: #F0263F; }
  body.template-color-9 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F0263F; }

body.template-color-9 .contact-form.contact-form--3 .input-box button {
  color: #F0263F; }

body.template-color-9 .animated-dot {
  background-color: #F0263F; }
  body.template-color-9 .animated-dot .middle-dot {
    background-color: #F0263F; }
  body.template-color-9 .animated-dot .signal {
    background-color: #F0263F; }
  body.template-color-9 .animated-dot .signal2 {
    background-color: #F0263F; }

body.template-color-9 .progress-charts .progress .progress-bar {
  background-color: #F0263F; }

body.template-color-9 .brook-counter .icon i {
  color: #F0263F; }

body.template-color-9 .social-share a:hover {
  color: #F0263F; }

body.template-color-9 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F0263F; }

body.template-color-9 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F0263F; }

body.template-color-9 .video-btn a.video-icon,
body.template-color-9 .video-btn a .video-icon {
  background: #F0263F; }

body.template-color-9 .video-btn-2 .icon-gradient::before {
  background-color: #F0263F;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F0263F 100%); }

body.template-color-9 .video-btn-2 .icon-gradient::after {
  background-color: #F0263F;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F0263F 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F0263F 100%); }

body.template-color-9 .single-motive-speach .icon i {
  color: #F0263F; }

body.template-color-9 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F0263F; }
  body.template-color-9 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F0263F; }

body.template-color-9 .contact-form input:focus,
body.template-color-9 .contact-form textarea:focus {
  border-color: #F0263F; }

body.template-color-9 .contact-form input[type="submit"],
body.template-color-9 .contact-form textarea[type="submit"] {
  border-color: #F0263F;
  background-color: #F0263F; }

body.template-color-9 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F0263F;
  color: #fff;
  border-color: #F0263F; }

body.template-color-9 .gallery .hover-overlay {
  background-color: #F0263F; }

body.template-color-9 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F0263F; }

body.template-color-9 .blog-grid .post-content::before {
  background-color: #F0263F; }

body.template-color-9 .blog-grid-no-flex .post-quote::before {
  color: #F0263F; }

body.template-color-9 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F0263F; }
  body.template-color-9 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F0263F; }

body.template-color-9 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F0263F; }

body.template-color-9 .bl-sidebar.search button:hover {
  background-color: #F0263F; }

body.template-color-9 .bl-sidebar.search button span {
  color: #F0263F; }

body.template-color-9 .bl-sidebar.category .category-list li a:hover {
  color: #F0263F; }

body.template-color-9 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F0263F; }

body.template-color-9 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F0263F; }

body.template-color-9 .icon-box .inner .icon {
  color: #F0263F; }

body.template-color-9 .basic-thick-line-theme-4 {
  border-top: 4px solid #F0263F; }

body.template-color-9 .procing-box .header .feature-mark {
  background-color: #F0263F; }

body.template-color-9 a.brook-btn.bk-btn-dark:hover {
  background: #F0263F; }

body.template-color-9 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F0263F; }

body.template-color-9 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F0263F; }

body.template-color-9 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F0263F !important; }

body.template-color-9 .basic-modern-dots .dot {
  background-color: #F0263F; }

body.template-color-9 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F0263F; }

body.template-color-9 a.brook-btn.bk-btn-theme-border,
body.template-color-9 button.brook-btn.bk-btn-theme-border {
  border-color: #F0263F;
  color: #F0263F; }
  body.template-color-9 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-9 button.brook-btn.bk-btn-theme-border:hover {
    background: #F0263F;
    color: #ffffff; }

body.template-color-9 .brook-quote::before {
  color: #F0263F; }

body.template-color-9 .bk-text-box.bg-theme-color {
  background: #F0263F; }

body.template-color-9 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F0263F; }

body.template-color-9 .view-more-btn a span.btn-arrow {
  color: #F0263F; }

body.template-color-9 #fp-nav ul li a.active {
  background-color: #F0263F; }

body.template-color-10 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #19d2a8; }

body.template-color-10 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #19d2a8; }

body.template-color-10 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #19d2a8; }

body.template-color-10 .faq-content h2 {
  color: #19d2a8; }

body.template-color-10 .brook-section-title-corona h2 {
  color: #19d2a8; }

body.template-color-10 .single-contagion .contagion-content h3 {
  color: #19d2a8; }

body.template-color-10 .single-sympotom .sympotom-content h3 {
  color: #19d2a8; }

body.template-color-10 .single-prevination .prevination-content h3 {
  color: #19d2a8; }

body.template-color-10 .service.service--1.theme-color {
  background: #19d2a8; }

body.template-color-10 .blog-grid-minimal.outline.bg-transparant {
  border-color: #19d2a8; }
  body.template-color-10 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #19d2a8; }

body.template-color-10 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-10 .single-svg-icon-box.business-icon-box:hover {
  background: #19d2a8; }

body.template-color-10 .product_review .description_nav a.active, body.template-color-10 .product_review .description_nav a:hover {
  border-bottom: 3px solid #19d2a8; }

body.template-color-10 .single-product-details-side .product-action a {
  border-color: #19d2a8;
  background-color: #19d2a8; }

body.template-color-10 .cart-table td.pro-subtotal span {
  color: #19d2a8; }

body.template-color-10 .cart-table td.pro-addtocart button {
  border: 1px solid #19d2a8;
  background-color: #19d2a8; }

body.template-color-10 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #19d2a8; }

body.template-color-10 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #19d2a8; }

body.template-color-10 .slider-business .video-btn a .video-icon.secondary-color {
  background: #19d2a8 !important; }

body.template-color-10 a.brook-btn.bk-btn-yellow, body.template-color-10 button.brook-btn.bk-btn-yellow {
  background: #19d2a8;
  color: #fff !important; }

body.template-color-10 a.bk-btn.theme-btn, body.template-color-10 button.bk-btn.theme-btn {
  background-color: #19d2a8; }

body.template-color-10 a.brook-btn.bk-btn-red, body.template-color-10 button.brook-btn.bk-btn-red {
  background: #19d2a8; }

body.template-color-10 .section-title h3 {
  color: #19d2a8; }

body.template-color-10 .service.service--1:hover::before {
  border-color: #19d2a8; }

body.template-color-10 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-10 .service.service--1 .icons i {
    color: #19d2a8;
    font-size: 80px; }

body.template-color-10 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #19d2a8;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-10 .service.service--1 .content a.service-btn:hover {
  color: #19d2a8; }
  body.template-color-10 .service.service--1 .content a.service-btn:hover i {
    color: #19d2a8; }

body.template-color-10 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-10 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-10 .service.service--3 .content h3 a:hover {
  color: #19d2a8; }

body.template-color-10 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-10 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-10 .title-separator.color-red::after, body.template-color-10 .title-separator.color-blue::after {
    background: #19d2a8; }

body.template-color-10 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-10 .bk-gradation .item-grid .dot-wrap .dot {
    background: #19d2a8; }
  body.template-color-10 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #19d2a8; }
  body.template-color-10 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #19d2a8; }
  body.template-color-10 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #19d2a8; }

body.template-color-10 .post-nav-lisnt .nav-item a:hover {
  background: #19d2a8; }

body.template-color-10 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #19d2a8; }

body.template-color-10 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #19d2a8; }

body.template-color-10 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #19d2a8; }

body.template-color-10 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #19d2a8; }

body.template-color-10 .team.team__style--2 .thumb .overlay {
  background-color: #19d2a8; }
  body.template-color-10 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #19d2a8; }

body.template-color-10 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-10 .team.team__style--3 .thumb .overlay {
  background-color: #19d2a8; }
  body.template-color-10 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #19d2a8; }

body.template-color-10 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-10 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #19d2a8; }
  body.template-color-10 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #19d2a8; }
  body.template-color-10 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #19d2a8; }
  body.template-color-10 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-10 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #19d2a8; }
  body.template-color-10 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #19d2a8;
    color: #fff; }

body.template-color-10 [data-overlay]:before {
  background-color: #19d2a8; }

body.template-color-10 .team-heading .heading-right button i {
  color: #19d2a8; }

body.template-color-10 .team-heading .heading-right button:hover {
  color: #19d2a8 !important; }

body.template-color-10 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #19d2a8; }

body.template-color-10 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-10 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #19d2a8; }

body.template-color-10 .mini-cart .shopping-cart {
  border-bottom: 2px solid #19d2a8; }
  body.template-color-10 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #19d2a8; }
  body.template-color-10 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #19d2a8; }
  body.template-color-10 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #19d2a8;
    background-color: #19d2a8; }

body.template-color-10 .brook__toolbar .inner a:hover {
  color: #19d2a8; }

body.template-color-10 .blog-grid .post-inner h5.heading:hover {
  color: #19d2a8; }
  body.template-color-10 .blog-grid .post-inner h5.heading:hover a {
    color: #19d2a8; }

body.template-color-10 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #19d2a8; }

body.template-color-10 .theme-color {
  color: #19d2a8; }

body.template-color-10 .bg_color--2 {
  background: #19d2a8; }

body.template-color-10 a.brook-btn.bk-btn-theme,
body.template-color-10 button.brook-btn.bk-btn-theme {
  background: #19d2a8;
  color: #fff; }

body.template-color-10 .btn-essential:hover {
  background: #19d2a8 !important;
  border-color: #19d2a8 !important;
  color: #fff; }

body.template-color-10 .red-border {
  border-color: #19d2a8 !important; }

body.template-color-10 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #19d2a8;
  color: #fff; }

body.template-color-10 .single-svg-icon-box .svg-icon svg * {
  stroke: #19d2a8 !important; }

body.template-color-10 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-10 .menu-price {
  color: #19d2a8; }

body.template-color-10 a.moredetails-btn:hover {
  color: #19d2a8; }

body.template-color-10 a.moredetails-btn i {
  color: #19d2a8; }

body.template-color-10 .messonry-button button.is-checked span.filter-text {
  color: #19d2a8; }

body.template-color-10 .messonry-button button:hover span.filter-text {
  color: #19d2a8; }

body.template-color-10 .messonry-button button span.filter-counter {
  background-color: #19d2a8; }
  body.template-color-10 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #19d2a8;
    border-top-color: #19d2a8; }

body.template-color-10 .messonry-button button:hover span.filter-text {
  color: #19d2a8; }

body.template-color-10 .messonry-button button.is-checked span.filter-text {
  color: #19d2a8; }

body.template-color-10 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #19d2a8; }

body.template-color-10 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #19d2a8; }

body.template-color-10 .testimonial_style--1 .content .testimonial-quote span {
  color: #19d2a8; }

body.template-color-10 .testimonial_style--1:hover {
  background: #19d2a8; }
  body.template-color-10 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-10 .contact-form.contact-form--4 .input-box button {
  color: #19d2a8; }
  body.template-color-10 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #19d2a8; }

body.template-color-10 .contact-form.contact-form--3 .input-box button {
  color: #19d2a8; }

body.template-color-10 .animated-dot {
  background-color: #19d2a8; }
  body.template-color-10 .animated-dot .middle-dot {
    background-color: #19d2a8; }
  body.template-color-10 .animated-dot .signal {
    background-color: #19d2a8; }
  body.template-color-10 .animated-dot .signal2 {
    background-color: #19d2a8; }

body.template-color-10 .progress-charts .progress .progress-bar {
  background-color: #19d2a8; }

body.template-color-10 .brook-counter .icon i {
  color: #19d2a8; }

body.template-color-10 .social-share a:hover {
  color: #19d2a8; }

body.template-color-10 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #19d2a8; }

body.template-color-10 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #19d2a8; }

body.template-color-10 .video-btn a.video-icon,
body.template-color-10 .video-btn a .video-icon {
  background: #19d2a8; }

body.template-color-10 .video-btn-2 .icon-gradient::before {
  background-color: #19d2a8;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #19d2a8 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #19d2a8 100%);
  background-image: linear-gradient(-133deg, #899664 0, #19d2a8 100%); }

body.template-color-10 .video-btn-2 .icon-gradient::after {
  background-color: #19d2a8;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #19d2a8 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #19d2a8 100%);
  background-image: linear-gradient(-133deg, #899664 0, #19d2a8 100%); }

body.template-color-10 .single-motive-speach .icon i {
  color: #19d2a8; }

body.template-color-10 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #19d2a8; }
  body.template-color-10 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #19d2a8; }

body.template-color-10 .contact-form input:focus,
body.template-color-10 .contact-form textarea:focus {
  border-color: #19d2a8; }

body.template-color-10 .contact-form input[type="submit"],
body.template-color-10 .contact-form textarea[type="submit"] {
  border-color: #19d2a8;
  background-color: #19d2a8; }

body.template-color-10 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #19d2a8;
  color: #fff;
  border-color: #19d2a8; }

body.template-color-10 .gallery .hover-overlay {
  background-color: #19d2a8; }

body.template-color-10 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #19d2a8; }

body.template-color-10 .blog-grid .post-content::before {
  background-color: #19d2a8; }

body.template-color-10 .blog-grid-no-flex .post-quote::before {
  color: #19d2a8; }

body.template-color-10 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #19d2a8; }
  body.template-color-10 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #19d2a8; }

body.template-color-10 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #19d2a8; }

body.template-color-10 .bl-sidebar.search button:hover {
  background-color: #19d2a8; }

body.template-color-10 .bl-sidebar.search button span {
  color: #19d2a8; }

body.template-color-10 .bl-sidebar.category .category-list li a:hover {
  color: #19d2a8; }

body.template-color-10 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #19d2a8; }

body.template-color-10 .social-icon.icon-solid-rounded li a:hover {
  background-color: #19d2a8; }

body.template-color-10 .icon-box .inner .icon {
  color: #19d2a8; }

body.template-color-10 .basic-thick-line-theme-4 {
  border-top: 4px solid #19d2a8; }

body.template-color-10 .procing-box .header .feature-mark {
  background-color: #19d2a8; }

body.template-color-10 a.brook-btn.bk-btn-dark:hover {
  background: #19d2a8; }

body.template-color-10 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #19d2a8; }

body.template-color-10 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #19d2a8; }

body.template-color-10 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #19d2a8 !important; }

body.template-color-10 .basic-modern-dots .dot {
  background-color: #19d2a8; }

body.template-color-10 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #19d2a8; }

body.template-color-10 a.brook-btn.bk-btn-theme-border,
body.template-color-10 button.brook-btn.bk-btn-theme-border {
  border-color: #19d2a8;
  color: #19d2a8; }
  body.template-color-10 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-10 button.brook-btn.bk-btn-theme-border:hover {
    background: #19d2a8;
    color: #ffffff; }

body.template-color-10 .brook-quote::before {
  color: #19d2a8; }

body.template-color-10 .bk-text-box.bg-theme-color {
  background: #19d2a8; }

body.template-color-10 .bk-list--2 .list-header.with-number--theme .marker {
  color: #19d2a8; }

body.template-color-10 .view-more-btn a span.btn-arrow {
  color: #19d2a8; }

body.template-color-10 #fp-nav ul li a.active {
  background-color: #19d2a8; }

body.template-color-11 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #0069FF; }

body.template-color-11 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #0069FF; }

body.template-color-11 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #0069FF; }

body.template-color-11 .faq-content h2 {
  color: #0069FF; }

body.template-color-11 .brook-section-title-corona h2 {
  color: #0069FF; }

body.template-color-11 .single-contagion .contagion-content h3 {
  color: #0069FF; }

body.template-color-11 .single-sympotom .sympotom-content h3 {
  color: #0069FF; }

body.template-color-11 .single-prevination .prevination-content h3 {
  color: #0069FF; }

body.template-color-11 .service.service--1.theme-color {
  background: #0069FF; }

body.template-color-11 .blog-grid-minimal.outline.bg-transparant {
  border-color: #0069FF; }
  body.template-color-11 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #0069FF; }

body.template-color-11 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-11 .single-svg-icon-box.business-icon-box:hover {
  background: #0069FF; }

body.template-color-11 .product_review .description_nav a.active, body.template-color-11 .product_review .description_nav a:hover {
  border-bottom: 3px solid #0069FF; }

body.template-color-11 .single-product-details-side .product-action a {
  border-color: #0069FF;
  background-color: #0069FF; }

body.template-color-11 .cart-table td.pro-subtotal span {
  color: #0069FF; }

body.template-color-11 .cart-table td.pro-addtocart button {
  border: 1px solid #0069FF;
  background-color: #0069FF; }

body.template-color-11 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #0069FF; }

body.template-color-11 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #0069FF; }

body.template-color-11 .slider-business .video-btn a .video-icon.secondary-color {
  background: #0069FF !important; }

body.template-color-11 a.brook-btn.bk-btn-yellow, body.template-color-11 button.brook-btn.bk-btn-yellow {
  background: #0069FF;
  color: #fff !important; }

body.template-color-11 a.bk-btn.theme-btn, body.template-color-11 button.bk-btn.theme-btn {
  background-color: #0069FF; }

body.template-color-11 a.brook-btn.bk-btn-red, body.template-color-11 button.brook-btn.bk-btn-red {
  background: #0069FF; }

body.template-color-11 .section-title h3 {
  color: #0069FF; }

body.template-color-11 .service.service--1:hover::before {
  border-color: #0069FF; }

body.template-color-11 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-11 .service.service--1 .icons i {
    color: #0069FF;
    font-size: 80px; }

body.template-color-11 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #0069FF;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-11 .service.service--1 .content a.service-btn:hover {
  color: #0069FF; }
  body.template-color-11 .service.service--1 .content a.service-btn:hover i {
    color: #0069FF; }

body.template-color-11 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-11 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-11 .service.service--3 .content h3 a:hover {
  color: #0069FF; }

body.template-color-11 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-11 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-11 .title-separator.color-red::after, body.template-color-11 .title-separator.color-blue::after {
    background: #0069FF; }

body.template-color-11 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-11 .bk-gradation .item-grid .dot-wrap .dot {
    background: #0069FF; }
  body.template-color-11 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #0069FF; }
  body.template-color-11 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #0069FF; }
  body.template-color-11 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #0069FF; }

body.template-color-11 .post-nav-lisnt .nav-item a:hover {
  background: #0069FF; }

body.template-color-11 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #0069FF; }

body.template-color-11 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #0069FF; }

body.template-color-11 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #0069FF; }

body.template-color-11 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #0069FF; }

body.template-color-11 .team.team__style--2 .thumb .overlay {
  background-color: #0069FF; }
  body.template-color-11 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #0069FF; }

body.template-color-11 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-11 .team.team__style--3 .thumb .overlay {
  background-color: #0069FF; }
  body.template-color-11 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #0069FF; }

body.template-color-11 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-11 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #0069FF; }
  body.template-color-11 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #0069FF; }
  body.template-color-11 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #0069FF; }
  body.template-color-11 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-11 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #0069FF; }
  body.template-color-11 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #0069FF;
    color: #fff; }

body.template-color-11 [data-overlay]:before {
  background-color: #0069FF; }

body.template-color-11 .team-heading .heading-right button i {
  color: #0069FF; }

body.template-color-11 .team-heading .heading-right button:hover {
  color: #0069FF !important; }

body.template-color-11 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #0069FF; }

body.template-color-11 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-11 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #0069FF; }

body.template-color-11 .mini-cart .shopping-cart {
  border-bottom: 2px solid #0069FF; }
  body.template-color-11 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #0069FF; }
  body.template-color-11 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #0069FF; }
  body.template-color-11 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #0069FF;
    background-color: #0069FF; }

body.template-color-11 .brook__toolbar .inner a:hover {
  color: #0069FF; }

body.template-color-11 .blog-grid .post-inner h5.heading:hover {
  color: #0069FF; }
  body.template-color-11 .blog-grid .post-inner h5.heading:hover a {
    color: #0069FF; }

body.template-color-11 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #0069FF; }

body.template-color-11 .theme-color {
  color: #0069FF; }

body.template-color-11 .bg_color--2 {
  background: #0069FF; }

body.template-color-11 a.brook-btn.bk-btn-theme,
body.template-color-11 button.brook-btn.bk-btn-theme {
  background: #0069FF;
  color: #fff; }

body.template-color-11 .btn-essential:hover {
  background: #0069FF !important;
  border-color: #0069FF !important;
  color: #fff; }

body.template-color-11 .red-border {
  border-color: #0069FF !important; }

body.template-color-11 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #0069FF;
  color: #fff; }

body.template-color-11 .single-svg-icon-box .svg-icon svg * {
  stroke: #0069FF !important; }

body.template-color-11 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-11 .menu-price {
  color: #0069FF; }

body.template-color-11 a.moredetails-btn:hover {
  color: #0069FF; }

body.template-color-11 a.moredetails-btn i {
  color: #0069FF; }

body.template-color-11 .messonry-button button.is-checked span.filter-text {
  color: #0069FF; }

body.template-color-11 .messonry-button button:hover span.filter-text {
  color: #0069FF; }

body.template-color-11 .messonry-button button span.filter-counter {
  background-color: #0069FF; }
  body.template-color-11 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #0069FF;
    border-top-color: #0069FF; }

body.template-color-11 .messonry-button button:hover span.filter-text {
  color: #0069FF; }

body.template-color-11 .messonry-button button.is-checked span.filter-text {
  color: #0069FF; }

body.template-color-11 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #0069FF; }

body.template-color-11 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #0069FF; }

body.template-color-11 .testimonial_style--1 .content .testimonial-quote span {
  color: #0069FF; }

body.template-color-11 .testimonial_style--1:hover {
  background: #0069FF; }
  body.template-color-11 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-11 .contact-form.contact-form--4 .input-box button {
  color: #0069FF; }
  body.template-color-11 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #0069FF; }

body.template-color-11 .contact-form.contact-form--3 .input-box button {
  color: #0069FF; }

body.template-color-11 .animated-dot {
  background-color: #0069FF; }
  body.template-color-11 .animated-dot .middle-dot {
    background-color: #0069FF; }
  body.template-color-11 .animated-dot .signal {
    background-color: #0069FF; }
  body.template-color-11 .animated-dot .signal2 {
    background-color: #0069FF; }

body.template-color-11 .progress-charts .progress .progress-bar {
  background-color: #0069FF; }

body.template-color-11 .brook-counter .icon i {
  color: #0069FF; }

body.template-color-11 .social-share a:hover {
  color: #0069FF; }

body.template-color-11 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #0069FF; }

body.template-color-11 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #0069FF; }

body.template-color-11 .video-btn a.video-icon,
body.template-color-11 .video-btn a .video-icon {
  background: #0069FF; }

body.template-color-11 .video-btn-2 .icon-gradient::before {
  background-color: #0069FF;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #0069FF 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #0069FF 100%);
  background-image: linear-gradient(-133deg, #899664 0, #0069FF 100%); }

body.template-color-11 .video-btn-2 .icon-gradient::after {
  background-color: #0069FF;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #0069FF 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #0069FF 100%);
  background-image: linear-gradient(-133deg, #899664 0, #0069FF 100%); }

body.template-color-11 .single-motive-speach .icon i {
  color: #0069FF; }

body.template-color-11 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #0069FF; }
  body.template-color-11 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #0069FF; }

body.template-color-11 .contact-form input:focus,
body.template-color-11 .contact-form textarea:focus {
  border-color: #0069FF; }

body.template-color-11 .contact-form input[type="submit"],
body.template-color-11 .contact-form textarea[type="submit"] {
  border-color: #0069FF;
  background-color: #0069FF; }

body.template-color-11 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #0069FF;
  color: #fff;
  border-color: #0069FF; }

body.template-color-11 .gallery .hover-overlay {
  background-color: #0069FF; }

body.template-color-11 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #0069FF; }

body.template-color-11 .blog-grid .post-content::before {
  background-color: #0069FF; }

body.template-color-11 .blog-grid-no-flex .post-quote::before {
  color: #0069FF; }

body.template-color-11 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #0069FF; }
  body.template-color-11 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #0069FF; }

body.template-color-11 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #0069FF; }

body.template-color-11 .bl-sidebar.search button:hover {
  background-color: #0069FF; }

body.template-color-11 .bl-sidebar.search button span {
  color: #0069FF; }

body.template-color-11 .bl-sidebar.category .category-list li a:hover {
  color: #0069FF; }

body.template-color-11 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #0069FF; }

body.template-color-11 .social-icon.icon-solid-rounded li a:hover {
  background-color: #0069FF; }

body.template-color-11 .icon-box .inner .icon {
  color: #0069FF; }

body.template-color-11 .basic-thick-line-theme-4 {
  border-top: 4px solid #0069FF; }

body.template-color-11 .procing-box .header .feature-mark {
  background-color: #0069FF; }

body.template-color-11 a.brook-btn.bk-btn-dark:hover {
  background: #0069FF; }

body.template-color-11 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #0069FF; }

body.template-color-11 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #0069FF; }

body.template-color-11 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #0069FF !important; }

body.template-color-11 .basic-modern-dots .dot {
  background-color: #0069FF; }

body.template-color-11 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #0069FF; }

body.template-color-11 a.brook-btn.bk-btn-theme-border,
body.template-color-11 button.brook-btn.bk-btn-theme-border {
  border-color: #0069FF;
  color: #0069FF; }
  body.template-color-11 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-11 button.brook-btn.bk-btn-theme-border:hover {
    background: #0069FF;
    color: #ffffff; }

body.template-color-11 .brook-quote::before {
  color: #0069FF; }

body.template-color-11 .bk-text-box.bg-theme-color {
  background: #0069FF; }

body.template-color-11 .bk-list--2 .list-header.with-number--theme .marker {
  color: #0069FF; }

body.template-color-11 .view-more-btn a span.btn-arrow {
  color: #0069FF; }

body.template-color-11 #fp-nav ul li a.active {
  background-color: #0069FF; }

body.template-color-12 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F1C078; }

body.template-color-12 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F1C078; }

body.template-color-12 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F1C078; }

body.template-color-12 .faq-content h2 {
  color: #F1C078; }

body.template-color-12 .brook-section-title-corona h2 {
  color: #F1C078; }

body.template-color-12 .single-contagion .contagion-content h3 {
  color: #F1C078; }

body.template-color-12 .single-sympotom .sympotom-content h3 {
  color: #F1C078; }

body.template-color-12 .single-prevination .prevination-content h3 {
  color: #F1C078; }

body.template-color-12 .service.service--1.theme-color {
  background: #F1C078; }

body.template-color-12 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F1C078; }
  body.template-color-12 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F1C078; }

body.template-color-12 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-12 .single-svg-icon-box.business-icon-box:hover {
  background: #F1C078; }

body.template-color-12 .product_review .description_nav a.active, body.template-color-12 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F1C078; }

body.template-color-12 .single-product-details-side .product-action a {
  border-color: #F1C078;
  background-color: #F1C078; }

body.template-color-12 .cart-table td.pro-subtotal span {
  color: #F1C078; }

body.template-color-12 .cart-table td.pro-addtocart button {
  border: 1px solid #F1C078;
  background-color: #F1C078; }

body.template-color-12 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F1C078; }

body.template-color-12 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F1C078; }

body.template-color-12 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F1C078 !important; }

body.template-color-12 a.brook-btn.bk-btn-yellow, body.template-color-12 button.brook-btn.bk-btn-yellow {
  background: #F1C078;
  color: #fff !important; }

body.template-color-12 a.bk-btn.theme-btn, body.template-color-12 button.bk-btn.theme-btn {
  background-color: #F1C078; }

body.template-color-12 a.brook-btn.bk-btn-red, body.template-color-12 button.brook-btn.bk-btn-red {
  background: #F1C078; }

body.template-color-12 .section-title h3 {
  color: #F1C078; }

body.template-color-12 .service.service--1:hover::before {
  border-color: #F1C078; }

body.template-color-12 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-12 .service.service--1 .icons i {
    color: #F1C078;
    font-size: 80px; }

body.template-color-12 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F1C078;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-12 .service.service--1 .content a.service-btn:hover {
  color: #F1C078; }
  body.template-color-12 .service.service--1 .content a.service-btn:hover i {
    color: #F1C078; }

body.template-color-12 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-12 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-12 .service.service--3 .content h3 a:hover {
  color: #F1C078; }

body.template-color-12 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-12 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-12 .title-separator.color-red::after, body.template-color-12 .title-separator.color-blue::after {
    background: #F1C078; }

body.template-color-12 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-12 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F1C078; }
  body.template-color-12 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F1C078; }
  body.template-color-12 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F1C078; }
  body.template-color-12 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F1C078; }

body.template-color-12 .post-nav-lisnt .nav-item a:hover {
  background: #F1C078; }

body.template-color-12 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F1C078; }

body.template-color-12 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F1C078; }

body.template-color-12 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F1C078; }

body.template-color-12 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F1C078; }

body.template-color-12 .team.team__style--2 .thumb .overlay {
  background-color: #F1C078; }
  body.template-color-12 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F1C078; }

body.template-color-12 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-12 .team.team__style--3 .thumb .overlay {
  background-color: #F1C078; }
  body.template-color-12 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F1C078; }

body.template-color-12 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-12 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F1C078; }
  body.template-color-12 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F1C078; }
  body.template-color-12 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F1C078; }
  body.template-color-12 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-12 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F1C078; }
  body.template-color-12 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F1C078;
    color: #fff; }

body.template-color-12 [data-overlay]:before {
  background-color: #F1C078; }

body.template-color-12 .team-heading .heading-right button i {
  color: #F1C078; }

body.template-color-12 .team-heading .heading-right button:hover {
  color: #F1C078 !important; }

body.template-color-12 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F1C078; }

body.template-color-12 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-12 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F1C078; }

body.template-color-12 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F1C078; }
  body.template-color-12 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F1C078; }
  body.template-color-12 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F1C078; }
  body.template-color-12 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F1C078;
    background-color: #F1C078; }

body.template-color-12 .brook__toolbar .inner a:hover {
  color: #F1C078; }

body.template-color-12 .blog-grid .post-inner h5.heading:hover {
  color: #F1C078; }
  body.template-color-12 .blog-grid .post-inner h5.heading:hover a {
    color: #F1C078; }

body.template-color-12 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F1C078; }

body.template-color-12 .theme-color {
  color: #F1C078; }

body.template-color-12 .bg_color--2 {
  background: #F1C078; }

body.template-color-12 a.brook-btn.bk-btn-theme,
body.template-color-12 button.brook-btn.bk-btn-theme {
  background: #F1C078;
  color: #fff; }

body.template-color-12 .btn-essential:hover {
  background: #F1C078 !important;
  border-color: #F1C078 !important;
  color: #fff; }

body.template-color-12 .red-border {
  border-color: #F1C078 !important; }

body.template-color-12 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F1C078;
  color: #fff; }

body.template-color-12 .single-svg-icon-box .svg-icon svg * {
  stroke: #F1C078 !important; }

body.template-color-12 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-12 .menu-price {
  color: #F1C078; }

body.template-color-12 a.moredetails-btn:hover {
  color: #F1C078; }

body.template-color-12 a.moredetails-btn i {
  color: #F1C078; }

body.template-color-12 .messonry-button button.is-checked span.filter-text {
  color: #F1C078; }

body.template-color-12 .messonry-button button:hover span.filter-text {
  color: #F1C078; }

body.template-color-12 .messonry-button button span.filter-counter {
  background-color: #F1C078; }
  body.template-color-12 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F1C078;
    border-top-color: #F1C078; }

body.template-color-12 .messonry-button button:hover span.filter-text {
  color: #F1C078; }

body.template-color-12 .messonry-button button.is-checked span.filter-text {
  color: #F1C078; }

body.template-color-12 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F1C078; }

body.template-color-12 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F1C078; }

body.template-color-12 .testimonial_style--1 .content .testimonial-quote span {
  color: #F1C078; }

body.template-color-12 .testimonial_style--1:hover {
  background: #F1C078; }
  body.template-color-12 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-12 .contact-form.contact-form--4 .input-box button {
  color: #F1C078; }
  body.template-color-12 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F1C078; }

body.template-color-12 .contact-form.contact-form--3 .input-box button {
  color: #F1C078; }

body.template-color-12 .animated-dot {
  background-color: #F1C078; }
  body.template-color-12 .animated-dot .middle-dot {
    background-color: #F1C078; }
  body.template-color-12 .animated-dot .signal {
    background-color: #F1C078; }
  body.template-color-12 .animated-dot .signal2 {
    background-color: #F1C078; }

body.template-color-12 .progress-charts .progress .progress-bar {
  background-color: #F1C078; }

body.template-color-12 .brook-counter .icon i {
  color: #F1C078; }

body.template-color-12 .social-share a:hover {
  color: #F1C078; }

body.template-color-12 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F1C078; }

body.template-color-12 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F1C078; }

body.template-color-12 .video-btn a.video-icon,
body.template-color-12 .video-btn a .video-icon {
  background: #F1C078; }

body.template-color-12 .video-btn-2 .icon-gradient::before {
  background-color: #F1C078;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F1C078 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F1C078 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F1C078 100%); }

body.template-color-12 .video-btn-2 .icon-gradient::after {
  background-color: #F1C078;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F1C078 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F1C078 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F1C078 100%); }

body.template-color-12 .single-motive-speach .icon i {
  color: #F1C078; }

body.template-color-12 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F1C078; }
  body.template-color-12 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F1C078; }

body.template-color-12 .contact-form input:focus,
body.template-color-12 .contact-form textarea:focus {
  border-color: #F1C078; }

body.template-color-12 .contact-form input[type="submit"],
body.template-color-12 .contact-form textarea[type="submit"] {
  border-color: #F1C078;
  background-color: #F1C078; }

body.template-color-12 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F1C078;
  color: #fff;
  border-color: #F1C078; }

body.template-color-12 .gallery .hover-overlay {
  background-color: #F1C078; }

body.template-color-12 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F1C078; }

body.template-color-12 .blog-grid .post-content::before {
  background-color: #F1C078; }

body.template-color-12 .blog-grid-no-flex .post-quote::before {
  color: #F1C078; }

body.template-color-12 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F1C078; }
  body.template-color-12 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F1C078; }

body.template-color-12 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F1C078; }

body.template-color-12 .bl-sidebar.search button:hover {
  background-color: #F1C078; }

body.template-color-12 .bl-sidebar.search button span {
  color: #F1C078; }

body.template-color-12 .bl-sidebar.category .category-list li a:hover {
  color: #F1C078; }

body.template-color-12 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F1C078; }

body.template-color-12 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F1C078; }

body.template-color-12 .icon-box .inner .icon {
  color: #F1C078; }

body.template-color-12 .basic-thick-line-theme-4 {
  border-top: 4px solid #F1C078; }

body.template-color-12 .procing-box .header .feature-mark {
  background-color: #F1C078; }

body.template-color-12 a.brook-btn.bk-btn-dark:hover {
  background: #F1C078; }

body.template-color-12 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F1C078; }

body.template-color-12 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F1C078; }

body.template-color-12 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F1C078 !important; }

body.template-color-12 .basic-modern-dots .dot {
  background-color: #F1C078; }

body.template-color-12 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F1C078; }

body.template-color-12 a.brook-btn.bk-btn-theme-border,
body.template-color-12 button.brook-btn.bk-btn-theme-border {
  border-color: #F1C078;
  color: #F1C078; }
  body.template-color-12 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-12 button.brook-btn.bk-btn-theme-border:hover {
    background: #F1C078;
    color: #ffffff; }

body.template-color-12 .brook-quote::before {
  color: #F1C078; }

body.template-color-12 .bk-text-box.bg-theme-color {
  background: #F1C078; }

body.template-color-12 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F1C078; }

body.template-color-12 .view-more-btn a span.btn-arrow {
  color: #F1C078; }

body.template-color-12 #fp-nav ul li a.active {
  background-color: #F1C078; }

body.template-color-13 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #CAC0B3; }

body.template-color-13 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #CAC0B3; }

body.template-color-13 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #CAC0B3; }

body.template-color-13 .faq-content h2 {
  color: #CAC0B3; }

body.template-color-13 .brook-section-title-corona h2 {
  color: #CAC0B3; }

body.template-color-13 .single-contagion .contagion-content h3 {
  color: #CAC0B3; }

body.template-color-13 .single-sympotom .sympotom-content h3 {
  color: #CAC0B3; }

body.template-color-13 .single-prevination .prevination-content h3 {
  color: #CAC0B3; }

body.template-color-13 .service.service--1.theme-color {
  background: #CAC0B3; }

body.template-color-13 .blog-grid-minimal.outline.bg-transparant {
  border-color: #CAC0B3; }
  body.template-color-13 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #CAC0B3; }

body.template-color-13 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-13 .single-svg-icon-box.business-icon-box:hover {
  background: #CAC0B3; }

body.template-color-13 .product_review .description_nav a.active, body.template-color-13 .product_review .description_nav a:hover {
  border-bottom: 3px solid #CAC0B3; }

body.template-color-13 .single-product-details-side .product-action a {
  border-color: #CAC0B3;
  background-color: #CAC0B3; }

body.template-color-13 .cart-table td.pro-subtotal span {
  color: #CAC0B3; }

body.template-color-13 .cart-table td.pro-addtocart button {
  border: 1px solid #CAC0B3;
  background-color: #CAC0B3; }

body.template-color-13 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #CAC0B3; }

body.template-color-13 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #CAC0B3; }

body.template-color-13 .slider-business .video-btn a .video-icon.secondary-color {
  background: #CAC0B3 !important; }

body.template-color-13 a.brook-btn.bk-btn-yellow, body.template-color-13 button.brook-btn.bk-btn-yellow {
  background: #CAC0B3;
  color: #fff !important; }

body.template-color-13 a.bk-btn.theme-btn, body.template-color-13 button.bk-btn.theme-btn {
  background-color: #CAC0B3; }

body.template-color-13 a.brook-btn.bk-btn-red, body.template-color-13 button.brook-btn.bk-btn-red {
  background: #CAC0B3; }

body.template-color-13 .section-title h3 {
  color: #CAC0B3; }

body.template-color-13 .service.service--1:hover::before {
  border-color: #CAC0B3; }

body.template-color-13 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-13 .service.service--1 .icons i {
    color: #CAC0B3;
    font-size: 80px; }

body.template-color-13 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #CAC0B3;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-13 .service.service--1 .content a.service-btn:hover {
  color: #CAC0B3; }
  body.template-color-13 .service.service--1 .content a.service-btn:hover i {
    color: #CAC0B3; }

body.template-color-13 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-13 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-13 .service.service--3 .content h3 a:hover {
  color: #CAC0B3; }

body.template-color-13 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-13 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-13 .title-separator.color-red::after, body.template-color-13 .title-separator.color-blue::after {
    background: #CAC0B3; }

body.template-color-13 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-13 .bk-gradation .item-grid .dot-wrap .dot {
    background: #CAC0B3; }
  body.template-color-13 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #CAC0B3; }
  body.template-color-13 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #CAC0B3; }
  body.template-color-13 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #CAC0B3; }

body.template-color-13 .post-nav-lisnt .nav-item a:hover {
  background: #CAC0B3; }

body.template-color-13 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #CAC0B3; }

body.template-color-13 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #CAC0B3; }

body.template-color-13 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #CAC0B3; }

body.template-color-13 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #CAC0B3; }

body.template-color-13 .team.team__style--2 .thumb .overlay {
  background-color: #CAC0B3; }
  body.template-color-13 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #CAC0B3; }

body.template-color-13 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-13 .team.team__style--3 .thumb .overlay {
  background-color: #CAC0B3; }
  body.template-color-13 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #CAC0B3; }

body.template-color-13 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-13 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #CAC0B3; }
  body.template-color-13 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #CAC0B3; }
  body.template-color-13 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #CAC0B3; }
  body.template-color-13 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-13 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #CAC0B3; }
  body.template-color-13 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #CAC0B3;
    color: #fff; }

body.template-color-13 [data-overlay]:before {
  background-color: #CAC0B3; }

body.template-color-13 .team-heading .heading-right button i {
  color: #CAC0B3; }

body.template-color-13 .team-heading .heading-right button:hover {
  color: #CAC0B3 !important; }

body.template-color-13 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #CAC0B3; }

body.template-color-13 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-13 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #CAC0B3; }

body.template-color-13 .mini-cart .shopping-cart {
  border-bottom: 2px solid #CAC0B3; }
  body.template-color-13 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #CAC0B3; }
  body.template-color-13 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #CAC0B3; }
  body.template-color-13 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #CAC0B3;
    background-color: #CAC0B3; }

body.template-color-13 .brook__toolbar .inner a:hover {
  color: #CAC0B3; }

body.template-color-13 .blog-grid .post-inner h5.heading:hover {
  color: #CAC0B3; }
  body.template-color-13 .blog-grid .post-inner h5.heading:hover a {
    color: #CAC0B3; }

body.template-color-13 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #CAC0B3; }

body.template-color-13 .theme-color {
  color: #CAC0B3; }

body.template-color-13 .bg_color--2 {
  background: #CAC0B3; }

body.template-color-13 a.brook-btn.bk-btn-theme,
body.template-color-13 button.brook-btn.bk-btn-theme {
  background: #CAC0B3;
  color: #fff; }

body.template-color-13 .btn-essential:hover {
  background: #CAC0B3 !important;
  border-color: #CAC0B3 !important;
  color: #fff; }

body.template-color-13 .red-border {
  border-color: #CAC0B3 !important; }

body.template-color-13 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #CAC0B3;
  color: #fff; }

body.template-color-13 .single-svg-icon-box .svg-icon svg * {
  stroke: #CAC0B3 !important; }

body.template-color-13 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-13 .menu-price {
  color: #CAC0B3; }

body.template-color-13 a.moredetails-btn:hover {
  color: #CAC0B3; }

body.template-color-13 a.moredetails-btn i {
  color: #CAC0B3; }

body.template-color-13 .messonry-button button.is-checked span.filter-text {
  color: #CAC0B3; }

body.template-color-13 .messonry-button button:hover span.filter-text {
  color: #CAC0B3; }

body.template-color-13 .messonry-button button span.filter-counter {
  background-color: #CAC0B3; }
  body.template-color-13 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #CAC0B3;
    border-top-color: #CAC0B3; }

body.template-color-13 .messonry-button button:hover span.filter-text {
  color: #CAC0B3; }

body.template-color-13 .messonry-button button.is-checked span.filter-text {
  color: #CAC0B3; }

body.template-color-13 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #CAC0B3; }

body.template-color-13 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #CAC0B3; }

body.template-color-13 .testimonial_style--1 .content .testimonial-quote span {
  color: #CAC0B3; }

body.template-color-13 .testimonial_style--1:hover {
  background: #CAC0B3; }
  body.template-color-13 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-13 .contact-form.contact-form--4 .input-box button {
  color: #CAC0B3; }
  body.template-color-13 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #CAC0B3; }

body.template-color-13 .contact-form.contact-form--3 .input-box button {
  color: #CAC0B3; }

body.template-color-13 .animated-dot {
  background-color: #CAC0B3; }
  body.template-color-13 .animated-dot .middle-dot {
    background-color: #CAC0B3; }
  body.template-color-13 .animated-dot .signal {
    background-color: #CAC0B3; }
  body.template-color-13 .animated-dot .signal2 {
    background-color: #CAC0B3; }

body.template-color-13 .progress-charts .progress .progress-bar {
  background-color: #CAC0B3; }

body.template-color-13 .brook-counter .icon i {
  color: #CAC0B3; }

body.template-color-13 .social-share a:hover {
  color: #CAC0B3; }

body.template-color-13 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #CAC0B3; }

body.template-color-13 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #CAC0B3; }

body.template-color-13 .video-btn a.video-icon,
body.template-color-13 .video-btn a .video-icon {
  background: #CAC0B3; }

body.template-color-13 .video-btn-2 .icon-gradient::before {
  background-color: #CAC0B3;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #CAC0B3 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #CAC0B3 100%);
  background-image: linear-gradient(-133deg, #899664 0, #CAC0B3 100%); }

body.template-color-13 .video-btn-2 .icon-gradient::after {
  background-color: #CAC0B3;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #CAC0B3 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #CAC0B3 100%);
  background-image: linear-gradient(-133deg, #899664 0, #CAC0B3 100%); }

body.template-color-13 .single-motive-speach .icon i {
  color: #CAC0B3; }

body.template-color-13 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #CAC0B3; }
  body.template-color-13 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #CAC0B3; }

body.template-color-13 .contact-form input:focus,
body.template-color-13 .contact-form textarea:focus {
  border-color: #CAC0B3; }

body.template-color-13 .contact-form input[type="submit"],
body.template-color-13 .contact-form textarea[type="submit"] {
  border-color: #CAC0B3;
  background-color: #CAC0B3; }

body.template-color-13 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #CAC0B3;
  color: #fff;
  border-color: #CAC0B3; }

body.template-color-13 .gallery .hover-overlay {
  background-color: #CAC0B3; }

body.template-color-13 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #CAC0B3; }

body.template-color-13 .blog-grid .post-content::before {
  background-color: #CAC0B3; }

body.template-color-13 .blog-grid-no-flex .post-quote::before {
  color: #CAC0B3; }

body.template-color-13 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #CAC0B3; }
  body.template-color-13 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #CAC0B3; }

body.template-color-13 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #CAC0B3; }

body.template-color-13 .bl-sidebar.search button:hover {
  background-color: #CAC0B3; }

body.template-color-13 .bl-sidebar.search button span {
  color: #CAC0B3; }

body.template-color-13 .bl-sidebar.category .category-list li a:hover {
  color: #CAC0B3; }

body.template-color-13 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #CAC0B3; }

body.template-color-13 .social-icon.icon-solid-rounded li a:hover {
  background-color: #CAC0B3; }

body.template-color-13 .icon-box .inner .icon {
  color: #CAC0B3; }

body.template-color-13 .basic-thick-line-theme-4 {
  border-top: 4px solid #CAC0B3; }

body.template-color-13 .procing-box .header .feature-mark {
  background-color: #CAC0B3; }

body.template-color-13 a.brook-btn.bk-btn-dark:hover {
  background: #CAC0B3; }

body.template-color-13 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #CAC0B3; }

body.template-color-13 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #CAC0B3; }

body.template-color-13 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #CAC0B3 !important; }

body.template-color-13 .basic-modern-dots .dot {
  background-color: #CAC0B3; }

body.template-color-13 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #CAC0B3; }

body.template-color-13 a.brook-btn.bk-btn-theme-border,
body.template-color-13 button.brook-btn.bk-btn-theme-border {
  border-color: #CAC0B3;
  color: #CAC0B3; }
  body.template-color-13 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-13 button.brook-btn.bk-btn-theme-border:hover {
    background: #CAC0B3;
    color: #ffffff; }

body.template-color-13 .brook-quote::before {
  color: #CAC0B3; }

body.template-color-13 .bk-text-box.bg-theme-color {
  background: #CAC0B3; }

body.template-color-13 .bk-list--2 .list-header.with-number--theme .marker {
  color: #CAC0B3; }

body.template-color-13 .view-more-btn a span.btn-arrow {
  color: #CAC0B3; }

body.template-color-13 #fp-nav ul li a.active {
  background-color: #CAC0B3; }

body.template-color-14 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F55D4E; }

body.template-color-14 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F55D4E; }

body.template-color-14 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F55D4E; }

body.template-color-14 .faq-content h2 {
  color: #F55D4E; }

body.template-color-14 .brook-section-title-corona h2 {
  color: #F55D4E; }

body.template-color-14 .single-contagion .contagion-content h3 {
  color: #F55D4E; }

body.template-color-14 .single-sympotom .sympotom-content h3 {
  color: #F55D4E; }

body.template-color-14 .single-prevination .prevination-content h3 {
  color: #F55D4E; }

body.template-color-14 .service.service--1.theme-color {
  background: #F55D4E; }

body.template-color-14 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F55D4E; }
  body.template-color-14 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F55D4E; }

body.template-color-14 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-14 .single-svg-icon-box.business-icon-box:hover {
  background: #F55D4E; }

body.template-color-14 .product_review .description_nav a.active, body.template-color-14 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F55D4E; }

body.template-color-14 .single-product-details-side .product-action a {
  border-color: #F55D4E;
  background-color: #F55D4E; }

body.template-color-14 .cart-table td.pro-subtotal span {
  color: #F55D4E; }

body.template-color-14 .cart-table td.pro-addtocart button {
  border: 1px solid #F55D4E;
  background-color: #F55D4E; }

body.template-color-14 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F55D4E; }

body.template-color-14 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F55D4E; }

body.template-color-14 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F55D4E !important; }

body.template-color-14 a.brook-btn.bk-btn-yellow, body.template-color-14 button.brook-btn.bk-btn-yellow {
  background: #F55D4E;
  color: #fff !important; }

body.template-color-14 a.bk-btn.theme-btn, body.template-color-14 button.bk-btn.theme-btn {
  background-color: #F55D4E; }

body.template-color-14 a.brook-btn.bk-btn-red, body.template-color-14 button.brook-btn.bk-btn-red {
  background: #F55D4E; }

body.template-color-14 .section-title h3 {
  color: #F55D4E; }

body.template-color-14 .service.service--1:hover::before {
  border-color: #F55D4E; }

body.template-color-14 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-14 .service.service--1 .icons i {
    color: #F55D4E;
    font-size: 80px; }

body.template-color-14 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F55D4E;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-14 .service.service--1 .content a.service-btn:hover {
  color: #F55D4E; }
  body.template-color-14 .service.service--1 .content a.service-btn:hover i {
    color: #F55D4E; }

body.template-color-14 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-14 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-14 .service.service--3 .content h3 a:hover {
  color: #F55D4E; }

body.template-color-14 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-14 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-14 .title-separator.color-red::after, body.template-color-14 .title-separator.color-blue::after {
    background: #F55D4E; }

body.template-color-14 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-14 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F55D4E; }
  body.template-color-14 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F55D4E; }
  body.template-color-14 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F55D4E; }
  body.template-color-14 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F55D4E; }

body.template-color-14 .post-nav-lisnt .nav-item a:hover {
  background: #F55D4E; }

body.template-color-14 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F55D4E; }

body.template-color-14 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F55D4E; }

body.template-color-14 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F55D4E; }

body.template-color-14 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F55D4E; }

body.template-color-14 .team.team__style--2 .thumb .overlay {
  background-color: #F55D4E; }
  body.template-color-14 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F55D4E; }

body.template-color-14 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-14 .team.team__style--3 .thumb .overlay {
  background-color: #F55D4E; }
  body.template-color-14 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F55D4E; }

body.template-color-14 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-14 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F55D4E; }
  body.template-color-14 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F55D4E; }
  body.template-color-14 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F55D4E; }
  body.template-color-14 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-14 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F55D4E; }
  body.template-color-14 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F55D4E;
    color: #fff; }

body.template-color-14 [data-overlay]:before {
  background-color: #F55D4E; }

body.template-color-14 .team-heading .heading-right button i {
  color: #F55D4E; }

body.template-color-14 .team-heading .heading-right button:hover {
  color: #F55D4E !important; }

body.template-color-14 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F55D4E; }

body.template-color-14 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-14 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F55D4E; }

body.template-color-14 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F55D4E; }
  body.template-color-14 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F55D4E; }
  body.template-color-14 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F55D4E; }
  body.template-color-14 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F55D4E;
    background-color: #F55D4E; }

body.template-color-14 .brook__toolbar .inner a:hover {
  color: #F55D4E; }

body.template-color-14 .blog-grid .post-inner h5.heading:hover {
  color: #F55D4E; }
  body.template-color-14 .blog-grid .post-inner h5.heading:hover a {
    color: #F55D4E; }

body.template-color-14 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F55D4E; }

body.template-color-14 .theme-color {
  color: #F55D4E; }

body.template-color-14 .bg_color--2 {
  background: #F55D4E; }

body.template-color-14 a.brook-btn.bk-btn-theme,
body.template-color-14 button.brook-btn.bk-btn-theme {
  background: #F55D4E;
  color: #fff; }

body.template-color-14 .btn-essential:hover {
  background: #F55D4E !important;
  border-color: #F55D4E !important;
  color: #fff; }

body.template-color-14 .red-border {
  border-color: #F55D4E !important; }

body.template-color-14 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F55D4E;
  color: #fff; }

body.template-color-14 .single-svg-icon-box .svg-icon svg * {
  stroke: #F55D4E !important; }

body.template-color-14 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-14 .menu-price {
  color: #F55D4E; }

body.template-color-14 a.moredetails-btn:hover {
  color: #F55D4E; }

body.template-color-14 a.moredetails-btn i {
  color: #F55D4E; }

body.template-color-14 .messonry-button button.is-checked span.filter-text {
  color: #F55D4E; }

body.template-color-14 .messonry-button button:hover span.filter-text {
  color: #F55D4E; }

body.template-color-14 .messonry-button button span.filter-counter {
  background-color: #F55D4E; }
  body.template-color-14 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F55D4E;
    border-top-color: #F55D4E; }

body.template-color-14 .messonry-button button:hover span.filter-text {
  color: #F55D4E; }

body.template-color-14 .messonry-button button.is-checked span.filter-text {
  color: #F55D4E; }

body.template-color-14 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F55D4E; }

body.template-color-14 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F55D4E; }

body.template-color-14 .testimonial_style--1 .content .testimonial-quote span {
  color: #F55D4E; }

body.template-color-14 .testimonial_style--1:hover {
  background: #F55D4E; }
  body.template-color-14 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-14 .contact-form.contact-form--4 .input-box button {
  color: #F55D4E; }
  body.template-color-14 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F55D4E; }

body.template-color-14 .contact-form.contact-form--3 .input-box button {
  color: #F55D4E; }

body.template-color-14 .animated-dot {
  background-color: #F55D4E; }
  body.template-color-14 .animated-dot .middle-dot {
    background-color: #F55D4E; }
  body.template-color-14 .animated-dot .signal {
    background-color: #F55D4E; }
  body.template-color-14 .animated-dot .signal2 {
    background-color: #F55D4E; }

body.template-color-14 .progress-charts .progress .progress-bar {
  background-color: #F55D4E; }

body.template-color-14 .brook-counter .icon i {
  color: #F55D4E; }

body.template-color-14 .social-share a:hover {
  color: #F55D4E; }

body.template-color-14 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F55D4E; }

body.template-color-14 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F55D4E; }

body.template-color-14 .video-btn a.video-icon,
body.template-color-14 .video-btn a .video-icon {
  background: #F55D4E; }

body.template-color-14 .video-btn-2 .icon-gradient::before {
  background-color: #F55D4E;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F55D4E 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F55D4E 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F55D4E 100%); }

body.template-color-14 .video-btn-2 .icon-gradient::after {
  background-color: #F55D4E;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F55D4E 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F55D4E 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F55D4E 100%); }

body.template-color-14 .single-motive-speach .icon i {
  color: #F55D4E; }

body.template-color-14 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F55D4E; }
  body.template-color-14 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F55D4E; }

body.template-color-14 .contact-form input:focus,
body.template-color-14 .contact-form textarea:focus {
  border-color: #F55D4E; }

body.template-color-14 .contact-form input[type="submit"],
body.template-color-14 .contact-form textarea[type="submit"] {
  border-color: #F55D4E;
  background-color: #F55D4E; }

body.template-color-14 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F55D4E;
  color: #fff;
  border-color: #F55D4E; }

body.template-color-14 .gallery .hover-overlay {
  background-color: #F55D4E; }

body.template-color-14 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F55D4E; }

body.template-color-14 .blog-grid .post-content::before {
  background-color: #F55D4E; }

body.template-color-14 .blog-grid-no-flex .post-quote::before {
  color: #F55D4E; }

body.template-color-14 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F55D4E; }
  body.template-color-14 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F55D4E; }

body.template-color-14 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F55D4E; }

body.template-color-14 .bl-sidebar.search button:hover {
  background-color: #F55D4E; }

body.template-color-14 .bl-sidebar.search button span {
  color: #F55D4E; }

body.template-color-14 .bl-sidebar.category .category-list li a:hover {
  color: #F55D4E; }

body.template-color-14 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F55D4E; }

body.template-color-14 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F55D4E; }

body.template-color-14 .icon-box .inner .icon {
  color: #F55D4E; }

body.template-color-14 .basic-thick-line-theme-4 {
  border-top: 4px solid #F55D4E; }

body.template-color-14 .procing-box .header .feature-mark {
  background-color: #F55D4E; }

body.template-color-14 a.brook-btn.bk-btn-dark:hover {
  background: #F55D4E; }

body.template-color-14 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F55D4E; }

body.template-color-14 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F55D4E; }

body.template-color-14 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F55D4E !important; }

body.template-color-14 .basic-modern-dots .dot {
  background-color: #F55D4E; }

body.template-color-14 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F55D4E; }

body.template-color-14 a.brook-btn.bk-btn-theme-border,
body.template-color-14 button.brook-btn.bk-btn-theme-border {
  border-color: #F55D4E;
  color: #F55D4E; }
  body.template-color-14 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-14 button.brook-btn.bk-btn-theme-border:hover {
    background: #F55D4E;
    color: #ffffff; }

body.template-color-14 .brook-quote::before {
  color: #F55D4E; }

body.template-color-14 .bk-text-box.bg-theme-color {
  background: #F55D4E; }

body.template-color-14 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F55D4E; }

body.template-color-14 .view-more-btn a span.btn-arrow {
  color: #F55D4E; }

body.template-color-14 #fp-nav ul li a.active {
  background-color: #F55D4E; }

body.template-color-15 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #AC61EE; }

body.template-color-15 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #AC61EE; }

body.template-color-15 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #AC61EE; }

body.template-color-15 .faq-content h2 {
  color: #AC61EE; }

body.template-color-15 .brook-section-title-corona h2 {
  color: #AC61EE; }

body.template-color-15 .single-contagion .contagion-content h3 {
  color: #AC61EE; }

body.template-color-15 .single-sympotom .sympotom-content h3 {
  color: #AC61EE; }

body.template-color-15 .single-prevination .prevination-content h3 {
  color: #AC61EE; }

body.template-color-15 .service.service--1.theme-color {
  background: #AC61EE; }

body.template-color-15 .blog-grid-minimal.outline.bg-transparant {
  border-color: #AC61EE; }
  body.template-color-15 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #AC61EE; }

body.template-color-15 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-15 .single-svg-icon-box.business-icon-box:hover {
  background: #AC61EE; }

body.template-color-15 .product_review .description_nav a.active, body.template-color-15 .product_review .description_nav a:hover {
  border-bottom: 3px solid #AC61EE; }

body.template-color-15 .single-product-details-side .product-action a {
  border-color: #AC61EE;
  background-color: #AC61EE; }

body.template-color-15 .cart-table td.pro-subtotal span {
  color: #AC61EE; }

body.template-color-15 .cart-table td.pro-addtocart button {
  border: 1px solid #AC61EE;
  background-color: #AC61EE; }

body.template-color-15 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #AC61EE; }

body.template-color-15 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #AC61EE; }

body.template-color-15 .slider-business .video-btn a .video-icon.secondary-color {
  background: #AC61EE !important; }

body.template-color-15 a.brook-btn.bk-btn-yellow, body.template-color-15 button.brook-btn.bk-btn-yellow {
  background: #AC61EE;
  color: #fff !important; }

body.template-color-15 a.bk-btn.theme-btn, body.template-color-15 button.bk-btn.theme-btn {
  background-color: #AC61EE; }

body.template-color-15 a.brook-btn.bk-btn-red, body.template-color-15 button.brook-btn.bk-btn-red {
  background: #AC61EE; }

body.template-color-15 .section-title h3 {
  color: #AC61EE; }

body.template-color-15 .service.service--1:hover::before {
  border-color: #AC61EE; }

body.template-color-15 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-15 .service.service--1 .icons i {
    color: #AC61EE;
    font-size: 80px; }

body.template-color-15 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #AC61EE;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-15 .service.service--1 .content a.service-btn:hover {
  color: #AC61EE; }
  body.template-color-15 .service.service--1 .content a.service-btn:hover i {
    color: #AC61EE; }

body.template-color-15 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-15 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-15 .service.service--3 .content h3 a:hover {
  color: #AC61EE; }

body.template-color-15 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-15 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-15 .title-separator.color-red::after, body.template-color-15 .title-separator.color-blue::after {
    background: #AC61EE; }

body.template-color-15 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-15 .bk-gradation .item-grid .dot-wrap .dot {
    background: #AC61EE; }
  body.template-color-15 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #AC61EE; }
  body.template-color-15 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #AC61EE; }
  body.template-color-15 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #AC61EE; }

body.template-color-15 .post-nav-lisnt .nav-item a:hover {
  background: #AC61EE; }

body.template-color-15 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #AC61EE; }

body.template-color-15 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #AC61EE; }

body.template-color-15 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #AC61EE; }

body.template-color-15 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #AC61EE; }

body.template-color-15 .team.team__style--2 .thumb .overlay {
  background-color: #AC61EE; }
  body.template-color-15 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #AC61EE; }

body.template-color-15 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-15 .team.team__style--3 .thumb .overlay {
  background-color: #AC61EE; }
  body.template-color-15 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #AC61EE; }

body.template-color-15 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-15 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #AC61EE; }
  body.template-color-15 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #AC61EE; }
  body.template-color-15 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #AC61EE; }
  body.template-color-15 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-15 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #AC61EE; }
  body.template-color-15 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #AC61EE;
    color: #fff; }

body.template-color-15 [data-overlay]:before {
  background-color: #AC61EE; }

body.template-color-15 .team-heading .heading-right button i {
  color: #AC61EE; }

body.template-color-15 .team-heading .heading-right button:hover {
  color: #AC61EE !important; }

body.template-color-15 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #AC61EE; }

body.template-color-15 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-15 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #AC61EE; }

body.template-color-15 .mini-cart .shopping-cart {
  border-bottom: 2px solid #AC61EE; }
  body.template-color-15 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #AC61EE; }
  body.template-color-15 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #AC61EE; }
  body.template-color-15 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #AC61EE;
    background-color: #AC61EE; }

body.template-color-15 .brook__toolbar .inner a:hover {
  color: #AC61EE; }

body.template-color-15 .blog-grid .post-inner h5.heading:hover {
  color: #AC61EE; }
  body.template-color-15 .blog-grid .post-inner h5.heading:hover a {
    color: #AC61EE; }

body.template-color-15 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #AC61EE; }

body.template-color-15 .theme-color {
  color: #AC61EE; }

body.template-color-15 .bg_color--2 {
  background: #AC61EE; }

body.template-color-15 a.brook-btn.bk-btn-theme,
body.template-color-15 button.brook-btn.bk-btn-theme {
  background: #AC61EE;
  color: #fff; }

body.template-color-15 .btn-essential:hover {
  background: #AC61EE !important;
  border-color: #AC61EE !important;
  color: #fff; }

body.template-color-15 .red-border {
  border-color: #AC61EE !important; }

body.template-color-15 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #AC61EE;
  color: #fff; }

body.template-color-15 .single-svg-icon-box .svg-icon svg * {
  stroke: #AC61EE !important; }

body.template-color-15 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-15 .menu-price {
  color: #AC61EE; }

body.template-color-15 a.moredetails-btn:hover {
  color: #AC61EE; }

body.template-color-15 a.moredetails-btn i {
  color: #AC61EE; }

body.template-color-15 .messonry-button button.is-checked span.filter-text {
  color: #AC61EE; }

body.template-color-15 .messonry-button button:hover span.filter-text {
  color: #AC61EE; }

body.template-color-15 .messonry-button button span.filter-counter {
  background-color: #AC61EE; }
  body.template-color-15 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #AC61EE;
    border-top-color: #AC61EE; }

body.template-color-15 .messonry-button button:hover span.filter-text {
  color: #AC61EE; }

body.template-color-15 .messonry-button button.is-checked span.filter-text {
  color: #AC61EE; }

body.template-color-15 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #AC61EE; }

body.template-color-15 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #AC61EE; }

body.template-color-15 .testimonial_style--1 .content .testimonial-quote span {
  color: #AC61EE; }

body.template-color-15 .testimonial_style--1:hover {
  background: #AC61EE; }
  body.template-color-15 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-15 .contact-form.contact-form--4 .input-box button {
  color: #AC61EE; }
  body.template-color-15 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #AC61EE; }

body.template-color-15 .contact-form.contact-form--3 .input-box button {
  color: #AC61EE; }

body.template-color-15 .animated-dot {
  background-color: #AC61EE; }
  body.template-color-15 .animated-dot .middle-dot {
    background-color: #AC61EE; }
  body.template-color-15 .animated-dot .signal {
    background-color: #AC61EE; }
  body.template-color-15 .animated-dot .signal2 {
    background-color: #AC61EE; }

body.template-color-15 .progress-charts .progress .progress-bar {
  background-color: #AC61EE; }

body.template-color-15 .brook-counter .icon i {
  color: #AC61EE; }

body.template-color-15 .social-share a:hover {
  color: #AC61EE; }

body.template-color-15 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #AC61EE; }

body.template-color-15 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #AC61EE; }

body.template-color-15 .video-btn a.video-icon,
body.template-color-15 .video-btn a .video-icon {
  background: #AC61EE; }

body.template-color-15 .video-btn-2 .icon-gradient::before {
  background-color: #AC61EE;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #AC61EE 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #AC61EE 100%);
  background-image: linear-gradient(-133deg, #899664 0, #AC61EE 100%); }

body.template-color-15 .video-btn-2 .icon-gradient::after {
  background-color: #AC61EE;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #AC61EE 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #AC61EE 100%);
  background-image: linear-gradient(-133deg, #899664 0, #AC61EE 100%); }

body.template-color-15 .single-motive-speach .icon i {
  color: #AC61EE; }

body.template-color-15 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #AC61EE; }
  body.template-color-15 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #AC61EE; }

body.template-color-15 .contact-form input:focus,
body.template-color-15 .contact-form textarea:focus {
  border-color: #AC61EE; }

body.template-color-15 .contact-form input[type="submit"],
body.template-color-15 .contact-form textarea[type="submit"] {
  border-color: #AC61EE;
  background-color: #AC61EE; }

body.template-color-15 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #AC61EE;
  color: #fff;
  border-color: #AC61EE; }

body.template-color-15 .gallery .hover-overlay {
  background-color: #AC61EE; }

body.template-color-15 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #AC61EE; }

body.template-color-15 .blog-grid .post-content::before {
  background-color: #AC61EE; }

body.template-color-15 .blog-grid-no-flex .post-quote::before {
  color: #AC61EE; }

body.template-color-15 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #AC61EE; }
  body.template-color-15 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #AC61EE; }

body.template-color-15 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #AC61EE; }

body.template-color-15 .bl-sidebar.search button:hover {
  background-color: #AC61EE; }

body.template-color-15 .bl-sidebar.search button span {
  color: #AC61EE; }

body.template-color-15 .bl-sidebar.category .category-list li a:hover {
  color: #AC61EE; }

body.template-color-15 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #AC61EE; }

body.template-color-15 .social-icon.icon-solid-rounded li a:hover {
  background-color: #AC61EE; }

body.template-color-15 .icon-box .inner .icon {
  color: #AC61EE; }

body.template-color-15 .basic-thick-line-theme-4 {
  border-top: 4px solid #AC61EE; }

body.template-color-15 .procing-box .header .feature-mark {
  background-color: #AC61EE; }

body.template-color-15 a.brook-btn.bk-btn-dark:hover {
  background: #AC61EE; }

body.template-color-15 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #AC61EE; }

body.template-color-15 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #AC61EE; }

body.template-color-15 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #AC61EE !important; }

body.template-color-15 .basic-modern-dots .dot {
  background-color: #AC61EE; }

body.template-color-15 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #AC61EE; }

body.template-color-15 a.brook-btn.bk-btn-theme-border,
body.template-color-15 button.brook-btn.bk-btn-theme-border {
  border-color: #AC61EE;
  color: #AC61EE; }
  body.template-color-15 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-15 button.brook-btn.bk-btn-theme-border:hover {
    background: #AC61EE;
    color: #ffffff; }

body.template-color-15 .brook-quote::before {
  color: #AC61EE; }

body.template-color-15 .bk-text-box.bg-theme-color {
  background: #AC61EE; }

body.template-color-15 .bk-list--2 .list-header.with-number--theme .marker {
  color: #AC61EE; }

body.template-color-15 .view-more-btn a span.btn-arrow {
  color: #AC61EE; }

body.template-color-15 #fp-nav ul li a.active {
  background-color: #AC61EE; }

body.template-color-16 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F5A623; }

body.template-color-16 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F5A623; }

body.template-color-16 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F5A623; }

body.template-color-16 .faq-content h2 {
  color: #F5A623; }

body.template-color-16 .brook-section-title-corona h2 {
  color: #F5A623; }

body.template-color-16 .single-contagion .contagion-content h3 {
  color: #F5A623; }

body.template-color-16 .single-sympotom .sympotom-content h3 {
  color: #F5A623; }

body.template-color-16 .single-prevination .prevination-content h3 {
  color: #F5A623; }

body.template-color-16 .service.service--1.theme-color {
  background: #F5A623; }

body.template-color-16 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F5A623; }
  body.template-color-16 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F5A623; }

body.template-color-16 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-16 .single-svg-icon-box.business-icon-box:hover {
  background: #F5A623; }

body.template-color-16 .product_review .description_nav a.active, body.template-color-16 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F5A623; }

body.template-color-16 .single-product-details-side .product-action a {
  border-color: #F5A623;
  background-color: #F5A623; }

body.template-color-16 .cart-table td.pro-subtotal span {
  color: #F5A623; }

body.template-color-16 .cart-table td.pro-addtocart button {
  border: 1px solid #F5A623;
  background-color: #F5A623; }

body.template-color-16 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F5A623; }

body.template-color-16 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F5A623; }

body.template-color-16 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F5A623 !important; }

body.template-color-16 a.brook-btn.bk-btn-yellow, body.template-color-16 button.brook-btn.bk-btn-yellow {
  background: #F5A623;
  color: #fff !important; }

body.template-color-16 a.bk-btn.theme-btn, body.template-color-16 button.bk-btn.theme-btn {
  background-color: #F5A623; }

body.template-color-16 a.brook-btn.bk-btn-red, body.template-color-16 button.brook-btn.bk-btn-red {
  background: #F5A623; }

body.template-color-16 .section-title h3 {
  color: #F5A623; }

body.template-color-16 .service.service--1:hover::before {
  border-color: #F5A623; }

body.template-color-16 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-16 .service.service--1 .icons i {
    color: #F5A623;
    font-size: 80px; }

body.template-color-16 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F5A623;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-16 .service.service--1 .content a.service-btn:hover {
  color: #F5A623; }
  body.template-color-16 .service.service--1 .content a.service-btn:hover i {
    color: #F5A623; }

body.template-color-16 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-16 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-16 .service.service--3 .content h3 a:hover {
  color: #F5A623; }

body.template-color-16 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-16 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-16 .title-separator.color-red::after, body.template-color-16 .title-separator.color-blue::after {
    background: #F5A623; }

body.template-color-16 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-16 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F5A623; }
  body.template-color-16 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F5A623; }
  body.template-color-16 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F5A623; }
  body.template-color-16 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F5A623; }

body.template-color-16 .post-nav-lisnt .nav-item a:hover {
  background: #F5A623; }

body.template-color-16 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F5A623; }

body.template-color-16 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F5A623; }

body.template-color-16 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F5A623; }

body.template-color-16 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F5A623; }

body.template-color-16 .team.team__style--2 .thumb .overlay {
  background-color: #F5A623; }
  body.template-color-16 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F5A623; }

body.template-color-16 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-16 .team.team__style--3 .thumb .overlay {
  background-color: #F5A623; }
  body.template-color-16 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F5A623; }

body.template-color-16 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-16 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F5A623; }
  body.template-color-16 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F5A623; }
  body.template-color-16 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F5A623; }
  body.template-color-16 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-16 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F5A623; }
  body.template-color-16 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F5A623;
    color: #fff; }

body.template-color-16 [data-overlay]:before {
  background-color: #F5A623; }

body.template-color-16 .team-heading .heading-right button i {
  color: #F5A623; }

body.template-color-16 .team-heading .heading-right button:hover {
  color: #F5A623 !important; }

body.template-color-16 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F5A623; }

body.template-color-16 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-16 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F5A623; }

body.template-color-16 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F5A623; }
  body.template-color-16 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F5A623; }
  body.template-color-16 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F5A623; }
  body.template-color-16 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F5A623;
    background-color: #F5A623; }

body.template-color-16 .brook__toolbar .inner a:hover {
  color: #F5A623; }

body.template-color-16 .blog-grid .post-inner h5.heading:hover {
  color: #F5A623; }
  body.template-color-16 .blog-grid .post-inner h5.heading:hover a {
    color: #F5A623; }

body.template-color-16 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F5A623; }

body.template-color-16 .theme-color {
  color: #F5A623; }

body.template-color-16 .bg_color--2 {
  background: #F5A623; }

body.template-color-16 a.brook-btn.bk-btn-theme,
body.template-color-16 button.brook-btn.bk-btn-theme {
  background: #F5A623;
  color: #fff; }

body.template-color-16 .btn-essential:hover {
  background: #F5A623 !important;
  border-color: #F5A623 !important;
  color: #fff; }

body.template-color-16 .red-border {
  border-color: #F5A623 !important; }

body.template-color-16 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F5A623;
  color: #fff; }

body.template-color-16 .single-svg-icon-box .svg-icon svg * {
  stroke: #F5A623 !important; }

body.template-color-16 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-16 .menu-price {
  color: #F5A623; }

body.template-color-16 a.moredetails-btn:hover {
  color: #F5A623; }

body.template-color-16 a.moredetails-btn i {
  color: #F5A623; }

body.template-color-16 .messonry-button button.is-checked span.filter-text {
  color: #F5A623; }

body.template-color-16 .messonry-button button:hover span.filter-text {
  color: #F5A623; }

body.template-color-16 .messonry-button button span.filter-counter {
  background-color: #F5A623; }
  body.template-color-16 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F5A623;
    border-top-color: #F5A623; }

body.template-color-16 .messonry-button button:hover span.filter-text {
  color: #F5A623; }

body.template-color-16 .messonry-button button.is-checked span.filter-text {
  color: #F5A623; }

body.template-color-16 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F5A623; }

body.template-color-16 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F5A623; }

body.template-color-16 .testimonial_style--1 .content .testimonial-quote span {
  color: #F5A623; }

body.template-color-16 .testimonial_style--1:hover {
  background: #F5A623; }
  body.template-color-16 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-16 .contact-form.contact-form--4 .input-box button {
  color: #F5A623; }
  body.template-color-16 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F5A623; }

body.template-color-16 .contact-form.contact-form--3 .input-box button {
  color: #F5A623; }

body.template-color-16 .animated-dot {
  background-color: #F5A623; }
  body.template-color-16 .animated-dot .middle-dot {
    background-color: #F5A623; }
  body.template-color-16 .animated-dot .signal {
    background-color: #F5A623; }
  body.template-color-16 .animated-dot .signal2 {
    background-color: #F5A623; }

body.template-color-16 .progress-charts .progress .progress-bar {
  background-color: #F5A623; }

body.template-color-16 .brook-counter .icon i {
  color: #F5A623; }

body.template-color-16 .social-share a:hover {
  color: #F5A623; }

body.template-color-16 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F5A623; }

body.template-color-16 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F5A623; }

body.template-color-16 .video-btn a.video-icon,
body.template-color-16 .video-btn a .video-icon {
  background: #F5A623; }

body.template-color-16 .video-btn-2 .icon-gradient::before {
  background-color: #F5A623;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F5A623 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F5A623 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F5A623 100%); }

body.template-color-16 .video-btn-2 .icon-gradient::after {
  background-color: #F5A623;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F5A623 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F5A623 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F5A623 100%); }

body.template-color-16 .single-motive-speach .icon i {
  color: #F5A623; }

body.template-color-16 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F5A623; }
  body.template-color-16 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F5A623; }

body.template-color-16 .contact-form input:focus,
body.template-color-16 .contact-form textarea:focus {
  border-color: #F5A623; }

body.template-color-16 .contact-form input[type="submit"],
body.template-color-16 .contact-form textarea[type="submit"] {
  border-color: #F5A623;
  background-color: #F5A623; }

body.template-color-16 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F5A623;
  color: #fff;
  border-color: #F5A623; }

body.template-color-16 .gallery .hover-overlay {
  background-color: #F5A623; }

body.template-color-16 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F5A623; }

body.template-color-16 .blog-grid .post-content::before {
  background-color: #F5A623; }

body.template-color-16 .blog-grid-no-flex .post-quote::before {
  color: #F5A623; }

body.template-color-16 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F5A623; }
  body.template-color-16 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F5A623; }

body.template-color-16 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F5A623; }

body.template-color-16 .bl-sidebar.search button:hover {
  background-color: #F5A623; }

body.template-color-16 .bl-sidebar.search button span {
  color: #F5A623; }

body.template-color-16 .bl-sidebar.category .category-list li a:hover {
  color: #F5A623; }

body.template-color-16 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F5A623; }

body.template-color-16 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F5A623; }

body.template-color-16 .icon-box .inner .icon {
  color: #F5A623; }

body.template-color-16 .basic-thick-line-theme-4 {
  border-top: 4px solid #F5A623; }

body.template-color-16 .procing-box .header .feature-mark {
  background-color: #F5A623; }

body.template-color-16 a.brook-btn.bk-btn-dark:hover {
  background: #F5A623; }

body.template-color-16 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F5A623; }

body.template-color-16 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F5A623; }

body.template-color-16 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F5A623 !important; }

body.template-color-16 .basic-modern-dots .dot {
  background-color: #F5A623; }

body.template-color-16 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F5A623; }

body.template-color-16 a.brook-btn.bk-btn-theme-border,
body.template-color-16 button.brook-btn.bk-btn-theme-border {
  border-color: #F5A623;
  color: #F5A623; }
  body.template-color-16 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-16 button.brook-btn.bk-btn-theme-border:hover {
    background: #F5A623;
    color: #ffffff; }

body.template-color-16 .brook-quote::before {
  color: #F5A623; }

body.template-color-16 .bk-text-box.bg-theme-color {
  background: #F5A623; }

body.template-color-16 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F5A623; }

body.template-color-16 .view-more-btn a span.btn-arrow {
  color: #F5A623; }

body.template-color-16 #fp-nav ul li a.active {
  background-color: #F5A623; }

body.template-color-17 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #B013FE; }

body.template-color-17 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #B013FE; }

body.template-color-17 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #B013FE; }

body.template-color-17 .faq-content h2 {
  color: #B013FE; }

body.template-color-17 .brook-section-title-corona h2 {
  color: #B013FE; }

body.template-color-17 .single-contagion .contagion-content h3 {
  color: #B013FE; }

body.template-color-17 .single-sympotom .sympotom-content h3 {
  color: #B013FE; }

body.template-color-17 .single-prevination .prevination-content h3 {
  color: #B013FE; }

body.template-color-17 .service.service--1.theme-color {
  background: #B013FE; }

body.template-color-17 .blog-grid-minimal.outline.bg-transparant {
  border-color: #B013FE; }
  body.template-color-17 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #B013FE; }

body.template-color-17 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-17 .single-svg-icon-box.business-icon-box:hover {
  background: #B013FE; }

body.template-color-17 .product_review .description_nav a.active, body.template-color-17 .product_review .description_nav a:hover {
  border-bottom: 3px solid #B013FE; }

body.template-color-17 .single-product-details-side .product-action a {
  border-color: #B013FE;
  background-color: #B013FE; }

body.template-color-17 .cart-table td.pro-subtotal span {
  color: #B013FE; }

body.template-color-17 .cart-table td.pro-addtocart button {
  border: 1px solid #B013FE;
  background-color: #B013FE; }

body.template-color-17 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #B013FE; }

body.template-color-17 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #B013FE; }

body.template-color-17 .slider-business .video-btn a .video-icon.secondary-color {
  background: #B013FE !important; }

body.template-color-17 a.brook-btn.bk-btn-yellow, body.template-color-17 button.brook-btn.bk-btn-yellow {
  background: #B013FE;
  color: #fff !important; }

body.template-color-17 a.bk-btn.theme-btn, body.template-color-17 button.bk-btn.theme-btn {
  background-color: #B013FE; }

body.template-color-17 a.brook-btn.bk-btn-red, body.template-color-17 button.brook-btn.bk-btn-red {
  background: #B013FE; }

body.template-color-17 .section-title h3 {
  color: #B013FE; }

body.template-color-17 .service.service--1:hover::before {
  border-color: #B013FE; }

body.template-color-17 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-17 .service.service--1 .icons i {
    color: #B013FE;
    font-size: 80px; }

body.template-color-17 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #B013FE;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-17 .service.service--1 .content a.service-btn:hover {
  color: #B013FE; }
  body.template-color-17 .service.service--1 .content a.service-btn:hover i {
    color: #B013FE; }

body.template-color-17 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-17 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-17 .service.service--3 .content h3 a:hover {
  color: #B013FE; }

body.template-color-17 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-17 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-17 .title-separator.color-red::after, body.template-color-17 .title-separator.color-blue::after {
    background: #B013FE; }

body.template-color-17 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-17 .bk-gradation .item-grid .dot-wrap .dot {
    background: #B013FE; }
  body.template-color-17 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #B013FE; }
  body.template-color-17 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #B013FE; }
  body.template-color-17 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #B013FE; }

body.template-color-17 .post-nav-lisnt .nav-item a:hover {
  background: #B013FE; }

body.template-color-17 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #B013FE; }

body.template-color-17 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #B013FE; }

body.template-color-17 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #B013FE; }

body.template-color-17 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #B013FE; }

body.template-color-17 .team.team__style--2 .thumb .overlay {
  background-color: #B013FE; }
  body.template-color-17 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #B013FE; }

body.template-color-17 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-17 .team.team__style--3 .thumb .overlay {
  background-color: #B013FE; }
  body.template-color-17 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #B013FE; }

body.template-color-17 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-17 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #B013FE; }
  body.template-color-17 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #B013FE; }
  body.template-color-17 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #B013FE; }
  body.template-color-17 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-17 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #B013FE; }
  body.template-color-17 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #B013FE;
    color: #fff; }

body.template-color-17 [data-overlay]:before {
  background-color: #B013FE; }

body.template-color-17 .team-heading .heading-right button i {
  color: #B013FE; }

body.template-color-17 .team-heading .heading-right button:hover {
  color: #B013FE !important; }

body.template-color-17 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #B013FE; }

body.template-color-17 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-17 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #B013FE; }

body.template-color-17 .mini-cart .shopping-cart {
  border-bottom: 2px solid #B013FE; }
  body.template-color-17 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #B013FE; }
  body.template-color-17 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #B013FE; }
  body.template-color-17 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #B013FE;
    background-color: #B013FE; }

body.template-color-17 .brook__toolbar .inner a:hover {
  color: #B013FE; }

body.template-color-17 .blog-grid .post-inner h5.heading:hover {
  color: #B013FE; }
  body.template-color-17 .blog-grid .post-inner h5.heading:hover a {
    color: #B013FE; }

body.template-color-17 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #B013FE; }

body.template-color-17 .theme-color {
  color: #B013FE; }

body.template-color-17 .bg_color--2 {
  background: #B013FE; }

body.template-color-17 a.brook-btn.bk-btn-theme,
body.template-color-17 button.brook-btn.bk-btn-theme {
  background: #B013FE;
  color: #fff; }

body.template-color-17 .btn-essential:hover {
  background: #B013FE !important;
  border-color: #B013FE !important;
  color: #fff; }

body.template-color-17 .red-border {
  border-color: #B013FE !important; }

body.template-color-17 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #B013FE;
  color: #fff; }

body.template-color-17 .single-svg-icon-box .svg-icon svg * {
  stroke: #B013FE !important; }

body.template-color-17 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-17 .menu-price {
  color: #B013FE; }

body.template-color-17 a.moredetails-btn:hover {
  color: #B013FE; }

body.template-color-17 a.moredetails-btn i {
  color: #B013FE; }

body.template-color-17 .messonry-button button.is-checked span.filter-text {
  color: #B013FE; }

body.template-color-17 .messonry-button button:hover span.filter-text {
  color: #B013FE; }

body.template-color-17 .messonry-button button span.filter-counter {
  background-color: #B013FE; }
  body.template-color-17 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #B013FE;
    border-top-color: #B013FE; }

body.template-color-17 .messonry-button button:hover span.filter-text {
  color: #B013FE; }

body.template-color-17 .messonry-button button.is-checked span.filter-text {
  color: #B013FE; }

body.template-color-17 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #B013FE; }

body.template-color-17 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #B013FE; }

body.template-color-17 .testimonial_style--1 .content .testimonial-quote span {
  color: #B013FE; }

body.template-color-17 .testimonial_style--1:hover {
  background: #B013FE; }
  body.template-color-17 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-17 .contact-form.contact-form--4 .input-box button {
  color: #B013FE; }
  body.template-color-17 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #B013FE; }

body.template-color-17 .contact-form.contact-form--3 .input-box button {
  color: #B013FE; }

body.template-color-17 .animated-dot {
  background-color: #B013FE; }
  body.template-color-17 .animated-dot .middle-dot {
    background-color: #B013FE; }
  body.template-color-17 .animated-dot .signal {
    background-color: #B013FE; }
  body.template-color-17 .animated-dot .signal2 {
    background-color: #B013FE; }

body.template-color-17 .progress-charts .progress .progress-bar {
  background-color: #B013FE; }

body.template-color-17 .brook-counter .icon i {
  color: #B013FE; }

body.template-color-17 .social-share a:hover {
  color: #B013FE; }

body.template-color-17 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #B013FE; }

body.template-color-17 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #B013FE; }

body.template-color-17 .video-btn a.video-icon,
body.template-color-17 .video-btn a .video-icon {
  background: #B013FE; }

body.template-color-17 .video-btn-2 .icon-gradient::before {
  background-color: #B013FE;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #B013FE 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #B013FE 100%);
  background-image: linear-gradient(-133deg, #899664 0, #B013FE 100%); }

body.template-color-17 .video-btn-2 .icon-gradient::after {
  background-color: #B013FE;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #B013FE 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #B013FE 100%);
  background-image: linear-gradient(-133deg, #899664 0, #B013FE 100%); }

body.template-color-17 .single-motive-speach .icon i {
  color: #B013FE; }

body.template-color-17 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #B013FE; }
  body.template-color-17 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #B013FE; }

body.template-color-17 .contact-form input:focus,
body.template-color-17 .contact-form textarea:focus {
  border-color: #B013FE; }

body.template-color-17 .contact-form input[type="submit"],
body.template-color-17 .contact-form textarea[type="submit"] {
  border-color: #B013FE;
  background-color: #B013FE; }

body.template-color-17 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #B013FE;
  color: #fff;
  border-color: #B013FE; }

body.template-color-17 .gallery .hover-overlay {
  background-color: #B013FE; }

body.template-color-17 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #B013FE; }

body.template-color-17 .blog-grid .post-content::before {
  background-color: #B013FE; }

body.template-color-17 .blog-grid-no-flex .post-quote::before {
  color: #B013FE; }

body.template-color-17 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #B013FE; }
  body.template-color-17 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #B013FE; }

body.template-color-17 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #B013FE; }

body.template-color-17 .bl-sidebar.search button:hover {
  background-color: #B013FE; }

body.template-color-17 .bl-sidebar.search button span {
  color: #B013FE; }

body.template-color-17 .bl-sidebar.category .category-list li a:hover {
  color: #B013FE; }

body.template-color-17 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #B013FE; }

body.template-color-17 .social-icon.icon-solid-rounded li a:hover {
  background-color: #B013FE; }

body.template-color-17 .icon-box .inner .icon {
  color: #B013FE; }

body.template-color-17 .basic-thick-line-theme-4 {
  border-top: 4px solid #B013FE; }

body.template-color-17 .procing-box .header .feature-mark {
  background-color: #B013FE; }

body.template-color-17 a.brook-btn.bk-btn-dark:hover {
  background: #B013FE; }

body.template-color-17 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #B013FE; }

body.template-color-17 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #B013FE; }

body.template-color-17 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #B013FE !important; }

body.template-color-17 .basic-modern-dots .dot {
  background-color: #B013FE; }

body.template-color-17 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #B013FE; }

body.template-color-17 a.brook-btn.bk-btn-theme-border,
body.template-color-17 button.brook-btn.bk-btn-theme-border {
  border-color: #B013FE;
  color: #B013FE; }
  body.template-color-17 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-17 button.brook-btn.bk-btn-theme-border:hover {
    background: #B013FE;
    color: #ffffff; }

body.template-color-17 .brook-quote::before {
  color: #B013FE; }

body.template-color-17 .bk-text-box.bg-theme-color {
  background: #B013FE; }

body.template-color-17 .bk-list--2 .list-header.with-number--theme .marker {
  color: #B013FE; }

body.template-color-17 .view-more-btn a span.btn-arrow {
  color: #B013FE; }

body.template-color-17 #fp-nav ul li a.active {
  background-color: #B013FE; }

body.template-color-18 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #899664; }

body.template-color-18 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #899664; }

body.template-color-18 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #899664; }

body.template-color-18 .faq-content h2 {
  color: #899664; }

body.template-color-18 .brook-section-title-corona h2 {
  color: #899664; }

body.template-color-18 .single-contagion .contagion-content h3 {
  color: #899664; }

body.template-color-18 .single-sympotom .sympotom-content h3 {
  color: #899664; }

body.template-color-18 .single-prevination .prevination-content h3 {
  color: #899664; }

body.template-color-18 .service.service--1.theme-color {
  background: #899664; }

body.template-color-18 .blog-grid-minimal.outline.bg-transparant {
  border-color: #899664; }
  body.template-color-18 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #899664; }

body.template-color-18 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-18 .single-svg-icon-box.business-icon-box:hover {
  background: #899664; }

body.template-color-18 .product_review .description_nav a.active, body.template-color-18 .product_review .description_nav a:hover {
  border-bottom: 3px solid #899664; }

body.template-color-18 .single-product-details-side .product-action a {
  border-color: #899664;
  background-color: #899664; }

body.template-color-18 .cart-table td.pro-subtotal span {
  color: #899664; }

body.template-color-18 .cart-table td.pro-addtocart button {
  border: 1px solid #899664;
  background-color: #899664; }

body.template-color-18 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #899664; }

body.template-color-18 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #899664; }

body.template-color-18 .slider-business .video-btn a .video-icon.secondary-color {
  background: #899664 !important; }

body.template-color-18 a.brook-btn.bk-btn-yellow, body.template-color-18 button.brook-btn.bk-btn-yellow {
  background: #899664;
  color: #fff !important; }

body.template-color-18 a.bk-btn.theme-btn, body.template-color-18 button.bk-btn.theme-btn {
  background-color: #899664; }

body.template-color-18 a.brook-btn.bk-btn-red, body.template-color-18 button.brook-btn.bk-btn-red {
  background: #899664; }

body.template-color-18 .section-title h3 {
  color: #899664; }

body.template-color-18 .service.service--1:hover::before {
  border-color: #899664; }

body.template-color-18 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-18 .service.service--1 .icons i {
    color: #899664;
    font-size: 80px; }

body.template-color-18 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #899664;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-18 .service.service--1 .content a.service-btn:hover {
  color: #899664; }
  body.template-color-18 .service.service--1 .content a.service-btn:hover i {
    color: #899664; }

body.template-color-18 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-18 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-18 .service.service--3 .content h3 a:hover {
  color: #899664; }

body.template-color-18 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-18 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-18 .title-separator.color-red::after, body.template-color-18 .title-separator.color-blue::after {
    background: #899664; }

body.template-color-18 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-18 .bk-gradation .item-grid .dot-wrap .dot {
    background: #899664; }
  body.template-color-18 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #899664; }
  body.template-color-18 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #899664; }
  body.template-color-18 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #899664; }

body.template-color-18 .post-nav-lisnt .nav-item a:hover {
  background: #899664; }

body.template-color-18 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #899664; }

body.template-color-18 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #899664; }

body.template-color-18 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #899664; }

body.template-color-18 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #899664; }

body.template-color-18 .team.team__style--2 .thumb .overlay {
  background-color: #899664; }
  body.template-color-18 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #899664; }

body.template-color-18 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-18 .team.team__style--3 .thumb .overlay {
  background-color: #899664; }
  body.template-color-18 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #899664; }

body.template-color-18 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-18 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #899664; }
  body.template-color-18 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #899664; }
  body.template-color-18 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #899664; }
  body.template-color-18 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-18 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #899664; }
  body.template-color-18 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #899664;
    color: #fff; }

body.template-color-18 [data-overlay]:before {
  background-color: #899664; }

body.template-color-18 .team-heading .heading-right button i {
  color: #899664; }

body.template-color-18 .team-heading .heading-right button:hover {
  color: #899664 !important; }

body.template-color-18 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #899664; }

body.template-color-18 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-18 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #899664; }

body.template-color-18 .mini-cart .shopping-cart {
  border-bottom: 2px solid #899664; }
  body.template-color-18 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #899664; }
  body.template-color-18 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #899664; }
  body.template-color-18 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #899664;
    background-color: #899664; }

body.template-color-18 .brook__toolbar .inner a:hover {
  color: #899664; }

body.template-color-18 .blog-grid .post-inner h5.heading:hover {
  color: #899664; }
  body.template-color-18 .blog-grid .post-inner h5.heading:hover a {
    color: #899664; }

body.template-color-18 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #899664; }

body.template-color-18 .theme-color {
  color: #899664; }

body.template-color-18 .bg_color--2 {
  background: #899664; }

body.template-color-18 a.brook-btn.bk-btn-theme,
body.template-color-18 button.brook-btn.bk-btn-theme {
  background: #899664;
  color: #fff; }

body.template-color-18 .btn-essential:hover {
  background: #899664 !important;
  border-color: #899664 !important;
  color: #fff; }

body.template-color-18 .red-border {
  border-color: #899664 !important; }

body.template-color-18 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #899664;
  color: #fff; }

body.template-color-18 .single-svg-icon-box .svg-icon svg * {
  stroke: #899664 !important; }

body.template-color-18 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-18 .menu-price {
  color: #899664; }

body.template-color-18 a.moredetails-btn:hover {
  color: #899664; }

body.template-color-18 a.moredetails-btn i {
  color: #899664; }

body.template-color-18 .messonry-button button.is-checked span.filter-text {
  color: #899664; }

body.template-color-18 .messonry-button button:hover span.filter-text {
  color: #899664; }

body.template-color-18 .messonry-button button span.filter-counter {
  background-color: #899664; }
  body.template-color-18 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #899664;
    border-top-color: #899664; }

body.template-color-18 .messonry-button button:hover span.filter-text {
  color: #899664; }

body.template-color-18 .messonry-button button.is-checked span.filter-text {
  color: #899664; }

body.template-color-18 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #899664; }

body.template-color-18 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #899664; }

body.template-color-18 .testimonial_style--1 .content .testimonial-quote span {
  color: #899664; }

body.template-color-18 .testimonial_style--1:hover {
  background: #899664; }
  body.template-color-18 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-18 .contact-form.contact-form--4 .input-box button {
  color: #899664; }
  body.template-color-18 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #899664; }

body.template-color-18 .contact-form.contact-form--3 .input-box button {
  color: #899664; }

body.template-color-18 .animated-dot {
  background-color: #899664; }
  body.template-color-18 .animated-dot .middle-dot {
    background-color: #899664; }
  body.template-color-18 .animated-dot .signal {
    background-color: #899664; }
  body.template-color-18 .animated-dot .signal2 {
    background-color: #899664; }

body.template-color-18 .progress-charts .progress .progress-bar {
  background-color: #899664; }

body.template-color-18 .brook-counter .icon i {
  color: #899664; }

body.template-color-18 .social-share a:hover {
  color: #899664; }

body.template-color-18 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #899664; }

body.template-color-18 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #899664; }

body.template-color-18 .video-btn a.video-icon,
body.template-color-18 .video-btn a .video-icon {
  background: #899664; }

body.template-color-18 .video-btn-2 .icon-gradient::before {
  background-color: #899664;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #899664 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #899664 100%);
  background-image: linear-gradient(-133deg, #899664 0, #899664 100%); }

body.template-color-18 .video-btn-2 .icon-gradient::after {
  background-color: #899664;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #899664 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #899664 100%);
  background-image: linear-gradient(-133deg, #899664 0, #899664 100%); }

body.template-color-18 .single-motive-speach .icon i {
  color: #899664; }

body.template-color-18 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #899664; }
  body.template-color-18 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #899664; }

body.template-color-18 .contact-form input:focus,
body.template-color-18 .contact-form textarea:focus {
  border-color: #899664; }

body.template-color-18 .contact-form input[type="submit"],
body.template-color-18 .contact-form textarea[type="submit"] {
  border-color: #899664;
  background-color: #899664; }

body.template-color-18 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #899664;
  color: #fff;
  border-color: #899664; }

body.template-color-18 .gallery .hover-overlay {
  background-color: #899664; }

body.template-color-18 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #899664; }

body.template-color-18 .blog-grid .post-content::before {
  background-color: #899664; }

body.template-color-18 .blog-grid-no-flex .post-quote::before {
  color: #899664; }

body.template-color-18 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #899664; }
  body.template-color-18 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #899664; }

body.template-color-18 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #899664; }

body.template-color-18 .bl-sidebar.search button:hover {
  background-color: #899664; }

body.template-color-18 .bl-sidebar.search button span {
  color: #899664; }

body.template-color-18 .bl-sidebar.category .category-list li a:hover {
  color: #899664; }

body.template-color-18 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #899664; }

body.template-color-18 .social-icon.icon-solid-rounded li a:hover {
  background-color: #899664; }

body.template-color-18 .icon-box .inner .icon {
  color: #899664; }

body.template-color-18 .basic-thick-line-theme-4 {
  border-top: 4px solid #899664; }

body.template-color-18 .procing-box .header .feature-mark {
  background-color: #899664; }

body.template-color-18 a.brook-btn.bk-btn-dark:hover {
  background: #899664; }

body.template-color-18 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #899664; }

body.template-color-18 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #899664; }

body.template-color-18 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #899664 !important; }

body.template-color-18 .basic-modern-dots .dot {
  background-color: #899664; }

body.template-color-18 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #899664; }

body.template-color-18 a.brook-btn.bk-btn-theme-border,
body.template-color-18 button.brook-btn.bk-btn-theme-border {
  border-color: #899664;
  color: #899664; }
  body.template-color-18 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-18 button.brook-btn.bk-btn-theme-border:hover {
    background: #899664;
    color: #ffffff; }

body.template-color-18 .brook-quote::before {
  color: #899664; }

body.template-color-18 .bk-text-box.bg-theme-color {
  background: #899664; }

body.template-color-18 .bk-list--2 .list-header.with-number--theme .marker {
  color: #899664; }

body.template-color-18 .view-more-btn a span.btn-arrow {
  color: #899664; }

body.template-color-18 #fp-nav ul li a.active {
  background-color: #899664; }

body.template-color-19 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F12C6E; }

body.template-color-19 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F12C6E; }

body.template-color-19 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F12C6E; }

body.template-color-19 .faq-content h2 {
  color: #F12C6E; }

body.template-color-19 .brook-section-title-corona h2 {
  color: #F12C6E; }

body.template-color-19 .single-contagion .contagion-content h3 {
  color: #F12C6E; }

body.template-color-19 .single-sympotom .sympotom-content h3 {
  color: #F12C6E; }

body.template-color-19 .single-prevination .prevination-content h3 {
  color: #F12C6E; }

body.template-color-19 .service.service--1.theme-color {
  background: #F12C6E; }

body.template-color-19 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F12C6E; }
  body.template-color-19 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F12C6E; }

body.template-color-19 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-19 .single-svg-icon-box.business-icon-box:hover {
  background: #F12C6E; }

body.template-color-19 .product_review .description_nav a.active, body.template-color-19 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F12C6E; }

body.template-color-19 .single-product-details-side .product-action a {
  border-color: #F12C6E;
  background-color: #F12C6E; }

body.template-color-19 .cart-table td.pro-subtotal span {
  color: #F12C6E; }

body.template-color-19 .cart-table td.pro-addtocart button {
  border: 1px solid #F12C6E;
  background-color: #F12C6E; }

body.template-color-19 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F12C6E; }

body.template-color-19 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F12C6E; }

body.template-color-19 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F12C6E !important; }

body.template-color-19 a.brook-btn.bk-btn-yellow, body.template-color-19 button.brook-btn.bk-btn-yellow {
  background: #F12C6E;
  color: #fff !important; }

body.template-color-19 a.bk-btn.theme-btn, body.template-color-19 button.bk-btn.theme-btn {
  background-color: #F12C6E; }

body.template-color-19 a.brook-btn.bk-btn-red, body.template-color-19 button.brook-btn.bk-btn-red {
  background: #F12C6E; }

body.template-color-19 .section-title h3 {
  color: #F12C6E; }

body.template-color-19 .service.service--1:hover::before {
  border-color: #F12C6E; }

body.template-color-19 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-19 .service.service--1 .icons i {
    color: #F12C6E;
    font-size: 80px; }

body.template-color-19 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F12C6E;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-19 .service.service--1 .content a.service-btn:hover {
  color: #F12C6E; }
  body.template-color-19 .service.service--1 .content a.service-btn:hover i {
    color: #F12C6E; }

body.template-color-19 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-19 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-19 .service.service--3 .content h3 a:hover {
  color: #F12C6E; }

body.template-color-19 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-19 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-19 .title-separator.color-red::after, body.template-color-19 .title-separator.color-blue::after {
    background: #F12C6E; }

body.template-color-19 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-19 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F12C6E; }
  body.template-color-19 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F12C6E; }
  body.template-color-19 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F12C6E; }
  body.template-color-19 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F12C6E; }

body.template-color-19 .post-nav-lisnt .nav-item a:hover {
  background: #F12C6E; }

body.template-color-19 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F12C6E; }

body.template-color-19 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F12C6E; }

body.template-color-19 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F12C6E; }

body.template-color-19 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F12C6E; }

body.template-color-19 .team.team__style--2 .thumb .overlay {
  background-color: #F12C6E; }
  body.template-color-19 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F12C6E; }

body.template-color-19 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-19 .team.team__style--3 .thumb .overlay {
  background-color: #F12C6E; }
  body.template-color-19 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F12C6E; }

body.template-color-19 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-19 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F12C6E; }
  body.template-color-19 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F12C6E; }
  body.template-color-19 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F12C6E; }
  body.template-color-19 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-19 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F12C6E; }
  body.template-color-19 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F12C6E;
    color: #fff; }

body.template-color-19 [data-overlay]:before {
  background-color: #F12C6E; }

body.template-color-19 .team-heading .heading-right button i {
  color: #F12C6E; }

body.template-color-19 .team-heading .heading-right button:hover {
  color: #F12C6E !important; }

body.template-color-19 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F12C6E; }

body.template-color-19 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-19 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F12C6E; }

body.template-color-19 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F12C6E; }
  body.template-color-19 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F12C6E; }
  body.template-color-19 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F12C6E; }
  body.template-color-19 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F12C6E;
    background-color: #F12C6E; }

body.template-color-19 .brook__toolbar .inner a:hover {
  color: #F12C6E; }

body.template-color-19 .blog-grid .post-inner h5.heading:hover {
  color: #F12C6E; }
  body.template-color-19 .blog-grid .post-inner h5.heading:hover a {
    color: #F12C6E; }

body.template-color-19 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F12C6E; }

body.template-color-19 .theme-color {
  color: #F12C6E; }

body.template-color-19 .bg_color--2 {
  background: #F12C6E; }

body.template-color-19 a.brook-btn.bk-btn-theme,
body.template-color-19 button.brook-btn.bk-btn-theme {
  background: #F12C6E;
  color: #fff; }

body.template-color-19 .btn-essential:hover {
  background: #F12C6E !important;
  border-color: #F12C6E !important;
  color: #fff; }

body.template-color-19 .red-border {
  border-color: #F12C6E !important; }

body.template-color-19 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F12C6E;
  color: #fff; }

body.template-color-19 .single-svg-icon-box .svg-icon svg * {
  stroke: #F12C6E !important; }

body.template-color-19 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-19 .menu-price {
  color: #F12C6E; }

body.template-color-19 a.moredetails-btn:hover {
  color: #F12C6E; }

body.template-color-19 a.moredetails-btn i {
  color: #F12C6E; }

body.template-color-19 .messonry-button button.is-checked span.filter-text {
  color: #F12C6E; }

body.template-color-19 .messonry-button button:hover span.filter-text {
  color: #F12C6E; }

body.template-color-19 .messonry-button button span.filter-counter {
  background-color: #F12C6E; }
  body.template-color-19 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F12C6E;
    border-top-color: #F12C6E; }

body.template-color-19 .messonry-button button:hover span.filter-text {
  color: #F12C6E; }

body.template-color-19 .messonry-button button.is-checked span.filter-text {
  color: #F12C6E; }

body.template-color-19 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F12C6E; }

body.template-color-19 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F12C6E; }

body.template-color-19 .testimonial_style--1 .content .testimonial-quote span {
  color: #F12C6E; }

body.template-color-19 .testimonial_style--1:hover {
  background: #F12C6E; }
  body.template-color-19 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-19 .contact-form.contact-form--4 .input-box button {
  color: #F12C6E; }
  body.template-color-19 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F12C6E; }

body.template-color-19 .contact-form.contact-form--3 .input-box button {
  color: #F12C6E; }

body.template-color-19 .animated-dot {
  background-color: #F12C6E; }
  body.template-color-19 .animated-dot .middle-dot {
    background-color: #F12C6E; }
  body.template-color-19 .animated-dot .signal {
    background-color: #F12C6E; }
  body.template-color-19 .animated-dot .signal2 {
    background-color: #F12C6E; }

body.template-color-19 .progress-charts .progress .progress-bar {
  background-color: #F12C6E; }

body.template-color-19 .brook-counter .icon i {
  color: #F12C6E; }

body.template-color-19 .social-share a:hover {
  color: #F12C6E; }

body.template-color-19 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F12C6E; }

body.template-color-19 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F12C6E; }

body.template-color-19 .video-btn a.video-icon,
body.template-color-19 .video-btn a .video-icon {
  background: #F12C6E; }

body.template-color-19 .video-btn-2 .icon-gradient::before {
  background-color: #F12C6E;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F12C6E 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F12C6E 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F12C6E 100%); }

body.template-color-19 .video-btn-2 .icon-gradient::after {
  background-color: #F12C6E;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F12C6E 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F12C6E 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F12C6E 100%); }

body.template-color-19 .single-motive-speach .icon i {
  color: #F12C6E; }

body.template-color-19 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F12C6E; }
  body.template-color-19 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F12C6E; }

body.template-color-19 .contact-form input:focus,
body.template-color-19 .contact-form textarea:focus {
  border-color: #F12C6E; }

body.template-color-19 .contact-form input[type="submit"],
body.template-color-19 .contact-form textarea[type="submit"] {
  border-color: #F12C6E;
  background-color: #F12C6E; }

body.template-color-19 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F12C6E;
  color: #fff;
  border-color: #F12C6E; }

body.template-color-19 .gallery .hover-overlay {
  background-color: #F12C6E; }

body.template-color-19 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F12C6E; }

body.template-color-19 .blog-grid .post-content::before {
  background-color: #F12C6E; }

body.template-color-19 .blog-grid-no-flex .post-quote::before {
  color: #F12C6E; }

body.template-color-19 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F12C6E; }
  body.template-color-19 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F12C6E; }

body.template-color-19 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F12C6E; }

body.template-color-19 .bl-sidebar.search button:hover {
  background-color: #F12C6E; }

body.template-color-19 .bl-sidebar.search button span {
  color: #F12C6E; }

body.template-color-19 .bl-sidebar.category .category-list li a:hover {
  color: #F12C6E; }

body.template-color-19 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F12C6E; }

body.template-color-19 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F12C6E; }

body.template-color-19 .icon-box .inner .icon {
  color: #F12C6E; }

body.template-color-19 .basic-thick-line-theme-4 {
  border-top: 4px solid #F12C6E; }

body.template-color-19 .procing-box .header .feature-mark {
  background-color: #F12C6E; }

body.template-color-19 a.brook-btn.bk-btn-dark:hover {
  background: #F12C6E; }

body.template-color-19 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F12C6E; }

body.template-color-19 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F12C6E; }

body.template-color-19 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F12C6E !important; }

body.template-color-19 .basic-modern-dots .dot {
  background-color: #F12C6E; }

body.template-color-19 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F12C6E; }

body.template-color-19 a.brook-btn.bk-btn-theme-border,
body.template-color-19 button.brook-btn.bk-btn-theme-border {
  border-color: #F12C6E;
  color: #F12C6E; }
  body.template-color-19 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-19 button.brook-btn.bk-btn-theme-border:hover {
    background: #F12C6E;
    color: #ffffff; }

body.template-color-19 .brook-quote::before {
  color: #F12C6E; }

body.template-color-19 .bk-text-box.bg-theme-color {
  background: #F12C6E; }

body.template-color-19 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F12C6E; }

body.template-color-19 .view-more-btn a span.btn-arrow {
  color: #F12C6E; }

body.template-color-19 #fp-nav ul li a.active {
  background-color: #F12C6E; }

body.template-color-20 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #CE8F4F; }

body.template-color-20 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #CE8F4F; }

body.template-color-20 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #CE8F4F; }

body.template-color-20 .faq-content h2 {
  color: #CE8F4F; }

body.template-color-20 .brook-section-title-corona h2 {
  color: #CE8F4F; }

body.template-color-20 .single-contagion .contagion-content h3 {
  color: #CE8F4F; }

body.template-color-20 .single-sympotom .sympotom-content h3 {
  color: #CE8F4F; }

body.template-color-20 .single-prevination .prevination-content h3 {
  color: #CE8F4F; }

body.template-color-20 .service.service--1.theme-color {
  background: #CE8F4F; }

body.template-color-20 .blog-grid-minimal.outline.bg-transparant {
  border-color: #CE8F4F; }
  body.template-color-20 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #CE8F4F; }

body.template-color-20 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-20 .single-svg-icon-box.business-icon-box:hover {
  background: #CE8F4F; }

body.template-color-20 .product_review .description_nav a.active, body.template-color-20 .product_review .description_nav a:hover {
  border-bottom: 3px solid #CE8F4F; }

body.template-color-20 .single-product-details-side .product-action a {
  border-color: #CE8F4F;
  background-color: #CE8F4F; }

body.template-color-20 .cart-table td.pro-subtotal span {
  color: #CE8F4F; }

body.template-color-20 .cart-table td.pro-addtocart button {
  border: 1px solid #CE8F4F;
  background-color: #CE8F4F; }

body.template-color-20 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #CE8F4F; }

body.template-color-20 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #CE8F4F; }

body.template-color-20 .slider-business .video-btn a .video-icon.secondary-color {
  background: #CE8F4F !important; }

body.template-color-20 a.brook-btn.bk-btn-yellow, body.template-color-20 button.brook-btn.bk-btn-yellow {
  background: #CE8F4F;
  color: #fff !important; }

body.template-color-20 a.bk-btn.theme-btn, body.template-color-20 button.bk-btn.theme-btn {
  background-color: #CE8F4F; }

body.template-color-20 a.brook-btn.bk-btn-red, body.template-color-20 button.brook-btn.bk-btn-red {
  background: #CE8F4F; }

body.template-color-20 .section-title h3 {
  color: #CE8F4F; }

body.template-color-20 .service.service--1:hover::before {
  border-color: #CE8F4F; }

body.template-color-20 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-20 .service.service--1 .icons i {
    color: #CE8F4F;
    font-size: 80px; }

body.template-color-20 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #CE8F4F;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-20 .service.service--1 .content a.service-btn:hover {
  color: #CE8F4F; }
  body.template-color-20 .service.service--1 .content a.service-btn:hover i {
    color: #CE8F4F; }

body.template-color-20 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-20 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-20 .service.service--3 .content h3 a:hover {
  color: #CE8F4F; }

body.template-color-20 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-20 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-20 .title-separator.color-red::after, body.template-color-20 .title-separator.color-blue::after {
    background: #CE8F4F; }

body.template-color-20 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-20 .bk-gradation .item-grid .dot-wrap .dot {
    background: #CE8F4F; }
  body.template-color-20 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #CE8F4F; }
  body.template-color-20 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #CE8F4F; }
  body.template-color-20 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #CE8F4F; }

body.template-color-20 .post-nav-lisnt .nav-item a:hover {
  background: #CE8F4F; }

body.template-color-20 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #CE8F4F; }

body.template-color-20 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #CE8F4F; }

body.template-color-20 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #CE8F4F; }

body.template-color-20 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #CE8F4F; }

body.template-color-20 .team.team__style--2 .thumb .overlay {
  background-color: #CE8F4F; }
  body.template-color-20 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #CE8F4F; }

body.template-color-20 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-20 .team.team__style--3 .thumb .overlay {
  background-color: #CE8F4F; }
  body.template-color-20 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #CE8F4F; }

body.template-color-20 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-20 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #CE8F4F; }
  body.template-color-20 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #CE8F4F; }
  body.template-color-20 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #CE8F4F; }
  body.template-color-20 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-20 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #CE8F4F; }
  body.template-color-20 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #CE8F4F;
    color: #fff; }

body.template-color-20 [data-overlay]:before {
  background-color: #CE8F4F; }

body.template-color-20 .team-heading .heading-right button i {
  color: #CE8F4F; }

body.template-color-20 .team-heading .heading-right button:hover {
  color: #CE8F4F !important; }

body.template-color-20 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #CE8F4F; }

body.template-color-20 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-20 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #CE8F4F; }

body.template-color-20 .mini-cart .shopping-cart {
  border-bottom: 2px solid #CE8F4F; }
  body.template-color-20 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #CE8F4F; }
  body.template-color-20 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #CE8F4F; }
  body.template-color-20 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #CE8F4F;
    background-color: #CE8F4F; }

body.template-color-20 .brook__toolbar .inner a:hover {
  color: #CE8F4F; }

body.template-color-20 .blog-grid .post-inner h5.heading:hover {
  color: #CE8F4F; }
  body.template-color-20 .blog-grid .post-inner h5.heading:hover a {
    color: #CE8F4F; }

body.template-color-20 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #CE8F4F; }

body.template-color-20 .theme-color {
  color: #CE8F4F; }

body.template-color-20 .bg_color--2 {
  background: #CE8F4F; }

body.template-color-20 a.brook-btn.bk-btn-theme,
body.template-color-20 button.brook-btn.bk-btn-theme {
  background: #CE8F4F;
  color: #fff; }

body.template-color-20 .btn-essential:hover {
  background: #CE8F4F !important;
  border-color: #CE8F4F !important;
  color: #fff; }

body.template-color-20 .red-border {
  border-color: #CE8F4F !important; }

body.template-color-20 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #CE8F4F;
  color: #fff; }

body.template-color-20 .single-svg-icon-box .svg-icon svg * {
  stroke: #CE8F4F !important; }

body.template-color-20 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-20 .menu-price {
  color: #CE8F4F; }

body.template-color-20 a.moredetails-btn:hover {
  color: #CE8F4F; }

body.template-color-20 a.moredetails-btn i {
  color: #CE8F4F; }

body.template-color-20 .messonry-button button.is-checked span.filter-text {
  color: #CE8F4F; }

body.template-color-20 .messonry-button button:hover span.filter-text {
  color: #CE8F4F; }

body.template-color-20 .messonry-button button span.filter-counter {
  background-color: #CE8F4F; }
  body.template-color-20 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #CE8F4F;
    border-top-color: #CE8F4F; }

body.template-color-20 .messonry-button button:hover span.filter-text {
  color: #CE8F4F; }

body.template-color-20 .messonry-button button.is-checked span.filter-text {
  color: #CE8F4F; }

body.template-color-20 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #CE8F4F; }

body.template-color-20 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #CE8F4F; }

body.template-color-20 .testimonial_style--1 .content .testimonial-quote span {
  color: #CE8F4F; }

body.template-color-20 .testimonial_style--1:hover {
  background: #CE8F4F; }
  body.template-color-20 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-20 .contact-form.contact-form--4 .input-box button {
  color: #CE8F4F; }
  body.template-color-20 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #CE8F4F; }

body.template-color-20 .contact-form.contact-form--3 .input-box button {
  color: #CE8F4F; }

body.template-color-20 .animated-dot {
  background-color: #CE8F4F; }
  body.template-color-20 .animated-dot .middle-dot {
    background-color: #CE8F4F; }
  body.template-color-20 .animated-dot .signal {
    background-color: #CE8F4F; }
  body.template-color-20 .animated-dot .signal2 {
    background-color: #CE8F4F; }

body.template-color-20 .progress-charts .progress .progress-bar {
  background-color: #CE8F4F; }

body.template-color-20 .brook-counter .icon i {
  color: #CE8F4F; }

body.template-color-20 .social-share a:hover {
  color: #CE8F4F; }

body.template-color-20 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #CE8F4F; }

body.template-color-20 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #CE8F4F; }

body.template-color-20 .video-btn a.video-icon,
body.template-color-20 .video-btn a .video-icon {
  background: #CE8F4F; }

body.template-color-20 .video-btn-2 .icon-gradient::before {
  background-color: #CE8F4F;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #CE8F4F 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #CE8F4F 100%);
  background-image: linear-gradient(-133deg, #899664 0, #CE8F4F 100%); }

body.template-color-20 .video-btn-2 .icon-gradient::after {
  background-color: #CE8F4F;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #CE8F4F 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #CE8F4F 100%);
  background-image: linear-gradient(-133deg, #899664 0, #CE8F4F 100%); }

body.template-color-20 .single-motive-speach .icon i {
  color: #CE8F4F; }

body.template-color-20 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #CE8F4F; }
  body.template-color-20 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #CE8F4F; }

body.template-color-20 .contact-form input:focus,
body.template-color-20 .contact-form textarea:focus {
  border-color: #CE8F4F; }

body.template-color-20 .contact-form input[type="submit"],
body.template-color-20 .contact-form textarea[type="submit"] {
  border-color: #CE8F4F;
  background-color: #CE8F4F; }

body.template-color-20 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #CE8F4F;
  color: #fff;
  border-color: #CE8F4F; }

body.template-color-20 .gallery .hover-overlay {
  background-color: #CE8F4F; }

body.template-color-20 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #CE8F4F; }

body.template-color-20 .blog-grid .post-content::before {
  background-color: #CE8F4F; }

body.template-color-20 .blog-grid-no-flex .post-quote::before {
  color: #CE8F4F; }

body.template-color-20 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #CE8F4F; }
  body.template-color-20 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #CE8F4F; }

body.template-color-20 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #CE8F4F; }

body.template-color-20 .bl-sidebar.search button:hover {
  background-color: #CE8F4F; }

body.template-color-20 .bl-sidebar.search button span {
  color: #CE8F4F; }

body.template-color-20 .bl-sidebar.category .category-list li a:hover {
  color: #CE8F4F; }

body.template-color-20 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #CE8F4F; }

body.template-color-20 .social-icon.icon-solid-rounded li a:hover {
  background-color: #CE8F4F; }

body.template-color-20 .icon-box .inner .icon {
  color: #CE8F4F; }

body.template-color-20 .basic-thick-line-theme-4 {
  border-top: 4px solid #CE8F4F; }

body.template-color-20 .procing-box .header .feature-mark {
  background-color: #CE8F4F; }

body.template-color-20 a.brook-btn.bk-btn-dark:hover {
  background: #CE8F4F; }

body.template-color-20 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #CE8F4F; }

body.template-color-20 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #CE8F4F; }

body.template-color-20 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #CE8F4F !important; }

body.template-color-20 .basic-modern-dots .dot {
  background-color: #CE8F4F; }

body.template-color-20 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #CE8F4F; }

body.template-color-20 a.brook-btn.bk-btn-theme-border,
body.template-color-20 button.brook-btn.bk-btn-theme-border {
  border-color: #CE8F4F;
  color: #CE8F4F; }
  body.template-color-20 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-20 button.brook-btn.bk-btn-theme-border:hover {
    background: #CE8F4F;
    color: #ffffff; }

body.template-color-20 .brook-quote::before {
  color: #CE8F4F; }

body.template-color-20 .bk-text-box.bg-theme-color {
  background: #CE8F4F; }

body.template-color-20 .bk-list--2 .list-header.with-number--theme .marker {
  color: #CE8F4F; }

body.template-color-20 .view-more-btn a span.btn-arrow {
  color: #CE8F4F; }

body.template-color-20 #fp-nav ul li a.active {
  background-color: #CE8F4F; }

body.template-color-21 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #FB6031; }

body.template-color-21 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #FB6031; }

body.template-color-21 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #FB6031; }

body.template-color-21 .faq-content h2 {
  color: #FB6031; }

body.template-color-21 .brook-section-title-corona h2 {
  color: #FB6031; }

body.template-color-21 .single-contagion .contagion-content h3 {
  color: #FB6031; }

body.template-color-21 .single-sympotom .sympotom-content h3 {
  color: #FB6031; }

body.template-color-21 .single-prevination .prevination-content h3 {
  color: #FB6031; }

body.template-color-21 .service.service--1.theme-color {
  background: #FB6031; }

body.template-color-21 .blog-grid-minimal.outline.bg-transparant {
  border-color: #FB6031; }
  body.template-color-21 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #FB6031; }

body.template-color-21 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-21 .single-svg-icon-box.business-icon-box:hover {
  background: #FB6031; }

body.template-color-21 .product_review .description_nav a.active, body.template-color-21 .product_review .description_nav a:hover {
  border-bottom: 3px solid #FB6031; }

body.template-color-21 .single-product-details-side .product-action a {
  border-color: #FB6031;
  background-color: #FB6031; }

body.template-color-21 .cart-table td.pro-subtotal span {
  color: #FB6031; }

body.template-color-21 .cart-table td.pro-addtocart button {
  border: 1px solid #FB6031;
  background-color: #FB6031; }

body.template-color-21 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #FB6031; }

body.template-color-21 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #FB6031; }

body.template-color-21 .slider-business .video-btn a .video-icon.secondary-color {
  background: #FB6031 !important; }

body.template-color-21 a.brook-btn.bk-btn-yellow, body.template-color-21 button.brook-btn.bk-btn-yellow {
  background: #FB6031;
  color: #fff !important; }

body.template-color-21 a.bk-btn.theme-btn, body.template-color-21 button.bk-btn.theme-btn {
  background-color: #FB6031; }

body.template-color-21 a.brook-btn.bk-btn-red, body.template-color-21 button.brook-btn.bk-btn-red {
  background: #FB6031; }

body.template-color-21 .section-title h3 {
  color: #FB6031; }

body.template-color-21 .service.service--1:hover::before {
  border-color: #FB6031; }

body.template-color-21 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-21 .service.service--1 .icons i {
    color: #FB6031;
    font-size: 80px; }

body.template-color-21 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #FB6031;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-21 .service.service--1 .content a.service-btn:hover {
  color: #FB6031; }
  body.template-color-21 .service.service--1 .content a.service-btn:hover i {
    color: #FB6031; }

body.template-color-21 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-21 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-21 .service.service--3 .content h3 a:hover {
  color: #FB6031; }

body.template-color-21 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-21 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-21 .title-separator.color-red::after, body.template-color-21 .title-separator.color-blue::after {
    background: #FB6031; }

body.template-color-21 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-21 .bk-gradation .item-grid .dot-wrap .dot {
    background: #FB6031; }
  body.template-color-21 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #FB6031; }
  body.template-color-21 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #FB6031; }
  body.template-color-21 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #FB6031; }

body.template-color-21 .post-nav-lisnt .nav-item a:hover {
  background: #FB6031; }

body.template-color-21 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #FB6031; }

body.template-color-21 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #FB6031; }

body.template-color-21 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #FB6031; }

body.template-color-21 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #FB6031; }

body.template-color-21 .team.team__style--2 .thumb .overlay {
  background-color: #FB6031; }
  body.template-color-21 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #FB6031; }

body.template-color-21 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-21 .team.team__style--3 .thumb .overlay {
  background-color: #FB6031; }
  body.template-color-21 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #FB6031; }

body.template-color-21 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-21 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #FB6031; }
  body.template-color-21 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #FB6031; }
  body.template-color-21 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #FB6031; }
  body.template-color-21 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-21 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #FB6031; }
  body.template-color-21 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #FB6031;
    color: #fff; }

body.template-color-21 [data-overlay]:before {
  background-color: #FB6031; }

body.template-color-21 .team-heading .heading-right button i {
  color: #FB6031; }

body.template-color-21 .team-heading .heading-right button:hover {
  color: #FB6031 !important; }

body.template-color-21 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #FB6031; }

body.template-color-21 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-21 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #FB6031; }

body.template-color-21 .mini-cart .shopping-cart {
  border-bottom: 2px solid #FB6031; }
  body.template-color-21 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #FB6031; }
  body.template-color-21 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #FB6031; }
  body.template-color-21 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #FB6031;
    background-color: #FB6031; }

body.template-color-21 .brook__toolbar .inner a:hover {
  color: #FB6031; }

body.template-color-21 .blog-grid .post-inner h5.heading:hover {
  color: #FB6031; }
  body.template-color-21 .blog-grid .post-inner h5.heading:hover a {
    color: #FB6031; }

body.template-color-21 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #FB6031; }

body.template-color-21 .theme-color {
  color: #FB6031; }

body.template-color-21 .bg_color--2 {
  background: #FB6031; }

body.template-color-21 a.brook-btn.bk-btn-theme,
body.template-color-21 button.brook-btn.bk-btn-theme {
  background: #FB6031;
  color: #fff; }

body.template-color-21 .btn-essential:hover {
  background: #FB6031 !important;
  border-color: #FB6031 !important;
  color: #fff; }

body.template-color-21 .red-border {
  border-color: #FB6031 !important; }

body.template-color-21 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #FB6031;
  color: #fff; }

body.template-color-21 .single-svg-icon-box .svg-icon svg * {
  stroke: #FB6031 !important; }

body.template-color-21 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-21 .menu-price {
  color: #FB6031; }

body.template-color-21 a.moredetails-btn:hover {
  color: #FB6031; }

body.template-color-21 a.moredetails-btn i {
  color: #FB6031; }

body.template-color-21 .messonry-button button.is-checked span.filter-text {
  color: #FB6031; }

body.template-color-21 .messonry-button button:hover span.filter-text {
  color: #FB6031; }

body.template-color-21 .messonry-button button span.filter-counter {
  background-color: #FB6031; }
  body.template-color-21 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #FB6031;
    border-top-color: #FB6031; }

body.template-color-21 .messonry-button button:hover span.filter-text {
  color: #FB6031; }

body.template-color-21 .messonry-button button.is-checked span.filter-text {
  color: #FB6031; }

body.template-color-21 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #FB6031; }

body.template-color-21 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #FB6031; }

body.template-color-21 .testimonial_style--1 .content .testimonial-quote span {
  color: #FB6031; }

body.template-color-21 .testimonial_style--1:hover {
  background: #FB6031; }
  body.template-color-21 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-21 .contact-form.contact-form--4 .input-box button {
  color: #FB6031; }
  body.template-color-21 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #FB6031; }

body.template-color-21 .contact-form.contact-form--3 .input-box button {
  color: #FB6031; }

body.template-color-21 .animated-dot {
  background-color: #FB6031; }
  body.template-color-21 .animated-dot .middle-dot {
    background-color: #FB6031; }
  body.template-color-21 .animated-dot .signal {
    background-color: #FB6031; }
  body.template-color-21 .animated-dot .signal2 {
    background-color: #FB6031; }

body.template-color-21 .progress-charts .progress .progress-bar {
  background-color: #FB6031; }

body.template-color-21 .brook-counter .icon i {
  color: #FB6031; }

body.template-color-21 .social-share a:hover {
  color: #FB6031; }

body.template-color-21 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #FB6031; }

body.template-color-21 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #FB6031; }

body.template-color-21 .video-btn a.video-icon,
body.template-color-21 .video-btn a .video-icon {
  background: #FB6031; }

body.template-color-21 .video-btn-2 .icon-gradient::before {
  background-color: #FB6031;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FB6031 100%); }

body.template-color-21 .video-btn-2 .icon-gradient::after {
  background-color: #FB6031;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FB6031 100%); }

body.template-color-21 .single-motive-speach .icon i {
  color: #FB6031; }

body.template-color-21 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #FB6031; }
  body.template-color-21 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #FB6031; }

body.template-color-21 .contact-form input:focus,
body.template-color-21 .contact-form textarea:focus {
  border-color: #FB6031; }

body.template-color-21 .contact-form input[type="submit"],
body.template-color-21 .contact-form textarea[type="submit"] {
  border-color: #FB6031;
  background-color: #FB6031; }

body.template-color-21 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #FB6031;
  color: #fff;
  border-color: #FB6031; }

body.template-color-21 .gallery .hover-overlay {
  background-color: #FB6031; }

body.template-color-21 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #FB6031; }

body.template-color-21 .blog-grid .post-content::before {
  background-color: #FB6031; }

body.template-color-21 .blog-grid-no-flex .post-quote::before {
  color: #FB6031; }

body.template-color-21 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #FB6031; }
  body.template-color-21 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #FB6031; }

body.template-color-21 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #FB6031; }

body.template-color-21 .bl-sidebar.search button:hover {
  background-color: #FB6031; }

body.template-color-21 .bl-sidebar.search button span {
  color: #FB6031; }

body.template-color-21 .bl-sidebar.category .category-list li a:hover {
  color: #FB6031; }

body.template-color-21 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #FB6031; }

body.template-color-21 .social-icon.icon-solid-rounded li a:hover {
  background-color: #FB6031; }

body.template-color-21 .icon-box .inner .icon {
  color: #FB6031; }

body.template-color-21 .basic-thick-line-theme-4 {
  border-top: 4px solid #FB6031; }

body.template-color-21 .procing-box .header .feature-mark {
  background-color: #FB6031; }

body.template-color-21 a.brook-btn.bk-btn-dark:hover {
  background: #FB6031; }

body.template-color-21 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #FB6031; }

body.template-color-21 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #FB6031; }

body.template-color-21 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #FB6031 !important; }

body.template-color-21 .basic-modern-dots .dot {
  background-color: #FB6031; }

body.template-color-21 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #FB6031; }

body.template-color-21 a.brook-btn.bk-btn-theme-border,
body.template-color-21 button.brook-btn.bk-btn-theme-border {
  border-color: #FB6031;
  color: #FB6031; }
  body.template-color-21 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-21 button.brook-btn.bk-btn-theme-border:hover {
    background: #FB6031;
    color: #ffffff; }

body.template-color-21 .brook-quote::before {
  color: #FB6031; }

body.template-color-21 .bk-text-box.bg-theme-color {
  background: #FB6031; }

body.template-color-21 .bk-list--2 .list-header.with-number--theme .marker {
  color: #FB6031; }

body.template-color-21 .view-more-btn a span.btn-arrow {
  color: #FB6031; }

body.template-color-21 #fp-nav ul li a.active {
  background-color: #FB6031; }

body.template-color-22 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #A810E0; }

body.template-color-22 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #A810E0; }

body.template-color-22 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #A810E0; }

body.template-color-22 .faq-content h2 {
  color: #A810E0; }

body.template-color-22 .brook-section-title-corona h2 {
  color: #A810E0; }

body.template-color-22 .single-contagion .contagion-content h3 {
  color: #A810E0; }

body.template-color-22 .single-sympotom .sympotom-content h3 {
  color: #A810E0; }

body.template-color-22 .single-prevination .prevination-content h3 {
  color: #A810E0; }

body.template-color-22 .service.service--1.theme-color {
  background: #A810E0; }

body.template-color-22 .blog-grid-minimal.outline.bg-transparant {
  border-color: #A810E0; }
  body.template-color-22 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #A810E0; }

body.template-color-22 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-22 .single-svg-icon-box.business-icon-box:hover {
  background: #A810E0; }

body.template-color-22 .product_review .description_nav a.active, body.template-color-22 .product_review .description_nav a:hover {
  border-bottom: 3px solid #A810E0; }

body.template-color-22 .single-product-details-side .product-action a {
  border-color: #A810E0;
  background-color: #A810E0; }

body.template-color-22 .cart-table td.pro-subtotal span {
  color: #A810E0; }

body.template-color-22 .cart-table td.pro-addtocart button {
  border: 1px solid #A810E0;
  background-color: #A810E0; }

body.template-color-22 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #A810E0; }

body.template-color-22 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #A810E0; }

body.template-color-22 .slider-business .video-btn a .video-icon.secondary-color {
  background: #A810E0 !important; }

body.template-color-22 a.brook-btn.bk-btn-yellow, body.template-color-22 button.brook-btn.bk-btn-yellow {
  background: #A810E0;
  color: #fff !important; }

body.template-color-22 a.bk-btn.theme-btn, body.template-color-22 button.bk-btn.theme-btn {
  background-color: #A810E0; }

body.template-color-22 a.brook-btn.bk-btn-red, body.template-color-22 button.brook-btn.bk-btn-red {
  background: #A810E0; }

body.template-color-22 .section-title h3 {
  color: #A810E0; }

body.template-color-22 .service.service--1:hover::before {
  border-color: #A810E0; }

body.template-color-22 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-22 .service.service--1 .icons i {
    color: #A810E0;
    font-size: 80px; }

body.template-color-22 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #A810E0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-22 .service.service--1 .content a.service-btn:hover {
  color: #A810E0; }
  body.template-color-22 .service.service--1 .content a.service-btn:hover i {
    color: #A810E0; }

body.template-color-22 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-22 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-22 .service.service--3 .content h3 a:hover {
  color: #A810E0; }

body.template-color-22 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-22 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-22 .title-separator.color-red::after, body.template-color-22 .title-separator.color-blue::after {
    background: #A810E0; }

body.template-color-22 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-22 .bk-gradation .item-grid .dot-wrap .dot {
    background: #A810E0; }
  body.template-color-22 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #A810E0; }
  body.template-color-22 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #A810E0; }
  body.template-color-22 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #A810E0; }

body.template-color-22 .post-nav-lisnt .nav-item a:hover {
  background: #A810E0; }

body.template-color-22 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #A810E0; }

body.template-color-22 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #A810E0; }

body.template-color-22 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #A810E0; }

body.template-color-22 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #A810E0; }

body.template-color-22 .team.team__style--2 .thumb .overlay {
  background-color: #A810E0; }
  body.template-color-22 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #A810E0; }

body.template-color-22 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-22 .team.team__style--3 .thumb .overlay {
  background-color: #A810E0; }
  body.template-color-22 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #A810E0; }

body.template-color-22 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-22 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #A810E0; }
  body.template-color-22 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #A810E0; }
  body.template-color-22 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #A810E0; }
  body.template-color-22 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-22 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #A810E0; }
  body.template-color-22 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #A810E0;
    color: #fff; }

body.template-color-22 [data-overlay]:before {
  background-color: #A810E0; }

body.template-color-22 .team-heading .heading-right button i {
  color: #A810E0; }

body.template-color-22 .team-heading .heading-right button:hover {
  color: #A810E0 !important; }

body.template-color-22 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #A810E0; }

body.template-color-22 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-22 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #A810E0; }

body.template-color-22 .mini-cart .shopping-cart {
  border-bottom: 2px solid #A810E0; }
  body.template-color-22 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #A810E0; }
  body.template-color-22 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #A810E0; }
  body.template-color-22 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #A810E0;
    background-color: #A810E0; }

body.template-color-22 .brook__toolbar .inner a:hover {
  color: #A810E0; }

body.template-color-22 .blog-grid .post-inner h5.heading:hover {
  color: #A810E0; }
  body.template-color-22 .blog-grid .post-inner h5.heading:hover a {
    color: #A810E0; }

body.template-color-22 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #A810E0; }

body.template-color-22 .theme-color {
  color: #A810E0; }

body.template-color-22 .bg_color--2 {
  background: #A810E0; }

body.template-color-22 a.brook-btn.bk-btn-theme,
body.template-color-22 button.brook-btn.bk-btn-theme {
  background: #A810E0;
  color: #fff; }

body.template-color-22 .btn-essential:hover {
  background: #A810E0 !important;
  border-color: #A810E0 !important;
  color: #fff; }

body.template-color-22 .red-border {
  border-color: #A810E0 !important; }

body.template-color-22 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #A810E0;
  color: #fff; }

body.template-color-22 .single-svg-icon-box .svg-icon svg * {
  stroke: #A810E0 !important; }

body.template-color-22 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-22 .menu-price {
  color: #A810E0; }

body.template-color-22 a.moredetails-btn:hover {
  color: #A810E0; }

body.template-color-22 a.moredetails-btn i {
  color: #A810E0; }

body.template-color-22 .messonry-button button.is-checked span.filter-text {
  color: #A810E0; }

body.template-color-22 .messonry-button button:hover span.filter-text {
  color: #A810E0; }

body.template-color-22 .messonry-button button span.filter-counter {
  background-color: #A810E0; }
  body.template-color-22 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #A810E0;
    border-top-color: #A810E0; }

body.template-color-22 .messonry-button button:hover span.filter-text {
  color: #A810E0; }

body.template-color-22 .messonry-button button.is-checked span.filter-text {
  color: #A810E0; }

body.template-color-22 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #A810E0; }

body.template-color-22 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #A810E0; }

body.template-color-22 .testimonial_style--1 .content .testimonial-quote span {
  color: #A810E0; }

body.template-color-22 .testimonial_style--1:hover {
  background: #A810E0; }
  body.template-color-22 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-22 .contact-form.contact-form--4 .input-box button {
  color: #A810E0; }
  body.template-color-22 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #A810E0; }

body.template-color-22 .contact-form.contact-form--3 .input-box button {
  color: #A810E0; }

body.template-color-22 .animated-dot {
  background-color: #A810E0; }
  body.template-color-22 .animated-dot .middle-dot {
    background-color: #A810E0; }
  body.template-color-22 .animated-dot .signal {
    background-color: #A810E0; }
  body.template-color-22 .animated-dot .signal2 {
    background-color: #A810E0; }

body.template-color-22 .progress-charts .progress .progress-bar {
  background-color: #A810E0; }

body.template-color-22 .brook-counter .icon i {
  color: #A810E0; }

body.template-color-22 .social-share a:hover {
  color: #A810E0; }

body.template-color-22 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #A810E0; }

body.template-color-22 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #A810E0; }

body.template-color-22 .video-btn a.video-icon,
body.template-color-22 .video-btn a .video-icon {
  background: #A810E0; }

body.template-color-22 .video-btn-2 .icon-gradient::before {
  background-color: #A810E0;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #A810E0 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #A810E0 100%);
  background-image: linear-gradient(-133deg, #899664 0, #A810E0 100%); }

body.template-color-22 .video-btn-2 .icon-gradient::after {
  background-color: #A810E0;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #A810E0 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #A810E0 100%);
  background-image: linear-gradient(-133deg, #899664 0, #A810E0 100%); }

body.template-color-22 .single-motive-speach .icon i {
  color: #A810E0; }

body.template-color-22 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #A810E0; }
  body.template-color-22 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #A810E0; }

body.template-color-22 .contact-form input:focus,
body.template-color-22 .contact-form textarea:focus {
  border-color: #A810E0; }

body.template-color-22 .contact-form input[type="submit"],
body.template-color-22 .contact-form textarea[type="submit"] {
  border-color: #A810E0;
  background-color: #A810E0; }

body.template-color-22 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #A810E0;
  color: #fff;
  border-color: #A810E0; }

body.template-color-22 .gallery .hover-overlay {
  background-color: #A810E0; }

body.template-color-22 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #A810E0; }

body.template-color-22 .blog-grid .post-content::before {
  background-color: #A810E0; }

body.template-color-22 .blog-grid-no-flex .post-quote::before {
  color: #A810E0; }

body.template-color-22 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #A810E0; }
  body.template-color-22 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #A810E0; }

body.template-color-22 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #A810E0; }

body.template-color-22 .bl-sidebar.search button:hover {
  background-color: #A810E0; }

body.template-color-22 .bl-sidebar.search button span {
  color: #A810E0; }

body.template-color-22 .bl-sidebar.category .category-list li a:hover {
  color: #A810E0; }

body.template-color-22 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #A810E0; }

body.template-color-22 .social-icon.icon-solid-rounded li a:hover {
  background-color: #A810E0; }

body.template-color-22 .icon-box .inner .icon {
  color: #A810E0; }

body.template-color-22 .basic-thick-line-theme-4 {
  border-top: 4px solid #A810E0; }

body.template-color-22 .procing-box .header .feature-mark {
  background-color: #A810E0; }

body.template-color-22 a.brook-btn.bk-btn-dark:hover {
  background: #A810E0; }

body.template-color-22 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #A810E0; }

body.template-color-22 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #A810E0; }

body.template-color-22 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #A810E0 !important; }

body.template-color-22 .basic-modern-dots .dot {
  background-color: #A810E0; }

body.template-color-22 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #A810E0; }

body.template-color-22 a.brook-btn.bk-btn-theme-border,
body.template-color-22 button.brook-btn.bk-btn-theme-border {
  border-color: #A810E0;
  color: #A810E0; }
  body.template-color-22 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-22 button.brook-btn.bk-btn-theme-border:hover {
    background: #A810E0;
    color: #ffffff; }

body.template-color-22 .brook-quote::before {
  color: #A810E0; }

body.template-color-22 .bk-text-box.bg-theme-color {
  background: #A810E0; }

body.template-color-22 .bk-list--2 .list-header.with-number--theme .marker {
  color: #A810E0; }

body.template-color-22 .view-more-btn a span.btn-arrow {
  color: #A810E0; }

body.template-color-22 #fp-nav ul li a.active {
  background-color: #A810E0; }

body.template-color-23 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #68AE4A; }

body.template-color-23 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #68AE4A; }

body.template-color-23 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #68AE4A; }

body.template-color-23 .faq-content h2 {
  color: #68AE4A; }

body.template-color-23 .brook-section-title-corona h2 {
  color: #68AE4A; }

body.template-color-23 .single-contagion .contagion-content h3 {
  color: #68AE4A; }

body.template-color-23 .single-sympotom .sympotom-content h3 {
  color: #68AE4A; }

body.template-color-23 .single-prevination .prevination-content h3 {
  color: #68AE4A; }

body.template-color-23 .service.service--1.theme-color {
  background: #68AE4A; }

body.template-color-23 .blog-grid-minimal.outline.bg-transparant {
  border-color: #68AE4A; }
  body.template-color-23 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #68AE4A; }

body.template-color-23 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-23 .single-svg-icon-box.business-icon-box:hover {
  background: #68AE4A; }

body.template-color-23 .product_review .description_nav a.active, body.template-color-23 .product_review .description_nav a:hover {
  border-bottom: 3px solid #68AE4A; }

body.template-color-23 .single-product-details-side .product-action a {
  border-color: #68AE4A;
  background-color: #68AE4A; }

body.template-color-23 .cart-table td.pro-subtotal span {
  color: #68AE4A; }

body.template-color-23 .cart-table td.pro-addtocart button {
  border: 1px solid #68AE4A;
  background-color: #68AE4A; }

body.template-color-23 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #68AE4A; }

body.template-color-23 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #68AE4A; }

body.template-color-23 .slider-business .video-btn a .video-icon.secondary-color {
  background: #68AE4A !important; }

body.template-color-23 a.brook-btn.bk-btn-yellow, body.template-color-23 button.brook-btn.bk-btn-yellow {
  background: #68AE4A;
  color: #fff !important; }

body.template-color-23 a.bk-btn.theme-btn, body.template-color-23 button.bk-btn.theme-btn {
  background-color: #68AE4A; }

body.template-color-23 a.brook-btn.bk-btn-red, body.template-color-23 button.brook-btn.bk-btn-red {
  background: #68AE4A; }

body.template-color-23 .section-title h3 {
  color: #68AE4A; }

body.template-color-23 .service.service--1:hover::before {
  border-color: #68AE4A; }

body.template-color-23 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-23 .service.service--1 .icons i {
    color: #68AE4A;
    font-size: 80px; }

body.template-color-23 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #68AE4A;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-23 .service.service--1 .content a.service-btn:hover {
  color: #68AE4A; }
  body.template-color-23 .service.service--1 .content a.service-btn:hover i {
    color: #68AE4A; }

body.template-color-23 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-23 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-23 .service.service--3 .content h3 a:hover {
  color: #68AE4A; }

body.template-color-23 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-23 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-23 .title-separator.color-red::after, body.template-color-23 .title-separator.color-blue::after {
    background: #68AE4A; }

body.template-color-23 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-23 .bk-gradation .item-grid .dot-wrap .dot {
    background: #68AE4A; }
  body.template-color-23 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #68AE4A; }
  body.template-color-23 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #68AE4A; }
  body.template-color-23 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #68AE4A; }

body.template-color-23 .post-nav-lisnt .nav-item a:hover {
  background: #68AE4A; }

body.template-color-23 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #68AE4A; }

body.template-color-23 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #68AE4A; }

body.template-color-23 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #68AE4A; }

body.template-color-23 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #68AE4A; }

body.template-color-23 .team.team__style--2 .thumb .overlay {
  background-color: #68AE4A; }
  body.template-color-23 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #68AE4A; }

body.template-color-23 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-23 .team.team__style--3 .thumb .overlay {
  background-color: #68AE4A; }
  body.template-color-23 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #68AE4A; }

body.template-color-23 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-23 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #68AE4A; }
  body.template-color-23 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #68AE4A; }
  body.template-color-23 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #68AE4A; }
  body.template-color-23 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-23 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #68AE4A; }
  body.template-color-23 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #68AE4A;
    color: #fff; }

body.template-color-23 [data-overlay]:before {
  background-color: #68AE4A; }

body.template-color-23 .team-heading .heading-right button i {
  color: #68AE4A; }

body.template-color-23 .team-heading .heading-right button:hover {
  color: #68AE4A !important; }

body.template-color-23 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #68AE4A; }

body.template-color-23 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-23 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #68AE4A; }

body.template-color-23 .mini-cart .shopping-cart {
  border-bottom: 2px solid #68AE4A; }
  body.template-color-23 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #68AE4A; }
  body.template-color-23 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #68AE4A; }
  body.template-color-23 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #68AE4A;
    background-color: #68AE4A; }

body.template-color-23 .brook__toolbar .inner a:hover {
  color: #68AE4A; }

body.template-color-23 .blog-grid .post-inner h5.heading:hover {
  color: #68AE4A; }
  body.template-color-23 .blog-grid .post-inner h5.heading:hover a {
    color: #68AE4A; }

body.template-color-23 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #68AE4A; }

body.template-color-23 .theme-color {
  color: #68AE4A; }

body.template-color-23 .bg_color--2 {
  background: #68AE4A; }

body.template-color-23 a.brook-btn.bk-btn-theme,
body.template-color-23 button.brook-btn.bk-btn-theme {
  background: #68AE4A;
  color: #fff; }

body.template-color-23 .btn-essential:hover {
  background: #68AE4A !important;
  border-color: #68AE4A !important;
  color: #fff; }

body.template-color-23 .red-border {
  border-color: #68AE4A !important; }

body.template-color-23 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #68AE4A;
  color: #fff; }

body.template-color-23 .single-svg-icon-box .svg-icon svg * {
  stroke: #68AE4A !important; }

body.template-color-23 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-23 .menu-price {
  color: #68AE4A; }

body.template-color-23 a.moredetails-btn:hover {
  color: #68AE4A; }

body.template-color-23 a.moredetails-btn i {
  color: #68AE4A; }

body.template-color-23 .messonry-button button.is-checked span.filter-text {
  color: #68AE4A; }

body.template-color-23 .messonry-button button:hover span.filter-text {
  color: #68AE4A; }

body.template-color-23 .messonry-button button span.filter-counter {
  background-color: #68AE4A; }
  body.template-color-23 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #68AE4A;
    border-top-color: #68AE4A; }

body.template-color-23 .messonry-button button:hover span.filter-text {
  color: #68AE4A; }

body.template-color-23 .messonry-button button.is-checked span.filter-text {
  color: #68AE4A; }

body.template-color-23 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #68AE4A; }

body.template-color-23 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #68AE4A; }

body.template-color-23 .testimonial_style--1 .content .testimonial-quote span {
  color: #68AE4A; }

body.template-color-23 .testimonial_style--1:hover {
  background: #68AE4A; }
  body.template-color-23 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-23 .contact-form.contact-form--4 .input-box button {
  color: #68AE4A; }
  body.template-color-23 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #68AE4A; }

body.template-color-23 .contact-form.contact-form--3 .input-box button {
  color: #68AE4A; }

body.template-color-23 .animated-dot {
  background-color: #68AE4A; }
  body.template-color-23 .animated-dot .middle-dot {
    background-color: #68AE4A; }
  body.template-color-23 .animated-dot .signal {
    background-color: #68AE4A; }
  body.template-color-23 .animated-dot .signal2 {
    background-color: #68AE4A; }

body.template-color-23 .progress-charts .progress .progress-bar {
  background-color: #68AE4A; }

body.template-color-23 .brook-counter .icon i {
  color: #68AE4A; }

body.template-color-23 .social-share a:hover {
  color: #68AE4A; }

body.template-color-23 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #68AE4A; }

body.template-color-23 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #68AE4A; }

body.template-color-23 .video-btn a.video-icon,
body.template-color-23 .video-btn a .video-icon {
  background: #68AE4A; }

body.template-color-23 .video-btn-2 .icon-gradient::before {
  background-color: #68AE4A;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #68AE4A 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #68AE4A 100%);
  background-image: linear-gradient(-133deg, #899664 0, #68AE4A 100%); }

body.template-color-23 .video-btn-2 .icon-gradient::after {
  background-color: #68AE4A;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #68AE4A 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #68AE4A 100%);
  background-image: linear-gradient(-133deg, #899664 0, #68AE4A 100%); }

body.template-color-23 .single-motive-speach .icon i {
  color: #68AE4A; }

body.template-color-23 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #68AE4A; }
  body.template-color-23 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #68AE4A; }

body.template-color-23 .contact-form input:focus,
body.template-color-23 .contact-form textarea:focus {
  border-color: #68AE4A; }

body.template-color-23 .contact-form input[type="submit"],
body.template-color-23 .contact-form textarea[type="submit"] {
  border-color: #68AE4A;
  background-color: #68AE4A; }

body.template-color-23 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #68AE4A;
  color: #fff;
  border-color: #68AE4A; }

body.template-color-23 .gallery .hover-overlay {
  background-color: #68AE4A; }

body.template-color-23 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #68AE4A; }

body.template-color-23 .blog-grid .post-content::before {
  background-color: #68AE4A; }

body.template-color-23 .blog-grid-no-flex .post-quote::before {
  color: #68AE4A; }

body.template-color-23 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #68AE4A; }
  body.template-color-23 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #68AE4A; }

body.template-color-23 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #68AE4A; }

body.template-color-23 .bl-sidebar.search button:hover {
  background-color: #68AE4A; }

body.template-color-23 .bl-sidebar.search button span {
  color: #68AE4A; }

body.template-color-23 .bl-sidebar.category .category-list li a:hover {
  color: #68AE4A; }

body.template-color-23 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #68AE4A; }

body.template-color-23 .social-icon.icon-solid-rounded li a:hover {
  background-color: #68AE4A; }

body.template-color-23 .icon-box .inner .icon {
  color: #68AE4A; }

body.template-color-23 .basic-thick-line-theme-4 {
  border-top: 4px solid #68AE4A; }

body.template-color-23 .procing-box .header .feature-mark {
  background-color: #68AE4A; }

body.template-color-23 a.brook-btn.bk-btn-dark:hover {
  background: #68AE4A; }

body.template-color-23 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #68AE4A; }

body.template-color-23 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #68AE4A; }

body.template-color-23 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #68AE4A !important; }

body.template-color-23 .basic-modern-dots .dot {
  background-color: #68AE4A; }

body.template-color-23 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #68AE4A; }

body.template-color-23 a.brook-btn.bk-btn-theme-border,
body.template-color-23 button.brook-btn.bk-btn-theme-border {
  border-color: #68AE4A;
  color: #68AE4A; }
  body.template-color-23 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-23 button.brook-btn.bk-btn-theme-border:hover {
    background: #68AE4A;
    color: #ffffff; }

body.template-color-23 .brook-quote::before {
  color: #68AE4A; }

body.template-color-23 .bk-text-box.bg-theme-color {
  background: #68AE4A; }

body.template-color-23 .bk-list--2 .list-header.with-number--theme .marker {
  color: #68AE4A; }

body.template-color-23 .view-more-btn a span.btn-arrow {
  color: #68AE4A; }

body.template-color-23 #fp-nav ul li a.active {
  background-color: #68AE4A; }

body.template-color-24 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #BC9464; }

body.template-color-24 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #BC9464; }

body.template-color-24 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #BC9464; }

body.template-color-24 .faq-content h2 {
  color: #BC9464; }

body.template-color-24 .brook-section-title-corona h2 {
  color: #BC9464; }

body.template-color-24 .single-contagion .contagion-content h3 {
  color: #BC9464; }

body.template-color-24 .single-sympotom .sympotom-content h3 {
  color: #BC9464; }

body.template-color-24 .single-prevination .prevination-content h3 {
  color: #BC9464; }

body.template-color-24 .service.service--1.theme-color {
  background: #BC9464; }

body.template-color-24 .blog-grid-minimal.outline.bg-transparant {
  border-color: #BC9464; }
  body.template-color-24 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #BC9464; }

body.template-color-24 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-24 .single-svg-icon-box.business-icon-box:hover {
  background: #BC9464; }

body.template-color-24 .product_review .description_nav a.active, body.template-color-24 .product_review .description_nav a:hover {
  border-bottom: 3px solid #BC9464; }

body.template-color-24 .single-product-details-side .product-action a {
  border-color: #BC9464;
  background-color: #BC9464; }

body.template-color-24 .cart-table td.pro-subtotal span {
  color: #BC9464; }

body.template-color-24 .cart-table td.pro-addtocart button {
  border: 1px solid #BC9464;
  background-color: #BC9464; }

body.template-color-24 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #BC9464; }

body.template-color-24 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #BC9464; }

body.template-color-24 .slider-business .video-btn a .video-icon.secondary-color {
  background: #BC9464 !important; }

body.template-color-24 a.brook-btn.bk-btn-yellow, body.template-color-24 button.brook-btn.bk-btn-yellow {
  background: #BC9464;
  color: #fff !important; }

body.template-color-24 a.bk-btn.theme-btn, body.template-color-24 button.bk-btn.theme-btn {
  background-color: #BC9464; }

body.template-color-24 a.brook-btn.bk-btn-red, body.template-color-24 button.brook-btn.bk-btn-red {
  background: #BC9464; }

body.template-color-24 .section-title h3 {
  color: #BC9464; }

body.template-color-24 .service.service--1:hover::before {
  border-color: #BC9464; }

body.template-color-24 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-24 .service.service--1 .icons i {
    color: #BC9464;
    font-size: 80px; }

body.template-color-24 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #BC9464;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-24 .service.service--1 .content a.service-btn:hover {
  color: #BC9464; }
  body.template-color-24 .service.service--1 .content a.service-btn:hover i {
    color: #BC9464; }

body.template-color-24 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-24 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-24 .service.service--3 .content h3 a:hover {
  color: #BC9464; }

body.template-color-24 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-24 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-24 .title-separator.color-red::after, body.template-color-24 .title-separator.color-blue::after {
    background: #BC9464; }

body.template-color-24 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-24 .bk-gradation .item-grid .dot-wrap .dot {
    background: #BC9464; }
  body.template-color-24 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #BC9464; }
  body.template-color-24 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #BC9464; }
  body.template-color-24 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #BC9464; }

body.template-color-24 .post-nav-lisnt .nav-item a:hover {
  background: #BC9464; }

body.template-color-24 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #BC9464; }

body.template-color-24 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #BC9464; }

body.template-color-24 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #BC9464; }

body.template-color-24 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #BC9464; }

body.template-color-24 .team.team__style--2 .thumb .overlay {
  background-color: #BC9464; }
  body.template-color-24 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #BC9464; }

body.template-color-24 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-24 .team.team__style--3 .thumb .overlay {
  background-color: #BC9464; }
  body.template-color-24 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #BC9464; }

body.template-color-24 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-24 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #BC9464; }
  body.template-color-24 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #BC9464; }
  body.template-color-24 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #BC9464; }
  body.template-color-24 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-24 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #BC9464; }
  body.template-color-24 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #BC9464;
    color: #fff; }

body.template-color-24 [data-overlay]:before {
  background-color: #BC9464; }

body.template-color-24 .team-heading .heading-right button i {
  color: #BC9464; }

body.template-color-24 .team-heading .heading-right button:hover {
  color: #BC9464 !important; }

body.template-color-24 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #BC9464; }

body.template-color-24 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-24 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #BC9464; }

body.template-color-24 .mini-cart .shopping-cart {
  border-bottom: 2px solid #BC9464; }
  body.template-color-24 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #BC9464; }
  body.template-color-24 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #BC9464; }
  body.template-color-24 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #BC9464;
    background-color: #BC9464; }

body.template-color-24 .brook__toolbar .inner a:hover {
  color: #BC9464; }

body.template-color-24 .blog-grid .post-inner h5.heading:hover {
  color: #BC9464; }
  body.template-color-24 .blog-grid .post-inner h5.heading:hover a {
    color: #BC9464; }

body.template-color-24 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #BC9464; }

body.template-color-24 .theme-color {
  color: #BC9464; }

body.template-color-24 .bg_color--2 {
  background: #BC9464; }

body.template-color-24 a.brook-btn.bk-btn-theme,
body.template-color-24 button.brook-btn.bk-btn-theme {
  background: #BC9464;
  color: #fff; }

body.template-color-24 .btn-essential:hover {
  background: #BC9464 !important;
  border-color: #BC9464 !important;
  color: #fff; }

body.template-color-24 .red-border {
  border-color: #BC9464 !important; }

body.template-color-24 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #BC9464;
  color: #fff; }

body.template-color-24 .single-svg-icon-box .svg-icon svg * {
  stroke: #BC9464 !important; }

body.template-color-24 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-24 .menu-price {
  color: #BC9464; }

body.template-color-24 a.moredetails-btn:hover {
  color: #BC9464; }

body.template-color-24 a.moredetails-btn i {
  color: #BC9464; }

body.template-color-24 .messonry-button button.is-checked span.filter-text {
  color: #BC9464; }

body.template-color-24 .messonry-button button:hover span.filter-text {
  color: #BC9464; }

body.template-color-24 .messonry-button button span.filter-counter {
  background-color: #BC9464; }
  body.template-color-24 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #BC9464;
    border-top-color: #BC9464; }

body.template-color-24 .messonry-button button:hover span.filter-text {
  color: #BC9464; }

body.template-color-24 .messonry-button button.is-checked span.filter-text {
  color: #BC9464; }

body.template-color-24 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #BC9464; }

body.template-color-24 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #BC9464; }

body.template-color-24 .testimonial_style--1 .content .testimonial-quote span {
  color: #BC9464; }

body.template-color-24 .testimonial_style--1:hover {
  background: #BC9464; }
  body.template-color-24 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-24 .contact-form.contact-form--4 .input-box button {
  color: #BC9464; }
  body.template-color-24 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #BC9464; }

body.template-color-24 .contact-form.contact-form--3 .input-box button {
  color: #BC9464; }

body.template-color-24 .animated-dot {
  background-color: #BC9464; }
  body.template-color-24 .animated-dot .middle-dot {
    background-color: #BC9464; }
  body.template-color-24 .animated-dot .signal {
    background-color: #BC9464; }
  body.template-color-24 .animated-dot .signal2 {
    background-color: #BC9464; }

body.template-color-24 .progress-charts .progress .progress-bar {
  background-color: #BC9464; }

body.template-color-24 .brook-counter .icon i {
  color: #BC9464; }

body.template-color-24 .social-share a:hover {
  color: #BC9464; }

body.template-color-24 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #BC9464; }

body.template-color-24 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #BC9464; }

body.template-color-24 .video-btn a.video-icon,
body.template-color-24 .video-btn a .video-icon {
  background: #BC9464; }

body.template-color-24 .video-btn-2 .icon-gradient::before {
  background-color: #BC9464;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #BC9464 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #BC9464 100%);
  background-image: linear-gradient(-133deg, #899664 0, #BC9464 100%); }

body.template-color-24 .video-btn-2 .icon-gradient::after {
  background-color: #BC9464;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #BC9464 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #BC9464 100%);
  background-image: linear-gradient(-133deg, #899664 0, #BC9464 100%); }

body.template-color-24 .single-motive-speach .icon i {
  color: #BC9464; }

body.template-color-24 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #BC9464; }
  body.template-color-24 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #BC9464; }

body.template-color-24 .contact-form input:focus,
body.template-color-24 .contact-form textarea:focus {
  border-color: #BC9464; }

body.template-color-24 .contact-form input[type="submit"],
body.template-color-24 .contact-form textarea[type="submit"] {
  border-color: #BC9464;
  background-color: #BC9464; }

body.template-color-24 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #BC9464;
  color: #fff;
  border-color: #BC9464; }

body.template-color-24 .gallery .hover-overlay {
  background-color: #BC9464; }

body.template-color-24 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #BC9464; }

body.template-color-24 .blog-grid .post-content::before {
  background-color: #BC9464; }

body.template-color-24 .blog-grid-no-flex .post-quote::before {
  color: #BC9464; }

body.template-color-24 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #BC9464; }
  body.template-color-24 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #BC9464; }

body.template-color-24 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #BC9464; }

body.template-color-24 .bl-sidebar.search button:hover {
  background-color: #BC9464; }

body.template-color-24 .bl-sidebar.search button span {
  color: #BC9464; }

body.template-color-24 .bl-sidebar.category .category-list li a:hover {
  color: #BC9464; }

body.template-color-24 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #BC9464; }

body.template-color-24 .social-icon.icon-solid-rounded li a:hover {
  background-color: #BC9464; }

body.template-color-24 .icon-box .inner .icon {
  color: #BC9464; }

body.template-color-24 .basic-thick-line-theme-4 {
  border-top: 4px solid #BC9464; }

body.template-color-24 .procing-box .header .feature-mark {
  background-color: #BC9464; }

body.template-color-24 a.brook-btn.bk-btn-dark:hover {
  background: #BC9464; }

body.template-color-24 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #BC9464; }

body.template-color-24 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #BC9464; }

body.template-color-24 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #BC9464 !important; }

body.template-color-24 .basic-modern-dots .dot {
  background-color: #BC9464; }

body.template-color-24 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #BC9464; }

body.template-color-24 a.brook-btn.bk-btn-theme-border,
body.template-color-24 button.brook-btn.bk-btn-theme-border {
  border-color: #BC9464;
  color: #BC9464; }
  body.template-color-24 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-24 button.brook-btn.bk-btn-theme-border:hover {
    background: #BC9464;
    color: #ffffff; }

body.template-color-24 .brook-quote::before {
  color: #BC9464; }

body.template-color-24 .bk-text-box.bg-theme-color {
  background: #BC9464; }

body.template-color-24 .bk-list--2 .list-header.with-number--theme .marker {
  color: #BC9464; }

body.template-color-24 .view-more-btn a span.btn-arrow {
  color: #BC9464; }

body.template-color-24 #fp-nav ul li a.active {
  background-color: #BC9464; }

body.template-color-25 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #9F3939; }

body.template-color-25 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #9F3939; }

body.template-color-25 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #9F3939; }

body.template-color-25 .faq-content h2 {
  color: #9F3939; }

body.template-color-25 .brook-section-title-corona h2 {
  color: #9F3939; }

body.template-color-25 .single-contagion .contagion-content h3 {
  color: #9F3939; }

body.template-color-25 .single-sympotom .sympotom-content h3 {
  color: #9F3939; }

body.template-color-25 .single-prevination .prevination-content h3 {
  color: #9F3939; }

body.template-color-25 .service.service--1.theme-color {
  background: #9F3939; }

body.template-color-25 .blog-grid-minimal.outline.bg-transparant {
  border-color: #9F3939; }
  body.template-color-25 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #9F3939; }

body.template-color-25 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-25 .single-svg-icon-box.business-icon-box:hover {
  background: #9F3939; }

body.template-color-25 .product_review .description_nav a.active, body.template-color-25 .product_review .description_nav a:hover {
  border-bottom: 3px solid #9F3939; }

body.template-color-25 .single-product-details-side .product-action a {
  border-color: #9F3939;
  background-color: #9F3939; }

body.template-color-25 .cart-table td.pro-subtotal span {
  color: #9F3939; }

body.template-color-25 .cart-table td.pro-addtocart button {
  border: 1px solid #9F3939;
  background-color: #9F3939; }

body.template-color-25 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #9F3939; }

body.template-color-25 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #9F3939; }

body.template-color-25 .slider-business .video-btn a .video-icon.secondary-color {
  background: #9F3939 !important; }

body.template-color-25 a.brook-btn.bk-btn-yellow, body.template-color-25 button.brook-btn.bk-btn-yellow {
  background: #9F3939;
  color: #fff !important; }

body.template-color-25 a.bk-btn.theme-btn, body.template-color-25 button.bk-btn.theme-btn {
  background-color: #9F3939; }

body.template-color-25 a.brook-btn.bk-btn-red, body.template-color-25 button.brook-btn.bk-btn-red {
  background: #9F3939; }

body.template-color-25 .section-title h3 {
  color: #9F3939; }

body.template-color-25 .service.service--1:hover::before {
  border-color: #9F3939; }

body.template-color-25 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-25 .service.service--1 .icons i {
    color: #9F3939;
    font-size: 80px; }

body.template-color-25 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #9F3939;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-25 .service.service--1 .content a.service-btn:hover {
  color: #9F3939; }
  body.template-color-25 .service.service--1 .content a.service-btn:hover i {
    color: #9F3939; }

body.template-color-25 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-25 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-25 .service.service--3 .content h3 a:hover {
  color: #9F3939; }

body.template-color-25 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-25 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-25 .title-separator.color-red::after, body.template-color-25 .title-separator.color-blue::after {
    background: #9F3939; }

body.template-color-25 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-25 .bk-gradation .item-grid .dot-wrap .dot {
    background: #9F3939; }
  body.template-color-25 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #9F3939; }
  body.template-color-25 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #9F3939; }
  body.template-color-25 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #9F3939; }

body.template-color-25 .post-nav-lisnt .nav-item a:hover {
  background: #9F3939; }

body.template-color-25 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #9F3939; }

body.template-color-25 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #9F3939; }

body.template-color-25 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #9F3939; }

body.template-color-25 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #9F3939; }

body.template-color-25 .team.team__style--2 .thumb .overlay {
  background-color: #9F3939; }
  body.template-color-25 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #9F3939; }

body.template-color-25 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-25 .team.team__style--3 .thumb .overlay {
  background-color: #9F3939; }
  body.template-color-25 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #9F3939; }

body.template-color-25 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-25 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #9F3939; }
  body.template-color-25 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #9F3939; }
  body.template-color-25 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #9F3939; }
  body.template-color-25 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-25 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #9F3939; }
  body.template-color-25 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #9F3939;
    color: #fff; }

body.template-color-25 [data-overlay]:before {
  background-color: #9F3939; }

body.template-color-25 .team-heading .heading-right button i {
  color: #9F3939; }

body.template-color-25 .team-heading .heading-right button:hover {
  color: #9F3939 !important; }

body.template-color-25 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #9F3939; }

body.template-color-25 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-25 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #9F3939; }

body.template-color-25 .mini-cart .shopping-cart {
  border-bottom: 2px solid #9F3939; }
  body.template-color-25 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #9F3939; }
  body.template-color-25 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #9F3939; }
  body.template-color-25 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #9F3939;
    background-color: #9F3939; }

body.template-color-25 .brook__toolbar .inner a:hover {
  color: #9F3939; }

body.template-color-25 .blog-grid .post-inner h5.heading:hover {
  color: #9F3939; }
  body.template-color-25 .blog-grid .post-inner h5.heading:hover a {
    color: #9F3939; }

body.template-color-25 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #9F3939; }

body.template-color-25 .theme-color {
  color: #9F3939; }

body.template-color-25 .bg_color--2 {
  background: #9F3939; }

body.template-color-25 a.brook-btn.bk-btn-theme,
body.template-color-25 button.brook-btn.bk-btn-theme {
  background: #9F3939;
  color: #fff; }

body.template-color-25 .btn-essential:hover {
  background: #9F3939 !important;
  border-color: #9F3939 !important;
  color: #fff; }

body.template-color-25 .red-border {
  border-color: #9F3939 !important; }

body.template-color-25 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #9F3939;
  color: #fff; }

body.template-color-25 .single-svg-icon-box .svg-icon svg * {
  stroke: #9F3939 !important; }

body.template-color-25 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-25 .menu-price {
  color: #9F3939; }

body.template-color-25 a.moredetails-btn:hover {
  color: #9F3939; }

body.template-color-25 a.moredetails-btn i {
  color: #9F3939; }

body.template-color-25 .messonry-button button.is-checked span.filter-text {
  color: #9F3939; }

body.template-color-25 .messonry-button button:hover span.filter-text {
  color: #9F3939; }

body.template-color-25 .messonry-button button span.filter-counter {
  background-color: #9F3939; }
  body.template-color-25 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #9F3939;
    border-top-color: #9F3939; }

body.template-color-25 .messonry-button button:hover span.filter-text {
  color: #9F3939; }

body.template-color-25 .messonry-button button.is-checked span.filter-text {
  color: #9F3939; }

body.template-color-25 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #9F3939; }

body.template-color-25 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #9F3939; }

body.template-color-25 .testimonial_style--1 .content .testimonial-quote span {
  color: #9F3939; }

body.template-color-25 .testimonial_style--1:hover {
  background: #9F3939; }
  body.template-color-25 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-25 .contact-form.contact-form--4 .input-box button {
  color: #9F3939; }
  body.template-color-25 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #9F3939; }

body.template-color-25 .contact-form.contact-form--3 .input-box button {
  color: #9F3939; }

body.template-color-25 .animated-dot {
  background-color: #9F3939; }
  body.template-color-25 .animated-dot .middle-dot {
    background-color: #9F3939; }
  body.template-color-25 .animated-dot .signal {
    background-color: #9F3939; }
  body.template-color-25 .animated-dot .signal2 {
    background-color: #9F3939; }

body.template-color-25 .progress-charts .progress .progress-bar {
  background-color: #9F3939; }

body.template-color-25 .brook-counter .icon i {
  color: #9F3939; }

body.template-color-25 .social-share a:hover {
  color: #9F3939; }

body.template-color-25 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #9F3939; }

body.template-color-25 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #9F3939; }

body.template-color-25 .video-btn a.video-icon,
body.template-color-25 .video-btn a .video-icon {
  background: #9F3939; }

body.template-color-25 .video-btn-2 .icon-gradient::before {
  background-color: #9F3939;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #9F3939 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #9F3939 100%);
  background-image: linear-gradient(-133deg, #899664 0, #9F3939 100%); }

body.template-color-25 .video-btn-2 .icon-gradient::after {
  background-color: #9F3939;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #9F3939 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #9F3939 100%);
  background-image: linear-gradient(-133deg, #899664 0, #9F3939 100%); }

body.template-color-25 .single-motive-speach .icon i {
  color: #9F3939; }

body.template-color-25 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #9F3939; }
  body.template-color-25 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #9F3939; }

body.template-color-25 .contact-form input:focus,
body.template-color-25 .contact-form textarea:focus {
  border-color: #9F3939; }

body.template-color-25 .contact-form input[type="submit"],
body.template-color-25 .contact-form textarea[type="submit"] {
  border-color: #9F3939;
  background-color: #9F3939; }

body.template-color-25 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #9F3939;
  color: #fff;
  border-color: #9F3939; }

body.template-color-25 .gallery .hover-overlay {
  background-color: #9F3939; }

body.template-color-25 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #9F3939; }

body.template-color-25 .blog-grid .post-content::before {
  background-color: #9F3939; }

body.template-color-25 .blog-grid-no-flex .post-quote::before {
  color: #9F3939; }

body.template-color-25 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #9F3939; }
  body.template-color-25 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #9F3939; }

body.template-color-25 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #9F3939; }

body.template-color-25 .bl-sidebar.search button:hover {
  background-color: #9F3939; }

body.template-color-25 .bl-sidebar.search button span {
  color: #9F3939; }

body.template-color-25 .bl-sidebar.category .category-list li a:hover {
  color: #9F3939; }

body.template-color-25 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #9F3939; }

body.template-color-25 .social-icon.icon-solid-rounded li a:hover {
  background-color: #9F3939; }

body.template-color-25 .icon-box .inner .icon {
  color: #9F3939; }

body.template-color-25 .basic-thick-line-theme-4 {
  border-top: 4px solid #9F3939; }

body.template-color-25 .procing-box .header .feature-mark {
  background-color: #9F3939; }

body.template-color-25 a.brook-btn.bk-btn-dark:hover {
  background: #9F3939; }

body.template-color-25 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #9F3939; }

body.template-color-25 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #9F3939; }

body.template-color-25 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #9F3939 !important; }

body.template-color-25 .basic-modern-dots .dot {
  background-color: #9F3939; }

body.template-color-25 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #9F3939; }

body.template-color-25 a.brook-btn.bk-btn-theme-border,
body.template-color-25 button.brook-btn.bk-btn-theme-border {
  border-color: #9F3939;
  color: #9F3939; }
  body.template-color-25 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-25 button.brook-btn.bk-btn-theme-border:hover {
    background: #9F3939;
    color: #ffffff; }

body.template-color-25 .brook-quote::before {
  color: #9F3939; }

body.template-color-25 .bk-text-box.bg-theme-color {
  background: #9F3939; }

body.template-color-25 .bk-list--2 .list-header.with-number--theme .marker {
  color: #9F3939; }

body.template-color-25 .view-more-btn a span.btn-arrow {
  color: #9F3939; }

body.template-color-25 #fp-nav ul li a.active {
  background-color: #9F3939; }

body.template-color-26 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #7BA100; }

body.template-color-26 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #7BA100; }

body.template-color-26 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #7BA100; }

body.template-color-26 .faq-content h2 {
  color: #7BA100; }

body.template-color-26 .brook-section-title-corona h2 {
  color: #7BA100; }

body.template-color-26 .single-contagion .contagion-content h3 {
  color: #7BA100; }

body.template-color-26 .single-sympotom .sympotom-content h3 {
  color: #7BA100; }

body.template-color-26 .single-prevination .prevination-content h3 {
  color: #7BA100; }

body.template-color-26 .service.service--1.theme-color {
  background: #7BA100; }

body.template-color-26 .blog-grid-minimal.outline.bg-transparant {
  border-color: #7BA100; }
  body.template-color-26 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #7BA100; }

body.template-color-26 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-26 .single-svg-icon-box.business-icon-box:hover {
  background: #7BA100; }

body.template-color-26 .product_review .description_nav a.active, body.template-color-26 .product_review .description_nav a:hover {
  border-bottom: 3px solid #7BA100; }

body.template-color-26 .single-product-details-side .product-action a {
  border-color: #7BA100;
  background-color: #7BA100; }

body.template-color-26 .cart-table td.pro-subtotal span {
  color: #7BA100; }

body.template-color-26 .cart-table td.pro-addtocart button {
  border: 1px solid #7BA100;
  background-color: #7BA100; }

body.template-color-26 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #7BA100; }

body.template-color-26 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #7BA100; }

body.template-color-26 .slider-business .video-btn a .video-icon.secondary-color {
  background: #7BA100 !important; }

body.template-color-26 a.brook-btn.bk-btn-yellow, body.template-color-26 button.brook-btn.bk-btn-yellow {
  background: #7BA100;
  color: #fff !important; }

body.template-color-26 a.bk-btn.theme-btn, body.template-color-26 button.bk-btn.theme-btn {
  background-color: #7BA100; }

body.template-color-26 a.brook-btn.bk-btn-red, body.template-color-26 button.brook-btn.bk-btn-red {
  background: #7BA100; }

body.template-color-26 .section-title h3 {
  color: #7BA100; }

body.template-color-26 .service.service--1:hover::before {
  border-color: #7BA100; }

body.template-color-26 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-26 .service.service--1 .icons i {
    color: #7BA100;
    font-size: 80px; }

body.template-color-26 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #7BA100;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-26 .service.service--1 .content a.service-btn:hover {
  color: #7BA100; }
  body.template-color-26 .service.service--1 .content a.service-btn:hover i {
    color: #7BA100; }

body.template-color-26 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-26 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-26 .service.service--3 .content h3 a:hover {
  color: #7BA100; }

body.template-color-26 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-26 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-26 .title-separator.color-red::after, body.template-color-26 .title-separator.color-blue::after {
    background: #7BA100; }

body.template-color-26 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-26 .bk-gradation .item-grid .dot-wrap .dot {
    background: #7BA100; }
  body.template-color-26 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #7BA100; }
  body.template-color-26 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #7BA100; }
  body.template-color-26 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #7BA100; }

body.template-color-26 .post-nav-lisnt .nav-item a:hover {
  background: #7BA100; }

body.template-color-26 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #7BA100; }

body.template-color-26 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #7BA100; }

body.template-color-26 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #7BA100; }

body.template-color-26 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #7BA100; }

body.template-color-26 .team.team__style--2 .thumb .overlay {
  background-color: #7BA100; }
  body.template-color-26 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #7BA100; }

body.template-color-26 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-26 .team.team__style--3 .thumb .overlay {
  background-color: #7BA100; }
  body.template-color-26 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #7BA100; }

body.template-color-26 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-26 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #7BA100; }
  body.template-color-26 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #7BA100; }
  body.template-color-26 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #7BA100; }
  body.template-color-26 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-26 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #7BA100; }
  body.template-color-26 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #7BA100;
    color: #fff; }

body.template-color-26 [data-overlay]:before {
  background-color: #7BA100; }

body.template-color-26 .team-heading .heading-right button i {
  color: #7BA100; }

body.template-color-26 .team-heading .heading-right button:hover {
  color: #7BA100 !important; }

body.template-color-26 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #7BA100; }

body.template-color-26 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-26 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #7BA100; }

body.template-color-26 .mini-cart .shopping-cart {
  border-bottom: 2px solid #7BA100; }
  body.template-color-26 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #7BA100; }
  body.template-color-26 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #7BA100; }
  body.template-color-26 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #7BA100;
    background-color: #7BA100; }

body.template-color-26 .brook__toolbar .inner a:hover {
  color: #7BA100; }

body.template-color-26 .blog-grid .post-inner h5.heading:hover {
  color: #7BA100; }
  body.template-color-26 .blog-grid .post-inner h5.heading:hover a {
    color: #7BA100; }

body.template-color-26 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #7BA100; }

body.template-color-26 .theme-color {
  color: #7BA100; }

body.template-color-26 .bg_color--2 {
  background: #7BA100; }

body.template-color-26 a.brook-btn.bk-btn-theme,
body.template-color-26 button.brook-btn.bk-btn-theme {
  background: #7BA100;
  color: #fff; }

body.template-color-26 .btn-essential:hover {
  background: #7BA100 !important;
  border-color: #7BA100 !important;
  color: #fff; }

body.template-color-26 .red-border {
  border-color: #7BA100 !important; }

body.template-color-26 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #7BA100;
  color: #fff; }

body.template-color-26 .single-svg-icon-box .svg-icon svg * {
  stroke: #7BA100 !important; }

body.template-color-26 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-26 .menu-price {
  color: #7BA100; }

body.template-color-26 a.moredetails-btn:hover {
  color: #7BA100; }

body.template-color-26 a.moredetails-btn i {
  color: #7BA100; }

body.template-color-26 .messonry-button button.is-checked span.filter-text {
  color: #7BA100; }

body.template-color-26 .messonry-button button:hover span.filter-text {
  color: #7BA100; }

body.template-color-26 .messonry-button button span.filter-counter {
  background-color: #7BA100; }
  body.template-color-26 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #7BA100;
    border-top-color: #7BA100; }

body.template-color-26 .messonry-button button:hover span.filter-text {
  color: #7BA100; }

body.template-color-26 .messonry-button button.is-checked span.filter-text {
  color: #7BA100; }

body.template-color-26 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #7BA100; }

body.template-color-26 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #7BA100; }

body.template-color-26 .testimonial_style--1 .content .testimonial-quote span {
  color: #7BA100; }

body.template-color-26 .testimonial_style--1:hover {
  background: #7BA100; }
  body.template-color-26 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-26 .contact-form.contact-form--4 .input-box button {
  color: #7BA100; }
  body.template-color-26 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #7BA100; }

body.template-color-26 .contact-form.contact-form--3 .input-box button {
  color: #7BA100; }

body.template-color-26 .animated-dot {
  background-color: #7BA100; }
  body.template-color-26 .animated-dot .middle-dot {
    background-color: #7BA100; }
  body.template-color-26 .animated-dot .signal {
    background-color: #7BA100; }
  body.template-color-26 .animated-dot .signal2 {
    background-color: #7BA100; }

body.template-color-26 .progress-charts .progress .progress-bar {
  background-color: #7BA100; }

body.template-color-26 .brook-counter .icon i {
  color: #7BA100; }

body.template-color-26 .social-share a:hover {
  color: #7BA100; }

body.template-color-26 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #7BA100; }

body.template-color-26 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #7BA100; }

body.template-color-26 .video-btn a.video-icon,
body.template-color-26 .video-btn a .video-icon {
  background: #7BA100; }

body.template-color-26 .video-btn-2 .icon-gradient::before {
  background-color: #7BA100;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #7BA100 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #7BA100 100%);
  background-image: linear-gradient(-133deg, #899664 0, #7BA100 100%); }

body.template-color-26 .video-btn-2 .icon-gradient::after {
  background-color: #7BA100;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #7BA100 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #7BA100 100%);
  background-image: linear-gradient(-133deg, #899664 0, #7BA100 100%); }

body.template-color-26 .single-motive-speach .icon i {
  color: #7BA100; }

body.template-color-26 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #7BA100; }
  body.template-color-26 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #7BA100; }

body.template-color-26 .contact-form input:focus,
body.template-color-26 .contact-form textarea:focus {
  border-color: #7BA100; }

body.template-color-26 .contact-form input[type="submit"],
body.template-color-26 .contact-form textarea[type="submit"] {
  border-color: #7BA100;
  background-color: #7BA100; }

body.template-color-26 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #7BA100;
  color: #fff;
  border-color: #7BA100; }

body.template-color-26 .gallery .hover-overlay {
  background-color: #7BA100; }

body.template-color-26 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #7BA100; }

body.template-color-26 .blog-grid .post-content::before {
  background-color: #7BA100; }

body.template-color-26 .blog-grid-no-flex .post-quote::before {
  color: #7BA100; }

body.template-color-26 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #7BA100; }
  body.template-color-26 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #7BA100; }

body.template-color-26 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #7BA100; }

body.template-color-26 .bl-sidebar.search button:hover {
  background-color: #7BA100; }

body.template-color-26 .bl-sidebar.search button span {
  color: #7BA100; }

body.template-color-26 .bl-sidebar.category .category-list li a:hover {
  color: #7BA100; }

body.template-color-26 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #7BA100; }

body.template-color-26 .social-icon.icon-solid-rounded li a:hover {
  background-color: #7BA100; }

body.template-color-26 .icon-box .inner .icon {
  color: #7BA100; }

body.template-color-26 .basic-thick-line-theme-4 {
  border-top: 4px solid #7BA100; }

body.template-color-26 .procing-box .header .feature-mark {
  background-color: #7BA100; }

body.template-color-26 a.brook-btn.bk-btn-dark:hover {
  background: #7BA100; }

body.template-color-26 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #7BA100; }

body.template-color-26 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #7BA100; }

body.template-color-26 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #7BA100 !important; }

body.template-color-26 .basic-modern-dots .dot {
  background-color: #7BA100; }

body.template-color-26 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #7BA100; }

body.template-color-26 a.brook-btn.bk-btn-theme-border,
body.template-color-26 button.brook-btn.bk-btn-theme-border {
  border-color: #7BA100;
  color: #7BA100; }
  body.template-color-26 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-26 button.brook-btn.bk-btn-theme-border:hover {
    background: #7BA100;
    color: #ffffff; }

body.template-color-26 .brook-quote::before {
  color: #7BA100; }

body.template-color-26 .bk-text-box.bg-theme-color {
  background: #7BA100; }

body.template-color-26 .bk-list--2 .list-header.with-number--theme .marker {
  color: #7BA100; }

body.template-color-26 .view-more-btn a span.btn-arrow {
  color: #7BA100; }

body.template-color-26 #fp-nav ul li a.active {
  background-color: #7BA100; }

body.template-color-27 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #14359E; }

body.template-color-27 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #14359E; }

body.template-color-27 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #14359E; }

body.template-color-27 .faq-content h2 {
  color: #14359E; }

body.template-color-27 .brook-section-title-corona h2 {
  color: #14359E; }

body.template-color-27 .single-contagion .contagion-content h3 {
  color: #14359E; }

body.template-color-27 .single-sympotom .sympotom-content h3 {
  color: #14359E; }

body.template-color-27 .single-prevination .prevination-content h3 {
  color: #14359E; }

body.template-color-27 .service.service--1.theme-color {
  background: #14359E; }

body.template-color-27 .blog-grid-minimal.outline.bg-transparant {
  border-color: #14359E; }
  body.template-color-27 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #14359E; }

body.template-color-27 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-27 .single-svg-icon-box.business-icon-box:hover {
  background: #14359E; }

body.template-color-27 .product_review .description_nav a.active, body.template-color-27 .product_review .description_nav a:hover {
  border-bottom: 3px solid #14359E; }

body.template-color-27 .single-product-details-side .product-action a {
  border-color: #14359E;
  background-color: #14359E; }

body.template-color-27 .cart-table td.pro-subtotal span {
  color: #14359E; }

body.template-color-27 .cart-table td.pro-addtocart button {
  border: 1px solid #14359E;
  background-color: #14359E; }

body.template-color-27 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #14359E; }

body.template-color-27 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #14359E; }

body.template-color-27 .slider-business .video-btn a .video-icon.secondary-color {
  background: #14359E !important; }

body.template-color-27 a.brook-btn.bk-btn-yellow, body.template-color-27 button.brook-btn.bk-btn-yellow {
  background: #14359E;
  color: #fff !important; }

body.template-color-27 a.bk-btn.theme-btn, body.template-color-27 button.bk-btn.theme-btn {
  background-color: #14359E; }

body.template-color-27 a.brook-btn.bk-btn-red, body.template-color-27 button.brook-btn.bk-btn-red {
  background: #14359E; }

body.template-color-27 .section-title h3 {
  color: #14359E; }

body.template-color-27 .service.service--1:hover::before {
  border-color: #14359E; }

body.template-color-27 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-27 .service.service--1 .icons i {
    color: #14359E;
    font-size: 80px; }

body.template-color-27 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #14359E;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-27 .service.service--1 .content a.service-btn:hover {
  color: #14359E; }
  body.template-color-27 .service.service--1 .content a.service-btn:hover i {
    color: #14359E; }

body.template-color-27 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-27 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-27 .service.service--3 .content h3 a:hover {
  color: #14359E; }

body.template-color-27 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-27 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-27 .title-separator.color-red::after, body.template-color-27 .title-separator.color-blue::after {
    background: #14359E; }

body.template-color-27 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-27 .bk-gradation .item-grid .dot-wrap .dot {
    background: #14359E; }
  body.template-color-27 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #14359E; }
  body.template-color-27 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #14359E; }
  body.template-color-27 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #14359E; }

body.template-color-27 .post-nav-lisnt .nav-item a:hover {
  background: #14359E; }

body.template-color-27 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #14359E; }

body.template-color-27 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #14359E; }

body.template-color-27 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #14359E; }

body.template-color-27 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #14359E; }

body.template-color-27 .team.team__style--2 .thumb .overlay {
  background-color: #14359E; }
  body.template-color-27 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #14359E; }

body.template-color-27 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-27 .team.team__style--3 .thumb .overlay {
  background-color: #14359E; }
  body.template-color-27 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #14359E; }

body.template-color-27 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-27 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #14359E; }
  body.template-color-27 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #14359E; }
  body.template-color-27 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #14359E; }
  body.template-color-27 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-27 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #14359E; }
  body.template-color-27 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #14359E;
    color: #fff; }

body.template-color-27 [data-overlay]:before {
  background-color: #14359E; }

body.template-color-27 .team-heading .heading-right button i {
  color: #14359E; }

body.template-color-27 .team-heading .heading-right button:hover {
  color: #14359E !important; }

body.template-color-27 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #14359E; }

body.template-color-27 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-27 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #14359E; }

body.template-color-27 .mini-cart .shopping-cart {
  border-bottom: 2px solid #14359E; }
  body.template-color-27 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #14359E; }
  body.template-color-27 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #14359E; }
  body.template-color-27 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #14359E;
    background-color: #14359E; }

body.template-color-27 .brook__toolbar .inner a:hover {
  color: #14359E; }

body.template-color-27 .blog-grid .post-inner h5.heading:hover {
  color: #14359E; }
  body.template-color-27 .blog-grid .post-inner h5.heading:hover a {
    color: #14359E; }

body.template-color-27 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #14359E; }

body.template-color-27 .theme-color {
  color: #14359E; }

body.template-color-27 .bg_color--2 {
  background: #14359E; }

body.template-color-27 a.brook-btn.bk-btn-theme,
body.template-color-27 button.brook-btn.bk-btn-theme {
  background: #14359E;
  color: #fff; }

body.template-color-27 .btn-essential:hover {
  background: #14359E !important;
  border-color: #14359E !important;
  color: #fff; }

body.template-color-27 .red-border {
  border-color: #14359E !important; }

body.template-color-27 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #14359E;
  color: #fff; }

body.template-color-27 .single-svg-icon-box .svg-icon svg * {
  stroke: #14359E !important; }

body.template-color-27 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-27 .menu-price {
  color: #14359E; }

body.template-color-27 a.moredetails-btn:hover {
  color: #14359E; }

body.template-color-27 a.moredetails-btn i {
  color: #14359E; }

body.template-color-27 .messonry-button button.is-checked span.filter-text {
  color: #14359E; }

body.template-color-27 .messonry-button button:hover span.filter-text {
  color: #14359E; }

body.template-color-27 .messonry-button button span.filter-counter {
  background-color: #14359E; }
  body.template-color-27 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #14359E;
    border-top-color: #14359E; }

body.template-color-27 .messonry-button button:hover span.filter-text {
  color: #14359E; }

body.template-color-27 .messonry-button button.is-checked span.filter-text {
  color: #14359E; }

body.template-color-27 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #14359E; }

body.template-color-27 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #14359E; }

body.template-color-27 .testimonial_style--1 .content .testimonial-quote span {
  color: #14359E; }

body.template-color-27 .testimonial_style--1:hover {
  background: #14359E; }
  body.template-color-27 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-27 .contact-form.contact-form--4 .input-box button {
  color: #14359E; }
  body.template-color-27 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #14359E; }

body.template-color-27 .contact-form.contact-form--3 .input-box button {
  color: #14359E; }

body.template-color-27 .animated-dot {
  background-color: #14359E; }
  body.template-color-27 .animated-dot .middle-dot {
    background-color: #14359E; }
  body.template-color-27 .animated-dot .signal {
    background-color: #14359E; }
  body.template-color-27 .animated-dot .signal2 {
    background-color: #14359E; }

body.template-color-27 .progress-charts .progress .progress-bar {
  background-color: #14359E; }

body.template-color-27 .brook-counter .icon i {
  color: #14359E; }

body.template-color-27 .social-share a:hover {
  color: #14359E; }

body.template-color-27 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #14359E; }

body.template-color-27 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #14359E; }

body.template-color-27 .video-btn a.video-icon,
body.template-color-27 .video-btn a .video-icon {
  background: #14359E; }

body.template-color-27 .video-btn-2 .icon-gradient::before {
  background-color: #14359E;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #14359E 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #14359E 100%);
  background-image: linear-gradient(-133deg, #899664 0, #14359E 100%); }

body.template-color-27 .video-btn-2 .icon-gradient::after {
  background-color: #14359E;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #14359E 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #14359E 100%);
  background-image: linear-gradient(-133deg, #899664 0, #14359E 100%); }

body.template-color-27 .single-motive-speach .icon i {
  color: #14359E; }

body.template-color-27 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #14359E; }
  body.template-color-27 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #14359E; }

body.template-color-27 .contact-form input:focus,
body.template-color-27 .contact-form textarea:focus {
  border-color: #14359E; }

body.template-color-27 .contact-form input[type="submit"],
body.template-color-27 .contact-form textarea[type="submit"] {
  border-color: #14359E;
  background-color: #14359E; }

body.template-color-27 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #14359E;
  color: #fff;
  border-color: #14359E; }

body.template-color-27 .gallery .hover-overlay {
  background-color: #14359E; }

body.template-color-27 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #14359E; }

body.template-color-27 .blog-grid .post-content::before {
  background-color: #14359E; }

body.template-color-27 .blog-grid-no-flex .post-quote::before {
  color: #14359E; }

body.template-color-27 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #14359E; }
  body.template-color-27 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #14359E; }

body.template-color-27 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #14359E; }

body.template-color-27 .bl-sidebar.search button:hover {
  background-color: #14359E; }

body.template-color-27 .bl-sidebar.search button span {
  color: #14359E; }

body.template-color-27 .bl-sidebar.category .category-list li a:hover {
  color: #14359E; }

body.template-color-27 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #14359E; }

body.template-color-27 .social-icon.icon-solid-rounded li a:hover {
  background-color: #14359E; }

body.template-color-27 .icon-box .inner .icon {
  color: #14359E; }

body.template-color-27 .basic-thick-line-theme-4 {
  border-top: 4px solid #14359E; }

body.template-color-27 .procing-box .header .feature-mark {
  background-color: #14359E; }

body.template-color-27 a.brook-btn.bk-btn-dark:hover {
  background: #14359E; }

body.template-color-27 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #14359E; }

body.template-color-27 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #14359E; }

body.template-color-27 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #14359E !important; }

body.template-color-27 .basic-modern-dots .dot {
  background-color: #14359E; }

body.template-color-27 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #14359E; }

body.template-color-27 a.brook-btn.bk-btn-theme-border,
body.template-color-27 button.brook-btn.bk-btn-theme-border {
  border-color: #14359E;
  color: #14359E; }
  body.template-color-27 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-27 button.brook-btn.bk-btn-theme-border:hover {
    background: #14359E;
    color: #ffffff; }

body.template-color-27 .brook-quote::before {
  color: #14359E; }

body.template-color-27 .bk-text-box.bg-theme-color {
  background: #14359E; }

body.template-color-27 .bk-list--2 .list-header.with-number--theme .marker {
  color: #14359E; }

body.template-color-27 .view-more-btn a span.btn-arrow {
  color: #14359E; }

body.template-color-27 #fp-nav ul li a.active {
  background-color: #14359E; }

body.template-color-28 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #CC327B; }

body.template-color-28 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #CC327B; }

body.template-color-28 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #CC327B; }

body.template-color-28 .faq-content h2 {
  color: #CC327B; }

body.template-color-28 .brook-section-title-corona h2 {
  color: #CC327B; }

body.template-color-28 .single-contagion .contagion-content h3 {
  color: #CC327B; }

body.template-color-28 .single-sympotom .sympotom-content h3 {
  color: #CC327B; }

body.template-color-28 .single-prevination .prevination-content h3 {
  color: #CC327B; }

body.template-color-28 .service.service--1.theme-color {
  background: #CC327B; }

body.template-color-28 .blog-grid-minimal.outline.bg-transparant {
  border-color: #CC327B; }
  body.template-color-28 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #CC327B; }

body.template-color-28 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-28 .single-svg-icon-box.business-icon-box:hover {
  background: #CC327B; }

body.template-color-28 .product_review .description_nav a.active, body.template-color-28 .product_review .description_nav a:hover {
  border-bottom: 3px solid #CC327B; }

body.template-color-28 .single-product-details-side .product-action a {
  border-color: #CC327B;
  background-color: #CC327B; }

body.template-color-28 .cart-table td.pro-subtotal span {
  color: #CC327B; }

body.template-color-28 .cart-table td.pro-addtocart button {
  border: 1px solid #CC327B;
  background-color: #CC327B; }

body.template-color-28 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #CC327B; }

body.template-color-28 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #CC327B; }

body.template-color-28 .slider-business .video-btn a .video-icon.secondary-color {
  background: #CC327B !important; }

body.template-color-28 a.brook-btn.bk-btn-yellow, body.template-color-28 button.brook-btn.bk-btn-yellow {
  background: #CC327B;
  color: #fff !important; }

body.template-color-28 a.bk-btn.theme-btn, body.template-color-28 button.bk-btn.theme-btn {
  background-color: #CC327B; }

body.template-color-28 a.brook-btn.bk-btn-red, body.template-color-28 button.brook-btn.bk-btn-red {
  background: #CC327B; }

body.template-color-28 .section-title h3 {
  color: #CC327B; }

body.template-color-28 .service.service--1:hover::before {
  border-color: #CC327B; }

body.template-color-28 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-28 .service.service--1 .icons i {
    color: #CC327B;
    font-size: 80px; }

body.template-color-28 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #CC327B;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-28 .service.service--1 .content a.service-btn:hover {
  color: #CC327B; }
  body.template-color-28 .service.service--1 .content a.service-btn:hover i {
    color: #CC327B; }

body.template-color-28 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-28 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-28 .service.service--3 .content h3 a:hover {
  color: #CC327B; }

body.template-color-28 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-28 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-28 .title-separator.color-red::after, body.template-color-28 .title-separator.color-blue::after {
    background: #CC327B; }

body.template-color-28 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-28 .bk-gradation .item-grid .dot-wrap .dot {
    background: #CC327B; }
  body.template-color-28 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #CC327B; }
  body.template-color-28 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #CC327B; }
  body.template-color-28 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #CC327B; }

body.template-color-28 .post-nav-lisnt .nav-item a:hover {
  background: #CC327B; }

body.template-color-28 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #CC327B; }

body.template-color-28 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #CC327B; }

body.template-color-28 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #CC327B; }

body.template-color-28 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #CC327B; }

body.template-color-28 .team.team__style--2 .thumb .overlay {
  background-color: #CC327B; }
  body.template-color-28 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #CC327B; }

body.template-color-28 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-28 .team.team__style--3 .thumb .overlay {
  background-color: #CC327B; }
  body.template-color-28 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #CC327B; }

body.template-color-28 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-28 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #CC327B; }
  body.template-color-28 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #CC327B; }
  body.template-color-28 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #CC327B; }
  body.template-color-28 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-28 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #CC327B; }
  body.template-color-28 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #CC327B;
    color: #fff; }

body.template-color-28 [data-overlay]:before {
  background-color: #CC327B; }

body.template-color-28 .team-heading .heading-right button i {
  color: #CC327B; }

body.template-color-28 .team-heading .heading-right button:hover {
  color: #CC327B !important; }

body.template-color-28 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #CC327B; }

body.template-color-28 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-28 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #CC327B; }

body.template-color-28 .mini-cart .shopping-cart {
  border-bottom: 2px solid #CC327B; }
  body.template-color-28 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #CC327B; }
  body.template-color-28 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #CC327B; }
  body.template-color-28 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #CC327B;
    background-color: #CC327B; }

body.template-color-28 .brook__toolbar .inner a:hover {
  color: #CC327B; }

body.template-color-28 .blog-grid .post-inner h5.heading:hover {
  color: #CC327B; }
  body.template-color-28 .blog-grid .post-inner h5.heading:hover a {
    color: #CC327B; }

body.template-color-28 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #CC327B; }

body.template-color-28 .theme-color {
  color: #CC327B; }

body.template-color-28 .bg_color--2 {
  background: #CC327B; }

body.template-color-28 a.brook-btn.bk-btn-theme,
body.template-color-28 button.brook-btn.bk-btn-theme {
  background: #CC327B;
  color: #fff; }

body.template-color-28 .btn-essential:hover {
  background: #CC327B !important;
  border-color: #CC327B !important;
  color: #fff; }

body.template-color-28 .red-border {
  border-color: #CC327B !important; }

body.template-color-28 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #CC327B;
  color: #fff; }

body.template-color-28 .single-svg-icon-box .svg-icon svg * {
  stroke: #CC327B !important; }

body.template-color-28 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-28 .menu-price {
  color: #CC327B; }

body.template-color-28 a.moredetails-btn:hover {
  color: #CC327B; }

body.template-color-28 a.moredetails-btn i {
  color: #CC327B; }

body.template-color-28 .messonry-button button.is-checked span.filter-text {
  color: #CC327B; }

body.template-color-28 .messonry-button button:hover span.filter-text {
  color: #CC327B; }

body.template-color-28 .messonry-button button span.filter-counter {
  background-color: #CC327B; }
  body.template-color-28 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #CC327B;
    border-top-color: #CC327B; }

body.template-color-28 .messonry-button button:hover span.filter-text {
  color: #CC327B; }

body.template-color-28 .messonry-button button.is-checked span.filter-text {
  color: #CC327B; }

body.template-color-28 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #CC327B; }

body.template-color-28 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #CC327B; }

body.template-color-28 .testimonial_style--1 .content .testimonial-quote span {
  color: #CC327B; }

body.template-color-28 .testimonial_style--1:hover {
  background: #CC327B; }
  body.template-color-28 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-28 .contact-form.contact-form--4 .input-box button {
  color: #CC327B; }
  body.template-color-28 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #CC327B; }

body.template-color-28 .contact-form.contact-form--3 .input-box button {
  color: #CC327B; }

body.template-color-28 .animated-dot {
  background-color: #CC327B; }
  body.template-color-28 .animated-dot .middle-dot {
    background-color: #CC327B; }
  body.template-color-28 .animated-dot .signal {
    background-color: #CC327B; }
  body.template-color-28 .animated-dot .signal2 {
    background-color: #CC327B; }

body.template-color-28 .progress-charts .progress .progress-bar {
  background-color: #CC327B; }

body.template-color-28 .brook-counter .icon i {
  color: #CC327B; }

body.template-color-28 .social-share a:hover {
  color: #CC327B; }

body.template-color-28 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #CC327B; }

body.template-color-28 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #CC327B; }

body.template-color-28 .video-btn a.video-icon,
body.template-color-28 .video-btn a .video-icon {
  background: #CC327B; }

body.template-color-28 .video-btn-2 .icon-gradient::before {
  background-color: #CC327B;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #CC327B 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #CC327B 100%);
  background-image: linear-gradient(-133deg, #899664 0, #CC327B 100%); }

body.template-color-28 .video-btn-2 .icon-gradient::after {
  background-color: #CC327B;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #CC327B 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #CC327B 100%);
  background-image: linear-gradient(-133deg, #899664 0, #CC327B 100%); }

body.template-color-28 .single-motive-speach .icon i {
  color: #CC327B; }

body.template-color-28 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #CC327B; }
  body.template-color-28 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #CC327B; }

body.template-color-28 .contact-form input:focus,
body.template-color-28 .contact-form textarea:focus {
  border-color: #CC327B; }

body.template-color-28 .contact-form input[type="submit"],
body.template-color-28 .contact-form textarea[type="submit"] {
  border-color: #CC327B;
  background-color: #CC327B; }

body.template-color-28 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #CC327B;
  color: #fff;
  border-color: #CC327B; }

body.template-color-28 .gallery .hover-overlay {
  background-color: #CC327B; }

body.template-color-28 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #CC327B; }

body.template-color-28 .blog-grid .post-content::before {
  background-color: #CC327B; }

body.template-color-28 .blog-grid-no-flex .post-quote::before {
  color: #CC327B; }

body.template-color-28 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #CC327B; }
  body.template-color-28 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #CC327B; }

body.template-color-28 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #CC327B; }

body.template-color-28 .bl-sidebar.search button:hover {
  background-color: #CC327B; }

body.template-color-28 .bl-sidebar.search button span {
  color: #CC327B; }

body.template-color-28 .bl-sidebar.category .category-list li a:hover {
  color: #CC327B; }

body.template-color-28 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #CC327B; }

body.template-color-28 .social-icon.icon-solid-rounded li a:hover {
  background-color: #CC327B; }

body.template-color-28 .icon-box .inner .icon {
  color: #CC327B; }

body.template-color-28 .basic-thick-line-theme-4 {
  border-top: 4px solid #CC327B; }

body.template-color-28 .procing-box .header .feature-mark {
  background-color: #CC327B; }

body.template-color-28 a.brook-btn.bk-btn-dark:hover {
  background: #CC327B; }

body.template-color-28 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #CC327B; }

body.template-color-28 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #CC327B; }

body.template-color-28 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #CC327B !important; }

body.template-color-28 .basic-modern-dots .dot {
  background-color: #CC327B; }

body.template-color-28 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #CC327B; }

body.template-color-28 a.brook-btn.bk-btn-theme-border,
body.template-color-28 button.brook-btn.bk-btn-theme-border {
  border-color: #CC327B;
  color: #CC327B; }
  body.template-color-28 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-28 button.brook-btn.bk-btn-theme-border:hover {
    background: #CC327B;
    color: #ffffff; }

body.template-color-28 .brook-quote::before {
  color: #CC327B; }

body.template-color-28 .bk-text-box.bg-theme-color {
  background: #CC327B; }

body.template-color-28 .bk-list--2 .list-header.with-number--theme .marker {
  color: #CC327B; }

body.template-color-28 .view-more-btn a span.btn-arrow {
  color: #CC327B; }

body.template-color-28 #fp-nav ul li a.active {
  background-color: #CC327B; }

body.template-color-29 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #E23636; }

body.template-color-29 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #E23636; }

body.template-color-29 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #E23636; }

body.template-color-29 .faq-content h2 {
  color: #E23636; }

body.template-color-29 .brook-section-title-corona h2 {
  color: #E23636; }

body.template-color-29 .single-contagion .contagion-content h3 {
  color: #E23636; }

body.template-color-29 .single-sympotom .sympotom-content h3 {
  color: #E23636; }

body.template-color-29 .single-prevination .prevination-content h3 {
  color: #E23636; }

body.template-color-29 .service.service--1.theme-color {
  background: #E23636; }

body.template-color-29 .blog-grid-minimal.outline.bg-transparant {
  border-color: #E23636; }
  body.template-color-29 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #E23636; }

body.template-color-29 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-29 .single-svg-icon-box.business-icon-box:hover {
  background: #E23636; }

body.template-color-29 .product_review .description_nav a.active, body.template-color-29 .product_review .description_nav a:hover {
  border-bottom: 3px solid #E23636; }

body.template-color-29 .single-product-details-side .product-action a {
  border-color: #E23636;
  background-color: #E23636; }

body.template-color-29 .cart-table td.pro-subtotal span {
  color: #E23636; }

body.template-color-29 .cart-table td.pro-addtocart button {
  border: 1px solid #E23636;
  background-color: #E23636; }

body.template-color-29 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #E23636; }

body.template-color-29 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #E23636; }

body.template-color-29 .slider-business .video-btn a .video-icon.secondary-color {
  background: #E23636 !important; }

body.template-color-29 a.brook-btn.bk-btn-yellow, body.template-color-29 button.brook-btn.bk-btn-yellow {
  background: #E23636;
  color: #fff !important; }

body.template-color-29 a.bk-btn.theme-btn, body.template-color-29 button.bk-btn.theme-btn {
  background-color: #E23636; }

body.template-color-29 a.brook-btn.bk-btn-red, body.template-color-29 button.brook-btn.bk-btn-red {
  background: #E23636; }

body.template-color-29 .section-title h3 {
  color: #E23636; }

body.template-color-29 .service.service--1:hover::before {
  border-color: #E23636; }

body.template-color-29 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-29 .service.service--1 .icons i {
    color: #E23636;
    font-size: 80px; }

body.template-color-29 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #E23636;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-29 .service.service--1 .content a.service-btn:hover {
  color: #E23636; }
  body.template-color-29 .service.service--1 .content a.service-btn:hover i {
    color: #E23636; }

body.template-color-29 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-29 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-29 .service.service--3 .content h3 a:hover {
  color: #E23636; }

body.template-color-29 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-29 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-29 .title-separator.color-red::after, body.template-color-29 .title-separator.color-blue::after {
    background: #E23636; }

body.template-color-29 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-29 .bk-gradation .item-grid .dot-wrap .dot {
    background: #E23636; }
  body.template-color-29 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #E23636; }
  body.template-color-29 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #E23636; }
  body.template-color-29 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #E23636; }

body.template-color-29 .post-nav-lisnt .nav-item a:hover {
  background: #E23636; }

body.template-color-29 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #E23636; }

body.template-color-29 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #E23636; }

body.template-color-29 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #E23636; }

body.template-color-29 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #E23636; }

body.template-color-29 .team.team__style--2 .thumb .overlay {
  background-color: #E23636; }
  body.template-color-29 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #E23636; }

body.template-color-29 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-29 .team.team__style--3 .thumb .overlay {
  background-color: #E23636; }
  body.template-color-29 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #E23636; }

body.template-color-29 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-29 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #E23636; }
  body.template-color-29 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #E23636; }
  body.template-color-29 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #E23636; }
  body.template-color-29 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-29 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #E23636; }
  body.template-color-29 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #E23636;
    color: #fff; }

body.template-color-29 [data-overlay]:before {
  background-color: #E23636; }

body.template-color-29 .team-heading .heading-right button i {
  color: #E23636; }

body.template-color-29 .team-heading .heading-right button:hover {
  color: #E23636 !important; }

body.template-color-29 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #E23636; }

body.template-color-29 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-29 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #E23636; }

body.template-color-29 .mini-cart .shopping-cart {
  border-bottom: 2px solid #E23636; }
  body.template-color-29 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #E23636; }
  body.template-color-29 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #E23636; }
  body.template-color-29 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #E23636;
    background-color: #E23636; }

body.template-color-29 .brook__toolbar .inner a:hover {
  color: #E23636; }

body.template-color-29 .blog-grid .post-inner h5.heading:hover {
  color: #E23636; }
  body.template-color-29 .blog-grid .post-inner h5.heading:hover a {
    color: #E23636; }

body.template-color-29 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #E23636; }

body.template-color-29 .theme-color {
  color: #E23636; }

body.template-color-29 .bg_color--2 {
  background: #E23636; }

body.template-color-29 a.brook-btn.bk-btn-theme,
body.template-color-29 button.brook-btn.bk-btn-theme {
  background: #E23636;
  color: #fff; }

body.template-color-29 .btn-essential:hover {
  background: #E23636 !important;
  border-color: #E23636 !important;
  color: #fff; }

body.template-color-29 .red-border {
  border-color: #E23636 !important; }

body.template-color-29 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #E23636;
  color: #fff; }

body.template-color-29 .single-svg-icon-box .svg-icon svg * {
  stroke: #E23636 !important; }

body.template-color-29 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-29 .menu-price {
  color: #E23636; }

body.template-color-29 a.moredetails-btn:hover {
  color: #E23636; }

body.template-color-29 a.moredetails-btn i {
  color: #E23636; }

body.template-color-29 .messonry-button button.is-checked span.filter-text {
  color: #E23636; }

body.template-color-29 .messonry-button button:hover span.filter-text {
  color: #E23636; }

body.template-color-29 .messonry-button button span.filter-counter {
  background-color: #E23636; }
  body.template-color-29 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #E23636;
    border-top-color: #E23636; }

body.template-color-29 .messonry-button button:hover span.filter-text {
  color: #E23636; }

body.template-color-29 .messonry-button button.is-checked span.filter-text {
  color: #E23636; }

body.template-color-29 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #E23636; }

body.template-color-29 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #E23636; }

body.template-color-29 .testimonial_style--1 .content .testimonial-quote span {
  color: #E23636; }

body.template-color-29 .testimonial_style--1:hover {
  background: #E23636; }
  body.template-color-29 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-29 .contact-form.contact-form--4 .input-box button {
  color: #E23636; }
  body.template-color-29 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #E23636; }

body.template-color-29 .contact-form.contact-form--3 .input-box button {
  color: #E23636; }

body.template-color-29 .animated-dot {
  background-color: #E23636; }
  body.template-color-29 .animated-dot .middle-dot {
    background-color: #E23636; }
  body.template-color-29 .animated-dot .signal {
    background-color: #E23636; }
  body.template-color-29 .animated-dot .signal2 {
    background-color: #E23636; }

body.template-color-29 .progress-charts .progress .progress-bar {
  background-color: #E23636; }

body.template-color-29 .brook-counter .icon i {
  color: #E23636; }

body.template-color-29 .social-share a:hover {
  color: #E23636; }

body.template-color-29 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #E23636; }

body.template-color-29 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #E23636; }

body.template-color-29 .video-btn a.video-icon,
body.template-color-29 .video-btn a .video-icon {
  background: #E23636; }

body.template-color-29 .video-btn-2 .icon-gradient::before {
  background-color: #E23636;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #E23636 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #E23636 100%);
  background-image: linear-gradient(-133deg, #899664 0, #E23636 100%); }

body.template-color-29 .video-btn-2 .icon-gradient::after {
  background-color: #E23636;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #E23636 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #E23636 100%);
  background-image: linear-gradient(-133deg, #899664 0, #E23636 100%); }

body.template-color-29 .single-motive-speach .icon i {
  color: #E23636; }

body.template-color-29 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #E23636; }
  body.template-color-29 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #E23636; }

body.template-color-29 .contact-form input:focus,
body.template-color-29 .contact-form textarea:focus {
  border-color: #E23636; }

body.template-color-29 .contact-form input[type="submit"],
body.template-color-29 .contact-form textarea[type="submit"] {
  border-color: #E23636;
  background-color: #E23636; }

body.template-color-29 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #E23636;
  color: #fff;
  border-color: #E23636; }

body.template-color-29 .gallery .hover-overlay {
  background-color: #E23636; }

body.template-color-29 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #E23636; }

body.template-color-29 .blog-grid .post-content::before {
  background-color: #E23636; }

body.template-color-29 .blog-grid-no-flex .post-quote::before {
  color: #E23636; }

body.template-color-29 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #E23636; }
  body.template-color-29 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #E23636; }

body.template-color-29 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #E23636; }

body.template-color-29 .bl-sidebar.search button:hover {
  background-color: #E23636; }

body.template-color-29 .bl-sidebar.search button span {
  color: #E23636; }

body.template-color-29 .bl-sidebar.category .category-list li a:hover {
  color: #E23636; }

body.template-color-29 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #E23636; }

body.template-color-29 .social-icon.icon-solid-rounded li a:hover {
  background-color: #E23636; }

body.template-color-29 .icon-box .inner .icon {
  color: #E23636; }

body.template-color-29 .basic-thick-line-theme-4 {
  border-top: 4px solid #E23636; }

body.template-color-29 .procing-box .header .feature-mark {
  background-color: #E23636; }

body.template-color-29 a.brook-btn.bk-btn-dark:hover {
  background: #E23636; }

body.template-color-29 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #E23636; }

body.template-color-29 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #E23636; }

body.template-color-29 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #E23636 !important; }

body.template-color-29 .basic-modern-dots .dot {
  background-color: #E23636; }

body.template-color-29 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #E23636; }

body.template-color-29 a.brook-btn.bk-btn-theme-border,
body.template-color-29 button.brook-btn.bk-btn-theme-border {
  border-color: #E23636;
  color: #E23636; }
  body.template-color-29 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-29 button.brook-btn.bk-btn-theme-border:hover {
    background: #E23636;
    color: #ffffff; }

body.template-color-29 .brook-quote::before {
  color: #E23636; }

body.template-color-29 .bk-text-box.bg-theme-color {
  background: #E23636; }

body.template-color-29 .bk-list--2 .list-header.with-number--theme .marker {
  color: #E23636; }

body.template-color-29 .view-more-btn a span.btn-arrow {
  color: #E23636; }

body.template-color-29 #fp-nav ul li a.active {
  background-color: #E23636; }

body.template-color-30 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #FB6031; }

body.template-color-30 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #FB6031; }

body.template-color-30 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #FB6031; }

body.template-color-30 .faq-content h2 {
  color: #FB6031; }

body.template-color-30 .brook-section-title-corona h2 {
  color: #FB6031; }

body.template-color-30 .single-contagion .contagion-content h3 {
  color: #FB6031; }

body.template-color-30 .single-sympotom .sympotom-content h3 {
  color: #FB6031; }

body.template-color-30 .single-prevination .prevination-content h3 {
  color: #FB6031; }

body.template-color-30 .service.service--1.theme-color {
  background: #FB6031; }

body.template-color-30 .blog-grid-minimal.outline.bg-transparant {
  border-color: #FB6031; }
  body.template-color-30 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #FB6031; }

body.template-color-30 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-30 .single-svg-icon-box.business-icon-box:hover {
  background: #FB6031; }

body.template-color-30 .product_review .description_nav a.active, body.template-color-30 .product_review .description_nav a:hover {
  border-bottom: 3px solid #FB6031; }

body.template-color-30 .single-product-details-side .product-action a {
  border-color: #FB6031;
  background-color: #FB6031; }

body.template-color-30 .cart-table td.pro-subtotal span {
  color: #FB6031; }

body.template-color-30 .cart-table td.pro-addtocart button {
  border: 1px solid #FB6031;
  background-color: #FB6031; }

body.template-color-30 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #FB6031; }

body.template-color-30 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #FB6031; }

body.template-color-30 .slider-business .video-btn a .video-icon.secondary-color {
  background: #FB6031 !important; }

body.template-color-30 a.brook-btn.bk-btn-yellow, body.template-color-30 button.brook-btn.bk-btn-yellow {
  background: #FB6031;
  color: #fff !important; }

body.template-color-30 a.bk-btn.theme-btn, body.template-color-30 button.bk-btn.theme-btn {
  background-color: #FB6031; }

body.template-color-30 a.brook-btn.bk-btn-red, body.template-color-30 button.brook-btn.bk-btn-red {
  background: #FB6031; }

body.template-color-30 .section-title h3 {
  color: #FB6031; }

body.template-color-30 .service.service--1:hover::before {
  border-color: #FB6031; }

body.template-color-30 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-30 .service.service--1 .icons i {
    color: #FB6031;
    font-size: 80px; }

body.template-color-30 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #FB6031;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-30 .service.service--1 .content a.service-btn:hover {
  color: #FB6031; }
  body.template-color-30 .service.service--1 .content a.service-btn:hover i {
    color: #FB6031; }

body.template-color-30 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-30 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-30 .service.service--3 .content h3 a:hover {
  color: #FB6031; }

body.template-color-30 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-30 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-30 .title-separator.color-red::after, body.template-color-30 .title-separator.color-blue::after {
    background: #FB6031; }

body.template-color-30 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-30 .bk-gradation .item-grid .dot-wrap .dot {
    background: #FB6031; }
  body.template-color-30 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #FB6031; }
  body.template-color-30 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #FB6031; }
  body.template-color-30 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #FB6031; }

body.template-color-30 .post-nav-lisnt .nav-item a:hover {
  background: #FB6031; }

body.template-color-30 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #FB6031; }

body.template-color-30 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #FB6031; }

body.template-color-30 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #FB6031; }

body.template-color-30 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #FB6031; }

body.template-color-30 .team.team__style--2 .thumb .overlay {
  background-color: #FB6031; }
  body.template-color-30 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #FB6031; }

body.template-color-30 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-30 .team.team__style--3 .thumb .overlay {
  background-color: #FB6031; }
  body.template-color-30 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #FB6031; }

body.template-color-30 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-30 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #FB6031; }
  body.template-color-30 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #FB6031; }
  body.template-color-30 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #FB6031; }
  body.template-color-30 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-30 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #FB6031; }
  body.template-color-30 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #FB6031;
    color: #fff; }

body.template-color-30 [data-overlay]:before {
  background-color: #FB6031; }

body.template-color-30 .team-heading .heading-right button i {
  color: #FB6031; }

body.template-color-30 .team-heading .heading-right button:hover {
  color: #FB6031 !important; }

body.template-color-30 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #FB6031; }

body.template-color-30 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-30 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #FB6031; }

body.template-color-30 .mini-cart .shopping-cart {
  border-bottom: 2px solid #FB6031; }
  body.template-color-30 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #FB6031; }
  body.template-color-30 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #FB6031; }
  body.template-color-30 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #FB6031;
    background-color: #FB6031; }

body.template-color-30 .brook__toolbar .inner a:hover {
  color: #FB6031; }

body.template-color-30 .blog-grid .post-inner h5.heading:hover {
  color: #FB6031; }
  body.template-color-30 .blog-grid .post-inner h5.heading:hover a {
    color: #FB6031; }

body.template-color-30 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #FB6031; }

body.template-color-30 .theme-color {
  color: #FB6031; }

body.template-color-30 .bg_color--2 {
  background: #FB6031; }

body.template-color-30 a.brook-btn.bk-btn-theme,
body.template-color-30 button.brook-btn.bk-btn-theme {
  background: #FB6031;
  color: #fff; }

body.template-color-30 .btn-essential:hover {
  background: #FB6031 !important;
  border-color: #FB6031 !important;
  color: #fff; }

body.template-color-30 .red-border {
  border-color: #FB6031 !important; }

body.template-color-30 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #FB6031;
  color: #fff; }

body.template-color-30 .single-svg-icon-box .svg-icon svg * {
  stroke: #FB6031 !important; }

body.template-color-30 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-30 .menu-price {
  color: #FB6031; }

body.template-color-30 a.moredetails-btn:hover {
  color: #FB6031; }

body.template-color-30 a.moredetails-btn i {
  color: #FB6031; }

body.template-color-30 .messonry-button button.is-checked span.filter-text {
  color: #FB6031; }

body.template-color-30 .messonry-button button:hover span.filter-text {
  color: #FB6031; }

body.template-color-30 .messonry-button button span.filter-counter {
  background-color: #FB6031; }
  body.template-color-30 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #FB6031;
    border-top-color: #FB6031; }

body.template-color-30 .messonry-button button:hover span.filter-text {
  color: #FB6031; }

body.template-color-30 .messonry-button button.is-checked span.filter-text {
  color: #FB6031; }

body.template-color-30 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #FB6031; }

body.template-color-30 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #FB6031; }

body.template-color-30 .testimonial_style--1 .content .testimonial-quote span {
  color: #FB6031; }

body.template-color-30 .testimonial_style--1:hover {
  background: #FB6031; }
  body.template-color-30 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-30 .contact-form.contact-form--4 .input-box button {
  color: #FB6031; }
  body.template-color-30 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #FB6031; }

body.template-color-30 .contact-form.contact-form--3 .input-box button {
  color: #FB6031; }

body.template-color-30 .animated-dot {
  background-color: #FB6031; }
  body.template-color-30 .animated-dot .middle-dot {
    background-color: #FB6031; }
  body.template-color-30 .animated-dot .signal {
    background-color: #FB6031; }
  body.template-color-30 .animated-dot .signal2 {
    background-color: #FB6031; }

body.template-color-30 .progress-charts .progress .progress-bar {
  background-color: #FB6031; }

body.template-color-30 .brook-counter .icon i {
  color: #FB6031; }

body.template-color-30 .social-share a:hover {
  color: #FB6031; }

body.template-color-30 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #FB6031; }

body.template-color-30 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #FB6031; }

body.template-color-30 .video-btn a.video-icon,
body.template-color-30 .video-btn a .video-icon {
  background: #FB6031; }

body.template-color-30 .video-btn-2 .icon-gradient::before {
  background-color: #FB6031;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FB6031 100%); }

body.template-color-30 .video-btn-2 .icon-gradient::after {
  background-color: #FB6031;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FB6031 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FB6031 100%); }

body.template-color-30 .single-motive-speach .icon i {
  color: #FB6031; }

body.template-color-30 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #FB6031; }
  body.template-color-30 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #FB6031; }

body.template-color-30 .contact-form input:focus,
body.template-color-30 .contact-form textarea:focus {
  border-color: #FB6031; }

body.template-color-30 .contact-form input[type="submit"],
body.template-color-30 .contact-form textarea[type="submit"] {
  border-color: #FB6031;
  background-color: #FB6031; }

body.template-color-30 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #FB6031;
  color: #fff;
  border-color: #FB6031; }

body.template-color-30 .gallery .hover-overlay {
  background-color: #FB6031; }

body.template-color-30 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #FB6031; }

body.template-color-30 .blog-grid .post-content::before {
  background-color: #FB6031; }

body.template-color-30 .blog-grid-no-flex .post-quote::before {
  color: #FB6031; }

body.template-color-30 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #FB6031; }
  body.template-color-30 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #FB6031; }

body.template-color-30 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #FB6031; }

body.template-color-30 .bl-sidebar.search button:hover {
  background-color: #FB6031; }

body.template-color-30 .bl-sidebar.search button span {
  color: #FB6031; }

body.template-color-30 .bl-sidebar.category .category-list li a:hover {
  color: #FB6031; }

body.template-color-30 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #FB6031; }

body.template-color-30 .social-icon.icon-solid-rounded li a:hover {
  background-color: #FB6031; }

body.template-color-30 .icon-box .inner .icon {
  color: #FB6031; }

body.template-color-30 .basic-thick-line-theme-4 {
  border-top: 4px solid #FB6031; }

body.template-color-30 .procing-box .header .feature-mark {
  background-color: #FB6031; }

body.template-color-30 a.brook-btn.bk-btn-dark:hover {
  background: #FB6031; }

body.template-color-30 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #FB6031; }

body.template-color-30 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #FB6031; }

body.template-color-30 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #FB6031 !important; }

body.template-color-30 .basic-modern-dots .dot {
  background-color: #FB6031; }

body.template-color-30 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #FB6031; }

body.template-color-30 a.brook-btn.bk-btn-theme-border,
body.template-color-30 button.brook-btn.bk-btn-theme-border {
  border-color: #FB6031;
  color: #FB6031; }
  body.template-color-30 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-30 button.brook-btn.bk-btn-theme-border:hover {
    background: #FB6031;
    color: #ffffff; }

body.template-color-30 .brook-quote::before {
  color: #FB6031; }

body.template-color-30 .bk-text-box.bg-theme-color {
  background: #FB6031; }

body.template-color-30 .bk-list--2 .list-header.with-number--theme .marker {
  color: #FB6031; }

body.template-color-30 .view-more-btn a span.btn-arrow {
  color: #FB6031; }

body.template-color-30 #fp-nav ul li a.active {
  background-color: #FB6031; }

body.template-color-31 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #906032; }

body.template-color-31 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #906032; }

body.template-color-31 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #906032; }

body.template-color-31 .faq-content h2 {
  color: #906032; }

body.template-color-31 .brook-section-title-corona h2 {
  color: #906032; }

body.template-color-31 .single-contagion .contagion-content h3 {
  color: #906032; }

body.template-color-31 .single-sympotom .sympotom-content h3 {
  color: #906032; }

body.template-color-31 .single-prevination .prevination-content h3 {
  color: #906032; }

body.template-color-31 .service.service--1.theme-color {
  background: #906032; }

body.template-color-31 .blog-grid-minimal.outline.bg-transparant {
  border-color: #906032; }
  body.template-color-31 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #906032; }

body.template-color-31 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-31 .single-svg-icon-box.business-icon-box:hover {
  background: #906032; }

body.template-color-31 .product_review .description_nav a.active, body.template-color-31 .product_review .description_nav a:hover {
  border-bottom: 3px solid #906032; }

body.template-color-31 .single-product-details-side .product-action a {
  border-color: #906032;
  background-color: #906032; }

body.template-color-31 .cart-table td.pro-subtotal span {
  color: #906032; }

body.template-color-31 .cart-table td.pro-addtocart button {
  border: 1px solid #906032;
  background-color: #906032; }

body.template-color-31 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #906032; }

body.template-color-31 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #906032; }

body.template-color-31 .slider-business .video-btn a .video-icon.secondary-color {
  background: #906032 !important; }

body.template-color-31 a.brook-btn.bk-btn-yellow, body.template-color-31 button.brook-btn.bk-btn-yellow {
  background: #906032;
  color: #fff !important; }

body.template-color-31 a.bk-btn.theme-btn, body.template-color-31 button.bk-btn.theme-btn {
  background-color: #906032; }

body.template-color-31 a.brook-btn.bk-btn-red, body.template-color-31 button.brook-btn.bk-btn-red {
  background: #906032; }

body.template-color-31 .section-title h3 {
  color: #906032; }

body.template-color-31 .service.service--1:hover::before {
  border-color: #906032; }

body.template-color-31 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-31 .service.service--1 .icons i {
    color: #906032;
    font-size: 80px; }

body.template-color-31 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #906032;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-31 .service.service--1 .content a.service-btn:hover {
  color: #906032; }
  body.template-color-31 .service.service--1 .content a.service-btn:hover i {
    color: #906032; }

body.template-color-31 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-31 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-31 .service.service--3 .content h3 a:hover {
  color: #906032; }

body.template-color-31 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-31 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-31 .title-separator.color-red::after, body.template-color-31 .title-separator.color-blue::after {
    background: #906032; }

body.template-color-31 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-31 .bk-gradation .item-grid .dot-wrap .dot {
    background: #906032; }
  body.template-color-31 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #906032; }
  body.template-color-31 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #906032; }
  body.template-color-31 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #906032; }

body.template-color-31 .post-nav-lisnt .nav-item a:hover {
  background: #906032; }

body.template-color-31 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #906032; }

body.template-color-31 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #906032; }

body.template-color-31 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #906032; }

body.template-color-31 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #906032; }

body.template-color-31 .team.team__style--2 .thumb .overlay {
  background-color: #906032; }
  body.template-color-31 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #906032; }

body.template-color-31 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-31 .team.team__style--3 .thumb .overlay {
  background-color: #906032; }
  body.template-color-31 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #906032; }

body.template-color-31 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-31 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #906032; }
  body.template-color-31 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #906032; }
  body.template-color-31 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #906032; }
  body.template-color-31 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-31 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #906032; }
  body.template-color-31 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #906032;
    color: #fff; }

body.template-color-31 [data-overlay]:before {
  background-color: #906032; }

body.template-color-31 .team-heading .heading-right button i {
  color: #906032; }

body.template-color-31 .team-heading .heading-right button:hover {
  color: #906032 !important; }

body.template-color-31 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #906032; }

body.template-color-31 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-31 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #906032; }

body.template-color-31 .mini-cart .shopping-cart {
  border-bottom: 2px solid #906032; }
  body.template-color-31 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #906032; }
  body.template-color-31 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #906032; }
  body.template-color-31 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #906032;
    background-color: #906032; }

body.template-color-31 .brook__toolbar .inner a:hover {
  color: #906032; }

body.template-color-31 .blog-grid .post-inner h5.heading:hover {
  color: #906032; }
  body.template-color-31 .blog-grid .post-inner h5.heading:hover a {
    color: #906032; }

body.template-color-31 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #906032; }

body.template-color-31 .theme-color {
  color: #906032; }

body.template-color-31 .bg_color--2 {
  background: #906032; }

body.template-color-31 a.brook-btn.bk-btn-theme,
body.template-color-31 button.brook-btn.bk-btn-theme {
  background: #906032;
  color: #fff; }

body.template-color-31 .btn-essential:hover {
  background: #906032 !important;
  border-color: #906032 !important;
  color: #fff; }

body.template-color-31 .red-border {
  border-color: #906032 !important; }

body.template-color-31 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #906032;
  color: #fff; }

body.template-color-31 .single-svg-icon-box .svg-icon svg * {
  stroke: #906032 !important; }

body.template-color-31 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-31 .menu-price {
  color: #906032; }

body.template-color-31 a.moredetails-btn:hover {
  color: #906032; }

body.template-color-31 a.moredetails-btn i {
  color: #906032; }

body.template-color-31 .messonry-button button.is-checked span.filter-text {
  color: #906032; }

body.template-color-31 .messonry-button button:hover span.filter-text {
  color: #906032; }

body.template-color-31 .messonry-button button span.filter-counter {
  background-color: #906032; }
  body.template-color-31 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #906032;
    border-top-color: #906032; }

body.template-color-31 .messonry-button button:hover span.filter-text {
  color: #906032; }

body.template-color-31 .messonry-button button.is-checked span.filter-text {
  color: #906032; }

body.template-color-31 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #906032; }

body.template-color-31 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #906032; }

body.template-color-31 .testimonial_style--1 .content .testimonial-quote span {
  color: #906032; }

body.template-color-31 .testimonial_style--1:hover {
  background: #906032; }
  body.template-color-31 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-31 .contact-form.contact-form--4 .input-box button {
  color: #906032; }
  body.template-color-31 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #906032; }

body.template-color-31 .contact-form.contact-form--3 .input-box button {
  color: #906032; }

body.template-color-31 .animated-dot {
  background-color: #906032; }
  body.template-color-31 .animated-dot .middle-dot {
    background-color: #906032; }
  body.template-color-31 .animated-dot .signal {
    background-color: #906032; }
  body.template-color-31 .animated-dot .signal2 {
    background-color: #906032; }

body.template-color-31 .progress-charts .progress .progress-bar {
  background-color: #906032; }

body.template-color-31 .brook-counter .icon i {
  color: #906032; }

body.template-color-31 .social-share a:hover {
  color: #906032; }

body.template-color-31 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #906032; }

body.template-color-31 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #906032; }

body.template-color-31 .video-btn a.video-icon,
body.template-color-31 .video-btn a .video-icon {
  background: #906032; }

body.template-color-31 .video-btn-2 .icon-gradient::before {
  background-color: #906032;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #906032 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #906032 100%);
  background-image: linear-gradient(-133deg, #899664 0, #906032 100%); }

body.template-color-31 .video-btn-2 .icon-gradient::after {
  background-color: #906032;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #906032 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #906032 100%);
  background-image: linear-gradient(-133deg, #899664 0, #906032 100%); }

body.template-color-31 .single-motive-speach .icon i {
  color: #906032; }

body.template-color-31 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #906032; }
  body.template-color-31 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #906032; }

body.template-color-31 .contact-form input:focus,
body.template-color-31 .contact-form textarea:focus {
  border-color: #906032; }

body.template-color-31 .contact-form input[type="submit"],
body.template-color-31 .contact-form textarea[type="submit"] {
  border-color: #906032;
  background-color: #906032; }

body.template-color-31 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #906032;
  color: #fff;
  border-color: #906032; }

body.template-color-31 .gallery .hover-overlay {
  background-color: #906032; }

body.template-color-31 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #906032; }

body.template-color-31 .blog-grid .post-content::before {
  background-color: #906032; }

body.template-color-31 .blog-grid-no-flex .post-quote::before {
  color: #906032; }

body.template-color-31 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #906032; }
  body.template-color-31 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #906032; }

body.template-color-31 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #906032; }

body.template-color-31 .bl-sidebar.search button:hover {
  background-color: #906032; }

body.template-color-31 .bl-sidebar.search button span {
  color: #906032; }

body.template-color-31 .bl-sidebar.category .category-list li a:hover {
  color: #906032; }

body.template-color-31 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #906032; }

body.template-color-31 .social-icon.icon-solid-rounded li a:hover {
  background-color: #906032; }

body.template-color-31 .icon-box .inner .icon {
  color: #906032; }

body.template-color-31 .basic-thick-line-theme-4 {
  border-top: 4px solid #906032; }

body.template-color-31 .procing-box .header .feature-mark {
  background-color: #906032; }

body.template-color-31 a.brook-btn.bk-btn-dark:hover {
  background: #906032; }

body.template-color-31 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #906032; }

body.template-color-31 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #906032; }

body.template-color-31 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #906032 !important; }

body.template-color-31 .basic-modern-dots .dot {
  background-color: #906032; }

body.template-color-31 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #906032; }

body.template-color-31 a.brook-btn.bk-btn-theme-border,
body.template-color-31 button.brook-btn.bk-btn-theme-border {
  border-color: #906032;
  color: #906032; }
  body.template-color-31 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-31 button.brook-btn.bk-btn-theme-border:hover {
    background: #906032;
    color: #ffffff; }

body.template-color-31 .brook-quote::before {
  color: #906032; }

body.template-color-31 .bk-text-box.bg-theme-color {
  background: #906032; }

body.template-color-31 .bk-list--2 .list-header.with-number--theme .marker {
  color: #906032; }

body.template-color-31 .view-more-btn a span.btn-arrow {
  color: #906032; }

body.template-color-31 #fp-nav ul li a.active {
  background-color: #906032; }

body.template-color-32 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #EC5B4B; }

body.template-color-32 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #EC5B4B; }

body.template-color-32 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #EC5B4B; }

body.template-color-32 .faq-content h2 {
  color: #EC5B4B; }

body.template-color-32 .brook-section-title-corona h2 {
  color: #EC5B4B; }

body.template-color-32 .single-contagion .contagion-content h3 {
  color: #EC5B4B; }

body.template-color-32 .single-sympotom .sympotom-content h3 {
  color: #EC5B4B; }

body.template-color-32 .single-prevination .prevination-content h3 {
  color: #EC5B4B; }

body.template-color-32 .service.service--1.theme-color {
  background: #EC5B4B; }

body.template-color-32 .blog-grid-minimal.outline.bg-transparant {
  border-color: #EC5B4B; }
  body.template-color-32 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #EC5B4B; }

body.template-color-32 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-32 .single-svg-icon-box.business-icon-box:hover {
  background: #EC5B4B; }

body.template-color-32 .product_review .description_nav a.active, body.template-color-32 .product_review .description_nav a:hover {
  border-bottom: 3px solid #EC5B4B; }

body.template-color-32 .single-product-details-side .product-action a {
  border-color: #EC5B4B;
  background-color: #EC5B4B; }

body.template-color-32 .cart-table td.pro-subtotal span {
  color: #EC5B4B; }

body.template-color-32 .cart-table td.pro-addtocart button {
  border: 1px solid #EC5B4B;
  background-color: #EC5B4B; }

body.template-color-32 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #EC5B4B; }

body.template-color-32 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #EC5B4B; }

body.template-color-32 .slider-business .video-btn a .video-icon.secondary-color {
  background: #EC5B4B !important; }

body.template-color-32 a.brook-btn.bk-btn-yellow, body.template-color-32 button.brook-btn.bk-btn-yellow {
  background: #EC5B4B;
  color: #fff !important; }

body.template-color-32 a.bk-btn.theme-btn, body.template-color-32 button.bk-btn.theme-btn {
  background-color: #EC5B4B; }

body.template-color-32 a.brook-btn.bk-btn-red, body.template-color-32 button.brook-btn.bk-btn-red {
  background: #EC5B4B; }

body.template-color-32 .section-title h3 {
  color: #EC5B4B; }

body.template-color-32 .service.service--1:hover::before {
  border-color: #EC5B4B; }

body.template-color-32 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-32 .service.service--1 .icons i {
    color: #EC5B4B;
    font-size: 80px; }

body.template-color-32 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #EC5B4B;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-32 .service.service--1 .content a.service-btn:hover {
  color: #EC5B4B; }
  body.template-color-32 .service.service--1 .content a.service-btn:hover i {
    color: #EC5B4B; }

body.template-color-32 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-32 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-32 .service.service--3 .content h3 a:hover {
  color: #EC5B4B; }

body.template-color-32 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-32 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-32 .title-separator.color-red::after, body.template-color-32 .title-separator.color-blue::after {
    background: #EC5B4B; }

body.template-color-32 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-32 .bk-gradation .item-grid .dot-wrap .dot {
    background: #EC5B4B; }
  body.template-color-32 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #EC5B4B; }
  body.template-color-32 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #EC5B4B; }
  body.template-color-32 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #EC5B4B; }

body.template-color-32 .post-nav-lisnt .nav-item a:hover {
  background: #EC5B4B; }

body.template-color-32 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #EC5B4B; }

body.template-color-32 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #EC5B4B; }

body.template-color-32 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #EC5B4B; }

body.template-color-32 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #EC5B4B; }

body.template-color-32 .team.team__style--2 .thumb .overlay {
  background-color: #EC5B4B; }
  body.template-color-32 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #EC5B4B; }

body.template-color-32 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-32 .team.team__style--3 .thumb .overlay {
  background-color: #EC5B4B; }
  body.template-color-32 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #EC5B4B; }

body.template-color-32 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-32 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #EC5B4B; }
  body.template-color-32 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #EC5B4B; }
  body.template-color-32 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #EC5B4B; }
  body.template-color-32 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-32 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #EC5B4B; }
  body.template-color-32 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #EC5B4B;
    color: #fff; }

body.template-color-32 [data-overlay]:before {
  background-color: #EC5B4B; }

body.template-color-32 .team-heading .heading-right button i {
  color: #EC5B4B; }

body.template-color-32 .team-heading .heading-right button:hover {
  color: #EC5B4B !important; }

body.template-color-32 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #EC5B4B; }

body.template-color-32 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-32 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #EC5B4B; }

body.template-color-32 .mini-cart .shopping-cart {
  border-bottom: 2px solid #EC5B4B; }
  body.template-color-32 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #EC5B4B; }
  body.template-color-32 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #EC5B4B; }
  body.template-color-32 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #EC5B4B;
    background-color: #EC5B4B; }

body.template-color-32 .brook__toolbar .inner a:hover {
  color: #EC5B4B; }

body.template-color-32 .blog-grid .post-inner h5.heading:hover {
  color: #EC5B4B; }
  body.template-color-32 .blog-grid .post-inner h5.heading:hover a {
    color: #EC5B4B; }

body.template-color-32 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #EC5B4B; }

body.template-color-32 .theme-color {
  color: #EC5B4B; }

body.template-color-32 .bg_color--2 {
  background: #EC5B4B; }

body.template-color-32 a.brook-btn.bk-btn-theme,
body.template-color-32 button.brook-btn.bk-btn-theme {
  background: #EC5B4B;
  color: #fff; }

body.template-color-32 .btn-essential:hover {
  background: #EC5B4B !important;
  border-color: #EC5B4B !important;
  color: #fff; }

body.template-color-32 .red-border {
  border-color: #EC5B4B !important; }

body.template-color-32 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #EC5B4B;
  color: #fff; }

body.template-color-32 .single-svg-icon-box .svg-icon svg * {
  stroke: #EC5B4B !important; }

body.template-color-32 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-32 .menu-price {
  color: #EC5B4B; }

body.template-color-32 a.moredetails-btn:hover {
  color: #EC5B4B; }

body.template-color-32 a.moredetails-btn i {
  color: #EC5B4B; }

body.template-color-32 .messonry-button button.is-checked span.filter-text {
  color: #EC5B4B; }

body.template-color-32 .messonry-button button:hover span.filter-text {
  color: #EC5B4B; }

body.template-color-32 .messonry-button button span.filter-counter {
  background-color: #EC5B4B; }
  body.template-color-32 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #EC5B4B;
    border-top-color: #EC5B4B; }

body.template-color-32 .messonry-button button:hover span.filter-text {
  color: #EC5B4B; }

body.template-color-32 .messonry-button button.is-checked span.filter-text {
  color: #EC5B4B; }

body.template-color-32 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #EC5B4B; }

body.template-color-32 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #EC5B4B; }

body.template-color-32 .testimonial_style--1 .content .testimonial-quote span {
  color: #EC5B4B; }

body.template-color-32 .testimonial_style--1:hover {
  background: #EC5B4B; }
  body.template-color-32 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-32 .contact-form.contact-form--4 .input-box button {
  color: #EC5B4B; }
  body.template-color-32 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #EC5B4B; }

body.template-color-32 .contact-form.contact-form--3 .input-box button {
  color: #EC5B4B; }

body.template-color-32 .animated-dot {
  background-color: #EC5B4B; }
  body.template-color-32 .animated-dot .middle-dot {
    background-color: #EC5B4B; }
  body.template-color-32 .animated-dot .signal {
    background-color: #EC5B4B; }
  body.template-color-32 .animated-dot .signal2 {
    background-color: #EC5B4B; }

body.template-color-32 .progress-charts .progress .progress-bar {
  background-color: #EC5B4B; }

body.template-color-32 .brook-counter .icon i {
  color: #EC5B4B; }

body.template-color-32 .social-share a:hover {
  color: #EC5B4B; }

body.template-color-32 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #EC5B4B; }

body.template-color-32 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #EC5B4B; }

body.template-color-32 .video-btn a.video-icon,
body.template-color-32 .video-btn a .video-icon {
  background: #EC5B4B; }

body.template-color-32 .video-btn-2 .icon-gradient::before {
  background-color: #EC5B4B;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #EC5B4B 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #EC5B4B 100%);
  background-image: linear-gradient(-133deg, #899664 0, #EC5B4B 100%); }

body.template-color-32 .video-btn-2 .icon-gradient::after {
  background-color: #EC5B4B;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #EC5B4B 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #EC5B4B 100%);
  background-image: linear-gradient(-133deg, #899664 0, #EC5B4B 100%); }

body.template-color-32 .single-motive-speach .icon i {
  color: #EC5B4B; }

body.template-color-32 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #EC5B4B; }
  body.template-color-32 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #EC5B4B; }

body.template-color-32 .contact-form input:focus,
body.template-color-32 .contact-form textarea:focus {
  border-color: #EC5B4B; }

body.template-color-32 .contact-form input[type="submit"],
body.template-color-32 .contact-form textarea[type="submit"] {
  border-color: #EC5B4B;
  background-color: #EC5B4B; }

body.template-color-32 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #EC5B4B;
  color: #fff;
  border-color: #EC5B4B; }

body.template-color-32 .gallery .hover-overlay {
  background-color: #EC5B4B; }

body.template-color-32 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #EC5B4B; }

body.template-color-32 .blog-grid .post-content::before {
  background-color: #EC5B4B; }

body.template-color-32 .blog-grid-no-flex .post-quote::before {
  color: #EC5B4B; }

body.template-color-32 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #EC5B4B; }
  body.template-color-32 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #EC5B4B; }

body.template-color-32 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #EC5B4B; }

body.template-color-32 .bl-sidebar.search button:hover {
  background-color: #EC5B4B; }

body.template-color-32 .bl-sidebar.search button span {
  color: #EC5B4B; }

body.template-color-32 .bl-sidebar.category .category-list li a:hover {
  color: #EC5B4B; }

body.template-color-32 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #EC5B4B; }

body.template-color-32 .social-icon.icon-solid-rounded li a:hover {
  background-color: #EC5B4B; }

body.template-color-32 .icon-box .inner .icon {
  color: #EC5B4B; }

body.template-color-32 .basic-thick-line-theme-4 {
  border-top: 4px solid #EC5B4B; }

body.template-color-32 .procing-box .header .feature-mark {
  background-color: #EC5B4B; }

body.template-color-32 a.brook-btn.bk-btn-dark:hover {
  background: #EC5B4B; }

body.template-color-32 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #EC5B4B; }

body.template-color-32 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #EC5B4B; }

body.template-color-32 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #EC5B4B !important; }

body.template-color-32 .basic-modern-dots .dot {
  background-color: #EC5B4B; }

body.template-color-32 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #EC5B4B; }

body.template-color-32 a.brook-btn.bk-btn-theme-border,
body.template-color-32 button.brook-btn.bk-btn-theme-border {
  border-color: #EC5B4B;
  color: #EC5B4B; }
  body.template-color-32 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-32 button.brook-btn.bk-btn-theme-border:hover {
    background: #EC5B4B;
    color: #ffffff; }

body.template-color-32 .brook-quote::before {
  color: #EC5B4B; }

body.template-color-32 .bk-text-box.bg-theme-color {
  background: #EC5B4B; }

body.template-color-32 .bk-list--2 .list-header.with-number--theme .marker {
  color: #EC5B4B; }

body.template-color-32 .view-more-btn a span.btn-arrow {
  color: #EC5B4B; }

body.template-color-32 #fp-nav ul li a.active {
  background-color: #EC5B4B; }

body.template-color-33 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #C1B538; }

body.template-color-33 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #C1B538; }

body.template-color-33 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #C1B538; }

body.template-color-33 .faq-content h2 {
  color: #C1B538; }

body.template-color-33 .brook-section-title-corona h2 {
  color: #C1B538; }

body.template-color-33 .single-contagion .contagion-content h3 {
  color: #C1B538; }

body.template-color-33 .single-sympotom .sympotom-content h3 {
  color: #C1B538; }

body.template-color-33 .single-prevination .prevination-content h3 {
  color: #C1B538; }

body.template-color-33 .service.service--1.theme-color {
  background: #C1B538; }

body.template-color-33 .blog-grid-minimal.outline.bg-transparant {
  border-color: #C1B538; }
  body.template-color-33 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #C1B538; }

body.template-color-33 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-33 .single-svg-icon-box.business-icon-box:hover {
  background: #C1B538; }

body.template-color-33 .product_review .description_nav a.active, body.template-color-33 .product_review .description_nav a:hover {
  border-bottom: 3px solid #C1B538; }

body.template-color-33 .single-product-details-side .product-action a {
  border-color: #C1B538;
  background-color: #C1B538; }

body.template-color-33 .cart-table td.pro-subtotal span {
  color: #C1B538; }

body.template-color-33 .cart-table td.pro-addtocart button {
  border: 1px solid #C1B538;
  background-color: #C1B538; }

body.template-color-33 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #C1B538; }

body.template-color-33 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #C1B538; }

body.template-color-33 .slider-business .video-btn a .video-icon.secondary-color {
  background: #C1B538 !important; }

body.template-color-33 a.brook-btn.bk-btn-yellow, body.template-color-33 button.brook-btn.bk-btn-yellow {
  background: #C1B538;
  color: #fff !important; }

body.template-color-33 a.bk-btn.theme-btn, body.template-color-33 button.bk-btn.theme-btn {
  background-color: #C1B538; }

body.template-color-33 a.brook-btn.bk-btn-red, body.template-color-33 button.brook-btn.bk-btn-red {
  background: #C1B538; }

body.template-color-33 .section-title h3 {
  color: #C1B538; }

body.template-color-33 .service.service--1:hover::before {
  border-color: #C1B538; }

body.template-color-33 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-33 .service.service--1 .icons i {
    color: #C1B538;
    font-size: 80px; }

body.template-color-33 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #C1B538;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-33 .service.service--1 .content a.service-btn:hover {
  color: #C1B538; }
  body.template-color-33 .service.service--1 .content a.service-btn:hover i {
    color: #C1B538; }

body.template-color-33 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-33 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-33 .service.service--3 .content h3 a:hover {
  color: #C1B538; }

body.template-color-33 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-33 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-33 .title-separator.color-red::after, body.template-color-33 .title-separator.color-blue::after {
    background: #C1B538; }

body.template-color-33 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-33 .bk-gradation .item-grid .dot-wrap .dot {
    background: #C1B538; }
  body.template-color-33 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #C1B538; }
  body.template-color-33 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #C1B538; }
  body.template-color-33 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #C1B538; }

body.template-color-33 .post-nav-lisnt .nav-item a:hover {
  background: #C1B538; }

body.template-color-33 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #C1B538; }

body.template-color-33 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #C1B538; }

body.template-color-33 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #C1B538; }

body.template-color-33 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #C1B538; }

body.template-color-33 .team.team__style--2 .thumb .overlay {
  background-color: #C1B538; }
  body.template-color-33 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #C1B538; }

body.template-color-33 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-33 .team.team__style--3 .thumb .overlay {
  background-color: #C1B538; }
  body.template-color-33 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #C1B538; }

body.template-color-33 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-33 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #C1B538; }
  body.template-color-33 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #C1B538; }
  body.template-color-33 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #C1B538; }
  body.template-color-33 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-33 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #C1B538; }
  body.template-color-33 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #C1B538;
    color: #fff; }

body.template-color-33 [data-overlay]:before {
  background-color: #C1B538; }

body.template-color-33 .team-heading .heading-right button i {
  color: #C1B538; }

body.template-color-33 .team-heading .heading-right button:hover {
  color: #C1B538 !important; }

body.template-color-33 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #C1B538; }

body.template-color-33 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-33 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #C1B538; }

body.template-color-33 .mini-cart .shopping-cart {
  border-bottom: 2px solid #C1B538; }
  body.template-color-33 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #C1B538; }
  body.template-color-33 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #C1B538; }
  body.template-color-33 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #C1B538;
    background-color: #C1B538; }

body.template-color-33 .brook__toolbar .inner a:hover {
  color: #C1B538; }

body.template-color-33 .blog-grid .post-inner h5.heading:hover {
  color: #C1B538; }
  body.template-color-33 .blog-grid .post-inner h5.heading:hover a {
    color: #C1B538; }

body.template-color-33 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #C1B538; }

body.template-color-33 .theme-color {
  color: #C1B538; }

body.template-color-33 .bg_color--2 {
  background: #C1B538; }

body.template-color-33 a.brook-btn.bk-btn-theme,
body.template-color-33 button.brook-btn.bk-btn-theme {
  background: #C1B538;
  color: #fff; }

body.template-color-33 .btn-essential:hover {
  background: #C1B538 !important;
  border-color: #C1B538 !important;
  color: #fff; }

body.template-color-33 .red-border {
  border-color: #C1B538 !important; }

body.template-color-33 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #C1B538;
  color: #fff; }

body.template-color-33 .single-svg-icon-box .svg-icon svg * {
  stroke: #C1B538 !important; }

body.template-color-33 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-33 .menu-price {
  color: #C1B538; }

body.template-color-33 a.moredetails-btn:hover {
  color: #C1B538; }

body.template-color-33 a.moredetails-btn i {
  color: #C1B538; }

body.template-color-33 .messonry-button button.is-checked span.filter-text {
  color: #C1B538; }

body.template-color-33 .messonry-button button:hover span.filter-text {
  color: #C1B538; }

body.template-color-33 .messonry-button button span.filter-counter {
  background-color: #C1B538; }
  body.template-color-33 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #C1B538;
    border-top-color: #C1B538; }

body.template-color-33 .messonry-button button:hover span.filter-text {
  color: #C1B538; }

body.template-color-33 .messonry-button button.is-checked span.filter-text {
  color: #C1B538; }

body.template-color-33 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #C1B538; }

body.template-color-33 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #C1B538; }

body.template-color-33 .testimonial_style--1 .content .testimonial-quote span {
  color: #C1B538; }

body.template-color-33 .testimonial_style--1:hover {
  background: #C1B538; }
  body.template-color-33 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-33 .contact-form.contact-form--4 .input-box button {
  color: #C1B538; }
  body.template-color-33 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #C1B538; }

body.template-color-33 .contact-form.contact-form--3 .input-box button {
  color: #C1B538; }

body.template-color-33 .animated-dot {
  background-color: #C1B538; }
  body.template-color-33 .animated-dot .middle-dot {
    background-color: #C1B538; }
  body.template-color-33 .animated-dot .signal {
    background-color: #C1B538; }
  body.template-color-33 .animated-dot .signal2 {
    background-color: #C1B538; }

body.template-color-33 .progress-charts .progress .progress-bar {
  background-color: #C1B538; }

body.template-color-33 .brook-counter .icon i {
  color: #C1B538; }

body.template-color-33 .social-share a:hover {
  color: #C1B538; }

body.template-color-33 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #C1B538; }

body.template-color-33 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #C1B538; }

body.template-color-33 .video-btn a.video-icon,
body.template-color-33 .video-btn a .video-icon {
  background: #C1B538; }

body.template-color-33 .video-btn-2 .icon-gradient::before {
  background-color: #C1B538;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #C1B538 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #C1B538 100%);
  background-image: linear-gradient(-133deg, #899664 0, #C1B538 100%); }

body.template-color-33 .video-btn-2 .icon-gradient::after {
  background-color: #C1B538;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #C1B538 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #C1B538 100%);
  background-image: linear-gradient(-133deg, #899664 0, #C1B538 100%); }

body.template-color-33 .single-motive-speach .icon i {
  color: #C1B538; }

body.template-color-33 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #C1B538; }
  body.template-color-33 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #C1B538; }

body.template-color-33 .contact-form input:focus,
body.template-color-33 .contact-form textarea:focus {
  border-color: #C1B538; }

body.template-color-33 .contact-form input[type="submit"],
body.template-color-33 .contact-form textarea[type="submit"] {
  border-color: #C1B538;
  background-color: #C1B538; }

body.template-color-33 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #C1B538;
  color: #fff;
  border-color: #C1B538; }

body.template-color-33 .gallery .hover-overlay {
  background-color: #C1B538; }

body.template-color-33 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #C1B538; }

body.template-color-33 .blog-grid .post-content::before {
  background-color: #C1B538; }

body.template-color-33 .blog-grid-no-flex .post-quote::before {
  color: #C1B538; }

body.template-color-33 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #C1B538; }
  body.template-color-33 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #C1B538; }

body.template-color-33 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #C1B538; }

body.template-color-33 .bl-sidebar.search button:hover {
  background-color: #C1B538; }

body.template-color-33 .bl-sidebar.search button span {
  color: #C1B538; }

body.template-color-33 .bl-sidebar.category .category-list li a:hover {
  color: #C1B538; }

body.template-color-33 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #C1B538; }

body.template-color-33 .social-icon.icon-solid-rounded li a:hover {
  background-color: #C1B538; }

body.template-color-33 .icon-box .inner .icon {
  color: #C1B538; }

body.template-color-33 .basic-thick-line-theme-4 {
  border-top: 4px solid #C1B538; }

body.template-color-33 .procing-box .header .feature-mark {
  background-color: #C1B538; }

body.template-color-33 a.brook-btn.bk-btn-dark:hover {
  background: #C1B538; }

body.template-color-33 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #C1B538; }

body.template-color-33 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #C1B538; }

body.template-color-33 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #C1B538 !important; }

body.template-color-33 .basic-modern-dots .dot {
  background-color: #C1B538; }

body.template-color-33 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #C1B538; }

body.template-color-33 a.brook-btn.bk-btn-theme-border,
body.template-color-33 button.brook-btn.bk-btn-theme-border {
  border-color: #C1B538;
  color: #C1B538; }
  body.template-color-33 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-33 button.brook-btn.bk-btn-theme-border:hover {
    background: #C1B538;
    color: #ffffff; }

body.template-color-33 .brook-quote::before {
  color: #C1B538; }

body.template-color-33 .bk-text-box.bg-theme-color {
  background: #C1B538; }

body.template-color-33 .bk-list--2 .list-header.with-number--theme .marker {
  color: #C1B538; }

body.template-color-33 .view-more-btn a span.btn-arrow {
  color: #C1B538; }

body.template-color-33 #fp-nav ul li a.active {
  background-color: #C1B538; }

body.template-color-34 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #126CDC; }

body.template-color-34 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #126CDC; }

body.template-color-34 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #126CDC; }

body.template-color-34 .faq-content h2 {
  color: #126CDC; }

body.template-color-34 .brook-section-title-corona h2 {
  color: #126CDC; }

body.template-color-34 .single-contagion .contagion-content h3 {
  color: #126CDC; }

body.template-color-34 .single-sympotom .sympotom-content h3 {
  color: #126CDC; }

body.template-color-34 .single-prevination .prevination-content h3 {
  color: #126CDC; }

body.template-color-34 .service.service--1.theme-color {
  background: #126CDC; }

body.template-color-34 .blog-grid-minimal.outline.bg-transparant {
  border-color: #126CDC; }
  body.template-color-34 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #126CDC; }

body.template-color-34 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-34 .single-svg-icon-box.business-icon-box:hover {
  background: #126CDC; }

body.template-color-34 .product_review .description_nav a.active, body.template-color-34 .product_review .description_nav a:hover {
  border-bottom: 3px solid #126CDC; }

body.template-color-34 .single-product-details-side .product-action a {
  border-color: #126CDC;
  background-color: #126CDC; }

body.template-color-34 .cart-table td.pro-subtotal span {
  color: #126CDC; }

body.template-color-34 .cart-table td.pro-addtocart button {
  border: 1px solid #126CDC;
  background-color: #126CDC; }

body.template-color-34 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #126CDC; }

body.template-color-34 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #126CDC; }

body.template-color-34 .slider-business .video-btn a .video-icon.secondary-color {
  background: #126CDC !important; }

body.template-color-34 a.brook-btn.bk-btn-yellow, body.template-color-34 button.brook-btn.bk-btn-yellow {
  background: #126CDC;
  color: #fff !important; }

body.template-color-34 a.bk-btn.theme-btn, body.template-color-34 button.bk-btn.theme-btn {
  background-color: #126CDC; }

body.template-color-34 a.brook-btn.bk-btn-red, body.template-color-34 button.brook-btn.bk-btn-red {
  background: #126CDC; }

body.template-color-34 .section-title h3 {
  color: #126CDC; }

body.template-color-34 .service.service--1:hover::before {
  border-color: #126CDC; }

body.template-color-34 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-34 .service.service--1 .icons i {
    color: #126CDC;
    font-size: 80px; }

body.template-color-34 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #126CDC;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-34 .service.service--1 .content a.service-btn:hover {
  color: #126CDC; }
  body.template-color-34 .service.service--1 .content a.service-btn:hover i {
    color: #126CDC; }

body.template-color-34 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-34 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-34 .service.service--3 .content h3 a:hover {
  color: #126CDC; }

body.template-color-34 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-34 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-34 .title-separator.color-red::after, body.template-color-34 .title-separator.color-blue::after {
    background: #126CDC; }

body.template-color-34 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-34 .bk-gradation .item-grid .dot-wrap .dot {
    background: #126CDC; }
  body.template-color-34 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #126CDC; }
  body.template-color-34 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #126CDC; }
  body.template-color-34 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #126CDC; }

body.template-color-34 .post-nav-lisnt .nav-item a:hover {
  background: #126CDC; }

body.template-color-34 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #126CDC; }

body.template-color-34 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #126CDC; }

body.template-color-34 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #126CDC; }

body.template-color-34 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #126CDC; }

body.template-color-34 .team.team__style--2 .thumb .overlay {
  background-color: #126CDC; }
  body.template-color-34 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #126CDC; }

body.template-color-34 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-34 .team.team__style--3 .thumb .overlay {
  background-color: #126CDC; }
  body.template-color-34 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #126CDC; }

body.template-color-34 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-34 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #126CDC; }
  body.template-color-34 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #126CDC; }
  body.template-color-34 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #126CDC; }
  body.template-color-34 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-34 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #126CDC; }
  body.template-color-34 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #126CDC;
    color: #fff; }

body.template-color-34 [data-overlay]:before {
  background-color: #126CDC; }

body.template-color-34 .team-heading .heading-right button i {
  color: #126CDC; }

body.template-color-34 .team-heading .heading-right button:hover {
  color: #126CDC !important; }

body.template-color-34 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #126CDC; }

body.template-color-34 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-34 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #126CDC; }

body.template-color-34 .mini-cart .shopping-cart {
  border-bottom: 2px solid #126CDC; }
  body.template-color-34 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #126CDC; }
  body.template-color-34 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #126CDC; }
  body.template-color-34 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #126CDC;
    background-color: #126CDC; }

body.template-color-34 .brook__toolbar .inner a:hover {
  color: #126CDC; }

body.template-color-34 .blog-grid .post-inner h5.heading:hover {
  color: #126CDC; }
  body.template-color-34 .blog-grid .post-inner h5.heading:hover a {
    color: #126CDC; }

body.template-color-34 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #126CDC; }

body.template-color-34 .theme-color {
  color: #126CDC; }

body.template-color-34 .bg_color--2 {
  background: #126CDC; }

body.template-color-34 a.brook-btn.bk-btn-theme,
body.template-color-34 button.brook-btn.bk-btn-theme {
  background: #126CDC;
  color: #fff; }

body.template-color-34 .btn-essential:hover {
  background: #126CDC !important;
  border-color: #126CDC !important;
  color: #fff; }

body.template-color-34 .red-border {
  border-color: #126CDC !important; }

body.template-color-34 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #126CDC;
  color: #fff; }

body.template-color-34 .single-svg-icon-box .svg-icon svg * {
  stroke: #126CDC !important; }

body.template-color-34 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-34 .menu-price {
  color: #126CDC; }

body.template-color-34 a.moredetails-btn:hover {
  color: #126CDC; }

body.template-color-34 a.moredetails-btn i {
  color: #126CDC; }

body.template-color-34 .messonry-button button.is-checked span.filter-text {
  color: #126CDC; }

body.template-color-34 .messonry-button button:hover span.filter-text {
  color: #126CDC; }

body.template-color-34 .messonry-button button span.filter-counter {
  background-color: #126CDC; }
  body.template-color-34 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #126CDC;
    border-top-color: #126CDC; }

body.template-color-34 .messonry-button button:hover span.filter-text {
  color: #126CDC; }

body.template-color-34 .messonry-button button.is-checked span.filter-text {
  color: #126CDC; }

body.template-color-34 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #126CDC; }

body.template-color-34 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #126CDC; }

body.template-color-34 .testimonial_style--1 .content .testimonial-quote span {
  color: #126CDC; }

body.template-color-34 .testimonial_style--1:hover {
  background: #126CDC; }
  body.template-color-34 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-34 .contact-form.contact-form--4 .input-box button {
  color: #126CDC; }
  body.template-color-34 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #126CDC; }

body.template-color-34 .contact-form.contact-form--3 .input-box button {
  color: #126CDC; }

body.template-color-34 .animated-dot {
  background-color: #126CDC; }
  body.template-color-34 .animated-dot .middle-dot {
    background-color: #126CDC; }
  body.template-color-34 .animated-dot .signal {
    background-color: #126CDC; }
  body.template-color-34 .animated-dot .signal2 {
    background-color: #126CDC; }

body.template-color-34 .progress-charts .progress .progress-bar {
  background-color: #126CDC; }

body.template-color-34 .brook-counter .icon i {
  color: #126CDC; }

body.template-color-34 .social-share a:hover {
  color: #126CDC; }

body.template-color-34 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #126CDC; }

body.template-color-34 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #126CDC; }

body.template-color-34 .video-btn a.video-icon,
body.template-color-34 .video-btn a .video-icon {
  background: #126CDC; }

body.template-color-34 .video-btn-2 .icon-gradient::before {
  background-color: #126CDC;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #126CDC 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #126CDC 100%);
  background-image: linear-gradient(-133deg, #899664 0, #126CDC 100%); }

body.template-color-34 .video-btn-2 .icon-gradient::after {
  background-color: #126CDC;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #126CDC 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #126CDC 100%);
  background-image: linear-gradient(-133deg, #899664 0, #126CDC 100%); }

body.template-color-34 .single-motive-speach .icon i {
  color: #126CDC; }

body.template-color-34 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #126CDC; }
  body.template-color-34 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #126CDC; }

body.template-color-34 .contact-form input:focus,
body.template-color-34 .contact-form textarea:focus {
  border-color: #126CDC; }

body.template-color-34 .contact-form input[type="submit"],
body.template-color-34 .contact-form textarea[type="submit"] {
  border-color: #126CDC;
  background-color: #126CDC; }

body.template-color-34 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #126CDC;
  color: #fff;
  border-color: #126CDC; }

body.template-color-34 .gallery .hover-overlay {
  background-color: #126CDC; }

body.template-color-34 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #126CDC; }

body.template-color-34 .blog-grid .post-content::before {
  background-color: #126CDC; }

body.template-color-34 .blog-grid-no-flex .post-quote::before {
  color: #126CDC; }

body.template-color-34 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #126CDC; }
  body.template-color-34 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #126CDC; }

body.template-color-34 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #126CDC; }

body.template-color-34 .bl-sidebar.search button:hover {
  background-color: #126CDC; }

body.template-color-34 .bl-sidebar.search button span {
  color: #126CDC; }

body.template-color-34 .bl-sidebar.category .category-list li a:hover {
  color: #126CDC; }

body.template-color-34 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #126CDC; }

body.template-color-34 .social-icon.icon-solid-rounded li a:hover {
  background-color: #126CDC; }

body.template-color-34 .icon-box .inner .icon {
  color: #126CDC; }

body.template-color-34 .basic-thick-line-theme-4 {
  border-top: 4px solid #126CDC; }

body.template-color-34 .procing-box .header .feature-mark {
  background-color: #126CDC; }

body.template-color-34 a.brook-btn.bk-btn-dark:hover {
  background: #126CDC; }

body.template-color-34 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #126CDC; }

body.template-color-34 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #126CDC; }

body.template-color-34 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #126CDC !important; }

body.template-color-34 .basic-modern-dots .dot {
  background-color: #126CDC; }

body.template-color-34 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #126CDC; }

body.template-color-34 a.brook-btn.bk-btn-theme-border,
body.template-color-34 button.brook-btn.bk-btn-theme-border {
  border-color: #126CDC;
  color: #126CDC; }
  body.template-color-34 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-34 button.brook-btn.bk-btn-theme-border:hover {
    background: #126CDC;
    color: #ffffff; }

body.template-color-34 .brook-quote::before {
  color: #126CDC; }

body.template-color-34 .bk-text-box.bg-theme-color {
  background: #126CDC; }

body.template-color-34 .bk-list--2 .list-header.with-number--theme .marker {
  color: #126CDC; }

body.template-color-34 .view-more-btn a span.btn-arrow {
  color: #126CDC; }

body.template-color-34 #fp-nav ul li a.active {
  background-color: #126CDC; }

body.template-color-35 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #F50809; }

body.template-color-35 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #F50809; }

body.template-color-35 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #F50809; }

body.template-color-35 .faq-content h2 {
  color: #F50809; }

body.template-color-35 .brook-section-title-corona h2 {
  color: #F50809; }

body.template-color-35 .single-contagion .contagion-content h3 {
  color: #F50809; }

body.template-color-35 .single-sympotom .sympotom-content h3 {
  color: #F50809; }

body.template-color-35 .single-prevination .prevination-content h3 {
  color: #F50809; }

body.template-color-35 .service.service--1.theme-color {
  background: #F50809; }

body.template-color-35 .blog-grid-minimal.outline.bg-transparant {
  border-color: #F50809; }
  body.template-color-35 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #F50809; }

body.template-color-35 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-35 .single-svg-icon-box.business-icon-box:hover {
  background: #F50809; }

body.template-color-35 .product_review .description_nav a.active, body.template-color-35 .product_review .description_nav a:hover {
  border-bottom: 3px solid #F50809; }

body.template-color-35 .single-product-details-side .product-action a {
  border-color: #F50809;
  background-color: #F50809; }

body.template-color-35 .cart-table td.pro-subtotal span {
  color: #F50809; }

body.template-color-35 .cart-table td.pro-addtocart button {
  border: 1px solid #F50809;
  background-color: #F50809; }

body.template-color-35 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #F50809; }

body.template-color-35 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #F50809; }

body.template-color-35 .slider-business .video-btn a .video-icon.secondary-color {
  background: #F50809 !important; }

body.template-color-35 a.brook-btn.bk-btn-yellow, body.template-color-35 button.brook-btn.bk-btn-yellow {
  background: #F50809;
  color: #fff !important; }

body.template-color-35 a.bk-btn.theme-btn, body.template-color-35 button.bk-btn.theme-btn {
  background-color: #F50809; }

body.template-color-35 a.brook-btn.bk-btn-red, body.template-color-35 button.brook-btn.bk-btn-red {
  background: #F50809; }

body.template-color-35 .section-title h3 {
  color: #F50809; }

body.template-color-35 .service.service--1:hover::before {
  border-color: #F50809; }

body.template-color-35 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-35 .service.service--1 .icons i {
    color: #F50809;
    font-size: 80px; }

body.template-color-35 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #F50809;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-35 .service.service--1 .content a.service-btn:hover {
  color: #F50809; }
  body.template-color-35 .service.service--1 .content a.service-btn:hover i {
    color: #F50809; }

body.template-color-35 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-35 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-35 .service.service--3 .content h3 a:hover {
  color: #F50809; }

body.template-color-35 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-35 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-35 .title-separator.color-red::after, body.template-color-35 .title-separator.color-blue::after {
    background: #F50809; }

body.template-color-35 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-35 .bk-gradation .item-grid .dot-wrap .dot {
    background: #F50809; }
  body.template-color-35 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #F50809; }
  body.template-color-35 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #F50809; }
  body.template-color-35 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #F50809; }

body.template-color-35 .post-nav-lisnt .nav-item a:hover {
  background: #F50809; }

body.template-color-35 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #F50809; }

body.template-color-35 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #F50809; }

body.template-color-35 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #F50809; }

body.template-color-35 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #F50809; }

body.template-color-35 .team.team__style--2 .thumb .overlay {
  background-color: #F50809; }
  body.template-color-35 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #F50809; }

body.template-color-35 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-35 .team.team__style--3 .thumb .overlay {
  background-color: #F50809; }
  body.template-color-35 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #F50809; }

body.template-color-35 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-35 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #F50809; }
  body.template-color-35 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #F50809; }
  body.template-color-35 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #F50809; }
  body.template-color-35 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-35 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #F50809; }
  body.template-color-35 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #F50809;
    color: #fff; }

body.template-color-35 [data-overlay]:before {
  background-color: #F50809; }

body.template-color-35 .team-heading .heading-right button i {
  color: #F50809; }

body.template-color-35 .team-heading .heading-right button:hover {
  color: #F50809 !important; }

body.template-color-35 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #F50809; }

body.template-color-35 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-35 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #F50809; }

body.template-color-35 .mini-cart .shopping-cart {
  border-bottom: 2px solid #F50809; }
  body.template-color-35 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #F50809; }
  body.template-color-35 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #F50809; }
  body.template-color-35 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #F50809;
    background-color: #F50809; }

body.template-color-35 .brook__toolbar .inner a:hover {
  color: #F50809; }

body.template-color-35 .blog-grid .post-inner h5.heading:hover {
  color: #F50809; }
  body.template-color-35 .blog-grid .post-inner h5.heading:hover a {
    color: #F50809; }

body.template-color-35 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #F50809; }

body.template-color-35 .theme-color {
  color: #F50809; }

body.template-color-35 .bg_color--2 {
  background: #F50809; }

body.template-color-35 a.brook-btn.bk-btn-theme,
body.template-color-35 button.brook-btn.bk-btn-theme {
  background: #F50809;
  color: #fff; }

body.template-color-35 .btn-essential:hover {
  background: #F50809 !important;
  border-color: #F50809 !important;
  color: #fff; }

body.template-color-35 .red-border {
  border-color: #F50809 !important; }

body.template-color-35 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #F50809;
  color: #fff; }

body.template-color-35 .single-svg-icon-box .svg-icon svg * {
  stroke: #F50809 !important; }

body.template-color-35 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-35 .menu-price {
  color: #F50809; }

body.template-color-35 a.moredetails-btn:hover {
  color: #F50809; }

body.template-color-35 a.moredetails-btn i {
  color: #F50809; }

body.template-color-35 .messonry-button button.is-checked span.filter-text {
  color: #F50809; }

body.template-color-35 .messonry-button button:hover span.filter-text {
  color: #F50809; }

body.template-color-35 .messonry-button button span.filter-counter {
  background-color: #F50809; }
  body.template-color-35 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #F50809;
    border-top-color: #F50809; }

body.template-color-35 .messonry-button button:hover span.filter-text {
  color: #F50809; }

body.template-color-35 .messonry-button button.is-checked span.filter-text {
  color: #F50809; }

body.template-color-35 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #F50809; }

body.template-color-35 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #F50809; }

body.template-color-35 .testimonial_style--1 .content .testimonial-quote span {
  color: #F50809; }

body.template-color-35 .testimonial_style--1:hover {
  background: #F50809; }
  body.template-color-35 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-35 .contact-form.contact-form--4 .input-box button {
  color: #F50809; }
  body.template-color-35 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #F50809; }

body.template-color-35 .contact-form.contact-form--3 .input-box button {
  color: #F50809; }

body.template-color-35 .animated-dot {
  background-color: #F50809; }
  body.template-color-35 .animated-dot .middle-dot {
    background-color: #F50809; }
  body.template-color-35 .animated-dot .signal {
    background-color: #F50809; }
  body.template-color-35 .animated-dot .signal2 {
    background-color: #F50809; }

body.template-color-35 .progress-charts .progress .progress-bar {
  background-color: #F50809; }

body.template-color-35 .brook-counter .icon i {
  color: #F50809; }

body.template-color-35 .social-share a:hover {
  color: #F50809; }

body.template-color-35 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #F50809; }

body.template-color-35 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #F50809; }

body.template-color-35 .video-btn a.video-icon,
body.template-color-35 .video-btn a .video-icon {
  background: #F50809; }

body.template-color-35 .video-btn-2 .icon-gradient::before {
  background-color: #F50809;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F50809 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F50809 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F50809 100%); }

body.template-color-35 .video-btn-2 .icon-gradient::after {
  background-color: #F50809;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #F50809 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #F50809 100%);
  background-image: linear-gradient(-133deg, #899664 0, #F50809 100%); }

body.template-color-35 .single-motive-speach .icon i {
  color: #F50809; }

body.template-color-35 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #F50809; }
  body.template-color-35 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #F50809; }

body.template-color-35 .contact-form input:focus,
body.template-color-35 .contact-form textarea:focus {
  border-color: #F50809; }

body.template-color-35 .contact-form input[type="submit"],
body.template-color-35 .contact-form textarea[type="submit"] {
  border-color: #F50809;
  background-color: #F50809; }

body.template-color-35 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #F50809;
  color: #fff;
  border-color: #F50809; }

body.template-color-35 .gallery .hover-overlay {
  background-color: #F50809; }

body.template-color-35 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #F50809; }

body.template-color-35 .blog-grid .post-content::before {
  background-color: #F50809; }

body.template-color-35 .blog-grid-no-flex .post-quote::before {
  color: #F50809; }

body.template-color-35 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #F50809; }
  body.template-color-35 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #F50809; }

body.template-color-35 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #F50809; }

body.template-color-35 .bl-sidebar.search button:hover {
  background-color: #F50809; }

body.template-color-35 .bl-sidebar.search button span {
  color: #F50809; }

body.template-color-35 .bl-sidebar.category .category-list li a:hover {
  color: #F50809; }

body.template-color-35 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #F50809; }

body.template-color-35 .social-icon.icon-solid-rounded li a:hover {
  background-color: #F50809; }

body.template-color-35 .icon-box .inner .icon {
  color: #F50809; }

body.template-color-35 .basic-thick-line-theme-4 {
  border-top: 4px solid #F50809; }

body.template-color-35 .procing-box .header .feature-mark {
  background-color: #F50809; }

body.template-color-35 a.brook-btn.bk-btn-dark:hover {
  background: #F50809; }

body.template-color-35 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #F50809; }

body.template-color-35 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #F50809; }

body.template-color-35 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #F50809 !important; }

body.template-color-35 .basic-modern-dots .dot {
  background-color: #F50809; }

body.template-color-35 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #F50809; }

body.template-color-35 a.brook-btn.bk-btn-theme-border,
body.template-color-35 button.brook-btn.bk-btn-theme-border {
  border-color: #F50809;
  color: #F50809; }
  body.template-color-35 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-35 button.brook-btn.bk-btn-theme-border:hover {
    background: #F50809;
    color: #ffffff; }

body.template-color-35 .brook-quote::before {
  color: #F50809; }

body.template-color-35 .bk-text-box.bg-theme-color {
  background: #F50809; }

body.template-color-35 .bk-list--2 .list-header.with-number--theme .marker {
  color: #F50809; }

body.template-color-35 .view-more-btn a span.btn-arrow {
  color: #F50809; }

body.template-color-35 #fp-nav ul li a.active {
  background-color: #F50809; }

body.template-color-36 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #4BE472; }

body.template-color-36 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #4BE472; }

body.template-color-36 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #4BE472; }

body.template-color-36 .faq-content h2 {
  color: #4BE472; }

body.template-color-36 .brook-section-title-corona h2 {
  color: #4BE472; }

body.template-color-36 .single-contagion .contagion-content h3 {
  color: #4BE472; }

body.template-color-36 .single-sympotom .sympotom-content h3 {
  color: #4BE472; }

body.template-color-36 .single-prevination .prevination-content h3 {
  color: #4BE472; }

body.template-color-36 .service.service--1.theme-color {
  background: #4BE472; }

body.template-color-36 .blog-grid-minimal.outline.bg-transparant {
  border-color: #4BE472; }
  body.template-color-36 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #4BE472; }

body.template-color-36 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-36 .single-svg-icon-box.business-icon-box:hover {
  background: #4BE472; }

body.template-color-36 .product_review .description_nav a.active, body.template-color-36 .product_review .description_nav a:hover {
  border-bottom: 3px solid #4BE472; }

body.template-color-36 .single-product-details-side .product-action a {
  border-color: #4BE472;
  background-color: #4BE472; }

body.template-color-36 .cart-table td.pro-subtotal span {
  color: #4BE472; }

body.template-color-36 .cart-table td.pro-addtocart button {
  border: 1px solid #4BE472;
  background-color: #4BE472; }

body.template-color-36 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #4BE472; }

body.template-color-36 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #4BE472; }

body.template-color-36 .slider-business .video-btn a .video-icon.secondary-color {
  background: #4BE472 !important; }

body.template-color-36 a.brook-btn.bk-btn-yellow, body.template-color-36 button.brook-btn.bk-btn-yellow {
  background: #4BE472;
  color: #fff !important; }

body.template-color-36 a.bk-btn.theme-btn, body.template-color-36 button.bk-btn.theme-btn {
  background-color: #4BE472; }

body.template-color-36 a.brook-btn.bk-btn-red, body.template-color-36 button.brook-btn.bk-btn-red {
  background: #4BE472; }

body.template-color-36 .section-title h3 {
  color: #4BE472; }

body.template-color-36 .service.service--1:hover::before {
  border-color: #4BE472; }

body.template-color-36 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-36 .service.service--1 .icons i {
    color: #4BE472;
    font-size: 80px; }

body.template-color-36 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #4BE472;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-36 .service.service--1 .content a.service-btn:hover {
  color: #4BE472; }
  body.template-color-36 .service.service--1 .content a.service-btn:hover i {
    color: #4BE472; }

body.template-color-36 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-36 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-36 .service.service--3 .content h3 a:hover {
  color: #4BE472; }

body.template-color-36 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-36 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-36 .title-separator.color-red::after, body.template-color-36 .title-separator.color-blue::after {
    background: #4BE472; }

body.template-color-36 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-36 .bk-gradation .item-grid .dot-wrap .dot {
    background: #4BE472; }
  body.template-color-36 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #4BE472; }
  body.template-color-36 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #4BE472; }
  body.template-color-36 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #4BE472; }

body.template-color-36 .post-nav-lisnt .nav-item a:hover {
  background: #4BE472; }

body.template-color-36 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #4BE472; }

body.template-color-36 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #4BE472; }

body.template-color-36 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #4BE472; }

body.template-color-36 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #4BE472; }

body.template-color-36 .team.team__style--2 .thumb .overlay {
  background-color: #4BE472; }
  body.template-color-36 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #4BE472; }

body.template-color-36 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-36 .team.team__style--3 .thumb .overlay {
  background-color: #4BE472; }
  body.template-color-36 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #4BE472; }

body.template-color-36 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-36 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #4BE472; }
  body.template-color-36 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #4BE472; }
  body.template-color-36 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #4BE472; }
  body.template-color-36 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-36 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #4BE472; }
  body.template-color-36 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #4BE472;
    color: #fff; }

body.template-color-36 [data-overlay]:before {
  background-color: #4BE472; }

body.template-color-36 .team-heading .heading-right button i {
  color: #4BE472; }

body.template-color-36 .team-heading .heading-right button:hover {
  color: #4BE472 !important; }

body.template-color-36 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #4BE472; }

body.template-color-36 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-36 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #4BE472; }

body.template-color-36 .mini-cart .shopping-cart {
  border-bottom: 2px solid #4BE472; }
  body.template-color-36 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #4BE472; }
  body.template-color-36 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #4BE472; }
  body.template-color-36 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #4BE472;
    background-color: #4BE472; }

body.template-color-36 .brook__toolbar .inner a:hover {
  color: #4BE472; }

body.template-color-36 .blog-grid .post-inner h5.heading:hover {
  color: #4BE472; }
  body.template-color-36 .blog-grid .post-inner h5.heading:hover a {
    color: #4BE472; }

body.template-color-36 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #4BE472; }

body.template-color-36 .theme-color {
  color: #4BE472; }

body.template-color-36 .bg_color--2 {
  background: #4BE472; }

body.template-color-36 a.brook-btn.bk-btn-theme,
body.template-color-36 button.brook-btn.bk-btn-theme {
  background: #4BE472;
  color: #fff; }

body.template-color-36 .btn-essential:hover {
  background: #4BE472 !important;
  border-color: #4BE472 !important;
  color: #fff; }

body.template-color-36 .red-border {
  border-color: #4BE472 !important; }

body.template-color-36 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #4BE472;
  color: #fff; }

body.template-color-36 .single-svg-icon-box .svg-icon svg * {
  stroke: #4BE472 !important; }

body.template-color-36 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-36 .menu-price {
  color: #4BE472; }

body.template-color-36 a.moredetails-btn:hover {
  color: #4BE472; }

body.template-color-36 a.moredetails-btn i {
  color: #4BE472; }

body.template-color-36 .messonry-button button.is-checked span.filter-text {
  color: #4BE472; }

body.template-color-36 .messonry-button button:hover span.filter-text {
  color: #4BE472; }

body.template-color-36 .messonry-button button span.filter-counter {
  background-color: #4BE472; }
  body.template-color-36 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #4BE472;
    border-top-color: #4BE472; }

body.template-color-36 .messonry-button button:hover span.filter-text {
  color: #4BE472; }

body.template-color-36 .messonry-button button.is-checked span.filter-text {
  color: #4BE472; }

body.template-color-36 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #4BE472; }

body.template-color-36 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #4BE472; }

body.template-color-36 .testimonial_style--1 .content .testimonial-quote span {
  color: #4BE472; }

body.template-color-36 .testimonial_style--1:hover {
  background: #4BE472; }
  body.template-color-36 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-36 .contact-form.contact-form--4 .input-box button {
  color: #4BE472; }
  body.template-color-36 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #4BE472; }

body.template-color-36 .contact-form.contact-form--3 .input-box button {
  color: #4BE472; }

body.template-color-36 .animated-dot {
  background-color: #4BE472; }
  body.template-color-36 .animated-dot .middle-dot {
    background-color: #4BE472; }
  body.template-color-36 .animated-dot .signal {
    background-color: #4BE472; }
  body.template-color-36 .animated-dot .signal2 {
    background-color: #4BE472; }

body.template-color-36 .progress-charts .progress .progress-bar {
  background-color: #4BE472; }

body.template-color-36 .brook-counter .icon i {
  color: #4BE472; }

body.template-color-36 .social-share a:hover {
  color: #4BE472; }

body.template-color-36 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #4BE472; }

body.template-color-36 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #4BE472; }

body.template-color-36 .video-btn a.video-icon,
body.template-color-36 .video-btn a .video-icon {
  background: #4BE472; }

body.template-color-36 .video-btn-2 .icon-gradient::before {
  background-color: #4BE472;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #4BE472 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #4BE472 100%);
  background-image: linear-gradient(-133deg, #899664 0, #4BE472 100%); }

body.template-color-36 .video-btn-2 .icon-gradient::after {
  background-color: #4BE472;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #4BE472 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #4BE472 100%);
  background-image: linear-gradient(-133deg, #899664 0, #4BE472 100%); }

body.template-color-36 .single-motive-speach .icon i {
  color: #4BE472; }

body.template-color-36 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #4BE472; }
  body.template-color-36 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #4BE472; }

body.template-color-36 .contact-form input:focus,
body.template-color-36 .contact-form textarea:focus {
  border-color: #4BE472; }

body.template-color-36 .contact-form input[type="submit"],
body.template-color-36 .contact-form textarea[type="submit"] {
  border-color: #4BE472;
  background-color: #4BE472; }

body.template-color-36 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #4BE472;
  color: #fff;
  border-color: #4BE472; }

body.template-color-36 .gallery .hover-overlay {
  background-color: #4BE472; }

body.template-color-36 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #4BE472; }

body.template-color-36 .blog-grid .post-content::before {
  background-color: #4BE472; }

body.template-color-36 .blog-grid-no-flex .post-quote::before {
  color: #4BE472; }

body.template-color-36 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #4BE472; }
  body.template-color-36 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #4BE472; }

body.template-color-36 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #4BE472; }

body.template-color-36 .bl-sidebar.search button:hover {
  background-color: #4BE472; }

body.template-color-36 .bl-sidebar.search button span {
  color: #4BE472; }

body.template-color-36 .bl-sidebar.category .category-list li a:hover {
  color: #4BE472; }

body.template-color-36 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #4BE472; }

body.template-color-36 .social-icon.icon-solid-rounded li a:hover {
  background-color: #4BE472; }

body.template-color-36 .icon-box .inner .icon {
  color: #4BE472; }

body.template-color-36 .basic-thick-line-theme-4 {
  border-top: 4px solid #4BE472; }

body.template-color-36 .procing-box .header .feature-mark {
  background-color: #4BE472; }

body.template-color-36 a.brook-btn.bk-btn-dark:hover {
  background: #4BE472; }

body.template-color-36 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #4BE472; }

body.template-color-36 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #4BE472; }

body.template-color-36 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #4BE472 !important; }

body.template-color-36 .basic-modern-dots .dot {
  background-color: #4BE472; }

body.template-color-36 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #4BE472; }

body.template-color-36 a.brook-btn.bk-btn-theme-border,
body.template-color-36 button.brook-btn.bk-btn-theme-border {
  border-color: #4BE472;
  color: #4BE472; }
  body.template-color-36 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-36 button.brook-btn.bk-btn-theme-border:hover {
    background: #4BE472;
    color: #ffffff; }

body.template-color-36 .brook-quote::before {
  color: #4BE472; }

body.template-color-36 .bk-text-box.bg-theme-color {
  background: #4BE472; }

body.template-color-36 .bk-list--2 .list-header.with-number--theme .marker {
  color: #4BE472; }

body.template-color-36 .view-more-btn a span.btn-arrow {
  color: #4BE472; }

body.template-color-36 #fp-nav ul li a.active {
  background-color: #4BE472; }

body.template-color-37 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #DA9D55; }

body.template-color-37 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #DA9D55; }

body.template-color-37 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #DA9D55; }

body.template-color-37 .faq-content h2 {
  color: #DA9D55; }

body.template-color-37 .brook-section-title-corona h2 {
  color: #DA9D55; }

body.template-color-37 .single-contagion .contagion-content h3 {
  color: #DA9D55; }

body.template-color-37 .single-sympotom .sympotom-content h3 {
  color: #DA9D55; }

body.template-color-37 .single-prevination .prevination-content h3 {
  color: #DA9D55; }

body.template-color-37 .service.service--1.theme-color {
  background: #DA9D55; }

body.template-color-37 .blog-grid-minimal.outline.bg-transparant {
  border-color: #DA9D55; }
  body.template-color-37 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #DA9D55; }

body.template-color-37 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-37 .single-svg-icon-box.business-icon-box:hover {
  background: #DA9D55; }

body.template-color-37 .product_review .description_nav a.active, body.template-color-37 .product_review .description_nav a:hover {
  border-bottom: 3px solid #DA9D55; }

body.template-color-37 .single-product-details-side .product-action a {
  border-color: #DA9D55;
  background-color: #DA9D55; }

body.template-color-37 .cart-table td.pro-subtotal span {
  color: #DA9D55; }

body.template-color-37 .cart-table td.pro-addtocart button {
  border: 1px solid #DA9D55;
  background-color: #DA9D55; }

body.template-color-37 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #DA9D55; }

body.template-color-37 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #DA9D55; }

body.template-color-37 .slider-business .video-btn a .video-icon.secondary-color {
  background: #DA9D55 !important; }

body.template-color-37 a.brook-btn.bk-btn-yellow, body.template-color-37 button.brook-btn.bk-btn-yellow {
  background: #DA9D55;
  color: #fff !important; }

body.template-color-37 a.bk-btn.theme-btn, body.template-color-37 button.bk-btn.theme-btn {
  background-color: #DA9D55; }

body.template-color-37 a.brook-btn.bk-btn-red, body.template-color-37 button.brook-btn.bk-btn-red {
  background: #DA9D55; }

body.template-color-37 .section-title h3 {
  color: #DA9D55; }

body.template-color-37 .service.service--1:hover::before {
  border-color: #DA9D55; }

body.template-color-37 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-37 .service.service--1 .icons i {
    color: #DA9D55;
    font-size: 80px; }

body.template-color-37 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #DA9D55;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-37 .service.service--1 .content a.service-btn:hover {
  color: #DA9D55; }
  body.template-color-37 .service.service--1 .content a.service-btn:hover i {
    color: #DA9D55; }

body.template-color-37 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-37 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-37 .service.service--3 .content h3 a:hover {
  color: #DA9D55; }

body.template-color-37 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-37 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-37 .title-separator.color-red::after, body.template-color-37 .title-separator.color-blue::after {
    background: #DA9D55; }

body.template-color-37 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-37 .bk-gradation .item-grid .dot-wrap .dot {
    background: #DA9D55; }
  body.template-color-37 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #DA9D55; }
  body.template-color-37 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #DA9D55; }
  body.template-color-37 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #DA9D55; }

body.template-color-37 .post-nav-lisnt .nav-item a:hover {
  background: #DA9D55; }

body.template-color-37 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #DA9D55; }

body.template-color-37 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #DA9D55; }

body.template-color-37 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #DA9D55; }

body.template-color-37 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #DA9D55; }

body.template-color-37 .team.team__style--2 .thumb .overlay {
  background-color: #DA9D55; }
  body.template-color-37 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #DA9D55; }

body.template-color-37 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-37 .team.team__style--3 .thumb .overlay {
  background-color: #DA9D55; }
  body.template-color-37 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #DA9D55; }

body.template-color-37 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-37 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #DA9D55; }
  body.template-color-37 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #DA9D55; }
  body.template-color-37 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #DA9D55; }
  body.template-color-37 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-37 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #DA9D55; }
  body.template-color-37 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #DA9D55;
    color: #fff; }

body.template-color-37 [data-overlay]:before {
  background-color: #DA9D55; }

body.template-color-37 .team-heading .heading-right button i {
  color: #DA9D55; }

body.template-color-37 .team-heading .heading-right button:hover {
  color: #DA9D55 !important; }

body.template-color-37 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #DA9D55; }

body.template-color-37 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-37 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #DA9D55; }

body.template-color-37 .mini-cart .shopping-cart {
  border-bottom: 2px solid #DA9D55; }
  body.template-color-37 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #DA9D55; }
  body.template-color-37 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #DA9D55; }
  body.template-color-37 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #DA9D55;
    background-color: #DA9D55; }

body.template-color-37 .brook__toolbar .inner a:hover {
  color: #DA9D55; }

body.template-color-37 .blog-grid .post-inner h5.heading:hover {
  color: #DA9D55; }
  body.template-color-37 .blog-grid .post-inner h5.heading:hover a {
    color: #DA9D55; }

body.template-color-37 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #DA9D55; }

body.template-color-37 .theme-color {
  color: #DA9D55; }

body.template-color-37 .bg_color--2 {
  background: #DA9D55; }

body.template-color-37 a.brook-btn.bk-btn-theme,
body.template-color-37 button.brook-btn.bk-btn-theme {
  background: #DA9D55;
  color: #fff; }

body.template-color-37 .btn-essential:hover {
  background: #DA9D55 !important;
  border-color: #DA9D55 !important;
  color: #fff; }

body.template-color-37 .red-border {
  border-color: #DA9D55 !important; }

body.template-color-37 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #DA9D55;
  color: #fff; }

body.template-color-37 .single-svg-icon-box .svg-icon svg * {
  stroke: #DA9D55 !important; }

body.template-color-37 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-37 .menu-price {
  color: #DA9D55; }

body.template-color-37 a.moredetails-btn:hover {
  color: #DA9D55; }

body.template-color-37 a.moredetails-btn i {
  color: #DA9D55; }

body.template-color-37 .messonry-button button.is-checked span.filter-text {
  color: #DA9D55; }

body.template-color-37 .messonry-button button:hover span.filter-text {
  color: #DA9D55; }

body.template-color-37 .messonry-button button span.filter-counter {
  background-color: #DA9D55; }
  body.template-color-37 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #DA9D55;
    border-top-color: #DA9D55; }

body.template-color-37 .messonry-button button:hover span.filter-text {
  color: #DA9D55; }

body.template-color-37 .messonry-button button.is-checked span.filter-text {
  color: #DA9D55; }

body.template-color-37 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #DA9D55; }

body.template-color-37 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #DA9D55; }

body.template-color-37 .testimonial_style--1 .content .testimonial-quote span {
  color: #DA9D55; }

body.template-color-37 .testimonial_style--1:hover {
  background: #DA9D55; }
  body.template-color-37 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-37 .contact-form.contact-form--4 .input-box button {
  color: #DA9D55; }
  body.template-color-37 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #DA9D55; }

body.template-color-37 .contact-form.contact-form--3 .input-box button {
  color: #DA9D55; }

body.template-color-37 .animated-dot {
  background-color: #DA9D55; }
  body.template-color-37 .animated-dot .middle-dot {
    background-color: #DA9D55; }
  body.template-color-37 .animated-dot .signal {
    background-color: #DA9D55; }
  body.template-color-37 .animated-dot .signal2 {
    background-color: #DA9D55; }

body.template-color-37 .progress-charts .progress .progress-bar {
  background-color: #DA9D55; }

body.template-color-37 .brook-counter .icon i {
  color: #DA9D55; }

body.template-color-37 .social-share a:hover {
  color: #DA9D55; }

body.template-color-37 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #DA9D55; }

body.template-color-37 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #DA9D55; }

body.template-color-37 .video-btn a.video-icon,
body.template-color-37 .video-btn a .video-icon {
  background: #DA9D55; }

body.template-color-37 .video-btn-2 .icon-gradient::before {
  background-color: #DA9D55;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #DA9D55 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #DA9D55 100%);
  background-image: linear-gradient(-133deg, #899664 0, #DA9D55 100%); }

body.template-color-37 .video-btn-2 .icon-gradient::after {
  background-color: #DA9D55;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #DA9D55 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #DA9D55 100%);
  background-image: linear-gradient(-133deg, #899664 0, #DA9D55 100%); }

body.template-color-37 .single-motive-speach .icon i {
  color: #DA9D55; }

body.template-color-37 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #DA9D55; }
  body.template-color-37 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #DA9D55; }

body.template-color-37 .contact-form input:focus,
body.template-color-37 .contact-form textarea:focus {
  border-color: #DA9D55; }

body.template-color-37 .contact-form input[type="submit"],
body.template-color-37 .contact-form textarea[type="submit"] {
  border-color: #DA9D55;
  background-color: #DA9D55; }

body.template-color-37 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #DA9D55;
  color: #fff;
  border-color: #DA9D55; }

body.template-color-37 .gallery .hover-overlay {
  background-color: #DA9D55; }

body.template-color-37 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #DA9D55; }

body.template-color-37 .blog-grid .post-content::before {
  background-color: #DA9D55; }

body.template-color-37 .blog-grid-no-flex .post-quote::before {
  color: #DA9D55; }

body.template-color-37 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #DA9D55; }
  body.template-color-37 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #DA9D55; }

body.template-color-37 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #DA9D55; }

body.template-color-37 .bl-sidebar.search button:hover {
  background-color: #DA9D55; }

body.template-color-37 .bl-sidebar.search button span {
  color: #DA9D55; }

body.template-color-37 .bl-sidebar.category .category-list li a:hover {
  color: #DA9D55; }

body.template-color-37 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #DA9D55; }

body.template-color-37 .social-icon.icon-solid-rounded li a:hover {
  background-color: #DA9D55; }

body.template-color-37 .icon-box .inner .icon {
  color: #DA9D55; }

body.template-color-37 .basic-thick-line-theme-4 {
  border-top: 4px solid #DA9D55; }

body.template-color-37 .procing-box .header .feature-mark {
  background-color: #DA9D55; }

body.template-color-37 a.brook-btn.bk-btn-dark:hover {
  background: #DA9D55; }

body.template-color-37 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #DA9D55; }

body.template-color-37 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #DA9D55; }

body.template-color-37 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #DA9D55 !important; }

body.template-color-37 .basic-modern-dots .dot {
  background-color: #DA9D55; }

body.template-color-37 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #DA9D55; }

body.template-color-37 a.brook-btn.bk-btn-theme-border,
body.template-color-37 button.brook-btn.bk-btn-theme-border {
  border-color: #DA9D55;
  color: #DA9D55; }
  body.template-color-37 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-37 button.brook-btn.bk-btn-theme-border:hover {
    background: #DA9D55;
    color: #ffffff; }

body.template-color-37 .brook-quote::before {
  color: #DA9D55; }

body.template-color-37 .bk-text-box.bg-theme-color {
  background: #DA9D55; }

body.template-color-37 .bk-list--2 .list-header.with-number--theme .marker {
  color: #DA9D55; }

body.template-color-37 .view-more-btn a span.btn-arrow {
  color: #DA9D55; }

body.template-color-37 #fp-nav ul li a.active {
  background-color: #DA9D55; }

body.template-color-38 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #3264F5; }

body.template-color-38 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #3264F5; }

body.template-color-38 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #3264F5; }

body.template-color-38 .faq-content h2 {
  color: #3264F5; }

body.template-color-38 .brook-section-title-corona h2 {
  color: #3264F5; }

body.template-color-38 .single-contagion .contagion-content h3 {
  color: #3264F5; }

body.template-color-38 .single-sympotom .sympotom-content h3 {
  color: #3264F5; }

body.template-color-38 .single-prevination .prevination-content h3 {
  color: #3264F5; }

body.template-color-38 .service.service--1.theme-color {
  background: #3264F5; }

body.template-color-38 .blog-grid-minimal.outline.bg-transparant {
  border-color: #3264F5; }
  body.template-color-38 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #3264F5; }

body.template-color-38 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-38 .single-svg-icon-box.business-icon-box:hover {
  background: #3264F5; }

body.template-color-38 .product_review .description_nav a.active, body.template-color-38 .product_review .description_nav a:hover {
  border-bottom: 3px solid #3264F5; }

body.template-color-38 .single-product-details-side .product-action a {
  border-color: #3264F5;
  background-color: #3264F5; }

body.template-color-38 .cart-table td.pro-subtotal span {
  color: #3264F5; }

body.template-color-38 .cart-table td.pro-addtocart button {
  border: 1px solid #3264F5;
  background-color: #3264F5; }

body.template-color-38 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #3264F5; }

body.template-color-38 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #3264F5; }

body.template-color-38 .slider-business .video-btn a .video-icon.secondary-color {
  background: #3264F5 !important; }

body.template-color-38 a.brook-btn.bk-btn-yellow, body.template-color-38 button.brook-btn.bk-btn-yellow {
  background: #3264F5;
  color: #fff !important; }

body.template-color-38 a.bk-btn.theme-btn, body.template-color-38 button.bk-btn.theme-btn {
  background-color: #3264F5; }

body.template-color-38 a.brook-btn.bk-btn-red, body.template-color-38 button.brook-btn.bk-btn-red {
  background: #3264F5; }

body.template-color-38 .section-title h3 {
  color: #3264F5; }

body.template-color-38 .service.service--1:hover::before {
  border-color: #3264F5; }

body.template-color-38 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-38 .service.service--1 .icons i {
    color: #3264F5;
    font-size: 80px; }

body.template-color-38 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #3264F5;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-38 .service.service--1 .content a.service-btn:hover {
  color: #3264F5; }
  body.template-color-38 .service.service--1 .content a.service-btn:hover i {
    color: #3264F5; }

body.template-color-38 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-38 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-38 .service.service--3 .content h3 a:hover {
  color: #3264F5; }

body.template-color-38 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-38 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-38 .title-separator.color-red::after, body.template-color-38 .title-separator.color-blue::after {
    background: #3264F5; }

body.template-color-38 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-38 .bk-gradation .item-grid .dot-wrap .dot {
    background: #3264F5; }
  body.template-color-38 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #3264F5; }
  body.template-color-38 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #3264F5; }
  body.template-color-38 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #3264F5; }

body.template-color-38 .post-nav-lisnt .nav-item a:hover {
  background: #3264F5; }

body.template-color-38 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #3264F5; }

body.template-color-38 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #3264F5; }

body.template-color-38 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #3264F5; }

body.template-color-38 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #3264F5; }

body.template-color-38 .team.team__style--2 .thumb .overlay {
  background-color: #3264F5; }
  body.template-color-38 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #3264F5; }

body.template-color-38 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-38 .team.team__style--3 .thumb .overlay {
  background-color: #3264F5; }
  body.template-color-38 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #3264F5; }

body.template-color-38 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-38 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #3264F5; }
  body.template-color-38 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #3264F5; }
  body.template-color-38 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #3264F5; }
  body.template-color-38 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-38 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #3264F5; }
  body.template-color-38 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #3264F5;
    color: #fff; }

body.template-color-38 [data-overlay]:before {
  background-color: #3264F5; }

body.template-color-38 .team-heading .heading-right button i {
  color: #3264F5; }

body.template-color-38 .team-heading .heading-right button:hover {
  color: #3264F5 !important; }

body.template-color-38 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #3264F5; }

body.template-color-38 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-38 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #3264F5; }

body.template-color-38 .mini-cart .shopping-cart {
  border-bottom: 2px solid #3264F5; }
  body.template-color-38 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #3264F5; }
  body.template-color-38 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #3264F5; }
  body.template-color-38 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #3264F5;
    background-color: #3264F5; }

body.template-color-38 .brook__toolbar .inner a:hover {
  color: #3264F5; }

body.template-color-38 .blog-grid .post-inner h5.heading:hover {
  color: #3264F5; }
  body.template-color-38 .blog-grid .post-inner h5.heading:hover a {
    color: #3264F5; }

body.template-color-38 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #3264F5; }

body.template-color-38 .theme-color {
  color: #3264F5; }

body.template-color-38 .bg_color--2 {
  background: #3264F5; }

body.template-color-38 a.brook-btn.bk-btn-theme,
body.template-color-38 button.brook-btn.bk-btn-theme {
  background: #3264F5;
  color: #fff; }

body.template-color-38 .btn-essential:hover {
  background: #3264F5 !important;
  border-color: #3264F5 !important;
  color: #fff; }

body.template-color-38 .red-border {
  border-color: #3264F5 !important; }

body.template-color-38 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #3264F5;
  color: #fff; }

body.template-color-38 .single-svg-icon-box .svg-icon svg * {
  stroke: #3264F5 !important; }

body.template-color-38 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-38 .menu-price {
  color: #3264F5; }

body.template-color-38 a.moredetails-btn:hover {
  color: #3264F5; }

body.template-color-38 a.moredetails-btn i {
  color: #3264F5; }

body.template-color-38 .messonry-button button.is-checked span.filter-text {
  color: #3264F5; }

body.template-color-38 .messonry-button button:hover span.filter-text {
  color: #3264F5; }

body.template-color-38 .messonry-button button span.filter-counter {
  background-color: #3264F5; }
  body.template-color-38 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #3264F5;
    border-top-color: #3264F5; }

body.template-color-38 .messonry-button button:hover span.filter-text {
  color: #3264F5; }

body.template-color-38 .messonry-button button.is-checked span.filter-text {
  color: #3264F5; }

body.template-color-38 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #3264F5; }

body.template-color-38 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #3264F5; }

body.template-color-38 .testimonial_style--1 .content .testimonial-quote span {
  color: #3264F5; }

body.template-color-38 .testimonial_style--1:hover {
  background: #3264F5; }
  body.template-color-38 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-38 .contact-form.contact-form--4 .input-box button {
  color: #3264F5; }
  body.template-color-38 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #3264F5; }

body.template-color-38 .contact-form.contact-form--3 .input-box button {
  color: #3264F5; }

body.template-color-38 .animated-dot {
  background-color: #3264F5; }
  body.template-color-38 .animated-dot .middle-dot {
    background-color: #3264F5; }
  body.template-color-38 .animated-dot .signal {
    background-color: #3264F5; }
  body.template-color-38 .animated-dot .signal2 {
    background-color: #3264F5; }

body.template-color-38 .progress-charts .progress .progress-bar {
  background-color: #3264F5; }

body.template-color-38 .brook-counter .icon i {
  color: #3264F5; }

body.template-color-38 .social-share a:hover {
  color: #3264F5; }

body.template-color-38 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #3264F5; }

body.template-color-38 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #3264F5; }

body.template-color-38 .video-btn a.video-icon,
body.template-color-38 .video-btn a .video-icon {
  background: #3264F5; }

body.template-color-38 .video-btn-2 .icon-gradient::before {
  background-color: #3264F5;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #3264F5 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #3264F5 100%);
  background-image: linear-gradient(-133deg, #899664 0, #3264F5 100%); }

body.template-color-38 .video-btn-2 .icon-gradient::after {
  background-color: #3264F5;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #3264F5 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #3264F5 100%);
  background-image: linear-gradient(-133deg, #899664 0, #3264F5 100%); }

body.template-color-38 .single-motive-speach .icon i {
  color: #3264F5; }

body.template-color-38 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #3264F5; }
  body.template-color-38 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #3264F5; }

body.template-color-38 .contact-form input:focus,
body.template-color-38 .contact-form textarea:focus {
  border-color: #3264F5; }

body.template-color-38 .contact-form input[type="submit"],
body.template-color-38 .contact-form textarea[type="submit"] {
  border-color: #3264F5;
  background-color: #3264F5; }

body.template-color-38 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #3264F5;
  color: #fff;
  border-color: #3264F5; }

body.template-color-38 .gallery .hover-overlay {
  background-color: #3264F5; }

body.template-color-38 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #3264F5; }

body.template-color-38 .blog-grid .post-content::before {
  background-color: #3264F5; }

body.template-color-38 .blog-grid-no-flex .post-quote::before {
  color: #3264F5; }

body.template-color-38 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #3264F5; }
  body.template-color-38 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #3264F5; }

body.template-color-38 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #3264F5; }

body.template-color-38 .bl-sidebar.search button:hover {
  background-color: #3264F5; }

body.template-color-38 .bl-sidebar.search button span {
  color: #3264F5; }

body.template-color-38 .bl-sidebar.category .category-list li a:hover {
  color: #3264F5; }

body.template-color-38 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #3264F5; }

body.template-color-38 .social-icon.icon-solid-rounded li a:hover {
  background-color: #3264F5; }

body.template-color-38 .icon-box .inner .icon {
  color: #3264F5; }

body.template-color-38 .basic-thick-line-theme-4 {
  border-top: 4px solid #3264F5; }

body.template-color-38 .procing-box .header .feature-mark {
  background-color: #3264F5; }

body.template-color-38 a.brook-btn.bk-btn-dark:hover {
  background: #3264F5; }

body.template-color-38 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #3264F5; }

body.template-color-38 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #3264F5; }

body.template-color-38 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #3264F5 !important; }

body.template-color-38 .basic-modern-dots .dot {
  background-color: #3264F5; }

body.template-color-38 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #3264F5; }

body.template-color-38 a.brook-btn.bk-btn-theme-border,
body.template-color-38 button.brook-btn.bk-btn-theme-border {
  border-color: #3264F5;
  color: #3264F5; }
  body.template-color-38 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-38 button.brook-btn.bk-btn-theme-border:hover {
    background: #3264F5;
    color: #ffffff; }

body.template-color-38 .brook-quote::before {
  color: #3264F5; }

body.template-color-38 .bk-text-box.bg-theme-color {
  background: #3264F5; }

body.template-color-38 .bk-list--2 .list-header.with-number--theme .marker {
  color: #3264F5; }

body.template-color-38 .view-more-btn a span.btn-arrow {
  color: #3264F5; }

body.template-color-38 #fp-nav ul li a.active {
  background-color: #3264F5; }

body.template-color-39 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #FFC851; }

body.template-color-39 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #FFC851; }

body.template-color-39 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #FFC851; }

body.template-color-39 .faq-content h2 {
  color: #FFC851; }

body.template-color-39 .brook-section-title-corona h2 {
  color: #FFC851; }

body.template-color-39 .single-contagion .contagion-content h3 {
  color: #FFC851; }

body.template-color-39 .single-sympotom .sympotom-content h3 {
  color: #FFC851; }

body.template-color-39 .single-prevination .prevination-content h3 {
  color: #FFC851; }

body.template-color-39 .service.service--1.theme-color {
  background: #FFC851; }

body.template-color-39 .blog-grid-minimal.outline.bg-transparant {
  border-color: #FFC851; }
  body.template-color-39 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #FFC851; }

body.template-color-39 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-39 .single-svg-icon-box.business-icon-box:hover {
  background: #FFC851; }

body.template-color-39 .product_review .description_nav a.active, body.template-color-39 .product_review .description_nav a:hover {
  border-bottom: 3px solid #FFC851; }

body.template-color-39 .single-product-details-side .product-action a {
  border-color: #FFC851;
  background-color: #FFC851; }

body.template-color-39 .cart-table td.pro-subtotal span {
  color: #FFC851; }

body.template-color-39 .cart-table td.pro-addtocart button {
  border: 1px solid #FFC851;
  background-color: #FFC851; }

body.template-color-39 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #FFC851; }

body.template-color-39 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #FFC851; }

body.template-color-39 .slider-business .video-btn a .video-icon.secondary-color {
  background: #FFC851 !important; }

body.template-color-39 a.brook-btn.bk-btn-yellow, body.template-color-39 button.brook-btn.bk-btn-yellow {
  background: #FFC851;
  color: #fff !important; }

body.template-color-39 a.bk-btn.theme-btn, body.template-color-39 button.bk-btn.theme-btn {
  background-color: #FFC851; }

body.template-color-39 a.brook-btn.bk-btn-red, body.template-color-39 button.brook-btn.bk-btn-red {
  background: #FFC851; }

body.template-color-39 .section-title h3 {
  color: #FFC851; }

body.template-color-39 .service.service--1:hover::before {
  border-color: #FFC851; }

body.template-color-39 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-39 .service.service--1 .icons i {
    color: #FFC851;
    font-size: 80px; }

body.template-color-39 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #FFC851;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-39 .service.service--1 .content a.service-btn:hover {
  color: #FFC851; }
  body.template-color-39 .service.service--1 .content a.service-btn:hover i {
    color: #FFC851; }

body.template-color-39 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-39 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-39 .service.service--3 .content h3 a:hover {
  color: #FFC851; }

body.template-color-39 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-39 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-39 .title-separator.color-red::after, body.template-color-39 .title-separator.color-blue::after {
    background: #FFC851; }

body.template-color-39 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-39 .bk-gradation .item-grid .dot-wrap .dot {
    background: #FFC851; }
  body.template-color-39 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #FFC851; }
  body.template-color-39 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #FFC851; }
  body.template-color-39 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #FFC851; }

body.template-color-39 .post-nav-lisnt .nav-item a:hover {
  background: #FFC851; }

body.template-color-39 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #FFC851; }

body.template-color-39 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #FFC851; }

body.template-color-39 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #FFC851; }

body.template-color-39 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #FFC851; }

body.template-color-39 .team.team__style--2 .thumb .overlay {
  background-color: #FFC851; }
  body.template-color-39 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #FFC851; }

body.template-color-39 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-39 .team.team__style--3 .thumb .overlay {
  background-color: #FFC851; }
  body.template-color-39 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #FFC851; }

body.template-color-39 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-39 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #FFC851; }
  body.template-color-39 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #FFC851; }
  body.template-color-39 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #FFC851; }
  body.template-color-39 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-39 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #FFC851; }
  body.template-color-39 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #FFC851;
    color: #fff; }

body.template-color-39 [data-overlay]:before {
  background-color: #FFC851; }

body.template-color-39 .team-heading .heading-right button i {
  color: #FFC851; }

body.template-color-39 .team-heading .heading-right button:hover {
  color: #FFC851 !important; }

body.template-color-39 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #FFC851; }

body.template-color-39 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-39 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #FFC851; }

body.template-color-39 .mini-cart .shopping-cart {
  border-bottom: 2px solid #FFC851; }
  body.template-color-39 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #FFC851; }
  body.template-color-39 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #FFC851; }
  body.template-color-39 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #FFC851;
    background-color: #FFC851; }

body.template-color-39 .brook__toolbar .inner a:hover {
  color: #FFC851; }

body.template-color-39 .blog-grid .post-inner h5.heading:hover {
  color: #FFC851; }
  body.template-color-39 .blog-grid .post-inner h5.heading:hover a {
    color: #FFC851; }

body.template-color-39 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #FFC851; }

body.template-color-39 .theme-color {
  color: #FFC851; }

body.template-color-39 .bg_color--2 {
  background: #FFC851; }

body.template-color-39 a.brook-btn.bk-btn-theme,
body.template-color-39 button.brook-btn.bk-btn-theme {
  background: #FFC851;
  color: #fff; }

body.template-color-39 .btn-essential:hover {
  background: #FFC851 !important;
  border-color: #FFC851 !important;
  color: #fff; }

body.template-color-39 .red-border {
  border-color: #FFC851 !important; }

body.template-color-39 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #FFC851;
  color: #fff; }

body.template-color-39 .single-svg-icon-box .svg-icon svg * {
  stroke: #FFC851 !important; }

body.template-color-39 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-39 .menu-price {
  color: #FFC851; }

body.template-color-39 a.moredetails-btn:hover {
  color: #FFC851; }

body.template-color-39 a.moredetails-btn i {
  color: #FFC851; }

body.template-color-39 .messonry-button button.is-checked span.filter-text {
  color: #FFC851; }

body.template-color-39 .messonry-button button:hover span.filter-text {
  color: #FFC851; }

body.template-color-39 .messonry-button button span.filter-counter {
  background-color: #FFC851; }
  body.template-color-39 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #FFC851;
    border-top-color: #FFC851; }

body.template-color-39 .messonry-button button:hover span.filter-text {
  color: #FFC851; }

body.template-color-39 .messonry-button button.is-checked span.filter-text {
  color: #FFC851; }

body.template-color-39 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #FFC851; }

body.template-color-39 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #FFC851; }

body.template-color-39 .testimonial_style--1 .content .testimonial-quote span {
  color: #FFC851; }

body.template-color-39 .testimonial_style--1:hover {
  background: #FFC851; }
  body.template-color-39 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-39 .contact-form.contact-form--4 .input-box button {
  color: #FFC851; }
  body.template-color-39 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #FFC851; }

body.template-color-39 .contact-form.contact-form--3 .input-box button {
  color: #FFC851; }

body.template-color-39 .animated-dot {
  background-color: #FFC851; }
  body.template-color-39 .animated-dot .middle-dot {
    background-color: #FFC851; }
  body.template-color-39 .animated-dot .signal {
    background-color: #FFC851; }
  body.template-color-39 .animated-dot .signal2 {
    background-color: #FFC851; }

body.template-color-39 .progress-charts .progress .progress-bar {
  background-color: #FFC851; }

body.template-color-39 .brook-counter .icon i {
  color: #FFC851; }

body.template-color-39 .social-share a:hover {
  color: #FFC851; }

body.template-color-39 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #FFC851; }

body.template-color-39 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #FFC851; }

body.template-color-39 .video-btn a.video-icon,
body.template-color-39 .video-btn a .video-icon {
  background: #FFC851; }

body.template-color-39 .video-btn-2 .icon-gradient::before {
  background-color: #FFC851;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FFC851 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FFC851 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FFC851 100%); }

body.template-color-39 .video-btn-2 .icon-gradient::after {
  background-color: #FFC851;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #FFC851 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #FFC851 100%);
  background-image: linear-gradient(-133deg, #899664 0, #FFC851 100%); }

body.template-color-39 .single-motive-speach .icon i {
  color: #FFC851; }

body.template-color-39 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #FFC851; }
  body.template-color-39 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #FFC851; }

body.template-color-39 .contact-form input:focus,
body.template-color-39 .contact-form textarea:focus {
  border-color: #FFC851; }

body.template-color-39 .contact-form input[type="submit"],
body.template-color-39 .contact-form textarea[type="submit"] {
  border-color: #FFC851;
  background-color: #FFC851; }

body.template-color-39 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #FFC851;
  color: #fff;
  border-color: #FFC851; }

body.template-color-39 .gallery .hover-overlay {
  background-color: #FFC851; }

body.template-color-39 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #FFC851; }

body.template-color-39 .blog-grid .post-content::before {
  background-color: #FFC851; }

body.template-color-39 .blog-grid-no-flex .post-quote::before {
  color: #FFC851; }

body.template-color-39 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #FFC851; }
  body.template-color-39 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #FFC851; }

body.template-color-39 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #FFC851; }

body.template-color-39 .bl-sidebar.search button:hover {
  background-color: #FFC851; }

body.template-color-39 .bl-sidebar.search button span {
  color: #FFC851; }

body.template-color-39 .bl-sidebar.category .category-list li a:hover {
  color: #FFC851; }

body.template-color-39 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #FFC851; }

body.template-color-39 .social-icon.icon-solid-rounded li a:hover {
  background-color: #FFC851; }

body.template-color-39 .icon-box .inner .icon {
  color: #FFC851; }

body.template-color-39 .basic-thick-line-theme-4 {
  border-top: 4px solid #FFC851; }

body.template-color-39 .procing-box .header .feature-mark {
  background-color: #FFC851; }

body.template-color-39 a.brook-btn.bk-btn-dark:hover {
  background: #FFC851; }

body.template-color-39 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #FFC851; }

body.template-color-39 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #FFC851; }

body.template-color-39 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #FFC851 !important; }

body.template-color-39 .basic-modern-dots .dot {
  background-color: #FFC851; }

body.template-color-39 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #FFC851; }

body.template-color-39 a.brook-btn.bk-btn-theme-border,
body.template-color-39 button.brook-btn.bk-btn-theme-border {
  border-color: #FFC851;
  color: #FFC851; }
  body.template-color-39 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-39 button.brook-btn.bk-btn-theme-border:hover {
    background: #FFC851;
    color: #ffffff; }

body.template-color-39 .brook-quote::before {
  color: #FFC851; }

body.template-color-39 .bk-text-box.bg-theme-color {
  background: #FFC851; }

body.template-color-39 .bk-list--2 .list-header.with-number--theme .marker {
  color: #FFC851; }

body.template-color-39 .view-more-btn a span.btn-arrow {
  color: #FFC851; }

body.template-color-39 #fp-nav ul li a.active {
  background-color: #FFC851; }

body.template-color-40 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #C4DCFA; }

body.template-color-40 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #C4DCFA; }

body.template-color-40 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #C4DCFA; }

body.template-color-40 .faq-content h2 {
  color: #C4DCFA; }

body.template-color-40 .brook-section-title-corona h2 {
  color: #C4DCFA; }

body.template-color-40 .single-contagion .contagion-content h3 {
  color: #C4DCFA; }

body.template-color-40 .single-sympotom .sympotom-content h3 {
  color: #C4DCFA; }

body.template-color-40 .single-prevination .prevination-content h3 {
  color: #C4DCFA; }

body.template-color-40 .service.service--1.theme-color {
  background: #C4DCFA; }

body.template-color-40 .blog-grid-minimal.outline.bg-transparant {
  border-color: #C4DCFA; }
  body.template-color-40 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #C4DCFA; }

body.template-color-40 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-40 .single-svg-icon-box.business-icon-box:hover {
  background: #C4DCFA; }

body.template-color-40 .product_review .description_nav a.active, body.template-color-40 .product_review .description_nav a:hover {
  border-bottom: 3px solid #C4DCFA; }

body.template-color-40 .single-product-details-side .product-action a {
  border-color: #C4DCFA;
  background-color: #C4DCFA; }

body.template-color-40 .cart-table td.pro-subtotal span {
  color: #C4DCFA; }

body.template-color-40 .cart-table td.pro-addtocart button {
  border: 1px solid #C4DCFA;
  background-color: #C4DCFA; }

body.template-color-40 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #C4DCFA; }

body.template-color-40 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #C4DCFA; }

body.template-color-40 .slider-business .video-btn a .video-icon.secondary-color {
  background: #C4DCFA !important; }

body.template-color-40 a.brook-btn.bk-btn-yellow, body.template-color-40 button.brook-btn.bk-btn-yellow {
  background: #C4DCFA;
  color: #fff !important; }

body.template-color-40 a.bk-btn.theme-btn, body.template-color-40 button.bk-btn.theme-btn {
  background-color: #C4DCFA; }

body.template-color-40 a.brook-btn.bk-btn-red, body.template-color-40 button.brook-btn.bk-btn-red {
  background: #C4DCFA; }

body.template-color-40 .section-title h3 {
  color: #C4DCFA; }

body.template-color-40 .service.service--1:hover::before {
  border-color: #C4DCFA; }

body.template-color-40 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-40 .service.service--1 .icons i {
    color: #C4DCFA;
    font-size: 80px; }

body.template-color-40 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #C4DCFA;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-40 .service.service--1 .content a.service-btn:hover {
  color: #C4DCFA; }
  body.template-color-40 .service.service--1 .content a.service-btn:hover i {
    color: #C4DCFA; }

body.template-color-40 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-40 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-40 .service.service--3 .content h3 a:hover {
  color: #C4DCFA; }

body.template-color-40 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-40 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-40 .title-separator.color-red::after, body.template-color-40 .title-separator.color-blue::after {
    background: #C4DCFA; }

body.template-color-40 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-40 .bk-gradation .item-grid .dot-wrap .dot {
    background: #C4DCFA; }
  body.template-color-40 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #C4DCFA; }
  body.template-color-40 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #C4DCFA; }
  body.template-color-40 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #C4DCFA; }

body.template-color-40 .post-nav-lisnt .nav-item a:hover {
  background: #C4DCFA; }

body.template-color-40 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #C4DCFA; }

body.template-color-40 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #C4DCFA; }

body.template-color-40 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #C4DCFA; }

body.template-color-40 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #C4DCFA; }

body.template-color-40 .team.team__style--2 .thumb .overlay {
  background-color: #C4DCFA; }
  body.template-color-40 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #C4DCFA; }

body.template-color-40 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-40 .team.team__style--3 .thumb .overlay {
  background-color: #C4DCFA; }
  body.template-color-40 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #C4DCFA; }

body.template-color-40 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-40 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #C4DCFA; }
  body.template-color-40 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #C4DCFA; }
  body.template-color-40 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #C4DCFA; }
  body.template-color-40 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-40 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #C4DCFA; }
  body.template-color-40 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #C4DCFA;
    color: #fff; }

body.template-color-40 [data-overlay]:before {
  background-color: #C4DCFA; }

body.template-color-40 .team-heading .heading-right button i {
  color: #C4DCFA; }

body.template-color-40 .team-heading .heading-right button:hover {
  color: #C4DCFA !important; }

body.template-color-40 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #C4DCFA; }

body.template-color-40 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-40 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #C4DCFA; }

body.template-color-40 .mini-cart .shopping-cart {
  border-bottom: 2px solid #C4DCFA; }
  body.template-color-40 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #C4DCFA; }
  body.template-color-40 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #C4DCFA; }
  body.template-color-40 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #C4DCFA;
    background-color: #C4DCFA; }

body.template-color-40 .brook__toolbar .inner a:hover {
  color: #C4DCFA; }

body.template-color-40 .blog-grid .post-inner h5.heading:hover {
  color: #C4DCFA; }
  body.template-color-40 .blog-grid .post-inner h5.heading:hover a {
    color: #C4DCFA; }

body.template-color-40 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #C4DCFA; }

body.template-color-40 .theme-color {
  color: #C4DCFA; }

body.template-color-40 .bg_color--2 {
  background: #C4DCFA; }

body.template-color-40 a.brook-btn.bk-btn-theme,
body.template-color-40 button.brook-btn.bk-btn-theme {
  background: #C4DCFA;
  color: #fff; }

body.template-color-40 .btn-essential:hover {
  background: #C4DCFA !important;
  border-color: #C4DCFA !important;
  color: #fff; }

body.template-color-40 .red-border {
  border-color: #C4DCFA !important; }

body.template-color-40 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #C4DCFA;
  color: #fff; }

body.template-color-40 .single-svg-icon-box .svg-icon svg * {
  stroke: #C4DCFA !important; }

body.template-color-40 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-40 .menu-price {
  color: #C4DCFA; }

body.template-color-40 a.moredetails-btn:hover {
  color: #C4DCFA; }

body.template-color-40 a.moredetails-btn i {
  color: #C4DCFA; }

body.template-color-40 .messonry-button button.is-checked span.filter-text {
  color: #C4DCFA; }

body.template-color-40 .messonry-button button:hover span.filter-text {
  color: #C4DCFA; }

body.template-color-40 .messonry-button button span.filter-counter {
  background-color: #C4DCFA; }
  body.template-color-40 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #C4DCFA;
    border-top-color: #C4DCFA; }

body.template-color-40 .messonry-button button:hover span.filter-text {
  color: #C4DCFA; }

body.template-color-40 .messonry-button button.is-checked span.filter-text {
  color: #C4DCFA; }

body.template-color-40 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #C4DCFA; }

body.template-color-40 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #C4DCFA; }

body.template-color-40 .testimonial_style--1 .content .testimonial-quote span {
  color: #C4DCFA; }

body.template-color-40 .testimonial_style--1:hover {
  background: #C4DCFA; }
  body.template-color-40 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-40 .contact-form.contact-form--4 .input-box button {
  color: #C4DCFA; }
  body.template-color-40 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #C4DCFA; }

body.template-color-40 .contact-form.contact-form--3 .input-box button {
  color: #C4DCFA; }

body.template-color-40 .animated-dot {
  background-color: #C4DCFA; }
  body.template-color-40 .animated-dot .middle-dot {
    background-color: #C4DCFA; }
  body.template-color-40 .animated-dot .signal {
    background-color: #C4DCFA; }
  body.template-color-40 .animated-dot .signal2 {
    background-color: #C4DCFA; }

body.template-color-40 .progress-charts .progress .progress-bar {
  background-color: #C4DCFA; }

body.template-color-40 .brook-counter .icon i {
  color: #C4DCFA; }

body.template-color-40 .social-share a:hover {
  color: #C4DCFA; }

body.template-color-40 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #C4DCFA; }

body.template-color-40 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #C4DCFA; }

body.template-color-40 .video-btn a.video-icon,
body.template-color-40 .video-btn a .video-icon {
  background: #C4DCFA; }

body.template-color-40 .video-btn-2 .icon-gradient::before {
  background-color: #C4DCFA;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #C4DCFA 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #C4DCFA 100%);
  background-image: linear-gradient(-133deg, #899664 0, #C4DCFA 100%); }

body.template-color-40 .video-btn-2 .icon-gradient::after {
  background-color: #C4DCFA;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #C4DCFA 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #C4DCFA 100%);
  background-image: linear-gradient(-133deg, #899664 0, #C4DCFA 100%); }

body.template-color-40 .single-motive-speach .icon i {
  color: #C4DCFA; }

body.template-color-40 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #C4DCFA; }
  body.template-color-40 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #C4DCFA; }

body.template-color-40 .contact-form input:focus,
body.template-color-40 .contact-form textarea:focus {
  border-color: #C4DCFA; }

body.template-color-40 .contact-form input[type="submit"],
body.template-color-40 .contact-form textarea[type="submit"] {
  border-color: #C4DCFA;
  background-color: #C4DCFA; }

body.template-color-40 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #C4DCFA;
  color: #fff;
  border-color: #C4DCFA; }

body.template-color-40 .gallery .hover-overlay {
  background-color: #C4DCFA; }

body.template-color-40 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #C4DCFA; }

body.template-color-40 .blog-grid .post-content::before {
  background-color: #C4DCFA; }

body.template-color-40 .blog-grid-no-flex .post-quote::before {
  color: #C4DCFA; }

body.template-color-40 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #C4DCFA; }
  body.template-color-40 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #C4DCFA; }

body.template-color-40 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #C4DCFA; }

body.template-color-40 .bl-sidebar.search button:hover {
  background-color: #C4DCFA; }

body.template-color-40 .bl-sidebar.search button span {
  color: #C4DCFA; }

body.template-color-40 .bl-sidebar.category .category-list li a:hover {
  color: #C4DCFA; }

body.template-color-40 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #C4DCFA; }

body.template-color-40 .social-icon.icon-solid-rounded li a:hover {
  background-color: #C4DCFA; }

body.template-color-40 .icon-box .inner .icon {
  color: #C4DCFA; }

body.template-color-40 .basic-thick-line-theme-4 {
  border-top: 4px solid #C4DCFA; }

body.template-color-40 .procing-box .header .feature-mark {
  background-color: #C4DCFA; }

body.template-color-40 a.brook-btn.bk-btn-dark:hover {
  background: #C4DCFA; }

body.template-color-40 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #C4DCFA; }

body.template-color-40 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #C4DCFA; }

body.template-color-40 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #C4DCFA !important; }

body.template-color-40 .basic-modern-dots .dot {
  background-color: #C4DCFA; }

body.template-color-40 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #C4DCFA; }

body.template-color-40 a.brook-btn.bk-btn-theme-border,
body.template-color-40 button.brook-btn.bk-btn-theme-border {
  border-color: #C4DCFA;
  color: #C4DCFA; }
  body.template-color-40 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-40 button.brook-btn.bk-btn-theme-border:hover {
    background: #C4DCFA;
    color: #ffffff; }

body.template-color-40 .brook-quote::before {
  color: #C4DCFA; }

body.template-color-40 .bk-text-box.bg-theme-color {
  background: #C4DCFA; }

body.template-color-40 .bk-list--2 .list-header.with-number--theme .marker {
  color: #C4DCFA; }

body.template-color-40 .view-more-btn a span.btn-arrow {
  color: #C4DCFA; }

body.template-color-40 #fp-nav ul li a.active {
  background-color: #C4DCFA; }

body.template-color-41 .blog-grid .post-content .post-inner h5.heading a:hover {
  color: #7F48B0; }

body.template-color-41 .blog-grid .post-content .post-inner h4.heading a:hover {
  color: #7F48B0; }

body.template-color-41 .blog-grid .post-content .post-inner h3.heading a:hover {
  color: #7F48B0; }

body.template-color-41 .faq-content h2 {
  color: #7F48B0; }

body.template-color-41 .brook-section-title-corona h2 {
  color: #7F48B0; }

body.template-color-41 .single-contagion .contagion-content h3 {
  color: #7F48B0; }

body.template-color-41 .single-sympotom .sympotom-content h3 {
  color: #7F48B0; }

body.template-color-41 .single-prevination .prevination-content h3 {
  color: #7F48B0; }

body.template-color-41 .service.service--1.theme-color {
  background: #7F48B0; }

body.template-color-41 .blog-grid-minimal.outline.bg-transparant {
  border-color: #7F48B0; }
  body.template-color-41 .blog-grid-minimal.outline.bg-transparant .post-content .post-meta .post-category a {
    color: #7F48B0; }

body.template-color-41 .blog-grid-minimal.outline:hover {
  border-color: transparent; }

body.template-color-41 .single-svg-icon-box.business-icon-box:hover {
  background: #7F48B0; }

body.template-color-41 .product_review .description_nav a.active, body.template-color-41 .product_review .description_nav a:hover {
  border-bottom: 3px solid #7F48B0; }

body.template-color-41 .single-product-details-side .product-action a {
  border-color: #7F48B0;
  background-color: #7F48B0; }

body.template-color-41 .cart-table td.pro-subtotal span {
  color: #7F48B0; }

body.template-color-41 .cart-table td.pro-addtocart button {
  border: 1px solid #7F48B0;
  background-color: #7F48B0; }

body.template-color-41 .team.team_style--4 .inner .content-header .social-network .social-list a:hover {
  color: #7F48B0; }

body.template-color-41 .testimonial--horizontal--active .swiper-pagination-custom .progressbar .filled {
  background: #7F48B0; }

body.template-color-41 .slider-business .video-btn a .video-icon.secondary-color {
  background: #7F48B0 !important; }

body.template-color-41 a.brook-btn.bk-btn-yellow, body.template-color-41 button.brook-btn.bk-btn-yellow {
  background: #7F48B0;
  color: #fff !important; }

body.template-color-41 a.bk-btn.theme-btn, body.template-color-41 button.bk-btn.theme-btn {
  background-color: #7F48B0; }

body.template-color-41 a.brook-btn.bk-btn-red, body.template-color-41 button.brook-btn.bk-btn-red {
  background: #7F48B0; }

body.template-color-41 .section-title h3 {
  color: #7F48B0; }

body.template-color-41 .service.service--1:hover::before {
  border-color: #7F48B0; }

body.template-color-41 .service.service--1 .icons {
  margin-bottom: 22px; }
  body.template-color-41 .service.service--1 .icons i {
    color: #7F48B0;
    font-size: 80px; }

body.template-color-41 .service.service--1 .content a.service-btn i {
  font-size: 12px;
  left: auto;
  right: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  margin-left: 15px;
  color: #7F48B0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

body.template-color-41 .service.service--1 .content a.service-btn:hover {
  color: #7F48B0; }
  body.template-color-41 .service.service--1 .content a.service-btn:hover i {
    color: #7F48B0; }

body.template-color-41 .service.service--3 .thumb {
  overflow: hidden; }
  body.template-color-41 .service.service--3 .thumb a img {
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

body.template-color-41 .service.service--3 .content h3 a:hover {
  color: #7F48B0; }

body.template-color-41 .title-separator {
  display: block;
  line-height: 0px; }
  body.template-color-41 .title-separator::after {
    content: "";
    display: inline-block;
    height: 3px;
    border-radius: 3px; }
  body.template-color-41 .title-separator.color-red::after, body.template-color-41 .title-separator.color-blue::after {
    background: #7F48B0; }

body.template-color-41 .bk-gradation .item-grid {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  padding: 0 10px; }
  body.template-color-41 .bk-gradation .item-grid .dot-wrap .dot {
    background: #7F48B0; }
  body.template-color-41 .bk-gradation .item-grid.custom-color--2 .dot-wrap .dot {
    background: #7F48B0; }
  body.template-color-41 .bk-gradation .item-grid.custom-color--3 .dot-wrap .dot {
    background: #7F48B0; }
  body.template-color-41 .bk-gradation .item-grid.custom-color--4 .dot-wrap .dot {
    background: #7F48B0; }

body.template-color-41 .post-nav-lisnt .nav-item a:hover {
  background: #7F48B0; }

body.template-color-41 .portfolio-nav-list .portfolio-page .inner a:hover {
  color: #7F48B0; }

body.template-color-41 .team.team_style--1 .image-wrap .thumb .overlay.red-color {
  background-color: #7F48B0; }

body.template-color-41 .team.team_style--1 .image-wrap .social-networks .inner a[class*=hint--]:after {
  background-color: #7F48B0; }

body.template-color-41 .team.team_style--1 .image-wrap .social-networks .inner a.hint--top::before {
  border-top-color: #7F48B0; }

body.template-color-41 .team.team__style--2 .thumb .overlay {
  background-color: #7F48B0; }
  body.template-color-41 .team.team__style--2 .thumb .overlay.red-color {
    background-color: #7F48B0; }

body.template-color-41 .team.team__style--2:hover .thumb {
  border-color: rgba(0, 56, 227, 0.1); }

body.template-color-41 .team.team__style--3 .thumb .overlay {
  background-color: #7F48B0; }
  body.template-color-41 .team.team__style--3 .thumb .overlay.red-color {
    background-color: #7F48B0; }

body.template-color-41 .blog-grid--modern.blog-standard {
  /* -------------------
                                Yellow Color  
                                ---------------------*/ }
  body.template-color-41 .blog-grid--modern.blog-standard .post-thumb::after {
    background: #7F48B0; }
  body.template-color-41 .blog-grid--modern.blog-standard .post-read-more {
    background-color: #7F48B0; }
  body.template-color-41 .blog-grid--modern.blog-standard.blog-yellow-color .post-thumb::after {
    background: #7F48B0; }
  body.template-color-41 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner h5.heading a:hover, body.template-color-41 .blog-grid--modern.blog-standard.blog-yellow-color .post-content .post-inner .post-meta .post-category a:hover {
    color: #7F48B0; }
  body.template-color-41 .blog-grid--modern.blog-standard.blog-yellow-color .post-read-more {
    background-color: #7F48B0;
    color: #fff; }

body.template-color-41 [data-overlay]:before {
  background-color: #7F48B0; }

body.template-color-41 .team-heading .heading-right button i {
  color: #7F48B0; }

body.template-color-41 .team-heading .heading-right button:hover {
  color: #7F48B0 !important; }

body.template-color-41 .has-center-content .has-centerinner .content h1.heading::before {
  background-color: #7F48B0; }

body.template-color-41 .has-center-content .has-centerinner .content .read-more-btn a i, body.template-color-41 .has-center-content .has-centerinner .content .read-more-btn a:hover {
  color: #7F48B0; }

body.template-color-41 .mini-cart .shopping-cart {
  border-bottom: 2px solid #7F48B0; }
  body.template-color-41 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 a:hover {
    color: #7F48B0; }
  body.template-color-41 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .delete-btn:hover {
    color: #7F48B0; }
  body.template-color-41 .mini-cart .shopping-cart .footer a.cart-btn {
    border-color: #7F48B0;
    background-color: #7F48B0; }

body.template-color-41 .brook__toolbar .inner a:hover {
  color: #7F48B0; }

body.template-color-41 .blog-grid .post-inner h5.heading:hover {
  color: #7F48B0; }
  body.template-color-41 .blog-grid .post-inner h5.heading:hover a {
    color: #7F48B0; }

body.template-color-41 .blog-grid .post-inner .post-meta .post-category:hover a {
  color: #7F48B0; }

body.template-color-41 .theme-color {
  color: #7F48B0; }

body.template-color-41 .bg_color--2 {
  background: #7F48B0; }

body.template-color-41 a.brook-btn.bk-btn-theme,
body.template-color-41 button.brook-btn.bk-btn-theme {
  background: #7F48B0;
  color: #fff; }

body.template-color-41 .btn-essential:hover {
  background: #7F48B0 !important;
  border-color: #7F48B0 !important;
  color: #fff; }

body.template-color-41 .red-border {
  border-color: #7F48B0 !important; }

body.template-color-41 .slick-arrow-center.slick-arrow-rounded .slick-btn:hover {
  background-color: #7F48B0;
  color: #fff; }

body.template-color-41 .single-svg-icon-box .svg-icon svg * {
  stroke: #7F48B0 !important; }

body.template-color-41 .single-svg-icon-box:hover .svg-icon svg * {
  stroke: #fff !important; }

body.template-color-41 .menu-price {
  color: #7F48B0; }

body.template-color-41 a.moredetails-btn:hover {
  color: #7F48B0; }

body.template-color-41 a.moredetails-btn i {
  color: #7F48B0; }

body.template-color-41 .messonry-button button.is-checked span.filter-text {
  color: #7F48B0; }

body.template-color-41 .messonry-button button:hover span.filter-text {
  color: #7F48B0; }

body.template-color-41 .messonry-button button span.filter-counter {
  background-color: #7F48B0; }
  body.template-color-41 .messonry-button button span.filter-counter::before {
    border-top: 4px solid #7F48B0;
    border-top-color: #7F48B0; }

body.template-color-41 .messonry-button button:hover span.filter-text {
  color: #7F48B0; }

body.template-color-41 .messonry-button button.is-checked span.filter-text {
  color: #7F48B0; }

body.template-color-41 .portfolio.portfolio_style--1 .port-overlay-info {
  background-color: #7F48B0; }

body.template-color-41 .testimonial_style--2 .content .testimonial-info .testimonial-quote {
  color: #7F48B0; }

body.template-color-41 .testimonial_style--1 .content .testimonial-quote span {
  color: #7F48B0; }

body.template-color-41 .testimonial_style--1:hover {
  background: #7F48B0; }
  body.template-color-41 .testimonial_style--1:hover .testimonial-quote span {
    color: #ffffff; }

body.template-color-41 .contact-form.contact-form--4 .input-box button {
  color: #7F48B0; }
  body.template-color-41 .contact-form.contact-form--4 .input-box button:hover {
    color: #fff;
    background: #7F48B0; }

body.template-color-41 .contact-form.contact-form--3 .input-box button {
  color: #7F48B0; }

body.template-color-41 .animated-dot {
  background-color: #7F48B0; }
  body.template-color-41 .animated-dot .middle-dot {
    background-color: #7F48B0; }
  body.template-color-41 .animated-dot .signal {
    background-color: #7F48B0; }
  body.template-color-41 .animated-dot .signal2 {
    background-color: #7F48B0; }

body.template-color-41 .progress-charts .progress .progress-bar {
  background-color: #7F48B0; }

body.template-color-41 .brook-counter .icon i {
  color: #7F48B0; }

body.template-color-41 .social-share a:hover {
  color: #7F48B0; }

body.template-color-41 .social-icon.tooltip-layout li a.hint--theme[aria-label]::after {
  background: #7F48B0; }

body.template-color-41 .social-icon.tooltip-layout li a.hint--theme[aria-label]::before {
  border-top-color: #7F48B0; }

body.template-color-41 .video-btn a.video-icon,
body.template-color-41 .video-btn a .video-icon {
  background: #7F48B0; }

body.template-color-41 .video-btn-2 .icon-gradient::before {
  background-color: #7F48B0;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #7F48B0 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #7F48B0 100%);
  background-image: linear-gradient(-133deg, #899664 0, #7F48B0 100%); }

body.template-color-41 .video-btn-2 .icon-gradient::after {
  background-color: #7F48B0;
  background-image: -webkit-linear-gradient(223deg, #899664 0, #7F48B0 100%);
  background-image: -o-linear-gradient(223deg, #899664 0, #7F48B0 100%);
  background-image: linear-gradient(-133deg, #899664 0, #7F48B0 100%); }

body.template-color-41 .single-motive-speach .icon i {
  color: #7F48B0; }

body.template-color-41 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"] {
  color: #7F48B0; }
  body.template-color-41 .bk-accordion-style--2 .card-header h5 a.acc-btn[aria-expanded="true"]::before {
    color: #7F48B0; }

body.template-color-41 .contact-form input:focus,
body.template-color-41 .contact-form textarea:focus {
  border-color: #7F48B0; }

body.template-color-41 .contact-form input[type="submit"],
body.template-color-41 .contact-form textarea[type="submit"] {
  border-color: #7F48B0;
  background-color: #7F48B0; }

body.template-color-41 .product .product-thumbnail .product-action .action-list.tooltip-layout li a:hover {
  background-color: #7F48B0;
  color: #fff;
  border-color: #7F48B0; }

body.template-color-41 .gallery .hover-overlay {
  background-color: #7F48B0; }

body.template-color-41 .slick-dots-bottom .slick-dots li.slick-active button {
  background: #7F48B0; }

body.template-color-41 .blog-grid .post-content::before {
  background-color: #7F48B0; }

body.template-color-41 .blog-grid-no-flex .post-quote::before {
  color: #7F48B0; }

body.template-color-41 .brook-pagination-wrapper .brook-pagination li a {
  border: 1px solid #7F48B0; }
  body.template-color-41 .brook-pagination-wrapper .brook-pagination li a:hover {
    background: #7F48B0; }

body.template-color-41 .brook-pagination-wrapper .brook-pagination li.active a {
  background: #7F48B0; }

body.template-color-41 .bl-sidebar.search button:hover {
  background-color: #7F48B0; }

body.template-color-41 .bl-sidebar.search button span {
  color: #7F48B0; }

body.template-color-41 .bl-sidebar.category .category-list li a:hover {
  color: #7F48B0; }

body.template-color-41 .bl-sidebar.tag .inner .tagcloud li a:hover {
  color: #7F48B0; }

body.template-color-41 .social-icon.icon-solid-rounded li a:hover {
  background-color: #7F48B0; }

body.template-color-41 .icon-box .inner .icon {
  color: #7F48B0; }

body.template-color-41 .basic-thick-line-theme-4 {
  border-top: 4px solid #7F48B0; }

body.template-color-41 .procing-box .header .feature-mark {
  background-color: #7F48B0; }

body.template-color-41 a.brook-btn.bk-btn-dark:hover {
  background: #7F48B0; }

body.template-color-41 .bk-list--2 .list-header.with-ckeck .marker::before {
  color: #7F48B0; }

body.template-color-41 .ht-pricing-list-view thead tr th.pricing-header .feature-park {
  background: #7F48B0; }

body.template-color-41 .testimonial-color-variation .slick-center .testimonial_style--1.bg-dark {
  background: #7F48B0 !important; }

body.template-color-41 .basic-modern-dots .dot {
  background-color: #7F48B0; }

body.template-color-41 .bk-accordion .card-header h5 a.acc-btn[aria-expanded="true"] {
  background: #7F48B0; }

body.template-color-41 a.brook-btn.bk-btn-theme-border,
body.template-color-41 button.brook-btn.bk-btn-theme-border {
  border-color: #7F48B0;
  color: #7F48B0; }
  body.template-color-41 a.brook-btn.bk-btn-theme-border:hover,
  body.template-color-41 button.brook-btn.bk-btn-theme-border:hover {
    background: #7F48B0;
    color: #ffffff; }

body.template-color-41 .brook-quote::before {
  color: #7F48B0; }

body.template-color-41 .bk-text-box.bg-theme-color {
  background: #7F48B0; }

body.template-color-41 .bk-list--2 .list-header.with-number--theme .marker {
  color: #7F48B0; }

body.template-color-41 .view-more-btn a span.btn-arrow {
  color: #7F48B0; }

body.template-color-41 #fp-nav ul li a.active {
  background-color: #7F48B0; }

body.template-color-5 .bg_color--13 .single-motive-speach .icon i {
  color: #fff; }

body.template-color-25 .single-svg-icon-box .svg-icon svg * {
  stroke: #fff !important; }

/* ======================
Font Variation 
========================*/
body.template-font-1 .heading,
body.template-font-1 h1,
body.template-font-1 h2,
body.template-font-1 h3,
body.template-font-1 h4,
body.template-font-1 h5,
body.template-font-1 h6,
body.template-font-1 .h1,
body.template-font-1 .h2,
body.template-font-1 .h3,
body.template-font-1 .h4,
body.template-font-1 .h5,
body.template-font-1 .h6 {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-1 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .mega__width--fullscreen .mega__list li a span {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .copyright ul.bk-copyright-menu li a {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .copyright .copyright-right p {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .footer-widget .footer-address p {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .form-style--1 .input-box input,
body.template-font-1 .form-style--1 .input-box textarea {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .testimonial_style--1 .content p {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .service.service--1 .content p {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .service.service--2 .content h4 {
  font-family: "Poppins", sans-serif; }

body.template-font-1 .service.service--2 .content p {
  font-family: "Poppins", sans-serif; }

body.template-font-2 .heading,
body.template-font-2 h1,
body.template-font-2 h2,
body.template-font-2 h3,
body.template-font-2 h4,
body.template-font-2 h5,
body.template-font-2 h6,
body.template-font-2 .h1,
body.template-font-2 .h2,
body.template-font-2 .h3,
body.template-font-2 .h4,
body.template-font-2 .h5,
body.template-font-2 .h6 {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-2 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .mega__width--fullscreen .mega__list li a span {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .copyright ul.bk-copyright-menu li a {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .copyright .copyright-right p {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .footer-widget .footer-address p {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .form-style--1 .input-box input,
body.template-font-2 .form-style--1 .input-box textarea {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .testimonial_style--1 .content p {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .service.service--1 .content p {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .service.service--2 .content h4 {
  font-family: "louis_george_caferegular"; }

body.template-font-2 .service.service--2 .content p {
  font-family: "louis_george_caferegular"; }

body.template-font-3 .heading,
body.template-font-3 h1,
body.template-font-3 h2,
body.template-font-3 h3,
body.template-font-3 h4,
body.template-font-3 h5,
body.template-font-3 h6,
body.template-font-3 .h1,
body.template-font-3 .h2,
body.template-font-3 .h3,
body.template-font-3 .h4,
body.template-font-3 .h5,
body.template-font-3 .h6 {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-3 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .mega__width--fullscreen .mega__list li a span {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .copyright ul.bk-copyright-menu li a {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .copyright .copyright-right p {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .footer-widget .footer-address p {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .form-style--1 .input-box input,
body.template-font-3 .form-style--1 .input-box textarea {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .testimonial_style--1 .content p {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .service.service--1 .content p {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .service.service--2 .content h4 {
  font-family: "Montserrat", sans-serif; }

body.template-font-3 .service.service--2 .content p {
  font-family: "Montserrat", sans-serif; }

body.template-font-4 .heading,
body.template-font-4 h1,
body.template-font-4 h2,
body.template-font-4 h3,
body.template-font-4 h4,
body.template-font-4 h5,
body.template-font-4 h6,
body.template-font-4 .h1,
body.template-font-4 .h2,
body.template-font-4 .h3,
body.template-font-4 .h4,
body.template-font-4 .h5,
body.template-font-4 .h6 {
  font-family: "Playfair Display", serif; }

body.template-font-4 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-4 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "Playfair Display", serif; }

body.template-font-4 .mega__width--fullscreen .mega__list li a span {
  font-family: "Playfair Display", serif; }

body.template-font-4 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "Playfair Display", serif; }

body.template-font-4 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "Playfair Display", serif; }

body.template-font-4 .copyright ul.bk-copyright-menu li a {
  font-family: "Playfair Display", serif; }

body.template-font-4 .copyright .copyright-right p {
  font-family: "Playfair Display", serif; }

body.template-font-4 .footer-widget .footer-address p {
  font-family: "Playfair Display", serif; }

body.template-font-4 .form-style--1 .input-box input,
body.template-font-4 .form-style--1 .input-box textarea {
  font-family: "Playfair Display", serif; }

body.template-font-4 .testimonial_style--1 .content p {
  font-family: "Playfair Display", serif; }

body.template-font-4 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "Playfair Display", serif; }

body.template-font-4 .service.service--1 .content p {
  font-family: "Playfair Display", serif; }

body.template-font-4 .service.service--2 .content h4 {
  font-family: "Playfair Display", serif; }

body.template-font-4 .service.service--2 .content p {
  font-family: "Playfair Display", serif; }

body.template-font-5 .heading,
body.template-font-5 h1,
body.template-font-5 h2,
body.template-font-5 h3,
body.template-font-5 h4,
body.template-font-5 h5,
body.template-font-5 h6,
body.template-font-5 .h1,
body.template-font-5 .h2,
body.template-font-5 .h3,
body.template-font-5 .h4,
body.template-font-5 .h5,
body.template-font-5 .h6 {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-5 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .mega__width--fullscreen .mega__list li a span {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .copyright ul.bk-copyright-menu li a {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .copyright .copyright-right p {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .footer-widget .footer-address p {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .form-style--1 .input-box input,
body.template-font-5 .form-style--1 .input-box textarea {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .testimonial_style--1 .content p {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .service.service--1 .content p {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .service.service--2 .content h4 {
  font-family: "Permanent Marker", cursive; }

body.template-font-5 .service.service--2 .content p {
  font-family: "Permanent Marker", cursive; }

body.template-font-6 .heading,
body.template-font-6 h1,
body.template-font-6 h2,
body.template-font-6 h3,
body.template-font-6 h4,
body.template-font-6 h5,
body.template-font-6 h6,
body.template-font-6 .h1,
body.template-font-6 .h2,
body.template-font-6 .h3,
body.template-font-6 .h4,
body.template-font-6 .h5,
body.template-font-6 .h6 {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-6 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .mega__width--fullscreen .mega__list li a span {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .copyright ul.bk-copyright-menu li a {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .copyright .copyright-right p {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .footer-widget .footer-address p {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .form-style--1 .input-box input,
body.template-font-6 .form-style--1 .input-box textarea {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .testimonial_style--1 .content p {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .service.service--1 .content p {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .service.service--2 .content h4 {
  font-family: "Roboto", sans-serif; }

body.template-font-6 .service.service--2 .content p {
  font-family: "Roboto", sans-serif; }

body.template-font-7 .heading,
body.template-font-7 h1,
body.template-font-7 h2,
body.template-font-7 h3,
body.template-font-7 h4,
body.template-font-7 h5,
body.template-font-7 h6,
body.template-font-7 .h1,
body.template-font-7 .h2,
body.template-font-7 .h3,
body.template-font-7 .h4,
body.template-font-7 .h5,
body.template-font-7 .h6 {
  font-family: "CerebriSans"; }

body.template-font-7 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-7 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "CerebriSans"; }

body.template-font-7 .mega__width--fullscreen .mega__list li a span {
  font-family: "CerebriSans"; }

body.template-font-7 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "CerebriSans"; }

body.template-font-7 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "CerebriSans"; }

body.template-font-7 .copyright ul.bk-copyright-menu li a {
  font-family: "CerebriSans"; }

body.template-font-7 .copyright .copyright-right p {
  font-family: "CerebriSans"; }

body.template-font-7 .footer-widget .footer-address p {
  font-family: "CerebriSans"; }

body.template-font-7 .form-style--1 .input-box input,
body.template-font-7 .form-style--1 .input-box textarea {
  font-family: "CerebriSans"; }

body.template-font-7 .testimonial_style--1 .content p {
  font-family: "CerebriSans"; }

body.template-font-7 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "CerebriSans"; }

body.template-font-7 .service.service--1 .content p {
  font-family: "CerebriSans"; }

body.template-font-7 .service.service--2 .content h4 {
  font-family: "CerebriSans"; }

body.template-font-7 .service.service--2 .content p {
  font-family: "CerebriSans"; }

body.template-font-8 .heading,
body.template-font-8 h1,
body.template-font-8 h2,
body.template-font-8 h3,
body.template-font-8 h4,
body.template-font-8 h5,
body.template-font-8 h6,
body.template-font-8 .h1,
body.template-font-8 .h2,
body.template-font-8 .h3,
body.template-font-8 .h4,
body.template-font-8 .h5,
body.template-font-8 .h6 {
  font-family: "Comic Sans MS"; }

body.template-font-8 .heading.font-reenie {
  font-family: 'Reenie Beanie', cursive; }

body.template-font-8 .mini-cart .shopping-cart .shop-inner ul.product-list li .content .inner h4 {
  font-family: "Comic Sans MS"; }

body.template-font-8 .mega__width--fullscreen .mega__list li a span {
  font-family: "Comic Sans MS"; }

body.template-font-8 .mainmenu-wrapper .page_nav ul.mainmenu li.lavel-1 > a span {
  font-family: "Comic Sans MS"; }

body.template-font-8 .footer-widget .footer-menu ul.ft-menu-list li a {
  font-family: "Comic Sans MS"; }

body.template-font-8 .copyright ul.bk-copyright-menu li a {
  font-family: "Comic Sans MS"; }

body.template-font-8 .copyright .copyright-right p {
  font-family: "Comic Sans MS"; }

body.template-font-8 .footer-widget .footer-address p {
  font-family: "Comic Sans MS"; }

body.template-font-8 .form-style--1 .input-box input,
body.template-font-8 .form-style--1 .input-box textarea {
  font-family: "Comic Sans MS"; }

body.template-font-8 .testimonial_style--1 .content p {
  font-family: "Comic Sans MS"; }

body.template-font-8 .testimonial_style--1 .content .testimonial-info .clint-info span {
  font-family: "Comic Sans MS"; }

body.template-font-8 .service.service--1 .content p {
  font-family: "Comic Sans MS"; }

body.template-font-8 .service.service--2 .content h4 {
  font-family: "Comic Sans MS"; }

body.template-font-8 .service.service--2 .content p {
  font-family: "Comic Sans MS"; }

/* =====================
    Preview Area
========================*/
.preview-html {
  scroll-behavior: smooth; }

.pv-service-wrapper {
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 50px;
  display: -ms-grid;
  display: grid; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .pv-service-wrapper {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 767px) {
    .pv-service-wrapper {
      grid-template-columns: repeat(1, 1fr); } }
  .pv-service-wrapper .single-grid {
    position: relative; }
    .pv-service-wrapper .single-grid::before {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/img/icons/grid-separator-dashed.png) repeat-y;
      width: 1px; }
    .pv-service-wrapper .single-grid .inner {
      max-width: 390px;
      margin: 0 auto;
      padding: 0 15px; }
      .pv-service-wrapper .single-grid .inner h4 {
        font-size: 20px;
        font-family: "Poppins", sans-serif;
        line-height: 1.4;
        margin-bottom: 0;
        word-wrap: break-word;
        max-width: 100%; }

/*======================
Metro Grid Style 3
==========================*/
.preview-grid-metro {
  margin: 0 -30px; }
  .preview-grid-metro .portfolio-33-33 {
    width: 33.33%;
    padding: 0 30px;
    margin-bottom: 30px; }
  .preview-grid-metro .resizer {
    width: 33.33%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .preview-grid-metro .portfolio-33-33 {
      width: 50%; }
    .preview-grid-metro .resizer {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .preview-grid-metro .portfolio-33-33 {
      width: 100%; }
    .preview-grid-metro .resizer {
      width: 100%; } }
  .preview-grid-metro .portfolio {
    text-align: center; }
    .preview-grid-metro .portfolio .thumb {
      position: relative;
      margin-bottom: 40px;
      box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
      transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1); }
    .preview-grid-metro .portfolio .pv-port-info h5 a {
      color: #222222;
      font-size: 18px;
      line-height: 1.39; }
    .preview-grid-metro .portfolio:hover .thumb {
      -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      transform: translateY(-20px); }

.background-marque2 {
  background-image: url(/img/preview-image/brook-landing-marque-image-01.jpg);
  background-repeat: repeat-x;
  background-size: auto; }

.demo-section-title h1 {
  font-weight: 700;
  display: inline-block;
  background: -webkit-linear-gradient(right, #ffb87d 5%, #ff3d99 95%);
  background: linear-gradient(-90deg, #ffb87d 5%, #ff3d99 95%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 150px; }
  .demo-section-title h1 sup {
    font-size: .33em;
    vertical-align: super;
    font-weight: 700;
    margin-left: -9px;
    background: -webkit-linear-gradient(right, #ffb87d 5%, #ff3d99 95%);
    background: linear-gradient(-90deg, #ffb87d 5%, #ff3d99 95%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text; }

.demo-section-title h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4; }

.demo-section-title p {
  font-size: 16px;
  width: 730px;
  color: #888888;
  line-height: 1.88;
  margin: auto;
  margin-bottom: 110px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .demo-section-title p {
      max-width: 730px;
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .demo-section-title p {
      max-width: 730px;
      width: 100%; } }

.pv-masonry-wrapper {
  padding: 0 140px; }

/*============================
    Template Layout
=============================*/
.pv-template-layout.layout-bg {
  background-image: url(/img/preview-image/brook-landing-immensity-of-template-bg.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #e9eef0; }

.pv-single-feature {
  width: 780px;
  max-width: 100%;
  padding: 0 110px; }
  .pv-single-feature .feature-head {
    font-size: 131px;
    line-height: 1;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FAEFAE), color-stop(100%, #F09659));
    background: -moz-linear-gradient(-90deg, #FAEFAE 0, #F09659 100%);
    background: -webkit-linear-gradient(-90deg, #FAEFAE 0, #F09659 100%);
    background: -o-linear-gradient(-90deg, #FAEFAE 0, #F09659 100%);
    background: -ms-linear-gradient(-90deg, #FAEFAE 0, #F09659 100%);
    background: linear-gradient(-90deg, #FAEFAE 0, #F09659 100%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: 700;
    display: inline-block; }
    .pv-single-feature .feature-head sup {
      font-size: .33em;
      vertical-align: super;
      font-weight: 700;
      margin-left: 19px;
      background: inherit;
      color: transparent;
      top: -.5em; }
  .pv-single-feature .content h2.heading-h2 {
    font-size: 40px; }

.pv-bg-color-1 {
  background-color: #e9eef0; }

/*======================
preview Area
=========================*/
.pv-footer-area {
  background-color: #e63a65;
  background-image: url(/img/preview-image/brook-landing-footer-bg-image-01.png);
  background-repeat: no-repeat;
  background-position: bottom left; }
  .pv-footer-area .footer-bg-2 {
    background-image: url(/img/preview-image//brook-landing-footer-bg-image-02.png);
    background-repeat: no-repeat;
    background-position: top right; }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .pv-masonry-wrapper {
    padding: 0 60px; }
  .pv-single-feature .content h2.heading-h2 {
    font-size: 33px; }
  .pv-single-feature .feature-head {
    font-size: 82px; }
  .pv-single-feature .feature-head sup {
    margin-left: -1px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .demo-section-title p {
    margin-bottom: 61px; }
  .pv-single-feature .feature-head {
    font-size: 112px; }
  .pv-template-layout.layout-bg {
    background-size: cover;
    padding-bottom: 150px; }
  .pv-masonry-wrapper {
    padding: 0 50px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pv-template-layout.layout-bg {
    background-size: cover;
    padding-bottom: 150px; }
  .pv-masonry-wrapper {
    padding: 0 60px; }
  .pv-single-feature .feature-head {
    font-size: 116px; }
  .preview-flexable .slick-gutter-40 .slick-slide {
    padding-left: 20px;
    padding-right: 20px; }
  .pv-single-feature {
    padding: 0 50px; } }

@media only screen and (max-width: 767px) {
  .pv-template-layout.layout-bg {
    background-size: cover;
    padding-bottom: 150px; }
  .pv-masonry-wrapper {
    padding: 0 30px; }
  .pv-single-feature .feature-head {
    font-size: 116px; }
  .demo-section-title p {
    margin-bottom: 46px;
    font-size: 14px; }
  .demo-section-title h2 {
    font-size: 25px; }
  .demo-section-title h1 {
    font-size: 107px; }
  .pv-single-feature {
    padding: 0 50px; }
  .tp-caption.display-your {
    padding: 0 20px !important; }
  .tp-caption.display-range {
    padding: 0 20px !important;
    font-size: 14px !important;
    top: 39px; } }

/* Responsive Css */
@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .creative-portfolio .tp-caption .playfair-font {
    font-size: 50px; } }

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .creative-portfolio .tp-caption .playfair-font {
    font-size: 38px;
    max-width: 550px; }
  .minimal-gallery.masonry-wrap .masonary-item {
    padding: 0 20px;
    margin-top: 40px; }
  .minimal-gallery.masonry-wrap {
    margin: 0 -20px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .creative-portfolio .tp-caption .playfair-font {
    font-size: 36px;
    max-width: 550px;
    left: -20px;
    position: relative; }
  .revolution-slider-area .inner h1.heading {
    font-size: 89px; }
  .one-page-header .header-wrapper .header-left-wrapper {
    padding-left: 43px;
    padding-right: 43px; }
  .presentation-slider-wrapper .presentation-inner .inner.skill-area {
    padding-left: 30px;
    padding-right: 30px; }
  .single-product-details-side {
    margin-left: 16px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .revolution-slider-area .inner h1.heading {
    font-size: 70px !important; }
  .one-page-header .header-wrapper .header-left-wrapper {
    padding-left: 43px;
    padding-right: 43px; }
  .fullscreen-slider-wrapper .feature-wrapper .fullscreen-inner .post-character {
    font-size: 220px; }
  .blog-style--1 .blog-content .content h3.the-title {
    font-size: 20px; }
  .blog-grid.blog-standard .post-content {
    padding: 29px 12px 30px; } }

@media only screen and (max-width: 767px) {
  .slick-dot-vertical-center .slick-dots {
    right: 10px; }
  .authenthic-service h1 br {
    display: none; }
  .revolution-slider-area .inner h1.heading {
    font-size: 70px !important; }
  .clint-succeed h3.heading-h3.font-32 {
    font-size: 20px;
    line-height: 1.88;
    margin-top: 20px; }
  .tp-caption.startups-pra,
  .tp-caption.creative-agency-pra {
    padding: 0 29px !important;
    width: auto !important; }
  .tp-caption.homer-service-heading {
    padding: 0 84px !important;
    width: 100% !important;
    top: 9px; }
  .tp-caption.homer-service-pra {
    padding: 0 84px !important;
    width: 100% !important;
    top: 35px; }
  .tp-caption.creative-agency-heading {
    font-size: 38px !important;
    padding: 0 30px !important; }
  .tp-caption.product-landing-pra {
    font-size: 14px !important;
    padding: 0 30px !important; }
  .architecture-service p.bk_pra {
    color: #ffffff; }
  .tp-caption.design-studio-pra {
    font-size: 14px !important;
    padding: 0 30px !important;
    position: relative;
    top: 15px; }
  .tp-caption.architecture-pra {
    font-size: 14px !important;
    padding: 0 30px !important;
    position: relative;
    left: -36px !important;
    padding-right: 51px !important;
    top: -77px; }
  .tp-caption.architecture-btn {
    position: relative;
    top: -50px !important; }
  .single-vertical-slide .slide-inner .info h2.heading {
    font-size: 36px; }
  .revolution-slider-area .inner h1.heading br,
  h3.heading-h3 br,
  .single-vertical-slide .slide-inner .info h2.heading br {
    display: none; }
  h6.heading-h6.font-16 {
    font-size: 14px; }
  .brand-default.brand-style--2 .brand {
    min-height: 144px; }
  .section-title--between .title h3,
  .title--creative h2 {
    font-size: 29px; }
  .call-to-action.callto-action--1 .content h3 {
    font-size: 32px;
    margin-bottom: 35px; }
  .portfolio.portfolio_style--2 .port-overlay-info .content {
    padding: 37px 20px; }
  .portfolio.portfolio_style--2 .port-overlay-info .content h3.port-title {
    font-size: 18px; }
  .one-page-header .header-wrapper .header-left-wrapper {
    padding-left: 30px;
    padding-right: 30px; }
  .shop-sidebar-container .shop-sidebar-wrapper {
    padding: 50px 10px 66px; }
  .number-wrap span {
    font-size: 41px; }
  .testimonial--horizontal--active {
    padding-right: 30px; }
  .number-speaks .content h2 {
    font-size: 37px; }
  .blog-style--1 .blog-content.blog-position--bottom {
    padding: 30px 18px 10px; }
  .blog-style--1 .blog-content .content h3.the-title {
    font-size: 16px;
    margin: 0 0 13px; }
  .blog-style--1 .blog-content .content ul.meta {
    margin-bottom: 14px; }
  .blog-style--1 .blog-content .content .video-btn {
    margin-bottom: 22px; }
  .single-motive-speach .content p {
    padding-right: 0; }
  .authenthic-service h1.heading-h1 {
    font-size: 24px !important; } }

@media only screen and (max-width: 575px) {
  .copyright.copyright--2 .copyright-right {
    width: 244px; }
  .testimonial--horizontal--active .swiper-pagination.swiper-pagination-custom {
    width: 245px; }
  .porfolio-swip-horizontal .swiper-slide .thumb img {
    height: 404px; } }
